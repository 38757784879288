import { Controller, useForm } from "react-hook-form";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { Drawer, SIZE } from "baseui/drawer";
import {
  HeadingSmall,
  HeadingXLarge,
  LabelMedium,
  LabelSmall,
  ParagraphLarge,
  ParagraphMedium,
} from "baseui/typography";
import { IconButton, KIND as Icon } from "../../components/IconButton";
import PopupExit from "../../assets/img/svg/PopupExit";
import Plus from "../../assets/img/svg/Upload/Plus";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
// import RequestClarificationForm from "./RequestClarificationForm";
import RequestInformationCard from "../../components/RequestInformationCard/RequestInformationCard";
import RequestInformationEdit from "../../components/RequestInformationEdit/RequestInformationEdit";
import { TextButton, SIZE as size, KIND } from "../../components/TextButton";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getCustomerRequestInformation,
  getCustomerRequestInformationSingle,
} from "../../services";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";
import { useIntl } from "react-intl";
import setSelectValues from "../../utils/setSelectValues";
import moment from "moment";
import { ChevronLeft } from "baseui/icon";
import RequestInformation from "../../assets/img/svg/RequestInformation";
import _ from "lodash";
import { CLIENT_PORTAL_ACCESS } from "../../providers/RBACProvider/permissionList";
import RequestClarificationForm from "../../containers/Request/RequestClarificationForm";
import { useSelector } from "react-redux";

const RequestInformationList = ({ setAdd, setResource, customerId }) => {
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  let { currentOrganization, users, referenceData } = useContext(MaxyfiContext);

  const { isLoading, data, isFetching } = useQuery(
    `customer-${customerId}-request-information`,
    async () => {
      return await getCustomerRequestInformation({
        organization: currentOrganization,
        customerId,
        is_disputes: false,
      });
    },
    {
      onSuccess: (data) => {
        if (
          data &&
          data.data &&
          data.data.docs &&
          Array.isArray(data.data.docs)
        ) {
          if (data.data.docs.length > 0) {
            setAdd(false);
          } else {
            setAdd();
          }
        }
      },
    }
  );

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      {data && data.data && data.data.docs && Array.isArray(data.data.docs) ? (
        data.data.docs.map((e) => {
          return (
            <RequestInformationCard
              {...e}
              isEdit={false}
              setIsEdit={() => {}}
              setResource={setResource}
            />
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

const RequestInformationInfo = ({
  id,
  reset,
  isEdit = false,
  customerId,
  requestInfoId,
}) => {
  let {
    currentOrganization,
    users,
    currentDefaultFormatDetails,
    referenceData,
    space,
  } = useContext(MaxyfiContext);

  const isClientAccess =
    space && space?.role_permissions?.includes(CLIENT_PORTAL_ACCESS);

  const { isLoading, data, isFetching } = useQuery(
    `customer-${customerId}-request-${id}`,
    async () => {
      return await getCustomerRequestInformationSingle({
        organization: currentOrganization,
        customerId,
        resourceId: id,
        is_disputes: false,
      });
    }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (data && data.data && data.data.doc) {
    const {
      invoices = [],
      assigned_to,
      assign_type,
      review_date,
      status,
      sub_category = "",
      details,
      category,
    } = data.data.doc;

    const referenceUserData = referenceData["users"].find(
      (id) => id.id === assigned_to
    );

    const defaultValues = {
      invoices: setSelectValues(invoices, true),
      category: category,
      assigned_to: [
        {
          id: assigned_to,
          label: referenceUserData?.label,
        },
      ],
      // assigned_to:
      //   assign_type === "BUSINESS_UNIT"
      //     ? [
      //         {
      //           ...(assignedTo && assignedTo[0] ? assignedTo[0] : {}),
      //           isClient: true,
      //         },
      //       ]
      //     : assignedTo,
      ...(review_date
        ? {
            review_date: moment
              .utc(review_date)
              .tz(currentDefaultFormatDetails.time_zone)
              .toDate(),
          }
        : {}),
      status: setSelectValues(status),
      ...(isEdit && !isClientAccess ? { details } : {}),
    };

    return (
      <RequestInformationCard {...data.data.doc} isList={false}>
        {isEdit ? (
          <RequestClarificationForm
            resourceId={id}
            requestInfoId={requestInfoId}
            defaultValues={defaultValues}
            isEdit
            reset={reset}
            sub_category={sub_category}
            business_unit={_.get(data, "data.doc.business_unit", "")}
            customerId={customerId}
          />
        ) : (
          <></>
        )}
      </RequestInformationCard>
    );
  }
};

const LIST = "LIST";
const ADD = "ADD";
const EDIT = "EDIT";
const VIEW = "VIEW";
const initialState = {
  type: LIST,
  resourceId: null,
  resourceName: "",
};

function RequestInformation_V2({
  setIsRequest,
  active,
  resourceId,
  resourceName,
}) {
  let customerId = "";

  // business Unit ID for V2 UI
  let { data } = useSelector((e) => e.customerOverviewDrawer);

  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const [state, setState] = useState(initialState);

  const params = useParams();

  if (params.customerId) {
    customerId = params.customerId;
  } else {
    customerId = getSearchParams.get("customers");
  }

  const setAdd = (isAdd = true) => {
    setState({ ...state, type: isAdd ? ADD : LIST });
  };

  const setResource = (id, name, isEdit = false) => {
    setState({
      ...state,
      type: isEdit ? EDIT : VIEW,
      resourceId: id,
      resourceName: name,
    });
  };

  const reset = () => {
    if (resourceId && resourceName) {
      setIsRequest();
    } else {
      setState({ ...initialState });
    }
  };

  useEffect(() => {
    if (resourceId && resourceName) {
      setResource(resourceId, resourceName, true);
    }

    return () => {
      if (resourceId && resourceName) {
        getSearchParams.delete("customers");
        setGetSearchParams(getSearchParams.toString());
      }
    };
  }, []);

  return (
    <>
      <div
        // autoFocus
        // onClose={() => {
        //   setIsRequest(false);
        // }}
        // isOpen={active}
        // onBackdropClick={() => {}}
        style={{ width: "800px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            paddingRight: "30px",
          }}
        >
          {state.type === ADD || state.type === LIST ? (
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <RequestInformation size={22} />
              <HeadingXLarge>Request Information</HeadingXLarge>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {resourceId && resourceName ? (
                <></>
              ) : (
                <IconButton
                  kind="tertiary"
                  size="mini"
                  onClick={() => {
                    reset();
                  }}
                >
                  <ChevronLeft color="#ADADAD" />
                </IconButton>
              )}
              <ParagraphLarge $style={{ color: "#ADADAD" }}>
                Request Information /{" "}
              </ParagraphLarge>{" "}
              <HeadingXLarge>
                {state.resourceName ? state.resourceName : ""}
              </HeadingXLarge>
            </div>
          )}
          <div>
            {state.type === LIST ? (
              <TextButton
                kind="secondary"
                size="mini"
                onClick={() => {
                  setAdd();
                }}
              >
                <Plus fill="#516BEB" size={18} />
                Add
              </TextButton>
            ) : (
              <></>
            )}
          </div>
        </div>
        {state.type === ADD && (
          <RequestClarificationForm
            reset={reset}
            business_unit={data}
            customerId={customerId}
          />
        )}
        {state.type === LIST && (
          <RequestInformationList
            setAdd={setAdd}
            setResource={setResource}
            business_unit={data}
            customerId={customerId}
          />
        )}
        {state.type === VIEW || state.type === EDIT ? (
          <RequestInformationInfo
            id={state.resourceId}
            reset={reset}
            requestInfoId={resourceId}
            isEdit={state.type === EDIT ? true : false}
            business_unit={data}
            customerId={customerId}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default RequestInformation_V2;
