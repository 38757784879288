import React, { useContext, useEffect, useMemo } from "react";
import { Icon, Typography } from "../../../components_v2";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextBox } from "../../../components/TextBox";
import { Controller } from "react-hook-form";
import { SIZE, SelectBox } from "../../../components/SelectBox";
import AdjustmentTable from "./AdjustmentTable";
import { KIND, TextButton } from "../../../components/TextButton";
import { ADJUSTMENT_DRAWER, setDrawerState } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import {
  ADD_LIST_VALUES,
  DELETE_ADJUSTMENT,
  EDIT_ADJUSTMENT,
} from "../../../providers/RBACProvider/permissionList";
import { useMutation, useQuery } from "react-query";
import {
  deleteAdjustment,
  getCustomerOverviewTimelineList,
} from "../../../services";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import _ from "lodash";
import useFormat from "../../../hooks/useFormat";
import {
  ADJ_TIMELINE_,
  CUSTOMER_OVERVIEW_,
  EDIT,
  VIEW,
} from "../../../constants";
import setSelectValues from "../../../utils/setSelectValues";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import { ParagraphSmall } from "baseui/typography";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { AllocatedInputBox } from "../../../components/AllocatedInputBox";
import { currencyList } from "../../../utils_v2/CurrencyToSymbol";
import queryClient from "../../../providers/queryClient";
import Loader from "../../../components/Loader";

const AdjustmentForm = (props) => {
  let {
    handleSubmit = () => {},
    onSubmit = () => {},
    onSuccessRefetch,
    invoiceData,
    control,
    errors,
    setValue,
    watch,
    reset,
    editAdjustment,
    addAdjustment,
  } = props;
  const { customerId } = useParams();
  const intl = useIntl();
  const format = useFormat();

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const { referenceData, space, currentOrganization } =
    useContext(MaxyfiContext);

  const { debtCom } = useMemo(() => {
    let debtCom;
    if (_.get(invoiceData, "data.data.docs.business_unit", "")) {
      debtCom =
        referenceData &&
        referenceData["business_unit_list"].find(
          (e) => e.id === _.get(invoiceData, "data.data.docs.business_unit", "")
        );
    }
    return { debtCom };
  }, [invoiceData.isFetched]);

  const { amount, ici } = watch();

  const { data } = useSelector((e) => e.customerOverviewDrawer);

  const { drawerType, isForm } = data;

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  let dispatch = useDispatch();

  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  const timeline = useQuery(
    [
      `${ADJ_TIMELINE_}${data?.cus_id}`,
      {
        filters: {
          adjustmentInvoiceId: data && data.invID,
          type: ["INVA_ADJ"],
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getCustomerOverviewTimelineList({
        organization: currentOrganization,
        id: data?.cus_id,
        entity: "customer",
        page_size: 0,
        page: 1,
        ...filters,
        is_hide_internal: true,
      });
    }
  );

  const removeAdjustment = useMutation(
    (adjData) =>
      deleteAdjustment({
        ...adjData,
        organization: currentOrganization,
        invoiceId: data && data.invID,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        onSuccessRefetch();
      },
    }
  );

  useEffect(() => {
    if (
      debtCom &&
      debtCom.commission_method !== "DC" &&
      _.get(invoiceData, "data.data.docs.is_itmzn", false) &&
      _.get(invoiceData, "data.data.docs.com.p", 0)
    ) {
      let findComPer =
        (amount * _.get(invoiceData, "data.data.docs.com.p", 0)) /
        (100 + _.get(invoiceData, "data.data.docs.com.p", 0));

      if (amount && findComPer) {
        let subClaa =
          Number(amount)?.toFixed(2) - Number(findComPer)?.toFixed(2);
        setValue(`claa`, Number(subClaa)?.toFixed(2));
        setValue(`aca`, Number(findComPer)?.toFixed(2));
      }
    } else if (
      debtCom &&
      debtCom.commission_method !== "DC" &&
      _.get(invoiceData, "data.data.docs.comper", 0) >= 0
    ) {
      let findComPer =
        (amount * _.get(invoiceData, "data.data.docs.comper", 0)) /
        (100 + _.get(invoiceData, "data.data.docs.comper", 0));

      if (amount && findComPer) {
        let subClaa =
          Number(amount)?.toFixed(2) - Number(findComPer)?.toFixed(2);
        setValue(`claa`, Number(subClaa)?.toFixed(2));
        setValue(`aca`, Number(findComPer)?.toFixed(2));
      }
    }
  }, [amount, invoiceData.isFetched, ici]);

  return (
    <>
      {isForm ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography type="h2" subType="medium">
            Adjustments
          </Typography>
          <div className="co_add_payment_top_input_wraper">
            <div style={{ width: "180px" }}>
              <Controller
                name="date"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <DateRangeSelect
                    clearable={false}
                    requiredAstric={true}
                    size={SIZE.mini}
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "date",
                    })}
                    placeholder={intl.formatMessage({
                      id: " ",
                    })}
                    disabled={drawerType === VIEW}
                    value={field.value}
                    onChange={(e) => field.onChange(e.date)}
                  />
                )}
              />
            </div>
            <div style={{ width: "180px" }}>
              <Controller
                name="amount"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    requiredAstric={true}
                    size={SIZE.mini}
                    {...field}
                    name={field.name}
                    type="number"
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "amount",
                    })}
                    placeholder={intl.formatMessage({
                      id: "amount",
                    })}
                    onKeyDown={(e) => {
                      if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    onBlur={() => {
                      setValue(`invoices[0].allocatedAmount`, amount);
                    }}
                    disabled={drawerType === VIEW}
                    value={field.value}
                  />
                )}
              />
            </div>
            <div style={{ width: "180px" }}>
              <Controller
                defaultValues={[]}
                name="type"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.mini}
                    {...field}
                    name={field.name}
                    clearable={false}
                    creatable={creatablePermission ? true : false}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "motion_type",
                    })}
                    placeholder={intl.formatMessage({
                      id: "motion_type",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    disabled={drawerType === VIEW}
                    options={referenceData["adjustment_type"]}
                  />
                )}
              />
            </div>
            <div style={{ width: "180px" }}>
              <Controller
                name="reference"
                control={control}
                render={({ field }) => (
                  <TextBox
                    size={SIZE.mini}
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "reference",
                    })}
                    placeholder={intl.formatMessage({
                      id: "reference",
                    })}
                    disabled={drawerType === VIEW}
                    value={field.value}
                  />
                )}
              />
            </div>
          </div>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextBox
                {...field}
                size={SIZE.mini}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "description",
                })}
                placeholder={intl.formatMessage({
                  id: "description",
                })}
                disabled={drawerType === VIEW}
                value={field.value}
              />
            )}
          />

          {debtCom && debtCom.commission_method !== "DC" && (
            <div style={{ marginBottom: "10px" }}>
              <Controller
                name={`ici`}
                control={control}
                render={({ field }) => (
                  <CheckBoxBaseweb
                    disabled={drawerType === VIEW}
                    {...field}
                    checked={field.value}
                  >
                    <Typography>
                      Commission amount included in the amount{" "}
                      {currencyList &&
                      currencyList[
                        _.get(customerData, "data.doc.default_currency")
                      ]
                        ? currencyList[
                            _.get(customerData, "data.doc.default_currency")
                          ]
                        : ""}
                      {format.currency({ amount: amount })}
                    </Typography>
                  </CheckBoxBaseweb>
                )}
              />
            </div>
          )}

          {debtCom && debtCom.commission_method !== "DC" && ici && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                marginTop: "0px",
                marginBottom: "15px",
              }}
            >
              <div style={{ display: "flex", gap: "15px" }}>
                <Typography>
                  Adjustment Amount
                  <br />
                  <span style={{ fontSize: "11px", color: "#787878" }}>
                    (Less Commission)
                  </span>
                </Typography>
                <div>
                  <Controller
                    name={`claa`}
                    control={control}
                    shouldUnregister={true}
                    render={({ field }) => {
                      return (
                        <AllocatedInputBox
                          {...field}
                          type="number"
                          clearable={false}
                          fullWidth
                          name={field.name}
                          size={"mini"}
                          value={field.value}
                          disabled={true}
                          onFocus={(e) => {
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            );
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div
                style={{ display: "flex", gap: "15px", alignItems: "baseline" }}
              >
                <Typography>Commission Amount</Typography>
                <div>
                  <Controller
                    name={`aca`}
                    control={control}
                    shouldUnregister={true}
                    render={({ field }) => {
                      return (
                        <AllocatedInputBox
                          {...field}
                          type="number"
                          clearable={false}
                          fullWidth
                          name={field.name}
                          size={"mini"}
                          value={field.value}
                          disabled={true}
                          onFocus={(e) => {
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            );
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <Typography type="h3" subType="medium">
            Adjustment Overview
          </Typography>
          {invoiceData.isFetched && !invoiceData.isLoading && (
            <AdjustmentTable
              watch={watch}
              control={control}
              invoiceData={invoiceData}
            />
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <TextButton
              kind={KIND.tertiary}
              size={"mini"}
              type="button"
              onClick={() => closeDrawer()}
            >
              Cancel
            </TextButton>
            <TextButton
              size={"mini"}
              // disabled={}
              disabled={
                editAdjustment.isLoading ||
                addAdjustment.isLoading ||
                drawerType === VIEW
              }
              isLoading={editAdjustment.isLoading || addAdjustment.isLoading}
            >
              Save
            </TextButton>
          </div>
        </form>
      ) : (
        <></>
      )}

      {/* {_.get(timeline, "data.data.docs", []).length > 0 ? ( */}
      <Typography type="h3" subType="medium">
        Past Adjustment Details
      </Typography>
      {/* // ) : (
      //   <></>
      // )} */}

      {timeline.isLoading || timeline.isFetching ? (
        <div>
          <Loader />
        </div>
      ) : (
        <></>
      )}

      {!_.get(timeline, "data.data.docs", []).length && (
        <Typography style={{ textAlign: "center", marginTop: "20px" }}>
          No past adjustment details found.
        </Typography>
      )}

      {!timeline.isFetching ? (
        <table className="adj_table">
          {_.get(timeline, "data.data.docs", []).map((e, i) => {
            return (
              <tr className="adj_table_row" key={i}>
                <td style={{ width: "20%" }}>
                  <Typography type="p" subType="regular">
                    {format.date({
                      value: _.get(e, "content.portal_payment_date", null),
                    })}
                  </Typography>
                </td>
                <td style={{ width: "60%" }}>
                  <div className="adj_content_wraper">
                    <Typography type="p" subType="regular">
                      <span className="adj_span">Adjusted amount:</span>{" "}
                      {format.currency({
                        amount: _.get(
                          e,
                          "content.portal_payment_amount.value",
                          0
                        ),
                      })}
                    </Typography>
                    <Typography type="p" subType="regular">
                      <span className="adj_span">Type:</span>{" "}
                      {format.rd({
                        id: _.get(e, "content.portal_payment_method", ""),
                        name: "adjustment_type",
                      })}
                    </Typography>
                  </div>
                  <Typography type="p" subType="regular">
                    <span className="adj_span">Description:</span>{" "}
                    {_.get(e, "comment", "")}
                  </Typography>
                </td>
                <td style={{ width: "20%", borderRight: "0" }}>
                  <Typography type="p" subType="regular">
                    {format.rd({
                      name: "users",
                      id: _.get(e, "executed_by", ""),
                    })}
                  </Typography>
                </td>
                {_.get(e, "del_by", "") ? (
                  <></>
                ) : (
                  <td style={{ borderLeft: "0" }}>
                    <div className="adj_past_list_icon_wraper">
                      <RBACWrapper role={EDIT_ADJUSTMENT} type="PERMISSION">
                        {drawerType !== VIEW ? (
                          <Icon
                            icon="edit"
                            color="#ADADAD"
                            isPressable
                            size={18}
                            onClick={() => {
                              setValue(
                                "type",
                                setSelectValues(
                                  _.get(e, "content.portal_payment_method", "")
                                )
                              );
                              setValue(
                                "reference",
                                _.get(e, "content.portal_payment_reference", "")
                              );
                              setValue("description", _.get(e, "comment", ""));
                              setValue(
                                "amount",
                                _.get(
                                  e,
                                  "content.portal_payment_amount.value",
                                  ""
                                )
                              );
                              setValue(
                                "date",
                                new Date(
                                  _.get(e, "content.portal_payment_date", null)
                                )
                              );
                              setValue(
                                `invoices[0].allocatedAmount`,
                                _.get(
                                  e,
                                  "content.portal_payment_amount.value",
                                  ""
                                )
                              );
                              setDrawer(ADJUSTMENT_DRAWER, {
                                ...data,
                                ...e,
                                invID: _.get(
                                  e,
                                  "content.portal_payment_invoice_id[0]",
                                  ""
                                ),
                                drawerType: EDIT,
                                isForm: true,
                              });
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </RBACWrapper>
                      <RBACWrapper role={DELETE_ADJUSTMENT} type="PERMISSION">
                        <StatefulPopover
                          triggerType={TRIGGER_TYPE.click}
                          content={({ close }) => (
                            <div className="adj_popover_container">
                              <div className="adj_popover_head_container">
                                <Icon icon="alert" color="#FD372A" />
                                <Typography type="p">
                                  Do you want to continue?
                                </Typography>
                              </div>
                              <Typography
                                type="p"
                                subType="regular"
                                className="text-secondary"
                              >
                                This will delete the adjustment entry
                              </Typography>
                              <div className="adj_popover_footer_container">
                                <TextButton
                                  size="mini"
                                  kind={KIND.tertiary}
                                  type="button"
                                  onClick={() => close()}
                                >
                                  <Typography type="p" subType="regular">
                                    Back
                                  </Typography>
                                </TextButton>
                                <TextButton
                                  size="mini"
                                  kind={KIND.secondary}
                                  disabled={
                                    drawerType === VIEW ||
                                    removeAdjustment.isLoading
                                  }
                                  isLoading={removeAdjustment.isLoading}
                                  onClick={() => {
                                    removeAdjustment
                                      .mutateAsync({
                                        timelineId: _.get(e, "_id", ""),
                                      })
                                      .then((data) => {
                                        close();
                                      });
                                  }}
                                  type="error"
                                >
                                  <Typography
                                    type="p"
                                    subType="regular"
                                    className="text-error"
                                  >
                                    Confirm
                                  </Typography>
                                </TextButton>
                              </div>
                            </div>
                          )}
                        >
                          {drawerType !== VIEW ? (
                            <div>
                              <Icon
                                icon="delete"
                                color="#ADADAD"
                                isPressable
                                size={18}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </StatefulPopover>
                      </RBACWrapper>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </table>
      ) : (
        <></>
      )}
    </>
  );
};

export default AdjustmentForm;
