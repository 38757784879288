const QuillFrame = (props) => {
  let { content, QuillHeight, scrollCustomize = false } = props;

  const styledString = ` <style>
    /* Customize the scrollbar */
    ::-webkit-scrollbar {
      width: 5px; /* Width of the scrollbar */
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1; /* Track color */
    }
    ::-webkit-scrollbar-thumb {
      background: #cdced9; /* Thumb color */
      border-radius: 7px; /* Rounded corners */
    }
    ::-webkit-scrollbar-thumb:hover {
      background: darkred; /* Thumb hover color */
    }
  </style> ${content}`;
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        border: "0.5px solid #cdced9",
        borderRadius: "5px",
      }}
    >
      <iframe
        style={{
          height: QuillHeight ? QuillHeight : "100%",
          width: "100%",
          padding: "10px 0px 10px 10px",
        }}
        className="scroll"
        srcDoc={scrollCustomize ? styledString : content}
        // src="demo_iframe_srcdoc.htm"
      ></iframe>
    </div>
  );
};

export default QuillFrame;
