import React, { useContext } from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _, { values } from "lodash";

function removeHTMLTags(htmlString) {
  // Remove <style> blocks
  const withoutStyle = htmlString.replace(
    /<style[^>]*>[\s\S]*?<\/style>/gi,
    ""
  );

  // Remove <script> blocks (if needed)
  const withoutScript = withoutStyle.replace(
    /<script[^>]*>[\s\S]*?<\/script>/gi,
    ""
  );

  // Remove function definitions (if defined inline)
  const withoutInlineFunctions = withoutScript.replace(
    /function\s+[a-zA-Z0-9_]+\s*\([\s\S]*?\}\s*}/g,
    ""
  );
  const parser = new DOMParser();
  const trimmedContent = withoutInlineFunctions.trim();
  const doc = parser.parseFromString(trimmedContent, "text/html");

  const textContent = doc?.body?.textContent || "";

  return textContent?.trim();
}

function HtmlTextConvertor(props) {
  let { value, column, alignment, accessor, suffix, row, color, ...rest } =
    props;

  value = _.get(row.original, accessor, "");

  const [visible, setVisible] = React.useState(false);
  let referenceData = useContext(MaxyfiContext);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  return visible ? (
    <StatefulTooltip
      accessibilityType={"tooltip"}
      content={
        <div
          style={{
            minWidth: "auto",
            maxWidth: "600px",
            overflow: "scroll",
            maxHeight: "200px",
          }}
        >
          {removeHTMLTags(value)}
        </div>
      }
    >
      <Paragraph2
        ref={ref}
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          textAlign: alignment,
          // margin: "5px",
          lineHeight: "18px",
          color: color ? color : "#333860",
        }}
      >
        <div
          className="elip"
          ref={ref}
          style={{ color: `${!suffix ? "" : "#757575"}`, lineHeight: "18px" }}
        >
          {value && value?.toString().length ? (
            <>
              {removeHTMLTags(value)}
              {!suffix ? "" : suffix}
            </>
          ) : (
            "-"
          )}
        </div>
      </Paragraph2>
    </StatefulTooltip>
  ) : (
    <Paragraph2
      ref={ref}
      $style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        textAlign: alignment,
        lineHeight: "18px",
        // margin: "5px",
        color: color ? color : "#333860",
      }}
      style={{ color: `${!suffix ? "" : "#757575"}` }}
    >
      {(value && value.toString().length) || value == 0 ? (
        <>
          {removeHTMLTags(value)} {!suffix ? "" : suffix}
        </>
      ) : (
        "-"
      )}
    </Paragraph2>
  );
}
export default HtmlTextConvertor;
