import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer } from "baseui/drawer";
import { FormattedMessage, useIntl } from "react-intl";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { LabelLarge, ParagraphLarge } from "baseui/typography";

import {
  addingLetterStatusDeskDocument,
  addLetterStatusEmailAttachment,
  letterStatusDrawer,
  refetchLetterStatus,
  unSelectingLetterStatusDeskDocument,
  updatingLetterStatusDeskDocument,
} from "../../../redux/customerOverview/letterStatus/action";
import { TextArea } from "../../../components/TextArea";
import EmailAttachment from "../../../components/EmailAttachment/EmailAttachment";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import setSelectValues from "../../../utils/setSelectValues";
import { TextBox } from "../../../components/TextBox";
import { letterPreviewPost, putLetterAction } from "../../../services";
import getSelectValues from "../../../utils/getSelectValues";
import PlusLetter from "../../../assets/img/svg/PlusLetter";
import { ADD_LIST_VALUES } from "../../../providers/RBACProvider/permissionList";
import { TextButton, SIZE, KIND } from "../../../components/TextButton";
import { SelectBox } from "../../../components/SelectBox";
import { useParams } from "react-router-dom";
import queryClient from "../../../providers/queryClient";
import { LETTER_STATUS, LETTER_STATUS_ } from "../../../constants";
import { setDrawerState } from "../../../redux/actions";
import { Typography } from "../../../components_v2";
import { Spinner } from "baseui/icon";
import DocFileType from "../../../assets/img/svg/DocFileType";

const LetterStatusEdit = () => {
  const { letterDrawerStatus, selectedDocumentLetterStatusData } = useSelector(
    (s) => s.letterStatusReducer
  );

  const { customerId } = useParams();

  const { data } = useSelector((s) => s.customerOverviewDrawer);

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  const letterAttachments =
    selectedDocumentLetterStatusData &&
    selectedDocumentLetterStatusData.map((e) => {
      return e.fileRef;
    });

  let dispatch = useDispatch();
  let intl = useIntl();

  let {
    currentOrganization,
    referenceData,
    currentDefaultFormatDetails,
    space,
  } = useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { user_comments: "" },
  });

  //   DROP ZONE

  const fileSize = 10000000;
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 1MB`,
      };
    }
  }

  let letterId = data && data._id;

  const putLetterDatas = useMutation(
    (data) =>
      putLetterAction({
        organization: currentOrganization,
        customerId: customerId,
        letterId: letterId,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        queryClient.refetchQueries([`${LETTER_STATUS_}${customerId}`]);
        setDrawer(LETTER_STATUS);
        dispatch(addLetterStatusEmailAttachment([]));
      },
    }
  );

  const postLetterPreview = useMutation(
    (data) => {
      return letterPreviewPost({
        customerId: customerId,
        organization: currentOrganization,
        ...data,
      });
    },
    {
      onSuccess: (data, variables, context) => {},
    }
  );

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: {
        "image/jpg": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      validator: uploadLogoValidation,

      onDrop: (acceptedFiles) => {
        let arrObj = acceptedFiles.map((fileDoc) => {
          let { path, type } = fileDoc;

          let splitType = type.split("/");

          return {
            name: path,
            type: splitType[1],
            mimeType: type,
            fileDoc,
          };
        });
        dispatch(addingLetterStatusDeskDocument(arrObj));
      },
    });

  useEffect(() => {
    if (data) {
      setValue("delivery_method", setSelectValues(data.delivery_method));
      setValue(
        "tracking_url",
        data.tracking_url !== "undefined" ? data.tracking_url : ""
      );
      setValue("delivery_status", setSelectValues(data.delivery_status));
      setValue(
        "user_comments",
        data?.comments !== "undefined" ? data?.comments : ""
      );
      // if (data && data.attachments && data.attachments.length > 0) {
      //   let attachData = [];
      //   data &&
      //     data.attachments.map((e) => {
      //       let splitType = e && e.name.split(".");

      //       return attachData.push({
      //         name: e.name,
      //         fileRef: e.ref,
      //         _id: e._id,
      //         type: splitType[1],
      //       });
      //     });

      //   dispatch(addLetterStatusEmailAttachment(attachData));
      // }
      // setValue("user_comments", data.comments);
    }
  }, [data]);

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  const onSubmit = async (data) => {
    let values = {
      delivery_method: getSelectValues(data.delivery_method),
      tracking_url: data.tracking_url,
      delivery_status: getSelectValues(data.delivery_status),
      comments: data.user_comments,
      attachments: letterAttachments,
    };
    await putLetterDatas.mutateAsync(values);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // height: "100vh",
          width: "600px",
        }}
      >
        {/* ONE STARTS*/}
        <div
          style={{
            marginBottom: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: "6px" }}>
            <LabelLarge>Letter Status Edit</LabelLarge>
            <div></div>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div
          // style={{ paddingTop: "15px" }}
          >
            <div
              style={{
                paddingBottom: "5px",
                // minHeight: "100px",
                overflow: "scroll",
                maxHeight: "240px",
              }}
            >
              {data &&
              data.letter_name &&
              data?.template_id &&
              data?.recipient_id ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "350px",
                      justifyContent: "space-between",
                      border: "1px solid #c7c7c7",
                      borderRadius: "5px",
                      padding: "8px 5px 5px 5px",
                      marginTop: "10px",
                      cursor: "pointer",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                    onClick={async () => {
                      await postLetterPreview.mutateAsync({
                        recipient: data && data.recipient_id,
                        template_id: data && data.template_id,
                      });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div className="file_wraper_icon">
                        <DocFileType />
                        <p
                          className={`file_text_wraper --file_name_position ${"--doc_font_size"}`}
                          style={{ paddingTop: "5px", fontSize: "6px" }}
                        >
                          {"pdf".toUpperCase()}
                        </p>
                      </div>
                      <Typography
                        $style={{
                          color: "#516beb",
                          lineHeight: "15px",
                          color: "#516beb",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          minWidth: "50px",
                          maxWidth: "205px",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data && data.letter_name}
                      </Typography>
                    </div>
                    <div>
                      {postLetterPreview && postLetterPreview.isLoading ? (
                        <>
                          <Spinner $size={SIZE.small} />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {data &&
                data.attachments?.map((attachment, index) => {
                  let { tempId } = attachment;
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "350px",
                        justifyContent: "space-between",
                        border: "1px solid #c7c7c7",
                        borderRadius: "5px",
                        padding: "8px 5px 5px 5px",
                        marginTop: "10px",
                        cursor: "pointer",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div className="file_wraper_icon">
                          <DocFileType />
                          <p
                            className={`file_text_wraper --file_name_position ${"--doc_font_size"}`}
                            style={{ paddingTop: "5px", fontSize: "6px" }}
                          >
                            {"pdf".toUpperCase()}
                          </p>
                        </div>
                        <Typography
                          $style={{
                            color: "#516beb",
                            lineHeight: "15px",
                            color: "#516beb",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            minWidth: "50px",
                            maxWidth: "205px",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {attachment && attachment.name ? attachment.name : ""}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
            </div>
            <Controller
              control={control}
              name="delivery_method"
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "delivery_method",
                  })}
                  placeholder={intl.formatMessage({
                    id: "delivery_method",
                  })}
                  size={SIZE.compact}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={referenceData["letter_delivery_method_list"]}

                  // options={data.map((i) => {
                  //   return {
                  //     label: i.name,
                  //     id: i._id,
                  //     frequency: i.frequency,
                  //   };
                  // })}
                />
              )}
            />
            {/* <Controller
                control={control}
                name="tracking_url"
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "tracking_url",
                    })}
                    placeholder={intl.formatMessage({
                      id: "tracking_url",
                    })}
                    size={SIZE.compact}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    // options={data.map((i) => {
                    //   return {
                    //     label: i.name,
                    //     id: i._id,
                    //     frequency: i.frequency,
                    //   };
                    // })}
                  />
                )}
              />{" "} */}
            <Controller
              // defaultValue={""}
              name="tracking_url"
              control={control}
              // rules={{ required: "Required" }}
              render={({ field }) => (
                <TextBox
                  {...field}
                  // hidden={true}
                  // type="number"
                  name={field.name}
                  // requiredAstric={true}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "tracking_url",
                  })}
                  placeholder={intl.formatMessage({
                    id: "tracking_url",
                  })}
                  // onChange={(e) => {
                  //   field.onChange(e.value);
                  // }}
                  size={SIZE.compact}
                  value={field.value}
                />
              )}
            />
            <Controller
              control={control}
              name="delivery_status"
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "delivery_status",
                  })}
                  placeholder={intl.formatMessage({
                    id: "delivery_status",
                  })}
                  size={SIZE.compact}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  // options={data.map((i) => {
                  //   return {
                  //     label: i.name,
                  //     id: i._id,
                  //     frequency: i.frequency,
                  //   };
                  // })}
                  options={referenceData["letter_delivery_status"]}
                />
              )}
            />
            <Controller
              name="user_comments"
              control={control}
              render={({ field }) => (
                <TextArea
                  {...field}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "user_comments",
                  })}
                  placeholder={intl.formatMessage({
                    id: "user_comments",
                  })}
                  value={field.value}
                />
              )}
            />
          </div>

          {data &&
            data.attachment?.map((attachment, index) => {
              let { tempId } = attachment;
              return (
                <EmailAttachment
                  key={tempId}
                  {...attachment}
                  id={customerId}
                  currentOrganization={currentOrganization}
                  entity={"customer"}
                  updatingDeskUpload={updatingLetterStatusDeskDocument}
                  removeAttchFile={unSelectingLetterStatusDeskDocument}
                  subCategory={data && data?.sub_category}
                  isClose={false}
                />
              );
            })}

          {selectedDocumentLetterStatusData &&
            selectedDocumentLetterStatusData?.map((attachment, index) => {
              let { tempId } = attachment;
              return (
                <EmailAttachment
                  key={tempId}
                  {...attachment}
                  id={customerId}
                  currentOrganization={currentOrganization}
                  entity={"customer"}
                  updatingDeskUpload={updatingLetterStatusDeskDocument}
                  removeAttchFile={unSelectingLetterStatusDeskDocument}
                  subCategory={data && data?.sub_category}
                />
              );
            })}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // marginTop: "23px",
            }}
          >
            <TextButton
              kind={KIND.tertiary}
              size={SIZE.mini}
              onClick={() => {
                open();
              }}
              type="button"
            >
              <div style={{ paddingRight: "4px", paddingTop: "2px" }}>
                <PlusLetter />
              </div>
              {/* <Plus /> */}
              Add Attachment
            </TextButton>
            <div style={{ display: "flex", gap: "8px" }}>
              <TextButton
                kind={KIND.tertiary}
                size={SIZE.mini}
                type="button"
                onClick={() => {
                  dispatch(addLetterStatusEmailAttachment([]));
                  setDrawer(LETTER_STATUS);
                }}
              >
                Cancel
              </TextButton>
              <TextButton
                kind={KIND.primary}
                size={SIZE.mini}
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Submit
              </TextButton>
            </div>
          </div>
        </form>

        {/* FORM ENDS */}
      </div>
    </>
  );
};

export default LetterStatusEdit;
