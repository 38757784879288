import React from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { isOpenModal } from "../../redux/actions";

function InvoiceCustomerHyperlink(props) {
  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;
  value = _.get(original, accessor, "");

  let dispatch = useDispatch();

  const navigate = useNavigate();

  const [visible, setVisible] = React.useState(false);
  const [addSearchParams, setAddSearchParams] = useSearchParams();

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  return (
    <>
      {visible ? (
        <StatefulTooltip accessibilityType={"tooltip"} content={value}>
          <Paragraph2
            ref={ref}
            onClick={() => {
              navigate({
                pathname: "/invoices",
                search: `?inv=${original._id}`,
              });
              // setAddSearchParams({ inv: `${original._id}` });
              dispatch(isOpenModal(false));
            }}
            $style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
              width: "100%",
              textAlign: alignment,
              margin: "5px",
              color: "#516beb",
              textDecoration: "underline",
            }}
          >
            <div className="elip" ref={ref}>
              {value}
            </div>
          </Paragraph2>
        </StatefulTooltip>
      ) : (
        <Paragraph2
          ref={ref}
          onClick={() => {
            navigate({
              pathname: "/invoices",
              search: `?inv=${original._id}`,
            });
            // setAddSearchParams({ inv: `${original._id}` });
            dispatch(isOpenModal(false));
          }}
          $style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
            width: "100%",
            textAlign: alignment,
            margin: "5px",
            color: "#516beb",
            textDecoration: "underline",
          }}
        >
          {value}
        </Paragraph2>
      )}
    </>
  );
}
export default InvoiceCustomerHyperlink;
