import React, { useContext, useEffect, useMemo, useRef } from "react";
import "../../assets/scss/components/notification.scss";
import { HeadingXSmall, HeadingXLarge } from "baseui/typography";
import { getChatSingle } from "../../services";
import { useQuery } from "react-query";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { CHATBOT_CHAT_BODY, CUSTOMER_OVERVIEW } from "../../constants";
import moment from "moment";
import { FormattedTime } from "react-intl";
import _ from "lodash";
import { Icon, Typography } from "../../components_v2";

function ChatBodyList(props) {
  let { closeSideBar, setLogin } = props;

  let { currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  const { data } = useQuery(
    `${CHATBOT_CHAT_BODY}-${props?.data?.customer_id}-${props?.data?.chat_id}`,
    async () => {
      return await getChatSingle({
        organization: currentOrganization,
        customerId: props?.data?.customer_id,
        chatId: props?.data?.chat_id,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const messageList = useMemo(() => {
    let list = [];
    if (data && data.data) {
      let { docs = [] } = data.data;
      let newMessages = [...docs];
      list = newMessages;
    }
    return list;
  }, [data]);

  let lateDate = null;

  // let today = new Date();
  // let dateValue = today.valueOf();
  // let dateOf = moment
  //   .utc(dateValue)
  //   .tz(timeZone)
  //   .format(currentDefaultFormatDetails?.date_format);

  let unReadTag = false;

  let chatContainer = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
    }, 700);
  }, [props?.data?.customer_id]);

  return (
    <div style={{ height: "100%", overflow: "hidden" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "9px",
            marginLeft: "14px",
          }}
        >
          <Icon icon="chat" />
          <HeadingXLarge>Chat</HeadingXLarge>
        </div>
        <Icon
          icon="remove"
          isPressable
          onClick={() => {
            closeSideBar();
            setLogin(false);
          }}
        />
      </div>

      <div
        key={0}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 55px)",
          }}
        >
          <div className="chat_body_container" ref={chatContainer}>
            <>
              {messageList.map((e) => {
                let isDateTag = false;
                let formattedDate = moment
                  .utc(e.ctime)
                  ?.tz(currentDefaultFormatDetails.time_zone)
                  ?.format(currentDefaultFormatDetails?.date_format);
                if (formattedDate != lateDate) {
                  isDateTag = true;
                  lateDate = formattedDate;
                }

                let unReadStatus = e.status;

                let showReadStatus = false;
                if (!unReadTag && unReadStatus === "UNREAD") {
                  showReadStatus = true;
                  unReadTag = true;
                }
                return (
                  <>
                    {isDateTag && (
                      <div
                        style={{
                          display: "flex",
                          flex: "1",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            backgroundColor: "#FEFEFE",
                            color: "#787878",
                            padding: "4px 8px",
                            borderRadius: "5px",
                            margin: "8px 3px",
                          }}
                        >
                          {lateDate}
                        </p>
                      </div>
                    )}

                    <div className={e.role == "u" && "--whatsapp_msg"}>
                      <div
                        className={`notification ${
                          e.role == "u"
                            ? "---chat-box-replay"
                            : "---chat-box-sender"
                        }`}
                      >
                        {e && e.content && (
                          <Typography
                            style={{
                              whiteSpace: "break-spaces",
                              marginTop: "5px",
                            }}
                          >
                            {e.content}
                          </Typography>
                        )}
                        <div className="timeline_chat_time_status">
                          <HeadingXSmall>
                            <FormattedTime value={new Date(e.ctime)} />
                          </HeadingXSmall>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatBodyList;
