import React, { useContext, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import { Icon, Typography } from "../../components_v2";
import { toast } from "react-toastify";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { nextCustomerFn, worklistOverAllCount } from "../../services";
import { useMutation, useQuery } from "react-query";
import queryClient from "../../providers/queryClient";
import { useDispatch, useSelector } from "react-redux";
import { setWorklistData } from "../../redux/worklist/action";
import { useNavigate } from "react-router-dom";
import { IconButton, KIND } from "../../components/IconButton";

const HeaderContiner = () => {
  let dispatch = useDispatch();
  const navigator = useNavigate();
  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  const onSuccessRefetch = () => {
    queryClient.invalidateQueries([
      `WORKLIST_WORK_COUNT-${currentOrganization}`,
    ]);
    queryClient.invalidateQueries([`WORKLIST_MAIN-${currentOrganization}`]);
    toast.success("Data Refresh Successful");
  };

  const { worklists } = useSelector((e) => e.worklist.worklistData);

  const { isLoading, data, isFetching } = useQuery(
    [`WORKLIST_WORK_COUNT-${currentOrganization}`],
    async () => {
      return await worklistOverAllCount({
        organization: currentOrganization,
        time_zone: currentDefaultFormatDetails.time_zone,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data?.data?.doc) {
      dispatch(setWorklistData(data?.data?.doc));
    } else {
      let value = {
        _id: null,
      };
      dispatch(setWorklistData(value));
    }
  }, [isFetching]);

  const nextCustomer = useMutation(
    (checkInData) =>
      nextCustomerFn({
        ...checkInData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        if (data?.data?.doc?.customer_id) {
          navigator(`/customers/${data?.data?.doc?.customer_id}`);
        }
      },
    }
  );

  return (
    <div className="wl_header_container">
      <div className="wl_title_ct">
        <PageTitle id="worklist" />
        <Icon
          icon="referesh"
          size={16}
          isPressable
          onClick={() => {
            onSuccessRefetch();
          }}
        />
      </div>
      <div className="wl_record_container">
        {data?.data?.doc?.count && (
          <>
            <Typography type="h5" className="text-secondary">
              {data?.data?.doc?.process_count
                ? data?.data?.doc?.process_count
                : 0}
              /{data?.data?.doc?.count ? data?.data?.doc?.count : 0} Worked
            </Typography>
            <IconButton
              size="mini"
              kind={KIND.tertiary}
              isLoading={nextCustomer.isLoading}
              disabled={nextCustomer.isLoading}
              onClick={() => {
                nextCustomer.mutateAsync({
                  worklists: [...worklists],
                });
              }}
            >
              <Icon icon="play_svg" />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderContiner;
