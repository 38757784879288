import React, { useEffect, useContext, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ModalBody, ModalFooter, SIZE } from "baseui/modal";
import { HeadingXLarge, LabelLarge } from "baseui/typography";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useParams } from "react-router";

import { IconButton, KIND } from "../../components/IconButton";
import { TextBox } from "../../components/TextBox";
import { TextButton, KIND as kinds } from "../../components/TextButton";
import { SelectBox } from "../../components/SelectBox";
import PlusContact from "../../assets/img/svg/CustomerOverViewIcon/PlusContact";
import getSelectValues from "../../utils/getSelectValues";
import * as TextBoxSize from "../../components/TextBox";
import {
  useCustomerContact,
  useUpdateCustomerContact,
} from "../../services/customerOverviewContacts";
import setSelectValues from "../../utils/setSelectValues";
import { CUSTOMER_OVERVIEW_, VIEW } from "../../constants";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  alloweSpecialCharacter,
  constactEmailRegex,
  nameRegex,
} from "../../utils/regexConfig";
import ValidCallSvg from "../../assets/img/svg/ValidCallSvg";
import Swap from "../../assets/img/svg/Swap";
import ContactLocation from "../../assets/img/svg/ContactLocation";
import DeleteIcon from "../../assets/img/svg/DeleteIcon";
import {
  ADD_LIST_VALUES,
  UPDATE_CONTACT_ADDRESS,
  UPDATE_CONTACT_EMAIL,
  UPDATE_CONTACT_PHONE,
} from "../../providers/RBACProvider/permissionList";
import PhoneInputFields from "../../containers/CustomerOverView/PhoneInputFields";
import ConatctEmailFields from "../../containers/CustomerOverView/ConatctEmailFields";
// import CustomFieldsTab from "../../components/CustomFields/CustomFieldsTab";
import { EDIT_CONTACT, setDrawerState } from "../../redux/actions";
import CustomFieldsTab from "./CustomFields/CustomFieldsTab";
import moment from "moment";
import { Icon, Typography } from "../../components_v2";
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { TextArea } from "../../components/TextArea";
import useFormat from "../../hooks/useFormat";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import queryClient from "../../providers/queryClient";
import VoiceBotIcon from "../../assets/img/svg/VoiceBotIcon";

const AddContact = (props) => {
  const { customerId } = useParams();
  let maxyfiContextValue = useContext(MaxyfiContext);
  const {
    referenceData,
    defaultData,
    currentOrganization,
    customContact,
    space,
    refetch,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;

  const dispatch = useDispatch();

  // adding for overview V2

  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };
  const [commentOpen, setCommentOpen] = useState({});
  const [addressComment, setAddressCommentOpen] = useState({});
  const { type, data } = useSelector((e) => e.customerOverviewDrawer);
  const [textOpenComment, setTextOpenCommentOpen] = useState({});
  const [textLandline, setTextLandline] = useState({});
  const toggleCommentOpen = (index) => {
    setCommentOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const cancelEdit = (index, comment) => {
    setCommentOpen((prev) => ({ ...prev, [index]: undefined }));
    setValue(`landline[${index}].comment`, comment);
  };
  const cancelEditAdress = (index, comment) => {
    setAddressCommentOpen((prev) => ({ ...prev, [index]: undefined }));
    setValue(`address[${index}].comment`, comment);
  };
  const toggleAddressCommentOpen = (index) => {
    setAddressCommentOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };
  const intl = useIntl();

  const findFullWidth = useRef(null);

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);
  const updateContactMobile =
    space && space?.role_permissions?.includes(UPDATE_CONTACT_PHONE);
  const updateContactAddress =
    space && space?.role_permissions?.includes(UPDATE_CONTACT_ADDRESS);
  const updateContactEmail =
    space && space?.role_permissions?.includes(UPDATE_CONTACT_EMAIL);
  const schema = Yup.object().shape(
    {
      first_name: Yup.string()
        .required("First Name is Mandatory")
        .max(100, "Character Length can't be more than 100")
        .matches(
          alloweSpecialCharacter,
          "Invalid input - Only Alphabets ' , . - and _ allowed."
        ),
      last_name: Yup.string()
        .nullable()
        .max(100, "Character Length can't be more than 100")
        .matches(alloweSpecialCharacter, {
          message: "Invalid input - Only Alphabets ' , . - and _ allowed.",
          excludeEmptyString: true,
        }),
      designation: Yup.array().min(1, "Required").required("Role is Mandatory"),
      phone: Yup.array().of(
        Yup.object(
          {
            value: Yup.string()
              .test("phone", "Invaild Phone Number", (value, ctr) => {
                if (
                  ctr.parent.country_code &&
                  ctr.parent.country_code[0] &&
                  ctr.parent.country_code[0].id &&
                  ctr?.parent?.status?.[0]?.id == "VALID"
                ) {
                  let NumberValid = isValidPhoneNumber(
                    `${ctr.parent.country_code[0].id}${value}`
                  );

                  return NumberValid;
                } else if (value && ctr?.parent?.status?.[0]?.id == "INVALID") {
                  return true;
                } else {
                  return true;
                }
              })
              .nullable()
              .optional(),

            status: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (value) => {
                  return value && value?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),

            country_code: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (phone) => {
                  //
                  return phone && phone?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),
          },
          ["country_code", "status", "value"]
        )
      ),

      email: Yup.array().of(
        Yup.object(
          {
            value: Yup.string()
              .optional()
              .test("email", "Invalid Email ID", (value, ctr) => {
                if (value && ctr?.parent?.status?.[0]?.id == "VALID") {
                  let emailValue = value?.match(constactEmailRegex);
                  return emailValue;
                } else if (value && ctr?.parent?.status?.[0]?.id == "INVALID") {
                  return true;
                } else {
                  return true;
                }
              }),

            status: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (value) => {
                  return value && value?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),
          },
          ["status", "value"]
        )
      ),

      landline: Yup.array().of(
        Yup.object(
          {
            value: Yup.string()
              .test("phone", "Invaild Phone Number", (value, ctr) => {
                if (
                  ctr.parent.country_code &&
                  ctr.parent.country_code[0] &&
                  ctr.parent.country_code[0].id &&
                  ctr?.parent?.status?.[0]?.id == "VALID"
                ) {
                  let NumberValid = isValidPhoneNumber(
                    `${ctr.parent.country_code[0].id}${value}`
                  );

                  return NumberValid;
                } else if (value && ctr?.parent?.status?.[0]?.id == "INVALID") {
                  return true;
                } else {
                  return true;
                }
              })
              .nullable()
              .optional(),

            status: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (value) => {
                  return value && value?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),

            country_code: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (phone) => {
                  //
                  return phone && phone?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),
          },
          ["country_code", "status", "value"]
        )
      ),
    },
    [["email", "phone"]]
  );

  const [disabledPhoneFields, setPhoneDisabledFields] = useState([]);
  const [disabledLandlineFields, setLandlineDisabledFields] = useState([]);
  const [disabledEmailFields, setEmailDisabledFields] = useState([]);
  const [disabledAddressFields, setAddressDisabledFields] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      phone: [
        {
          country_code: setSelectValues(defaultData?.phone_code),
          type: [{ id: "PERSONAL_MOBILE", label: "Personal" }],
          status: [{ id: "VALID", label: "Valid" }],
          value: "",
        },
      ],
      landline: [
        {
          country_code: setSelectValues(defaultData?.phone_code),
          type: [{ id: "OFFICE_LANDLINE", label: "Office" }],
          status: [{ id: "VALID", label: "Valid" }],
          value: "",
        },
      ],
      email: [
        {
          type: [{ id: "PERSONAL_EMAIL", label: "Personal" }],
          status: [{ id: "VALID", label: "Valid" }],
          value: "",
        },
      ],
      address: [
        {
          type: [{ id: "OFFICE_ADDRESS", label: "Office" }],
          line_1: "",
          line_2: "",
          country: "",
          state: [],
          city: "",
          zip_code: "",
          status: [{ id: "VALID", label: "Valid" }],
        },
      ],
    },
  });

  let { relation } = watch();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "phone",
  });

  const landlineArray = useFieldArray({
    control,
    name: "landline",
  });

  const emailArray = useFieldArray({
    control,
    name: "email",
  });

  const addressArray = useFieldArray({
    control,
    name: "address",
  });

  let phoneFileds = fields;

  const isDisable = type === VIEW ? true : false;

  useEffect(() => {
    if (type === EDIT_CONTACT) {
      const fieldsMain = Object.keys(data);

      fieldsMain.map((field) => {
        if (field === "designation" || field === "relation") {
          setValue(field, setSelectValues(data[field]));
        } else if (typeof data[field] == "string") {
          setValue(field, data[field]);
        } else {
        }
      });
    }
  }, []);

  useEffect(() => {
    if (data && type === EDIT_CONTACT) {
      const updatedPhoneDisabledFields = _.get(data, "phone", []).map((e) => {
        return (
          isDisable ||
          (_.get(e, "value", "")
            ? updateContactMobile
              ? !updateContactMobile
              : true
            : false)
        );
      });
      setPhoneDisabledFields(updatedPhoneDisabledFields);
      data.phone.map((ph, i) => {
        update(i, {
          type: setSelectValues(ph?.type),
          value: ph?.value,
          country_code: setSelectValues(ph?.country_code),
          status: setSelectValues(ph?.status),
          sms_outcome: ph?.sms_outcome,

          sms_status_updated_at: ph?.sms_status_updated_at,
          sms_success_percentage: ph?.sms_success_percentage,
          call_outcome: ph?.call_outcome,
          call_status: ph?.call_status,
          call_status_updated_at: ph?.call_status_updated_at,
          call_success_percentage: ph?.call_success_percentage,
          whatsapp_outcome: ph?.whatsapp_outcome,
          whatsapp_status: setSelectValues(
            _.get(ph, "whatsapp_status", ""),
            false
          ),
          ac_sts: setSelectValues(_.get(ph, "ac_sts", ""), false),
          whatsapp_status_updated_at: ph?.whatsapp_status_updated_at,
          whatsapp_success_percentage: ph?.whatsapp_success_percentage,
          sms_status: setSelectValues(_.get(ph, "sms_status", ""), false),
          comment: _.get(ph, "comment", ""),
          aby: _.get(ph, "aby", ""),
          aat: _.get(ph, "aat", ""),
          ...(_.get(ph, "lst_cl_dt", null)
            ? { lst_cl_dt: _.get(ph, "lst_cl_dt", null) }
            : {}),
          ...(_.get(ph, "cl_suc_cnt", null)
            ? { cl_suc_cnt: Number(_.get(ph, "cl_suc_cnt", null)) }
            : {}),
          ...(_.get(ph, "cl_cnt", null)
            ? { cl_cnt: Number(_.get(ph, "cl_cnt", null)) }
            : {}),
          ...(_.get(ph, "lst_cc", "")
            ? { lst_cc: _.get(ph, "lst_cc", "") }
            : {}),
          ...(ph?.cat ? { cat: _.get(ph, "cat", "") } : {}),
        });
      });
      const updatedLandlineDisabledFields = _.get(data, "landline", []).map(
        (e) => {
          return (
            isDisable ||
            (_.get(e, "value", "")
              ? updateContactMobile
                ? !updateContactMobile
                : true
              : false)
          );
        }
      );
      setLandlineDisabledFields(updatedLandlineDisabledFields);
      data.landline.map((ld, i) => {
        landlineArray.update(i, {
          type: setSelectValues(ld?.type),
          value: ld?.value,
          country_code: setSelectValues(ld?.country_code),
          status: setSelectValues(ld?.status),
          call_outcome: ld?.call_outcome,
          call_status: ld?.call_status,
          call_status_updated_at: ld?.call_status_updated_at,
          call_success_percentage: ld?.call_success_percentage,
          comment: _.get(ld, "comment", ""),
          ac_sts: setSelectValues(_.get(ld, "ac_sts", ""), false),
          aby: _.get(ld, "aby", ""),
          aat: _.get(ld, "aat", ""),
          ...(_.get(ld, "lst_cl_dt", null)
            ? { lst_cl_dt: _.get(ld, "lst_cl_dt", null) }
            : {}),
          ...(_.get(ld, "cl_suc_cnt", null)
            ? { cl_suc_cnt: Number(_.get(ld, "cl_suc_cnt", null)) }
            : {}),
          ...(_.get(ld, "cl_cnt", null)
            ? { cl_cnt: Number(_.get(ld, "cl_cnt", null)) }
            : {}),
          ...(_.get(ld, "lst_cc", "")
            ? { lst_cc: _.get(ld, "lst_cc", "") }
            : {}),
          ...(ld?.cat ? { cat: _.get(ld, "cat", "") } : {}),
        });
      });
      const updatedEmailDisabledFields = _.get(data, "email", []).map((e) => {
        return (
          isDisable ||
          (_.get(e, "value", "")
            ? updateContactEmail
              ? !updateContactEmail
              : true
            : false)
        );
      });
      setEmailDisabledFields(updatedEmailDisabledFields);
      data.email.map((em, i) => {
        emailArray.update(i, {
          type: setSelectValues(em?.type),
          value: em?.value,
          status: setSelectValues(em?.status),
          email_status: setSelectValues(em?.email_status),
          email_outcome: em?.email_outcome,
          email_status_updated_at: em?.email_status_updated_at,
          email_success_percentage: em?.email_success_percentage,
          comment: _.get(em, "comment", ""),
          aby: _.get(em, "aby", ""),
          aat: _.get(em, "aat", ""),
          ...(em?.cat ? { cat: _.get(em, "cat", "") } : {}),
        });
      });
      const updatedAddressDisabledFields = _.get(data, "address", []).map(
        (e) => {
          return (
            isDisable ||
            (_.get(e, "line_1", "")
              ? updateContactAddress
                ? !updateContactAddress
                : true
              : false)
          );
        }
      );
      setAddressDisabledFields(updatedAddressDisabledFields);
      data.address.map((em, i) => {
        addressArray.update(i, {
          type: em?.type
            ? setSelectValues(em?.type)
            : setSelectValues("OFFICE_ADDRESS"),
          country: setSelectValues(em?.country),
          line_1: em?.line_1,
          line_2: em?.line_2,
          state: setSelectValues(em?.state),
          city: em?.city,
          zip_code: em?.zip_code,
          status: em?.status
            ? setSelectValues(em?.status)
            : setSelectValues("VALID"),
          comment: _.get(em, "comment", ""),
          aby: _.get(em, "aby", ""),
          aat: _.get(em, "aat", ""),
          ...(em?.cat ? { cat: _.get(em, "cat", "") } : {}),
        });
      });
    }
  }, []);

  const { mutateAsync } = useCustomerContact();

  const updateContactData = useUpdateCustomerContact();

  let contactId = data._id;

  // Function to check if any of the keys have a value
  const hasValue = (obj) => {
    return obj && typeof obj === "object"
      ? Object.values(obj).some((value) => value !== null && value !== "")
      : false;
  };

  const onSubmit = async (data) => {
    // Create a deep copy of the data object to avoid mutating the original data
    let finalValues = { ...data };

    //Remove the phone abby and aat
    finalValues.phone = finalValues.phone.map((phoneObj) => {
      const { aby, aat, ...rest } = phoneObj;
      return rest;
    });
    //Remove the Email abby and aat
    finalValues.email = finalValues.email.map((phoneObj) => {
      const { aby, aat, ...rest } = phoneObj;
      return rest;
    });
    //Remove the Address abby and aat
    finalValues.address = finalValues.address.map((phoneObj) => {
      const { aby, aat, ...rest } = phoneObj;
      return rest;
    });
    //Remove the Landline abby and aat
    finalValues.landline = finalValues.landline.map((phoneObj) => {
      const { aby, aat, ...rest } = phoneObj;
      return rest;
    });

    let customFieldData = {};

    for (let i = 0; i < customContact.length; i++) {
      let path = customContact?.[i]?.path;
      let dataType = customContact?.[i]?.data_type;
      let isVisible = customContact?.[i]?.is_hidden;
      let keys = path.split(".");
      let current = data;
      for (let j = 0; j < keys.length; j++) {
        if (current.hasOwnProperty(keys[j])) {
          current = current[keys[j]];
        }
      }

      if (!isVisible) {
        if (dataType === "DATE") {
          if (current) {
            customFieldData[path] = moment
              .tz(
                `${current?.getFullYear()}-${
                  current?.getMonth() + 1
                }-${current?.getDate()}`,
                "YYYY-MM-DD",
                currentDefaultFormatDetails.time_zone
              )
              .utc()
              .valueOf();
          }
        } else if (dataType === "AMOUNT") {
          if (current.value) {
            customFieldData[path] = current;
          }
        } else if (
          dataType === "TEXT" &&
          customContact?.[i]?.options?.length > 0 &&
          current
        ) {
          customFieldData[path] = current && getSelectValues(current);
        } else {
          if (current) {
            customFieldData[path] = current;
          }
        }
      }
    }

    let phoneList = finalValues?.phone
      ?.map((value) => {
        let newValue = { ...value };
        newValue.type = getSelectValues(value?.type, false);
        newValue.country_code = getSelectValues(value?.country_code, false);
        newValue.status = getSelectValues(value?.status, false);
        newValue.sms_status = getSelectValues(value?.sms_status, false);
        newValue.call_status = Array.isArray(value?.call_status)
          ? getSelectValues(value?.call_status, false)
          : value?.call_status;
        newValue.whatsapp_status = getSelectValues(
          value?.whatsapp_status,
          false
        );
        newValue.ac_sts = getSelectValues(value?.ac_sts, false);
        newValue.comment = value?.comment;
        return newValue;
      })
      .filter(({ value }) => value);

    let landlineList = finalValues?.landline
      ?.map((value) => {
        let newValue = { ...value };
        newValue.type = getSelectValues(value.type, false);
        newValue.country_code = getSelectValues(value.country_code, false);
        newValue.status = getSelectValues(value.status, false);
        newValue.comment = value.comment;
        newValue.ac_sts = getSelectValues(value.ac_sts, false);
        return newValue;
      })
      .filter(({ value }) => value);

    let emailList = finalValues?.email
      ?.map((value) => {
        let newValue = { ...value };
        newValue.type = getSelectValues(value.type, false);
        newValue.status = getSelectValues(value.status, false);
        newValue.email_status = getSelectValues(value.email_status, false);
        newValue.comment = value.comment;
        return newValue;
      })
      .filter(({ value }) => value);

    const addressList = finalValues?.address
      ?.map((value) => {
        let newValue = { ...value };
        newValue.country = getSelectValues(value?.country);
        newValue.status = getSelectValues(value?.status);
        newValue.type = getSelectValues(value?.type);
        newValue.state = getSelectValues(value?.state);
        newValue.zip_code = value?.zip_code?.trim();
        newValue.comment = value?.comment?.trim();
        return newValue;
      })
      .filter(
        ({ city, line_1, line_2, state, zip_code }) =>
          city || line_1 || line_2 || state || zip_code
      );
    let values = {
      first_name: finalValues.first_name,
      // ...(finalValues.last_name ? {
      last_name: finalValues?.last_name,
      // } : {}),
      designation: getSelectValues(finalValues?.designation, false),
      // ...(finalValues.relation
      //   ? {
      relation:
        finalValues?.relation?.length > 0
          ? getSelectValues(finalValues?.relation, false)
          : "",
      // }
      // : {}),
      ...(phoneList?.length === 0 ? {} : { phone: phoneList }),
      ...(landlineList?.length === 0 ? {} : { landline: landlineList }),
      ...(emailList?.length === 0 ? {} : { email: emailList }),
      ...(addressList?.length === 0 ? {} : { address: addressList }),
      ...(hasValue(customFieldData)
        ? { custom_field: { ...customFieldData } }
        : {}),
    };
    delete values._id;
    delete values.entity;
    delete values.primary_invoices;
    delete values.esclation_invoices;

    // let phoneValue = values?.phone?.map((i) => !i?.value);

    if (type === EDIT_CONTACT) {
      delete values.customerId;
      delete values.created_file_id;
      delete values.updated_file_id;
      values.phone = values.phone?.filter((i) => i.value);
      values.landline = values.landline?.filter((i) => i.value);
      values.email = values.email?.filter((i) => i.value);
      values.address?.map((val, i) => {
        delete values.address[i]._id;
      });
      values.phone?.map((val, i) => {
        return delete values.phone[i]._id;
      });
      values?.landline?.map((val, i) => {
        return delete values?.landline[i]?._id;
      });
      values?.email?.map((val, i) => {
        return delete values?.email?.[i]?._id;
      });

      values?.phone?.map((val, i) => delete values.phone[i]._id);

      await updateContactData.mutateAsync(
        {
          values,
          customerId: customerId,
          organization: currentOrganization,
          contactId: contactId,
        },
        {
          onError: (error) => {
            if (error.response) {
              let { data } = error.response;
              toast.error(data.message);
            }
          },
          onSuccess: (data) => {
            if (relation && relation[0] && relation[0].isCreatable) {
              refetch({
                setLoader: false,
                currentOrganization: currentOrganization,
              });
            }
            queryClient.invalidateQueries({
              queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
            });
            reset();
            closeDrawer();
          },
        }
      );
    } else {
      await mutateAsync(
        {
          values,
          customerId: customerId,
          organization: currentOrganization,
        },
        {
          onError: (error) => {
            if (error.response) {
              let { data } = error.response;
              toast.error(data.message);
            }
          },
          onSuccess: (data) => {
            if (relation && relation[0] && relation[0].isCreatable) {
              refetch({
                setLoader: false,
                currentOrganization: currentOrganization,
              });
            }
            queryClient.invalidateQueries({
              queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
            });
            reset();
            closeDrawer();
          },
        }
      );
    }
  };

  const format = useFormat();
  const [textCommentOpen, setTextCommentOpen] = useState(false);
  // const [cancelEdit, setCancelEdit] = useState(false);
  const [deleteComment, setDeleteComment] = useState(false);
  const handleDeleteComment = (val) => {
    setValue(val, "");
  };

  //Address
  const [textAddressCommentOpen, setTextAddressCommentOpen] = useState(false);
  const [deleteAddressComment, setAddressDeleteComment] = useState(false);
  const handleAddressDeleteComment = (val) => {
    setValue(val, "");
  };

  const handleVoiceStatusChange = (field, status) => {
    const newValue = [{ id: status, label: status }];

    field.onChange(newValue);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            textAlign: "center",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "0px",
            marginBottom: "0px",
          }}
        >
          <HeadingXLarge $style={{ color: "#516BEB" }}>
            {type === EDIT_CONTACT ? (
              <FormattedMessage id="edit_contact_details">
                edit_contact_details
              </FormattedMessage>
            ) : (
              <FormattedMessage id="add_contact_details">
                add_contact_details
              </FormattedMessage>
            )}
          </HeadingXLarge>
        </div>
        <ModalBody
          style={{
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            height: "calc(100vh - 120px)",
            overflow: "scroll",
            // padding: "12px 12px",
            paddingTop: "13px",
          }}
          ref={findFullWidth}
        >
          <div style={{ width: "530px" }}>
            {/* First Name */}
            <div style={{ display: "flex", gap: "5px" }}>
              <Controller
                name="first_name"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <>
                    <TextBox
                      {...field}
                      requiredAstric={true}
                      fullWidth
                      disabled={isDisable}
                      size={TextBoxSize.SIZE.mini}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "first_name",
                      })}
                      placeholder={intl.formatMessage({
                        id: "first_name",
                      })}
                      value={field.value}
                      // onChange={(e) => {
                      //   updateLocalState("first_name", e.target.value);
                      // }}
                    />
                  </>
                )}
              />
              <div>
                <IconButton
                  size="mini"
                  kind="tertiary"
                  type="button"
                  onClick={() => {
                    const [firstName = "", lastName = ""] = getValues([
                      "first_name",
                      "last_name",
                    ]);

                    setValue("first_name", lastName);
                    setValue("last_name", firstName);
                  }}
                >
                  <Swap size={16} />
                </IconButton>
              </div>
              <Controller
                name="last_name"
                control={control}
                rules={{
                  pattern: {
                    message:
                      "Invalid input - Only Alphabets ' , . - and _ allowed.",
                    value: nameRegex,
                  },
                }}
                render={({ field }) => (
                  <>
                    <TextBox
                      {...field}
                      disabled={isDisable}
                      fullWidth
                      name={field.name}
                      size={TextBoxSize.SIZE.mini}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "last_name",
                      })}
                      placeholder={intl.formatMessage({
                        id: "last_name",
                      })}
                      value={field.value}
                    />
                  </>
                )}
              />
            </div>
            {/* Role  */}
            <div style={{ display: "flex", gap: "40px" }}>
              <div style={{ width: "248px" }}>
                <Controller
                  name="designation"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <>
                      <SelectBox
                        {...field}
                        disabled={isDisable}
                        name={field.name}
                        requiredAstric={true}
                        size={TextBoxSize.SIZE.mini}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: `role`,
                        })}
                        placeholder={intl.formatMessage({
                          id: "role",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={
                          referenceData &&
                          referenceData?.recipient_type?.map((i) => {
                            return { label: i.label, id: i.id };
                          })
                        }
                      />
                    </>
                  )}
                />
              </div>
              <div style={{ width: "248px" }}>
                <Controller
                  name="relation"
                  control={control}
                  render={({ field }) => (
                    <>
                      <SelectBox
                        {...field}
                        disabled={isDisable}
                        name={field.name}
                        size={TextBoxSize.SIZE.mini}
                        creatable={creatablePermission ? true : false}
                        // error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: `relation`,
                        })}
                        placeholder={intl.formatMessage({
                          id: "relation",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={
                          referenceData &&
                          referenceData["customer_contact_relation_list"]
                        }
                      />
                    </>
                  )}
                />
              </div>
            </div>
            {/* Phone Heading and Add Phone No. */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <LabelLarge>
                <FormattedMessage id="mobile" style={{ color: "#333860" }}>
                  mobile
                </FormattedMessage>
              </LabelLarge>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "row-reverse",
                }}
              >
                <TextButton
                  disabled={isDisable}
                  kind={KIND.tertiary}
                  size={TextBoxSize.SIZE.mini}
                  type="button"
                  style={{
                    fontSize: "12px",
                    fontWeight: "400px",
                    gap: "2px",
                    display: "flex",
                    // textAlign: "center",
                    justifyContent: "center",
                    alignItem: "center",
                  }}
                  onClick={() =>
                    append({
                      country_code: setSelectValues(defaultData.phone_code),
                      type: [{ id: "PERSONAL_MOBILE", label: "Personal" }],
                      status: [{ id: "VALID", label: "Valid" }],
                      value: "",
                    })
                  }
                >
                  <PlusContact /> Add Another Number
                </TextButton>
              </div>
            </div>

            {/* Phone Inputs */}
            {phoneFileds &&
              phoneFileds.map((e, i) => {
                return (
                  <PhoneInputFields
                    key={e.id}
                    errors={errors}
                    e={e}
                    i={i}
                    disableBasedMobileOnRule={disabledPhoneFields[i]}
                    control={control}
                    setValue={setValue}
                    isDisable={isDisable}
                    findFullWidth={findFullWidth}
                    actionType={type}
                    remove={remove}
                    fields={fields}
                  />
                );
              })}

            {/* landline start */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <LabelLarge>
                <FormattedMessage id="landline" style={{ color: "#333860" }}>
                  landline
                </FormattedMessage>
              </LabelLarge>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "row-reverse",
                }}
              >
                <TextButton
                  disabled={isDisable}
                  kind={KIND.tertiary}
                  size={TextBoxSize.SIZE.mini}
                  type="button"
                  style={{
                    fontSize: "12px",
                    fontWeight: "400px",
                    gap: "2px",
                    display: "flex",
                    // textAlign: "center",
                    justifyContent: "center",
                    alignItem: "center",
                  }}
                  // {
                  //         type: "phone",
                  //         value: { country_code: defaultData.phone_code,value:"",type:"work"},
                  //       }
                  onClick={() => {
                    landlineArray.append({
                      country_code: setSelectValues(defaultData.phone_code),
                      type: [{ id: "OFFICE_LANDLINE", label: "Office" }],
                      status: [{ id: "VALID", label: "Valid" }],
                      value: "",
                    });
                  }}
                >
                  <PlusContact /> Add Another Landline
                </TextButton>
              </div>
            </div>

            {/* Phone Inputs */}
            {landlineArray?.fields?.map((e, i) => (
              <>
                {/* {data.landline.length > 1 ? (
                    <LabelXSmall $style={{ marginBottom: "12px" }}>
                      Landline {[i + 1]}
                    </LabelXSmall>
                  ) : null} */}
                <div style={{ display: "flex", gap: "10px" }} key={e.id}>
                  {/* <div style={{ width: "180px" }}> */}
                  <Controller
                    name={`landline[${i}].type`}
                    control={control}
                    defaultValue={[{ id: "OFFICE_LANDLINE", label: "Office" }]}
                    render={({ field }) => (
                      <div style={{ width: "130px" }}>
                        <SelectBox
                          {...field}
                          disabled={isDisable || disabledLandlineFields[i]}
                          clearable={false}
                          name={field.name}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["landline"] &&
                            errors["landline"][i] &&
                            errors["landline"][i].type &&
                            errors["landline"][i].type.message
                          }
                          label={intl.formatMessage({
                            id: `type_contact`,
                          })}
                          placeholder={intl.formatMessage({
                            id: "type_contact",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={referenceData["contact_landline_type"]}
                        />
                      </div>
                    )}
                  />
                  {/* </div> */}

                  <Controller
                    name={`landline[${i}].country_code`}
                    control={control}
                    defaultValue={
                      type === EDIT_CONTACT && !e.phone_code && e.value
                        ? ""
                        : setSelectValues(defaultData.phone_code)
                      // : actionType === EDIT
                      // ? setSelectValues(defaultData.phone_code)
                      // setSelectValues(defaultData.phone_code)
                    }
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <div
                        style={{ width: "100px" }}
                        onClick={() => {
                          if (findFullWidth && findFullWidth.current) {
                            findFullWidth.current.scrollTop = 50;
                          }
                        }}
                      >
                        <SelectBox
                          {...field}
                          disabled={isDisable || disabledLandlineFields[i]}
                          clearable={false}
                          name={field.name}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["landline"] &&
                            errors["landline"][i] &&
                            errors["landline"][i].country_code &&
                            errors["landline"][i].country_code.message
                          }
                          label={intl.formatMessage({
                            id: "code",
                          })}
                          placeholder={intl.formatMessage({
                            id: "code",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={referenceData["contact_phone_code"]}
                        />
                      </div>
                    )}
                  />
                  {/* </div> */}
                  <Controller
                    name={`landline[${i}].value`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextBox
                          type="number"
                          {...field}
                          disabled={isDisable || disabledLandlineFields[i]}
                          fullWidth
                          name={field.name}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["landline"] &&
                            errors["landline"][i] &&
                            errors["landline"][i].value &&
                            errors["landline"][i].value.message
                          }
                          label={intl.formatMessage({
                            id: "number",
                          })}
                          placeholder={intl.formatMessage({
                            id: "enter_number",
                          })}
                          value={field.value}
                          onKeyDown={(e) => {
                            if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </>
                    )}
                  />
                  <div style={{ width: "180px" }}>
                    <Controller
                      name={`landline[${i}].status`}
                      control={control}
                      defaultValue={[{ id: "VALID", label: "Valid" }]}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          disabled={isDisable}
                          clearable={false}
                          name={field.name}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["landline"] &&
                            errors["landline"][i] &&
                            errors["landline"][i].status &&
                            errors["landline"][i].status.message
                          }
                          label={intl.formatMessage({
                            id: `status`,
                          })}
                          placeholder={intl.formatMessage({
                            id: "status",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={referenceData["contact_status"]}
                        />
                      )}
                    />
                  </div>
                  <div
                    style={{ display: "flex", marginTop: "3px", gap: "5px" }}
                  >
                    {data?.landline?.[i]?.call_status && (
                      <>
                        <ValidCallSvg
                          color={
                            data?.landline?.[i]?.call_status === "VALID"
                              ? "#12b112"
                              : "#FD372A"
                          }
                        />
                      </>
                    )}

                    {e.ac_sts && (
                      <StatefulPopover
                        content={({ close }) => (
                          <Controller
                            name={`landline[${i}].ac_sts`}
                            control={control}
                            defaultValue={[{ id: "VALID", label: "Valid" }]}
                            render={({ field }) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "8px",
                                  padding: "10px",
                                }}
                              >
                                <Typography
                                  type="p"
                                  className="regular"
                                  subType="regular"
                                >
                                  VoiceBot Status
                                </Typography>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                >
                                  {["VALID", "INVALID", "DNC"].map((status) => (
                                    <div
                                      key={status}
                                      style={{
                                        border: `1px solid ${
                                          getSelectValues(
                                            watch(`landline[${i}].ac_sts`)
                                          ) === status
                                            ? "#516BEB"
                                            : "#CDCED9"
                                        }`,
                                        pointerEvents:
                                          getSelectValues(
                                            _.get(e, "ac_sts", []),
                                            false
                                          ) === "DNC"
                                            ? "none"
                                            : "auto",
                                        padding: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleVoiceStatusChange(field, status)
                                      }
                                    >
                                      <Typography
                                        type="p"
                                        className="regular"
                                        style={{
                                          color: `${
                                            getSelectValues(
                                              watch(`landline[${i}].ac_sts`)
                                            ) === status
                                              ? "#516BEB"
                                              : ""
                                          }`,
                                        }}
                                      >
                                        {status === "DNC"
                                          ? status
                                          : _.capitalize(status)}
                                      </Typography>
                                    </div>
                                  ))}
                                </div>
                                <div style={{ border: "1px solid #EEEEEE" }}>
                                  {" "}
                                </div>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <TextButton
                                    size="mini"
                                    kind={KIND.tertiary}
                                    type="button"
                                    onClick={() => {
                                      close();
                                    }}
                                  >
                                    Cancel
                                  </TextButton>
                                  <TextButton
                                    size="mini"
                                    disabled={
                                      getSelectValues(
                                        _.get(e, "whatsapp_status", [])
                                      ) === "DNC"
                                        ? true
                                        : false
                                    }
                                    kind={KIND.primary}
                                    type="button"
                                    onClick={() => {
                                      close();
                                    }}
                                  >
                                    Save
                                  </TextButton>
                                </div>
                              </div>
                            )}
                          />
                        )}
                        returnFocus
                        autoFocus
                        overrides={{
                          Arrow: {
                            style: ({ $theme }) => ({
                              outline: `#CDCED9 solid`,
                              backgroundColor: "#16164B",
                            }),
                          },
                          Inner: {
                            style: ({ $theme }) => ({
                              width: "158px",
                              height: "112px",
                              backgroundColor: "#FFFFFF",
                              borderRadius: "5px",
                            }),
                          },
                        }}
                        placement={PLACEMENT.bottomLeft}
                      >
                        <div style={{ cursor: "pointer" }}>
                          <VoiceBotIcon
                            color={
                              getSelectValues(
                                watch(`landline[${i}].ac_sts`)
                              ) === "VALID"
                                ? "#12b112"
                                : "#FD372A"
                            }
                          />
                        </div>
                      </StatefulPopover>
                    )}
                    <StatefulPopover
                      content={({ close }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2px",
                          }}
                        >
                          {textLandline[i] !== undefined ? (
                            <></>
                          ) : (
                            <>
                              {!e.comment && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    gap: "4px",
                                    margin: "4px 0px",
                                  }}
                                >
                                  <div style={{ paddingLeft: "5px" }}>
                                    <Icon icon="contact_chat_icon" />
                                  </div>

                                  <Typography
                                    type="p"
                                    subType="medium"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      toggleCommentOpen(i);
                                      close();
                                    }}
                                  >
                                    {" "}
                                    Add Comment
                                  </Typography>
                                </div>
                              )}
                            </>
                          )}

                          {landlineArray?.fields?.length > 1 && (
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                margin: "4px 0px",
                                ":hover": {
                                  background: "#ffdfdc",
                                },
                              }}
                              onClick={() => landlineArray.remove(i)}
                            >
                              <div style={{ paddingLeft: "5px" }}>
                                <Icon
                                  icon="delete_outline"
                                  isPressable
                                  color="#FD372A"
                                />
                              </div>

                              <Typography
                                type="p"
                                subType="medium"
                                style={{ color: "#FD372A" }}
                              >
                                Delete
                              </Typography>
                            </div>
                          )}
                        </div>
                      )}
                      returnFocus
                      autoFocus
                      overrides={{
                        Arrow: {
                          style: ({ $theme }) => ({
                            outline: `#CDCED9 solid`,
                            backgroundColor: "#16164B",
                          }),
                        },
                        Inner: {
                          style: ({ $theme }) => ({
                            width: "120px",
                            // height: "70px",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                          }),
                        },
                      }}
                      placement={PLACEMENT.leftBottom}
                      triggerType={TRIGGER_TYPE.hover}
                    >
                      <Icon icon="triple_dot" isPressable />
                    </StatefulPopover>
                  </div>
                </div>
                {type === EDIT_CONTACT ? (
                  <>
                    {textLandline[i] !== undefined ? (
                      <></>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "flex-start",
                          }}
                        >
                          {e.comment && !textCommentOpen && (
                            <Icon icon="comment_sep" />
                          )}
                          {!e.comment && commentOpen[i] && !textCommentOpen && (
                            <Icon icon="comment_sep" />
                          )}
                          {e.comment && commentOpen[i] === undefined && (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  backgroundColor: "#F0F5FF",
                                  flexDirection: "column",
                                  width: "497px",
                                  padding: "13px",
                                  borderRadius: "6px",
                                  gap: "8px",
                                  marginBottom: "10px",
                                }}
                              >
                                <Typography type="p" className="regular">
                                  {_.get(e, "comment", "")}
                                </Typography>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "3px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        gap: "6px",
                                      }}
                                      onClick={() => {
                                        setCommentOpen((prev) => ({
                                          ...prev,
                                          [i]: false,
                                        }));
                                      }}
                                    >
                                      <Icon
                                        icon="edit_outline"
                                        color="#787878"
                                        size={20}
                                        isPressable
                                      />
                                      <Typography
                                        type="p"
                                        className="text-secondary"
                                      >
                                        Edit
                                      </Typography>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "2px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setDeleteComment(true);
                                        setTextLandline((prev) => ({
                                          ...prev,
                                          [i]: false,
                                        }));
                                        handleDeleteComment(
                                          `landline[${i}].comment`
                                        );
                                      }}
                                    >
                                      <Icon
                                        icon="dot"
                                        color="#D9D9D9"
                                        size={6}
                                      />
                                      <Icon
                                        icon="delete_outline"
                                        color="#787878"
                                        isPressable
                                      />
                                      <Typography
                                        type="p"
                                        className="text-secondary"
                                      >
                                        Remove
                                      </Typography>
                                    </div>
                                  </div>
                                  {_.get(e, "aby", "") ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "2px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Icon
                                        icon="user_outLine"
                                        color="#787878"
                                      />
                                      <Typography
                                        type="p"
                                        className="text-secondary"
                                      >
                                        {format.rd({
                                          id: _.get(e, "aby", ""),
                                          name: "users",
                                        })}{" "}
                                        ,{" "}
                                        {format.date({
                                          value: _.get(e, "aat", ""),
                                        })}
                                      </Typography>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </>
                          )}

                          {!e.comment && commentOpen[i] && (
                            <Controller
                              name={`landline[${i}].comment`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <TextArea
                                    onFocus={false}
                                    {...field}
                                    name={field.name}
                                    // disabled={}
                                    error={
                                      errors[field.name] &&
                                      errors[field.name].message
                                    }
                                    label={intl.formatMessage({
                                      id: "Comments",
                                    })}
                                    placeholder={intl.formatMessage({
                                      id: "Landline Comments",
                                    })}
                                    size={SIZE.compact}
                                    value={field.value}
                                    disabled={isDisable}
                                    // maxLength={200}
                                  />
                                </>
                              )}
                            />
                          )}

                          {e.comment && commentOpen[i] === false ? (
                            <Controller
                              name={`landline[${i}].comment`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <TextArea
                                    onFocus={false}
                                    {...field}
                                    name={field.name}
                                    // disabled={}
                                    error={
                                      errors[field.name] &&
                                      errors[field.name].message
                                    }
                                    label={intl.formatMessage({
                                      id: "Comments",
                                    })}
                                    placeholder={intl.formatMessage({
                                      id: "Landline Comments",
                                    })}
                                    size={SIZE.compact}
                                    value={field.value}
                                    disabled={isDisable}
                                    // maxLength={200}
                                  />
                                </>
                              )}
                            />
                          ) : (
                            <></>
                          )}
                        </div>

                        {e.comment && commentOpen[i] === false && (
                          <div
                            className="comment_cancel_button_container"
                            onClick={() => {
                              // setCancelEdit(true);
                              cancelEdit(i, e.comment);
                              // setValue(`landline[${i}].comment`, e?.comment);
                            }}
                          >
                            <Icon icon="crosss" isPressable />
                            <Typography
                              type="p"
                              className="text-secondary"
                              subType="medium"
                            >
                              {" "}
                              Cancel
                            </Typography>
                          </div>
                        )}
                        {!e.comment && commentOpen[i] && (
                          <div
                            className="comment_cancel_button_container"
                            onClick={() => {
                              setTextCommentOpen(false);
                              setCommentOpen((prev) => ({
                                ...prev,
                                [i]: false,
                              }));
                              setValue(`landline[${i}].comment`, "");
                            }}
                          >
                            <Icon icon="crosss" isPressable />
                            <Typography
                              type="p"
                              className="text-secondary"
                              subType="medium"
                            >
                              {" "}
                              Cancel
                            </Typography>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {commentOpen[i] && (
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "flex-start",
                        }}
                      >
                        <Icon icon="comment_sep" />
                        <Controller
                          name={`landline[${i}].comment`}
                          control={control}
                          render={({ field }) => (
                            <>
                              <TextArea
                                onFocus={false}
                                {...field}
                                name={field.name}
                                // disabled={}
                                error={
                                  errors[field.name] &&
                                  errors[field.name].message
                                }
                                label={intl.formatMessage({
                                  id: "Comments",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "Landline Comments",
                                })}
                                size={SIZE.compact}
                                value={field.value}
                                disabled={isDisable}
                                // maxLength={200}
                              />
                            </>
                          )}
                        />
                      </div>
                    )}
                    {commentOpen[i] && (
                      <div
                        className="comment_cancel_button_container"
                        onClick={() => {
                          setTextCommentOpen(false);
                          setCommentOpen((prev) => ({
                            ...prev,
                            [i]: false,
                          }));
                          setValue(`landline[${i}].comment`, "");
                        }}
                      >
                        <Icon icon="crosss" isPressable />
                        <Typography
                          type="p"
                          className="text-secondary"
                          subType="medium"
                        >
                          {" "}
                          Cancel
                        </Typography>
                      </div>
                    )}
                  </>
                )}
              </>
            ))}
            {/* landline end */}

            {/* Email Header */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <LabelLarge>
                <FormattedMessage id="email" style={{ color: "#333860" }}>
                  email
                </FormattedMessage>
              </LabelLarge>
              <TextButton
                disabled={isDisable}
                kind={KIND.tertiary}
                size={TextBoxSize.SIZE.mini}
                type="button"
                style={{
                  fontSize: "12px",
                  fontWeight: "400px",
                  gap: "2px",
                  display: "flex",
                  // textAlign: "center",
                  justifyContent: "center",
                  alignItem: "center",
                }}
                onClick={() =>
                  emailArray.append({
                    type: [{ id: "PERSONAL_EMAIL", label: "Personal" }],
                    status: [{ id: "VALID", label: "Valid" }],
                    value: "",
                  })
                }
              >
                <PlusContact /> Add Another Email
              </TextButton>
            </div>

            {/* Email Inputs */}

            {emailArray.fields.map((e, i) => (
              <>
                <ConatctEmailFields
                  key={e.id}
                  errors={errors}
                  e={e}
                  i={i}
                  disableBasedEmailOnRule={disabledEmailFields[i]}
                  setValue={setValue}
                  control={control}
                  isDisable={isDisable}
                  findFullWidth={findFullWidth}
                  actionType={type}
                  remove={emailArray.remove}
                  fields={emailArray.fields}
                />
              </>
            ))}

            {/* Address Heading */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <LabelLarge>
                <FormattedMessage id="address" style={{ color: "#333860" }}>
                  address
                </FormattedMessage>
              </LabelLarge>
              <TextButton
                disabled={isDisable}
                kind={KIND.tertiary}
                size={TextBoxSize.SIZE.mini}
                type="button"
                style={{
                  fontSize: "12px",
                  fontWeight: "400px",
                  gap: "2px",
                  display: "flex",
                  // textAlign: "center",
                  justifyContent: "center",
                  alignItem: "center",
                }}
                onClick={() =>
                  addressArray.append({
                    type: [{ id: "OFFICE_ADDRESS", label: "Office" }],
                    status: [{ id: "VALID", label: "Valid" }],
                    line_1: "",
                    line_2: "",
                    country: setSelectValues(defaultData.country),
                    state: [],
                    city: "",
                    zip_code: "",
                  })
                }
              >
                <PlusContact /> Add Another Address
              </TextButton>
            </div>
            {/* Address Inputs */}
            {addressArray.fields.map((e, i) => (
              <div key={e.id}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", gap: "10px", width: "30%" }}>
                    <Controller
                      name={`address[${i}].type`}
                      defaultValue={[{ id: "OFFICE_ADDRESS", label: "Office" }]}
                      control={control}
                      disabled={isDisable}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          disabled={isDisable || disabledAddressFields[i]}
                          name={field.name}
                          clearable={false}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["address"] &&
                            errors["address"][i] &&
                            errors["address"][i].type &&
                            errors["address"][i].type.message
                          }
                          label={intl.formatMessage({
                            id: "type_contact",
                          })}
                          placeholder={intl.formatMessage({
                            id: "type_contact",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={referenceData["contact_address_type"]}
                        />
                      )}
                    />
                  </div>

                  <StatefulPopover
                    content={({ close }) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px",
                        }}
                      >
                        {!e.comment && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              gap: "4px",
                              margin: "4px 0px",
                              ":hover": {
                                background: "#ffdfdc",
                              },
                            }}
                          >
                            <div style={{ paddingLeft: "5px" }}>
                              <Icon icon="contact_chat_icon" />
                            </div>

                            <Typography
                              type="p"
                              subType="medium"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                toggleAddressCommentOpen(i);
                                close();
                              }}
                            >
                              {" "}
                              Add Comment
                            </Typography>
                          </div>
                        )}

                        {addressArray.fields.length > 1 && (
                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              margin: "4px 0px",
                            }}
                            onClick={() => addressArray.remove(i)}
                          >
                            <div style={{ paddingLeft: "5px" }}>
                              <Icon
                                icon="delete_outline"
                                isPressable
                                color="#FD372A"
                              />
                            </div>

                            <Typography
                              type="p"
                              subType="medium"
                              style={{ color: "#FD372A" }}
                            >
                              Delete
                            </Typography>
                          </div>
                        )}
                      </div>
                    )}
                    returnFocus
                    autoFocus
                    overrides={{
                      Arrow: {
                        style: ({ $theme }) => ({
                          outline: `#CDCED9 solid`,
                          backgroundColor: "#16164B",
                        }),
                      },
                      Inner: {
                        style: ({ $theme }) => ({
                          width: "120px",
                          // height: "70px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }),
                      },
                    }}
                    placement={PLACEMENT.leftBottom}
                    triggerType={TRIGGER_TYPE.hover}
                  >
                    <Icon icon="triple_dot" isPressable />
                  </StatefulPopover>
                </div>
                <div key={e.id}>
                  <Controller
                    name={`address[${i}].line_1`}
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        disabled={isDisable || disabledAddressFields[i]}
                        {...field}
                        name={field.name}
                        size={TextBoxSize.SIZE.mini}
                        error={
                          errors &&
                          errors["address"] &&
                          errors["address"][i] &&
                          errors["address"][i].line_1 &&
                          errors["address"][i].line_1.message
                        }
                        label={intl.formatMessage({
                          id: "address_line_1",
                        })}
                        placeholder={intl.formatMessage({
                          id: "address_line_1",
                        })}
                        value={field.value}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name={`address[${i}].line_2`}
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        disabled={isDisable || disabledAddressFields[i]}
                        {...field}
                        name={field.name}
                        size={TextBoxSize.SIZE.mini}
                        error={
                          errors &&
                          errors["address"] &&
                          errors["address"][i] &&
                          errors["address"][i].line_2 &&
                          errors["address"][i].line_2.message
                        }
                        label={intl.formatMessage({
                          id: "address_line_2",
                        })}
                        placeholder={intl.formatMessage({
                          id: "address_line_2",
                        })}
                        value={field.value}
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Controller
                    name={`address[${i}].city`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          fullWidth
                          name={field.name}
                          disabled={isDisable || disabledAddressFields[i]}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["address"] &&
                            errors["address"][i] &&
                            errors["address"][i].city &&
                            errors["address"][i].city.message
                          }
                          label={intl.formatMessage({
                            id: "city",
                          })}
                          placeholder={intl.formatMessage({
                            id: "city",
                          })}
                          value={field.value}
                          onKeyDown={(e) => {
                            if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </>
                    )}
                  />

                  <Controller
                    name={`address[${i}].state`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <SelectBox
                          {...field}
                          disabled={isDisable || disabledAddressFields[i]}
                          name={field.name}
                          clearable={false}
                          creatable={creatablePermission ? true : false}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["address"] &&
                            errors["address"][i] &&
                            errors["address"][i].state &&
                            errors["address"][i].state.message
                          }
                          label={intl.formatMessage({
                            id: "state",
                          })}
                          placeholder={intl.formatMessage({
                            id: "state",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={referenceData["state_list"]}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name={`address[${i}].zip_code`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          fullWidth
                          name={field.name}
                          disabled={isDisable || disabledAddressFields[i]}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["address"] &&
                            errors["address"][i] &&
                            errors["address"][i].zip_code &&
                            errors["address"][i].zip_code.message
                          }
                          label={intl.formatMessage({
                            id: "zipCode",
                          })}
                          placeholder={intl.formatMessage({
                            id: "zipCode",
                          })}
                          value={field.value}
                          onKeyDown={(e) => {
                            if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </>
                    )}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "32%" }}>
                    <Controller
                      name={`address[${i}].country`}
                      defaultValue={setSelectValues(defaultData.country)}
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          disabled={isDisable || disabledAddressFields[i]}
                          name={field.name}
                          clearable={false}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["address"] &&
                            errors["address"][i] &&
                            errors["address"][i].country &&
                            errors["address"][i].country.message
                          }
                          label={intl.formatMessage({
                            id: "country",
                          })}
                          placeholder={intl.formatMessage({
                            id: "country",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={referenceData["country"]}
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: "32.5%" }}>
                    <Controller
                      name={`address[${i}].status`}
                      control={control}
                      disabled={isDisable}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          disabled={isDisable}
                          name={field.name}
                          clearable={false}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["address"] &&
                            errors["address"][i] &&
                            errors["address"][i].status &&
                            errors["address"][i].status.message
                          }
                          label={intl.formatMessage({
                            id: "status",
                          })}
                          placeholder={intl.formatMessage({
                            id: "status",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={referenceData["contact_status"]}
                        />
                      )}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "13px",
                      gap: "5px",
                    }}
                  >
                    {data &&
                    data?.address?.[i] &&
                    data?.address?.[i]?.address_status ? (
                      <ContactLocation
                        color={
                          data?.address?.[i]?.status === "VALID" ||
                          data?.address?.[i]?.status === "LEAD"
                            ? "#0fB158"
                            : data?.address?.[i]?.status === "DNC"
                            ? "#ba332b"
                            : data?.address?.[i]?.status === "INVALID"
                            ? "#E5AC16"
                            : "#FD372A"
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {type === EDIT_CONTACT ? (
                  <>
                    {textOpenComment[i] !== undefined ? (
                      <></>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "flex-start",
                          }}
                        >
                          {e.comment && !textAddressCommentOpen && (
                            <Icon icon="comment_sep" />
                          )}
                          {!e.comment &&
                            addressComment[i] &&
                            !textAddressCommentOpen && (
                              <Icon icon="comment_sep" />
                            )}

                          {e.comment && addressComment[i] === undefined && (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  backgroundColor: "#F0F5FF",
                                  flexDirection: "column",
                                  width: "497px",
                                  padding: "13px",
                                  borderRadius: "6px",
                                  gap: "8px",
                                  marginBottom: "10px",
                                }}
                              >
                                <Typography type="p" className="regular">
                                  {_.get(e, "comment", "")}
                                </Typography>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "3px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        gap: "6px",
                                      }}
                                      onClick={() => {
                                        setAddressCommentOpen((prev) => ({
                                          ...prev,
                                          [i]: false,
                                        }));
                                      }}
                                    >
                                      <Icon
                                        icon="edit_outline"
                                        color="#787878"
                                        size={20}
                                        isPressable
                                      />
                                      <Typography
                                        type="p"
                                        className="text-secondary"
                                      >
                                        Edit
                                      </Typography>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "2px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setTextOpenCommentOpen((prev) => ({
                                          ...prev,
                                          [i]: true,
                                        }));
                                        handleAddressDeleteComment(
                                          `address[${i}].comment`
                                        );
                                      }}
                                    >
                                      <Icon
                                        icon="dot"
                                        color="#D9D9D9"
                                        size={6}
                                      />
                                      <Icon
                                        icon="delete_outline"
                                        color="#787878"
                                        isPressable
                                      />
                                      <Typography
                                        type="p"
                                        className="text-secondary"
                                      >
                                        Remove
                                      </Typography>
                                    </div>
                                  </div>
                                  {_.get(e, "aat", "") ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "2px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Icon
                                        icon="user_outLine"
                                        color="#787878"
                                      />
                                      <Typography
                                        type="p"
                                        className="text-secondary"
                                      >
                                        {format.rd({
                                          id: _.get(e, "aby", ""),
                                          name: "users",
                                        })}{" "}
                                        ,{" "}
                                        {format.date({
                                          value: _.get(e, "aat", ""),
                                        })}
                                      </Typography>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </>
                          )}

                          {!e.comment && addressComment[i] && (
                            <Controller
                              name={`address[${i}].comment`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <TextArea
                                    onFocus={false}
                                    {...field}
                                    name={field.name}
                                    // disabled={}
                                    error={
                                      errors[field.name] &&
                                      errors[field.name].message
                                    }
                                    label={intl.formatMessage({
                                      id: "Comments",
                                    })}
                                    placeholder={intl.formatMessage({
                                      id: "Address Comments",
                                    })}
                                    size={SIZE.compact}
                                    value={field.value}
                                    disabled={isDisable}
                                    // maxLength={200}
                                  />
                                </>
                              )}
                            />
                          )}
                          {e.comment && addressComment[i] === false ? (
                            <Controller
                              name={`address[${i}].comment`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <TextArea
                                    onFocus={false}
                                    {...field}
                                    name={field.name}
                                    // disabled={}
                                    error={
                                      errors[field.name] &&
                                      errors[field.name].message
                                    }
                                    label={intl.formatMessage({
                                      id: "Comments",
                                    })}
                                    placeholder={intl.formatMessage({
                                      id: "Address Comments",
                                    })}
                                    size={SIZE.compact}
                                    value={field.value}
                                    disabled={isDisable}
                                    // maxLength={200}
                                  />
                                </>
                              )}
                            />
                          ) : (
                            <></>
                          )}
                        </div>

                        {e.comment && addressComment[i] === false && (
                          <div
                            className="comment_cancel_button_container"
                            onClick={() => {
                              cancelEditAdress(i, e.comment);
                            }}
                          >
                            <Icon icon="crosss" isPressable />
                            <Typography
                              type="p"
                              className="text-secondary"
                              subType="medium"
                            >
                              {" "}
                              Cancel
                            </Typography>
                          </div>
                        )}
                        {!e.comment && addressComment[i] && (
                          <div
                            className="comment_cancel_button_container"
                            onClick={() => {
                              setTextAddressCommentOpen(false);
                              setAddressCommentOpen((prev) => ({
                                ...prev,
                                [i]: false,
                              }));
                              setValue(`address[${i}].comment`, "");
                            }}
                          >
                            <Icon icon="crosss" isPressable />
                            <Typography
                              type="p"
                              className="text-secondary"
                              subType="medium"
                            >
                              {" "}
                              Cancel
                            </Typography>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {addressComment[i] && (
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "flex-start",
                        }}
                      >
                        <Controller
                          name={`address[${i}].comment`}
                          control={control}
                          render={({ field }) => (
                            <>
                              <TextArea
                                onFocus={false}
                                {...field}
                                name={field.name}
                                // disabled={}
                                error={
                                  errors[field.name] &&
                                  errors[field.name].message
                                }
                                label={intl.formatMessage({
                                  id: "Comments",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "Address Comments",
                                })}
                                size={SIZE.compact}
                                value={field.value}
                                disabled={type === VIEW ? true : false}
                                // maxLength={200}
                              />
                            </>
                          )}
                        />
                      </div>
                    )}

                    {addressComment[i] && (
                      <div
                        className="comment_cancel_button_container"
                        onClick={() => {
                          setTextAddressCommentOpen(false);
                          setAddressCommentOpen((prev) => ({
                            ...prev,
                            [i]: false,
                          }));
                          setValue(`address[${i}].comment`, "");
                        }}
                      >
                        <Icon icon="crosss" isPressable />
                        <Typography
                          type="p"
                          className="text-secondary"
                          subType="medium"
                        >
                          {" "}
                          Cancel
                        </Typography>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>

          <LabelLarge $style={{ marginBottom: "10px" }}>
            <FormattedMessage id="custom_fields" style={{ color: "#333860" }}>
              custom_fields
            </FormattedMessage>
          </LabelLarge>
          {/* {type === EDIT_CONTACT && ( */}
          <CustomFieldsTab
            customFields={data}
            fieldLevel={customContact}
            width={"530px"}
            wrapWidth={"200px"}
            control={control}
            setValue={setValue}
            reSet={reset}
          />

          {/* )} */}
        </ModalBody>
        <ModalFooter
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "5px",
            marginTop: "5px",
          }}
        >
          <div style={{ width: "125px" }}>
            <TextButton
              type="button"
              kind={KIND.tertiary}
              size={TextBoxSize.SIZE.mini}
              fullWidth
              onClick={() => {
                reset();
                closeDrawer();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ width: "125px" }}>
            <TextButton
              type="submit"
              kind={KIND.primary}
              size={TextBoxSize.SIZE.mini}
              fullWidth
              disabled={isDisable || isSubmitting}
              isLoading={isSubmitting}
            >
              Save
            </TextButton>
          </div>
        </ModalFooter>
      </form>
    </React.Fragment>
  );
};

export default AddContact;
