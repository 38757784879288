import React from "react";
import { useState } from "react";
import {
  ACT_CALL_BACK_LATER,
  ACT_FIELD_VISIT,
  ACT_NOTES,
  ACT_PAYMENT,
  ACT_PAYMENT_PLAN,
  ACT_PROMISE_TO_PAY,
  ACT_RECORD_DISPUTE,
  ACT_RETURN_VISIT,
  ACT_UNSUCCESSFUL,
  CO_TIMELINE_,
  CUSTOMER_OVERVIEW_,
  FIELD_VISIT,
  GETTING_FIELD_VISIT_,
  NEW,
} from "../../../../constants";
import CallBackLaterForm from "../Forms/CallBackLater";
import DisputeForm from "../Forms/Dispute";
import Pay from "../Forms/Pay";
import PromiseToPayForm from "../Forms/PromiseToPay";
import PaymentPlanForm from "../Forms/PaymentPlan";
import CallUnsuccessfulForm from "../Forms/CallUnsuccessful";
import FieldVisitForm from "../Forms/FieldVisit";
import NoteForm from "../Forms/Note";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDrawerState } from "../../../../redux/actions";
import queryClient from "../../../../providers/queryClient";
import { executeAdhocCustomerAction } from "../../../../services";
import { Icon, Typography } from "../../../../components_v2";
import { useIntl } from "react-intl";
import { SelectBox } from "../../../../components/SelectBox";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { useContext } from "react";
import FieldVisitUnsuccessForm from "./FieldVisitUnsuccessForm";
import ReturnVisitForm from "../Forms/ReturnVisit";
import {
  ADD_LIST_VALUES,
  CALL_PAYMENT_PLAN,
} from "../../../../providers/RBACProvider/permissionList";
import { Controller } from "react-hook-form";
import { Select } from "baseui/select";
import FieldVisitPaymentDataLayer from "./FieldVisitPaymentDataLayer";

const CallOutcome = (props) => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const { type, formValues = {}, ...rest } = props;
  const { currentOrganization } = useContext(MaxyfiContext);

  const setDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  const onSuccessRefetch = () => {
    queryClient.refetchQueries([`${GETTING_FIELD_VISIT_}-${customerId}`]);
    queryClient.refetchQueries([`${CO_TIMELINE_}${customerId}`]);
    setDrawer();
  };

  switch (type) {
    case ACT_CALL_BACK_LATER: {
      return (
        <CallBackLaterForm
          {...rest}
          formValues={{ ...formValues, visit_outcome: ACT_CALL_BACK_LATER }}
          service={executeAdhocCustomerAction}
          onCancel={() => {
            props.setState({ type: NEW });
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isIncrementor={false}
          isConsent={false}
          isContent={false}
        />
      );
    }

    case ACT_RECORD_DISPUTE: {
      return (
        <DisputeForm
          {...rest}
          formValues={{ ...formValues, visit_outcome: ACT_RECORD_DISPUTE }}
          service={executeAdhocCustomerAction}
          onCancel={() => {
            props.setState({ type: NEW });
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isIncrementor={false}
          isConsent={false}
          isContent={false}
        />
      );
    }

    case ACT_PAYMENT: {
      return (
        <FieldVisitPaymentDataLayer
          {...rest}
          formValues={{ ...formValues, visit_outcome: ACT_PAYMENT }}
          service={executeAdhocCustomerAction}
          onCancel={() => {
            props.setState({ type: NEW });
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isIncrementor={false}
          isConsent={false}
          isContent={false}
        />
      );
    }

    case ACT_PROMISE_TO_PAY: {
      return (
        <PromiseToPayForm
          {...rest}
          formValues={{ ...formValues, visit_outcome: ACT_PROMISE_TO_PAY }}
          service={executeAdhocCustomerAction}
          onCancel={() => {
            props.setState({ type: NEW });
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isIncrementor={false}
          isConsent={false}
          isContent={false}
          actionType={FIELD_VISIT}
        />
      );
    }

    case ACT_PAYMENT_PLAN: {
      return (
        <PaymentPlanForm
          {...rest}
          formValues={{ ...formValues, visit_outcome: ACT_PAYMENT_PLAN }}
          service={executeAdhocCustomerAction}
          onCancel={() => {
            props.setState({ type: NEW });
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isIncrementor={false}
          isConsent={false}
          isContent={false}
        />
      );
    }

    case ACT_UNSUCCESSFUL: {
      return (
        <CallUnsuccessfulForm
          {...rest}
          formValues={{ ...formValues, visit_outcome: ACT_UNSUCCESSFUL }}
          service={executeAdhocCustomerAction}
          onCancel={() => {
            props.setState({ type: NEW });
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isIncrementor={false}
          isConsent={false}
          isContent={false}
        />
      );
    }

    case ACT_RETURN_VISIT: {
      return (
        <ReturnVisitForm
          {...rest}
          formValues={{ ...formValues, visit_outcome: ACT_RETURN_VISIT }}
          service={executeAdhocCustomerAction}
          onCancel={() => {
            props.setState({ type: NEW });
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isIncrementor={false}
          isConsent={false}
          isContent={false}
        />
      );
    }

    case ACT_NOTES: {
      return (
        <NoteForm
          {...rest}
          formValues={{ ...formValues, visit_outcome: ACT_NOTES }}
          service={executeAdhocCustomerAction}
          onCancel={() => {
            props.setState({ type: NEW });
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isIncrementor={false}
          isConsent={false}
          isContent={false}
        />
      );
    }

    default:
      return null;
  }
};

const vistiSatus = [
  { label: "Successful", type: "SUCCESSFULL" },
  { label: "Unsuccessful", type: "UNSUCCESSFULL" },
];

const FieldVisitCapture = (props) => {
  const intl = useIntl();
  const { customerId } = useParams();
  const { referenceData, currentOrganization, space } =
    useContext(MaxyfiContext);
  const [isCapture, setIsCapture] = useState({
    visit_person: [{ id: "customers" }],
    visit_outcome: null,
    is_inbound_call: false,
    visit_status: "SUCCESSFULL",
    reason: [],
  });

  const callOutcomeList = [
    {
      label: "PTP",
      icon: "review_promise",
      type: ACT_PROMISE_TO_PAY,
      isEnabled: true,
    },
    {
      label: "CBL",
      icon: "callback_later",
      type: ACT_CALL_BACK_LATER,
      isEnabled: true,
    },
    {
      label: "DIS",
      icon: "dispute",
      type: ACT_RECORD_DISPUTE,
      isEnabled: true,
    },
    {
      label: "PPL",
      icon: "payment_plan",
      type: ACT_PAYMENT_PLAN,
      isEnabled: space?.role_permissions?.includes(CALL_PAYMENT_PLAN),
    },
    {
      label: "RV",
      icon: "field_visit",
      type: ACT_RETURN_VISIT,
      isEnabled: true,
    },
    { label: "Pay", icon: "pay", type: ACT_PAYMENT, isEnabled: true },
    { label: "Note", icon: "note", type: ACT_NOTES, isEnabled: true },
  ];

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  const onSuccessRefetch = () => {
    queryClient.refetchQueries([`${GETTING_FIELD_VISIT_}-${customerId}`]);
    queryClient.refetchQueries([`${CO_TIMELINE_}${customerId}`]);
    props.setState({ type: NEW });
  };

  return (
    <div style={{ padding: "10px" }}>
      <Typography style={{ color: "#787878", marginBottom: "10px" }}>
        Field Visit Status
      </Typography>
      <div className="co-visit-status-container">
        {vistiSatus.map((e) => (
          <div
            key={e.type}
            className={`co-call-outcome-btn ${
              isCapture.visit_status == e.type
                ? "co-call-outcome-btn--active"
                : ""
            }`}
            onClick={() => {
              setIsCapture({ ...isCapture, visit_status: e.type });
            }}
            disabled={!isCapture.outcome}
          >
            <Typography>{e.label}</Typography>
          </div>
        ))}
      </div>

      {isCapture.visit_status === "SUCCESSFULL" && (
        <>
          <Typography style={{ color: "#787878", marginBottom: "10px" }}>
            Whom You met?
          </Typography>
          <div style={{ width: "150px" }}>
            <SelectBox
              size="mini"
              requiredAstric={true}
              clearable={false}
              label={intl.formatMessage({
                id: "reciever",
              })}
              placeholder={intl.formatMessage({
                id: "reciever",
              })}
              value={isCapture.visit_person}
              onChange={(e) => {
                setIsCapture({ ...isCapture, visit_person: e.value });
              }}
              options={referenceData["visit_meeting_person_list"]}
            />
          </div>
          <Typography style={{ color: "#787878", marginBottom: "10px" }}>
            Capture Your Outcomes
          </Typography>
          <div className="co-call-outcome-container">
            {callOutcomeList.map((e) =>
              e.isEnabled ? (
                <div
                  key={e.type}
                  className={`co-call-outcome-btn ${
                    isCapture.visit_outcome === e.type
                      ? "co-call-outcome-btn--active"
                      : ""
                  }`}
                  onClick={() => {
                    setIsCapture({ ...isCapture, visit_outcome: e.type });
                  }}
                  disabled={!isCapture.visit_outcome}
                >
                  <Icon icon={e.icon} />
                  <Typography>{e.label}</Typography>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
          {isCapture.visit_outcome ? (
            <CallOutcome
              {...props}
              type={isCapture.visit_outcome}
              formValues={{
                action_type: "FIELD_VISIT",
                // is_inbound_call: isCapture.is_inbound_call,
                // is_increment_follow_up: true,
                visit_outcome: isCapture?.visit_outcome,
                visit_status: isCapture?.visit_status,
                visit_person: isCapture?.visit_person?.[0]?.id,
                field_visit_id: props._id,
              }}
            />
          ) : (
            <></>
          )}
        </>
      )}

      {isCapture.visit_status === "UNSUCCESSFULL" && (
        <>
          <Typography style={{ color: "#787878", marginBottom: "10px" }}>
            Reason
          </Typography>
          <div style={{ width: "150px" }}>
            <SelectBox
              size="mini"
              //   requiredAstric={true}
              //   label={intl.formatMessage({
              //     id: "reason",
              //   })}
              creatable={creatablePermission ? true : false}
              name="Reason"
              placeholder={intl.formatMessage({
                id: "Reason",
              })}
              // searchable={false}f
              value={isCapture.reason}
              onChange={(e) => {
                setIsCapture({ ...isCapture, reason: e?.value });
              }}
              options={referenceData["visit_unsuccess_reason_list"] || []}
            />
          </div>
          <FieldVisitUnsuccessForm
            {...props}
            service={executeAdhocCustomerAction}
            formValues={{
              action_type: "FIELD_VISIT",
              visit_status: isCapture?.visit_status,
              reason: isCapture?.reason?.[0]?.id,
              field_visit_id: props._id,
            }}
            onCancel={() => {
              props.setState({ type: NEW });
            }}
            onSuccess={() => {
              onSuccessRefetch();
            }}
          />
        </>
      )}
    </div>
  );
};

export default FieldVisitCapture;
