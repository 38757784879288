import React from "react";

const Signel = () => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="14"
    //   height="13"
    //   viewBox="7 1 4 19"
    //   fill="currentColor"
    // >
    //   <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z"></path>
    // </svg>
    <svg
      width="18"
      height="12"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8039 0H17.6373C16.9929 0 16.4706 0.50368 16.4706 1.125V10.875C16.4706 11.4963 16.9929 12 17.6373 12H18.8039C19.4482 12 19.9706 11.4963 19.9706 10.875V1.125C19.9706 0.50368 19.4482 0 18.8039 0ZM12.1471 2.62513H13.3137C13.9581 2.62513 14.4804 3.12881 14.4804 3.75013V10.8751C14.4804 11.4965 13.9581 12.0001 13.3137 12.0001H12.1471C11.5027 12.0001 10.9804 11.4965 10.9804 10.8751V3.75013C10.9804 3.12881 11.5027 2.62513 12.1471 2.62513ZM7.82353 5.24992H6.65686C6.01253 5.24992 5.4902 5.7536 5.4902 6.37492V10.8749C5.4902 11.4962 6.01253 11.9999 6.65686 11.9999H7.82353C8.46786 11.9999 8.9902 11.4962 8.9902 10.8749V6.37492C8.9902 5.7536 8.46786 5.24992 7.82353 5.24992ZM2.33333 7.49989H1.16667C0.522334 7.49989 0 8.00356 0 8.62489V10.8749C0 11.4962 0.522334 11.9999 1.16667 11.9999H2.33333C2.97767 11.9999 3.5 11.4962 3.5 10.8749V8.62489C3.5 8.00356 2.97767 7.49989 2.33333 7.49989Z"
        fill="black"
      />
    </svg>
  );
};
export default Signel;
