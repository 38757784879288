import { WORKLIST_DATA, WORKLIST_RESPONCE_DATA } from "./action";

const initialQueryBuilder = {
  combinator: "and",
  rules: [],
};

const initialState = {
  worklistData: {},
  actionResposce: {},
  worklistState: {
    filters: {},
    formFilters: initialQueryBuilder,
  },
};

const worklistReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case WORKLIST_DATA:
      return {
        ...state,
        worklistData: { ...payload },
      };
    case WORKLIST_RESPONCE_DATA:
      return {
        ...state,
        actionResposce: { ...payload },
      };
    default:
      return { ...state };
  }
};

export default worklistReducer;
