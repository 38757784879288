import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";
import { STYLE_TYPE } from "baseui/checkbox";
import { FormattedMessage, useIntl } from "react-intl";
import {
  LabelLarge,
  LabelXSmall,
  HeadingXSmall,
  ParagraphMedium,
} from "baseui/typography";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip";
import _ from "lodash";

import { TextBox } from "../../components/TextBox";
import { TextButton, SIZE } from "../../components/TextButton";
import { MultiSelect } from "../../components/MultiSelect/MultiSelect";
import CustomerPortalSettingLabel from "../../components/CustomerPortalSettingLabel/CustomerPortalSettingLabel";
import { SelectBox } from "../../components/SelectBox";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import InfoPortal from "../../assets/img/svg/InfoPortal";
import setSelectValues from "../../utils/setSelectValues";
import {
  updateCreditPortal,
  resetCustomerPortalSettings,
  assignBulkCustomerPortalSetting,
} from "../../services/creditPortal";
import getSelectValues from "../../utils/getSelectValues";
import { IconButton, KIND } from "../../components/IconButton";
import { getCustomerPortal, getOrganization } from "../../services";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import Delete from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import Plus from "../../assets/img/svg/Upload/Plus";
import queryClient from "../../providers/queryClient";
import {
  COMMISSION_SLAB,
  CUSTOMER_OVERVIEW,
  CUSTOMER_OVERVIEW_,
  NEW,
  VIEW,
} from "../../constants";
import { Icon, Typography } from "../../components_v2";
import {
  getCommissionSlab,
  getDiscountSlabFn,
} from "../../services/organization";
import DiscountSlabModal from "../../containers/Setting/DiscountSlab/DiscountSlabModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import * as TextBoxSize from "../../components/TextBox";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import Warning from "../../assets/img/warning.png";
import { ModalContext } from "../../providers/ModalProvider";

const CustomerPortalSettings = ({
  closeDrawer,
  setOpenCustomPortal,
  resource = "CUSTOMER",
  bulkAssignCustomer,
  selectedCustomer,
  getService = getCustomerPortal,
  submitService,
  toggleAllRowsSelected,
  isAllRowsSelected,
  queryFilter,
}) => {
  const intl = useIntl();
  const { customerId } = useParams();
  const [isModalState, setisModalState] = useState({
    isOpen: false,
    data: {},
    type: NEW,
  });
  const { handleModal } = useContext(ModalContext);

  const { smsGateWay } = useSelector((state) => state.customerOverviewReducer);
  let {
    currentOrganization,
    referenceData,
    currentDefaultFormatDetails,
    appAccessRest,
  } = useContext(MaxyfiContext);
  let minStart = moment();
  minStart.set({ hour: 9, minute: 0, second: 0, millisecond: 0 });

  let maxStart = moment();
  maxStart.set({ hour: 17, minute: 0, second: 0, millisecond: 0 });

  const schema = Yup.object().shape({
    amount_percentage: Yup.number().max(
      100,
      "Enter percentage between 0 to 100"
    ),
    is_pre_defined_payment_plan: Yup.boolean(),
    pre_defined_payment_plan: Yup.lazy((_, { parent }) =>
      parent.is_pre_defined_payment_plan
        ? Yup.array()
            .of(
              Yup.object({
                payment_of: Yup.string()
                  .required("Required")
                  .matches(/^\d+$/, "Only numbers are allowed"),
                // .matches(/^\d+$/, "Only numbers are allowed"), // Ensures only digits
                // .test(
                //   "max-limit",
                //   "Enter a number between 0 to 100",
                //   (value) => {
                //     return Number(value) <= 100;
                //   }
                // ),
                frequency: Yup.array()
                  .max(1, "Required")
                  .required("Required")
                  .nullable()
                  .typeError("Required"),
                leadtime: Yup.string()
                  .required("Required")
                  .matches(
                    /^([0-9]+|{{eom}})$/,
                    "Lead should be either a number or {{eom}}"
                  ),
                discount: Yup.string()
                  .optional()
                  .test(
                    "prc",
                    "{{max_settlement_rate}} tag is Required",
                    (prc) => {
                      return (
                        prc.length === 0 ||
                        Number(prc) ||
                        (!Number(prc) &&
                          prc.includes("{{max_settlement_rate}}"))
                      );
                    }
                  ),
              })
            )
            .min(1, "At least one payment plan is required")
        : Yup.array().notRequired()
    ),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      pre_defined_payment_plan: [
        { payment_of: "", leadtime: "", frequency: "", discount: "" },
      ],
    },
  });

  console.log(errors, "errors");

  let paymentAmountRestriction = getSelectValues(
    watch("request_back.restrict_date")
  );

  useEffect(() => {
    if (paymentAmountRestriction !== "no_validation") {
      setValue("request_back.amount_percentage", 100);
    } else {
      setValue("request_back.amount_percentage", 0);
    }
  }, []);

  const CommissionSlab = useQuery(
    [`DISCOUNT_SLAB-${currentOrganization}`],
    async ({ queryKey }) => {
      // let { filters } = queryKey[1];
      return await getDiscountSlabFn({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data, isFetched } = useQuery(
    [`PORTAL_SETTINGS_${customerId}`],
    async () => {
      return await getService({
        ...(customerId ? { customerPortalId: customerId } : {}),
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  useEffect(() => {
    let slabData = _.get(CommissionSlab, "data.data.docs", []);

    const setFormValues = (sourceData) => {
      setValue(
        "is_authentication",
        _.get(sourceData, "is_authentication", false)
      );
      setValue("is_invoice", _.get(sourceData, "is_invoice", false));
      setValue("is_credit_note", _.get(sourceData, "is_credit_note", false));
      setValue("is_payment", _.get(sourceData, "is_payment", false));
      setValue("is_message", _.get(sourceData, "is_message", false));
      setValue("is_callback", _.get(sourceData, "is_callback", false));
      setValue("is_request_back", _.get(sourceData, "is_request_back", false));
      setValue(
        "is_request_payment_plan",
        _.get(sourceData, "is_request_payment_plan", false)
      );
      setValue(
        "is_pre_defined_payment_plan",
        _.get(sourceData, "is_pre_defined_payment_plan", false)
      );

      setValue(
        "invoice.is_allow_invoice_download",
        _.get(sourceData, "invoice.is_allow_invoice_download", false)
      );
      setValue(
        "invoice.is_display_paid_invoices",
        _.get(sourceData, "invoice.is_display_paid_invoices", false)
      );
      setValue(
        "invoice.is_allow_payment_receipt_download",
        _.get(sourceData, "invoice.is_allow_payment_receipt_download", false)
      );
      setValue(
        "invoice.is_display_invoice_description",
        _.get(sourceData, "invoice.is_display_invoice_description", false)
      );

      setValue(
        "credit_note.is_display_adjusted_credit_note",
        _.get(sourceData, "credit_note.is_display_adjusted_credit_note", false)
      );

      setValue(
        "payment.is_adhoc_payment",
        _.get(sourceData, "payment.is_adhoc_payment", false)
      );
      setValue(
        "payment.is_pay_all_dues",
        _.get(sourceData, "payment.is_pay_all_dues", false)
      );
      setValue(
        "payment.is_pay_overdues",
        _.get(sourceData, "payment.is_pay_overdues", false)
      );

      setValue(
        "message.is_display_historical_chat",
        _.get(sourceData, "message.is_display_historical_chat", false)
      );
      setValue(
        "message.is_allow_files_transfered",
        _.get(sourceData, "message.is_allow_files_transfered", false)
      );

      setValue(
        "callback.callback_days",
        setSelectValues(_.get(sourceData, "callback.callback_days", []), true)
      );
      setValue(
        "callback.callback_duration",
        setSelectValues(_.get(sourceData, "callback.callback_duration", []))
      );
      setValue(
        "callback.start_time",
        setSelectValues(_.get(sourceData, "callback.start_time", ""))
      );
      setValue(
        "callback.end_time",
        setSelectValues(_.get(sourceData, "callback.end_time", ""))
      );

      setValue(
        "request_back.amount_percentage",
        _.get(sourceData, "request_back.amount_percentage", null)
      );
      setValue(
        "request_back.amount_restriction",
        setSelectValues(
          _.get(sourceData, "request_back.amount_restriction", [])
        )
      );
      setValue(
        "request_back.restrict_date",
        setSelectValues(_.get(sourceData, "request_back.restrict_date", ""))
      );

      setValue(
        "payment_plan.instalmentextension",
        setSelectValues(
          _.get(
            sourceData,
            "request_payment_plan.maximum_instalment_extention",
            []
          )
        )
      );
      setValue(
        "payment_plan.frequency",
        setSelectValues(
          _.get(sourceData, "request_payment_plan.payment_plan_freequency", []),
          true
        )
      );
      setValue(
        "payment_plan.residual",
        setSelectValues(
          _.get(sourceData, "request_payment_plan.payment_plan_residual", [])
        )
      );

      let legCommissionSlab = _.get(
        sourceData,
        "request_payment_plan.disc_slb",
        ""
      );
      if (legCommissionSlab && slabData.length) {
        let findCommissionSlab = slabData.find(
          ({ _id }) => _id === legCommissionSlab
        );
        let findata = findCommissionSlab
          ? [
              {
                id: findCommissionSlab._id,
                label: findCommissionSlab.slab_name,
                ...findCommissionSlab,
              },
            ]
          : [];
        setValue("payment_plan.disc_slb", findata);
      }

      if (sourceData?.pre_defined_payment_plan?.length) {
        setValue("pre_defined_payment_plan", []);
        sourceData.pre_defined_payment_plan.forEach((e, i) => {
          update(i, {
            payment_of: String(_.get(e, "no_of_payments", "")),
            leadtime: _.get(e, "max_lead_time", ""),
            frequency: setSelectValues(_.get(e, "payment_plan_frequency", "")),
            discount: _.get(e, "discount", ""),
          });
        });
      }
    };

    if (bulkAssignCustomer) {
      setFormValues(_.get(data, "data.doc.customer_portal", {}));
    } else {
      setFormValues(_.get(data, "data.doc", {}));
    }
  }, [data?.data?.doc, isFetched, CommissionSlab?.data]);

  const { payment_plan } = watch();

  const resetCustomerPortal = useMutation(
    () =>
      resetCustomerPortalSettings({
        organization: currentOrganization,
        id: customerId,
      }),

    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
        closeDrawer();
      },
    }
  );
  const postBulkSelectSetting = useMutation(
    (data) =>
      submitService({
        ...data,
        ...(customerId ? { id: customerId } : {}),
        ...(selectedCustomer ? { customerId: selectedCustomer } : {}),
        ...(currentOrganization ? { organization: currentOrganization } : {}),
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;

          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);

          // toast.error(data.message);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        // reset();
        queryClient.invalidateQueries(
          `${CUSTOMER_OVERVIEW}-${currentOrganization}`
        );
        if (closeDrawer) {
          closeDrawer();
        } else {
          setOpenCustomPortal(false);
        }
      },
    }
  );
  const { filters, globalFilters } = useSelector(
    (s) => s.customerSummary.tableCurrentState
  );

  const { tableCurrentState, gridStructure } = useSelector(
    (state) => state.customerSummary
  );

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };

  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];
  let newFilter = dynamicQueryFilter({
    gridStructure: gridStructure,
    // customerQueryFilter: queryFilter && queryFilter.display_name,
    timeZone: currentDefaultFormatDetails.time_zone,
    customerFilter: filters,
  });
  const onSubmit = async (data) => {
    let phoneList =
      data && data.pre_defined_payment_plan
        ? data.pre_defined_payment_plan
            .map((value) => {
              return {
                ...(value?.discount?.length > 0
                  ? { discount: value?.discount }
                  : {}),
                payment_plan_frequency: getSelectValues(value?.frequency),
                max_lead_time: _.get(value, "leadtime", ""),
                no_of_payments: Number(value?.payment_of),
              };
            })
            .filter((obj) => obj.no_of_payments)
        : [];

    let newValues = {
      ...data,
      request_back: {
        ...data.request_back,
        amount_restriction: getSelectValues(
          data.request_back.amount_restriction
        ),
        restrict_date: getSelectValues(data.request_back.restrict_date),
      },
      request_payment_plan: {
        maximum_instalment_extention: getSelectValues(
          data.payment_plan.instalmentextension
        ),
        payment_plan_freequency: getSelectValues(
          data.payment_plan.frequency,
          true
        ),
        payment_plan_residual: getSelectValues(data.payment_plan.residual),
        disc_slb: getSelectValues(data.payment_plan.disc_slb),
      },
      callback: {
        callback_days: getSelectValues(data.callback.callback_days, true),
        callback_duration: getSelectValues(data.callback.callback_duration),
        start_time: getSelectValues(data.callback.start_time),
        end_time: getSelectValues(data.callback.end_time),
      },
      is_pre_defined_payment_plan: data.is_pre_defined_payment_plan,
      pre_defined_payment_plan: phoneList,
    };
    delete newValues.payment_plan;
    delete newValues.is_all_customer;
    // delete newValues?.pre_defined_payment_plan;

    await postBulkSelectSetting.mutateAsync({
      ...(bulkAssignCustomer
        ? { customer_portal_setting: newValues }
        : { newValues }),
      ...(data?.is_all_customer && bulkAssignCustomer
        ? {
            query: newFilter ? newFilter : {},
            filters: {
              ...globalFilters,
              ...appAccessRest,
              needs_actions: [...NeedsActionFilter],
              stage: [...FilteredCustomerStage],
              search_value: queryFilter && queryFilter.display_name,
            },
          }
        : selectedCustomer
        ? { customerId: selectedCustomer }
        : {}),
      ...(bulkAssignCustomer
        ? { is_all_customer: _.get(data, "is_all_customer", false) }
        : {}),
      ...(bulkAssignCustomer ? { typ: "PERSONALIZE" } : {}),
    });
  };

  const { fields, append, update, remove, swap, move, insert } = useFieldArray({
    control,
    name: "pre_defined_payment_plan",
  });

  return (
    <>
      <div
        style={{
          height: "100%",
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* Customer Portal Link */}
          <div
            style={{
              height: "100%",
              Width: "950px",
              overflowY: "scroll",
              scrollbarWidth: "none",
              overflowX: "hidden",
            }}
          >
            {/* Customer Poratl Setting */}
            <div
              style={{
                margin: "10px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                <LabelLarge>Portal Settings</LabelLarge>
              </div>
            </div>

            {bulkAssignCustomer ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #cdced9",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <Typography type="h3">
                    Personalized at consumer Level
                  </Typography>
                  <Typography subType="regular">
                    Click to Reset the Portal Settings
                  </Typography>
                </div>
                <div>
                  <TextButton
                    type="button"
                    kind={KIND.secondary}
                    size={SIZE.mini}
                    onClick={async () => {
                      handleModal({
                        title: "",
                        content:
                          "This will remove the personalization done to portal setting. Would you like to continue ?",
                        successCallback: () => {
                          postBulkSelectSetting.mutateAsync({
                            // customer_portal_setting: newValues,
                            ...(data?.is_all_customer
                              ? {
                                  query: newFilter ? newFilter : {},
                                  filters: {
                                    ...globalFilters,
                                    ...appAccessRest,
                                    needs_actions: [...NeedsActionFilter],
                                    stage: [...FilteredCustomerStage],
                                    search_value:
                                      queryFilter && queryFilter.display_name,
                                  },
                                }
                              : { customerId: selectedCustomer }),
                            is_all_customer: _.get(
                              data,
                              "is_all_customer",
                              false
                            ),
                            typ: "RESTORE_DEFAULTS",
                          });
                        },
                        formContent: () => {
                          return <></>;
                        },
                        cancelCallback: () => {},
                        isChildren: false,
                        image: Warning,
                        successButtonType: "success",
                        buttonText: "Reset",
                        closeButtonText: "Cancel",
                        isCloseAble: false,
                      });
                    }}
                  >
                    Reset to Default
                  </TextButton>
                </div>
              </div>
            ) : _.get(data, "data.doc.is_customer_portal_customized", false) ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #cdced9",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <Typography type="h3">
                    Personalized at consumer Level
                  </Typography>
                  <Typography subType="regular">
                    Click to Reset the Portal Settings
                  </Typography>
                </div>
                <div>
                  <TextButton
                    type="button"
                    kind={KIND.secondary}
                    size={SIZE.mini}
                    onClick={async () => {
                      handleModal({
                        title: "",
                        content: "Are you sure, you want to reset to default ?",
                        successCallback: () => {
                          resetCustomerPortal.mutateAsync();
                        },
                        formContent: () => {
                          return <></>;
                        },
                        cancelCallback: () => {},
                        isChildren: false,
                        image: Warning,
                        successButtonType: "success",
                        buttonText: "Reset",
                        closeButtonText: "Cancel",
                        isCloseAble: false,
                      });
                    }}
                  >
                    Reset to Default
                  </TextButton>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div style={{ margin: "10px 0px" }}>
              <CustomerPortalSettingLabel
                label={"is_invoice"}
                isActive={watch("is_invoice")}
                setActive={(data) => setValue("is_invoice", data)}
                caption={"Display Invoice information in the Portal"}
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        flexDirection: "row",
                        gap: "15px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div>
                          <Controller
                            name="invoice.is_allow_invoice_download"
                            control={control}
                            render={({ field }) => (
                              <CheckBoxBaseweb
                                {...field}
                                checked={field.value}
                                checkmarkType={STYLE_TYPE.default}
                                // disabled={true}
                              >
                                <HeadingXSmall>
                                  <FormattedMessage id="allow_invoice_download">
                                    allow_invoice_download
                                  </FormattedMessage>
                                </HeadingXSmall>
                              </CheckBoxBaseweb>
                            )}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <Controller
                            name="invoice.is_display_paid_invoices"
                            control={control}
                            render={({ field }) => (
                              <CheckBoxBaseweb
                                {...field}
                                checked={field.value}
                                checkmarkType={STYLE_TYPE.default}
                                // checked={true}
                              >
                                <HeadingXSmall>
                                  <FormattedMessage id="display_paid_invoices">
                                    display_paid_invoices
                                  </FormattedMessage>
                                </HeadingXSmall>
                              </CheckBoxBaseweb>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        flexDirection: "row",
                        gap: "15px",
                      }}
                    >
                      <div>
                        <Controller
                          name="invoice.is_allow_payment_receipt_download"
                          control={control}
                          render={({ field }) => (
                            <CheckBoxBaseweb
                              {...field}
                              checked={field.value}
                              checkmarkType={STYLE_TYPE.default}
                              // checked={true}
                            >
                              <HeadingXSmall>
                                <FormattedMessage id="allow_payment_receipt_download">
                                  Allow_payment_receipt_download
                                </FormattedMessage>
                              </HeadingXSmall>
                            </CheckBoxBaseweb>
                          )}
                        />
                      </div>
                      <div>
                        {/* <Controller
                          name="invoice.is_display_invoice_description"
                          control={control}
                          render={({ field }) => (
                            <CheckBoxBaseweb
                              {...field}
                              checked={field.value}
                              checkmarkType={STYLE_TYPE.default}
                              // checked={true}
                            >
                              <HeadingXSmall>
                                <FormattedMessage id="is_display_invoice_description">
                                  Allow_payment_receipt_download
                                </FormattedMessage>
                              </HeadingXSmall>
                            </CheckBoxBaseweb>
                          )}
                        /> */}
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            {/* Credit Note */}
            {/* Payment */}
            <div>
              <CustomerPortalSettingLabel
                label={"is_payment"}
                isActive={watch("is_payment")}
                setActive={(data) => setValue("is_payment", data)}
                disable={
                  smsGateWay &&
                  smsGateWay.customer_portal &&
                  !smsGateWay.customer_portal.is_payment
                }
                caption={
                  _.get(data, "data.doc.is_configured", "")
                    ? ""
                    : "Get Paid Online or Payment Confirmation"
                }
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Controller
                          name="payment.is_pay_all_dues"
                          control={control}
                          render={({ field }) => (
                            <CheckBoxBaseweb
                              {...field}
                              checked={field.value}
                              checkmarkType={STYLE_TYPE.default}
                            >
                              <HeadingXSmall>
                                <FormattedMessage id="pay_all_dues">
                                  pay_all_dues
                                </FormattedMessage>
                              </HeadingXSmall>
                            </CheckBoxBaseweb>
                          )}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <Controller
                          name="payment.is_pay_overdues"
                          control={control}
                          render={({ field }) => (
                            <CheckBoxBaseweb
                              {...field}
                              checked={field.value}
                              checkmarkType={STYLE_TYPE.default}
                            >
                              <HeadingXSmall>
                                <FormattedMessage id="pay_overdues">
                                  pay_overdues
                                </FormattedMessage>
                              </HeadingXSmall>
                            </CheckBoxBaseweb>
                          )}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <Controller
                          name="payment.is_adhoc_payment"
                          control={control}
                          render={({ field }) => (
                            <CheckBoxBaseweb
                              {...field}
                              checked={field.value}
                              checkmarkType={STYLE_TYPE.default}
                            >
                              <HeadingXSmall>
                                <FormattedMessage id="adhoc_payment">
                                  adhoc_payment
                                </FormattedMessage>
                              </HeadingXSmall>
                            </CheckBoxBaseweb>
                          )}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            {/* Message */}
            {/* Call Back */}
            <div style={{ marginBottom: "20px" }}>
              <CustomerPortalSettingLabel
                label={"is_callback"}
                isActive={watch("is_callback")}
                checkBox={true}
                setActive={(data) => setValue("is_callback", data)}
                caption={"Consumers can request Call Back"}
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <div style={{ width: "260px" }}>
                        <Controller
                          name="callback.callback_days"
                          control={control}
                          render={({ field }) => (
                            <MultiSelect
                              {...field}
                              multi
                              name={field.name}
                              // label={intl.formatMessage({
                              //   id: "callback_1",
                              // })}
                              placeholder={intl.formatMessage({
                                id: "callback",
                              })}
                              value={field.value}
                              options={referenceData["week_days"]}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "260px" }}>
                        <Controller
                          name="callback.callback_duration"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              clearable={false}
                              size={SIZE.default}
                              style={{ marginTop: "0px" }}
                              {...field}
                              name={field.name}
                              label={intl.formatMessage({
                                id: "duration",
                              })}
                              placeholder={intl.formatMessage({
                                id: "duration",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                                // onChange();
                              }}
                              options={referenceData["call_back_duration"]}
                            />
                          )}
                        />
                      </div>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div style={{ width: "110px" }}>
                          <Controller
                            name="callback.start_time"
                            control={control}
                            render={({ field }) => (
                              <SelectBox
                                clearable={false}
                                style={{ marginTop: "0px" }}
                                {...field}
                                size={SIZE.default}
                                name={field.name}
                                label={intl.formatMessage({
                                  id: "start",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "start",
                                })}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                options={referenceData["org_time"]}
                              />
                            )}
                          />
                        </div>
                        <div style={{ width: "110px" }}>
                          <Controller
                            defaultValues={""}
                            name="callback.end_time"
                            control={control}
                            render={({ field }) => (
                              <SelectBox
                                clearable={false}
                                style={{ marginTop: "0px" }}
                                {...field}
                                size={SIZE.default}
                                name={field.name}
                                label={intl.formatMessage({
                                  id: "end",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "end",
                                })}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                options={referenceData["org_time"]}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            {/* Request Back */}
            <div style={{ paddingBottom: "5px" }}>
              <CustomerPortalSettingLabel
                label={"is_request_back"}
                isActive={watch("is_request_back")}
                checkBox={true}
                setActive={(data) => setValue("is_request_back", data)}
                caption={"Consumers can request additional Payment Time"}
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        // height: "80px",
                        // backgroundColor: "#FFFFFF",
                        // paddingLeft: "25px",
                        paddingTop: "5px",
                        // paddingBottom: "25px",
                        // paddingRight: "25px",
                        // alignItems: "center",
                        // borderLeft: "0.5px solid #CDCED9",
                        // borderRight: "0.5px solid #CDCED9",
                        // borderBottom: "0.5px solid #CDCED9",
                        // borderBottomLeftRadius: "5px ",
                        // borderBottomRightRadius: "5px",
                        // // gap: "90px",
                        // justifyContent: "space-between",
                        // overflow: "hidden",
                      }}
                    >
                      <div style={{ width: "260px" }}>
                        <Controller
                          name="request_back.amount_restriction"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              clearable={false}
                              size={SIZE.default}
                              style={{ marginTop: "0px" }}
                              {...field}
                              name={field.name}
                              label={intl.formatMessage({
                                id: "amnt_restriction",
                              })}
                              placeholder={intl.formatMessage({
                                id: "amnt_restriction",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              options={
                                referenceData["payment_amount_restriction"]
                              }

                              // options={
                              //   referenceData["payment_amount_restriction"]
                              // }
                            />
                          )}
                        />
                      </div>

                      <div style={{ width: "260px" }}>
                        <Controller
                          name="request_back.restrict_date"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              clearable={false}
                              style={{ marginTop: "0px" }}
                              {...field}
                              size={SIZE.default}
                              name={field.name}
                              label={intl.formatMessage({
                                id: "restrict_date",
                              })}
                              placeholder={intl.formatMessage({
                                id: "restrict_date",
                              })}
                              options={
                                referenceData["payment_extension_duration"]
                              }
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                            />
                          )}
                        />
                      </div>

                      <div style={{ width: "260px" }}>
                        <Controller
                          name="request_back.amount_percentage"
                          control={control}
                          rules={{
                            max: {
                              value: 100,
                              message: "Enter percentage between 0 to 100", // JS only: <p>error message</p> TS only support string
                            },
                          }}
                          render={({ field }) => (
                            <>
                              <TextBox
                                size={SIZE.default}
                                {...field}
                                type="number"
                                disabled={
                                  paymentAmountRestriction === "no_validation"
                                }
                                maxLength={3}
                                // clearOnEscape
                                fullWidth
                                name={field.name}
                                error={
                                  errors[field.name] &&
                                  errors[field.name].message
                                }
                                label={intl.formatMessage({
                                  id: "percentage",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "percentage",
                                })}
                                value={field.value}
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            {/* PaymentPlan */}
            <div style={{ marginBottom: "" }}>
              <CustomerPortalSettingLabel
                label={"is_payment_plan"}
                isActive={watch("is_request_payment_plan")}
                checkBox={true}
                setActive={(data) => setValue("is_request_payment_plan", data)}
                caption={"Consumers can request Payment Plan"}
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        gap: "10px",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ width: "230px" }}>
                        <Controller
                          name="payment_plan.instalmentextension"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              style={{ marginTop: "0px" }}
                              {...field}
                              name={field.name}
                              clearable={false}
                              label={intl.formatMessage({
                                id: "instalment_extension",
                              })}
                              placeholder={intl.formatMessage({
                                id: "instalment_extension",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                                // onChange();
                              }}
                              options={
                                referenceData["payment_plan_maximum_instalment"]
                              }
                              size={SIZE.default}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "200px" }}>
                        <Controller
                          name="payment_plan.frequency"
                          control={control}
                          render={({ field }) => (
                            <>
                              <MultiSelect
                                {...field}
                                multi
                                name={field.name}
                                // label={intl.formatMessage({
                                //   id: "callback_1",
                                // })}
                                placeholder={intl.formatMessage({
                                  id: "payment_frequency",
                                })}
                                value={field.value}
                                options={
                                  referenceData["payment_plan_frequency"]
                                }
                                onChange={(e) => {
                                  field.onChange(e);
                                }}
                              />
                            </>
                          )}
                        />
                      </div>
                      <div style={{ width: "200px" }}>
                        <Controller
                          name="payment_plan.residual"
                          control={control}
                          rules={{
                            max: {
                              value: 100,
                              message: "Enter percentage between 0 to 100", // JS only: <p>error message</p> TS only support string
                            },
                          }}
                          render={({ field }) => (
                            <SelectBox
                              clearable={false}
                              style={{ marginTop: "0px" }}
                              {...field}
                              name={field.name}
                              label={intl.formatMessage({
                                id: "payment_residual",
                              })}
                              placeholder={intl.formatMessage({
                                id: "payment_residual",
                              })}
                              size={SIZE.default}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              disabled={
                                payment_plan?.instalmentextension[0]?.label ==
                                "No Validation"
                              }
                              options={referenceData["payment_plan_residual"]}
                            />
                          )}
                        />
                      </div>
                      <div
                        style={{
                          width: "300px",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "260px",
                          }}
                        >
                          <Controller
                            name="payment_plan.disc_slb"
                            control={control}
                            rules={{
                              max: {
                                value: 100,
                                message: "Enter percentage between 0 to 100", // JS only: <p>error message</p> TS only support string
                              },
                            }}
                            render={({ field }) => (
                              <SelectBox
                                clearable={false}
                                style={{ marginTop: "0px" }}
                                {...field}
                                name={field.name}
                                label={intl.formatMessage({
                                  id: "discount_slab",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "discount_slab",
                                })}
                                size={SIZE.default}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                disabled={
                                  payment_plan?.instalmentextension[0]?.label ==
                                  "No Validation"
                                }
                                options={
                                  CommissionSlab &&
                                  CommissionSlab.data &&
                                  CommissionSlab.data.data &&
                                  CommissionSlab.data.data.docs &&
                                  Array.isArray(
                                    CommissionSlab.data.data.docs
                                  ) &&
                                  CommissionSlab.data.data.docs
                                    .map((e) => {
                                      let max =
                                        e?.slab_range_list?.[0]?.percentage;
                                      let min =
                                        e?.slab_range_list?.[0]?.percentage;
                                      for (
                                        let i = 1;
                                        i < e?.slab_range_list?.length;
                                        i++
                                      ) {
                                        if (
                                          e?.slab_range_list?.[i].percentage >
                                          max
                                        ) {
                                          max =
                                            e?.slab_range_list?.[i].percentage;
                                        }
                                        if (
                                          e?.slab_range_list?.[i].percentage <
                                          min
                                        ) {
                                          min =
                                            e?.slab_range_list?.[i].percentage;
                                        }
                                      }
                                      return {
                                        id: e._id,
                                        label: `${e.slab_name} (${min}% - ${max}%)`,
                                        ...e,
                                      };
                                    })
                                    .sort((a, b) =>
                                      a?.label?.localeCompare(b.label)
                                    )
                                }
                              />
                            )}
                          />
                          <div
                            className="add_discont_slan"
                            onClick={() => {
                              setisModalState({
                                isOpen: true,
                                data: {},
                              });
                            }}
                          >
                            <Icon icon="plus" color="#516beb" />
                            <Typography> Add Discount Slab</Typography>
                          </div>
                        </div>
                        {payment_plan &&
                          payment_plan.disc_slb &&
                          payment_plan.disc_slb[0] && (
                            <div style={{ marginTop: "5px" }}>
                              <IconButton
                                type="button"
                                kind={KIND.primary}
                                onClick={() => {
                                  setisModalState({
                                    type: VIEW,
                                    isOpen: true,
                                    data: payment_plan && payment_plan.disc_slb,
                                  });
                                }}
                              >
                                <Icon icon="view_icon" />
                              </IconButton>
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            {/* is_per_payment_plan */}
            <div>
              <CustomerPortalSettingLabel
                label={"is_per_payment_plan"}
                isActive={watch("is_pre_defined_payment_plan")}
                checkBox={true}
                setActive={(data) =>
                  setValue("is_pre_defined_payment_plan", data)
                }
                caption={"Consumers can request Payment Plan"}
                children={
                  <>
                    <div>
                      {fields &&
                        fields.map((e, i) => {
                          return (
                            <div
                              key={e.id}
                              style={{
                                display: "flex",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                gap: "10px",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "260px" }}>
                                <Controller
                                  name={`pre_defined_payment_plan.${i}.payment_of`}
                                  control={control}
                                  shouldUnregister={true}
                                  render={({ field }) => (
                                    <TextBox
                                      {...field}
                                      //   disabled={isDisable}
                                      fullWidth
                                      name={field.name}
                                      type="text"
                                      size={SIZE.default}
                                      error={
                                        errors &&
                                        errors["pre_defined_payment_plan"] &&
                                        errors["pre_defined_payment_plan"][i] &&
                                        errors["pre_defined_payment_plan"][i][
                                          "payment_of"
                                        ] &&
                                        errors["pre_defined_payment_plan"][i][
                                          "payment_of"
                                        ].message
                                      }
                                      label={intl.formatMessage({
                                        id: "of_pay",
                                      })}
                                      placeholder={intl.formatMessage({
                                        id: "of_pay",
                                      })}
                                      onChange={(e) => {
                                        const value = e.target.value.replace(
                                          /\D/g,
                                          ""
                                        ); // Allow only digits
                                        field.onChange(value);
                                      }}
                                      // value={field.value}
                                      onKeyDown={(e) => {
                                        if (
                                          e.key == "ArrowUp" ||
                                          e.key == "ArrowDown"
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div style={{ width: "260px" }}>
                                <Controller
                                  name={`pre_defined_payment_plan.${i}.frequency`}
                                  control={control}
                                  shouldUnregister={true}
                                  render={({ field }) => (
                                    <>
                                      <SelectBox
                                        clearable={false}
                                        style={{ marginTop: "0px" }}
                                        {...field}
                                        name={field.name}
                                        label={intl.formatMessage({
                                          id: "frequency",
                                        })}
                                        placeholder={intl.formatMessage({
                                          id: "frequency",
                                        })}
                                        error={
                                          errors &&
                                          errors["pre_defined_payment_plan"] &&
                                          errors["pre_defined_payment_plan"][
                                            i
                                          ] &&
                                          errors["pre_defined_payment_plan"][i][
                                            "frequency"
                                          ] &&
                                          errors["pre_defined_payment_plan"][i][
                                            "frequency"
                                          ].message
                                        }
                                        size={SIZE.default}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        disabled={
                                          payment_plan?.instalmentextension[0]
                                            ?.label == "No Validation"
                                        }
                                        options={
                                          referenceData[
                                            "pre_defined_payment_plan_frequency"
                                          ]
                                        }
                                      />
                                    </>
                                  )}
                                />
                              </div>
                              <div style={{ width: "260px" }}>
                                <Controller
                                  name={`pre_defined_payment_plan.${i}.leadtime`}
                                  control={control}
                                  shouldUnregister={true}
                                  render={({ field }) => (
                                    <TextBox
                                      {...field}
                                      //   disabled={isDisable}
                                      fullWidth
                                      name={field.name}
                                      size={SIZE.default}
                                      error={
                                        errors &&
                                        errors["pre_defined_payment_plan"] &&
                                        errors["pre_defined_payment_plan"][i] &&
                                        errors["pre_defined_payment_plan"][i][
                                          "leadtime"
                                        ] &&
                                        errors["pre_defined_payment_plan"][i][
                                          "leadtime"
                                        ].message
                                      }
                                      label={intl.formatMessage({
                                        id: "max_lead_time",
                                      })}
                                      placeholder={intl.formatMessage({
                                        id: "max_lead_time",
                                      })}
                                      value={field.value}
                                      onKeyDown={(e) => {
                                        if (
                                          e.key == "ArrowUp" ||
                                          e.key == "ArrowDown"
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div style={{ width: "260px" }}>
                                <Controller
                                  name={`pre_defined_payment_plan.${i}.discount`}
                                  control={control}
                                  shouldUnregister={true}
                                  render={({ field }) => (
                                    <TextBox
                                      {...field}
                                      fullWidth
                                      name={field.name}
                                      size={SIZE.default}
                                      error={
                                        errors &&
                                        errors.pre_defined_payment_plan &&
                                        errors.pre_defined_payment_plan[i] &&
                                        errors.pre_defined_payment_plan[i]
                                          .discount &&
                                        errors.pre_defined_payment_plan[i]
                                          .discount.message
                                      }
                                      label={intl.formatMessage({
                                        id: "discount",
                                      })}
                                      placeholder={intl.formatMessage({
                                        id: "discount",
                                      })}
                                      value={field.value}
                                      onKeyDown={(e) => {
                                        if (
                                          e.key == "ArrowUp" ||
                                          e.key == "ArrowDown"
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              {i > 0 ? (
                                <div style={{ paddingBottom: "15px" }}>
                                  <TextButton
                                    type="button"
                                    kind={KIND.tertiary}
                                    size={TextBoxSize.SIZE.mini}
                                    onClick={() => {
                                      remove(i);
                                    }}
                                  >
                                    <Delete color="#FD372A" />
                                  </TextButton>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                    </div>
                    <div style={{ widows: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <TextButton
                          type="button"
                          kind={KIND.tertiary}
                          size={TextBoxSize.SIZE.mini}
                          onClick={() =>
                            append({
                              payment_of: "",
                              leadtime: "",
                              frequency: "",
                              discount: "",
                            })
                          }
                        >
                          <Plus fill={"#516BEB"} />
                          <span className="new_payment_plan">
                            Add New Schedule
                          </span>
                        </TextButton>
                      </div>
                    </div>
                  </>
                }
              />
            </div>
          </div>
          <div style={{ padding: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: isAllRowsSelected
                  ? "space-between"
                  : "flex-end",
              }}
            >
              {isAllRowsSelected && (
                <div>
                  <Controller
                    name="is_all_customer"
                    control={control}
                    render={({ field }) => (
                      <CheckBoxBaseweb {...field} checked={field.value}>
                        <ParagraphMedium style={{ color: "#333860" }}>
                          Update all the matching consumer records
                        </ParagraphMedium>
                      </CheckBoxBaseweb>
                    )}
                  />
                </div>
              )}
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "50px", marginRight: "15px" }}>
                  <TextButton
                    type="button"
                    size={SIZE.mini}
                    fullWidth
                    disabled={isSubmitting}
                    kind={KIND.tertiary}
                    onClick={() => {
                      if (bulkAssignCustomer) {
                        setOpenCustomPortal(false);
                      } else {
                        closeDrawer();
                      }
                    }}
                  >
                    Cancel
                  </TextButton>
                </div>

                <div style={{ width: "50px", marginRight: "15px" }}>
                  <TextButton
                    type="submit"
                    size={SIZE.mini}
                    fullWidth
                    disabled={postBulkSelectSetting.isLoading}
                    isLoading={postBulkSelectSetting.isLoading}
                  >
                    Apply
                  </TextButton>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <DiscountSlabModal
        isModalState={isModalState}
        setisModalState={setisModalState}
        refetch={CommissionSlab.refetch}
      />
    </>
  );
};

export default CustomerPortalSettings;
