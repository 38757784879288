import React, { useMemo, useState, useEffect, useRef, useContext } from "react";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getGridStructure } from "../../services";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { updateTableDraftState } from "../../redux/customerSummary/action";
import { REPORT_DOWNLOAD_ } from "../../constants";
import columnMapper from "../../utils/mapper";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import Loader from "../../components/Loader";
import { getReportDownloadList } from "../../services/revenueBilling";
import PaginationWraper from "../../components/Pagination/Pagination";
import { Icon } from "../../components_v2";
import { reportSummaryDownload } from "../../services/customerSummary";
import useFormat from "../../hooks/useFormat";
import _ from "lodash";
const TableBody = ({ page, prepareRow, getTableBodyProps }) => {
  const { currentOrganization, referenceData } = useContext(MaxyfiContext);
  const format = useFormat();
  return (
    <>
      <div className="customer__tabel__body" {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          const downloadFileName = referenceData["generated_report_type"].find(
            (type) => type.id === row?.original.type
          );
          const formattedDateAndTime = format.dateTime({
            value: _.get(row, "original.gen_at", null),
            customFormat: "YYYY-MM-DD_HHmm", // Custom format for date and time
            includeTime: true, // Ensure time is included
            month: false, // Ensure month format is standard
          });

          const finalFileName = `${downloadFileName?.label}_${formattedDateAndTime}`;
          return (
            <React.Fragment key={rowProps.key}>
              <div
                key={index}
                className={`table-main__body-row toggle-btn__relate ${
                  row.isExpanded ? "expanded" : ""
                }`}
                {...row.getRowProps()}
                // style={{ gap: "15px" }}
              >
                {row.cells.map((cell) => {
                  return (
                    <div
                      className="table-main__body-cell"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}

                <div style={{ padding: "10px 10px" }}>
                  <Icon
                    icon="download_outline"
                    color="#516beb"
                    isPressable
                    onClick={async () => {
                      await reportSummaryDownload({
                        organization: currentOrganization,
                        reportId: row?.original?._id,
                        fileName:
                          _.get(row, "original.type") === "REV_EXT"
                            ? _.get(row, "original.reference", finalFileName)
                            : finalFileName
                            ? finalFileName
                            : downloadFileName?.label,
                      });
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

const TableBodyMemo = React.memo(TableBody, (prevProps, nextProps) => {
  return nextProps.isColumnResizing;
});

const ReportDownloadTable = ({ refetchingList }) => {
  const { currentOrganization } = useContext(MaxyfiContext);
  const [columnData, setColumnData] = useState([]);
  const expandedRowId = useRef(null);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [querySortBy, setQuerySortBy] = useState("");
  const [queryFilter, setQueryFilter] = useState({});
  const [width, setWidth] = useState(0);

  let ref = useRef();

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `REPORT_DOWNLOAD_${currentOrganization}_UPH`,
      {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
      return await getReportDownloadList({
        organization: currentOrganization,
        pageIndex,
        pageSize,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const userListApi = async () => {
      let userListVal = await getGridStructure({
        organization: currentOrganization,
        menu: "report_download_grid",
      });
      setColumnData(userListVal?.data?.doc?.table);
    };
    userListApi();
  }, []);

  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
    }
  }, [ref]);

  const columns = useMemo(() => {
    let { columns } = columnMapper({
      columns: columnData,
      isExpanded: false,
      isSelection: false,
      isExpandedHeader: false,
      width: width - 150,
    });
    return columns;
  }, [columnData]);

  const tabledata = useMemo(
    () => (isFetched && !isError && data?.data?.docs ? data.data.docs : []),
    [data, isFetched]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    visibleColumns,
    state: { pageIndex, pageSize, sortBy, filters },
    prepareRow,
    gotoPage,
    setSortBy,
    toggleRowExpanded,
    setPageSize,
  } = useTable(
    {
      columns,
      data: tabledata,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount:
        isFetched && !isError && data?.data ? data?.data?.totalPages : 0,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );
  useEffect(() => {
    if (pageIndex == queryPageIndex) {
      gotoPage(0);
    }
    setQueryPageIndex(pageIndex);
    setQueryPageSize(pageSize);
    setQuerySortBy(
      sortBy[0]
        ? sortBy[0].desc
          ? "-" + sortBy[0].id
          : "" + sortBy[0].id
        : "-_id"
    );
    let filterObj = {};
    filters.map((e) => {
      if (e.value) {
        if (Array.isArray(e.value)) {
          filterObj[e.id] = e.value.filter((val) => val);
        } else {
          filterObj[e.id] = e.value;
        }
      }
    });
    setQueryFilter(filterObj);
  }, [pageIndex, pageSize, sortBy, filters]);

  return (
    <>
      <div
        className="table-main user-table__main"
        {...getTableProps()}
        style={{ overflow: "hidden" }}
      >
        <div className="table-main-container" ref={ref}>
          <div className="table-main__head">
            {headerGroups.map((headerGroup) => (
              <DragDropContext>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(droppableProvided, snapshot) => (
                    <>
                      <div
                        className="table-main__head-container"
                        {...headerGroup.getHeaderGroupProps()}
                        ref={droppableProvided.innerRef}
                      >
                        <div className="table-main__head-row">
                          {headerGroup.headers.map((column, index) => (
                            <Draggable
                              key={column.id}
                              draggableId={column.id}
                              index={index}
                              isDragDisabled={!column.accessor}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    className="table-main__head-cell-wrapper"
                                  >
                                    <div
                                      className="table-main__head-cell"
                                      {...column.getHeaderProps()}
                                    >
                                      {column.render("Header")}
                                      <div
                                        // className="table-main__head-cell-drag-n-drop"
                                        {...provided.dragHandleProps}
                                      >
                                        {/* {column.disableDND === true ? null : (
                                          <DragDropHorizontal />
                                        )} */}
                                      </div>

                                      {column.canResize && (
                                        <a
                                          className="table-main__head-cell-resizer"
                                          {...column.getResizerProps()}
                                        ></a>
                                      )}
                                    </div>
                                  </div>
                                );
                              }}
                            </Draggable>
                          ))}
                          <div style={{ width: "55px" }}></div>
                        </div>
                      </div>
                    </>
                  )}
                </Droppable>
              </DragDropContext>
            ))}
          </div>
          {isLoading ? (
            <Loader />
          ) : tabledata && tabledata.length === 0 ? (
            <p
              style={{
                paddingTop: "10px",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              No Records Found
            </p>
          ) : (
            <TableBodyMemo
              page={page}
              prepareRow={prepareRow}
              getTableBodyProps={getTableBodyProps}
              visibleColumns={visibleColumns}
            />
          )}
        </div>
      </div>
      <PaginationWraper
        pageSize={pageSize}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default ReportDownloadTable;
