import React, { useContext, useState } from "react";
import { TextBox, SIZE as sizeBox } from "../../TextBox";
import { useIntl } from "react-intl";
import { LabelLarge, ParagraphMedium, LabelMedium } from "baseui/typography";
import SearchIcon from "../../svg/SearchIcon";
import { NumericFormat } from "react-number-format";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { useQuery } from "react-query";
import { globalInvoiceSearch } from "../../../services/customerSummary";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { Skeleton } from "baseui/skeleton";
import { getRecentVisitCusInv } from "../../../services";
import { Typography } from "../../../components_v2";
import useFormat from "../../../hooks/useFormat";
import _ from "lodash";
import Loader from "../../Loader";
function InvoiceSearch({
  debounceFilter,
  isLoading,
  data = [],
  isListFetching,
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");

  let {
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    referenceData,
    setGlobalFilter,
    currentOrganization,
    isGlobalSearch,
    setGlobalSearchDebounce,
  } = useContext(MaxyfiContext);
  const format = useFormat();
  const {
    isLoading: recentDataLoading,
    data: recentData,
    refetch,
    isFetching,
    isError,
  } = useQuery(
    [`recent-visit-invoice-${currentOrganization}`],
    async () => {
      return await getRecentVisitCusInv({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  function formatRecentTime(value) {
    if (value === null) {
      return "";
    } else {
      const now = new Date();
      const date = new Date(value);
      const diffMs = now - date;
      const diffMinutes = Math.floor(diffMs / (1000 * 60));
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      if (diffMinutes < 1) {
        return "Just now";
      } else if (diffMinutes < 60) {
        return `${diffMinutes} min ago`;
      } else if (diffHours < 24) {
        return `${diffHours} hr${diffHours > 1 ? "s" : ""} ago`;
      } else if (diffDays < 2) {
        return `Yesterday at ${date.toLocaleTimeString("en-US", options)}`;
      } else if (diffDays < 30 && now.getMonth() === date.getMonth()) {
        const dayOfWeek = date.toLocaleDateString("en-US", {
          weekday: "short",
        });
        return `${dayOfWeek} at ${date.toLocaleTimeString("en-US", options)}`;
      } else {
        const monthDay = date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        });
        return monthDay;
      }
    }
  }

  // if (isListFetching || isLoading) {
  //   return <Loader />;
  // }

  return (
    <div
      style={{
        height: "100%",
        overflow: "hidden",
        paddingTop: "20px",
        paddingBottom: "15px",
      }}
    >
      <div style={{ padding: "0px 12px" }}>
        <TextBox
          clearable
          size={sizeBox.mini}
          autoFocus
          value={isGlobalSearch.value}
          onChange={(e) => {
            let values = e.target.value;
            debounceFilter(values);
            setGlobalFilter({ ...isGlobalSearch, value: values });
          }}
          onKeyPress={(res) => {
            if (res.key == "Enter") {
              setGlobalSearchDebounce(isGlobalSearch.value);
            }
          }}
          // endEnhancer={<SearchIcon style={{ cursor: "pointer" }} size="18px" />}
          placeholder={intl.formatMessage({
            id: "Search by Invoice # & Reference",
          })}
        />
      </div>
      <div
        style={{
          height: "calc(100% - 36px)",
          overflowY: "scroll",
          paddingBottom: "0.5px",
        }}
      >
        {_.get(recentData, "data.docs", []) &&
        !isGlobalSearch.value &&
        _.get(recentData, "data.docs", []).length > 0 ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              type="p"
              className="text-secondary"
              style={{
                paddingLeft: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              Recent Visits
            </Typography>
            {_.get(recentData, "data.docs", []).length > 0 &&
              _.get(recentData, "data.docs", []).map((recentItem) => {
                return (
                  <div
                    className="recent_visit_container"
                    onClick={() => {
                      if (q && q.length > 0) {
                        navigate(`/customers/${recentItem?.res_id}?ib=true`);
                      } else {
                        navigate(`/customers/${recentItem?.res_id}`);
                      }
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Typography
                        type="h4"
                        style={{
                          color: "#333860",
                          maxWidth: "100%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {_.get(recentItem, "name", "-")}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <Typography
                          type="p"
                          className="text-secondary"
                          subType="regular"
                        >
                          {format.rd({
                            id: recentItem && recentItem?.bu,
                            name: "business_unit_list",
                          })}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",

                            gap: "3px",
                          }}
                        >
                          <Typography
                            type="p"
                            className="text-secondary"
                            subType="regular"
                          >
                            {" "}
                            {formatRecentTime(_.get(recentItem, "on", null))}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : isLoading || isListFetching ? (
          <>
            <div
              className={`cp__globalsearch `}
              style={{ padding: "6px 20px" }}
            >
              <div style={{ height: "15px" }}>
                <LabelLarge $style={{ color: "#333860" }}>
                  <Skeleton width="20%" height="16px" animation />
                </LabelLarge>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  gap: "3px",
                }}
              >
                <div className="dashboard__currency__wraper">
                  <div
                    style={{
                      height: "22px",
                      width: "55px",
                    }}
                  >
                    <Skeleton width="100%" height="16px" animation />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <div
                  style={{
                    height: "22px",
                    width: "55px",
                  }}
                >
                  <Skeleton width="100%" height="16px" animation />
                </div>
                <div
                  style={{
                    background: "#D9D9D9",
                    height: "5px",
                    width: "5px",
                    borderRadius: "50%",
                  }}
                ></div>
                <div
                  style={{
                    height: "22px",
                    width: "55px",
                  }}
                >
                  <Skeleton width="100%" height="16px" animation />
                </div>
              </div>
            </div>
          </>
        ) : data && data.length == 0 ? (
          <p
            style={{
              paddingTop: "10px",
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            No Records Found
          </p>
        ) : (
          data &&
          Array.isArray(data) &&
          data.map((e) => {
            const categoryFilter = e.business_unit
              ? referenceData &&
                referenceData["business_unit_list"].find(
                  ({ id }) => id === e.business_unit
                )
              : [];
            return (
              <div
                className={`cp__globalsearch ${
                  e._id === 0 ? "custmer__active" : " "
                }`}
                onClick={() => {
                  if (q && q.length > 0) {
                    navigate(
                      `/customers/${e?.pgmc ? e?.pgmc : e.customer_id}?ib=true`
                    );
                  } else {
                    navigate(`/customers/${e?.pgmc ? e?.pgmc : e.customer_id}`);
                  }
                }}
                style={{ padding: "6px 20px", height: "77px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <LabelMedium
                    $style={{
                      color: "#333860",
                      maxWidth: "160px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {e?.customer_name}
                  </LabelMedium>
                  &nbsp; /
                  <ParagraphMedium
                    $style={{
                      maxWidth: "127px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    &nbsp;{e?.invoice_number}
                  </ParagraphMedium>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    gap: "3px",
                  }}
                >
                  <div className="dashboard__currency__wraper">
                    <NumericFormat
                      displayType="text"
                      value={
                        e && e?.not_paid_amount && e?.not_paid_amount?.value
                          ? Number(
                              e?.not_paid_amount && e?.not_paid_amount?.value
                            )
                          : 0
                      }
                      thousandsGroupStyle={currencyGroupStyle}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={currencySeparator}
                      decimalSeparator={currencyDecimalSeparator}
                      renderText={(value) => (
                        <LabelMedium $style={{ color: "#333860" }}>
                          <sup style={{ fontSize: "9px" }}>
                            {e?.not_paid_amount?.currency}
                          </sup>{" "}
                          {value}
                        </LabelMedium>
                      )}
                    />
                  </div>
                </div>

                <ParagraphMedium
                  $style={{
                    color: "#787878",
                    maxWidth: "268px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {categoryFilter?.label}
                </ParagraphMedium>
              </div>
            );
          })
        )}
      </div>
      {/* */}
    </div>
  );
}

export default InvoiceSearch;
