import React, { useMemo, useState, useEffect, useRef, useContext } from "react";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { getGridStructure } from "../../services";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { updateTableDraftState } from "../../redux/customerSummary/action";
import {
  BUSINESS_UNIT_MAINTENANCE,
  GET_PERFORMANCE_DASHBOARD_,
  KPI_MAINTENANCE,
} from "../../constants";
import columnMapper from "../../utils/mapper";
import { Input } from "baseui/input";
import { Search } from "baseui/icon";

import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
import {
  disableInvoiceItem,
  getInvoiceListAudit,
} from "../../services/invoiceItem";
import Loader from "../../components/Loader";
import {
  getKpiMaintenanceList,
  getPerformanceList,
  getRevenueBillingList,
} from "../../services/revenueBilling";
import TaxRateAudit from "../Setting/TypeContainer/TaxRateMaintenance/TaxRateAudit";
import PaginationWraper from "../../components/Pagination/Pagination";
import { Icon, Typography } from "../../components_v2";
import { LineChartPerformance } from "./PerformanceChart/LineChartPerformance";
import BarchartPerformance from "./PerformanceChart/BarchartPerformance";
import _ from "lodash";
import useFormat from "../../hooks/useFormat";
import { inputOverrides } from "../../components/SelectBox";
import { useIntl } from "react-intl";
import DatepickerBs from "../../components/Datepicker/DatepickerBs";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const performanceActionType = [
  {
    label: "Payment Collected",
    type: "cla",
  },
  {
    label: "Commission Collected",
    type: "cma",
  },
  {
    label: "Call",
    type: "clc",
  },
  {
    label: "Email",
    type: "emc",
  },
  {
    label: "SMS",
    type: "smsc",
  },
  {
    label: "Letter",
    type: "ltc",
  },
  {
    label: "Field Visit",
    type: "fldc",
  },
  {
    label: "Notes",
    type: "ntc",
  },
  {
    label: "Dispute",
    type: "disc",
  },
  {
    label: "Promsie To Pay",
    type: "prc",
  },
  {
    label: "Payment Plan",
    type: "ppc",
  },
  {
    label: "WhatsApp",
    type: "whc",
  },
];
const TableBody = ({
  page,
  prepareRow,
  getTableBodyProps,
  setIsPerformance,
}) => {
  return (
    <>
      <tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          return (
            <tr
              key={index}
              {...row.getRowProps()}
              // style={{ gap: "15px" }}
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    onClick={() => {
                      if (
                        cell &&
                        cell.column &&
                        cell.column.cell_type ===
                          "PERFORMANCE_ACTION_INDICATION"
                      ) {
                        setIsPerformance({
                          isChartOpen: true,
                          user_id:
                            cell &&
                            cell.row &&
                            cell.row.original &&
                            cell.row.original.usi,
                          filter_type: cell && cell.column && cell.column.id,
                        });
                      }
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

const TableBodyMemo = React.memo(TableBody, (prevProps, nextProps) => {
  return nextProps.isColumnResizing;
});

const PerformanceDashboardTable = ({
  refetchingList,
  setPerformanceHiddenData,
  filterSearch,
  setPerformanceColumn,
  setFilterSearch,
  queryPageIndex,
  queryPageSize,
  querySortBy,
  startDate,
  setQueryPageIndex,
  setQuerySortBy,
  setQueryPageSize,
  setStartDate,
}) => {
  let intl = useIntl();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isAudit, setIsAudit] = useState({});

  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  const [columnData, setColumnData] = useState([]);
  const expandedRowId = useRef(null);

  const [queryFilter, setQueryFilter] = useState({});
  const [width, setWidth] = useState(0);
  const [value, setValue] = React.useState({
    startDate: null,
    endDate: null,
  });

  const format = useFormat();

  const [isPerformance, setIsPerformance] = useState({
    isChartOpen: false,
    dateRange: null,
    filter_type: "",
  });

  // const [endDate, setEndDate] = useState(null);

  const handleChange = ([newStartDate, newEndDate]) => {
    setValue({ startDate: newStartDate, endDate: newEndDate });
    // setEndDate(newEndDate);
  };

  let ref = useRef();

  const dispatch = useDispatch();
  // TODO: VIGNESH
  let reduxData = useSelector((state) => state.customerSummary.tableState);
  let refetchUser = useSelector((state) => state.userReducer.isFetched);

  const { data, isFetched, isError, isLoading, isFetching } = useQuery(
    [
      `${GET_PERFORMANCE_DASHBOARD_}_${currentOrganization}`,
      {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: querySortBy,
        filters: {
          ...(filterSearch && filterSearch.length > 0
            ? { display_name: filterSearch }
            : {}),
          ...(startDate
            ? {
                month: [
                  moment
                    .tz(startDate, currentDefaultFormatDetails.time_zone)
                    .startOf("month")
                    .utc()
                    .valueOf(),
                  moment
                    .tz(startDate, currentDefaultFormatDetails.time_zone)
                    .endOf("month")
                    .utc()
                    .valueOf(),
                ],
              }
            : {}),
        },
        // refetchUser,
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
      return await getPerformanceList({
        organization: currentOrganization,
        pageIndex,
        sortBy,
        pageSize,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: gridStructureData,
    isLoading: gridPerformanceLoading,
    isError: errorPerformanceGrid,
  } = useQuery(
    ["GRID_STRUCTURE-performace_dashBoard"],
    async () =>
      await getGridStructure({
        organization: currentOrganization,
        menu: "performance_dashboard_grid",
      }),
    {
      staleTime: 86400000, // 24 hours
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (gridStructureData) {
      setColumnData(gridStructureData.data.doc.table);
    }
  }, [gridStructureData]);

  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
    }
  }, [ref]);

  const columns = useMemo(() => {
    let { columns } = columnMapper({
      columns: columnData,
      isExpanded: false,
      isSelection: false,
      isExpandedHeader: false,
      width: width - 150,
    });
    setPerformanceColumn(columns);
    setPerformanceHiddenData(columnData);
    return columns;
  }, [columnData]);

  const tabledata = useMemo(() => {
    const performanceData = _.get(data, "data.data", []);
    if (performanceData && performanceData.length > 0) {
      let x = Math.max(...performanceData.map((item) => item && item.clc));

      let maxValues = {
        call: Math.max(
          ...performanceData.map((item) => (item.clc ? item.clc : null))
        ),
        email: Math.max(
          ...performanceData.map((item) => (item?.emc ? item?.emc : null))
        ),
        sms: Math.max(
          ...performanceData.map((item) => (item?.smsc ? item?.smsc : null))
        ),
        letter: Math.max(
          ...performanceData.map((item) => (item?.ltc ? item?.ltc : null))
        ),
        field_visit: Math.max(
          ...performanceData.map((item) => (item?.fldc ? item?.fldc : null))
        ),
        note: Math.max(
          ...performanceData.map((item) => (item?.ntc ? item?.ntc : null))
        ),
        dispute: Math.max(
          ...performanceData.map((item) => (item?.disc ? item?.disc : null))
        ),
        ptp: Math.max(
          ...performanceData.map((item) => (item?.prc ? item?.prc : null))
        ),
        ppl: Math.max(
          ...performanceData.map((item) => (item?.ppc ? item?.ppc : null))
        ),
        whs: Math.max(
          ...performanceData.map((item) => (item?.whc ? item?.whc : null))
        ),
      };

      // Add bool properties to the objects indicating if they have the maximum value
      return (
        performanceData
          .map((item) => ({
            ...item,
            clc_max_value: item.clc !== maxValues.call && maxValues.call,
            emc_max_value: item.emc !== maxValues.email && maxValues.email,
            smsc_max_value: item.smsc !== maxValues.sms && maxValues.sms,
            ltc_max_value: item.ltc !== maxValues.letter && maxValues.letter,
            fldc_max_value:
              item.fldc !== maxValues.field_visit && maxValues.field_visit,
            ntc_max_value: item.ntc !== maxValues.note && maxValues.note,
            disc_max_value:
              item.disc !== maxValues.dispute && maxValues.dispute,
            prc_max_value: item.prc !== maxValues.ptp && maxValues.ptp,
            ppc_max_value: item.ppc !== maxValues.ppl && maxValues.ppl,
            whc_max_value: item.whc !== maxValues.whs && maxValues.whs,
          }))
          .sort((a, b) => a?.dsn?.localeCompare(b?.dsn)) || []
      );
    } else {
      return [];
    }
  }, [isLoading, isFetching]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    visibleColumns,
    state: { pageIndex, pageSize, sortBy, filters, columnResizing, expanded },
    prepareRow,
    setHiddenColumns,
    setColumnOrder,
    allColumns,
    gotoPage,
    setSortBy,
    toggleRowExpanded,
    setPageSize,
  } = useTable(
    {
      columns,
      data: tabledata,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount:
        isFetched && !isError && data?.data ? data?.data?.totalPages : 0,
    },
    useFilters,
    useSortBy,
    // useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  const currentColOrder = React.useRef();
  const isColumnResizing = !!columnResizing.isResizingColumn;

  useEffect(() => {
    if (reduxData.sort) {
      let isAsc = reduxData.sort.startsWith("-");
      setSortBy([{ id: reduxData.sort.replace("-", ""), desc: !isAsc }]);
    }
  }, [reduxData.sort]);

  useEffect(() => {
    let rowIds = Object.keys(expanded);

    if (rowIds.length > 1) {
      toggleRowExpanded(
        rowIds.filter((e) => e == expandedRowId.current),
        false
      );
    }

    expandedRowId.current = rowIds[0];
  }, [expanded]);

  useEffect(() => {
    if (!isColumnResizing) {
      dispatch(updateTableDraftState(allColumns));
    }
  }, [isColumnResizing, sortBy, filters]);

  useEffect(() => {
    setHiddenColumns(columnData);
  }, [columnData]);

  useEffect(() => {
    if (pageIndex == queryPageIndex) {
      gotoPage(0);
    }
    setQueryPageIndex(pageIndex);
    setQueryPageSize(pageSize);
    setQuerySortBy(
      sortBy[0]
        ? sortBy[0].desc
          ? "-" + sortBy[0].id
          : "" + sortBy[0].id
        : "-_id"
    );
    let filterObj = {};
    filters.map((e) => {
      if (e.value) {
        if (Array.isArray(e.value)) {
          filterObj[e.id] = e.value.filter((val) => val);
        } else {
          filterObj[e.id] = e.value;
        }
      }
    });
    setQueryFilter(filterObj);
  }, [pageIndex, pageSize, sortBy, filters]);

  return (
    <div className="pfr_db_table_chart_container">
      <div
        className="pfr_db_table_access"
        {...getTableProps()}
        // style={{ overflow: "hidden" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#ffffff",
            padding: "5px",
            borderRadius: "5px 5px 0px 0px",
            border: "1px solid #cdced9",
            borderBottom: "0px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <div style={{ width: "250px" }}>
              <Input
                clearable
                autoComplete="off"
                autoFill="off"
                size={SIZE.mini}
                placeholder={"Search By User Name"}
                // value={viewName}
                endEnhancer={<Search size="18px" />}
                overrides={{ ...inputOverrides }}
                value={filterSearch}
                onChange={(val) => {
                  let value = val.target.value;
                  setFilterSearch(value);
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Icon icon="alert_circle" color="#ADADAD" size={18} />
              <Typography
                type="p"
                style={{ color: "#ADADAD", marginTop: "2px" }}
              >
                Click Action Datasets to know Stats
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div
              style={{
                width: "200px",
                paddingLeft: "5px",
              }}
              className="pre_dash_date_picker"
            >
              <ReactDatePicker
                selected={startDate}
                // onChange={handleChange}
                onChange={(date) => setStartDate(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                placeholderText="Select Month"
                // minDate={subMonths(new Date(), 6)}
                // maxDate={addMonths(new Date(), 6)}
              />
            </div>
            {isPerformance.isChartOpen && (
              <Icon
                icon="expand_right"
                isPressable
                onClick={() => {
                  setIsPerformance({ isChartOpen: false });
                }}
              />
            )}
          </div>
        </div>
        <table>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {isLoading || gridPerformanceLoading ? (
            <Loader />
          ) : tabledata && tabledata.length === 0 ? (
            <p
              style={{
                paddingTop: "10px",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              No Records Found
            </p>
          ) : (
            <TableBodyMemo
              isColumnResizing={isColumnResizing}
              page={page}
              prepareRow={prepareRow}
              getTableBodyProps={getTableBodyProps}
              visibleColumns={visibleColumns}
              setIsOpen={setIsOpen}
              setIsAudit={setIsAudit}
              setIsPerformance={setIsPerformance}
            />
          )}
        </table>
      </div>

      {isPerformance.isChartOpen && (
        <div className="pfr_chart_container">
          <Typography className="pfr_title_style">
            Stats:{" "}
            {format.rd({
              id: _.get(isPerformance, "user_id", ""),
              name: "users",
            })}
          </Typography>
          <div className="pfr_filter_container">
            {performanceActionType.map((e) => (
              <div
                key={e.type}
                className={`co-call-outcome-btn ${
                  isPerformance.filter_type === e.type
                    ? "co-call-outcome-btn--active"
                    : ""
                }`}
                onClick={() => {
                  setIsPerformance({ ...isPerformance, filter_type: e.type });
                }}
              >
                <Typography>{e.label}</Typography>
              </div>
            ))}
          </div>

          <LineChartPerformance
            isPerformance={isPerformance}
            setIsPerformance={setIsPerformance}
            value={value}
          />
          <Typography>User Positioning</Typography>
          <BarchartPerformance
            isPerformance={isPerformance}
            setIsPerformance={setIsPerformance}
            value={value}
          />
        </div>
      )}
    </div>
  );
};

export default PerformanceDashboardTable;
