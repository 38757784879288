import React, { useContext, useEffect, useState } from "react";
import {
  Label1,
  Label2,
  Paragraph1,
  ParagraphLarge,
  ParagraphSmall,
} from "baseui/typography";
import { TextButton, SIZE } from "../TextButton";
import { IconButton, KIND } from "../IconButton";
import { Controller, useForm } from "react-hook-form";
import { SelectBox } from "../SelectBox";
import { useIntl } from "react-intl";
import * as ModalButton from "../TextButton";
import {
  getWorkflowlist,
  assignWorkflow,
  getWorkflow,
  getOrganization,
  getCustomerContactDetail,
  assignMulCusWfRel,
  assignMulCusWfSgyRel,
} from "../../services";
import getSelectValues from "../../utils/getSelectValues";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment-timezone";
import DateRangeSelect from "../TableFilters/DateRangeFilter/DateRangeSelect";
import { faSlash } from "@fortawesome/free-solid-svg-icons";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import { STRATEGY_MAINTENTENCE_LIST } from "../../constants";
import { getStrategiclist } from "../../services/strategyMainteinance";
import _ from "lodash";

const AssignWorkflowStrategyForm = ({
  selectedCustomer,
  handleModal,
  toggleAllRowsSelected,
  customerRefetch,
  isAllRowsSelected,
  queryFilter,
}) => {
  const intl = useIntl();
  let maxyfiContextValue = useContext(MaxyfiContext);

  const { tableCurrentState, gridStructure } = useSelector(
    (state) => state.customerSummary
  );

  let {
    currentOrganization,
    referenceData,
    refetch,
    appAccessRest,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      start_date: new Date(),
      is_all_customer: false,
    },
  });

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];

  const getParams = getSearchParams.get("customers");

  const assignWorkflowMutation = useMutation(
    (workflowData) =>
      assignMulCusWfSgyRel({
        ...workflowData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
          toggleAllRowsSelected(false);
          customerRefetch();
          handleModal();
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);
        reset();
        handleModal();
        customerRefetch();
        refetch({
          ...maxyfiContextValue,
          setLoader: false,
          currentOrganization: currentOrganization,
        });
      },
    }
  );

  const { filters, globalFilters } = useSelector(
    (s) => s.customerSummary.tableCurrentState
  );

  const onSubmit = async (data) => {
    let newFilter = dynamicQueryFilter({
      gridStructure: gridStructure,
      timeZone: currentDefaultFormatDetails.time_zone,
      customerFilter: filters,
    });

    let newValues = {
      wf_sgy: getSelectValues(data.wf_sgy),

      ...(data.is_all_customer
        ? {
            query: newFilter ? newFilter : {},
            filters: {
              ...globalFilters,
              ...appAccessRest,
              needs_actions: [...NeedsActionFilter],
              stage: [...FilteredCustomerStage],
              search_value: queryFilter && queryFilter.display_name,
            },
          }
        : { customerId: selectedCustomer }),
      is_all_customer: data.is_all_customer,
      typ: "AWQ",
    };

    await assignWorkflowMutation.mutateAsync(newValues);
  };

  const { data: wfSty, ...wfStyRest } = useQuery(
    [
      `${STRATEGY_MAINTENTENCE_LIST}_${currentOrganization}`,
      {
        page: 1,
        page_size: 0,
        sortBy: "-_id",
      },
    ],
    async ({ queryKey }) => {
      let { page, page_size, sortBy, filters, type } = queryKey[1];
      return await getStrategiclist({
        organization: currentOrganization,
        page,
        page_size,
        sort: sortBy,
        filters,
        type,
      });
    }
  );

  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ParagraphLarge style={{ display: "flex", paddingBottom: "5px" }}>
          Select the workflow strategy to be used
        </ParagraphLarge>

        <Controller
          name="wf_sgy"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              size={SIZE.compact}
              // disabled={isDisabled}
              requiredAstric={true}
              // onInputChange={(evt) => {
              //   fetchWorkflow({ name: evt.target.value });
              // }}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "select_workflow_sgy",
              })}
              placeholder={intl.formatMessage({
                id: "select_workflow_sgy",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={_.get(wfSty, "data.docs", [])
                .filter((e) => e.ieb === true)
                .map((e) => ({
                  id: e._id,
                  label: e.nam,
                }))}
            />
          )}
        />

        {isAllRowsSelected && (
          <Controller
            name="is_all_customer"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall style={{ color: "#333860" }}>
                  Update all the matching consumer records
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        )}

        <div
          style={{
            // marginTop: "15px",
            textAlign: "center",
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
            // paddingBottom: "20px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={ModalButton.SIZE.compact}
              kind={KIND.tertiary}
              // onClick={close}
              onClick={() => {
                // dispatch(workflowAddModal(false));
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={ModalButton.SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Apply
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default AssignWorkflowStrategyForm;
