import moment from "moment";
import React from "react";
import Typography from "./Typography";
import millify from "millify";
import { MaxyfiContext } from "../providers/MaxyfiProvider";
import { useContext } from "react";
import Icon from "./Icon";
import AllFilters from "../components/AllFilters/AllFilters";
import { Input, SIZE } from "baseui/input";
import { FormattedMessage, useIntl } from "react-intl";
import { inputOverrides } from "../components/TextBox";
import { useNavigate } from "react-router-dom";
import {
  getCustomerSummaryGridStructure,
  setCusOverviewPageIndex,
  setCustomerStageFilter,
  setCustomerSummaryFilter,
  setCustomerSummaryGridAndView,
  setCustomerSummaryPageIndex,
  setCustomerSummaryPageSize,
  setCustomerSummaryTableView,
  setNeedsActionFilter,
} from "../redux/customerSummary/action";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { useSelector } from "react-redux";
import setTableGridStructure from "../utils/setTableGridStructure";
import { useEffect } from "react";
import { useState } from "react";
import { Search } from "baseui/icon";
import { useQuery } from "react-query";
import { CUSTOMER_OVERVIEW } from "../constants";
import dynamicQueryFilter from "../utils/dynamicQueryFilter";
import { customerData } from "../services/customerSummary";
import queryClient from "../providers/queryClient";
import Loader from "../components/Loader";
import { TextButton } from "../components/TextButton";
import PaginationWraper from "../components/Pagination/Pagination";
import { Pagination } from "baseui/pagination";

const ActionTypes = ({ type }) => {
  switch (type) {
    case "call":
      return <Icon icon="phone_status" />;

    case "sms":
      return <Icon icon="sms" />;

    case "dispute":
      return <Icon icon="dispute" />;

    case "email":
      return <Icon icon="email_status" />;

    case "promise":
      return <Icon icon="promise_to_pay" />;

    case "review_promise":
      return <Icon icon="review_promise" />;

    case "review_dispute":
      return <Icon icon="dispute" />;

    case "whatsapp":
      return <Icon icon="whatsapp_status" />;

    case "FIELD_VISIT":
      return <Icon icon="field_visit" />;

    case "letter":
      return <Icon icon="letter_status" />;

    case "review_payment_plan":
      return <Icon icon="payment_plan" />;

    default:
      return null;
  }
};

const CustomerListCard = ({
  customerId,
  gotoPage,
  queryFilter,
  setQueryFilter,
}) => {
  let intl = useIntl();
  let navigate = useNavigate();
  const { tableCurrentState, gridStructure, overViewPageIndex } = useSelector(
    (state) => state.customerSummary
  );
  const [filterSearch, setFilterSearch] = useState("");
  let {
    currentOrganization,
    userPersonalization,
    currentDefaultFormatDetails,
    customCustomer,
    appAccessRest,
  } = useContext(MaxyfiContext);

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];
  let localPageSize = localStorage.getItem("customer_page_size");

  let newFilter = dynamicQueryFilter({
    gridStructure,
    timeZone: currentDefaultFormatDetails.time_zone,
    customerFilter: tableCurrentState.filters,
  });

  const queryKey = {
    pageSize: localPageSize ? localPageSize : tableCurrentState.pageSize,
    ...(newFilter.rules.length ? { customerFilter: newFilter } : {}),

    filters: {
      ...tableCurrentState.globalFilters,
      ...appAccessRest,
      ...(NeedsActionFilter ? { needs_actions: [...NeedsActionFilter] } : []),
      ...(FilteredCustomerStage ? { stage: [...FilteredCustomerStage] } : []),
      search_value: queryFilter.display_name,
    },
  };

  const { data, isLoading, status, ...rest } = useQuery(
    [
      `${CUSTOMER_OVERVIEW}-${currentOrganization}`,
      { ...queryKey, pageIndex: overViewPageIndex },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters, customerFilter } =
        queryKey[1];

      return await customerData({
        organization: currentOrganization,
        pageIndex,
        sortBy,
        pageSize,
        filters,
        pg_type: "R",
        customerFilter: newFilter,
      });
    },
    {
      enabled: gridStructure._id ? true : false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const debounceFilter = useDebouncedCallback((value) => {
    setQueryFilter({ display_name: value });
  }, 1000);

  const CustomFilter = useSelector(
    (s) => s.customerSummary.tableCurrentState.formFilters
  );

  const CustomerStageFilter = useSelector(
    (s) => s.customerSummary.tableCurrentState.customer_stage
  );

  let dispatch = useDispatch();

  const filterReduxData = useSelector(
    (s) => s.customerSummary.tableCurrentState.formFilters
  );

  const NeedsActionData = useSelector(
    (s) => s.customerSummary.tableCurrentState.needs_action
  );
  const gridStructureValues = useSelector(
    (s) => s.customerSummary.gridStructure.table
  );

  let { viewName, gridAndViewLoaded } = useSelector(
    (state) => state.customerSummary
  );

  useEffect(() => {
    const menu = "customer_summary";
    if (!viewName && !gridAndViewLoaded) {
      setTableGridStructure({
        menu,
        currentOrganization,
        setGridStructureAction: getCustomerSummaryGridStructure,
        setViewAction: setCustomerSummaryTableView,
        setGridAndViewAction: setCustomerSummaryGridAndView,
        userPersonalization,
        dispatch,
        isSetGridAndView: true,
        customFields:
          customCustomer && Array.isArray(customCustomer) ? customCustomer : [],
      });
      // dispatch(setTableWidth(width));
    }
  }, []);

  // if (isLoading) {
  //   return <Loader />;
  // }

  let pageSizeStore = localPageSize
    ? localPageSize
    : tableCurrentState.pageSize;

  return (
    <>
      <div className="co_customer_expand_container">
        <div
          onClick={() => navigate("/customers")}
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <Icon icon="chevron_left" size={24} color="#516beb" />
          <Typography type="h3" style={{ color: "#516beb" }}>
            <FormattedMessage id="customers">customers</FormattedMessage>{" "}
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginLeft: "5px",
          marginBottom: "5px",
        }}
      >
        <Input
          autoComplete="off"
          autoFill="off"
          size={SIZE.compact}
          placeholder={intl.formatMessage({
            id: "search",
          })}
          clearable
          value={
            filterSearch
              ? filterSearch
              : queryFilter.display_name === undefined
              ? ""
              : queryFilter.display_name
          }
          endEnhancer={<Search size="18px" />}
          overrides={{ ...inputOverrides }}
          // value={isSearchData.display_name}
          onChange={(val) => {
            let value = val.target.value;
            debounceFilter(value);
            setFilterSearch(value);
          }}
        />
        <AllFilters
          setQueryFilter={setQueryFilter}
          //  setPageIndex={gotoPage}
          filterReduxData={filterReduxData}
          stageFilter={CustomerStageFilter}
          NeedsAction={NeedsActionData}
          name={"Custom"}
          title={"custom"}
          gridStructureValues={gridStructureValues}
          mainFilter={(data) => dispatch(setCustomerSummaryFilter(data))}
          needsActionFilter={setNeedsActionFilter}
          customerStageFilter={setCustomerStageFilter}
          pageIndex={setCustomerSummaryPageIndex}
          mainFilterDataLength={CustomFilter}
          needsActionFilterDataLength={NeedsActionFilter}
          customerStageFilterDataLength={CustomerStageFilter}
          id={"customer"}
        />
      </div>
      <div style={{ height: "calc(100vh - 120px)", overflow: "scroll" }}>
        {isLoading ? (
          <Loader />
        ) : status === "error" ? (
          <p
            style={{
              paddingTop: "10px",
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            Unable to Load Data, Kindly Try Again
          </p>
        ) : data &&
          data.data &&
          Array.isArray(data?.data?.docs) &&
          !data?.data?.docs?.length ? (
          <div>
            <p
              style={{
                paddingTop: "10px",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              No Records Found
            </p>
            {/* <Typography>No Records Found</Typography> */}
          </div>
        ) : data && data.data && Array.isArray(data?.data?.docs) ? (
          data &&
          data?.data &&
          data?.data?.docs?.map((e) => (
            <div
              className={`cp__customer__container ${
                e._id === customerId ? "co_custmer_active" : " "
              }`}
              onClick={() => {
                navigate(`/customers/${e?.pgmc ? e?.pgmc : e?._id}`);
              }}
            >
              <div style={{ flex: "1" }}>
                <Typography type="p">{e.display_name}</Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "3px",
                  }}
                >
                  <span className="cp_currency__style">
                    {e.total_inv_amount.currency}{" "}
                  </span>
                  <Typography type="h4">
                    {millify(
                      e &&
                        e.total_outstanding_invoice_amount &&
                        e.total_outstanding_invoice_amount?.value
                        ? e.total_outstanding_invoice_amount?.value
                        : 0,
                      {}
                    )}
                    {/* {e.total_inv_amount.value ? e.total_inv_amount.value : 0} */}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                  className="co_expand_list_icon_container"
                >
                  {e && e.active_next_action_type && (
                    <div className="co_expand_icon_wraper">
                      <ActionTypes type={e.active_next_action_type} />
                    </div>
                  )}

                  <Typography
                    type="p"
                    subType="subType"
                    style={{ color: "#787878" }}
                  >
                    {e.active_next_action_date
                      ? moment
                          .utc(e.active_next_action_date)
                          .tz(currentDefaultFormatDetails.time_zone)
                          .format(currentDefaultFormatDetails.date_format)
                      : " "}
                  </Typography>
                </div>
                {/* <HeadingXSmall>{e.next_action_name}</HeadingXSmall> */}
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
      <div style={{ textAlign: "end" }}>
        <TextButton
          size={"mini"}
          kind="secondary"
          onClick={() => {
            dispatch(setCusOverviewPageIndex(overViewPageIndex + 1));
            // setCurrentPage(currentPage + 1);
          }}
          disabled={
            Number(data?.data?.docs?.length) !== Number(pageSizeStore) ||
            !data?.data?.docs?.length
          }
        >
          Next <Icon icon="chevron_right" size={16} color={"#516beb"} />
        </TextButton>
      </div>
    </>
  );
};

export default CustomerListCard;
