export function getStatusChg(status, subStatus) {
  if (status === "INVALID") {
    return "INVALID";
  } else if (status === "DNC") {
    return "DNC";
  } else if (subStatus === "INVALID") {
    return "INVALID";
  } else if (subStatus === "DNC") {
    return "DNC";
  } else if (status === "VALID" && subStatus === "INVALID") {
    return "INVALID";
  } else if (status === "VALID" && subStatus === "DNC") {
    return "DNC";
  } else if (status === "VALID" && subStatus === "VALID") {
    return "VALID";
  } else if (status === "INVALID" && subStatus === "VALID") {
    return "INVALID";
  } else if (status === "DNC" && subStatus === "VALID") {
    return "DNC";
  } else if (status === "LEAD" && subStatus === "DNC") {
    return "DNC";
  } else if (status === "LEAD" && subStatus === "VALID") {
    return "LEAD";
  } else if (status === "LEAD" && subStatus === "INVALID") {
    return "INVALID";
  } else {
    return "VALID";
  }
}
