import React, { useContext, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { KIND, SIZE, TextButton } from "../../components/TextButton";
import { SelectBox } from "../../components/SelectBox";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import {
  HeadingXLarge,
  ParagraphLarge,
  ParagraphSmall,
} from "baseui/typography";
import { FormattedMessage, useIntl } from "react-intl";
import { LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import {
  editWorkListUsers,
  getOrganization,
  postOrganization,
} from "../../services";
import getSelectValues from "../../utils/getSelectValues";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useEffect } from "react";
import setSelectValues from "../../utils/setSelectValues";
import { TextArea } from "../../components/TextArea";
import _ from "lodash";
import { Icon, Typography } from "../../components_v2";
import { StatefulTooltip } from "baseui/tooltip";
import { TextBox } from "../../components/TextBox";
import useFormat from "../../hooks/useFormat";
import queryClient from "../../providers/queryClient";
import {
  EDIT_WORKLIST,
  REMOVE_USER_WORKLIST,
} from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import moment from "moment";
const EditingForm = ({
  isDrawer,
  referenceData,
  control,
  watch,
  setValue,
  errors,
  updateWorkListUserMutation,
  setIsDrawer,
  handleSubmit,
  onSubmit,
  removeUser,
  userListActive,
}) => {
  const intl = useIntl();
  const [isEditName, setEditName] = useState(false);
  const [isName, setWorkName] = useState(false);
  const format = useFormat();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ padding: "0px 10px 12px 10px" }}>
          <Typography type="h2">Worklist Edit</Typography>
        </div>
        <div style={{ display: "flex", gap: "5px", padding: "0px 10px" }}>
          <Controller
            name="nm"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <TextBox
                {...field}
                name={field.name}
                requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "worklist_name",
                })}
                placeholder={intl.formatMessage({
                  id: "worklist_name",
                })}
                value={field.value}
                size="mini"
                //   disabled={pageType == "VIEW_MM"}
              />
            )}
          />
          <Controller
            name="dt"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                name={field.name}
                // requiredAstric={true}
                // disabled={isDisabled}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "worklist_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                size={SIZE.mini}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
              />
            )}
          />
        </div>
        <div style={{ padding: "0px 10px" }}>
          <Controller
            control={control}
            name="cmt"
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <TextArea
                {...field}
                name={field.name}
                // requiredAstric={true}
                label={intl.formatMessage({
                  id: "comments",
                })}
                placeholder={intl.formatMessage({
                  id: "comments",
                })}
                size={SIZE.mini}
                value={field.value}
              />
            )}
          />
        </div>
        <div className="work-user-list">
          <table className="work-list-table">
            <thead className="work-lisit-thead">
              <tr className="work-lisit-tr">
                <th rowspan="2" className="work-lisit-th">
                  <Typography className="text-typo">User</Typography>
                </th>
                <th rowspan="2">
                  {" "}
                  <Typography className="text-typo"> Last Worked On</Typography>
                </th>
                <th rowSpan="2">
                  {" "}
                  <Typography className="text-typo"> Count </Typography>
                </th>
                <th rowSpan="2">
                  {" "}
                  <Typography className="text-typo"> Allocate </Typography>
                </th>
              </tr>
            </thead>

            <tbody>
              {userListActive.map((item, index) => {
                return (
                  <tr key={item._id}>
                    <td>
                      <div style={{ width: "113px" }}>
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "17px",
                          }}
                        >
                          {item.displayName}
                        </Typography>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          width: "113px",
                          //   display: "flex",
                          //   flexDirection: "column",
                          //   alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "17px",
                            color: _.get(item, "lst_act_dt", "") ? "" : "red",
                          }}
                        >
                          {_.get(item, "lst_act_dt", "")
                            ? format.date({ value: item?.lst_act_dt })
                            : "Yet to Start"}
                        </Typography>
                        {/* <Typography
                          style={{
                            color: "red",
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "17px",
                          }}
                        >
                          {_.get(matchedUser, "sts", "")}
                        </Typography> */}
                      </div>
                    </td>
                    <td>
                      <div style={{ width: "113px" }}>
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "17px",
                            textAlign: "center",
                          }}
                        >
                          {_.get(item, "prc_cnt", "-")}
                        </Typography>
                      </div>
                    </td>
                    {/* <td>
                      <Controller
                        name={`worklist[${index}]`}
                        control={control}
                        render={({ field }) => (
                          <div
                            style={{
                              width: "130px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CheckBoxBaseweb
                              checked={!!matchedUser} // Set checkbox to true if user is in alc_usr
                              onChange={(e) => {
                                field.onChange(e.target.checked);
                              }}
                            />
                          </div>
                        )}
                      />
                    </td> */}

                    <td>
                      <Controller
                        name={`worklist_enb[${index}]`}
                        control={control}
                        render={({ field }) => (
                          <div
                            style={{
                              width: "130px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CheckBoxBaseweb
                              checked={field.value} // Control checkbox state
                              onChange={(e) => {
                                field.onChange(e.target.checked);
                              }}
                              disabled={!removeUser}
                            />
                          </div>
                        )}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ width: "125px" }}>
            <TextButton
              fullWidth
              size={SIZE.mini}
              kind={KIND.tertiary}
              type="button"
              onClick={() => {
                setIsDrawer({ type: "", isOpen: false });
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ width: "125px" }}>
            <TextButton
              fullWidth
              size={SIZE.mini}
              type="submit"
              isLoading={updateWorkListUserMutation.isLoading}
              disabled={updateWorkListUserMutation.isLoading}
            >
              Save
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

const WorkListDrawerType = ({ setIsDrawer, isDrawer }) => {
  const maxyfiProviderData = useContext(MaxyfiContext);
  const {
    referenceData,
    currentOrganization,
    refetch,
    space,
    currentDefaultFormatDetails,
    users,
  } = maxyfiProviderData;

  const removeUser =
    space && space.role_permissions.includes(REMOVE_USER_WORKLIST);

  const {
    control,
    register,
    handleSubmit,
    unregister,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
    defaultValues: {},
  });

  const updateWorkListUser = useMutation(
    (data) =>
      editWorkListUsers({
        ...data,
        organization: currentOrganization,
        worklistId: _.get(isDrawer, "data._id", ""),
      }),
    {
      onError: (error) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data) => {
        queryClient.refetchQueries(`WORKLIST_MAIN-${currentOrganization}`);
        setIsDrawer({ type: "", isOpen: false, data: {} });
        // refetchMiniSetting();
      },
    }
  );

  const userListActive = useMemo(() => {
    let rm = users.filter((i) => i.active);

    let findingUser = rm?.filter((i) =>
      i.organization?.includes(currentOrganization)
    );

    let getUsers = findingUser
      .map((item) => {
        const matchedUser = _.get(isDrawer, "data.alc_usr", []).find(
          (usrItem) => usrItem.usr === item.id
        );
        return {
          ...item,
          ...matchedUser,
        };
      })
      .sort((a, b) => (a.usr === b.usr ? 1 : a.usr ? -1 : 1));

    if (Object.entries(isDrawer)?.length > 0) {
      setValue("nm", _.get(isDrawer, "data.nm", ""));
      setValue(
        "dt",
        isDrawer?.data?.dt ? new Date(_.get(isDrawer, "data.dt", null)) : null
      );
      setValue("cmt", _.get(isDrawer, "data.cmt", ""));
      getUsers?.forEach((item, index) => {
        setValue(`worklist_enb[${index}]`, item.usr ? true : false);
      });
    }
    return getUsers;
  }, [isDrawer.type]);

  const onSubmit = async (data) => {
    const enabledUsers = userListActive
      .map((item, index) => {
        return data.worklist_enb[index] ? item.id : null;
      })
      .filter(Boolean);
    let values = {
      nm: data.nm,
      ...(data?.cmt?.length ? { cmt: data.cmt } : {}),
      ...(data?.dt
        ? {
            dt: moment
              .tz(
                `${data.dt.getFullYear()}-${
                  data.dt.getMonth() + 1
                }-${data.dt.getDate()}`,
                "YYYY-MM-DD",
                currentDefaultFormatDetails.time_zone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      users: enabledUsers,
    };

    await updateWorkListUser.mutateAsync(values);
  };

  return (
    <>
      <EditingForm
        referenceData={referenceData}
        control={control}
        setValue={setValue}
        updateWorkListUserMutation={updateWorkListUser}
        setIsDrawer={setIsDrawer}
        errors={errors}
        watch={watch}
        isDrawer={isDrawer}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        removeUser={removeUser}
        userListActive={userListActive}
      />
    </>
  );
};

export default WorkListDrawerType;
