export const EMAIL = "email";
export const SMS = "sms";
export const LETTER = "letter";
export const LETTER_HEADER = "letter_header";
export const LETTER_CONTENT = "letter_content";
export const LETTER_FOOTER = "letter_footer";
export const INVOICE = "invoice";
export const INVOICE_UPDATE = "INVOICE_UPDATE";
export const SMSACTIVE = "smsactive";
export const NOTE = "note";
export const CALL = "call";
export const ESIGN = "e_sign";
export const ESIGNREPORT = "E_SIGN";
export const BOT = "bot";
export const NEXT = "next";
export const DISPUTE = "dispute";
export const HOLD = "hold";
export const PAYMENTS = "payments";
export const LETTER_STATUS = "letter_status";
export const REVIEW = "review";
export const SMTP = "smtp";
export const PROMISE = "promise";
export const CALLBACKLATER = "follow_up_call";
export const MEETINGREQUEST = "meeting_request";
export const HOLDACTION = "hold_action";
export const CALLUNSUCCESSFUL = "unsuccessful";
export const NEXTACTION = "NEXT_ACTION";
export const REVIEWPROMISE = "promise_to_pay";
export const UNSUCCESSFUL = "unsuccessful";
export const PROMISETOPAY = "PROMISE_TO_PAY";
export const CALL_DISPUTE = "RECORD_DISPUTE";
export const CALL_CALL_BACK_LATER = "CALL_BACK_LATER";
export const FIELD_UNSUCCESSFUL = "UNSUCCESSFULL";
export const CALL_UNSUCCESSFUL = "UNSUCCESSFUL";
export const GENERALINFO = "general_information";
export const CONTACTDETAILS = "contact_detail";
export const PAYMENTDETAILS = "payment";
export const CUSTOMERPORTAL = "customer_portal";
export const SMTPDETAILS = "smtp_server_detail";
export const EMAILREPLIES = "email_replies_detail";
export const SUBSCRIPTIONFREE = "freemium";
export const PREMIUM = "premium";
export const NEXTACTIONTRIGGER = "next_action";
export const ASSIGNRELATION = "assign_relation";
export const SETWORK = "set_work";
export const SUSPENDWORKFLOW = "suspend_workflow";
export const CAPTUREONHOLD = "capture_onhold";
export const RELEASEONHOLD = "release_onhold";
export const TAGS = "tags";
export const ISSEND = "is_send";
export const SKIPPEDTIMELINE = "action_skipped";
export const RELEASED = "release_action";
export const SMS_DETAILS = "sms_details";
export const ACCOUNTING_BOOKS = "accounting_books";
export const AUTOMATION_SETTING = "automation_setting";
export const OUTBOUND_EMAIL_SETTINGS = "outbound_email_settings";
export const DEFAULT_CONFIGURATION = "default_configuration";
export const CLOUD_TELEPHONY = "colud_telephony";
export const WHATSAPP = "whatsapp";
export const RECURRING_FEES = "recurring_fees";
export const TAX_RATE_MAINTENANCE = "tax_rate_maintenance";
export const TL_FIELD_VISIT = "FIELD_VISIT";
export const GENERIC_ACTION = "ACTION_CAPTURE";
export const TL_NOTES = "NOTES";
export const TL_PAYMENT = "app_payment";
export const TL_RELATION_MANAGER = "rel_manager";
export const UN_RELATION_MANAGER = "UA_RM";
export const TL_UPDATE_PROMISE_DATE = "UPDATE_PROMISE_DATE";
export const TL_UPDATE_PAYMENT_PLAN_DATE = "UPDATE_PAYMENT_PLAN_DATE";
export const TL_DOCUMENT_REQUEST_INFO = "DOCUMENT_REQUEST_INFO";
export const TL_CLARIFICATION_REQUEST_INFO = "CLARIFICATION_REQUEST_INFO";
export const TL_SKIP_ACTIONS = "action_skipped";
export const RM = "rel_manager";
export const WORKFLOW = "workflow";
export const WORKFLOW_STRATEGY = "WF_SGY";
export const BUSINESS_UNIT = "business_unit";
export const CUSTOMERT_STATUS = "customer_status";
export const NOTES = "notes";
export const CONTACTS = "contacts";
export const INSTANT_CALL_PAYMENT = "instant_payment";
export const PORTAL_PAYMENT = "portal_payment";
export const PLANNED = "Payment_Plan";
export const APP_PAYMENT = "app_payment";
export const PAYMENT_PLAN = "PAYMENT_PLAN";
export const CALL_RECORDING = "call_recording";
export const PAY = "pay";
export const PORTAL_PAYMENT_PLAN = "PORTAL_PAYMENT_PLAN";
export const CUSTOMER_NAME_UPDATE = "CUSTOMER_NAME_UPDATE";
export const Payment_Proof = "Payment_Proof";
export const FIELD_VISIT = "field_visit";
export const RETURN_VISIT = "return_visit";
export const SKIP_TRACE = "skip_trace";
export const ACT_PROMISE_TO_PAY = "PROMISE_TO_PAY";
export const ACT_PAYMENT_PLAN = "PAYMENT_PLAN";
export const ACT_AUTO_PAYMENT_PLAN = "ADPP";
export const ACT_CALL_BACK_LATER = "CALL_BACK_LATER";
export const ACT_PAYMENT = "PAYMENT";
export const ACT_RECORD_DISPUTE = "RECORD_DISPUTE";
export const ACT_FIELD_VISIT = "FIELD_VISIT";
export const ACT_RETURN_VISIT = "RETURN_VISIT";
export const ACT_NOTES = "NOTES";
export const ACT_UNSUCCESSFUL = "UNSUCCESSFUL";
export const NATCHA_FILE_CONFIG = "NATCHA_FILE_CONFIG";
export const LETTER_VENDOR = "LETTER_VENDOR";
export const CONSUMER_AUTHENTICATION = "CONSUMER_AUTHENTICATION";
export const CUSTOM_FIELDS = "CUSTOM_FIELDS";

export const CUSTOMIZE_CONSUMER_OVERVIEW = "CUSTOMIZE_CONSUMER_OVERVIEW";
export const LEGAL_NOTES = "NOTES";
export const LEGAL_DOCS = "DOCS";
export const LEGAL_DEBTS = "DEBTS";
export const LEGAL_DEFENDANTS = "DEFENDANTS";
export const LEGAL_MOTIONS = "MOTIONS";
export const LEGAL_COUNSEL = "COUNSEL";
export const LEGAL_STATUS = "LEGAL_STATUS";
export const LEGAL_TIMELINE = "LEGAL_TIMELINE";
export const EDIT_PROCESS_ACTION = "PS_GRP";
export const EDIT_CONUSMER_ACTION = "CS_GRP";
export const ACT_RETURN_FIELD_VISIT = "RETURN";
export const PAYEMENTPRIORITYANDBILLING = "payment_priority_billing";
export const LEGAL_TIMESHEET = "LEGAL_TIMESHEET";
export const LEGAL_AUDIT = "LEGAL_AUDIT";

export const BANKRUPTCY_FORM = "BANKRUPTCY_FORM";
export const BANKRUPTCY = "BNKR";
export const DATA_UPLOADED = "DATA_UPLOADED";
export const VERIFY_DELETION = "VERIFY_DELETION";
export const DATA_DELETED = "DATA_DELETED";
export const RESCHEDULE_NEXT_ACTION_DATE = "RESCHEDULE_NEXT_ACTION_DATE";
export const INVA_ADJ = "INVA_ADJ";
export const REVERSE_PAYMENT_TMD = "RV_PAY";
export const PPL_CHNG = "PPL_CHNG";
export const DL_PAY_PLN = "DL_PAY_PLN";
export const INV_STS = "INV_STS";
