import {
  INVOICE_OVERVIEW_SUBSTATUS_DRAWER,
  MANAGE_COPY_INVIOCE_STATUS,
  MANAGE_EDIT_INVIOCE_STATUS,
  MANAGE_INVOICE_STATUS_DRAWER,
  RESET_INVOICE_STATUS,
  RESET_INVOICE_STATUS_FORM,
  SET_INVOICE_STATUS_AUDITSDATE,
} from "./action";

const initialState = {
  drawerState: false,
  invoice_status_data: {},
  isEdit: false,
  isCopy: false,
  invoice_status_drawer: false,
  invoice_audit_edit_data: {},
};

export const invoiceStatusMaintenanceReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case MANAGE_INVOICE_STATUS_DRAWER: {
      return {
        ...state,
        isEdit: payload?.isEdit,
        isCopy: payload?.isCopy,
        drawerState: payload?.drawerState,
        invoice_status_data: payload?.invoice_status_data,
      };
    }

    case MANAGE_EDIT_INVIOCE_STATUS: {
      return {
        ...state,
        isCopy: payload?.isCopy,
        isEdit: payload?.isEdit,
        drawerState: payload?.drawerState,
        invoice_status_data: payload?.invoice_status_data,
      };
    }
    case MANAGE_COPY_INVIOCE_STATUS: {
      return {
        ...state,
        isEdit: payload?.isEdit,
        isCopy: payload?.isCopy,
        drawerState: payload?.drawerState,
        invoice_status_data: payload?.invoice_status_data,
      };
    }
    case INVOICE_OVERVIEW_SUBSTATUS_DRAWER: {
      return {
        ...state,
        invoice_status_drawer: payload?.invoice_status_drawer,
      };
    }
    case SET_INVOICE_STATUS_AUDITSDATE: {
      return {
        ...state,
        invoice_audit_edit_data:
          payload?.invoice_audit_edit_data ?? state.invoice_audit_edit_data,
      };
    }

    case RESET_INVOICE_STATUS: {
      return initialState;
    }
    default:
      return state;
  }
};
