import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useMutation, useQuery } from "react-query";

import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TextButton } from "../../../../components/TextButton";
import queryClient from "../../../../providers/queryClient";
import { CUSTOMER_OVERVIEW_, ACT_FIELD_VISIT } from "../../../../constants";
import getSelectValues from "../../../../utils/getSelectValues";
import ActionReminders from "../../../../components_v2/ActionReminders";
import getActionReminder from "../../../../utils_v2/getActionReminders";
import { Icon, Typography } from "../../../../components_v2";
import { useDebouncedCallback } from "use-debounce";
import { getHolidayBlackoutHolidayDate } from "../../../../services";
import setSelectValues from "../../../../utils/setSelectValues";

const FieldVisitForm = (props) => {
  const {
    formValues = {},
    service,
    onSuccess = () => {},
    onCancel = () => {},
    isConsent = true,
    isIncrementor = true,
    is_inbound_call = false,
    isStatus = false,
  } = props;
  const { customerId } = useParams();
  const intl = useIntl();

  let { referenceData, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const [location] = watch(["location"]);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const orgUsers = useMemo(() => {
    let orgBusiness = referenceData["users"].filter((e) => {
      return e?.organization?.includes(currentOrganization) && e && e.active;
    });
    return orgBusiness || [];
  }, []);

  const locations = useMemo(() => {
    const addressContacts = _.get(customerData, "data.doc.contacts", []).reduce(
      (prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = address
          .map(({ status, call_status, ...nRest }) => {
            let designation =
              referenceData && referenceData["recipient_type"]
                ? referenceData["recipient_type"].find(
                    (rf) => rf?.id === curr?.designation
                  )
                : "";

            return {
              label: `${curr?.first_name ? curr?.first_name : ""} - ${
                nRest.line_1
              }, ${nRest.line_2 ? nRest.line_2 : ""}, ${nRest.country}, ${
                nRest.city
              }-${nRest.zip_code} ${designation?.label}`,
              id: nRest._id,
              contactId: curr?._id,
              designation: designation.id,
              status,
            };
          })
          .filter((e) => e.status === "VALID" || e.status === "LEAD");
        return [...prev, ...nestedContacts];
      },
      []
    );

    let locOptions = [
      ...addressContacts,
      { id: "organization", label: "Organization Address" },
      { id: "other", label: "Other Location" },
    ];

    if (locOptions && locOptions.length > 0) {
      setValue("location", [locOptions && locOptions[0]]);
    }

    return locOptions;
  }, []);

  const serviceMutation = useMutation(
    (formData) =>
      service({
        ...formData,
        id: customerId,
        entity: "customer",
        organization: currentOrganization,
      }),
    {
      onSuccess: () => {
        onSuccess();
      },
    }
  );

  const [visitDate] = watch(["return_visit_date"]);

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: {
          date: visitDate
            ? [
                moment(visitDate)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf(),
              ]
            : null,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: customerId,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: visitDate ? true : false,
    }
  );

  const onSubmit = async (data) => {
    const {
      return_visit_date,
      return_visit_time,
      location,
      custom_location = "",
      assigned_to,
    } = data;

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      ...formValues,
      ...(isIncrementor ? { content: "-" } : {}),
      visit_date: moment
        .tz(
          `${return_visit_date.getFullYear()}-${
            return_visit_date.getMonth() + 1
          }-${return_visit_date.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails.time_zone
        )
        .utc()
        .valueOf(),
      assigned_to: getSelectValues(assigned_to),
      visit_time: getSelectValues(return_visit_time),
      ...(location && location[0] && location[0].id === "other"
        ? { custom_location }
        : {}),
      visit_address:
        location && location[0] && location[0].id && location[0].contactId
          ? `${location[0].contactId}/${location[0].id}`
          : getSelectValues(location),
      ...getActionReminder(data, {
        is_consent_enabled: isConsent,
        is_calendar: false,
        is_incrementor: isIncrementor,
      }),
      mention_users: uniqueMentionedUsers,
    };

    await serviceMutation.mutateAsync(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: "flex", gap: "5px", marginTop: "20px" }}>
        <div style={{ width: "200px" }}>
          <Controller
            name="return_visit_date"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                requiredAstric={true}
                label={intl.formatMessage({
                  id: "return_visit_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
              />
            )}
          />
        </div>
        <div style={{ width: "200px" }}>
          <Controller
            name="return_visit_time"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "return_visit_time",
                })}
                placeholder={intl.formatMessage({
                  id: "return_visit_time",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["time"] || []}
              />
            )}
          />
        </div>
        <div style={{ width: "250px" }}>
          <Controller
            name="assigned_to"
            control={control}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "assigned_to",
                })}
                placeholder={intl.formatMessage({
                  id: "assigned_to",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={orgUsers}
              />
            )}
          />
        </div>
      </div>
      {_.get(data, "data.doc.is_holiday") && (
        <>
          <div className="alert-holiday-section">
            <Icon icon="alert_circle" color="#516BEB" />
            <Typography
              type="p"
              style={{
                fontWeight: "400",
              }}
            >
              The Date Selected{" "}
              {_.get(data, "data.doc.holidays", []).map((val) => {
                return (
                  <>
                    {moment(val).format(
                      currentDefaultFormatDetails.date_format
                    )}
                  </>
                );
              })}{" "}
              is a <b>“Holiday”</b>
            </Typography>
          </div>
        </>
      )}

      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div style={{ width: "49%" }}>
          <Controller
            name="location"
            control={control}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "location",
                })}
                placeholder={intl.formatMessage({
                  id: "location",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={locations}
              />
            )}
          />
        </div>

        <div style={{ width: "49%" }}>
          {location && getSelectValues(location) === "other" ? (
            <Controller
              name="custom_location"
              control={control}
              render={({ field }) => (
                <TextBox
                  size={SIZE.compact}
                  {...field}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "type_your_location",
                  })}
                  placeholder={intl.formatMessage({
                    id: "type_your_location",
                  })}
                  value={field.value}
                />
              )}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType={formValues.action_type}
        is_inbound_call={
          formValues.is_inbound_call ? true : false || is_inbound_call
        }
        outcome={ACT_FIELD_VISIT}
        is_calendar={false}
        consentDate={visitDate}
        callValidationDuration={_.get(
          customerData,
          "data.doc.mini_miranda.call_validation_duration"
        )}
        timeZone={_.get(customerData, "data.doc.timezone")}
        is_consent_enabled={props.isConsent}
        is_incrementor={props.isIncrementor}
        is_valid_from_contact={
          formValues.action_type === "FIELD_VISIT" ||
          formValues.action_type === "ACTION_CAPTURE"
            ? true
            : false
        }
        is_status={isStatus}
      />

      <div
        style={{
          display: "flex",
          marginTop: "10px",
          gap: "10px",
          justifyContent: "flex-end",
        }}
      >
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          disabled={serviceMutation.isLoading}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </TextButton>
        <TextButton
          size="mini"
          disabled={serviceMutation.isLoading}
          isLoading={serviceMutation.isLoading}
        >
          Save
        </TextButton>
      </div>
    </form>
  );
};

const PromiseToPay = () => {
  const { customerId } = useParams();

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  return <div></div>;
};

export default FieldVisitForm;
