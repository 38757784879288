export const NEW = "NEW";
export const COPY = "COPY";
export const EDIT = "EDIT";
export const VIEW = "VIEW";
export const REPLY = "REPLY";
export const REPLY_ALL = "REPLY_ALL";
export const FORWARD = "FORWARD";
export const CAPTURE = "CAPTURE";
export const DATE_TIME = "DATE_TIME";
export const DATE_ORG = "DATE_ORG";
export const DATE = "DATE";
export const WORK_QUEUE = "WQ";
export const SELF = "SLF";
export const BUSINESS = "CNT";
export const MY_TEAM = "MYT";
export const WORKQUEUE = "WKQ";
export const TEST = "TEST";
export const COMMUNICATION_TEMPLATE_BUSINESS_FORM =
  "COMMUNICATION_TEMPLATE_BUSINESS_FORM";
export const REFERENCE = "REFERENCE";
export const STRING = "STRING";
export const DEFINED = "DEFINED";
export const REVERSE_PAYMENT = "REVERSE_PAYMENT";
export const CLIENT_REPORT_TITLE = "Client";
export const STATUS_REPORTS_TITLE = "Status";
export const RELATION_REPORTS_TITLE = "Relation Manager";
export const PREDEFINED_ADHOC = "PD_PY_WD";
export const PORTFOLIO_PAYMENT_REPORT_TITLE = "Portfolio";
