export const WORKLIST_DATA = "WORKLIST_DATA";
export const WORKLIST_RESPONCE_DATA = "WORKLIST_RESPONCE_DATA";

export const setWorklistData = (data) => {
  return {
    type: WORKLIST_DATA,
    payload: data,
  };
};

export const setWorklistResponceData = (data) => {
  return {
    type: WORKLIST_RESPONCE_DATA,
    payload: data,
  };
};
