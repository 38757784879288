import React, { useMemo } from "react";
import TitleHead from "../../../components_v2/TitleHead";
import { useParams } from "react-router-dom";
import queryClient from "../../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../../constants";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { useContext } from "react";
import { useNumericFormat } from "react-number-format";
import { HeadingXSmall, LabelMedium, ParagraphMedium } from "baseui/typography";
import _ from "lodash";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { SIZE, SelectBox } from "../../../components/SelectBox";
import { TextArea } from "../../../components/TextArea";
import { TextBox } from "../../../components/TextBox";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { useIntl } from "react-intl";
import { TextButton } from "../../../components/TextButton";
import getSelectValues from "../../../utils/getSelectValues";
import { useUpdateCustomFields } from "../../../services/customerOverviewContacts";
import { toast } from "react-toastify";
import Typography from "../../../components_v2/Typography";
import { setDrawerState } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import InputTypes from "./InputTypes";
import setSelectValues from "../../../utils/setSelectValues";
import { useMaxRateFields } from "../../../services";

const CustomFields = () => {
  const { customerId } = useParams();
  const intl = useIntl();
  const { customCustomer, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);
  let dispatch = useDispatch();
  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };
  let result = {};
  for (let obj of customCustomer) {
    let section = obj.section;

    if (result.hasOwnProperty(section)) {
      if (obj && !obj.idb) {
        result[section].push({ ...obj });
      }
    } else {
      if (obj && !obj.idb) {
        result[section] = [{ ...obj }];
      }
    }
  }

  const customFieldDefaultData = useMemo(() => {
    const setDefaultValue = {};
    for (let i = 0; i < customCustomer.length; i++) {
      let path = customCustomer[i].path;
      if (customerData?.data?.doc?.custom_field?.hasOwnProperty(path)) {
        if (customCustomer[i].data_type === "DATE") {
          if (customerData?.data?.doc?.custom_field[path]) {
            let dateMax = moment
              ?.utc(customerData?.data?.doc?.custom_field[path])
              ?.tz(currentDefaultFormatDetails.time_zone)
              ?.format(currentDefaultFormatDetails.date_format)
              .valueOf();
            setDefaultValue[path] = dateMax ? new Date(dateMax) : null;
          }
        } else if (customCustomer[i].data_type === "NUMBER") {
          if (customerData?.data?.doc?.custom_field?.[path]) {
            setDefaultValue[path] = customerData?.data?.doc?.custom_field[path];
          }
        } else if (
          customCustomer[i].data_type === "TEXT" &&
          customCustomer?.[i]?.options?.length > 0
        ) {
          if (customerData?.data?.doc?.custom_field[path]) {
            setDefaultValue[path] = setSelectValues(
              customerData?.data?.doc?.custom_field[path]
            );
          }
        } else {
          setDefaultValue[path] = customerData?.data?.doc?.custom_field[path];
        }
      }
    }
    return setDefaultValue;
  }, [customCustomer?.length]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...(customFieldDefaultData ? { ...customFieldDefaultData } : {}),
      ...(_.get(customerData, "data.doc.max_stl_prc", "")
        ? { max_stl_prc: _.get(customerData, "data.doc.max_stl_prc", "") }
        : {}),
      ...(_.get(customerData, "data.doc.dob", "")
        ? { dob: _.get(customerData, "data.doc.dob", "") }
        : {}),
    },
  });

  const { mutateAsync, isLoading } = useUpdateCustomFields();
  const maxRateFn = useMaxRateFields();

  let onSubmit = async (data) => {
    let value = {};
    for (let i = 0; i < customCustomer.length; i++) {
      let path = customCustomer?.[i]?.path;
      let dataType = customCustomer?.[i]?.data_type;
      let isVisible = customCustomer?.[i]?.is_hidden;
      let keys = path.split(".");
      let current = data;

      for (let j = 0; j < keys.length; j++) {
        if (current.hasOwnProperty(keys[j])) {
          current = current[keys[j]];
        }
        // else {
        //   value[path] = "not_found";
        //   break;
        // }
      }
      if (!isVisible) {
        if (dataType === "DATE") {
          if (current) {
            value[path] = moment
              .tz(
                `${current?.getFullYear()}-${
                  current?.getMonth() + 1
                }-${current?.getDate()}`,
                "YYYY-MM-DD",
                currentDefaultFormatDetails.time_zone
              )
              .utc()
              .valueOf();
          }
        } else if (dataType === "AMOUNT") {
          if (current.value) {
            value[path] = current;
          }
        } else if (
          dataType === "TEXT" &&
          customCustomer?.[i]?.options?.length > 0
        ) {
          value[path] = getSelectValues(current);
        } else if (dataType === "NUMBER") {
          if (current && current.toString().length > 0) {
            value[path] = current;
          }
        } else {
          value[path] = current;
        }
      }
    }

    let values = { custom_field: { ...value } };

    let maxRateData = {
      ...(data.max_stl_prc ? { max_stl_prc: +data?.max_stl_prc } : {}),
      ...(data.dob ? { dob: moment(data?.dob).utc().valueOf() } : {}),
    };

    await maxRateFn.mutateAsync({
      ...maxRateData,
      customerId: customerId,
      organization: currentOrganization,
    });

    if (customCustomer && customCustomer.length > 0) {
      await mutateAsync(
        {
          values,
          customerId: customerId,
          organization: currentOrganization,
          entity: "customer",
        },
        {
          onError: (error) => {
            if (error.response) {
              let { data } = error.response;
              toast.error(data.message);
            }
          },
          onSuccess: (data) => {
            closeDrawer();
            queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
          },
        }
      );
    }
  };

  return (
    customFieldDefaultData && (
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "534px" }}>
        <TitleHead title="Consumer Level Fields" />

        {/* <div style={{ display: "flex", gap: "2px" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "19px" }}>
            <ParagraphMedium>Customer Insurance</ParagraphMedium>
            <ParagraphMedium>:</ParagraphMedium>
          </div>
          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {_.get(customerData, "data.doc.insurance", "")}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "21px" }}>
            <ParagraphMedium>Customer Employer</ParagraphMedium>
            <ParagraphMedium>:</ParagraphMedium>
          </div>
          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {_.get(customerData, "data.doc.employer", "")}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Customer Date of Birth :</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {_.get(customerData, "data.doc.dob", "")
              ? moment
                  .utc(_.get(customerData, "data.doc.dob", ""))
                  ?.tz(currentDefaultFormatDetails?.time_zone)
                  ?.format(currentDefaultFormatDetails?.date_format)
              : ""}
          </ParagraphMedium>
        </div> */}
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Consumer ID :</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {" "}
            {_.get(customerData, "data.doc.customer_id", "")}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Create File Id :</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {" "}
            {_.get(customerData, "data.doc.created_file_id", "")}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Update File Id :</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {" "}
            {_.get(customerData, "data.doc.updated_file_id", "")}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Workflow Strategy :</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {" "}
            {_.get(customerData, "data.doc.wf_sgy_nam", "")}
          </ParagraphMedium>
        </div>
        <div
          style={{
            width: "450px",
            marginTop: "12px",
            display: "flex",
            gap: "5px",
          }}
        >
          <Controller
            name="max_stl_prc"
            control={control}
            rules={{
              min: {
                value: 0,
                message: "Value should be between 0 - 100",
              },
              max: {
                value: 100,
                message: "Value should be between 0 - 100",
              },
              // validate: (value) =>
              //   value === '' || !isNaN(value) || 'Value must be a number'
            }}
            render={({ field }) => (
              <TextBox
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: `max_settlement_rate`,
                })}
                placeholder={intl.formatMessage({
                  id: "max_settlement_rate",
                })}
                value={field.value}
                size={SIZE.mini}
                // disabled={pageType == VIEW ? true : false}
                type="number"
              />
            )}
          />
          <Controller
            name="dob"
            control={control}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                name={field.name}
                size={SIZE.mini}
                label={"D.O.B"}
                placeholder={"D.O.B"}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date("1900-01-01T00:00:00.000Z")}
              />
            )}
          />
        </div>

        {Object.keys(result)?.map((cus, i) => {
          return (
            <>
              <Typography type="h4" className="mb-12">
                {cus}
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                }}
              >
                {result &&
                  result[cus].map((e, i) => {
                    return (
                      !e.is_hidden && (
                        <div style={{ width: "258px" }}>
                          <InputTypes
                            name={e.name}
                            control={control}
                            currentDefaultFormatDetails={
                              currentDefaultFormatDetails
                            }
                            type={e.data_type}
                            path={e.path}
                            index={i}
                            arrayKey={cus}
                            options={e.options}
                            disabled={e.is_editable}
                          />
                        </div>
                      )
                    );
                  })}
              </div>
            </>
          );
        })}

        {/* {customCustomer.length > 0 && ( */}
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          <TextButton
            kind="tertiary"
            size="mini"
            type="button"
            disabled={isLoading}
            onClick={() => {
              closeDrawer();
            }}
          >
            Cancel
          </TextButton>
          <TextButton
            size="mini"
            type="submit"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Submit
          </TextButton>
        </div>
        {/* )} */}
      </form>
    )
  );
};

export default CustomFields;
