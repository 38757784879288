import { StatefulTooltip } from "baseui/tooltip";
import { Paragraph2, ParagraphSmall } from "baseui/typography";
import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";

const CSMContactType = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  value = _.get(row.original, accessor, "");

  let tootltipData = [];

  if (row.original.is_whatsapp) {
    tootltipData.push("WhatsApp");
  }
  if (row.original.is_sms) {
    tootltipData.push("SMS");
  }
  if (row.original.is_call) {
    tootltipData.push("Call");
  }
  if (row.original.is_email) {
    tootltipData.push("Email");
  }
  if (row.original.eac) {
    tootltipData.push("Voice Bot");
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "10px",
      }}
    >
      {/* {visible ? ( */}
      <Paragraph2
        // ref={ref}
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textAlign: alignment,
          // margin: "5px",
        }}
      >
        {tootltipData && tootltipData[0]}
      </Paragraph2>
      {tootltipData && tootltipData.length > 1 && (
        <div style={{}}>
          <StatefulTooltip
            accessibilityType={"tooltip"}
            content={`${tootltipData.join(", ").toString()}${"."}`}
          >
            <div
              style={{
                border: "1px solid #333860",
                width: "25px",
                height: "15px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "#333860",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
              >
                +{tootltipData && tootltipData.length - 1}
              </div>
            </div>
          </StatefulTooltip>
        </div>
      )}
    </div>
  );
};

export default CSMContactType;
