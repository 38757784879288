import { useMemo } from "react";
import { useQuery } from "react-query";
import _ from "lodash";

import { CO_INVOICES_, CUSTOMER_OVERVIEW_ } from "../constants";
import { customerInvoiceOverview, getCustomerDetails } from "../services";

export const useCustomerQuery = ({
  customerId,
  organizationId,
  options = {},
}) => {
  const queryInfo = useQuery(
    [`${CUSTOMER_OVERVIEW_}${customerId}`],
    async () => {
      return await getCustomerDetails({
        organization: organizationId,
        customerId,
      });
    },
    {
      ...options,
    }
  );

  return {
    ...queryInfo,
    data: useMemo(
      () => _.get(queryInfo, "data.data.doc", null),
      [queryInfo.data]
    ),
  };
};

// export const useInvoiceQuery = ({
//   customerId,
//   organizationId,
//   options = {},
//   mainFilters = {},
//   subFilters = {},
//   gid,
// }) => {
//
//   const invoices = useQuery(
//     [
//       `${CO_INVOICES_}${customerId}`,
//       {
//         filters: { ...mainFilters },
//         gid,
//       },
//     ],
//     async ({ queryKey }) => {
//       let { filters } = queryKey[1];
//       filters = {
//         ...filters,
//         ...subFilters,
//       };

//       return await customerInvoiceOverview({
//         organization: organizationId,
//         customer_id: customerId,
//         pageIndex: 0,
//         pageSize: 0,
//         sortBy: "-_id",
//         filters, //TODO
//       });
//     },
//     {
//       ...options,
//     }
//   );

//   return invoices;
// };
