import React from "react";
import { TextButton, KIND } from "../../components/TextButton";
import { Modal, SIZE, ROLE } from "baseui/modal";
import { NEW } from "../../constants";
import Plus from "../../assets/img/svg/Upload/Plus";
import AddCustomFieldListForm from "./AddCustomFieldListForm";

const CustomFieldListFormModal = ({
  addCustomModal,
  edtingCustomData,
  setEditingCustomData,
  setAddCustomModal,
}) => {
  return (
    <>
      {/* <TextButton
        onClick={() => {
          setAddCustomModal(true);
          setEditingCustomData({
            type: NEW,
            data: [{ taxes: [] }],
          });
        }}
        size="mini"
        $style={{ borderRadius: "5px", width: "170px" }}
      >
        <Plus /> Add New Custom Field
      </TextButton> */}

      <Modal
        isOpen={addCustomModal}
        animate
        autoFocus
        size={SIZE.auto}
        role={ROLE.dialog}
        overrides={{
          Close: {
            style: () => ({
              display: "none",
            }),
          },
          Dialog: {
            style: () => ({
              padding: "10px 20px",
              // borderRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              borderTopLeftRadius: "5px",
            }),
          },
        }}
      >
        <AddCustomFieldListForm
          setAddCustomModal={setAddCustomModal}
          edtingCustomData={edtingCustomData}
        />
      </Modal>
    </>
  );
};

export default CustomFieldListFormModal;
