import React from "react";
import { useForm, Controller } from "react-hook-form";
import { TextArea } from "../../components/TextArea";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextBox } from "../../components/TextBox";
import { KIND, SIZE } from "baseui/button";
import { TextButton } from "../../components/TextButton";
import { useContext } from "react";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useIntl } from "react-intl";
import getSelectValues from "../../utils/getSelectValues";
import moment from "moment";
import _ from "lodash";
import { SelectBox } from "../../components/SelectBox";
import { ModalContext } from "../../providers/ModalProvider";
import { useDispatch } from "react-redux";
import { isOpenModal, setDrawerState } from "../../redux/actions";
import Warning from "../../assets/img/warning.png";
import { Icon, Typography } from "../../components_v2";
import { useMutation, useQuery } from "react-query";
import {
  getHolidayBlackoutHolidayDate,
  updateCustomerAction,
} from "../../services";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import queryClient from "../../providers/queryClient";
import {
  ACT_FIELD_VISIT,
  CUSTOMER_OVERVIEW,
  CUSTOMER_OVERVIEW_,
  GETTING_FIELD_VISIT_,
  NEXTACTION,
} from "../../constants";
import TitleHead from "../../components_v2/TitleHead";
import ActionReminders from "../../components_v2/ActionReminders";
import { useMemo } from "react";
import getActionReminder from "../../utils_v2/getActionReminders";

const NextAction = () => {
  let intl = useIntl();
  let dispatch = useDispatch();
  const { customerId } = useParams();
  const { handleModal } = useContext(ModalContext);
  const { referenceData, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  const orgUsers = useMemo(() => {
    let orgBusiness = referenceData["users"].filter((e) => {
      return e?.organization?.includes(currentOrganization) && e && e.active;
    });
    return orgBusiness || [];
  }, []);

  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let { next_action_type, location, next_action_date } = watch();

  const { data, isFetched, isError } = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: {
          date: next_action_date
            ? [
                moment(next_action_date)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf(),
              ]
            : null,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: customerId,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: next_action_date ? true : false,
    }
  );

  const addNextAction = useMutation(
    (formValues) =>
      updateCustomerAction({
        ...formValues,
        customerId: customerId,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        closeDrawer();
        queryClient.refetchQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
        queryClient.refetchQueries(`${GETTING_FIELD_VISIT_}-${customerId}`);
      },
    }
  );

  const onSubmit = async (data) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      next_action_type: getSelectValues(data.next_action_type),
      next_action_date: moment
        .tz(
          `${data.next_action_date?.getFullYear()}-${
            data.next_action_date?.getMonth() + 1
          }-${data.next_action_date?.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails.time_zone
        )
        .utc()
        .valueOf(),
      comment: data.comment,
      ...(_.get(data, "next_action_type[0].id", "") === "FIELD_VISIT"
        ? {
            recipient_ref: getSelectValues(data.recipient_ref),
            next_action_time: getSelectValues(data.next_action_time),
            visit_address: getSelectValues(data.location),
            assigned_to: getSelectValues(data.assigned_to),
            ...(data.location &&
            data.location[0] &&
            data.location[0].id === "other"
              ? { custom_location: data.custom_location }
              : {}),
            ...getActionReminder(data, {
              is_consent_enabled: false,
              is_incrementor: false,
              is_comment: false,
              is_calendar: false,
            }),
          }
        : {}),
      mention_users: uniqueMentionedUsers,
    };
    await addNextAction.mutateAsync(values);
  };

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  const locations = useMemo(() => {
    const addressContacts = _.get(customerData, "data.doc.contacts", []).reduce(
      (prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = address
          .map(({ status, call_status, ...nRest }) => {
            let designation =
              referenceData && referenceData["recipient_type"]
                ? referenceData["recipient_type"].find(
                    (rf) => rf?.id === curr?.designation
                  )
                : "";

            return {
              label: `${curr?.first_name ? curr?.first_name : ""} - ${
                nRest.line_1
              }, ${nRest.line_2 ? nRest.line_2 : ""}, ${nRest.country}, ${
                nRest.city
              }-${nRest.zip_code} ${designation?.label}`,
              id: curr?._id,
              designation: designation.id,
              status,
            };
          })
          .filter((e) => e.status === "VALID" || e.status === "LEAD");

        return [...prev, ...nestedContacts];
      },
      []
    );

    let combineLocation = [
      ...addressContacts,
      { id: "organization", label: "Organization Address" },
      { id: "other", label: "Other Location" },
    ];

    if (combineLocation.length > 0) {
      setValue("location", [combineLocation && combineLocation[0]]);
    }

    return combineLocation;
  }, []);

  const recipientRefOption = useMemo(() => {
    let recipient = _.get(customerData, "data.doc.contacts", [])
      ?.sort((a, b) => a?.first_name.localeCompare(b?.first_name))
      ?.map((e) => {
        let designation = referenceData["recipient_type"].find(
          (rf) => rf.id === e.designation
        );

        let label = "";
        label = designation && designation.label ? designation.label : "";

        let phone = "";

        if (
          e.phone &&
          e.phone[0] &&
          e.phone[0].value &&
          e.phone[0].country_code
        ) {
          phone = `${e.phone[0].country_code} ${e.phone[0].value}`;
        }

        return {
          id: e._id,
          label: `${e.first_name || ""} (${phone}) - ${label}`,
          number: phone,
        };
      });
    if (recipient && recipient.length > 0) {
      setValue("recipient_ref", [recipient && recipient[0]]);
    }
    return recipient;
  }, []);

  return (
    <form style={{ width: "490px" }} onSubmit={handleSubmit(onSubmit)}>
      <TitleHead icon="next_action" title="Next Action" />

      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <div style={{ flex: "1" }}>
          <Controller
            defaultValues={[]}
            name="next_action_type"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "next_action_type",
                })}
                placeholder={intl.formatMessage({
                  id: "next_action_type",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["workflow_type"]}
                // requiredAstric={true}
              />
            )}
          />
        </div>
        <div style={{ flex: "1" }}>
          <Controller
            name="next_action_date"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                clearable={false}
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "next_action_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
              />
            )}
          />
        </div>
      </div>
      {_.get(data, "data.doc.is_holiday") && (
        <>
          <div className="alert-holiday-section">
            <Icon icon="alert_circle" color="#516BEB" />
            <Typography
              type="p"
              style={{
                fontWeight: "400",
              }}
            >
              The Date Selected{" "}
              {_.get(data, "data.doc.holidays", []).map((val) => {
                return (
                  <>
                    {moment(val).format(
                      currentDefaultFormatDetails.date_format
                    )}
                  </>
                );
              })}{" "}
              is a <b>“Holiday”</b>
            </Typography>
          </div>
        </>
      )}

      {_.get(next_action_type, "[0].id", "") === "FIELD_VISIT" ? (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div style={{ width: "50%" }}>
              <Controller
                defaultValues={[]}
                name="next_action_time"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.mini}
                    {...field}
                    name={field.name}
                    label={intl.formatMessage({
                      id: "next_action_time",
                    })}
                    placeholder={intl.formatMessage({
                      id: "next_action_time",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["time"]}
                    // requiredAstric={true}
                  />
                )}
              />
            </div>
            <div style={{ width: "50%" }}>
              <Controller
                defaultValues={[]}
                name="recipient_ref"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.mini}
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "contact_for_notifications",
                    })}
                    placeholder={intl.formatMessage({
                      id: "contact_for_notifications",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={recipientRefOption}
                    // requiredAstric={true}
                  />
                )}
              />
            </div>
          </div>

          <div style={{ width: "48%" }}>
            <Controller
              name="assigned_to"
              control={control}
              render={({ field }) => (
                <SelectBox
                  size={SIZE.mini}
                  {...field}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "assigned_to",
                  })}
                  placeholder={intl.formatMessage({
                    id: "assigned_to",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={orgUsers}
                />
              )}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div style={{ width: "260px" }}>
              <Controller
                defaultValues={[]}
                name="location"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.mini}
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "location",
                    })}
                    placeholder={intl.formatMessage({
                      id: "location",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={locations}
                    // requiredAstric={true}
                  />
                )}
              />
            </div>
            <div style={{ flex: "1" }}>
              {_.get(location, "[0].id", "") === "other" ? (
                <Controller
                  name="custom_location"
                  control={control}
                  render={({ field }) => (
                    <TextBox
                      size={SIZE.compact}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "type_your_location",
                      })}
                      placeholder={intl.formatMessage({
                        id: "type_your_location",
                      })}
                      value={field.value}
                    />
                  )}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <ActionReminders
        // {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType={NEXTACTION}
        outcome={ACT_FIELD_VISIT}
        timeZone={_.get(customerData, "data.doc.timezone")}
        is_calendar={false}
        is_incrementor={false}
        is_hold={
          _.get(next_action_type, "[0].id", "") === "FIELD_VISIT" ? true : false
        }
        is_followup={
          _.get(next_action_type, "[0].id", "") === "FIELD_VISIT" ? true : false
        }
        is_confirmation={
          _.get(next_action_type, "[0].id", "") === "FIELD_VISIT" ? true : false
        }
        is_valid_from_contact={true}
        is_status={false}
      />

      {/* <Controller
        name="comment"
        control={control}
        render={({ field }) => (
          <TextArea
            size={SIZE.mini}
            {...field}
            name={field.name}
            error={errors[field.name] && errors[field.name].message}
            label={intl.formatMessage({
              id: "user_comments",
            })}
            placeholder={intl.formatMessage({
              id: "user_comments",
            })}
            value={field.value}
          />
        )}
      /> */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ display: "flex", gap: "10px" }}>
          <div style={{ width: "125px", height: "36px" }}>
            <TextButton
              size={SIZE.mini}
              fullWidth
              kind={KIND.tertiary}
              type="button"
              onClick={() => {
                // dispatch(emptyState());
                // dispatch(isOpenModal(false));
                handleModal({
                  title: "",
                  content: "Would you like to cancel the operation?",
                  successCallback: () => {
                    closeDrawer();
                  },
                  formContent: () => {
                    return <></>;
                  },
                  cancelCallback: () => {
                    // dispatch(isOpenModal(false));
                  },
                  kind: "primary",
                  isChildren: false,
                  image: Warning,
                  successButtonType: "error",
                  buttonText: "Yes",
                  closeButtonText: "Cancel",
                  isCloseAble: false,
                });
              }}
            >
              Cancel
            </TextButton>
          </div>{" "}
          <div style={{ width: "125px", height: "36px" }}>
            <TextButton
              size={SIZE.mini}
              fullWidth
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Save
            </TextButton>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NextAction;
