import React, { useContext, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { Icon, Typography } from "../../../components_v2";
import { SIZE, SelectBox } from "../../../components/SelectBox";
import { TextBox } from "../../../components/TextBox";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { useIntl } from "react-intl";
import AdjustmentTable from "./AdjustmentTable";
import { setDrawerState } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { KIND, TextButton } from "../../../components/TextButton";
import {
  createAdjustment,
  deleteAdjustment,
  updateAdjustment,
} from "../../../services";
import getSelectValues from "../../../utils/getSelectValues";
import moment from "moment";
import { toast } from "react-toastify";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { useMutation, useQuery } from "react-query";
import { ADD_LIST_VALUES } from "../../../providers/RBACProvider/permissionList";
import { getInvoiceData } from "../../../services/invoiceOverview";
import AdjustmentForm from "./AdjustmentForm";
import {
  ADJ_TIMELINE_,
  CO_INVOICES_,
  CO_TIMELINE_,
  CUSTOMER_OVERVIEW,
  CUSTOMER_OVERVIEW_,
  EDIT,
  VIEW,
} from "../../../constants";
import queryClient from "../../../providers/queryClient";
import { useParams } from "react-router-dom";
import setSelectValues from "../../../utils/setSelectValues";
import _ from "lodash";

const Adjustment = () => {
  let dispatch = useDispatch();
  const { customerId } = useParams();
  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };
  const { data } = useSelector((e) => e.customerOverviewDrawer);
  let maxyfiContextValue = useContext(MaxyfiContext);
  const { currentOrganization, currentDefaultFormatDetails, referenceData } =
    maxyfiContextValue;

  const { drawerType, _id } = data;

  const onSuccessRefetch = () => {
    queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
    queryClient.refetchQueries([`${CO_TIMELINE_}${customerId}`]);
    queryClient.refetchQueries([`${CO_INVOICES_}${customerId}`]);
    queryClient.invalidateQueries([`${ADJ_TIMELINE_}${data?.cus_id}`]);
    queryClient.refetchQueries([`TIMELINE_${CUSTOMER_OVERVIEW}_${customerId}`]);
  };

  const invoiceData = useQuery(
    [`GET_INV_DATA_${data?.invID}`],
    async () => {
      return await getInvoiceData({
        organization: currentOrganization,
        invoiceId: data?.invID,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: data && data.invID ? true : false,
    }
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...(data?.drawerType === EDIT || data?.drawerType === VIEW
        ? {
            type: setSelectValues(
              _.get(data, "content.portal_payment_method", "")
            ),
            reference: _.get(data, "content.portal_payment_reference", ""),
            description: _.get(data, "comment", ""),
            amount: _.get(data, "content.portal_payment_amount.value", ""),
            ...(_.get(data, "content.portal_payment_date", "")
              ? {
                  date:
                    new Date(
                      _.get(data, "content.portal_payment_date", null)
                    ) || null,
                }
              : {}),

            ici: _.get(data, "content.ici", false),
            invoices: [
              {
                allocatedAmount: _.get(
                  data,
                  "content.portal_payment_amount.value",
                  ""
                ),
              },
            ],
          }
        : {}),
    },
  });

  const { debtCom } = useMemo(() => {
    let debtCom;
    if (_.get(invoiceData, "data.data.docs.business_unit", "")) {
      debtCom =
        referenceData &&
        referenceData["business_unit_list"].find(
          (e) => e.id === _.get(invoiceData, "data.data.docs.business_unit", "")
        );
    }
    if (debtCom && debtCom.commission_method !== "DC") {
      setValue("ici", true);
    }
    return { debtCom };
  }, [invoiceData.isFetching, invoiceData.isFetched]);

  useEffect(() => {
    if (data) {
      setValue("ici", _.get(data, "content.ici", false));
    }
  }, [data?.content?.ici, data.drawerType]);

  const { type } = watch();

  const addAdjustment = useMutation(
    (adjData) =>
      createAdjustment({
        ...adjData,
        organization: currentOrganization,
        invoiceId: data && data.invID,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        if (type && type[0] && type[0].isCreatable) {
          maxyfiContextValue.refetch({
            ...maxyfiContextValue,
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();

        onSuccessRefetch();
        closeDrawer();
      },
    }
  );

  const editAdjustment = useMutation(
    (adjData) =>
      updateAdjustment({
        ...adjData,
        organization: currentOrganization,
        invoiceId: data && data.invID,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        if (type && type[0] && type[0].isCreatable) {
          maxyfiContextValue.refetch({
            ...maxyfiContextValue,
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        onSuccessRefetch();
        closeDrawer();
      },
    }
  );

  const onSubmit = async (data) => {
    let { invoices, aca, claa, ...rest } = data;
    let value = {
      ...rest,
      ...(data.ici ? { aca: aca, claa: claa } : {}),
      date: moment
        .tz(
          `${data.date.getFullYear()}-${
            data.date.getMonth() + 1
          }-${data.date.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails.time_zone
        )
        .utc()
        .valueOf(),
      type: getSelectValues(data.type),
    };

    if (drawerType === EDIT) {
      await editAdjustment.mutateAsync({
        ...value,
        timelineId: _id,
      });
    } else {
      await addAdjustment.mutateAsync({
        ...value,
      });
    }
  };

  return (
    <div style={{ width: "740px" }}>
      <AdjustmentForm
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        invoiceData={invoiceData}
        watch={watch}
        setValue={setValue}
        errors={errors}
        control={control}
        reset={reset}
        onSuccessRefetch={onSuccessRefetch}
        addAdjustment={addAdjustment}
        editAdjustment={editAdjustment}
      />
    </div>
  );
};

export default Adjustment;
