import _ from "lodash";
import moment from "moment-timezone";

const isTimestamp = (value) => {
  if (typeof value === "number" && value > 100000000000) {
    return !isNaN(new Date(value).getTime());
  }

  if (typeof value === "string" && /^\d{12,}$/.test(value)) {
    return !isNaN(new Date(Number(value)).getTime());
  }

  return false;
};

const RuleQueryFormat = (props) => {
  let gridStructure = _.get(props, "gridStructure", {});
  let customerQueryFilter = _.get(props, "customerQueryFilter", "");
  let timeZone = _.get(props, "timeZone", "");
  let customerFilter = _.get(props, "customerFilter", {});
  let fieldName = _.get(props, "fieldName", "");

  let newFilter = {
    combinator: "and",
    rules: [],
    ...customerFilter,
  };

  const GridStructure = _.get(gridStructure, "table", []).reduce(
    (prev, curr) => {
      if (_.get(curr, "nested")) {
        return [...prev, ...(_.get(curr, "nested") || [])];
      }
      return [...prev, curr];
    },
    []
  );

  const findDateObj = GridStructure.filter(
    (dq) => _.get(dq, "type") === "DATE"
  ).map((dq) => _.get(dq, "accessor"));

  if (fieldName && customerQueryFilter?.length >= 0) {
    const fieldObj = GridStructure.find(
      (item) => _.get(item, "accessor") === fieldName
    );
    newFilter.rules = [
      ..._.get(newFilter, "rules", []),
      {
        operator: "contains",
        value: customerQueryFilter,
        id: "8fd3ed39-52ec-4e5e-986e-6829a8d0d047",
        field:
          _.get(fieldObj, "cell_type") === "CURRENCY"
            ? `${fieldName}.value`
            : fieldName,
        valueSource: "value",
      },
    ];
  }

  if (_.get(newFilter, "rules", []).length > 0) {
    newFilter.rules = newFilter.rules.map((rule) => {
      let ruleValue = _.get(rule, "value", "");
      let field = _.get(rule, "field", "");

      // Ensure field.value is considered when applicable
      let fieldObj = GridStructure.find(
        (item) => _.get(item, "accessor") === field
      );
      let finalField =
        _.get(fieldObj, "cell_type") === "CURRENCY" ? `${field}.value` : field;
      if (findDateObj.includes(finalField)) {
        let timestamps = ruleValue?.split(",").map((ts) => ts.trim());

        if (timestamps.every((ts) => isTimestamp(ts))) {
          // Convert timestamps to formatted dates
          const formattedDates = timestamps
            .map((ts) => moment.tz(Number(ts), timeZone).format("YYYY-MM-DD"))
            .join(",");

          return { ...rule, field: finalField, value: formattedDates };
        }

        let [dateFrom, dateTo] = ruleValue.split(",");

        if (dateFrom && dateTo) {
          let newDateFrom = moment.tz(dateFrom, timeZone).isValid()
            ? moment.tz(dateFrom, timeZone).startOf("day").valueOf()
            : "";

          let newDateTo = moment.tz(dateTo, timeZone).isValid()
            ? moment.tz(dateTo, timeZone).endOf("day").valueOf()
            : "";

          return {
            ...rule,
            field: finalField,
            value: `${newDateFrom},${newDateTo}`,
          };
        } else if (dateFrom && moment.tz(dateFrom, timeZone).isValid()) {
          let newDateFrom =
            rule.operator === ">"
              ? moment.tz(dateFrom, timeZone).endOf("day").valueOf()
              : moment.tz(dateFrom, timeZone).startOf("day").valueOf();

          return { ...rule, field: finalField, value: newDateFrom };
        }
      }
      return { ...rule, field: finalField };
    });
  }

  return newFilter;
};

export default RuleQueryFormat;
