import React, { useState, useContext, useEffect } from "react";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TextButton } from "../../../../components/TextButton";
import { StatefulPopover } from "baseui/popover";
import { Button, KIND, SIZE } from "baseui/button";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip";
import {
  HeadingXLarge,
  LabelLarge,
  LabelSmall,
  ParagraphSmall,
} from "baseui/typography";
import { IconButton } from "../../../../components/IconButton";
import { holdActionModal } from "../../../../redux/actions";
import ContactNoCode from "../../../../assets/img/svg/ContactNoCode";
import { getOrganization } from "../../../../services";
import NineDots from "../../../../assets/img/svg/CustomerOverViewIcon/NineDots";
import {
  SMS,
  NEXT,
  DISPUTE,
  CALL,
  EMAIL,
  LETTER,
  HOLD,
  PAYMENTS,
  REVIEW,
  NEW,
  ACTIVE,
  IN_ACTIVE,
  ON_HOLD,
  INVOICE,
  WHATSAPP,
  LETTER_STATUS,
} from "../../../../constants";
// import { Block } from "baseui/block";
import Sms from "../../../../assets/img/svg/CustomerOverViewIcon/Sms";
// import DoubleNext from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/DoubleNext";
import NextActionForward from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/NextActionForward";
import ReviewPromise from "../../../../assets/img/svg/CustomerOverViewIcon/ReviewPromise";
import Dispute from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Dispute";
import Call from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Call";
import Email from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Email";
import LegalLetter from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/LegalLetter";
import Hold from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Hold";
// import ReviewPromise from "../../../../assets/img/svg/CustomerOverViewIcon/ReviewPromise.png";

import ThreeLine from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/ThreeLine";
import {
  getActionData,
  isOpenModal,
  tabMotion,
} from "../../../../redux/customerOverview/action";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Payment from "../../../../assets/img/svg/CustomerOverViewIcon/Payment";
import "moment-timezone";
import { CLOSED } from "ws";
import { invoiceTabMotion } from "../../../../redux/invoiceOverview/action";
import Warning from "../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Warning";
import QuickActionDanger from "../../../../assets/img/svg/CustomerOverViewIcon/QuickActionDanger";
import QuickActionWarning from "../../../../assets/img/svg/CustomerOverViewIcon/QuickActionWarning";
import { fas } from "@fortawesome/free-solid-svg-icons";
import WhatsApp from "../../../../assets/img/svg/Template/WhatsApp";
import LetterStatusSvg from "../../../../assets/img/svg/LetterStatusSvg";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import LocationSvg from "../../../../assets/img/svg/LocationSvg";

const mostUsedData = [
  {
    id: "0",
    actionName: "SMS",
    type: "sms",
    content: "SMS Reminders with/without an actionable link",
  },
  {
    id: "1",
    actionName: "Email",
    type: "email",
    content: "Email follow-up with Customer",
  },
  {
    id: "2",
    actionName: "Manual Call",
    type: "call",
    content: "Call Follow-up & capture discussion outcome",
  },
  // {
  //   id: "2",
  //   actionName: "Letter",
  //   type: "letter",
  //   content: "Generate and Send Physical letters",
  // },
];

const SwitchSvg = ({
  type,
  data,
  dayDifference,
  settingData,
  isQuickAction,
  workFlowAssign,
  isNextAction,
  whatsAppIsConfigured,
}) => {
  switch (type) {
    case WHATSAPP:
      return (
        <div
          // style={{
          //   width: "50px",
          //   height: "50px",
          //   borderRadius: "50%",
          //   // border: "2px solid #EEEEEE",
          //   background: "#DBEADC",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          // }}
          // className="circ-clr-wts"
          className={`${
            // (data && data === ON_HOLD && dayDifference < 0) ||
            whatsAppIsConfigured === false
              ? // ||
                // !isQuickAction ||
                // (dayDifference <= 0 && isNextAction)
                "disable-circ-clr-wts"
              : "circ-clr-wts"
          }`}
        >
          <WhatsApp />
        </div>
      );
    case DISPUTE:
      return (
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            // border: "2px solid #EEEEEE",
            background: "#EAEEFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="circ-clr"
        >
          <Dispute />
        </div>
      );
    case SMS:
      return (
        <div
          className={`${
            // (data && data === ON_HOLD && dayDifference < 0) ||
            !settingData
              ? // ||
                // !isQuickAction ||
                // (dayDifference <= 0 && isNextAction)
                "disable-circle"
              : "enabled-circle  circ-clr"
          }`}
          // style={{
          //   width: "50px",
          //   height: "50px",
          //   borderRadius: "50%",
          //   // border: "2px solid #EEEEEE",
          //   background: "#EAEEFF",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          // }}
          // className="circ-clr"
        >
          <Sms />
        </div>
      );
    case NEXT:
      return (
        <div
          // className={`${
          //   (data && data === ON_HOLD) || workFlowAssign
          //     ? "disable-circle"
          //     : "enabled-circle circ-clr"
          // }`}
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            // border: "2px solid #EEEEEE",
            background: "#EAEEFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="circ-clr"
        >
          <NextActionForward />
        </div>
      );
    case CALL:
      return (
        <div
          // className={`${
          //   (data && data === ON_HOLD && dayDifference < 0) ||
          //   !isQuickAction ||
          //   (dayDifference <= 0 && isNextAction)
          //     ? "disable-circle"
          //     : "enabled-circle  circ-clr"
          // }`}
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            // border: "2px solid #EEEEEE",
            background: "#EAEEFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="circ-clr"
        >
          <Call />
        </div>
      );

    case EMAIL:
      return (
        <div
          // className={`${
          //   (data && data === ON_HOLD && dayDifference < 0) ||
          //   !isQuickAction ||
          //   (dayDifference <= 0 && isNextAction)
          //     ? "disable-circle"
          //     : "enabled-circle  circ-clr"
          // }`}

          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            // border: "2px solid #EEEEEE",
            background: "#EAEEFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="circ-clr"
        >
          <Email />
        </div>
      );
    case LETTER:
      return (
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            // border: "2px solid #EEEEEE",
            background: "#EAEEFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="circ-clr"
        >
          <LegalLetter />
        </div>
      );
    case HOLD:
      return (
        <div
          // className={`${
          //   (data && data === ON_HOLD) || !isQuickAction
          //     ? "disable-circle"
          //     : "enabled-circle  circ-clr"
          // }`}

          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            // border: "2px solid #EEEEEE",
            background: "#EAEEFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="circ-clr"
        >
          <Hold />
        </div>
      );
    case PAYMENTS:
      return (
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            // border: "2px solid #EEEEEE",
            background: "#EAEEFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="circ-clr"
        >
          <Payment />
        </div>
      );
    case REVIEW:
      return (
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            // border: "2px solid #EEEEEE",
            background: "#EAEEFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="circ-clr"
        >
          <ReviewPromise />
        </div>
      );
    case LETTER_STATUS:
      return (
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            // border: "2px solid #EEEEEE",
            background: "#EAEEFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="circ-clr"
        >
          <LetterStatusSvg />
        </div>
      );

    case "field_visit":
      return (
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            // border: "2px solid #EEEEEE",
            background: "#EAEEFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="circ-clr"
        >
          <LocationSvg />
        </div>
      );

    default:
      return null;
  }
};
const AllActionPopOver = ({
  isQuickAction,
  allActionData,
  quickActionData,
  isWorkflowActive,
  isWorkflow,
  workFlowStatus,
  isNextAction,
  dayDifferenceValue,
  dayDifference,
  smsGateWayIsConfgured,
  getParams,
  holdDateFormate,
  holdReason,
  cusHoldReason,
  workflowActionLength,
  workflowActionData,
  whatsAppIsConfigured,
}) => {
  // let whatsAppIsConfigured = false;
  let {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    space,
  } = useContext(MaxyfiContext);
  const [settingData, setSettingData] = useState(null);

  let entity = useSelector((s) => s.invoiceOverviewReducer.invoice_entity);
  //

  let findingUser = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );
  // let statusData = props.workFlowData?.status;

  const workFlowAssign = useSelector(
    (s) => s.customerOverviewReducer?.customerDetails?.workflow?.name
  );

  const dispatch = useDispatch();
  const [list, setList] = useState(false);
  // useEffect(async () => {
  //   await getOrganization({ organization: currentOrganization }).then((res) => {
  //     setSettingData(res?.data?.doc.sms_details || {});
  //   });
  //   //TODO: PRAVEEN FAILURE ERROR HANDLING
  // }, []);

  // const smsGateWayIsConfgured = useSelector(
  //   (s) => s.customerOverviewReducer.smsGateWay?.sms_details?.is_configured
  // );

  let actions = {
    hold: [!isQuickAction].some((e) => e),
    next: [isWorkflow].some((e) => e),
    sms: [
      !smsGateWayIsConfgured,
      isWorkflowActive && dayDifferenceValue <= 0 && isNextAction,
      !isQuickAction && !isWorkflowActive,
    ].some((e) => e),
    email: [
      isWorkflowActive && dayDifferenceValue <= 0 && isNextAction,
      !isQuickAction && !isWorkflowActive,
    ].some((e) => e),
    call: [
      isWorkflowActive && dayDifferenceValue <= 0 && isNextAction,
      !isQuickAction && !isWorkflowActive,
    ].some((e) => e),
  };

  return (
    <>
      <StatefulPopover
        style={{ paddingTop: "50px" }}
        // placement={PLACEMENT.bottomRight}
        overrides={{
          Body: {
            style: ({ $theme }) => ({
              marginTop: "5%",
            }),
          },
        }}
        content={({ close }) => (
          // Grid Structure View
          <>
            {list ? (
              <>
                {/* List View */}
                <div
                  style={{
                    // height: "500px",
                    width: "320px",
                    background: "#ffffff",
                    borderRadius: "5px",
                  }}
                >
                  {workFlowStatus === "ON_HOLD" && dayDifferenceValue === 0 ? (
                    <div
                      style={{
                        height: "40px",
                        background: "#FFD9D7",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <QuickActionDanger />

                      <ParagraphSmall
                        style={{
                          // display: "flex",
                          // alignItems: "center",
                          // justifyContent: "center",
                          gap: "3px",
                        }}
                      >
                        <FormattedMessage id="hold_action_applied_till">
                          hold_action_applied_till
                        </FormattedMessage>
                        {holdDateFormate}{" "}
                        <FormattedMessage id="due_to">due_to</FormattedMessage>{" "}
                        <LabelSmall style={{ color: "#FD372A" }}>
                          {holdReason && holdReason[0] && holdReason[0].label}
                        </LabelSmall>
                      </ParagraphSmall>
                    </div>
                  ) : workFlowStatus === "ON_HOLD" || !isQuickAction ? (
                    <>
                      <div
                        style={{
                          height: "40px",
                          background: "#FFD9D7",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <QuickActionDanger />

                        <ParagraphSmall
                          style={{
                            // display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            gap: "3px",
                          }}
                        >
                          <FormattedMessage id="hold_action_applied_till">
                            hold_action_applied_till
                          </FormattedMessage>
                          {holdDateFormate}{" "}
                          <FormattedMessage id="due_to">
                            due_to
                          </FormattedMessage>{" "}
                          <LabelSmall style={{ color: "#FD372A" }}>
                            "
                            {holdReason && holdReason[0] && holdReason[0].label}
                            "
                          </LabelSmall>
                        </ParagraphSmall>
                      </div>
                    </>
                  ) : dayDifferenceValue <= 0 &&
                    workflowActionData === false &&
                    !workflowActionLength === false ? (
                    <>
                      <div
                        style={{
                          height: "40px",
                          background: "#FFFAEB",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <QuickActionWarning />

                        <ParagraphSmall
                          style={{
                            // display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            gap: "3px",
                          }}
                        >
                          <LabelSmall style={{ color: "#FBBD04" }}>
                            Workflow Action Due!
                          </LabelSmall>
                          Want to perform Quick Action?
                        </ParagraphSmall>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      padding: "10px",
                      // textAlign: "center",
                      borderBottom: "0.5px solid #ADADAD",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end",
                    }}
                  >
                    <HeadingXLarge
                      style={{ color: "#516BEB", paddingRight: "24px" }}
                    >
                      <FormattedMessage id="all_actions">
                        all_actions
                      </FormattedMessage>
                    </HeadingXLarge>

                    {/* <div onClick={() => setList(false)}>Grid</div>
                    <div>List</div> */}
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          height: "36px",
                          width: "38px",
                        }}
                      >
                        <IconButton
                          kind={KIND.tertiary}
                          style={{
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => setList(false)}
                        >
                          <NineDots />
                        </IconButton>
                      </div>
                      <div
                        style={{
                          height: "36px",
                          width: "38px",
                        }}
                      >
                        <IconButton
                          onClick={() => setList(true)}
                          style={{
                            cursor: "pointer",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <ThreeLine />
                          {/* List */}
                        </IconButton>
                      </div>
                    </div>
                  </div>

                  {/* All Action View Content */}
                  <div
                    style={{
                      height: "420px",
                      overflow: "scroll",
                      padding: "20px 20px 0px 20px",
                    }}
                    // className="onHov"
                  >
                    {findingUser?.accounting_book === "CSV"
                      ? allActionData.map((data) => (
                          <RBACWrapper
                            role={data.permissions}
                            type="PERMISSION"
                          >
                            <div
                              // className={
                              //   actions[data?.type] == true ? "" : "onHov"
                              // }
                              // className="onHov"
                              className={
                                data.type === "sms" &&
                                !currentDefaultFormatDetails?.is_sms_configured
                                  ? ""
                                  : data.type === "whatsapp" &&
                                    whatsAppIsConfigured === false
                                  ? ""
                                  : "onHov"
                              }
                              onClick={() => {
                                // if (
                                //   (data.type === "email" ||
                                //     data.type === "call" ||
                                //     data.type === "sms" ||
                                //     data.type === "hold") &&
                                //   !isQuickAction
                                //   // dayDifference === false
                                // ) {
                                // } else if (
                                //   workFlowAssign &&
                                //   data.type === "next"
                                // ) {
                                // } else if (
                                //   data.type === "sms" &&
                                //   settingData?.is_configured === false
                                // ) {
                                // } else if (
                                //   workFlowStatus === ACTIVE &&
                                //   (data.type === "next" ||
                                //     data.type === "email" ||
                                //     data.type === "call" ||
                                //     data.type === "sms") &&
                                //   dayDifference <= 0 &&
                                //   isNextAction
                                // ) {
                                // } else if (
                                //   data.type === "hold" &&
                                //   isQuickAction
                                // ) {
                                //   dispatch(holdActionModal(true));
                                //   close();
                                // } else if (Entity === "CUSTOMER") {
                                //   dispatch(getActionData(data.type));
                                //   dispatch(isOpenModal(true));
                                //   dispatch(tabMotion(2));
                                //   close();
                                // } else {
                                //   dispatch(getActionData(data.type));
                                //   dispatch(isOpenModal(true));
                                //   dispatch(invoiceTabMotion(1));
                                //   // dispatch(tabMotion(2));
                                //   close();
                                // }
                                if (entity === "CUSTOMER") {
                                  dispatch(tabMotion(2));
                                } else {
                                  dispatch(invoiceTabMotion(1));
                                }
                                if (data.type === "hold") {
                                  dispatch(holdActionModal(true));
                                  close();
                                } else if (
                                  data.type === "sms" &&
                                  currentDefaultFormatDetails?.is_sms_configured ===
                                    false
                                ) {
                                } else if (
                                  data.type === "whatsapp" &&
                                  whatsAppIsConfigured === false
                                ) {
                                } else {
                                  dispatch(getActionData(data.type));
                                  dispatch(isOpenModal(true));
                                  // dispatch(invoiceTabMotion(1));
                                  // dispatch(tabMotion(2));
                                  close();
                                }
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  overflow: "scroll",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  padding: "10px 15px",
                                }}
                              >
                                <div>
                                  <SwitchSvg
                                    type={data.type}
                                    data={workFlowStatus}
                                    dayDifference={dayDifference}
                                    settingData={
                                      currentDefaultFormatDetails?.is_sms_configured
                                    }
                                    isQuickAction={isQuickAction}
                                    workFlowAssign={workFlowAssign}
                                    isNextAction={isNextAction}
                                    whatsAppIsConfigured={whatsAppIsConfigured}
                                  />
                                </div>
                                <div style={{ paddingLeft: "20px" }}>
                                  <LabelLarge
                                    // className={
                                    //   actions[data?.type] == true
                                    //     ? "enable"
                                    //     : "onHov"
                                    // }
                                    // className={
                                    //   data.type === "sms" &&
                                    //   !settingData?.is_configured
                                    //     ? ""

                                    //     : "onHov"
                                    // }
                                    className={
                                      data.type === "sms" &&
                                      !currentDefaultFormatDetails?.is_sms_configured
                                        ? ""
                                        : data.type === "whatsapp" &&
                                          whatsAppIsConfigured === false
                                        ? ""
                                        : "onHov"
                                    }
                                    style={{
                                      userSelect: "none",
                                      display: "flex",
                                      gap: "5px",
                                    }}
                                  >
                                    {data.actionTitle}
                                    {data.actionName === "SMS" &&
                                    currentDefaultFormatDetails?.is_sms_configured ===
                                      false ? (
                                      <>
                                        <StatefulTooltip
                                          content={() => (
                                            <div>
                                              SMS Gateway Configuration in
                                              Organization Settings - Pending
                                            </div>
                                          )}
                                          placement={PLACEMENT.right}
                                          returnFocus
                                          autoFocus
                                        >
                                          <div>
                                            <ContactNoCode />
                                          </div>
                                        </StatefulTooltip>
                                      </>
                                    ) : data.actionName === "WhatsApp" &&
                                      whatsAppIsConfigured === false ? (
                                      <>
                                        <StatefulTooltip
                                          content={() => (
                                            <div>
                                              WhatsApp Configuration in
                                              Organization Settings - Pending
                                            </div>
                                          )}
                                          placement={PLACEMENT.right}
                                          returnFocus
                                          autoFocus
                                        >
                                          <div>
                                            <ContactNoCode />
                                          </div>
                                        </StatefulTooltip>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </LabelLarge>
                                  <div
                                    style={{
                                      paddingTop: "5px",
                                      color: "#ADADAD",
                                    }}
                                  >
                                    <ParagraphSmall
                                      style={{
                                        color: "#ADADAD",
                                        userSelect: "none",
                                      }}
                                    >
                                      {data.contentIntl}
                                    </ParagraphSmall>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </RBACWrapper>
                        ))
                      : quickActionData.map((data) => (
                          <RBACWrapper
                            role={data.permissions}
                            type="PERMISSION"
                          >
                            <div
                              // className={
                              //   actions[data?.type] == true ? "" : "onHov"
                              // }
                              // className="onHov"
                              // className={
                              //   data.type === "sms" &&
                              //   !settingData?.is_configured
                              //     ? ""
                              //     : "onHov"
                              // }
                              className={
                                data.type === "sms" &&
                                !currentDefaultFormatDetails?.is_sms_configured
                                  ? ""
                                  : data.type === "whatsapp" &&
                                    whatsAppIsConfigured === false
                                  ? ""
                                  : "onHov"
                              }
                              onClick={() => {
                                // if (
                                //   (data.type === "email" ||
                                //     data.type === "call" ||
                                //     data.type === "sms" ||
                                //     data.type === "hold") &&
                                //   !isQuickAction
                                //   // dayDifference === false
                                // ) {
                                // } else if (
                                //   workFlowAssign &&
                                //   data.type === "next"
                                // ) {
                                // } else if (
                                //   data.type === "sms" &&
                                //   settingData?.is_configured === false
                                // ) {
                                // } else if (
                                //   workFlowStatus === ACTIVE &&
                                //   (data.type === "next" ||
                                //     data.type === "email" ||
                                //     data.type === "call" ||
                                //     data.type === "sms") &&
                                //   dayDifference <= 0 &&
                                //   isNextAction
                                // ) {
                                // } else if (
                                //   data.type === "hold" &&
                                //   isQuickAction
                                // ) {
                                //   dispatch(holdActionModal(true));
                                //   close();
                                // } else if (Entity === "CUSTOMER") {
                                //   dispatch(getActionData(data.type));
                                //   dispatch(isOpenModal(true));
                                //   dispatch(tabMotion(2));
                                //   close();
                                // } else {
                                //   dispatch(getActionData(data.type));
                                //   dispatch(isOpenModal(true));
                                //   dispatch(invoiceTabMotion(1));
                                //   // dispatch(tabMotion(2));
                                //   close();
                                // }

                                if (entity === "CUSTOMER") {
                                  dispatch(tabMotion(2));
                                } else {
                                  dispatch(invoiceTabMotion(1));
                                }

                                if (data.type === "hold") {
                                  dispatch(holdActionModal(true));
                                  close();
                                } else if (
                                  data.type === "sms" &&
                                  currentDefaultFormatDetails?.is_sms_configured ===
                                    false
                                ) {
                                } else if (
                                  data.type === "whatsapp" &&
                                  whatsAppIsConfigured === false
                                ) {
                                } else {
                                  dispatch(getActionData(data.type));
                                  dispatch(isOpenModal(true));
                                  // dispatch(invoiceTabMotion(1));
                                  // dispatch(tabMotion(2));
                                  close();
                                }
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  overflow: "scroll",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  padding: "10px 15px",
                                }}
                              >
                                <div>
                                  <SwitchSvg
                                    type={data.type}
                                    data={workFlowStatus}
                                    dayDifference={dayDifference}
                                    settingData={
                                      currentDefaultFormatDetails?.is_sms_configured
                                    }
                                    isQuickAction={isQuickAction}
                                    workFlowAssign={workFlowAssign}
                                    isNextAction={isNextAction}
                                    whatsAppIsConfigured={whatsAppIsConfigured}
                                  />
                                </div>
                                <div style={{ paddingLeft: "20px" }}>
                                  <LabelLarge
                                    // className={
                                    //   data.type === "sms" &&
                                    //   !settingData?.is_configured
                                    //     ? ""
                                    //     : "onHov"
                                    // }
                                    className={
                                      data.type === "sms" &&
                                      !currentDefaultFormatDetails?.is_sms_configured
                                        ? ""
                                        : data.type === "whatsapp" &&
                                          whatsAppIsConfigured === false
                                        ? ""
                                        : "onHov"
                                    }
                                    style={{
                                      userSelect: "none",
                                      display: "flex",
                                      gap: "5px",
                                    }}
                                  >
                                    {data.actionTitle}
                                    {data.actionName === "SMS" &&
                                    currentDefaultFormatDetails?.is_sms_configured ===
                                      false ? (
                                      <>
                                        <StatefulTooltip
                                          content={() => (
                                            <div>
                                              SMS Gateway Configuration in
                                              Organization Settings - Pending
                                            </div>
                                          )}
                                          placement={PLACEMENT.right}
                                          returnFocus
                                          autoFocus
                                        >
                                          <div>
                                            <ContactNoCode />
                                          </div>
                                        </StatefulTooltip>
                                      </>
                                    ) : data.actionName === "WhatsApp" &&
                                      whatsAppIsConfigured === false ? (
                                      <>
                                        <StatefulTooltip
                                          content={() => (
                                            <div>
                                              WhatsApp Configuration in
                                              Organization Settings - Pending
                                            </div>
                                          )}
                                          placement={PLACEMENT.right}
                                          returnFocus
                                          autoFocus
                                        >
                                          <div>
                                            <ContactNoCode />
                                          </div>
                                        </StatefulTooltip>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </LabelLarge>
                                  <div
                                    style={{
                                      paddingTop: "5px",
                                      color: "#ADADAD",
                                    }}
                                  >
                                    <ParagraphSmall
                                      style={{
                                        color: "#ADADAD",
                                        userSelect: "none",
                                      }}
                                    >
                                      {data.contentIntl}
                                    </ParagraphSmall>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </RBACWrapper>
                        ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    width: "880px",
                    // height: "450px",
                    backgroundColor: "#ffffff",
                    // padding: "40px",
                    // padding: "40px 30px",
                    borderRadius: "5px",
                    // overflow: "scroll",
                    position: "relative",
                  }}
                >
                  {workFlowStatus === "ON_HOLD" && dayDifferenceValue === 0 ? (
                    <div
                      style={{
                        height: "33px",
                        background: "#FFD9D7",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <QuickActionDanger />

                      <ParagraphSmall
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "3px",
                        }}
                      >
                        <FormattedMessage id="hold_action_applied_till">
                          hold_action_applied_till
                        </FormattedMessage>
                        {holdDateFormate}{" "}
                        <FormattedMessage id="due_to">due_to</FormattedMessage>{" "}
                        <LabelSmall style={{ color: "#FD372A" }}>
                          {holdReason && holdReason[0] && holdReason[0].label}
                        </LabelSmall>
                      </ParagraphSmall>
                    </div>
                  ) : workFlowStatus === "ON_HOLD" || !isQuickAction ? (
                    <>
                      <div
                        style={{
                          height: "33px",
                          background: "#FFD9D7",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <QuickActionDanger />

                        <ParagraphSmall
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "3px",
                          }}
                        >
                          <FormattedMessage id="hold_action_applied_till">
                            hold_action_applied_till
                          </FormattedMessage>{" "}
                          {holdDateFormate}{" "}
                          <FormattedMessage id="due_to">
                            due_to
                          </FormattedMessage>{" "}
                          <LabelSmall style={{ color: "#FD372A" }}>
                            {holdReason && holdReason[0] && holdReason[0].label}
                          </LabelSmall>
                        </ParagraphSmall>
                      </div>
                    </>
                  ) : dayDifferenceValue <= 0 &&
                    workflowActionData === false &&
                    !workflowActionLength === false ? (
                    <>
                      <div
                        style={{
                          height: "33px",
                          background: "#FFFAEB",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <QuickActionWarning />

                        <ParagraphSmall
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "3px",
                          }}
                        >
                          <LabelSmall style={{ color: "#FBBD04" }}>
                            Workflow Action Due!
                          </LabelSmall>
                          Want to perform Quick Action?
                        </ParagraphSmall>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* {dayDifferenceValue === 0 ? (
                    <div
                      style={{
                        height: "33px",
                        background: "#FFFAEB",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <QuickActionWarning />

                      <ParagraphSmall
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "3px",
                        }}
                      >
                        <LabelSmall style={{ color: "#FBBD04" }}>
                          Workflow Action Due!
                        </LabelSmall>
                        Want to perform Quick Action?
                      </ParagraphSmall>
                    </div>
                  ) : (
                    <></>
                  )} */}

                  {/* Most Used And Suggestion */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingLeft: "38px",
                      paddingTop: "30px",
                      paddingRight: "18px",
                      paddingBottom: "0px",
                    }}
                  >
                    <HeadingXLarge style={{ color: "#516BEB" }}>
                      <FormattedMessage id="all_actions">
                        all_actions
                      </FormattedMessage>
                    </HeadingXLarge>
                    <div></div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "38px",
                            height: "36px",
                          }}
                        >
                          <IconButton style={{ width: "100%", height: "100%" }}>
                            <NineDots />
                          </IconButton>
                        </div>
                        <div
                          style={{
                            width: "38px",
                            height: "36px",
                          }}
                        >
                          <IconButton
                            kind={KIND.tertiary}
                            onClick={() => setList(true)}
                            style={{ width: "100%", height: "100%" }}
                          >
                            <ThreeLine />
                            {/* List */}
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* All Actions Heading */}

                  <>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                        // paddingTop: "40px",
                        // paddingLeft: "8px",
                        paddingLeft: "38px",
                        // paddingTop: "40px",
                        paddingRight: "30px",
                        paddingBottom: "20px",
                      }}
                    ></div>

                    {/* All Actions Content */}

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3,10fr)",
                        gridRowGap: "20px",
                        gridColumnGap: "5px",
                        cursor: "pointer",
                        // paddingTop: "40px",
                        padding: "0px 20px 40px 24px",

                        // height: "17vh",
                        // overflow: "scroll",
                      }}
                    >
                      {findingUser?.accounting_book === "CSV"
                        ? allActionData.map((data) => (
                            <RBACWrapper
                              role={data.permissions}
                              type="PERMISSION"
                            >
                              <div
                                // className={
                                //   actions[data?.type] == true ? "" : "onHov"
                                // }
                                // className="onHov"
                                // className={
                                //   data.type === "sms" &&
                                //   !settingData?.is_configured
                                //     ? ""
                                //     : "onHov"
                                // }
                                className={
                                  data.type === "sms" &&
                                  !currentDefaultFormatDetails?.is_sms_configured
                                    ? ""
                                    : data.type === "whatsapp" &&
                                      whatsAppIsConfigured === false
                                    ? ""
                                    : "onHov"
                                }
                                onClick={() => {
                                  if (entity === "CUSTOMER") {
                                    dispatch(tabMotion(2));
                                  } else {
                                    dispatch(invoiceTabMotion(1));
                                  }

                                  if (data.type === "hold") {
                                    dispatch(holdActionModal(true));
                                    close();
                                  } else if (
                                    data.type === "sms" &&
                                    currentDefaultFormatDetails?.is_sms_configured ===
                                      false
                                  ) {
                                  } else if (
                                    data.type === "whatsapp" &&
                                    whatsAppIsConfigured === false
                                  ) {
                                  } else {
                                    dispatch(getActionData(data.type));
                                    dispatch(isOpenModal(true));
                                    // dispatch(invoiceTabMotion(1));
                                    // dispatch(tabMotion(2));
                                    close();
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "10px 15px",
                                  }}
                                >
                                  <div>
                                    <SwitchSvg
                                      type={data.type}
                                      data={workFlowStatus}
                                      dayDifference={dayDifference}
                                      settingData={
                                        currentDefaultFormatDetails?.is_sms_configured
                                      }
                                      isQuickAction={isQuickAction}
                                      workFlowAssign={workFlowAssign}
                                      isNextAction={isNextAction}
                                      whatsAppIsConfigured={
                                        whatsAppIsConfigured
                                      }
                                    />
                                  </div>
                                  <div style={{ paddingLeft: "20px" }}>
                                    <LabelLarge
                                      className={
                                        data.type === "sms" &&
                                        !currentDefaultFormatDetails?.is_sms_configured
                                          ? ""
                                          : data.type === "whatsapp" &&
                                            whatsAppIsConfigured === false
                                          ? ""
                                          : "onHov"
                                      }
                                      style={{
                                        userSelect: "none",
                                        display: "flex",
                                        gap: "5px",
                                      }}
                                    >
                                      {data.actionTitle}
                                      {data.actionName === "SMS" &&
                                      currentDefaultFormatDetails?.is_sms_configured ===
                                        false ? (
                                        <>
                                          <StatefulTooltip
                                            content={() => (
                                              <div>
                                                SMS Gateway Configuration in
                                                Organization Settings - Pending
                                              </div>
                                            )}
                                            placement={PLACEMENT.right}
                                            returnFocus
                                            autoFocus
                                          >
                                            <div>
                                              <ContactNoCode />
                                            </div>
                                          </StatefulTooltip>
                                        </>
                                      ) : data.actionName === "WhatsApp" &&
                                        whatsAppIsConfigured === false ? (
                                        <>
                                          <StatefulTooltip
                                            content={() => (
                                              <div>
                                                WhatsApp Configuration in
                                                Organization Settings - Pending
                                              </div>
                                            )}
                                            placement={PLACEMENT.right}
                                            returnFocus
                                            autoFocus
                                          >
                                            <div>
                                              <ContactNoCode />
                                            </div>
                                          </StatefulTooltip>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </LabelLarge>

                                    <div
                                      style={{
                                        paddingTop: "5px",
                                        color: "#ADADAD",
                                      }}
                                    >
                                      <ParagraphSmall
                                        style={{
                                          color: "#ADADAD",
                                          userSelect: "none",
                                        }}
                                      >
                                        {data.contentIntl}
                                      </ParagraphSmall>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </RBACWrapper>
                          ))
                        : quickActionData.map((data) => (
                            <RBACWrapper
                              role={data.permissions}
                              type="PERMISSION"
                            >
                              <div
                                className={
                                  data.type === "sms" &&
                                  !currentDefaultFormatDetails?.is_sms_configured
                                    ? ""
                                    : data.type === "whatsapp" &&
                                      whatsAppIsConfigured === false
                                    ? ""
                                    : "onHov"
                                }
                                // className="onHov"
                                onClick={() => {
                                  // if (
                                  //   (data.type === "email" ||
                                  //     data.type === "call" ||
                                  //     data.type === "sms" ||
                                  //     data.type === "hold") &&
                                  //   !isQuickAction
                                  //   // dayDifference === false
                                  // ) {
                                  // } else if (
                                  //   workFlowAssign &&
                                  //   data.type === "next"
                                  // ) {
                                  // } else if (
                                  //   data.type === "sms" &&
                                  //   settingData?.is_configured === false
                                  // ) {
                                  // } else if (
                                  //   workFlowStatus === ACTIVE &&
                                  //   (data.type === "next" ||
                                  //     data.type === "email" ||
                                  //     data.type === "call" ||
                                  //     data.type === "sms") &&
                                  //   dayDifference <= 0 &&
                                  //   isNextAction
                                  // ) {
                                  // } else if (
                                  //   data.type === "hold" &&
                                  //   isQuickAction
                                  // ) {
                                  //   dispatch(holdActionModal(true));
                                  //   close();
                                  // } else if (Entity === "CUSTOMER") {
                                  //   dispatch(getActionData(data.type));
                                  //   dispatch(isOpenModal(true));
                                  //   dispatch(tabMotion(2));
                                  //   close();
                                  // } else {
                                  //   dispatch(getActionData(data.type));
                                  //   dispatch(isOpenModal(true));
                                  //   dispatch(invoiceTabMotion(1));
                                  //   // dispatch(tabMotion(2));
                                  //   close();
                                  // }

                                  if (entity === "CUSTOMER") {
                                    dispatch(tabMotion(2));
                                  } else {
                                    dispatch(invoiceTabMotion(1));
                                  }

                                  if (data.type === "hold") {
                                    dispatch(holdActionModal(true));
                                    close();
                                  } else if (
                                    data.type === "sms" &&
                                    currentDefaultFormatDetails?.is_sms_configured ===
                                      false
                                  ) {
                                  } else if (
                                    data.type === "whatsapp" &&
                                    whatsAppIsConfigured === false
                                  ) {
                                  } else {
                                    dispatch(getActionData(data.type));
                                    dispatch(isOpenModal(true));
                                    close();
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "10px 15px",
                                  }}
                                >
                                  <div>
                                    <SwitchSvg
                                      type={data.type}
                                      data={workFlowStatus}
                                      dayDifference={dayDifference}
                                      settingData={
                                        currentDefaultFormatDetails?.is_sms_configured
                                      }
                                      isQuickAction={isQuickAction}
                                      workFlowAssign={workFlowAssign}
                                      isNextAction={isNextAction}
                                      whatsAppIsConfigured={
                                        whatsAppIsConfigured
                                      }
                                    />
                                  </div>
                                  <div style={{ paddingLeft: "20px" }}>
                                    <LabelLarge
                                      // className={
                                      //   actions[data?.type] == true
                                      //     ? "enable"
                                      //     : "onHov"
                                      // }
                                      // className="onHov"

                                      // className={
                                      //   data.type === "sms" &&
                                      //   !settingData?.is_configured
                                      //     ? ""
                                      //     : "onHov"
                                      // }
                                      className={
                                        data.type === "sms" &&
                                        !currentDefaultFormatDetails?.is_sms_configured
                                          ? ""
                                          : data.type === "whatsapp" &&
                                            whatsAppIsConfigured === false
                                          ? ""
                                          : "onHov"
                                      }
                                      style={{
                                        userSelect: "none",
                                        display: "flex",
                                        // alignItems: "center",
                                        gap: "5px",
                                      }}
                                    >
                                      {data.actionTitle}
                                      {data.actionName === "SMS" &&
                                      currentDefaultFormatDetails?.is_sms_configured ===
                                        false ? (
                                        <>
                                          <StatefulTooltip
                                            content={() => (
                                              <div>
                                                SMS Gateway Configuration in
                                                Organization Settings - Pending
                                              </div>
                                            )}
                                            placement={PLACEMENT.right}
                                            returnFocus
                                            autoFocus
                                          >
                                            <div>
                                              <ContactNoCode />
                                            </div>
                                          </StatefulTooltip>
                                        </>
                                      ) : data.actionName === "WhatsApp" &&
                                        whatsAppIsConfigured === false ? (
                                        <>
                                          <StatefulTooltip
                                            content={() => (
                                              <div>
                                                WhatsApp Configuration in
                                                Organization Settings - Pending
                                              </div>
                                            )}
                                            placement={PLACEMENT.right}
                                            returnFocus
                                            autoFocus
                                          >
                                            <div>
                                              <ContactNoCode />
                                            </div>
                                          </StatefulTooltip>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </LabelLarge>

                                    <div
                                      style={{
                                        paddingTop: "5px",
                                        color: "#ADADAD",
                                      }}
                                    >
                                      <ParagraphSmall
                                        style={{
                                          color: "#ADADAD",
                                          userSelect: "none",
                                        }}
                                      >
                                        {data.contentIntl}
                                      </ParagraphSmall>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </RBACWrapper>
                          ))}
                    </div>
                  </>
                </div>
              </>
            )}
          </>
        )}
        placement={PLACEMENT.rightBottom}
        // returnFocus
        // autoFocus
      >
        <div style={{ flex: "1", display: "flex", justifyContent: "center" }}>
          <div style={{ width: "130px" }}>
            <TextButton
              fullWidth
              size={SIZE.mini}
              // kind={KIND.secondary}
              kind={KIND.primary}
              // style={{ width: "100%" }}
              type="button"
              // endEnhancer={() => <AdhocActionDownArrow />}
            >
              <FormattedMessage id="quick_actions">
                quick_actions
              </FormattedMessage>{" "}
              (QA)
            </TextButton>
          </div>
        </div>
      </StatefulPopover>
    </>
  );
};

export default AllActionPopOver;
