import { useContext, useState } from "react";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { useMutation, useQuery } from "react-query";
import {
  deleteEditInvoiceStatusAudit,
  getInvoiceStatusAudit,
} from "../../../../services";
import Loader from "../../../../components/Loader";
import RequestInformationCard from "../../../../components/RequestInformationCard/RequestInformationCard";
import {
  ADD_SUB_MAIN_STATUS_MAINTENANCE,
  DELETE_SUB_MAIN_STATUS_MAINTENANCE,
  EDIT_SUB_MAIN_STATUS_MAINTENANCE,
} from "../../../../providers/RBACProvider/permissionList";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Icon, Typography } from "../../../../components_v2";
import { TextButton } from "../../../../components/TextButton";

import _ from "lodash";
import SubStatusForm from "./SubStatusForm";
import ExpandableText from "../../../../utils/ExpandableText";
import { ModalContext } from "../../../../providers/ModalProvider";

import Warning from "../../../../assets/img/warning.png";
import useFormat from "../../../../hooks/useFormat";
import {
  resetInvoiceStatus,
  setEditAuditDataInvoice,
} from "../../../../redux/InvoiceStatusMaintenance/action";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import { toast } from "react-toastify";
const LIST = "LIST";
const ADD = "ADD";
const EDIT = "EDIT";
const VIEW = "VIEW";
const initialState = {
  type: LIST,
  resourceId: null,
  isPreFill: true,
};

function InvoiceOverviewStatusDrawer({
  setIsRequest,
  active,
  resourceId,
  resourceName,
}) {
  let customerId = "";
  const format = useFormat();
  // business Unit ID for V2 UI
  let { data, type } = useSelector((e) => e.customerOverviewDrawer);
  const { invoiceId, invoiceClientRef, invoiceNumber } = data;
  const { handleModal } = useContext(ModalContext);

  let { currentOrganization } = useContext(MaxyfiContext);
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const [state, setState] = useState(initialState);

  // Function to handle Add/Edit actions
  const handleAction = (type, id = null) => {
    setState({ ...state, type, resourceId: id, isPreFill: false });
  };

  const dispatch = useDispatch();
  const params = useParams();

  if (params.customerId) {
    customerId = params.customerId;
  } else {
    customerId = getSearchParams.get("customers");
  }

  const {
    data: singleInvoiceAudit,
    isLoading: singleInvoiceAuditLoading,
    isFetched: singleInvoiceFetched,
    isError: singleInvoiceError,
    refetch,
  } = useQuery(
    [`SINGLE_INVOICE_AUDIT_STATUS_${currentOrganization}_${invoiceId}`],
    async () => {
      return await getInvoiceStatusAudit({
        organization: currentOrganization,
        invoiceId: invoiceId,
      });
    },
    {
      enabled: !!invoiceId, // Only run query if isRuleId is defined
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const hasDocuments =
    Array.isArray(_.get(singleInvoiceAudit, "data.doc", [])) &&
    _.get(singleInvoiceAudit, "data.doc", []).length > 0;
  const [isFormOpen, setIsFormOpen] = useState(!hasDocuments);
  const deleteDocumentData = useMutation(
    ({ id }) =>
      deleteEditInvoiceStatusAudit(id, invoiceId, currentOrganization),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        refetch();
      },
    }
  );

  if (singleInvoiceAuditLoading) {
    return <Loader />;
  }

  return (
    <>
      <div style={{ width: "620px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            paddingRight: "30px",
          }}
        >
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <Icon icon="dispute" size={22} />
            <Typography
              type="h3"
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "490px",
              }}
            >
              {" "}
              Status Details for{" "}
              {`- ${invoiceClientRef} ${
                invoiceNumber && invoiceClientRef ? "/" : ""
              } ${invoiceNumber}`}
            </Typography>
          </div>
          {/* {Array.isArray(_.get(singleInvoiceAudit, "data.doc", [])) &&
          _.get(singleInvoiceAudit, "data.doc", []).length === 0 ? ( */}
          {_.get(state, "type", "") === "ADD" ||
          _.get(singleInvoiceAudit, "data.doc", []).length === 0 ? (
            <> </>
          ) : (
            <>
              {" "}
              <div>
                <RBACWrapper
                  role={ADD_SUB_MAIN_STATUS_MAINTENANCE}
                  type="PERMISSION"
                >
                  <TextButton
                    kind="secondary"
                    size="mini"
                    onClick={() => {
                      handleAction(ADD);
                      setIsFormOpen(true);
                      dispatch(resetInvoiceStatus());
                    }}
                  >
                    <Icon icon="plus" color="#516beb" size={16} />
                    Add
                  </TextButton>
                </RBACWrapper>
              </div>
            </>
          )}
        </div>

        {(state.type === ADD || !hasDocuments || state.type === EDIT) &&
        isFormOpen ? (
          <SubStatusForm
            customerId={customerId}
            type={state.type}
            invoiceId={invoiceId}
            setState={setState}
            state={state}
            setIsFormOpen={setIsFormOpen}
            // defaultData={invoice_audit_edit_data}
            timeLineId={_.get(state, "resourceId", "")}
          />
        ) : (
          <></>
        )}
        {Array.isArray(_.get(singleInvoiceAudit, "data.doc", [])) &&
        _.get(singleInvoiceAudit, "data.doc", []).length > 0 ? (
          <div
            className="table-main-container-overview-status-invoice"
            style={{ marginTop: "10px" }}
          >
            <table className="status-audit-table">
              <thead>
                <tr style={{ backgroundColor: "#F5F6FA" }}>
                  <th style={{}}>
                    {" "}
                    <Typography type="h4" className="regular">
                      {" "}
                      Status Details
                    </Typography>
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      width: "173px",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      type="h4"
                      className="regular"
                      style={{ textAlign: "right" }}
                    >
                      {" "}
                      Audit Information
                    </Typography>
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      width: "50px",
                      padding: "5px",
                    }}
                  >
                    <Typography type="p" className="regular" subType="regular">
                      {" "}
                    </Typography>{" "}
                  </th>
                </tr>
              </thead>

              <tbody>
                {Array.isArray(_.get(singleInvoiceAudit, "data.doc", [])) &&
                  _.get(singleInvoiceAudit, "data.doc", []).length > 0 &&
                  _.get(singleInvoiceAudit, "data.doc", []).map((e, i) => (
                    <tr key={i} className="sub_status_overview_hover_row">
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "5px",
                            padding: "0px 5px",
                          }}
                        >
                          <Typography
                            type="p"
                            className="regular"
                            subType="regular"
                          >
                            {" "}
                            {`${_.get(e, "name", "")} ${
                              _.get(e, "content.s_sts", "") ? ">" : ""
                            } ${_.get(e, "content.s_sts", "")} ${
                              _.get(e, "content.ctr", "") ? ">" : ""
                            } ${_.get(e, "content.ctr", "")}`}
                          </Typography>

                          <ExpandableText
                            description={_.get(e, "comment", "")}
                            width={395}
                            textAlign={"start"}
                            noOfLine={2}
                          />
                        </div>{" "}
                      </td>

                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "5px",
                            padding: "5px",
                          }}
                        >
                          {e?.executed_by && (
                            <Typography
                              type="p"
                              className="regular"
                              subType="regular"
                              style={{ color: "#adadad" }}
                            >
                              {format.rd({
                                name: "users",
                                id: e?.executed_by,
                              })}
                            </Typography>
                          )}
                          {e?.executed_on && (
                            <Typography
                              type="p"
                              className="regular"
                              subType="regular"
                            >
                              {format.date({
                                includeTime: true,
                                isOrg: true,
                                value: e?.executed_on,
                              })}
                            </Typography>
                          )}
                        </div>
                      </td>

                      {/* // QUANTITY */}
                      <td>
                        <div className="sub_status_icon_outline">
                          <RBACWrapper
                            role={EDIT_SUB_MAIN_STATUS_MAINTENANCE}
                            type="PERMISSION"
                          >
                            <Icon
                              icon="edit_outline"
                              isPressable
                              onClick={() => {
                                handleAction(EDIT, e?._id);
                                setIsFormOpen(true);
                                dispatch(
                                  setEditAuditDataInvoice({
                                    invoice_audit_edit_data: e,
                                  })
                                );
                              }}
                            />
                          </RBACWrapper>
                          <RBACWrapper
                            role={DELETE_SUB_MAIN_STATUS_MAINTENANCE}
                            type="PERMISSION"
                          >
                            <Icon
                              icon="crosss"
                              isPressable
                              onClick={() => {
                                handleModal({
                                  title: "",
                                  content:
                                    "This will delete the status and details captured.Click confirm to continue",
                                  successCallback: () => {
                                    deleteDocumentData.mutate({
                                      id: _.get(e, "_id", ""),
                                    });
                                  },
                                  formContent: () => {
                                    return <></>;
                                  },
                                  cancelCallback: () => {},
                                  isChildren: false,
                                  image: Warning,
                                  successButtonType: "error",
                                  buttonText: "Delete",
                                  closeButtonText: "Cancel",
                                  isCloseAble: false,
                                });
                              }}
                            />
                          </RBACWrapper>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <>
            <Typography
              type="p"
              className="text-secondary"
              style={{ marginTop: "25%", textAlign: "center" }}
            >
              No Records Found
            </Typography>
          </>
        )}
      </div>
    </>
  );
}

export default InvoiceOverviewStatusDrawer;
