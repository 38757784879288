// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const updateCreditPortal = async ({
  newValues,
  organization = null,
  id,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${id}/customer-portal-setting`,
    newValues,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

//Bulk Customer portal Setting

export const assignBulkCustomerPortalSetting = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/assign/CUSTOMER_PORTAL_SETTINGS`,
    {
      customers: customerId,
      ...rest,
    }
  );
};

export const resetCustomerPortalSettings = async ({
  organization = null,
  id,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${id}/customer-portal-setting`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};
