import React, { useContext, useEffect } from "react";
import { TextButton, SIZE, KIND } from "../TextButton";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { LabelLarge, ParagraphLarge, ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";
import { SelectBox } from "../SelectBox";
import { MaxyfiProvider } from "../../providers/MaxyfiProvider";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { assignMulCusRel, createWorkList, worklistMain } from "../../services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { useSelector } from "react-redux";
import moment from "moment";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import DateRangeSelect from "../TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../TextArea";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import _ from "lodash";
import setSelectValues from "../../utils/setSelectValues";

const WorkListForm = ({
  selectedCustomer,
  handleModal,
  toggleAllRowsSelected,
  refetch,
  isAllRowsSelected,
  queryFilter,
}) => {
  let {
    currentOrganization,
    users,
    referenceData,
    restrictions,
    space,
    appAccessRest,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  const { filters, globalFilters } = useSelector(
    (s) => s.customerSummary.tableCurrentState
  );
  const { tableCurrentState, gridStructure } = useSelector(
    (state) => state.customerSummary
  );

  let rm = users.filter((i) => i.active);

  let findingUser = rm?.filter((i) =>
    i.organization?.includes(currentOrganization)
  );

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];

  const assignWorklist = useMutation(
    (relMang) =>
      createWorkList({
        ...relMang,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
          toggleAllRowsSelected(false);
        }
        refetch();
        handleModal();

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);
        reset();
        handleModal();
        refetch();
      },
    }
  );

  const { isLoading, data, isFetching } = useQuery(
    [`WORKLIST_BULK_LIST-${currentOrganization}`],
    async () => {
      return await worklistMain({
        organization: currentOrganization,
        filters: {
          type: "EXCLUDE_ALL",
        },
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_all_customer: false,
    },
  });

  let { wl } = watch();

  useEffect(() => {
    if (wl && wl[0] && wl[0].id && wl[0].users) {
      setValue(
        "users",
        setSelectValues(
          _.get(data, `data.docs[${wl[0].index}].users`, []),
          true
        )
      );
    }
  }, [wl?.[0]?.id]);

  const onSubmit = async (data) => {
    let newFilter = dynamicQueryFilter({
      gridStructure: gridStructure,
      timeZone: currentDefaultFormatDetails.time_zone,
      customerFilter: filters,
    });

    let values = {
      typ: "AWQ",
      users: getSelectValues(data.users, true),
      wl: getSelectValues(data.wl),
      ...(data?.cmt?.length ? { comment: data.cmt } : {}),
      ...(data?.dt
        ? {
            dt: moment
              .tz(
                `${data.dt.getFullYear()}-${
                  data.dt.getMonth() + 1
                }-${data.dt.getDate()}`,
                "YYYY-MM-DD",
                currentDefaultFormatDetails.time_zone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      is_all_customer: data.is_all_customer,
      ...(data.is_all_customer
        ? {
            query: newFilter ? newFilter : {},
            filters: {
              ...globalFilters,
              ...appAccessRest,
              needs_actions: [...NeedsActionFilter],
              stage: [...FilteredCustomerStage],
              search_value: queryFilter && queryFilter.display_name,
            },
          }
        : { customers: selectedCustomer }),
    };

    await assignWorklist.mutateAsync(values);
    // toggleAllRowsSelected(false);
  };

  const intl = useIntl();
  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ParagraphLarge
          style={{
            display: "flex",
            paddingBottom: "5px",
            justifyContent: "center",
          }}
        >
          Add Worklist
        </ParagraphLarge>
        <Controller
          defaultValues={[]}
          name="wl"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              size={SIZE.compact}
              {...field}
              name={field.name}
              requiredAstric={true}
              // multi
              creatable={creatablePermission ? true : false}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "select_the_worklist",
              })}
              placeholder={intl.formatMessage({
                id: "select_the_worklist",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={_.get(data, "data.docs", []).map((e, i) => {
                return {
                  ...e,
                  index: i,
                  label: e?.nm,
                  id: e?._id,
                };
              })}
            />
          )}
        />

        <Controller
          name="dt"
          control={control}
          // rules={{ required: "Required" }}
          render={({ field }) => (
            <DateRangeSelect
              {...field}
              name={field.name}
              // requiredAstric={true}
              // disabled={isDisabled}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "worklist_date",
              })}
              placeholder={intl.formatMessage({
                id: " ",
              })}
              value={field.value}
              size={SIZE.mini}
              onChange={(e) => field.onChange(e.date)}
              minDate={new Date()}
            />
          )}
        />
        <Controller
          defaultValues={[]}
          name="users"
          control={control}
          // rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              size={SIZE.compact}
              {...field}
              name={field.name}
              // requiredAstric={true}
              multi
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "assign_worklist_to",
              })}
              placeholder={intl.formatMessage({
                id: "assign_worklist_to",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={findingUser.map((i) => {
                return { label: i.displayName, id: i.id };
              })}
            />
          )}
        />

        <Controller
          control={control}
          name="cmt"
          render={({ field }) => (
            <TextArea
              {...field}
              name={field.name}
              label={intl.formatMessage({
                id: "comments",
              })}
              placeholder={intl.formatMessage({
                id: "comments",
              })}
              size={SIZE.mini}
              value={field.value}
            />
          )}
        />

        {isAllRowsSelected && (
          <Controller
            name="is_all_customer"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall style={{ color: "#333860" }}>
                  Update all the matching consumer records
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        )}
        <div
          style={{
            // marginTop: "15px",
            textAlign: "center",
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
            // paddingBottom: "20px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.compact}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
              //   disabled={isSubmitting || smsConfigBtn}
            >
              Apply
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default WorkListForm;
