import moment from "moment";
import { useContext } from "react";
import _ from "lodash";
const isTimestamp = (value) => {
  if (typeof value === "number" && value > 100000000000) {
    return !isNaN(new Date(value).getTime()); // Ensure it's a valid date
  }

  if (typeof value === "string" && /^\d{12,}$/.test(value)) {
    return !isNaN(new Date(Number(value)).getTime());
  }

  return false;
};
const reverseRuleQueryFilter = (props) => {
  let {
    gridStructure = {},
    customerQueryFilter = "",
    timeZone = "",
    customerFilter = {},
    fieldName = "",
  } = props;

  let newFilter = {
    combinator: "and",
    rules: [],
    ...customerFilter,
  };

  const formatDate = (timestamp, timeZone) => {
    if (!timestamp || isNaN(Number(timestamp))) return "Invalid Date";

    return moment.tz(Number(timestamp), timeZone).format("YYYY-MM-DD");
  };
  const GridStructure = gridStructure?.table?.reduce((prev, curr) => {
    if (curr.nested) {
      return [...prev, ...(curr?.nested ? curr?.nested : [])];
    }
    return [...prev, curr];
  }, []);

  const findDateObj = GridStructure?.filter((dq) => dq.type === "DATE").map(
    (dq) => dq.accessor
  );

  if (fieldName && customerQueryFilter?.length >= 0) {
    newFilter.rules = [
      ...newFilter.rules,
      {
        operator: "contains",
        value: customerQueryFilter,
        id: "8fd3ed39-52ec-4e5e-986e-6829a8d0d047",
        field: fieldName,
        valueSource: "value",
      },
    ];
  }

  newFilter.rules = newFilter?.rules?.map((rule) => {
    if (
      typeof rule?.value === "string" &&
      rule?.value?.includes(",") &&
      rule?.value.split(",").every((ts) => isTimestamp(ts.trim()))
    ) {
      // Handle multiple timestamps separated by a comma
      const timestamps = rule?.value?.split(",");
      const formattedDates = timestamps
        .map((ts) => formatDate(ts, props?.timeZone))
        .join(",");
      return { ...rule, value: formattedDates };
    } else if (typeof rule?.value === "number" && isTimestamp(rule?.value)) {
      const formattedDate = formatDate(
        _.get(rule, "value", ""),
        props.timeZone
      );
      return { ...rule, value: formattedDate };
    }

    return rule;
  });

  return newFilter;
};

export default reverseRuleQueryFilter;
