import React from "react";
import { Icon, RDText, Typography } from "../../components_v2";
import CurrencyFormate from "../../components_v2/CurrencyFormate";
import moment from "moment";
import { useContext } from "react";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useState } from "react";
import DateComponent from "../../components_v2/DateComponent";
import { DATE_ORG } from "../../constants";
import { FormattedTime } from "react-intl";
import _ from "lodash";
const TimelineCardWraper = (props) => {
  let {
    action,
    actionType,
    comment,
    title = null,
    children,
    icon = "",
    selectedId,
  } = props;
  const { referenceData, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  const executedBy =
    referenceData &&
    referenceData["users"] &&
    Array.isArray(referenceData["users"])
      ? referenceData["users"].find(
          (e) => e.id === _.get(props, "executed_by", "")
        )
      : null;

  return (
    <div
      className={
        selectedId
          ? "co_timeline_view_card_container"
          : "co_timeline_card_container"
      }
    >
      <div className="co_timeline_header_container">
        <div className="co_timeline_header">
          <Icon icon={icon} size={18} />
          <Typography type="h4">{title}</Typography>
        </div>
        {children}
        {(comment && comment.length > 0 && props?.type === "notes") ||
        props?.type === "business_unit" ||
        props?.type === "LGA" ||
        props?.type === "app_payment" ||
        _.get(props, "type", "") === "contacts" ||
        _.get(props, "type", "") === "RV_PAY" ||
        (props &&
          props?.content &&
          props?.content?.call_outcome === "NOTES") ? (
          <></>
        ) : (
          <div className="co_timeline_comment_wraper">
            {comment && comment.length > 0 && (
              <>
                <Typography
                  type="p"
                  className="text-secondary"
                  subType="regular"
                >
                  {_.get(props, "type", "") === "INV_STS"
                    ? "Status Detailed Text:"
                    : "Comments: "}
                </Typography>
                <Typography type="p" subType="regular">
                  {comment
                    ? comment
                        .replace(/(<([^>]+)>)/gi, "")
                        .replace(/&amp;/g, "&")
                    : " - "}
                </Typography>
              </>
            )}
          </div>
        )}
      </div>
      <div className="co_timeline_footer_contain">
        <Typography type="p" className="text-secondary" subType="regular">
          Captured By:{" "}
          {props && props.executed_by && executedBy?.label
            ? executedBy?.label
            : _.get(props, "is_receiver", "")
            ? "Consumer"
            : "System"}{" "}
          <DateComponent type={DATE_ORG} value={props && props.executed_on} />{" "}
          {moment
            .tz(
              props && props.executed_on,
              currentDefaultFormatDetails.time_zone
            )
            .format("hh:mm A")}
        </Typography>
      </div>
    </div>
  );
};

export default TimelineCardWraper;
