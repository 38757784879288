import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useMutation, useQuery } from "react-query";

import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TextButton } from "../../../../components/TextButton";
import queryClient from "../../../../providers/queryClient";
import {
  ACT_RECORD_DISPUTE,
  CO_INVOICES_,
  CUSTOMER_OVERVIEW_,
} from "../../../../constants";
import getSelectValues from "../../../../utils/getSelectValues";
import ActionReminders from "../../../../components_v2/ActionReminders";
import getActionReminder from "../../../../utils_v2/getActionReminders";
import { ADD_LIST_VALUES } from "../../../../providers/RBACProvider/permissionList";
import { TextArea } from "../../../../components/TextArea";
import {
  CLIENT_PORTAL_ACCESS,
  ASSIGN_RI_TO_CLIENT,
} from "../../../../providers/RBACProvider/permissionList";
import useFormat from "../../../../hooks/useFormat";
import { Icon, Typography } from "../../../../components_v2";
import { useDebouncedCallback } from "use-debounce";
import { getHolidayBlackoutHolidayDate } from "../../../../services";
import setSelectValues from "../../../../utils/setSelectValues";

const DisputeForm = (props) => {
  const {
    formValues = {},
    service,
    onSuccess = () => {},
    onCancel = () => {},
    isIncrementor = true,
    isContent = true,
    isConsent = true,
    is_inbound_call = false,
    isStatus = false,
  } = props;
  const { customerId } = useParams();
  const intl = useIntl();

  let {
    referenceData,
    currentDefaultFormatDetails,
    currentOrganization,
    space,
  } = useContext(MaxyfiContext);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const invoicesData = queryClient.getQueryData([
    `${CO_INVOICES_}${customerId}`,
    { filters: {}, gid: _.get(customerData, "data.doc.gid", "") },
  ]);
  const consumerGroupText = _.get(invoicesData, "data.docs", []).some((obj) =>
    obj.hasOwnProperty("pgid")
  );
  const [isChangeInvoices, setIsChangeInvoices] = useState([]);
  const [text, setAlert] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      dispute_status: [{ id: "OPEN" }],
    },
  });

  const format = useFormat();

  const invOption = useMemo(() => {
    let findpgid;

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      customerData.data.doc.pgid
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
      );
    }

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      !customerData.data.doc.pgid &&
      customerData.data.doc._id
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ customer_id }) => customer_id === _.get(customerData, "data.doc._id")
      );
    }

    let invIds = findpgid
      .filter((fl) => fl.status !== "paid" && fl.status !== "stl")
      .map(({ _id, not_paid_amount, ...e }) => ({
        id: _id,
        outstanding: not_paid_amount,
        label: `${format.rd({
          id: _.get(e, "business_unit", ""),
          name: "business_unit_list",
        })}${_.get(e, "business_unit", "") ? " / " : ""}${_.get(
          e,
          "invoice_number",
          ""
        )}${_.get(e, "invoice_number", "") ? " / " : ""}
      ${_.get(e, "client_reference", "")}${
          _.get(e, "client_reference", "") ? " / " : ""
        }
      ${moment
        .utc(e.invoice_date)
        .tz(currentDefaultFormatDetails.time_zone)
        .format(currentDefaultFormatDetails.date_format)}${
          _.get(not_paid_amount, "currency", "") ? " / " : ""
        }${_.get(not_paid_amount, "currency", "")}${
          _.get(not_paid_amount, "value", "") ? " " : ""
        }${_.get(not_paid_amount, "value", "")}`,
      }));
    setValue("invoices", [...invIds]);
    setIsChangeInvoices([...invIds]);
    setCurrentInvoice([...invIds]);
    return invIds;
  }, [invoicesData]);

  const riqPermission =
    space &&
    space.role_permissions &&
    space.role_permissions.includes(ASSIGN_RI_TO_CLIENT);

  const orgUsers = useMemo(() => {
    let orgBusiness = referenceData["users"].filter((e) => {
      return (
        e?.organization?.includes(currentOrganization) &&
        e.active &&
        !(
          e.is_client_login == true &&
          !e.business_unit
            ?.map((bt) => bt.business_unit_id)
            ?.includes(props.business_unit)
        )
      );
    });
    return orgBusiness || [];
  }, [props.business_unit]);

  const filterRiqUser = orgUsers?.filter(
    (e) => e && e.is_client_login !== true
  );

  const businessUnit = referenceData["business_unit_list"]
    ? referenceData["business_unit_list"].find(
        (e) => e.id === _.get(customerData, "data.doc.business_unit", "")
      )
    : null;

  const [reviewDate] = watch(["dispute_review_date"]);

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: {
          date: reviewDate
            ? [
                moment(reviewDate)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf(),
              ]
            : null,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: customerId,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: reviewDate ? true : false,
    }
  );

  const serviceMutation = useMutation(
    (formData) =>
      service({
        ...formData,
        id: customerId,
        entity: "customer",
        organization: currentOrganization,
      }),
    {
      onSuccess: () => {
        onSuccess();
      },
    }
  );

  const onSubmit = async (data) => {
    const {
      dispute_details,
      dispute_type,
      invoices = [],
      assigned_to,
      dispute_review_date,
      dispute_status,
      amt,
    } = data;

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      ...formValues,
      ...(isContent ? { content: "-" } : {}),
      dispute_details,
      dispute_type: getSelectValues(dispute_type),
      ...(invoices && invoices[0]
        ? {
            invoices: getSelectValues(invoices, true),
          }
        : {}),
      ...(assigned_to && assigned_to[0]
        ? {
            assigned_to: getSelectValues(assigned_to),
            ...(assigned_to[0].isClient
              ? {
                  assign_type: "BUSINESS_UNIT",
                }
              : { assign_type: "USER" }),
          }
        : {}),
      ...(dispute_review_date
        ? {
            dispute_review_date: moment
              .tz(
                `${dispute_review_date.getFullYear()}-${
                  dispute_review_date.getMonth() + 1
                }-${dispute_review_date.getDate()}`,
                "YYYY-MM-DD",
                currentDefaultFormatDetails.time_zone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(dispute_status
        ? { dispute_status: getSelectValues(dispute_status) }
        : {}),
      ...(amt ? { amt: Number(amt) } : {}),

      ...getActionReminder(data, {
        is_calendar: false,
        is_followup: false,
        is_confirmation: false,
        is_incrementor: isIncrementor,
        is_consent_enabled: isConsent,
      }),
      mention_users: uniqueMentionedUsers,
    };

    await serviceMutation.mutateAsync(values);
  };

  useEffect(() => {
    setValue(
      "amt",
      isChangeInvoices.length > 0
        ? isChangeInvoices
            .reduce((sum, item) => +sum + +item?.outstanding?.value, 0)
            .toFixed(2)
        : ""
    );
  }, [isChangeInvoices]);

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ paddingTop: "10px" }}>
        <Controller
          defaultValues={""}
          name="dispute_details"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextArea
              size={SIZE.default}
              requiredAstric={true}
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "capture_details",
              })}
              placeholder={intl.formatMessage({
                id: "details",
              })}
              value={field.value}
            />
          )}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          // width: "400px",
        }}
      >
        <div style={{ width: "200px" }}>
          <Controller
            name="dispute_type"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                requiredAstric={true}
                {...field}
                creatable={creatablePermission ? true : false}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "dispute_type",
                })}
                placeholder={intl.formatMessage({
                  id: "dispute_type",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["dispute_type"] || []}
              />
            )}
          />
        </div>

        <div style={{ width: "300px" }}>
          <Controller
            name="invoices"
            control={control}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "invoices",
                })}
                placeholder={intl.formatMessage({
                  id: "invoices",
                })}
                value={field.value}
                getValueLabel={({ option }) => {
                  const { label } = option;
                  const value = label ? label.split("/") : [];
                  return <>{value && value[1] ? value[1] : value[0]}</>;
                }}
                onChange={(e) => {
                  field.onChange(e.value);
                  setIsChangeInvoices(e.value);
                  setAlert(e.value.length < currentInvoice.length);
                }}
                multi
                options={invOption}
              />
            )}
          />
        </div>
        <div style={{ width: "200px" }}>
          <Controller
            name="amt"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <>
                <TextBox
                  {...field}
                  size={SIZE.mini}
                  type="number"
                  //   disabled={isDisable}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "dispute_amount",
                  })}
                  placeholder={intl.formatMessage({
                    id: "dispute_amount",
                  })}
                  value={field.value}
                />
              </>
            )}
          />
        </div>
      </div>
      {text && _.get(customerData, "data.doc.gid") && consumerGroupText && (
        <Typography
          className="co_action_exist"
          style={{ textAlign: "start", padding: "5px" }}
        >
          * New Group will be created for the selected records.
        </Typography>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          width: "500px",
        }}
      >
        <Controller
          name="assigned_to"
          control={control}
          render={({ field }) => (
            <SelectBox
              size={SIZE.mini}
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "assigned_to",
              })}
              placeholder={intl.formatMessage({
                id: "assigned_to",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={
                orgUsers && Array.isArray(orgUsers) && riqPermission
                  ? [
                      ...orgUsers,
                      ...(businessUnit && businessUnit.id
                        ? [
                            {
                              id: businessUnit.id,
                              label: businessUnit.label,
                              isClient: true,
                            },
                          ]
                        : []),
                    ]
                  : filterRiqUser && !riqPermission
                  ? [...filterRiqUser]
                  : []
              }
            />
          )}
        />
        <Controller
          name="dispute_review_date"
          control={control}
          render={({ field }) => (
            <DateRangeSelect
              clearable={false}
              size={SIZE.mini}
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "review_date",
              })}
              placeholder={intl.formatMessage({
                id: " ",
              })}
              value={field.value}
              onChange={(e) => field.onChange(e.date)}
              minDate={new Date()}
            />
          )}
        />
        <Controller
          name="dispute_status"
          control={control}
          render={({ field }) => (
            <SelectBox
              size={SIZE.mini}
              {...field}
              name={field.name}
              clearable={false}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "dispute_status",
              })}
              placeholder={intl.formatMessage({
                id: "dispute_status",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={referenceData["dispute_status"] || []}
            />
          )}
        />
      </div>
      {_.get(data, "data.doc.is_holiday") && (
        <>
          <div className="alert-holiday-section">
            <Icon icon="alert_circle" color="#516BEB" />
            <Typography
              type="p"
              style={{
                fontWeight: "400",
              }}
            >
              The Date Selected{" "}
              {_.get(data, "data.doc.holidays", []).map((val) => {
                return (
                  <>
                    {moment(val).format(
                      currentDefaultFormatDetails.date_format
                    )}
                  </>
                );
              })}{" "}
              is a <b>“Holiday”</b>
            </Typography>
          </div>
        </>
      )}
      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType={formValues.action_type}
        is_inbound_call={
          formValues.is_inbound_call ? true : false || is_inbound_call
        }
        outcome={ACT_RECORD_DISPUTE}
        consentDate={reviewDate}
        callValidationDuration={_.get(
          customerData,
          "data.doc.mini_miranda.call_validation_duration"
        )}
        timeZone={_.get(customerData, "data.doc.timezone")}
        is_calendar={false}
        is_followup={false}
        is_confirmation={false}
        is_incrementor={props.isIncrementor}
        is_consent={props.isConsent}
        is_valid_from_contact={
          formValues.action_type === "FIELD_VISIT" ||
          formValues.action_type === "ACTION_CAPTURE"
            ? true
            : false
        }
        is_status={isStatus}
      />

      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          disabled={serviceMutation.isLoading}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </TextButton>
        <TextButton
          size="mini"
          disabled={serviceMutation.isLoading}
          isLoading={serviceMutation.isLoading}
        >
          Save
        </TextButton>
      </div>
    </form>
  );
};

export default DisputeForm;
