import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";
import _ from "lodash";
import ReactQuill from "react-quill";

import { Icon, Typography } from "../../../components_v2";
import { SelectBox } from "../../../components/SelectBox";
import { TextBox } from "../../../components/TextBox";
import { KIND, TextButton } from "../../../components/TextButton";
import { TextBoxTag } from "../../../components/TextBoxTag/TextBoxTag";

import {
  COMMUNICATION_TEMPLATE,
  CUSTOMER_OVERVIEW_,
  CO_TIMELINE_,
  DATE_ORG,
} from "../../../constants";
import {
  getCommunicationTemplateList,
  getCommunicationTemplateFinalContent,
  executeAdhocCustomerAction,
  executeCustomerAction,
  executePaymentAction,
} from "../../../services";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import getSelectValues from "../../../utils/getSelectValues";
import ActionReminders from "../../../components_v2/ActionReminders";
import getActionReminder from "../../../utils_v2/getActionReminders";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { useDropzone } from "react-dropzone";
import EmailAttachment from "../../../components/EmailAttachment/EmailAttachment";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmailAttachmentQuickActionFromDesktop,
  initialQAReducer,
} from "../../../redux/invoiceOverview/invoiceQA/action";
import {
  addEmailAttachmentFromDesktop,
  documentDrive,
  typeToInitial,
} from "../../../redux/customerOverview/DMSystem/action";
import { getDocumentData } from "../../../services/documentSystem";
import { useState } from "react";
import SelectDoc from "./SelectDoc";
import AgentActionAlert from "../../../components_v2/AgentActionAlert";
import ActionHeader from "./ActionHeader";
import { EMAIL_DRAWER, minimizeSMSActionData } from "../../../redux/actions";
import RichTextEditorEmail from "../RichTextEditorEmail";
import { EMAIL_EDIT } from "../../../providers/RBACProvider/permissionList";
import useFormat from "../../../hooks/useFormat";
import { getStatusChg } from "./statusType";

const EmailAction = ({
  editData,
  resource = "CUSTOMER",
  closeDrawer,
  entity,
  id,
  master_tag,
  removeAttchFile,
  updatingDeskUpload,
  setAttachment,
  tags,
  action = "",
}) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const format = useFormat();
  const { customerId } = useParams();
  const editorRef = useRef(null);
  const {
    currentOrganization,
    referenceData,
    currentDefaultFormatDetails,
    space,
  } = useContext(MaxyfiContext);

  const [selectedData, setSelectedData] = useState([]);

  const [isDriveData, setIsDriveData] = useState([]);
  const [isSearch, setIsSearch] = useState("");
  const [agentInstru, setInstruc] = useState("");

  const drawerData = useSelector((state) => state.customerOverviewDrawer);

  const customer = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  let dispatch = useDispatch();
  const { emailAttachment } = useSelector((s) => s.customerDocumentReducer);
  const { count, quickActionsTempData } = useSelector(
    (e) => e.customerOverviewDrawer
  );

  const emailState = useSelector((e) => e.customerOverviewDrawer);

  const { template, emailAttached } = useMemo(() => {
    let template = [];
    let emailAttached = [];

    emailAttachment &&
      emailAttachment.map((e) => {
        if (e.type == "TEMPLATE") {
          template.push(e.fileRef);
        } else {
          emailAttached.push(e.fileRef);
        }
      });

    return { template, emailAttached };
  }, [emailAttachment.map((e) => e.fileRef)]);

  const fileSize = 10000000;
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 1MB`,
      };
    }
  }

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: {
        "image/jpg": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      validator: uploadLogoValidation,

      onDrop: (acceptedFiles) => {
        let arrObj = acceptedFiles.map((fileDoc) => {
          let { path, type } = fileDoc;

          let splitType = type.split("/");

          return {
            name: path,
            type: splitType[1],
            mimeType: type,
            fileDoc,
          };
        });
        if (entity === "invoices") {
          dispatch(addEmailAttachmentQuickActionFromDesktop(arrObj));
        } else {
          dispatch(addEmailAttachmentFromDesktop(arrObj));
        }
      },
    });

  const dafaultData = useMemo(() => {
    let {
      id,
      name,
      customerId,
      action,
      bcc,
      cc,
      template_id,
      emailAttachment,

      ...rest
    } = emailState && emailState?.data;
    return (
      id && {
        template_id: [
          {
            ...template_id?.[0],
            id: template_id && template_id[0] && template_id[0]?._id,
          },
        ],
        bcc: bcc,
        cc: cc,
        ...rest,
      }
    );
  }, [emailState]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...(dafaultData ? { ...dafaultData } : { bcc: [], cc: [] }),
    },
  });

  const { isLoading, data, refetch, isFetching } = useQuery(
    [
      `document-management-system-${customerId}`,
      // {
      //   sort: sortName,
      // },
    ],
    async ({ queryKey }) => {
      // let { sort } = queryKey[1];

      if (customerId) {
        return await getDocumentData({
          organization: currentOrganization,
          customerId: customerId,
          // sort,
        });
      }
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  // catch doc data for searching
  useEffect(() => {
    if (_.get(data, "data.docs", [])) {
      setIsDriveData(_.get(data, "data.docs", []));
    }
  }, [data]);

  // const searchByName = (key) => {};

  useEffect(() => {
    if (
      isSearch &&
      isSearch.length > 0 &&
      data &&
      data.data &&
      data.data.docs
    ) {
      const searchResult = _.get(data, "data.docs", []).filter((item) =>
        item.file_name.toLowerCase().includes(isSearch.toLowerCase())
      );
      setIsDriveData(searchResult);
    } else {
      setIsDriveData(_.get(data, "data.docs", []));
    }
  }, [isSearch]);

  let template_id = watch("template_id");
  let {
    recipients,
    cc,
    bcc,
    subject,
    content,
    is_increment_follow_up,
    comment,
  } = watch();

  useEffect(() => {
    const getTemplateContent = async () => {
      let templateContent = await getCommunicationTemplateFinalContent({
        organization: currentOrganization,
        templateId: template_id[0].id,
        entity: resource,
        id: _.get(drawerData, "data.editData.customer_id", customerId),
        ...(action === "PAYMENT"
          ? { paymentId: drawerData?.data?.payment_id }
          : {}),
      });

      if (
        templateContent.data &&
        templateContent.data.doc &&
        template_id &&
        template_id[0] &&
        template_id[0].id
      ) {
        let { subject, value } = templateContent.data.doc;

        setValue("content", value?.replaceAll(String.fromCharCode(92), ""));
        setInstruc(value?.toString());
        setValue("subject", subject);
        let emailAttachedData = [];
        if (template_id && template_id[0] && template_id[0]?.attachments) {
          emailAttachedData =
            template_id[0].attachments &&
            Array.isArray(template_id[0]?.attachments)
              ? template_id[0].attachments.map((e) => {
                  return {
                    ...e,
                    fileRef: e.id,
                    isTemplate: e.type === "TEMPLATE" ? true : false,
                    scanStatus: "SUCCESS",
                  };
                })
              : [];
        }

        dispatch(setAttachment(emailAttachedData));
      }
    };

    if (template_id && template_id[0] && template_id[0].id) {
      getTemplateContent();
    }
  }, [template_id?.[0]?.id]);

  const templates = useQuery(
    [
      `${COMMUNICATION_TEMPLATE}-${currentOrganization}-${resource}-EMAIL`,
      {
        filters: {
          disable: false,
          type: "email",
          tags: tags,
          ...(master_tag ? { master_tag: master_tag } : {}),
        },
      },
    ],
    async ({ queryKey }) => {
      let { page, page_size, sortBy, filters, type } = queryKey[1];
      return await getCommunicationTemplateList({
        organization: currentOrganization,
        clientCode: [currentOrganization],
        filters,
      });
    }
  );

  const templateOptions = useMemo(() => {
    return action === "PAYMENT"
      ? _.get(templates, "data.data.docs", []) &&
          _.get(templates, "data.data.docs", []).map((itm) => ({
            ...itm,
            label: `${itm.name} (${
              itm.language
                ? itm.language.toLocaleUpperCase()
                : "en".toLocaleUpperCase()
            })`,
            id: itm._id,
          }))
      : _.get(templates, "data.data.docs", []) &&
        Array.isArray(_.get(templates, "data.data.docs", []))
      ? _.get(templates, "data.data.docs", []).map((itm) => ({
          ...itm,
          label: `${itm.name} (${
            itm.language
              ? itm?.language?.toLocaleUpperCase()
              : "en".toLocaleUpperCase()
          })`,
          id: itm._id,
        }))
      : [];
  }, [templates.isFetching]);

  const contactOptions = useMemo(() => {
    const contacts = _.get(customer, "data.doc.contacts", [])
      .reduce((prev, curr) => {
        let { phone, landline, email = [], address, ...rest } = curr;
        let nestedContacts = email.map(({ status, email_status, ...nRest }) => {
          let designation =
            referenceData && referenceData["recipient_type"]
              ? referenceData["recipient_type"].find(
                  (rf) => rf?.id === curr?.designation
                )
              : "";

          return {
            label: `${curr?.first_name} - ${designation?.label} - ${
              nRest?.value
            }- (${format.rd({
              name: "contact_status",
              id: getStatusChg(status, email_status),
            })}) ${nRest.comment ? "-" : ""} ${
              nRest.comment ? nRest.comment : ""
            }`,
            id: nRest?._id,
            recipient_id: curr?._id,
            email_status,
            status,
            designation: curr?.designation,
            comment: nRest.comment,
            cat: nRest.cat,
            disabled:
              status === "INVALID" ||
              email_status === "INVALID" ||
              status === "DNC" ||
              email_status === "DNC",
          };
        });

        return [...prev, ...nestedContacts];
      }, [])
      .sort((a, b) => {
        if (a.cat === undefined) return 1;
        if (b.cat === undefined) return -1;
        return b.cat - a.cat;
      });

    const { data } = drawerData;

    if (data && data.isWorkflow) {
      if (data.recipientTypes && Array.isArray(data.recipientTypes)) {
        const selectedContacts = contacts
          .filter((ds) => ds.disabled === false)
          .filter((e) => data.recipientTypes.includes(e.designation));
        setValue("recipients", selectedContacts);
      }

      let emailAttachedData = [];

      if (
        drawerData &&
        drawerData.data &&
        drawerData.data.templateAttachments
      ) {
        emailAttachedData = Array.isArray(drawerData.data.templateAttachments)
          ? drawerData.data.templateAttachments.map((e) => {
              return {
                ...e,
                fileRef: e.id,
                isTemplate: e.type === "TEMPLATE" ? true : false,
                scanStatus: "SUCCESS",
              };
            })
          : [];
        dispatch(setAttachment(emailAttachedData));
      }

      setValue("content", data.content);
      setInstruc(data.content);
      setValue("subject", data.subject);
    } else {
      const selectedContacts = contacts
        .filter((ds) => ds.disabled === false)
        .filter((e) => ["primary_contact"].includes(e.designation));
      setValue("recipients", selectedContacts);
    }

    return contacts;
  }, [customer]);

  const executeActionMutation = useMutation(
    (formValues) =>
      !drawerData || !drawerData.data || !drawerData.data.isWorkflow
        ? executeAdhocCustomerAction({
            ...formValues,
            id: _.get(drawerData, "data.editData.customer_id", customerId),
            organization: currentOrganization,
            entity: resource,
          })
        : executeCustomerAction({
            ...formValues,
            id: customerId,
            organization: currentOrganization,
            entity: resource,
          }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
        });
        queryClient.invalidateQueries({
          queryKey: [`${CO_TIMELINE_}${customerId}`],
        });
        reset();
        closeDrawer();
      },
    }
  );

  const executePaymentMutation = useMutation(
    (formValues) =>
      executePaymentAction({
        ...formValues,
        id: _.get(drawerData, "data.editData.customer_id", id),
        organization: currentOrganization,
        entity: entity,
        paymentId: drawerData?.data?.payment_id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        closeDrawer();
      },
    }
  );

  const onSubmit = async (data) => {
    let recipients = [];
    if (data && data.recipients) {
      data &&
        data?.recipients?.map((e) => {
          recipients?.push({ id: e?.id, recipient_id: e?.recipient_id });
        });
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));
    let values = {
      action_type: "email",
      subject: data.subject,
      content: data.content,
      template_id: getSelectValues(data.template_id),
      recipients: recipients,
      cc: data.cc,
      bcc: data.bcc,
      attachments: emailAttached,
      template_attachments: template,
      ...getActionReminder(data, {
        is_consent_enabled: false,
        is_calendar: false,
        is_followup: false,
        is_confirmation: false,
        is_hold: false,
      }),
      mention_users: uniqueMentionedUsers,
    };

    let paymentValue = {
      subject: data.subject,
      content: data.content,
      template_id: getSelectValues(data.template_id),
      recipients: recipients,
      cc: data.cc,
      bcc: data.bcc,
      attachments: emailAttached,
      template_attachments: template,
      ...getActionReminder(data, {
        is_consent_enabled: false,
        is_calendar: false,
        is_followup: false,
        is_confirmation: false,
        is_hold: false,
        is_incrementor: false,
      }),
      mention_users: uniqueMentionedUsers,
    };

    if (action === "PAYMENT") {
      await executePaymentMutation.mutateAsync(paymentValue);
    } else {
      await executeActionMutation.mutateAsync(values);
    }
  };

  // const onFileSelectChange = (file) => {
  //
  //   let newSelectedFiles = selectedData;
  //   let isSelected = newSelectedFiles.find((fl) => fl.fileRef === file.fileRef);
  //   if (isSelected) {
  //     newSelectedFiles = newSelectedFiles.filter(
  //       (fl) => fl.fileRef !== file.fileRef
  //     );
  //   } else {
  //     newSelectedFiles.push(file);
  //   }

  //
  //   setSelectedData(newSelectedFiles);
  // };

  const onFileSelectChange = (file) => {
    setSelectedData((prevSelectedData) => {
      const isSelected = prevSelectedData.find(
        (fl) => fl.fileRef === file.fileRef
      );
      if (isSelected) {
        return prevSelectedData.filter((fl) => fl.fileRef !== file.fileRef);
      } else {
        return [...prevSelectedData, file];
      }
    });
  };

  let Block = ReactQuill.Quill.import("blots/block");

  Block.tagName = "div";
  ReactQuill.Quill.register(Block, true);

  let Embed = ReactQuill.Quill.import("blots/embed");
  class MxTable extends Embed {
    static create(paramValue) {
      let node = super.create();
      node.innerHTML = paramValue;
      return node;
    }

    static value(node) {
      return node.innerHTML;
    }
  }

  MxTable.blotName = "table";
  MxTable.tagName = "mx-table";
  ReactQuill.Quill.register(MxTable);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "750px",
        height: "100%",
        // position: "relative"
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") e.preventDefault();
      }}
    >
      {/* <div style={{ position: "absolute", right: "3%" }}>
        <Icon
          icon="minimize"
          color="#516BEB"
          size="18"
          isPressable
          onClick={() => {
            let data = {
              template_id: template_id,
              cc: cc,
              bcc: bcc,
              subject: subject,
              content: content,
              comment: comment,
              is_increment_follow_up: is_increment_follow_up,
              emailAttachment: emailAttachment,
              action: EMAIL_DRAWER,
              customerId: customerId,
              id: `${count}`,
              name: template_id && template_id[0] && template_id[0]?.name,
            };
            dispatch(minimizeSMSActionData(data));
            closeDrawer();
          }}
        />
      </div> */}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      > */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 20px 0px 0",
          flexDirection: "column",
        }}
      >
        <ActionHeader
          name={action === "PAYMENT" ? "Send Payment Receipt " : "Email"}
          icon="email"
        />
      </div>

      {/* </div> */}

      <AgentActionAlert />
      {!drawerData || !drawerData.data || !drawerData.data.isWorkflow ? (
        <Controller
          name="template_id"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              size="mini"
              {...field}
              name={field.name}
              requiredAstric={true}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "template_id",
              })}
              placeholder={intl.formatMessage({
                id: "template_id",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                if (entity === "invoices") {
                  dispatch(initialQAReducer());
                } else {
                  dispatch(typeToInitial());
                }
              }}
              options={templateOptions}
            />
          )}
        />
      ) : (
        <></>
      )}
      <Controller
        name="recipients"
        control={control}
        rules={{ required: "Required" }}
        render={({ field }) => (
          <SelectBox
            size="mini"
            {...field}
            name={field.name}
            requiredAstric={true}
            noResultMessage={`${"Email ID is not available for the consumer contact."}${" Use the Edit / Add option at consumer contact to capture Email ID"}`}
            error={errors[field.name] && errors[field.name].message}
            label={intl.formatMessage({
              id: "to",
            })}
            placeholder={intl.formatMessage({
              id: "to",
            })}
            value={field.value}
            multi
            onChange={(e) => {
              field.onChange(e.value);
            }}
            options={contactOptions}
            getOptionLabel={({ option }) => (
              <div
                className={`${
                  option?.comment ? "option-label-style-overwrite" : ""
                } `}
              >
                {option.label}
              </div>
            )}
          />
        )}
      />

      <Controller
        name="cc"
        control={control}
        render={({ field }) => (
          <>
            <TextBoxTag
              {...field}
              name={field.name}
              size="mini"
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "cc1",
              })}
              placeholder={intl.formatMessage({
                id: "cc",
              })}
              value={field.value}
              validation={true}
              caption={`${`${`Business Email `}${
                currentDefaultFormatDetails?.email?.value
              }${" will be kept in CC. "}${`You can review the Business Email in Organization Settings - Contact Details`}`}`}
              validationFunc={(value) => {
                let rg = new RegExp(
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                );
                return rg.test(value);
              }}
              setError={() =>
                setError(field.name, {
                  type: String,
                  message: "Invalid Email ID",
                })
              }
              clearErrors={() => {
                clearErrors(field.name, {
                  message: "",
                });
              }}
            />
          </>
        )}
      />

      <Controller
        defaultValues={[]}
        name="bcc"
        control={control}
        render={({ field }) => (
          <>
            <TextBoxTag
              // disabled={disableContent}
              {...field}
              name={field.name}
              size="mini"
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "bcc1",
              })}
              placeholder={intl.formatMessage({
                id: "bcc",
              })}
              value={field.value}
              validation={true}
              validationFunc={(value) => {
                let rg = new RegExp(
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                );
                return rg.test(value);
              }}
              setError={() =>
                setError(field.name, {
                  type: String,
                  message: "Email is Not Valid",
                })
              }
              clearErrors={() => {
                clearErrors(field.name, {
                  message: "",
                });
              }}
            />
          </>
        )}
      />

      <Controller
        name="subject"
        control={control}
        rules={{ required: "Required" }}
        render={({ field }) => (
          <TextBox
            {...field}
            name={field.name}
            size="mini"
            error={errors[field.name] && errors[field.name].message}
            label={intl.formatMessage({
              id: "subject",
            })}
            placeholder={intl.formatMessage({
              id: "subject",
            })}
            value={field.value}
          />
        )}
      />

      <div
        className="cp-quill cp-quill--action-view"
        style={{ marginBottom: "10px" }}
      >
        <Controller
          name="content"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => {
            return (
              <RichTextEditorEmail
                {...field}
                name={field.name}
                editorRef={editorRef}
                textToInsert={agentInstru}
                setTextToInsert={setInstruc}
                isHtml={true}
                isDisabled={
                  space && !space?.role_permissions?.includes(EMAIL_EDIT)
                }
              />
            );
          }}
        />
      </div>

      <StatefulPopover
        placement={PLACEMENT.top}
        content={(fDProps) => (
          <div className="cp_email_attachement">
            {/* [{filename:"From Desktop",}] */}
            <Typography
              $style={{}}
              onClick={() => {
                open();
                fDProps.close();
              }}
            >
              From Desktop
            </Typography>
            <StatefulPopover
              overrides={{
                Body: {
                  style: ({ $theme }) => ({
                    borderRadius: "5px",
                    overflow: "hidden",
                    width: "351px",
                    background: "#ffffff",
                  }),
                },
                Inner: {
                  style: ({ $theme }) => ({
                    backgroundColor: "#ffffff",
                  }),
                },
              }}
              placement={PLACEMENT.right}
              content={({ close }) => (
                <div style={{ padding: "10px" }}>
                  <TextBox
                    size="mini"
                    label={intl.formatMessage({
                      id: "search",
                    })}
                    placeholder={intl.formatMessage({
                      id: "search",
                    })}
                    value={isSearch}
                    onChange={(e) => {
                      setIsSearch(e.target.value);
                    }}
                  />
                  <div style={{ height: "200px", overflow: "scroll" }}>
                    {isDriveData.map((file, i) => {
                      let isSelected = selectedData.find((fl) => {
                        return fl.fileRef === file._id;
                      });
                      return (
                        <SelectDoc
                          res={file}
                          // deleteMethod={deleteDocumentData}
                          resourceId={customerId}
                          isSelected={isSelected ? true : false}
                          onFileSelectChange={onFileSelectChange}
                          entity={entity}
                          // need to change
                          // type={type}
                        />
                      );
                    })}
                  </div>
                  <div className="co-drawer-actions">
                    <TextButton
                      kind="tertiary"
                      size="mini"
                      type="button"
                      onClick={() => {
                        close();
                        setSelectedData([]);
                      }}
                    >
                      Cancel
                    </TextButton>
                    <TextButton
                      size="mini"
                      type="button"
                      onClick={() => {
                        dispatch(documentDrive(selectedData));
                        close();
                        fDProps.close();
                        setSelectedData([]);
                      }}
                    >
                      Add
                    </TextButton>
                  </div>
                </div>
              )}
              returnFocus
              autoFocus
            >
              <Typography>Consumer Drive</Typography>
            </StatefulPopover>
          </div>
        )}
        returnFocus
        autoFocus
      >
        <div style={{ width: "200px", marginBottom: "10px" }}>
          <TextButton type="button" size="mini" kind={KIND.secondary}>
            Add Attachments
          </TextButton>
        </div>
      </StatefulPopover>

      <div style={{ marginBottom: "10px" }}>
        {emailAttachment?.map((attachment, index) => {
          let { tempId } = attachment;
          return (
            <EmailAttachment
              key={tempId}
              {...attachment}
              id={id}
              currentOrganization={currentOrganization}
              entity={entity}
              removeAttchFile={removeAttchFile}
              updatingDeskUpload={updatingDeskUpload}
              recipientId={recipients && recipients[0] && recipients[0].id}
              templateId={template_id && template_id[0] && template_id[0].id}
              paymentId={
                drawerData && drawerData.data && drawerData.data.payment_id
              }
            />
          );
        })}
        {emailState &&
          emailState?.data?.emailAttachment?.map((attachment, index) => {
            let { tempId } = attachment;
            return (
              <EmailAttachment
                key={tempId}
                {...attachment}
                id={id}
                currentOrganization={currentOrganization}
                entity={entity}
                removeAttchFile={removeAttchFile}
                updatingDeskUpload={updatingDeskUpload}
                recipientId={recipients && recipients[0] && recipients[0].id}
                templateId={template_id && template_id[0] && template_id[0].id}
                paymentId={
                  drawerData && drawerData.data && drawerData.data.payment_id
                }
              />
            );
          })}
      </div>

      <ActionReminders
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType="SMS"
        outcome="SMS"
        is_calendar={false}
        is_confirmation={false}
        is_consent_enabled={false}
        is_followup={false}
        is_hold={false}
        is_incrementor={action === "PAYMENT" ? false : true}
        is_status={false}
      />

      <div className="co-drawer-actions">
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          onClick={() => {
            closeDrawer();
          }}
          disabled={executeActionMutation.isLoading}
        >
          Close
        </TextButton>
        <TextButton
          size="mini"
          disabled={executeActionMutation.isLoading}
          isLoading={executeActionMutation.isLoading}
        >
          Send
        </TextButton>
      </div>
    </form>
  );
};

export default EmailAction;
