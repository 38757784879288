import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { FormattedNumber, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useMutation, useQuery } from "react-query";

import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { KIND, TextButton } from "../../../../components/TextButton";
import queryClient from "../../../../providers/queryClient";
import {
  CUSTOMER_OVERVIEW_,
  ACT_PROMISE_TO_PAY,
  CO_INVOICES_,
  FIELD_VISIT,
} from "../../../../constants";
import getSelectValues from "../../../../utils/getSelectValues";
import ActionReminders from "../../../../components_v2/ActionReminders";
import getActionReminder from "../../../../utils_v2/getActionReminders";
import { Icon, Typography } from "../../../../components_v2";
import PromiseStatusTypes from "../PromiseStatusTypes";
import useFormat from "../../../../hooks/useFormat";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import { getHolidayBlackoutHolidayDate } from "../../../../services";
import { useSelector } from "react-redux";
import setSelectValues from "../../../../utils/setSelectValues";

const PromiseToPayForm = (props) => {
  const {
    isInvoice,
    formValues = {},
    service,
    onSuccess = () => {},
    onCancel = () => {},
    isIncrementor = true,
    selectedInvoices = {},
    totalAmount = 0,
    isContent = true,
    is_inbound_call = false,
    isStatus = false,
  } = props;
  const { customerId } = useParams();
  const intl = useIntl();

  let { referenceData, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      promise_type: [{ id: "full_amount" }],
    },
  });

  const [promiseType, promiseDate, isHold, promise_amount] = watch([
    "promise_type",
    "promise_date",
    "is_hold_workflow",
    "promise_amount",
  ]);

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: {
          date: [
            promiseDate
              ? moment(promiseDate)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf()
              : null,
          ],
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: customerId,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: promiseDate ? true : false,
    }
  );

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  let { validationField } = useSelector((e) => e.customerOverviewDrawer);

  const { currencies, outstanding_amount } = useMemo(() => {
    const currencies = _.get(
      customerData,
      "data.doc.invoice_currencies",
      []
    ).map((e) => ({ id: e, label: e }));
    const outstanding_amount = _.get(
      customerData,
      "data.doc.total_outstanding_invoice_amount.value",
      0
    );

    setValue("promise_currency", currencies[0] ? [currencies[0]] : []);
    setValue("promise_amount", outstanding_amount);

    return { currencies, outstanding_amount };
  }, [customerData?.data]);

  useEffect(() => {
    if (totalAmount > 0) {
      setValue("promise_amount", totalAmount);
    } else {
      setValue("promise_amount", outstanding_amount);
    }
  }, [promiseType, totalAmount]);

  useEffect(() => {
    if (
      _.get(validationField, "minPayment") &&
      _.get(validationField, "minPayment") > Number(promise_amount)
    ) {
      setError("promise_amount", {
        message: `*Minimum Promise amount is ${_.get(
          customerData,
          "data.doc.default_currency",
          0
        )} ${_.get(validationField, "minPayment", 0)}`,
      });
    } else {
      clearErrors("promise_amount");
    }
  }, [promise_amount]);

  const serviceMutation = useMutation(
    (formData) =>
      service({
        ...formData,
        id: customerId,
        entity: "customer",
        organization: currentOrganization,
      }),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
    }
  );

  const onSubmit = async (data) => {
    const {
      promise_type,
      promise_date,
      promise_amount,
      promise_currency,
      comment,
    } = data;

    if (isInvoice.length < 1) {
      return;
    }

    if (
      promise_type &&
      promise_type[0] &&
      promise_type[0].id === "partial_amount" &&
      Number(promise_amount) > Number(totalAmount)
    ) {
      return setError("promise_amount", {
        type: "custom",
        message: "Amount greater than due amount",
      });
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      ...selectedInvoices,
      ...formValues,
      ...(isContent ? { content: "-" } : {}),

      comment,
      promise_type: getSelectValues(promise_type),
      promise_date: moment
        .tz(
          `${promise_date.getFullYear()}-${
            promise_date.getMonth() + 1
          }-${promise_date.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails.time_zone
        )
        .utc()
        .valueOf(),
      ...(promise_type && promise_type[0] && promise_type[0].id !== "no_amount"
        ? {
            promise_amount: {
              value: promise_amount,
              currency: getSelectValues(promise_currency),
            },
          }
        : {}),
      ...getActionReminder(data, {
        is_consent_enabled: props.isConsent,
        is_incrementor: props.isIncrementor,
      }),
      mention_users: uniqueMentionedUsers,
    };

    await serviceMutation.mutateAsync(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: "flex", gap: "5px", marginTop: "20px" }}>
        <Controller
          name="promise_type"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              clearable={false}
              size={SIZE.mini}
              label={intl.formatMessage({
                id: "promise_type",
              })}
              placeholder={intl.formatMessage({
                id: "promise_type",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={referenceData["promise_type"] || []}
            />
          )}
        />

        {promiseType && promiseType[0] && promiseType[0].id !== "no_amount" && (
          <>
            <div>
              <Controller
                name="promise_currency"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    clearable={false}
                    size={SIZE.mini}
                    label={intl.formatMessage({
                      id: "cur",
                    })}
                    placeholder={intl.formatMessage({
                      id: "cur",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={currencies}
                    requiredAstric={true}
                  />
                )}
              />
            </div>

            <Controller
              name="promise_amount"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <TextBox
                  {...field}
                  hidden={true}
                  type="number"
                  name={field.name}
                  size={SIZE.mini}
                  requiredAstric={true}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "promise_amount",
                  })}
                  placeholder={
                    promiseType &&
                    promiseType[0] &&
                    promiseType[0].id === "full_amount"
                      ? ""
                      : intl.formatMessage({
                          id: "promise_amount",
                        })
                  }
                  value={field.value}
                  disabled={
                    promiseType &&
                    promiseType[0] &&
                    promiseType[0].id === "full_amount"
                      ? true
                      : false
                  }
                />
              )}
            />
          </>
        )}

        <Controller
          name="promise_date"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <DateRangeSelect
              {...field}
              name={field.name}
              size={SIZE.mini}
              error={errors[field.name] && errors[field.name].message}
              requiredAstric={true}
              label={intl.formatMessage({
                id: "promise_date",
              })}
              placeholder={intl.formatMessage({
                id: `${currentDefaultFormatDetails.date_format}`,
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.date);
              }}
              minDate={new Date()}
            />
          )}
        />
      </div>
      {_.get(data, "data.doc.is_holiday") && (
        <>
          <div className="alert-holiday-section">
            <Icon icon="alert_circle" color="#516BEB" />
            <Typography
              type="p"
              style={{
                fontWeight: "400",
              }}
            >
              The Date Selected{" "}
              {_.get(data, "data.doc.holidays", []).map((val) => {
                return (
                  <>
                    {moment(val).format(
                      currentDefaultFormatDetails.date_format
                    )}
                  </>
                );
              })}{" "}
              is a <b>“Holiday”</b>
            </Typography>
          </div>
        </>
      )}

      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType={formValues.action_type}
        is_inbound_call={
          formValues.is_inbound_call ? true : false || is_inbound_call
        }
        outcome={ACT_PROMISE_TO_PAY}
        consentDate={promiseDate}
        callValidationDuration={_.get(
          customerData,
          "data.doc.mini_miranda.call_validation_duration"
        )}
        timeZone={_.get(customerData, "data.doc.timezone")}
        is_incrementor={props.isIncrementor}
        is_consent_enabled={props.isConsent}
        isHold={isHold}
        is_valid_from_contact={
          formValues.action_type === "FIELD_VISIT" ||
          formValues.action_type === "ACTION_CAPTURE"
            ? true
            : false
        }
        is_status={isStatus}
      />

      <div
        style={{
          display: "flex",
          marginTop: "10px",
          gap: "10px",
          justifyContent: "flex-end",
        }}
      >
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          disabled={serviceMutation.isLoading}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </TextButton>
        <TextButton
          size="mini"
          disabled={serviceMutation.isLoading}
          isLoading={serviceMutation.isLoading}
        >
          Save
        </TextButton>
      </div>
    </form>
  );
};

const PromiseToPay = (props) => {
  const { customerId } = useParams();
  const [isDataPresent, setIsDataPresent] = useState(false);
  const [text, setAlertPromise] = useState(false);
  const [isInvoice, setIsInvoice] = useState([]);
  const [currentInvoice, setCurrentInvoice] = useState([]);
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);

  const [isPromiseExist, setIsPromiseExist] = useState(false);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  const format = useFormat();
  const invoicesData = queryClient.getQueryData([
    `${CO_INVOICES_}${customerId}`,
    { filters: {}, gid: _.get(customerData, "data.doc.gid", "") },
  ]);
  const consumerGroupPromiseText = _.get(invoicesData, "data.docs", []).some(
    (obj) => obj.hasOwnProperty("pgid")
  );
  const invOption = useMemo(() => {
    let findpgid;

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      customerData.data.doc.pgid
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
      );
    }

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      !customerData.data.doc.pgid &&
      customerData.data.doc._id
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ customer_id }) => customer_id === _.get(customerData, "data.doc._id")
      );
    }

    let invIds = findpgid
      .filter((fl) => fl.status !== "paid" && fl.status !== "stl")
      .map(({ _id, not_paid_amount, ...e }) => ({
        id: _id,
        outstanding: not_paid_amount,
        label: `${format.rd({
          id: _.get(e, "business_unit", ""),
          name: "business_unit_list",
        })}${_.get(e, "business_unit", "") ? " / " : ""}${_.get(
          e,
          "invoice_number",
          ""
        )}${_.get(e, "invoice_number", "") ? " / " : ""}
      ${_.get(e, "client_reference", "")}${
          _.get(e, "client_reference", "") ? " / " : ""
        }
      ${moment
        .utc(e.invoice_date)
        .tz(currentDefaultFormatDetails.time_zone)
        .format(currentDefaultFormatDetails.date_format)}${
          _.get(not_paid_amount, "currency", "") ? " / " : ""
        }${_.get(not_paid_amount, "currency", "")}${
          _.get(not_paid_amount, "value", "") ? " " : ""
        }${_.get(not_paid_amount, "value", "")}`,
      }));
    setIsInvoice([...invIds]);
    setCurrentInvoice([...invIds]);
    return invIds;
  }, [invoicesData]);

  // const invoices = useMemo(() => {
  //   let findpgid = _.get(invoicesData, "data.docs", []).filter(
  //     ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
  //   );

  //   let invIds = findpgid.map(({ _id, not_paid_amount }) => ({
  //     id: _id,
  //     outstanding: not_paid_amount,
  //   }));
  //   setIsInvoice([...invIds]);
  //   return _.get(invoicesData, "data.docs", []).map((e) => ({
  //     id: e._id,
  //     outstanding: e.not_paid_amount,
  //     label: `${format.rd({
  //       id: e?.business_unit,
  //       name: "business_unit_list",
  //     })} / ${e.client_reference ? e.client_reference : ""} / ${
  //       e.invoice_number
  //     } / ${moment
  //       .utc(e.invoice_due_date)
  //       .tz(currentDefaultFormatDetails.time_zone)
  //       .format(currentDefaultFormatDetails.date_format)} / ${
  //       e.not_paid_amount && e.not_paid_amount.currency
  //     } ${e.not_paid_amount && e.not_paid_amount.value}`,
  //   }));
  // }, [invoicesData]);

  const promiseStatus = useMemo(() => {
    let matchingIds = [];
    for (
      let i = 0;
      i < _.get(customerData, "data.doc.promise_to_pay", []).length;
      i++
    ) {
      let invArray =
        customerData && customerData?.data?.doc?.promise_to_pay?.[i];
      for (let j = 0; j < invArray?.inv?.length; j++) {
        if (isInvoice.some((item) => item.id === invArray?.inv?.[j])) {
          if (invArray && invArray.status === "IN_PROGRESS") {
            matchingIds.push(invArray);
          }
        }
      }
    }
    return matchingIds;
  }, [isInvoice]);

  useEffect(() => {
    if (promiseStatus.length > 0) {
      setIsPromiseExist(true);
    }
  }, [isInvoice]);

  return (
    <div style={{ marginTop: "15px" }}>
      <div style={{ width: "350px" }}>
        <SelectBox
          requiredAstric={true}
          value={isInvoice}
          getValueLabel={({ option }) => {
            const { label } = option;

            const value = label ? label.split("/") : [];
            return <>{value && value[1] ? value[1] : value[0]}</>;
          }}
          onChange={(e) => {
            setIsInvoice(e.value);
            setAlertPromise(e.value.length < currentInvoice.length);
          }}
          multi
          options={invOption}
          // error={""}
          size={"mini"}
        />
        {isInvoice.length < 1 && (
          <span style={{ color: "red" }}>Invioces is required</span>
        )}
      </div>
      {!isPromiseExist &&
        text &&
        _.get(customerData, "data.doc.gid") &&
        consumerGroupPromiseText && (
          <Typography
            className="co_action_exist"
            style={{ textAlign: "start" }}
          >
            * New Group will be created for the selected records.
          </Typography>
        )}
      {isPromiseExist ? (
        <PromiseStatusTypes
          groupedPromise={_.get(customerData, "data.doc.gid")}
          consumerGroupPromiseText={consumerGroupPromiseText}
          setAlertPromise={text}
          setIsPromiseExist={setIsPromiseExist}
          type={_.get(promiseStatus, "[0].status", "")}
          promiseStatus={_.get(promiseStatus, "[0]", {})}
        />
      ) : (
        <PromiseToPayForm
          isInvoice={isInvoice}
          selectedInvoices={{ invoices: isInvoice?.map(({ id }) => id) }}
          totalAmount={isInvoice
            .reduce((sum, item) => +sum + +item?.outstanding?.value, 0)
            .toFixed(2)}
          {...props}
        />
      )}
    </div>
  );
};

export default PromiseToPay;
