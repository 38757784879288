import React from "react";

const NextStartSvg = ({ size = "24", color = "#5f6368" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      fill={color}
    >
      <path d="M340-236.16v-487.68L723.07-480 340-236.16Z" />
    </svg>
  );
};

export default NextStartSvg;
