import { HeadingXSmall, LabelSmall, ParagraphMedium } from "baseui/typography";
import moment from "moment";
import React, { useContext, useState } from "react";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { utcTimstampToLocalDate } from "../../utils/utcTimstampToLocalDate";
import { Icon, RDText, Typography } from "../../../src/components_v2";
import DocFileType from "../../assets/img/svg/DocFileType";
import { useDispatch, useSelector } from "react-redux";
import { EMAIL } from "../../constants";
import { editDocumentForm } from "../../redux/customerOverview/DMSystem/action";
import { ModalContext } from "../../providers/ModalProvider";
import Warning from "../../assets/img/warning.png";
import { editInvoiceDocumentForm } from "../../redux/invoiceOverview/DMSystemINV/action";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  EDIT_DOCUMENTS,
  DELETE_DOCUMENTS,
  VIEW_DOWNLOAD_DOCUMENTS,
} from "../../providers/RBACProvider/permissionList";
import { FormattedTime } from "react-intl";
import { downloadDocumentData } from "../../services/documentSystem";

const DocumentType = ({ type }) => {
  switch (type) {
    case "pdf":
      return (
        <div className="file_wraper_icon">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );
    case "vnd.ms-excel":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            Excel
          </p>
        </div>
      );
    case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            Excel
          </p>
        </div>
      );
    case "jpg":
    case "jpeg":
    case "png":
    case "bmp":
      return (
        <div className="file_wraper_icon --doc_purple">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "csv":
    case "xls":
    case "xlsx":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "doc":
    case "docx":
      return (
        <div className="file_wraper_icon --doc_blue">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "txt":
    case "plain":
      return (
        <div className="file_wraper_icon --doc_grey">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingLeft: "2px" }}
          >
            {type === "plain" ? "TXT" : type.toUpperCase()}
            {/* {type.toUpperCase()} */}
          </p>
        </div>
      );

    default:
      return (
        <div className="file_wraper_icon --doc_yellow">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type?.length >= 4 && "--doc_font_size"
            }`}
          >
            {type?.toUpperCase()}
          </p>
        </div>
      );
  }
};

const DocumentCard = (props) => {
  let { res, resourceId, deleteMethod, type, setOpenDoc } = props;

  const { handleModal } = useContext(ModalContext);

  const [isExpand, setIsExpand] = useState(false);

  let dispatch = useDispatch();

  function epandCard() {
    setIsExpand(!isExpand);
  }

  let splitType = res && res.mime_type && res.mime_type.split("/");

  let {
    currentDefaultFormatDetails,
    users,
    referenceData,
    currentOrganization,
  } = useContext(MaxyfiContext);

  const legalFields = referenceData["legal_action_fields"].find(
    ({ id }) => id === res.lty
  );

  let noteLocalDate =
    res.audit_info &&
    res.audit_info.audit_on &&
    utcTimstampToLocalDate(
      res.audit_info.audit_on,
      currentDefaultFormatDetails.date_format
    );

  let created =
    res &&
    res.created_at &&
    utcTimstampToLocalDate(
      res.created_at,
      currentDefaultFormatDetails.date_format
    );

  let noteUser = res.modified_by ? res.modified_by : res.created_by;

  let noteUserRef = users.find((e) => e.id === noteUser);

  return (
    <div
      className={
        isExpand ? "dms_card_container --dms_card_pt" : "dms_card_container"
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ paddingLeft: "10px" }}>
            {res && res.tab === "timeline" ? (
              <></>
            ) : (
              <>
                {isExpand ? (
                  <Icon
                    icon="down_arrow"
                    size={18}
                    isPressable
                    onClick={() => epandCard()}
                  />
                ) : (
                  <Icon
                    icon="chevron_right"
                    size={18}
                    isPressable
                    onClick={() => epandCard()}
                  />
                )}
              </>
            )}
          </div>
          <div style={{ paddingLeft: "5px" }}>
            <DocumentType
              type={
                res.tab === "timeline"
                  ? res.mime_type
                  : splitType[1]
                  ? splitType[1]
                  : ""
              }
            />
          </div>
          <div
            style={{
              paddingLeft: "5px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "3px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Typography
                type="p"
                className="doc_title_els"
                title={res.file_name}
              >
                {res.file_name}
              </Typography>
              {res && res.lty === legalFields?.id ? (
                <>
                  <Typography
                    type="p"
                    style={{
                      color: "#787878",
                      fontWeight: "400",
                      lineHeight: "13.2px",
                      fontSize: "11px",
                      color: "#516BEB",
                      backgroundColor: res.lty ? "#F0F5FF" : "",
                      padding: "2.5px",
                      borderRadius: "5px",
                    }}
                  >
                    {res.lty ? legalFields?.label : ""}
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              <Typography
                type="p"
                style={{
                  color: "#787878",
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                {res?.category}
                {res && res.tab === "timeline" ? (
                  <></>
                ) : (
                  <>
                    {res?.category === "CUSTOMER" ? (
                      <></>
                    ) : (
                      <>/ {res?.invoice_number}</>
                    )}
                    / {res.sub_category}
                  </>
                )}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  {res.is_share_portal && !res.ldt && (
                    <Typography
                      type="p"
                      style={{
                        color: "#787878",
                        fontWeight: "400",
                        lineHeight: "13.2px",
                        fontSize: "11px",
                      }}
                    >
                      Download Available
                    </Typography>
                  )}
                  {res.ldt && (
                    <Typography
                      type="p"
                      style={{
                        color: "#787878",
                        fontWeight: "400",
                        lineHeight: "13.2px",
                        fontSize: "11px",
                      }}
                    >
                      Customer Downloaded on{" "}
                      {moment(res.ldt).format(
                        currentDefaultFormatDetails.date_format
                      )}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingRight: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <RBACWrapper role={VIEW_DOWNLOAD_DOCUMENTS} type={"PERMISSION"}>
              <Icon
                icon="download_outline"
                color="#adadad"
                size={18}
                isPressable
                onClick={() => {
                  downloadDocumentData({
                    documentId: res._id,
                    customerId: resourceId,
                    organization: currentOrganization,
                    fileName: res.file_name,
                    type: res.mime_type,
                  }).then(() => {});
                }}
              />
            </RBACWrapper>
            <RBACWrapper role={EDIT_DOCUMENTS} type={"PERMISSION"}>
              <Icon
                icon="edit_outline"
                color="#adadad"
                size={18}
                isPressable
                onClick={() => {
                  setOpenDoc(true);
                  dispatch(editDocumentForm(res));
                }}
              />
            </RBACWrapper>
            <RBACWrapper role={DELETE_DOCUMENTS} type={"PERMISSION"}>
              <Icon
                icon="delete_outline"
                color="#adadad"
                size={18}
                isPressable
                onClick={() => {
                  handleModal({
                    title: "",
                    content: "Are you sure, you want to delete this Document?",
                    successCallback: () => {
                      deleteMethod.mutateAsync({
                        documentId: res._id,
                        customerId: resourceId,
                      });
                    },
                    formContent: () => {
                      return <></>;
                    },
                    cancelCallback: () => {},
                    isChildren: false,
                    image: Warning,
                    successButtonType: "error",
                    buttonText: "Delete",
                    closeButtonText: "Cancel",
                    isCloseAble: false,
                  });
                }}
              />
            </RBACWrapper>
          </div>
          <div>
            <div>
              <LabelSmall
                $style={{
                  color: "#ADADAD",
                  lineHeight: "13.2px",
                  fontWeight: "400",
                  textAlign: "right",
                  fontSize: "11px",
                  marginTop: "5px",
                }}
              >
                {" "}
                Upload on: <br />
                {moment(created).format(
                  currentDefaultFormatDetails.date_format
                )}{" "}
                <FormattedTime value={created} />
              </LabelSmall>
            </div>
          </div>
        </div>
      </div>

      <div className="dms_card_doc_type_content">
        <div>
          {
            // need to change
            // drawerState
            type == EMAIL ? (
              <></>
            ) : (
              <>{res && res.tab === "timeline" ? <></> : <></>}</>
            )
          }
        </div>
        <div style={{ marginLeft: "10px" }}>{isExpand ? <></> : <></>}</div>
      </div>

      {isExpand && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "10px",
              borderTop: "1px solid #c7c7c7",
              marginTop: "10px",
              marginRight: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "8px",
              }}
            >
              <div style={{ paddingTop: "2px" }}>{/* <InvoiceUser /> */}</div>
              <div
                style={{
                  paddingLeft: "10px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <Icon icon="edit_outline" color="#adadad" size={16} /> {"  "}
                  <ParagraphMedium
                    $style={{
                      color: "#ADADAD",
                      paddingRight: "5px",
                      lineHeight: "14.4px",
                    }}
                  >
                    {" "}
                    {"Last updated :"}
                  </ParagraphMedium>
                  <ParagraphMedium
                    $style={{
                      color: "#ADADAD",
                      paddingRight: "5px",
                      lineHeight: "16px",
                    }}
                  >
                    {noteUserRef && noteUserRef.displayName
                      ? noteUserRef.displayName
                      : "Portal"}
                    {","}
                  </ParagraphMedium>
                  <HeadingXSmall
                    $style={{
                      color: "#ADADAD",
                      lineHeight: "14.4px",
                      fontWeight: "400",
                      paddingRight: "5px",
                    }}
                  >
                    {moment(noteLocalDate).format(
                      currentDefaultFormatDetails.date_format
                    )}
                    {","}
                  </HeadingXSmall>
                  <div
                    style={{
                      color: "#ADADAD",
                      lineHeight: "14.4px",
                      paddingRight: "5px",
                      fontWeight: "400",
                    }}
                  >
                    <FormattedTime value={created} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingLeft: "20px" }}>
            <ParagraphMedium>
              {res && res.remarks
                ? res.remarks.replace(/(<([^>]+)>)/gi, "").replace("&amp;", "")
                : ""}
            </ParagraphMedium>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentCard;
