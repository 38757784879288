import { useMemo } from "react";
import _ from "lodash";
export const dataType = {
  AMOUNT: "CURRENCY",
  DATE: "DATE",
  HYPERLINK: "HYPERLINK",
  TEXT: "ALPHANUMERIC",
  LONG_TEXT: "ALPHANUMERIC",
  NUMBER: "ALPHANUMERIC",
  STRING: "ALPHANUMERIC",
};

export const CreateGridStructureNes = (
  customInvoiceFields,
  literalAddedData,
  isEnabledData,
  flattenArrays
) => {
  const transformCustomInvoiceFields = (customInvoiceFields, isEnabledData) => {
    const compareInvoiceAllData = isEnabledData
      ? isEnabledData.map((item) => ({
          cid: item?.cid,
          nes: item?.nes?.cid,
        }))
      : [];

    const comparedDataCustomFields = customInvoiceFields
      ? customInvoiceFields.filter((item) =>
          compareInvoiceAllData.find(
            (data) => data.cid === item.path || data?.nes?.cid === item.path
          )
        )
      : [];

    const transformedFields = comparedDataCustomFields.map((field) => {
      // Check if the current field is a nested field in `compareInvoiceAllData`
      const isNestedField = compareInvoiceAllData.find(
        (data) => data.cid === field.path && data.nes
      );

      const nesField = isNestedField
        ? customInvoiceFields.find(
            (nestedField) => nestedField.path === isNestedField.nes
          ) ||
          flattenArrays.find(
            (nestedField) => nestedField.accessor === isNestedField.nes
          )
        : null;

      // Return the transformed field with `nes` if `isNestedField` is found
      return {
        ...field,
        ...(isNestedField ? { nes: nesField } : {}),
      };
    });

    return transformedFields;
  };

  const result = transformCustomInvoiceFields(
    customInvoiceFields,
    isEnabledData
  );
  return result.map((field, idx) => {
    const { path, name, data_type, nes } = field;
    const literalIdPath = literalAddedData[path] || name;
    const literalAddedDatassss = isEnabledData.reduce((acc, item) => {
      if (item?.nes?.cid) {
        return {
          ...acc,
          [item.nes.cid]: item?.nes?.ltr,
        };
      }
      acc[item.cid] = item?.ltr;
      return acc;
    }, {});
    const literalId = path
      ? `${literalIdPath}${
          _.get(nes, "path", "")
            ? `${literalAddedDatassss[_.get(nes, "path", "")] ? "/" : ""} ${
                literalAddedDatassss[_.get(nes, "path", "")]
                  ? literalAddedDatassss[_.get(nes, "path", "")]
                  : ""
              }`
            : _.get(nes, "accessor", "")
            ? `${literalAddedDatassss[_.get(nes, "accessor", "")] ? "/" : ""} ${
                literalAddedDatassss[_.get(nes, "accessor", "")]
                  ? literalAddedDatassss[_.get(nes, "accessor", "")]
                  : ""
              }`
            : ""
        }`
      : name;
    return {
      accessor: `custom_field.${path}`,
      alignment:
        dataType[data_type] === "CURRENCY"
          ? "right"
          : dataType[data_type] === "DATE"
          ? "center"
          : "left",
      cell_type: dataType[data_type] || "ALPHANUMERIC",
      col_id: `col_cf_${idx}`,
      filter_by: false,
      header_alignment: "center",
      is_hidden: false,
      literal_id: literalId,
      order: 0,
      sort_by: false,
      type: "STRING",
      width: 150,
      ...(nes?.path && !nes?.accessor
        ? {
            nested: [
              {
                accessor: `custom_field.${path}`,
                alignment:
                  dataType[data_type] === "CURRENCY"
                    ? "right"
                    : dataType[data_type] === "DATE"
                    ? "center"
                    : "left",
                cell_type: dataType[data_type] || "ALPHANUMERIC",
                col_id: `col_cf_${idx}`,
                filter_by: false,
                header_alignment: "center",
                literal_id: literalId,

                type: "STRING",
              },
              {
                accessor: `custom_field.${_.get(nes, "path", "")}`,
                alignment:
                  dataType[data_type] === "CURRENCY"
                    ? "right"
                    : dataType[data_type] === "DATE"
                    ? "center"
                    : "left",
                cell_type:
                  dataType[_.get(nes, "data_type", "")] || "ALPHANUMERIC",
                col_id: `col_cf_${idx + 1}`,
                filter_by: false,
                header_alignment: "center",
                literal_id: literalId,

                type: "STRING",
              },
            ],
          }
        : nes?.accessor
        ? {
            nested: [
              {
                accessor: `custom_field.${path}`,
                alignment:
                  dataType[data_type] === "CURRENCY"
                    ? "right"
                    : dataType[data_type] === "DATE"
                    ? "center"
                    : "left",
                cell_type: dataType[data_type] || "ALPHANUMERIC",
                col_id: `col_cf_${idx}`,
                filter_by: false,
                header_alignment: "center",
                literal_id: literalId,

                type: "STRING",
              },
              {
                ...nes,
                alignment:
                  dataType[data_type] === "CURRENCY"
                    ? "right"
                    : dataType[data_type] === "DATE"
                    ? "center"
                    : "left",
              },
            ],
          }
        : {}),
    };
  });
};
// import { useMemo } from "react";
// import _ from "lodash";

// export const dataType = {
//   AMOUNT: "CURRENCY",
//   DATE: "DATE",
//   HYPERLINK: "HYPERLINK",
//   TEXT: "ALPHANUMERIC",
//   LONG_TEXT: "ALPHANUMERIC",
//   NUMBER: "ALPHANUMERIC",
//   STRING: "ALPHANUMERIC",
// };

// export const CreateGridStructureNes = (
//   customInvoiceFields,
//   literalAddedData,
//   isEnabledData,
//   flattenArrays
// ) => {
//   // Transform and filter the customInvoiceFields
//   const transformCustomInvoiceFields = (fields, enabledData) => {
//     if (!fields || !enabledData) return [];

//     const compareInvoiceAllData = enabledData.map((item) => ({
//       cid: item?.cid,
//       nes: item?.nes?.cid,
//     }));

//     const comparedFields = fields.filter((field) =>
//       compareInvoiceAllData.some(
//         (data) => data.cid === field.path || data?.nes === field.path
//       )
//     );

//     return comparedFields.map((field) => {
//       const isNestedField = compareInvoiceAllData.find(
//         (data) => data.cid === field.path && data.nes
//       );

//       const nesField = isNestedField
//         ? fields.find(
//             (nestedField) => nestedField.path === isNestedField.nes
//           ) ||
//           flattenArrays.find(
//             (nestedField) => nestedField.accessor === isNestedField.nes
//           )
//         : null;

//       return { ...field, ...(isNestedField ? { nes: nesField } : {}) };
//     });
//   };

//   // Generate literal added data map
//   const generateLiteralAddedData = (enabledData) =>
//     enabledData.reduce((acc, item) => {
//       if (item?.nes?.cid) acc[item.nes.cid] = item?.nes?.ltr;
//       acc[item.cid] = item?.ltr;
//       return acc;
//     }, {});

//   const literalAddedMap = generateLiteralAddedData(isEnabledData);
//   const transformedFields = transformCustomInvoiceFields(
//     customInvoiceFields,
//     isEnabledData
//   );

//   // Generate the grid structure
//   return transformedFields.map((field, idx) => {
//     const { path, name, data_type, nes } = field;
//     const literalIdPath = literalAddedData[path] || name;
//     const nesPath = _.get(nes, "path", "");
//     const literalId = path
//       ? `${literalIdPath}${
//           nesPath && literalAddedMap[nesPath]
//             ? ` / ${literalAddedMap[nesPath]}`
//             : ""
//         }`
//       : name;

//     const baseField = {
//       accessor: `custom_field.${path}`,
//       alignment: "center",
//       cell_type: dataType[data_type] || "ALPHANUMERIC",
//       col_id: `col_cf_${idx}`,
//       filter_by: false,
//       header_alignment: "center",
//       literal_id: literalId,
//       order: 0,
//       sort_by: false,
//       type: "STRING",
//       width: 150,
//     };

//     if (nes?.path && !nes?.accessor) {
//       baseField.nested = [
//         baseField,
//         {
//           accessor: `custom_field.${nesPath}`,
//           alignment: "center",
//           cell_type: dataType[_.get(nes, "data_type", "")] || "ALPHANUMERIC",
//           col_id: `col_cf_${idx + 1}`,
//           filter_by: false,
//           header_alignment: "center",
//           literal_id: literalId,
//           type: "STRING",
//         },
//       ];
//     } else if (nes?.accessor) {
//       baseField.nested = [baseField, nes];
//     }

//     return baseField;
//   });
// };
