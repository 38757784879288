import React from "react";
import Layout from "../../layouts";
import RoleAccessSelect from "../../components/RoleAccessSelect/RoleAccessSelect";
import HeaderContiner from "../../containers_v2/WorklistContainer/HeaderContiner";
import WorklistContainer from "../../containers_v2/WorklistContainer/WorklistContainer";

const WorklistView = () => {
  return (
    <Layout>
      <div className="content-container --layout_relative">
        <div className="content-header">
          <HeaderContiner />
        </div>
        <div className="content-body">
          <WorklistContainer />
        </div>
      </div>
    </Layout>
  );
};

export default WorklistView;
