import React, { useState, useEffect, useRef } from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";

function DetailTextInvoice(props) {
  let { value, column, alignment, accessor, suffix, row, color, ...rest } =
    props;

  value = _.get(row.original, accessor, "");

  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const isEllipsized = ref.current.offsetHeight < ref.current.scrollHeight;
      setVisible(isEllipsized);
    }
  }, [column.width, value]); // Recalculate on width change

  return visible ? (
    <StatefulTooltip
      accessibilityType={"tooltip"}
      content={
        <div style={{ maxWidth: "250px", whiteSpace: "normal" }}>{value}</div>
      }
    >
      <Paragraph2
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "100%",
          textAlign: alignment,
          lineHeight: "18px",
          color: color || "#333860",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 4, // Limit to 4 lines
        }}
        ref={ref}
      >
        {value?.toString().length ? (
          <>
            {value} {suffix || ""}
          </>
        ) : (
          "-"
        )}
      </Paragraph2>
    </StatefulTooltip>
  ) : (
    <Paragraph2
      $style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        textAlign: alignment,
        lineHeight: "18px",
        color: color || "#333860",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 4,
      }}
      ref={ref}
    >
      {value?.toString().length || value === 0 ? (
        <>
          {value} {suffix || ""}
        </>
      ) : (
        "-"
      )}
    </Paragraph2>
  );
}

export default DetailTextInvoice;
