import React, { useContext, useState } from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _, { values } from "lodash";
import { removeElement } from "../../../utils/regexConfig";
import CheckBoxBaseweb from "../../CheckBoxBaseweb";

function EditCheckBoxStatus(props) {
  let { value, column, alignment, accessor, suffix, row, color, ...rest } =
    props;

  value = _.get(row.original, accessor, "");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: alignment,
        alignItems: alignment,
        textAlign: "center",
        flex: 1,
        // cursor: "none",
      }}
    >
      <CheckBoxBaseweb
        checked={value}
        opacityCustom={0.7}
        cursorTrue={"none"}
      ></CheckBoxBaseweb>
    </div>
  );
}
export default EditCheckBoxStatus;
