import { StatefulTooltip } from "baseui/tooltip";
import { Paragraph2, ParagraphSmall } from "baseui/typography";
import React, { useContext } from "react";

import _ from "lodash";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import {
  INVOICE_SUB_STATUS_DRAWER,
  setDrawerState,
} from "../../../redux/actions";
import { useDispatch } from "react-redux";

const InvoiceStatusDrawerCell = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  value = _.get(row.original, accessor, "");
  const dispatch = useDispatch();
  //
  let data;
  let maxyfiContextValue = useContext(MaxyfiContext);

  const { referenceData } = maxyfiContextValue;

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  let rdName = column && column?.rdName;

  if (props.rdName) {
    rdName = props.rdName;
  }

  let tootltipData = [];

  return (
    <>
      {value !== "" ? (
        <StatefulTooltip
          accessibilityType={"tooltip"}
          content={
            <div style={{ minWidth: "auto", maxWidth: "200px" }}>{value}</div>
          }
        >
          <ParagraphSmall
            $style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              textAlign: alignment,
              maxWidth: "100px",
              cursor: "pointer",
            }}
            onClick={() => {
              setDrawer(INVOICE_SUB_STATUS_DRAWER, {
                invoiceId: _.get(row, "original._id", ""),
                invoiceClientRef: _.get(row, "original.client_reference", ""),
                invoiceNumber: _.get(row, "original.invoice_number", ""),
              });
            }}
          >
            {value ? value : "-"}
          </ParagraphSmall>
        </StatefulTooltip>
      ) : (
        <ParagraphSmall
          $style={{ cursor: "pointer" }}
          onClick={() => {
            setDrawer(INVOICE_SUB_STATUS_DRAWER, {
              invoiceId: _.get(row, "original._id", ""),
              invoiceClientRef: _.get(row, "original.client_reference", ""),
              invoiceNumber: _.get(row, "original.invoice_number", ""),
            });
          }}
        >
          -
        </ParagraphSmall>
      )}
    </>
  );
};

export default InvoiceStatusDrawerCell;
