import React from "react";

const Battery = () => {
  return (
    //     <svg
    //       version="1.0"
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="27"
    //       height="17"
    //       viewBox="1 0 802 390"
    //       preserveAspectRatio="xMidYMid meet"
    //     >
    //       <g
    //         transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    //         fill="currentColor"
    //         stroke="none"
    //       >
    //         <path
    //           d="M395 3949 c-172 -25 -329 -165 -376 -334 -21 -74 -21 -2036 0 -2110
    // 50 -179 205 -310 396 -335 54 -7 722 -10 2085 -8 1998 3 2005 3 2061 24 128
    // 47 220 127 275 239 45 93 54 161 54 443 l0 232 28 0 c72 1 167 80 190 159 16
    // 53 16 549 0 602 -23 79 -118 158 -190 159 l-28 0 0 233 c0 281 -9 349 -54 442
    // -55 112 -147 192 -275 239 -56 21 -61 21 -2081 22 -1122 1 -2052 -2 -2085 -7z
    // m4120 -248 c51 -23 93 -65 116 -116 18 -38 19 -91 19 -1025 0 -933 -1 -987
    // -19 -1025 -10 -22 -33 -55 -51 -72 -70 -69 100 -64 -2155 -61 l-2040 3 -42 28
    // c-52 33 -88 86 -102 147 -14 62 -14 1898 0 1960 18 80 85 155 155 173 10 2
    // 932 5 2049 6 1949 1 2032 0 2070 -18z"
    //         />
    //         <path d="M470 2560 l0 -930 580 0 580 0 0 930 0 930 -580 0 -580 0 0 -930z" />
    //       </g>
    //     </svg>
    <svg
      width="25"
      height="13"
      viewBox="0 0 32 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.4"
        x="1.47058"
        y="0.5"
        width="24"
        height="12"
        rx="3.5"
        stroke="black"
      />
      <path
        opacity="0.5"
        d="M26.9706 4.5V8.5C27.7765 8.16122 28.3006 7.37313 28.3006 6.5C28.3006 5.62687 27.7765 4.83878 26.9706 4.5"
        fill="black"
      />
      <rect x="2.97058" y="2" width="17" height="9" rx="2" fill="black" />
    </svg>
  );
};
export default Battery;
