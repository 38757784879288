import { LabelSmall } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import DocFileType from "../../assets/img/svg/DocFileType";
import Cancel from "../../assets/img/svg/Template/Cancel";

export const DocumentType = ({ type }) => {
  switch (type) {
    case "pdf":
      return (
        <div className="file_wraper_icon">
          <DocFileType />
          <p
            className={`file_text_wraper--field  ${
              type?.length >= 4 && "--doc_font_size_field"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "jpg":
    case "jpeg":
    case "png":
    case "bmp":
      return (
        <div className="file_wraper_icon --doc_purple">
          <DocFileType />
          <p
            className={`file_text_wraper--field ${
              type?.length >= 4 && "--doc_font_size_field"
            }`}
          >
            {type?.toUpperCase()}
          </p>
        </div>
      );

    case "csv":
    case "xls":
    case "vnd.ms-excel":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType />
          <p
            className={`file_text_wraper--field ${
              type.length >= 4 && "--doc_font_size_field"
            }`}
          >
            Excel
          </p>
        </div>
      );
    case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType />
          <p
            className={`file_text_wraper--field ${
              type.length >= 4 && "--doc_font_size_field"
            }`}
          >
            Excel
          </p>
        </div>
      );
    case "xlsx":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType />
          <p
            className={`file_text_wraper--field ${
              type.length >= 4 && "--doc_font_size_field"
            }`}
          >
            {type?.toUpperCase()}
          </p>
        </div>
      );

    case "doc":
    case "docx":
      return (
        <div className="file_wraper_icon --doc_blue">
          <DocFileType />
          <p
            className={`file_text_wraper--field ${
              type.length >= 4 && "--doc_font_size_field"
            }`}
          >
            {type?.toUpperCase()}
          </p>
        </div>
      );

    case "txt":
    case "plain":
      return (
        <div className="file_wraper_icon --doc_grey">
          <DocFileType />
          <p
            className={`file_text_wraper--field ${
              type?.length >= 4 && "--doc_font_size_field"
            }`}
            style={{ paddingLeft: "2px" }}
          >
            {type === "plain" ? "TXT" : type.toUpperCase()}

            {/* {type?.toUpperCase()} */}
          </p>
        </div>
      );

    default:
      return (
        <div className="file_wraper_icon --doc_yellow">
          <DocFileType />
          <p
            className={`file_text_wraper--field ${
              type && type.length >= 4 && "--doc_font_size_field"
            }`}
          >
            {type?.toUpperCase()}
          </p>
        </div>
      );
  }
};
const fileSize = 10000000;
function uploadLogoValidation(file) {
  if (file.size > fileSize) {
    return {
      code: "file is too-large",
      message: `File Size is Largen than 1MB`,
    };
  }
}

export const FileUpload = ({ setFiles, files }) => {
  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      maxFiles: 1,
      accept: {
        "image/*": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      validator: uploadLogoValidation,
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  let splitType = files && files[0] && files[0].type.split("/");

  return (
    <>
      {files.length > 0 ? (
        <div className="file_upload_card">
          <div className="file_upload_icon_name">
            <DocumentType type={splitType[1]} />
            <LabelSmall $style={{ color: "#516beb" }}>
              {files && files[0] && files[0].path}
            </LabelSmall>
          </div>
          <div
            className="file_upload_close_btn"
            onClick={() => {
              setFiles([]);
            }}
          >
            <Cancel />
          </div>
        </div>
      ) : (
        <section className="container">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
        </section>
      )}
    </>
  );
};
