import React from "react";

const VoiceBotIcon = ({ color = "#FD372A", size = "22" }) => {
  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width={size}
      zoomAndPan="magnify"
      viewBox="0 0 810 809.999993"
      height={size}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <rect
        x="-81"
        width="972"
        fill="#ffffff"
        y="-80.999999"
        height="971.999992"
        fill-opacity="1"
      />
      <rect
        x="-81"
        width="972"
        fill="#ffffff"
        y="-80.999999"
        height="971.999992"
        fill-opacity="1"
      />
      <path
        fill={color}
        d="M 383.699219 127.675781 C 367.101562 143.785156 367.101562 170.144531 383.699219 186.742188 C 391.023438 194.0625 395.902344 204.3125 395.902344 213.101562 C 395.902344 227.257812 394.925781 227.746094 372.960938 227.746094 C 256.296875 227.746094 169.898438 296.570312 150.859375 403.960938 C 135.730469 489.382812 185.519531 580.664062 267.523438 619.714844 L 300.71875 635.335938 L 520.375 635.335938 L 553.570312 619.714844 C 636.0625 580.664062 685.851562 487.429688 670.234375 402.984375 C 650.21875 296.082031 564.308594 227.746094 448.621094 227.746094 C 426.167969 227.746094 425.191406 227.257812 425.191406 213.101562 C 425.191406 204.3125 430.070312 194.0625 437.394531 186.742188 C 462.777344 161.359375 445.691406 115.472656 410.546875 115.472656 C 401.273438 115.472656 391.023438 119.867188 383.699219 127.675781 Z M 514.03125 308.773438 C 565.773438 326.347656 602.382812 377.113281 602.382812 432.761719 C 602.871094 487.429688 577 529.410156 528.675781 553.328125 C 501.339844 566.996094 499.386719 566.996094 410.546875 566.996094 C 321.707031 566.996094 319.753906 566.996094 292.417969 553.328125 C 245.070312 529.898438 218.710938 486.941406 218.710938 432.761719 C 218.710938 378.089844 254.832031 326.835938 305.109375 308.773438 C 334.398438 298.523438 483.765625 298.035156 514.03125 308.773438 Z M 514.03125 308.773438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 280.71875 404.507812 C 254.847656 430.867188 266.5625 471.867188 302.683594 481.144531 C 330.507812 487.976562 359.304688 462.59375 359.304688 431.84375 C 359.304688 412.316406 334.898438 390.351562 312.933594 390.351562 C 299.753906 390.351562 291.457031 394.253906 280.71875 404.507812 Z M 280.71875 404.507812 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 475.988281 404.492188 C 460.371094 420.597656 457.929688 437.195312 469.15625 458.183594 C 483.3125 485.519531 519.433594 489.425781 542.863281 465.996094 C 569.710938 439.148438 547.257812 390.335938 508.207031 390.335938 C 495.027344 390.335938 486.726562 394.238281 475.988281 404.492188 Z M 475.988281 404.492188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 113.015625 198.496094 C 85.195312 233.152344 49.558594 303.441406 38.332031 346.398438 C 27.105469 388.867188 27.105469 478.195312 38.332031 524.566406 C 50.046875 571.914062 85.679688 642.207031 114.480469 673.933594 L 138.886719 700.292969 L 161.832031 677.351562 L 184.773438 654.898438 L 161.34375 623.65625 C 74.941406 509.433594 74.453125 362.996094 160.367188 249.261719 L 184.285156 218.019531 L 159.878906 194.101562 L 135.960938 169.695312 Z M 113.015625 198.496094 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 659.804688 195.113281 L 636.375 219.03125 L 654.433594 240.019531 C 679.328125 268.820312 709.59375 332.277344 718.378906 373.28125 C 736.929688 461.144531 715.941406 549.496094 659.804688 623.691406 L 636.375 654.933594 L 659.316406 677.386719 L 682.257812 700.328125 L 706.664062 673.96875 C 719.84375 659.328125 740.347656 627.109375 753.039062 602.214844 C 817.472656 472.371094 802.828125 324.957031 713.5 206.339844 C 701.296875 189.253906 689.09375 174.613281 687.140625 173.636719 C 685.1875 172.171875 672.984375 181.933594 659.804688 195.113281 Z M 659.804688 195.113281 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </svg>
  );
};

export default VoiceBotIcon;
