import React, { useContext } from "react";
import { Icon, Typography } from "../../../components_v2";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import {
  ITEMIZATION_ENHANCEMENT_DRAWER,
  setDrawerState,
} from "../../../redux/actions";
import { useDispatch } from "react-redux";
import _ from "lodash";
import useFormat from "../../../hooks/useFormat";
import { currencyList } from "../../../utils_v2/CurrencyToSymbol";
import moment from "moment/moment";

function InvLevelitemizationFields({ invoiceId, customFields }) {
  const format = useFormat();
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);

  const dispatch = useDispatch();
  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  return (
    <>
      <div
        className="itemization-custom-field-container"
        style={
          _.get(customFields, "is_itmzn_err", false)
            ? { backgroundColor: "#FFF9F9" }
            : {}
        }
      >
        <table>
          <tr>
            <td style={{ width: "12%" }}>
              {" "}
              <Typography type="p" className="typo-itemization">
                Amount Placed:
              </Typography>
            </td>
            <td style={{ width: "10%" }}>
              <Typography type="p" className="itemiza-amount-typo">
                {" "}
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "orgiamt", 0),
                })}
              </Typography>
            </td>
            <td style={{ width: "5%" }}>
              <Icon icon={"arrow_right"} color="#ADADAD" />
            </td>
            <td style={{ width: "13%" }}>
              <Typography type="p" className="typo-itemization">
                Principal Amount:{" "}
              </Typography>
            </td>
            <td>
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "orgiamt", 0),
                })}
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={{ width: "10%" }}>
              {" "}
              <Typography type="p" className="typo-itemization">
                Fees/Charges:
              </Typography>
            </td>
            <td style={{ width: "10%" }}>
              {" "}
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "fea_amt", 0),
                })}
              </Typography>
            </td>{" "}
            <td style={{ width: "5%" }}>
              <Icon icon={"arrow_right"} color="#ADADAD" />
            </td>
            <td style={{ width: "7%" }}>
              <Typography type="p" className="typo-itemization">
                Client Fees:
              </Typography>
            </td>
            <td>
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "cc_amt", 0),
                })}
              </Typography>
            </td>
            <td style={{ width: "11%" }}>
              <Typography type="p" className="typo-itemization">
                Agency Fees:{" "}
              </Typography>
            </td>
            <td style={{ width: "10%" }}>
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "ac_amt", 0),
                })}
              </Typography>
            </td>
            <td style={{ width: "11%" }}>
              <Typography type="p" className="typo-itemization">
                Collection Fees:{" "}
              </Typography>
            </td>
            <td style={{ width: "10%" }}>
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "comamt", 0),
                })}
              </Typography>
            </td>
          </tr>

          <tr>
            <td style={{ width: "10%" }}>
              {" "}
              <Typography type="p" className="typo-itemization">
                Interest:
              </Typography>
            </td>
            <td style={{ width: "10%" }}>
              {" "}
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "int_amt", 0),
                })}
              </Typography>
            </td>{" "}
            <td style={{ width: "5%" }}>
              <Icon icon={"arrow_right"} color="#ADADAD" />
            </td>
            <td style={{ width: "7%" }}>
              <Typography type="p" className="typo-itemization">
                Client Interest:
              </Typography>
            </td>
            <td>
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "ci_amt", 0),
                })}
              </Typography>
            </td>
            <td style={{ width: "11%" }}>
              <Typography type="p" className="typo-itemization">
                Agency Interest:{" "}
              </Typography>
            </td>
            <td style={{ width: "10%" }}>
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "ai_amt", 0),
                })}
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={{ width: "10%" }}>
              {" "}
              <Typography type="p" className="typo-itemization">
                Payments:
              </Typography>
            </td>
            <td style={{ width: "10%" }}>
              {" "}
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "tpyamt", 0),
                })}
              </Typography>
            </td>{" "}
            <td style={{ width: "5%" }}>
              <Icon icon={"arrow_right"} color="#ADADAD" />
            </td>
            <td style={{ width: "11%" }}>
              <Typography type="p" className="typo-itemization">
                Prior Payment:{" "}
              </Typography>
            </td>
            <td style={{ width: "10%" }}>
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "pp_amt", 0),
                })}
              </Typography>
            </td>
            <td style={{ width: "14%" }}>
              <Typography type="p" className="typo-itemization">
                Current Payment:
              </Typography>
            </td>
            <td style={{ width: "8%" }}>
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "cp_amt", 0),
                })}
              </Typography>
            </td>
            <td style={{ width: "11%" }}>
              <Typography type="p" className="typo-itemization">
                Last Paid Date:
              </Typography>
            </td>
            <td>
              <Typography type="p" className="itemiza-amount-typo">
                {customFields && customFields?.last_payment_date
                  ? moment(customFields?.last_payment_date).format(
                      currentDefaultFormatDetails.date_format
                    )
                  : "-"}
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={{ width: "10%" }}>
              {" "}
              <Typography type="p" className="typo-itemization">
                Adjustments:
              </Typography>
            </td>
            <td style={{ width: "10%" }}>
              {" "}
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "adjusted_amount.value", 0),
                })}
              </Typography>
            </td>{" "}
            <td style={{ width: "5%" }}>
              <Icon icon={"arrow_right"} color="#ADADAD" />
            </td>
            <td style={{ width: "11%" }}>
              <Typography type="p" className="typo-itemization">
                Prior Adjustment:{" "}
              </Typography>
            </td>
            <td style={{ width: "10%" }}>
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}{" "}
                {format.currency({
                  amount: _.get(customFields, "pa_amt", 0),
                })}
              </Typography>
            </td>
            <td style={{ width: "7%" }}>
              <Typography type="p" className="typo-itemization">
                Current Adjustment
              </Typography>
            </td>
            <td>
              <Typography type="p" className="itemiza-amount-typo">
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "ca_amt", 0),
                })}
              </Typography>
            </td>
          </tr>
          <tr
            className={
              _.get(customFields, "is_itmzn_err", false) && "itmzn_err"
            }
          >
            <td>
              <Typography type="p" className="typo-itemization">
                Outstanding:{" "}
              </Typography>
            </td>
            <td>
              <Typography
                type="p"
                className={
                  _.get(customFields, "is_itmzn_err", false)
                    ? "itemiza-amount-typo --err_itzm_col"
                    : "itemiza-amount-typo"
                }
              >
                {currencyList[currentDefaultFormatDetails.default_currency]}
                {format.currency({
                  amount: _.get(customFields, "not_paid_amount.value", 0),
                })}
              </Typography>
            </td>
            {_.get(customFields, "is_itmzn_err", false) && (
              <>
                <td style={{ width: "5%" }}>
                  {/* <Icon icon={"arrow_right"} color="#ADADAD" /> */}
                </td>
                <td style={{ width: "15%" }} colspan="4">
                  <div className="itmzn_err_alert">
                    <Typography type="p" className="typo-itemization">
                      Outstanding from Upload:{" "}
                      <span>
                        {
                          currencyList[
                            currentDefaultFormatDetails.default_currency
                          ]
                        }
                        {format.currency({
                          amount: _.get(customFields, "os_frm_upld", 0),
                        })}
                      </span>
                    </Typography>
                    <div className="itmzn_err_alert_icon">
                      <Icon icon="alert_circle" color={"#FD372A"} />
                      <Typography type="p">Itemization Error</Typography>
                    </div>
                  </div>
                </td>
              </>
            )}

            <td style={{ width: "10%" }}>
              <Typography type="p" className="itemiza-amount-typo"></Typography>
            </td>
          </tr>
        </table>

        <div className="itemization-date-container">
          <div style={{ display: "flex", gap: "10px" }}>
            <Icon icon={"calendar"} color="#ADADAD" />
            <div>
              <Typography
                type="p"
                style={{
                  fontWeight: "400",
                  color: "#ADADAD",
                  textAlign: "end",
                }}
              >
                Itemization Date:
              </Typography>
              <Typography
                type="p"
                style={{ fontWeight: "400", textAlign: "end" }}
              >
                {customFields?.itmzn_date
                  ? format.date({
                      isOrg: true,
                      value: customFields?.itmzn_date,
                    })
                  : "-"}
              </Typography>
            </div>
          </div>
          <div style={{ paddingLeft: "120px" }}>
            <Icon
              icon="edit"
              color="#787878"
              isPressable
              onClick={async () => {
                let data = {
                  actionType: "EDIT",
                  invoiceId: invoiceId,
                  ...customFields,
                };
                setDrawer(ITEMIZATION_ENHANCEMENT_DRAWER, data);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default InvLevelitemizationFields;
