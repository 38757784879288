import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer } from "baseui/drawer";
import { LabelLarge, ParagraphLarge } from "baseui/typography";
import { SelectBox } from "../../../../../../components/SelectBox";
import {
  addingLetterStatusDeskDocument,
  addLetterStatusEmailAttachment,
  letterStatusDrawer,
  refetchLetterStatus,
  unSelectingLetterStatusDeskDocument,
  updatingLetterStatusDeskDocument,
} from "../../../../../../redux/customerOverview/letterStatus/action";
import { TextArea } from "../../../../../../components/TextArea";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import User from "../../../../../../assets/img/svg/Upload/User";
import { useDropzone } from "react-dropzone";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  TextButton,
  SIZE,
  KIND,
} from "../../../../../../components/TextButton";
import EmailAttachment from "../../../../../../components/EmailAttachment/EmailAttachment";
import { updatingInvoiceDeskDocument } from "../../../../../../redux/invoiceAdding/dmsInvoiceAdding/action";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";
import setSelectValues from "../../../../../../utils/setSelectValues";
import { TextBox } from "../../../../../../components/TextBox";
import { putLetterAction } from "../../../../../../services";
import getSelectValues from "../../../../../../utils/getSelectValues";
import moment from "moment";
import Plus from "../../../../../../assets/img/svg/Upload/Plus";
import PlusOne from "../../../../../../assets/img/svg/Plus";
import PlusLetter from "../../../../../../assets/img/svg/PlusLetter";
import { ADD_LIST_VALUES } from "../../../../../../providers/RBACProvider/permissionList";

const LetterStatusDrawer = ({ getParams, data }) => {
  const [letterSelectedAttachment, setLetterSelectedAttachment] = useState([]);

  const { letterDrawerStatus, selectedDocumentLetterStatusData } = useSelector(
    (s) => s.letterStatusReducer
  );

  const letterAttachments =
    selectedDocumentLetterStatusData &&
    selectedDocumentLetterStatusData.map((e) => {
      return e.fileRef;
    });

  let dispatch = useDispatch();
  let intl = useIntl();

  let {
    currentOrganization,
    referenceData,
    currentDefaultFormatDetails,
    space,
  } = useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { user_comments: "" },
  });

  //   DROP ZONE

  const fileSize = 10000000;
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 1MB`,
      };
    }
  }

  let letterId = data && data._id;

  const putLetterDatas = useMutation(
    (data) =>
      putLetterAction({
        organization: currentOrganization,
        customerId: getParams,
        letterId: letterId,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();

        dispatch(letterStatusDrawer(false));
        dispatch(refetchLetterStatus());
        dispatch(addLetterStatusEmailAttachment([]));
        // setIsAddNew(false);
        // setRefetchCheckIn(refetchCheckIn + 1);
      },
    }
  );

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: {
        "image/jpg": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      validator: uploadLogoValidation,

      onDrop: (acceptedFiles) => {
        let arrObj = acceptedFiles.map((fileDoc) => {
          let { path, type } = fileDoc;

          let splitType = type.split("/");

          return {
            name: path,
            type: splitType[1],
            mimeType: type,
            fileDoc,
          };
        });
        dispatch(addingLetterStatusDeskDocument(arrObj));

        // if (entity === "invoices") {
        //   dispatch(addEmailInvoiceAttachmentFromDesktop(arrObj));
        // } else {
        //   dispatch(addEmailAttachmentFromDesktop(arrObj));
        // }
        // dispatch(emailAttachedFile(acceptedFiles));
        // setAddedAttachment(
        //   acceptedFiles.map((file) =>
        //     Object.assign(file, {
        //       preview: URL.createObjectURL(file),
        //     })
        //   )
        // );
      },
      // validator: uploadLogoValidation,
    });

  useEffect(() => {
    if (data) {
      setValue("delivery_method", setSelectValues(data.delivery_method));
      setValue(
        "tracking_url",
        data.tracking_url !== "undefined" ? data.tracking_url : ""
      );
      setValue("delivery_status", setSelectValues(data.delivery_status));
      setValue(
        "user_comments",
        data?.comments !== "undefined" ? data?.comments : ""
      );
      // if (data && data.attachments && data.attachments.length > 0) {
      //

      //   let attachData = [];
      //   data &&
      //     data.attachments.map((e) => {
      //       let splitType = e && e.name.split(".");

      //       return attachData.push({
      //         name: e.name,
      //         fileRef: e.ref,
      //         _id: e._id,
      //         type: splitType[1],
      //       });
      //     });
      //

      //   // dispatch(addLetterStatusEmailAttachment(attachData));
      // }
      // setValue("user_comments", data.comments);
    }
  }, [data]);

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  const onSubmit = async (data) => {
    //
    let values = {
      delivery_method: getSelectValues(data.delivery_method),
      tracking_url: data.tracking_url,
      delivery_status: getSelectValues(data.delivery_status),
      comments: data.user_comments,
      attachments: letterAttachments,
    };
    await putLetterDatas.mutateAsync(values);
  };

  return (
    <>
      <Drawer
        isOpen={letterDrawerStatus}
        autoFocus
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: "15px",
              marginRight: "15px",
              marginBottom: "15px",
              marginTop: "0px",
              padding: "15px",
              overflowY: "hidden",
            }),
          },
          DrawerContainer: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
        }}
        onClose={() => {
          dispatch(letterStatusDrawer(false));
          dispatch(addLetterStatusEmailAttachment([]));
          //   setIsAddNew(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            // height: "100vh",
          }}
        >
          {/* ONE STARTS*/}
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", gap: "6px" }}>
              <LabelLarge>Letter Status</LabelLarge>
              <div></div>
            </div>
          </div>
          {/* ONE ENDS */}
          {/* FORM STARTS */}
          <div style={{ display: "flex" }}></div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ParagraphLarge
              $style={{
                fontWeight: "500",
              }}
            >
              {data && data.letter_name}
            </ParagraphLarge>
            {/* <div style={{ display: "flex", gap: "5px" }}>
              <User />
              <div>JhonbDoe</div>
              <div>
                {data && data.updated_on
                  ? moment
                      .utc(data.updated_on)
                      .format(currentDefaultFormatDetails?.date_format)
                  : moment
                      .utc(data.created_on)
                      .format(currentDefaultFormatDetails?.date_format)}
              </div>
            </div> */}
            <div></div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div
            // style={{ paddingTop: "15px" }}
            >
              <div style={{ marginBottom: "15px" }}>
                <div
                  style={{
                    paddingBottom: "15px",
                    // minHeight: "100px",
                    overflow: "scroll",
                    maxHeight: "400px",
                  }}
                >
                  {selectedDocumentLetterStatusData &&
                    selectedDocumentLetterStatusData?.map(
                      (attachment, index) => {
                        let { tempId } = attachment;
                        return (
                          <EmailAttachment
                            key={tempId}
                            {...attachment}
                            id={getParams}
                            currentOrganization={currentOrganization}
                            entity={"customer"}
                            updatingDeskUpload={
                              updatingLetterStatusDeskDocument
                            }
                            removeAttchFile={
                              unSelectingLetterStatusDeskDocument
                            }
                            subCategory={data && data?.sub_category}
                          />
                        );
                      }
                    )}
                  {data &&
                  data &&
                  data?.attachments &&
                  data?.attachments.length > 0 ? (
                    <EmailAttachment
                      // key={tempId}
                      // {...attachment}
                      // id={getParams}
                      // currentOrganization={currentOrganization}
                      // entity={"customer"}
                      // updatingDeskUpload={updatingLetterStatusDeskDocument}
                      // removeAttchFile={unSelectingLetterStatusDeskDocument}
                      // subCategory={data && data?.sub_category}
                      isUpdateStatus={
                        data &&
                        data?.attachments &&
                        data?.attachments.length > 0
                          ? true
                          : false
                      }
                      attachment={data?.attachments}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <Controller
                control={control}
                name="delivery_method"
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    creatable={creatablePermission ? true : false}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "delivery_method",
                    })}
                    placeholder={intl.formatMessage({
                      id: "delivery_method",
                    })}
                    size={SIZE.compact}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["letter_delivery_method_list"]}

                    // options={data.map((i) => {
                    //   return {
                    //     label: i.name,
                    //     id: i._id,
                    //     frequency: i.frequency,
                    //   };
                    // })}
                  />
                )}
              />
              {/* <Controller
                control={control}
                name="tracking_url"
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "tracking_url",
                    })}
                    placeholder={intl.formatMessage({
                      id: "tracking_url",
                    })}
                    size={SIZE.compact}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    // options={data.map((i) => {
                    //   return {
                    //     label: i.name,
                    //     id: i._id,
                    //     frequency: i.frequency,
                    //   };
                    // })}
                  />
                )}
              />{" "} */}
              <Controller
                // defaultValue={""}
                name="tracking_url"
                control={control}
                // rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    // hidden={true}
                    // type="number"
                    name={field.name}
                    // requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "tracking_url",
                    })}
                    placeholder={intl.formatMessage({
                      id: "tracking_url",
                    })}
                    // onChange={(e) => {
                    //   field.onChange(e.value);
                    // }}
                    size={SIZE.compact}
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name="delivery_status"
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "delivery_status",
                    })}
                    placeholder={intl.formatMessage({
                      id: "delivery_status",
                    })}
                    size={SIZE.compact}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    // options={data.map((i) => {
                    //   return {
                    //     label: i.name,
                    //     id: i._id,
                    //     frequency: i.frequency,
                    //   };
                    // })}
                    options={referenceData["letter_delivery_status"]}
                  />
                )}
              />
              <Controller
                name="user_comments"
                control={control}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "user_comments",
                    })}
                    placeholder={intl.formatMessage({
                      id: "user_comments",
                    })}
                    value={field.value}
                  />
                )}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // marginTop: "23px",
              }}
            >
              <TextButton
                kind={KIND.tertiary}
                size={SIZE.mini}
                onClick={() => {
                  open();
                }}
                type="button"
              >
                <div style={{ paddingRight: "4px", paddingTop: "2px" }}>
                  <PlusLetter />
                </div>
                {/* <Plus /> */}
                Add Attachment
              </TextButton>
              <div style={{ display: "flex", gap: "8px" }}>
                <TextButton
                  kind={KIND.tertiary}
                  size={SIZE.mini}
                  type="button"
                  onClick={() => {
                    dispatch(letterStatusDrawer(false));
                    dispatch(addLetterStatusEmailAttachment([]));
                  }}
                >
                  Cancel
                </TextButton>
                <TextButton
                  kind={KIND.primary}
                  size={SIZE.mini}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Submit
                </TextButton>
              </div>
            </div>
          </form>

          {/* FORM ENDS */}
        </div>
      </Drawer>
    </>
  );
};

export default LetterStatusDrawer;
