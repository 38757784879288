import React, { useContext, useEffect, useMemo, useState } from "react";
import { getOrganizationDashboard } from "../../services/organization";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import PieChart from "./PieChart";
import {
  HeadingMedium,
  HeadingXSmall,
  LabelSmall,
  ParagraphLarge,
} from "baseui/typography";
import { NumericFormat } from "react-number-format";
import CsvModal from "./CsvModal";
import OverdueSvg from "../../assets/img/svg/OverdueSvg";
import { Skeleton } from "baseui/skeleton";
import DueSvg from "../../assets/img/svg/DueSvg";
import { useQuery } from "react-query";
import InfoSvg from "../../assets/img/svg/InfoSvg";
import { ACCESSIBILITY_TYPE, PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";
import ChavronSvg from "../../assets/img/svg/ChavronSvg";
import { CollectionAgencies } from "../../constants/IndustrialSector";
import { SelectBox } from "../../components/SelectBox";
import { useIntl } from "react-intl";
import { KIND, SIZE } from "baseui/button";
import { Icon, Typography } from "../../components_v2";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { ProgressBar } from "baseui/progress-bar";
import moment from "moment";
import millify from "millify";
import useFormat from "../../hooks/useFormat";
import { currencyList } from "../../utils_v2/CurrencyToSymbol";
const TrendsTag = ({ data = {}, path = "" }) => {
  return (
    <div
      style={{
        background: "#FFF1F0",
        padding: "5px",
        borderRadius: "5px",
        display: "flex",
        gap: "5px",
        alignItems: "center",
      }}
    >
      <div
        style={{
          transform:
            _.get(data, `data.doc.${path}`, "") > 0
              ? "rotate(180deg)"
              : "rotate(0deg)",
        }}
      >
        <ChavronSvg
          width={22}
          height={23}
          color={
            _.get(data, `data.doc.${path}`, "") > 0 ? "#0FB158" : "#FD372A"
          }
        />
      </div>
      <LabelSmall
        $style={{
          color:
            _.get(data, `data.doc.${path}`, "") > 0 ? "#0FB158" : "#FD372A",
        }}
      >{`${_.get(data, `data.doc.${path}`, "-")}%`}</LabelSmall>
    </div>
  );
};

const InvCountTrends = ({ data = {}, path = "" }) => {
  return (
    <>
      <div
        style={{
          height: "5px",
          width: "5px",
          borderRadius: "50px",
          backgroundColor: "#ADADAD",
        }}
      ></div>
      <ParagraphLarge
        $style={{
          color: _.get(data, `data.doc.${path}`, "-") ? "#0FB158" : "#FD372A",
        }}
      >{`${_.get(data, `data.doc.${path}`, "-")}%`}</ParagraphLarge>
    </>
  );
};

const TopSection = ({ setIsModal }) => {
  let {
    currentOrganization,
    appAccessRest,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
  } = useContext(MaxyfiContext);
  const intl = useIntl();
  const [currentMonthIndex, setCurrentMonthIndex] = useState(
    new Date().getMonth()
  );

  const format = useFormat();

  const [value, setValue] = React.useState(20);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [typePay, setTypePay] = useState("Payment");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const navigatePrevious = () => {
    if (currentMonthIndex === 0) {
      setCurrentMonthIndex(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonthIndex(currentMonthIndex - 1);
    }
  };

  const navigateNext = () => {
    if (currentMonthIndex === 11) {
      setCurrentMonthIndex(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonthIndex(currentMonthIndex + 1);
    }
  };

  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `AGENT_DASHBOARD_${currentOrganization}_TOTAL`,
      {
        filters: {
          ...appAccessRest,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getOrganizationDashboard({
        organization: currentOrganization,
        entity: "TOTAL",
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const performanceDash = useQuery(
    [
      `AGENT_DASHBOARD_${currentOrganization}_PERFORMANCE`,
      {
        filters: {
          ...appAccessRest,
          month_start: moment
            .tz(
              `${months[currentMonthIndex]} ${currentYear}`,
              "MMMM YYYY",
              currentDefaultFormatDetails.time_zone
            )
            .startOf("month")
            .valueOf(),
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getOrganizationDashboard({
        organization: currentOrganization,
        entity: "SELF_USER_PERFORMANCE_DASHBOARD",
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const { monthPer, sumOfFuturePay } = useMemo(() => {
    let monthPer;
    let sumOfFuturePay =
      _.get(performanceDash, "data.data.doc.fmpa", 0) +
      _.get(performanceDash, "data.data.doc.fppa", 0) +
      _.get(performanceDash, "data.data.doc.fpra", 0);

    const dayDiff = moment()
      .startOf("day")
      .diff(moment().startOf("month"), "days");
    const monthDiff = moment()
      .endOf("month")
      .diff(moment().startOf("month"), "days");

    if (_.get(performanceDash, "data.data.doc.clper", 0) === 0) {
      monthPer = 0;
    } else {
      monthPer = ((dayDiff / monthDiff) * 100).toFixed(2);
    }

    return { sumOfFuturePay, monthPer };
  }, [performanceDash.isFetching, performanceDash.isLoading]);

  useEffect(() => {
    if (
      _.get(data, "data.doc.total_inv_count", {}) == 0 &&
      currentDefaultFormatDetails.accounting_book == "CSV"
    ) {
      setIsModal(true);
    } else {
      setIsModal(false);
    }
    return () => {
      setIsModal(false);
    };
  }, [data]);

  return (
    <div className="db-sataus__card-container">
      <div className="db-status__section-card">
        <div className="db-status__content">
          {/* <div style={{ flex: 1, width: "25%" }}>
            <PieChart
              outstandingAmount={
                data &&
                data.data &&
                data.data.doc &&
                data.data.doc.overdue_amount
                  ? data.data.doc.overdue_amount
                  : 0
              }
              totalAmount={
                data && data.data && data.data.doc && data.data.doc.total_amount
                  ? data.data.doc.total_amount
                  : 0
              }
            />
          </div> */}
          <div style={{ flex: 3 }}>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <ParagraphLarge $style={{ color: "#ADADAD" }}>
                Outstanding
              </ParagraphLarge>
              <StatefulTooltip
                content={() => (
                  <div padding={"20px"}>Trend from Last 30 Days to Now</div>
                )}
                showArrow
                placement={PLACEMENT.right}
                accessibilityType={ACCESSIBILITY_TYPE.tooltip}
                overrides={{
                  Arrow: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#141440",
                    }),
                  },
                  Body: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#141440",
                    }),
                  },
                  Inner: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#141440",
                    }),
                  },
                }}
              >
                {currentDefaultFormatDetails?.industry_sector !==
                  CollectionAgencies &&
                  appAccessRest.master_filter_type === "BUSINESS_UNIT" && (
                    <div style={{ marginTop: "6px" }}>
                      <InfoSvg />
                    </div>
                  )}
              </StatefulTooltip>
            </div>
            <>
              {isLoading ? (
                <Skeleton width="100%" height="36px" animation />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <div className="dashboard__currency__wraper">
                    <div className="dashboard__currency">
                      <HeadingXSmall>
                        {_.get(data, "data.doc.currency", " ")}
                      </HeadingXSmall>
                    </div>
                    <NumericFormat
                      displayType="text"
                      value={
                        _.get(data, "data.doc.total_amount", {})
                          ? _.get(data, "data.doc.total_amount", {})
                          : 0
                      }
                      thousandsGroupStyle={currencyGroupStyle}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={currencySeparator}
                      decimalSeparator={currencyDecimalSeparator}
                      renderText={(value) => (
                        <HeadingMedium>{value}</HeadingMedium>
                      )}
                    />
                  </div>
                  {currentDefaultFormatDetails?.industry_sector !==
                    CollectionAgencies &&
                    appAccessRest.master_filter_type === "BUSINESS_UNIT" &&
                    _.get(data, `data.doc.total_amount_percentage`, "") && (
                      <TrendsTag data={data} path={"total_amount_percentage"} />
                    )}
                </div>
              )}
            </>
            <div>
              {isLoading ? (
                <Skeleton width="100%" height="16px" animation />
              ) : _.get(data, "data.doc.total_inv_count", "") ? (
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <ParagraphLarge
                    $style={{ color: "#0FB158", textAlign: "left" }}
                  >{`${_.get(
                    data,
                    "data.doc.total_inv_count",
                    ""
                  )} Invoices`}</ParagraphLarge>

                  {currentDefaultFormatDetails?.industry_sector !==
                    CollectionAgencies &&
                    appAccessRest.master_filter_type === "BUSINESS_UNIT" &&
                    _.get(data, `data.doc.total_inv_count_percentage`, "-") && (
                      <InvCountTrends
                        data={data}
                        path={"total_inv_count_percentage"}
                      />
                    )}
                </div>
              ) : (
                <ParagraphLarge
                  $style={{ color: "#0FB158", textAlign: "left" }}
                >
                  0 Invoices
                </ParagraphLarge>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="db-status__section-card">
        <div className="db-status__content">
          <div style={{ flex: 1, marginLeft: "5px" }}>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <ParagraphLarge $style={{ color: "#ADADAD" }}>
                Overdue
              </ParagraphLarge>
              <StatefulTooltip
                content={() => (
                  <div padding={"20px"}>Trend from Last 30 Days to Now</div>
                )}
                showArrow
                placement={PLACEMENT.right}
                accessibilityType={ACCESSIBILITY_TYPE.tooltip}
                overrides={{
                  Arrow: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#141440",
                    }),
                  },
                  Body: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#141440",
                    }),
                  },
                  Inner: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#141440",
                    }),
                  },
                }}
              >
                {currentDefaultFormatDetails?.industry_sector !==
                  CollectionAgencies &&
                  appAccessRest.master_filter_type === "BUSINESS_UNIT" && (
                    <div style={{ marginTop: "6px" }}>
                      <InfoSvg />
                    </div>
                  )}
              </StatefulTooltip>
            </div>
            <>
              {isLoading ? (
                <Skeleton width="100%" height="36px" animation />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <div className="dashboard__currency__wraper">
                    <div className="dashboard__currency">
                      <HeadingXSmall>
                        {data &&
                          data.data &&
                          data.data.doc &&
                          data.data.doc.currency}
                      </HeadingXSmall>
                    </div>
                    <NumericFormat
                      displayType="text"
                      value={
                        data &&
                        data.data &&
                        data.data.doc &&
                        data.data.doc.overdue_amount
                          ? data.data.doc.overdue_amount
                          : 0
                      }
                      thousandsGroupStyle={currencyGroupStyle}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={currencySeparator}
                      decimalSeparator={currencyDecimalSeparator}
                      renderText={(value) => (
                        <HeadingMedium>{value}</HeadingMedium>
                      )}
                    />
                  </div>
                  {currentDefaultFormatDetails?.industry_sector !==
                    CollectionAgencies &&
                    appAccessRest.master_filter_type === "BUSINESS_UNIT" &&
                    _.get(data, `data.doc.overdue_amount_percentage`, "") && (
                      <TrendsTag
                        data={data}
                        path={"overdue_amount_percentage"}
                      />
                    )}
                </div>
              )}
            </>
            <ParagraphLarge $style={{ color: "#0FB158", textAlign: "left" }}>
              {isLoading ? (
                <Skeleton width="100%" height="16px" animation />
              ) : _.get(data, "data.doc.overdue_inv_count", "") ? (
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <ParagraphLarge
                    $style={{ color: "#0FB158", textAlign: "left" }}
                  >{`${_.get(
                    data,
                    "data.doc.overdue_inv_count",
                    ""
                  )} Invoices`}</ParagraphLarge>
                  {currentDefaultFormatDetails?.industry_sector !==
                    CollectionAgencies &&
                    appAccessRest.master_filter_type === "BUSINESS_UNIT" &&
                    _.get(
                      data,
                      `data.doc.overdue_inv_count_percentage`,
                      ""
                    ) && (
                      <InvCountTrends
                        data={data}
                        path={"overdue_inv_count_percentage"}
                      />
                    )}
                </div>
              ) : (
                `0 Invoices`
              )}
            </ParagraphLarge>
          </div>
        </div>
      </div>
      <div className="db-status__section-card">
        <div className="db-status__content">
          <div style={{ flex: 1, marginLeft: "5px" }}>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <ParagraphLarge $style={{ color: "#ADADAD" }}>Due</ParagraphLarge>
              <StatefulTooltip
                content={() => (
                  <div padding={"20px"}>Trend from Last 30 Days to Now</div>
                )}
                showArrow
                placement={PLACEMENT.right}
                accessibilityType={ACCESSIBILITY_TYPE.tooltip}
                overrides={{
                  Arrow: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#141440",
                    }),
                  },
                  Body: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#141440",
                    }),
                  },
                  Inner: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#141440",
                    }),
                  },
                }}
              >
                {currentDefaultFormatDetails?.industry_sector !==
                  CollectionAgencies &&
                  appAccessRest.master_filter_type === "BUSINESS_UNIT" && (
                    <div style={{ marginTop: "6px" }}>
                      <InfoSvg />
                    </div>
                  )}
              </StatefulTooltip>
            </div>
            <>
              {isLoading ? (
                <Skeleton width="100%" height="36px" animation />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <div className="dashboard__currency__wraper">
                    <div className="dashboard__currency">
                      <HeadingXSmall>
                        {data &&
                          data.data &&
                          data.data.doc &&
                          data.data.doc.currency}
                      </HeadingXSmall>
                    </div>
                    <NumericFormat
                      displayType="text"
                      value={
                        data &&
                        data.data &&
                        data.data.doc &&
                        data.data.doc.due_amount
                          ? data.data.doc.due_amount
                          : 0
                      }
                      thousandsGroupStyle={currencyGroupStyle}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={currencySeparator}
                      decimalSeparator={currencyDecimalSeparator}
                      renderText={(value) => (
                        <HeadingMedium>{value}</HeadingMedium>
                      )}
                    />
                  </div>
                  {currentDefaultFormatDetails?.industry_sector !==
                    CollectionAgencies &&
                    appAccessRest.master_filter_type === "BUSINESS_UNIT" &&
                    _.get(data, `data.doc.due_amount_percentage`, "") && (
                      <TrendsTag data={data} path={"due_amount_percentage"} />
                    )}
                </div>
              )}
            </>
            <ParagraphLarge $style={{ color: "#0FB158", textAlign: "left" }}>
              {isLoading ? (
                <Skeleton width="100%" height="16px" animation />
              ) : _.get(data, "data.doc.due_inv_count", "") ? (
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <ParagraphLarge
                    $style={{ color: "#0FB158", textAlign: "left" }}
                  >{`${_.get(
                    data,
                    "data.doc.due_inv_count",
                    ""
                  )} Invoices`}</ParagraphLarge>
                  {currentDefaultFormatDetails?.industry_sector !==
                    CollectionAgencies &&
                    appAccessRest.master_filter_type === "BUSINESS_UNIT" &&
                    _.get(data, `data.doc.due_inv_count_percentage`, "") && (
                      <InvCountTrends
                        data={data}
                        path={"due_inv_count_percentage"}
                      />
                    )}
                </div>
              ) : (
                `0 Invoices`
              )}
            </ParagraphLarge>
          </div>
        </div>
      </div>
      <div className="future-payments-container">
        <div
          className="future-payments-header-section"
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            gap: "3px",
          }}
        >
          <div className="future-payments-header-separator">
            <StatefulPopover
              content={({ close }) => (
                <div className="future-payments-type_selector">
                  <div className="co_action_inv">
                    <Typography
                      type="p"
                      style={{ color: typePay === "Payment" ? "#516BEB" : "" }}
                      onClick={() => {
                        setTypePay("Payment");
                        close();
                      }}
                    >
                      {"Payment"}
                    </Typography>
                  </div>

                  <div className="co_action_inv">
                    <Typography
                      type="p"
                      style={{
                        color: typePay === "Commission" ? "#516BEB" : "",
                      }}
                      onClick={() => {
                        setTypePay("Commission");
                        close();
                      }}
                    >
                      Commission
                    </Typography>
                  </div>
                </div>
              )}
              triggerType={TRIGGER_TYPE.click}
              returnFocus
              autoFocus
            >
              <div
                style={{ display: "flex", gap: "3px", alignItems: "center" }}
              >
                <Typography
                  className="text-secondary"
                  type="p"
                  subType="regular"
                  style={{ color: "#ADADAD" }}
                >
                  {" "}
                  {typePay}
                </Typography>
                <div co-inv-table-edit>
                  <Icon
                    icon="chevron_down"
                    isPressable
                    size="18"
                    color="#ADADAD"
                  />
                </div>
              </div>
            </StatefulPopover>

            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <div
                onClick={navigatePrevious}
                style={{
                  borderRadius: "50%",
                  border: "1px solid #ADADAD",
                  textAlign: "center",
                }}
              >
                {" "}
                <Icon
                  icon="chevron_left"
                  isPressable
                  size="18"
                  color="#ADADAD"
                />
              </div>

              <Typography className="text-secondary" type="p" subType="regular">
                {months[currentMonthIndex]} {currentYear}
              </Typography>
              <div
                onClick={navigateNext}
                style={{
                  borderRadius: "50%",
                  border: "1px solid #ADADAD",
                  textAlign: "center",
                }}
              >
                <Icon
                  icon="chevron_right"
                  isPressable
                  size="18"
                  color="#ADADAD"
                />
              </div>
            </div>
          </div>
          <div className="future-payments-payment-collected">
            <Typography className="regular" type="p" subType="regular">
              Payment Collected :{" "}
              {currencyList[_.get(performanceDash, "data.data.doc.curr", 0)]}
              {format.currency({
                amount:
                  typePay === "Payment"
                    ? _.get(performanceDash, "data.data.doc.cla", 0)
                    : _.get(performanceDash, "data.data.doc.cma", 0),
              })}
            </Typography>

            <StatefulTooltip
              placement={PLACEMENT.bottom}
              showArrow
              overrides={{
                Arrow: {
                  style: ({ $theme }) => ({
                    outline: `#16164B solid`,
                    backgroundColor: "#16164B",
                  }),
                },
                Inner: {
                  style: ({ $theme }) => ({
                    outline: `#16164B solid`,
                    backgroundColor: "#16164B",
                  }),
                },
              }}
              content={() => (
                <div style={{ backgroundColor: "#16164B" }}>
                  <Typography
                    type="p"
                    subType="regular"
                    style={{ color: "white" }}
                  >
                    {moment(_.get(performanceDash, "data.data.doc.mns"))
                      .startOf("month")
                      .format("MMM-DD")}{" "}
                    Target{" "}
                    {
                      currencyList[
                        _.get(performanceDash, "data.data.doc.curr", 0)
                      ]
                    }
                    {format.currency({
                      amount:
                        typePay === "Payment"
                          ? _.get(performanceDash, "data.data.doc.tcla", 0)
                          : _.get(performanceDash, "data.data.doc.tcma", 0),
                    })}{" "}
                    and Collected is{" "}
                    {
                      currencyList[
                        _.get(performanceDash, "data.data.doc.curr", 0)
                      ]
                    }
                    {format.currency({
                      amount:
                        typePay === "Payment"
                          ? _.get(performanceDash, "data.data.doc.cla", 0)
                          : _.get(performanceDash, "data.data.doc.cma", 0),
                    })}{" "}
                    (
                    {typePay === "Payment"
                      ? _.get(performanceDash, "data.data.doc.clper", 0) - 100
                      : _.get(performanceDash, "data.data.doc.cper", 0) - 100}
                    %)
                  </Typography>
                </div>
              )}
              returnFocus
              autoFocus
            >
              <Icon icon="question_circle" size="16" color="#ADADAD" />
            </StatefulTooltip>
          </div>

          <ProgressBar
            value={monthPer}
            overrides={{
              BarProgress: {
                style: ({ $theme, $value }) => ({
                  ...$theme.typography.font350,
                  position: "relative",
                  backgroundColor: $theme.colors.positive,
                  color: $theme.colors.mono200,
                  overflow: "hidden",
                  backgroundSize: "100%",
                  textAlign: "center",
                  ":after": {
                    position: "absolute",
                    content:
                      $value > 5
                        ? `"${
                            typePay === "Payment"
                              ? _.get(performanceDash, "data.data.doc.clper", 0)
                              : _.get(performanceDash, "data.data.doc.cper", 0)
                          }%"`
                        : "",
                    right: "10px",
                  }, // Center the percentage value // Center the percentage value
                }),
              },
              Bar: {
                style: ({ $theme }) => ({
                  height: $theme.sizing.scale600,
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                }),
              },
              BarContainer: {
                style: ({ $theme }) => ({
                  marginLeft: "0px",
                  marginRight: "0px",
                  marginBottom: "0px",
                  marginTop: "0px",
                }),
              },
            }}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="text-secondary" type="p" subType="small">
              0
            </Typography>
            <Typography className="text-secondary" type="p" subType="small">
              {currencyList[_.get(performanceDash, "data.data.doc.curr", 0)]}
              {format.currency({
                amount:
                  typePay === "Payment"
                    ? _.get(performanceDash, "data.data.doc.ecla", 0)
                    : _.get(performanceDash, "data.data.doc.ecma", 0),
              })}
            </Typography>
          </div>

          <div style={{ border: "0.3px solid #EEEEEE" }}></div>
          <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
            <Typography className="regular" type="p" subType="regular">
              Future Payments:
            </Typography>
            <StatefulTooltip
              placement={PLACEMENT.bottom}
              showArrow
              overrides={{
                Arrow: {
                  style: ({ $theme }) => ({
                    outline: `#16164B solid`,
                    backgroundColor: "#16164B",
                  }),
                },
                Inner: {
                  style: ({ $theme }) => ({
                    outline: `#16164B solid`,
                    backgroundColor: "#16164B",
                  }),
                },
              }}
              content={() => (
                <div
                  className="future-payments-tooltip-expected"
                  style={{
                    backgroundColor: "#16164B",
                    display: "flex",
                    gap: "5px",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    type="p"
                    subType="regular"
                    style={{ color: "white" }}
                  >
                    Expected Projection - from Today to{" "}
                    {moment(_.get(performanceDash, "data.data.doc.mns"))
                      .endOf("month")
                      .format("MMM-DD")}
                  </Typography>
                  <div
                    style={{ border: "1px solid #575757", width: "100%" }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="future-payments-toolTip_smallBox"
                      style={{
                        backgroundColor: "#9966FF",
                      }}
                    ></div>
                    <Typography
                      type="p"
                      subType="regular"
                      style={{ color: "white" }}
                    >
                      Auto-Debit Payment Plan -{" "}
                      {millify(_.get(performanceDash, "data.data.doc.fppa", 0))}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="future-payments-toolTip_smallBox"
                      style={{
                        backgroundColor: "#FFCD56",
                      }}
                    ></div>
                    <Typography
                      type="p"
                      subType="regular"
                      style={{ color: "white" }}
                    >
                      Future Payment -{" "}
                      {millify(_.get(performanceDash, "data.data.doc.fmpa", 0))}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="future-payments-toolTip_smallBox"
                      style={{
                        backgroundColor: "#FF9F40",
                      }}
                    ></div>
                    <Typography
                      type="p"
                      subType="regular"
                      style={{ color: "white" }}
                    >
                      Promise To Pay -{" "}
                      {millify(_.get(performanceDash, "data.data.doc.fpra", 0))}
                    </Typography>
                  </div>
                </div>
              )}
              returnFocus
              autoFocus
            >
              <Icon icon="question_circle" size="16" color="#ADADAD" />
            </StatefulTooltip>
          </div>
          <div style={{ display: "flex", gap: "1px" }}>
            {_.get(performanceDash, "data.data.doc.fppa") ? (
              <div
                style={{
                  width: `calc(100% - ${
                    (_.get(performanceDash, "data.data.doc.fppa", 0) /
                      sumOfFuturePay) *
                    100
                  }px)`,
                  height: "20px",
                  backgroundColor: "#9966FF",
                  color: "#ffffff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {millify(_.get(performanceDash, "data.data.doc.fppa", 0))}
              </div>
            ) : (
              <></>
            )}

            {_.get(performanceDash, "data.data.doc.fmpa") ? (
              <div
                style={{
                  width: `calc(100% - ${
                    (_.get(performanceDash, "data.data.doc.fmpa", 0) /
                      sumOfFuturePay) *
                    100
                  }px)`,
                  height: "20px",
                  backgroundColor: "#FFCD56",
                  color: "#ffffff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {millify(_.get(performanceDash, "data.data.doc.fmpa", 0))}
              </div>
            ) : (
              <></>
            )}

            {_.get(performanceDash, "data.data.doc.fpra") ? (
              <div
                style={{
                  width: `calc(100% - ${
                    (_.get(performanceDash, "data.data.doc.fpra", 0) /
                      sumOfFuturePay) *
                    100
                  }px)`,
                  height: "20px",
                  backgroundColor: "#FF9F40",
                  color: "#ffffff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {millify(_.get(performanceDash, "data.data.doc.fpra", 0))}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="text-secondary" type="p" subType="small">
              Today
            </Typography>
            <Typography className="text-secondary" type="p" subType="small">
              {moment(_.get(performanceDash, "data.data.doc.mns"))
                .endOf("month")
                .format("MMM-DD")}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
