import React, { useState, useEffect, useRef } from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";
import {
  INVOICE_SUB_STATUS_DRAWER,
  setDrawerState,
} from "../../../redux/actions";
import { useDispatch } from "react-redux";

function DetailTextOverviewInvoice(props) {
  let { value, column, alignment, accessor, suffix, row, color, ...rest } =
    props;

  value = _.get(row.original, accessor, "");
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);
  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };
  useEffect(() => {
    if (ref.current) {
      const isEllipsized = ref.current.offsetHeight < ref.current.scrollHeight;
      setVisible(isEllipsized);
    }
  }, [column.width, value]); // Recalculate on width change

  return visible ? (
    <StatefulTooltip
      accessibilityType={"tooltip"}
      content={
        <div style={{ maxWidth: "250px", whiteSpace: "normal" }}>{value}</div>
      }
    >
      <Paragraph2
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "100%",
          textAlign: alignment,
          cursor: "pointer",
          lineHeight: "18px",
          color: color || "#333860",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2, // Limit to 4 lines
        }}
        onClick={() => {
          setDrawer(INVOICE_SUB_STATUS_DRAWER, {
            invoiceId: _.get(row, "original._id", ""),
            invoiceClientRef: _.get(row, "original.client_reference", ""),
            invoiceNumber: _.get(row, "original.invoice_number", ""),
          });
        }}
        ref={ref}
      >
        {value?.toString().length ? (
          <>
            {value} {suffix || ""}
          </>
        ) : (
          "-"
        )}
      </Paragraph2>
    </StatefulTooltip>
  ) : (
    <Paragraph2
      $style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        textAlign: alignment,
        lineHeight: "18px",
        cursor: "pointer",
        color: color || "#333860",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
      }}
      ref={ref}
      onClick={() => {
        setDrawer(INVOICE_SUB_STATUS_DRAWER, {
          invoiceId: _.get(row, "original._id", ""),
          invoiceClientRef: _.get(row, "original.client_reference", ""),
          invoiceNumber: _.get(row, "original.invoice_number", ""),
        });
      }}
    >
      {value?.toString().length || value === 0 ? (
        <>
          {value} {suffix || ""}
        </>
      ) : (
        "-"
      )}
    </Paragraph2>
  );
}

export default DetailTextOverviewInvoice;
