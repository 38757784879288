import AgentInstruction from "../../containers_v2/CustomerOverview/AgentInstruction";
import React, {
  useContext,
  useEffect,
  useState,
  Suspense,
  useMemo,
  useLayoutEffect,
} from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useQuery, useMutation } from "react-query";
import { FormattedMessage, useIntl } from "react-intl";
import _ from "lodash";
import { useDispatch } from "react-redux";
import moment from "moment";
import "moment-timezone";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Skeleton } from "baseui/skeleton";

import SideBar from "../../layouts/SideBar";
import {
  COAlertNote,
  COContacts,
  COInvoiceTable,
  COPaymentTable,
  COQuickNote,
  COTimeline,
  Drawer,
  GroupSelect,
} from "../../containers_v2/CustomerOverview";
import { Icon, Typography, RDText } from "../../components_v2";
import { TextButton } from "../../components/TextButton";
import {
  getTimelineDownload,
  nextCustomerFn,
  prevCustomerFn,
  removeCustomerLockFn,
  useAssignWorkflowFn,
  useUnassignRelmManger,
  worklistOverAllCount,
} from "../../services";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";

import Loader from "../../components/Loader";
import {
  activeWorkflowData,
  CALL_DRAWER,
  CUSTOM_FIELDS_DRAWER,
  DMS_DRAWER,
  EMAIL_DRAWER,
  FIELD_VISIT_DRAWER,
  GENERIC_ACTION_DRAWER,
  getCustomerDetailsAction,
  HOLD_ACTION,
  LEGAL_LETTER_DRAWER,
  NEXT_ACTION_DRAWER,
  PORTAL_SETTINGS_DRAWER,
  REQUEST_INFORMATION,
  setDrawerState,
  SMS_DRAWER,
  DISPUTE_DRAWER,
  REVIEW_PROMISE,
  workflowAddModal,
  LEGAL_ACTION_DRAWER,
  REVIEW_GROUPING_DRAWER,
  EDIT_GROUPING_DRAWER,
  getGroupingListDrawer,
  groupDataToDrawer,
  WHATSAPP_DRAWER,
  BANKRUPTCY_FORM,
  MANUAL_GROUPING,
  CREDIT_REPORT,
  minimizeSMSActionData,
  minimizeDeleteAction,
  maxValidationFields,
  ESIGN_ACTION_DRAWER,
} from "../../redux/actions";
import { SIZE, SelectBox } from "../../components/SelectBox";
import CustomerNameEdit from "../../containers_v2/CustomerOverview/CustomerNameEdit";
import {
  CUSTOMER_OVERVIEW,
  CUSTOMER_OVERVIEW_,
  CO_TIMELINE_,
  LETTER_STATUS,
  DATE_ORG,
  DOC_QUICK_VIEW_LIST,
  CO_INVOICES_,
  CO_PAYMENT_,
  GET_GROUPING_LIST_DATA,
} from "../../constants";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  CUSTOMER_WF_ACTION,
  CUSTOMER_WF_SKIP,
  ASSIGN_WORKFLOW,
  ASSIGN_RM,
  ASSIGN_STATUS,
  CUSTOMER_MANUAL_CALL,
  CUSTOMER_MANUAL_EMAIL,
  CUSTOMER_SMS,
  CUSTOMER_NEXT_ACTION,
  CUSTOMER_DISPUTE,
  CUSTOMER_PAUSE,
  CUSTOMER_REQUEST_INFORMATION,
  CUSTOMER_RESUME,
  CUSTOMER_PROMISE_REVIEW,
  VIEW_DOWNLOAD_DOCUMENTS,
  CUSTOMER_LETTER_STATUS,
  CUSTOMER_LETTER,
  CUSTOMER_WHATSAPP,
  CUSTOMER_GENERIC_ACTION,
  CLIENT_PORTAL_ACCESS,
  BANKRUCPTCY_STATUS,
  CONSUMER_MANUAL_GROUPING,
  CUSTOMER_FIELD_VISIT,
  CONSUMER_LEGAL_ACTION,
  ADD_CREDIT_REPORT,
  UNASSIGN_RM,
  DISABLE_TIMELINE,
  DISABLE_NOTES,
  CUSTOMER_PORTAL_SETTINGS,
  ASSIGN_WORK_QUEUE,
  ESIGNPERMISSIONS,
  WORKLIST,
  VIEW_PAYMENT,
  ADD_PAYMENTS,
  EDIT_PAYMENTS,
} from "../../providers/RBACProvider/permissionList";
import { useCustomerQuery } from "../../queries/useCustomer";

import CustomerStatus from "../../components_v2/CustomerStatus";
import { ModalContext } from "../../providers/ModalProvider";
import workflowModal from "../../assets/img/workflow-modal.png";
import AssignRelationManager from "../../components_v2/AssignRelationManager";
import CpWorkflowModal from "../../components/CpWorkflowModal/CpWorkflowModal";
import WarningImage from "../../assets/img/warning.png";
import PauseAction from "../../containers_v2/CustomerOverview/PauseAction";
import ResumeAction from "../../containers_v2/CustomerOverview/ResumeAction";
import SkipAction from "../../containers_v2/CustomerOverview/SkipAction";
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { useSelector } from "react-redux";
import SubscriptionDrawer from "../../containers/CustomerOverView/Subscriptions/SubscriptionDrawer";
import queryClient from "../../providers/queryClient";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import {
  customerData,
  getCustomerCampaign,
} from "../../services/customerSummary";
import CustomerListCard from "../../components_v2/CustomerListCard";
import { getCustomerActions } from "../../services";
import useFormat from "../../hooks/useFormat";
import ReviewButton from "../../containers_v2/CustomerOverview/ReviewButton";
import DateComponent from "../../components_v2/DateComponent";
import { NOTE_LIST_DRAWER } from "../../redux/actions";

import { StatefulTooltip } from "baseui/tooltip";
import { getRoleMaintenance } from "../../services/users";
import { KIND, Tag } from "baseui/tag";
import WorkQueueForm from "../../components/FormModal/WorkQueueForm";
import {
  getDocumentData,
  getGroupingCustomers,
} from "../../services/documentSystem";
import DocQuickView from "../../containers_v2/CustomerOverview/DocQuickView";
import AgentLocked from "../../containers_v2/CustomerOverview/AgentLocked";
import NotesComp from "../../containers_v2/CustomerOverview/NotesComp";
import NotesContainer from "../../containers_v2/CustomerOverview/NotesContainer";
import HeaderActionConfig from "./HeaderActionConfig";
import { currencyList } from "../../utils_v2/CurrencyToSymbol";
import SideBar_V2 from "../../layouts/SideBar_V2/SideBar_V2";
import {
  setWorklistData,
  setWorklistResponceData,
} from "../../redux/worklist/action";
import { IconButton } from "../../components/IconButton";

const ConfigureToolTip = ({ children, enable, name }) => {
  if (enable) {
    return (
      <StatefulTooltip
        content={() => <p>{name} Action is not Enabled</p>}
        returnFocus
        autoFocus
        accessibilityType="tooltip"
        placement="bottomLeft"
      >
        {children}
      </StatefulTooltip>
    );
  }

  return children;
};

export const CardRow = ({
  handleDrawer,
  label = "",
  value = "",
  type,
  prefixValue = "",
  editable = false,
  onEdit = () => {},
  editPermission = null,
  removable = false,
  onRemove = () => {},
  removePermission = "",
  typoSubType = "regular",
  iconType,
  adjustmentAmount,
  paymentAmt,
}) => {
  const dispatch = useDispatch();
  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);

  return (
    <div className="co-info-card_row">
      <div className="co-info-card_cell co-info-card_cell--label">
        <Typography type="p" subType={typoSubType} className="text-secondary">
          {label}
        </Typography>
      </div>
      <div className="co-info-card_cell co-info-card_cell--value">
        {iconType === "info_icon" ? (
          <>
            {" "}
            <StatefulTooltip
              content={() => (
                <>
                  <p style={{ color: "#ADADAD", fontWeight: "400" }}>
                    Amount Breakup:
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div>
                      <Typography className="text-trinery">
                        Payments:{" "}
                      </Typography>
                      <Typography className="text-trinery">
                        Adjustments:
                      </Typography>
                    </div>
                    <div>
                      <Typography className="text-trinery">
                        {" "}
                        {
                          currencyList[
                            currentDefaultFormatDetails.default_currency
                          ]
                        }
                        {paymentAmt}
                      </Typography>
                      <Typography className="text-trinery">
                        {" "}
                        {
                          currencyList[
                            currentDefaultFormatDetails.default_currency
                          ]
                        }
                        {adjustmentAmount}
                      </Typography>
                    </div>
                  </div>
                </>
              )}
              placement={PLACEMENT.bottom}
              returnFocus
              autoFocus
              accessibilityType="tooltip"
              overrides={{
                Inner: {
                  style: ({ $theme }) => ({
                    backgroundColor: "#16164B",
                  }),
                },
              }}
            >
              <div style={{ padding: "5px" }}>
                <Icon icon={iconType} color="#ADADAD" isPressable />
              </div>
            </StatefulTooltip>
          </>
        ) : (
          <></>
        )}

        <StatefulTooltip
          content={value}
          returnFocus
          autoFocus
          accessibilityType="tooltip"
        >
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (label === "Dispute") {
                setDrawer(DISPUTE_DRAWER);
              }
              if (label === "Promise") {
                setDrawer(REVIEW_PROMISE);
              }
            }}
            type="p"
            subType={typoSubType}
            className={type ? `text-${type}` : ""}
          >
            {value ? value : "-"}
            {prefixValue &&
            !prefixValue?.includes("in") &&
            prefixValue !== "Due Today" ? (
              <span
                style={{
                  color: "#fd372a",
                  cursor: "pointer",
                  marginLeft: "1px",
                }}
                onClick={() => {
                  if (label === "Dispute") {
                    setDrawer(DISPUTE_DRAWER);
                  }
                  if (label === "Promise") {
                    setDrawer(REVIEW_PROMISE);
                  }
                }}
              >
                {prefixValue}
              </span>
            ) : (
              <>
                {prefixValue && (
                  <span
                    style={{
                      cursor: "pointer",
                      marginLeft: "1px",
                    }}
                    onClick={() => {
                      if (label === "Dispute") {
                        setDrawer(DISPUTE_DRAWER);
                      }
                      if (label === "Promise") {
                        setDrawer(REVIEW_PROMISE);
                      }
                    }}
                  >
                    {prefixValue ? prefixValue : ""}
                  </span>
                )}
              </>
            )}
          </Typography>
        </StatefulTooltip>

        {editPermission ? (
          <RBACWrapper role={editPermission} type="PERMISSION">
            {editable && (
              <Icon
                icon="edit"
                size={16}
                isPressable
                onClick={() => onEdit()}
              />
            )}
          </RBACWrapper>
        ) : (
          editable && (
            <Icon icon="edit" size={16} isPressable onClick={() => onEdit()} />
          )
        )}
        {removePermission ? (
          <RBACWrapper role={removePermission} type="PERMISSION">
            {removable && (
              <Icon
                icon="remove"
                size={16}
                isPressable
                onClick={() => onRemove()}
              />
            )}
          </RBACWrapper>
        ) : (
          removable && (
            <Icon
              icon="remove"
              size={16}
              isPressable
              onClick={() => onRemove()}
            />
          )
        )}
      </div>
    </div>
  );
};

const FooterContent = ({ label = "", value = "", type }) => {
  return (
    <div className="co-info-card_footer_info">
      <Typography type="p" subType="regular" className="text-secondary">
        {label}
      </Typography>
      <Typography
        type="p"
        subType="regular"
        className={type ? `text-${type}` : ""}
      >
        {value}
      </Typography>
    </div>
  );
};

const NextActionStatus = ({ date = 0 }) => {
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);

  const today = moment.tz(currentDefaultFormatDetails.time_zone).startOf("day");
  const dayDiff = today.diff(
    moment.tz(date, currentDefaultFormatDetails.time_zone),
    "days"
  );

  if (!dayDiff) {
    return <span className="text-info"></span>;
  }

  if (dayDiff === 0) {
    return <span className="text-info">(Today)</span>;
  }

  if (dayDiff > 0) {
    return (
      <span className="text-error">(Overdue {Math.abs(dayDiff)} days ago)</span>
    );
  }
  if (Math.abs(dayDiff) === 1) {
    return <span>(In {Math.abs(dayDiff)} day)</span>;
  }

  return <span>(In {Math.abs(dayDiff)} days)</span>;
};

const iconType = {
  SMS_DRAWER: "sms",
  EMAIL_DRAWER: "email",
};

export default function CustomerOverview() {
  let intl = useIntl();
  const dispatch = useDispatch();
  const format = useFormat();
  const {
    currentOrganization,
    customCustomer,
    referenceData,
    appAccessRest,
    currentDefaultFormatDetails,
    space,
    userInfo,
  } = useContext(MaxyfiContext);
  const { handleModal } = useContext(ModalContext);
  const navigator = useNavigate();
  const { customerId } = useParams();

  const [agentInstructionModal, setAgentInstructionModalState] =
    useState(false);
  const [isClientInstruction, setClientInstrucionState] = useState(false);
  const [isCustomerExpand, setIsCustomerExpand] = useState(false);
  const [isEnable, setIsEnable] = useState({ isType: "", isCount: 0 });
  const [isAgentData, setIsAgentData] = useState("");
  const [isInternal, setIsInternal] = useState(false);
  const [getSearchParams] = useSearchParams();
  const [isOverAllData, setIsOverAllData] = useState({});
  const ib = getSearchParams.get("ib");
  const disableTimeline =
    space && !space?.role_permissions?.includes(DISABLE_TIMELINE);
  useEffect(() => {
    if (ib) {
      setIsInternal(true);
    }
  }, [ib]);

  const { subscriptionsDrawerIsOpen } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const { active, quickActionsTempData } = useSelector(
    (state) => state.customerOverviewDrawer
  );

  const lastTempDataActions = useMemo(() => {
    const lastThreeActions = quickActionsTempData.slice(-3);
    return lastThreeActions;
  }, [quickActionsTempData]);

  const customer = useCustomerQuery({
    customerId,
    organizationId: currentOrganization,
    options: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  });

  const worklistPermission = space && space.role_permissions.includes(WORKLIST);

  if (customer.isLoading) {
    <Loader />;
  }

  const { worklists, count, process_count } = useSelector(
    (e) => e.worklist.worklistData
  );

  const { actionResposce } = useSelector((e) => e.worklist);

  let worklistCount = useQuery(
    [`WORKLIST_WORK_COUNT_OVERVIEW-${currentOrganization}-${customerId}`],
    async () => {
      return await worklistOverAllCount({
        organization: currentOrganization,
        ...(worklists?.length ? { worklists: [...worklists] } : {}),
        time_zone: currentDefaultFormatDetails.time_zone,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: worklistPermission,
      onSuccess: (data) => {
        if (data?.data?.doc) {
          dispatch(setWorklistData(data?.data?.doc));
        }
      },
    }
  );

  //

  // useEffect(() => {
  //
  //   if (worklistCount?.data?.data?.doc) {
  //     dispatch(setWorklistData(worklistCount?.data?.data?.doc));
  //   } else {
  //     let value = {
  //       _id: null,
  //     };
  //     dispatch(setWorklistData(value));
  //   }
  // }, [
  //   // worklistCount.data,
  //   // worklistCount.isFetching,
  //   // worklistCount.isFetched,
  //   // worklistCount.isRefetching,
  //   // worklistCount.isLoading,
  //   worklistCount.status,
  // ]);

  useEffect(() => {
    if (customer && customer.data && customer.data.pgmc) {
      navigator(`/customers/${customer.data.pgmc}`);
    }
  }, [customerId, customer.isFetched]);

  const consumerStatusConfig = useMemo(() => {
    const status = _.get(customer, "data.customer_status");
    let statusUpdate = {
      customerOverview: true,
      customerAction: true,
      customerStatus: status,
      isStatusModify: true,
    };
    if (status) {
      const statusConfig =
        currentDefaultFormatDetails &&
        Array.isArray(currentDefaultFormatDetails.csm) &&
        currentDefaultFormatDetails.csm.find((i) => i.nam === status);
      if (statusConfig) {
        const isOverride =
          statusConfig.ror && Array.isArray(statusConfig.ror) && userInfo?.role
            ? statusConfig.ror.includes(userInfo.role)
            : false;

        const isStatusModify =
          statusConfig && statusConfig.mcsr && statusConfig.mcsr.length === 0
            ? true
            : statusConfig.mcsr &&
              Array.isArray(statusConfig.mcsr) &&
              userInfo?.role
            ? statusConfig.mcsr.includes(userInfo.role)
            : false;

        return {
          ...statusUpdate,
          customerOverview: statusConfig.rco && !isOverride ? false : true,
          customerAction: statusConfig.rca && !isOverride ? false : true,
          isStatusModify: isStatusModify,
        };
      }
    }
    return statusUpdate;
  }, [customer]);

  const [queryData, setQueryData] = useState();

  useEffect(() => {
    const unsubscribe = queryClient.getQueryCache().subscribe(() => {
      const data = queryClient.getQueryData([
        `${CO_INVOICES_}${customerId}`,
        { filters: {}, gid: _.get(customer, "data.gid", "") },
      ]);
      setQueryData(data);
    });

    return () => unsubscribe(); // Clean up on unmount
  }, [queryClient, customer]);

  const { getGroupingDataList } = useMemo(() => {
    const groupedData = Object.values(
      _.get(queryData, "data.docs", []).reduce((acc, curr) => {
        if (!acc[curr.pgid]) {
          acc[curr.pgid] = {
            _id: curr.pgid,
            pgmc: curr.pgmc || curr.customer_id,
            customers: [],
          };
        }
        acc?.[curr?.pgid]?.customers?.unshift({
          _id: curr.customer_id,
          business_unit: curr?.business_unit,
          days_since_created: curr?.days_since_created,
          pgid: curr?.pgid,
          total_outstanding_invoice_amount: "",
          invoice: {
            _id: curr?._id,
            invoice_number: curr?.invoice_number,
            invoice_date: curr?.invoice_date,
            not_paid_amount: curr?.not_paid_amount,
            client_reference: curr?.client_reference,
          },
          ipgm: curr.pgmc ? false : true,
        });
        return acc;
      }, {})
    );

    return { getGroupingDataList: groupedData?.reverse() };
  }, [queryData]);

  // const getGroupingDataList = useQuery(
  //   [`${GET_GROUPING_LIST_DATA}-${customerId}`],
  //   async () => {
  //     return await getGroupingCustomers({
  //       organization: currentOrganization,
  //       gid: _.get(customer, "data.gid", ""),
  //     });
  //   },
  //   {
  //     refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //     enabled: _.get(customer, "data.gid", "").length ? true : false,
  //   }
  // );

  const {
    minPayment,
    maxInstalmentCount,
    isClientPortalRedirect,
    agentInstruction,
    isDisplayOnLoad,
  } = useMemo(() => {
    let businessUnitList = [];

    let findPgidData = getGroupingDataList.find(
      (pg) => pg._id === _.get(customer, "data.pgid", "")
    );

    if (findPgidData && findPgidData._id) {
      businessUnitList.push(
        ...findPgidData?.customers?.map((ele) => ele.business_unit)
      );
    } else {
      businessUnitList.push(_.get(customer, "data.business_unit", ""));
    }

    let minPayment;
    let maxInstalmentCount;
    let isClientPortalRedirect;
    let agentInstruction = [];
    let isDisplayOnLoad = false;

    for (const bu of referenceData["business_unit_list"] || []) {
      if (businessUnitList.includes(String(bu.id))) {
        if (bu.mipay) {
          if (!minPayment) {
            minPayment = bu.mipay;
          } else if (minPayment < bu.mipay) {
            minPayment = bu.mipay;
          }
        }
        if (bu.mxinc) {
          if (!maxInstalmentCount) {
            maxInstalmentCount = bu.mxinc;
          } else if (maxInstalmentCount > bu.mxinc) {
            maxInstalmentCount = bu.mxinc;
          }
        }
        if (bu.ipycp) {
          isClientPortalRedirect = true;
        }
        if (bu.idagin) {
          isDisplayOnLoad = true;
        }
        if (bu.agins) {
          agentInstruction.push({
            client: bu.id,
            instruction: bu.agins,
          });
        }
      }
    }

    if (isDisplayOnLoad) {
      setIsEnable({ ...isEnable, isClientType: "BULB_ENABLE" });
    } else {
      setIsEnable({ ...isEnable, isClientType: "MINI_ENABLE" });
    }

    if (
      _.get(
        currentDefaultFormatDetails,
        "mini_miranda_settings.is_display_agent_instruction",
        false
      )
    ) {
      setIsEnable({ ...isEnable, isType: "BULB_ENABLE" });
    } else {
      setIsEnable({ ...isEnable, isType: "MINI_ENABLE" });
    }

    dispatch(
      maxValidationFields({
        minPayment,
        maxInstalmentCount,
        isClientPortalRedirect,
        agentInstruction,
        isDisplayOnLoad,
      })
    );

    return {
      minPayment,
      maxInstalmentCount,
      isClientPortalRedirect,
      agentInstruction,
      isDisplayOnLoad,
    };
  }, [getGroupingDataList?.length, customer.isFetching]);

  useEffect(() => {
    if (isDisplayOnLoad) {
      setClientInstrucionState(true);
      setAgentInstructionModalState(true);
    }
  }, [
    customerId,
    customer.isLoading,
    isDisplayOnLoad,
    agentInstruction.length,
  ]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      removeCustomerLockFn({
        organization: currentOrganization,
        clientId: customerId,
      }).catch();
      event.returnValue = ""; // Modern browsers may ignore this
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      removeCustomerLockFn({
        organization: currentOrganization,
        clientId: customerId,
      }).catch();
    };
  }, [customerId]);
  const getActionDetails = useMutation(
    async () =>
      await getCustomerActions({
        organization: currentOrganization,
        customerId,
      })
  );

  const {
    tableCurrentState,
    gridStructure,

    gridAndViewLoaded = false,
  } = useSelector((state) => state.customerSummary);
  let customerFilter = tableCurrentState.filters;
  let globalFilter = tableCurrentState.globalFilters;
  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };
  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };

  let NeedsActionFilter = tableCurrentState?.needs_action
    ? Object.keys(tableCurrentState?.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  // let customerStageFilters = {
  //   stage: tableCurrentState.customer_stage,
  // };

  // let FilteredCustomerStage = customerStageFilters.stage
  //   ? Object.keys(customerStageFilters.stage).filter(
  //       (i) => customerStageFilters.stage[i]
  //     )
  //   : [];

  let localPageSize = localStorage.getItem("customer_page_size");

  const [queryFilter, setQueryFilter] = useState({});

  const documentList = useQuery(
    [`${DOC_QUICK_VIEW_LIST}-${customerId}`],
    async () => {
      return await getDocumentData({
        organization: currentOrganization,
        customerId: customerId,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: customer.isSuccess,
    }
  );

  const {
    mutateAsync: fetchCustomerCampaign,
    isLoading: isCustomerCampaignLoading,
  } = useMutation(
    async (variables) => {
      return await nextCustomerFn({
        organization: currentOrganization,
        customer_id: customerId,
        ...variables,
      });
    },
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }
      },
      onSuccess: (response) => {
        worklistCount.refetch(); //VG is it required???
        if (_.get(response, "data.doc.customer_id", "")) {
          navigator(
            `/customers/${_.get(response, "data.doc.customer_id", "")}`
          ); // Redirect to new route
        }
      },
    }
  );

  const prevCustomer = useMutation(
    async (variables) => {
      return await prevCustomerFn({
        organization: currentOrganization,
        customer_id: customerId,

        ...variables,
      });
    },
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }
      },
      onSuccess: (response) => {
        worklistCount.refetch();
        if (_.get(response, "data.doc.customer_id", "")) {
          dispatch(setWorklistResponceData(_.get(response, "data.doc", {})));
          navigator(
            `/customers/${_.get(response, "data.doc.customer_id", "")}`
          ); // Redirect to new route
        }
      },
    }
  );

  let overAllFilters = {
    pageSize: localPageSize ? localPageSize : tableCurrentState.pageSize,
    customerFilter,
    filters: {
      ...globalFilter,
      ...appAccessRest,
      ...(NeedsActionFilter ? { needs_actions: [...NeedsActionFilter] } : []),
      ...(FilteredCustomerStage ? { stage: [...FilteredCustomerStage] } : []),
      search_value: queryFilter?.display_name,
    },
  };

  const { mutateAsync, isLoading } = useAssignWorkflowFn({ customerId });
  const unAssignRM = useUnassignRelmManger();

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  const timelineDownload = useMutation(
    (data) =>
      getTimelineDownload({
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {},
    }
  );

  const { counterValues, ...otherInfo } = useMemo(() => {
    let promise = {
      is_promise: false,
      is_promise_overdue: false,
      promise_in_days: 0,
      promises_amount: 0,
    };

    let dispute = {
      is_dispute: false,
      is_dispute_overdue: false,
      dispute_in_days: 0,
    };

    let counterValues = {
      weeklyCount: _.get(customer, "data.weekly_comm_count"),
      maxWeeklyCount: _.get(customer, "data.max_weekly_follow_up"),
      weeklyType: "",
      monthlyCount: _.get(customer, "data.monthly_comm_count"),
      maxMonthlyCount: _.get(customer, "data.max_monthly_follow_up"),
      monthlyType: "",
      callAttempt: _.get(customer, "data.call_attempt_count"),
      maxCallAttempt: _.get(customer, "data.mini_miranda.max_call_attempts"),
      callAttemptType: "",
      callRPC: _.get(customer, "data.call_rpc_count"),
      maxCallRPC: _.get(customer, "data.mini_miranda.max_call_rpc"),
      callRPCType: "",
    };

    const consentTillDate = _.get(customer, "data.consent_call_till");
    let consentTillDateDayDiff;

    if (consentTillDate) {
      consentTillDateDayDiff = moment
        .tz(currentDefaultFormatDetails.time_zone)
        .endOf("day")
        .diff(
          moment.tz(consentTillDate, currentDefaultFormatDetails.time_zone),
          "days"
        );
    }

    /* NOTE: Sets Type to every 3rd key based on prev 2 keys */
    Object.keys(counterValues).map((e, i, keys) => {
      if (!((i + 1) % 3)) {
        if (keys[i - 2] && keys[i - 1] && keys[i]) {
          const count = counterValues[keys[i - 2]];
          const maxCount = counterValues[keys[i - 1]];

          if (count && maxCount) {
            if (count === maxCount) {
              counterValues[keys[i]] = "info";
            } else if (count > maxCount) {
              counterValues[keys[i]] = "error";
            }
          }
        }
      }
    });

    let promises = _.get(customer, "data.promise_to_pay", [])
      .filter((e) => e.status === "IN_PROGRESS" || e.status === "IA")
      .sort((a, b) => a.promise_date - b.promise_date);
    const customerTz = moment.tz(_.get(customer, "data.timezone"));
    const customerStartOf = customerTz.startOf("day").valueOf();

    if (promises && promises[0]) {
      promise.is_promise = true;
      promise.promise_in_days = Math.abs(
        customerTz.startOf("day").diff(moment(promises[0].promise_date), "days")
      );

      promise.promises_amount =
        promises &&
        promises[0].promise_amount &&
        promises[0].promise_amount.value;

      let overdueAmount = 0;
      promises.forEach((p) => {
        if (p.promise_date < customerStartOf) {
          overdueAmount += p.promise_amount?.value || 0;
        }
      });
      if (overdueAmount > 0) {
        promise.is_promise_overdue = true;
        promise.promises_amount = overdueAmount;
      }
    }

    let disputes = _.get(customer, "data.disputes", [])
      .filter((e) => e.status !== "CLOSED")
      .sort((a, b) => a.review_date - b.review_date);

    if (disputes && disputes[0]) {
      dispute.is_dispute = true;
      dispute.dispute_in_days = Math.abs(
        customerTz.startOf("day").diff(moment(disputes[0].review_date), "days")
      );

      if (disputes[0].review_date < customerStartOf) {
        dispute.is_dispute_overdue = true;
      }
    }

    let alertNotes = _.get(customer, "data.alert_notes", []);

    return {
      counterValues: {
        ...counterValues,
        callBreached:
          _.get(customer, "data.mini_miranda") &&
          (counterValues.callAttemptType === "error" ||
            counterValues.callRPCType === "error") &&
          !(consentTillDate && consentTillDateDayDiff > 0),
        breached:
          _.get(customer, "data.mini_miranda") &&
          (counterValues.weeklyType === "error" ||
            counterValues.monthlyType === "error"),
        ...(consentTillDate ? { consentTillDate } : {}),
      },
      ...promise,
      ...dispute,
      alertNote: alertNotes && alertNotes[0] ? alertNotes[0] : null,
    };
  }, [customer?.data]);

  if (customer.isLoading) {
    return <Loader />;
  }

  function expandColse() {
    setIsCustomerExpand(!isCustomerExpand);
  }

  const isClientAccess =
    space && space?.role_permissions?.includes(CLIENT_PORTAL_ACCESS);

  let lastActionFinalValues = moment
    .utc(_.get(customer, "data.last_action_date"))
    .diff(moment().utc(), "days", true);

  lastActionFinalValues = Number(lastActionFinalValues).toFixed();

  let lastActionDaysLeftMessage = "";
  if (Math.abs(lastActionFinalValues) <= 0) {
    lastActionDaysLeftMessage = "(Today)";
  } else {
    lastActionDaysLeftMessage = `(${Math.abs(lastActionFinalValues)} days ago)`;
  }

  let RealtionManagerDate = moment
    .utc(_.get(customer, "data.rm_ass_at", ""))
    .diff(moment().utc(), "days", true);
  RealtionManagerDate = Number(RealtionManagerDate).toFixed();

  let realtionManagerDaysLeftMessage = "";
  if (Math.abs(RealtionManagerDate) <= 0) {
    realtionManagerDaysLeftMessage = "(Today)";
  } else {
    realtionManagerDaysLeftMessage = `(${Math.abs(
      RealtionManagerDate
    )} days ago)`;
  }

  const onSuccessRefetch = () => {
    queryClient.invalidateQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
    queryClient.invalidateQueries([`${CO_INVOICES_}${customerId}`]);
    queryClient.invalidateQueries([`${CO_PAYMENT_}${customerId}`]);
    queryClient.invalidateQueries([`${CO_TIMELINE_}${customerId}`]);
    if (_.get(customer, "data.gid", "").length) {
      queryClient.invalidateQueries([
        `${GET_GROUPING_LIST_DATA}-${customerId}`,
      ]);
    }
    toast.success("Data Refresh Successful");
  };

  let paymentRole = [VIEW_PAYMENT, ADD_PAYMENTS, EDIT_PAYMENTS];
  let paymentRoleExist = paymentRole
    .map((item) => space?.role_permissions.includes(item))
    .some(Boolean);

  return (
    <div className="drawer_body_container">
      <div style={{ display: "flex" }}>
        <SideBar_V2 />
        {consumerStatusConfig && !consumerStatusConfig.customerOverview ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <div>
                <Typography type="h2">
                  Access is Restricted for the Consumer Status -{" "}
                  {format.rd({
                    id: consumerStatusConfig.customerStatus,
                    name: "customer_status_list",
                  })}
                </Typography>
              </div>
              <div style={{ marginTop: "10px" }}>
                <TextButton
                  type="button"
                  kind={KIND.primary}
                  size={SIZE.default}
                  style={{ padding: "5px 20px" }}
                  onClick={() => {
                    navigator(-1);
                  }}
                >
                  Back
                </TextButton>
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div
              className={
                isCustomerExpand ? "co_container--scroll" : "co-container"
              }
            >
              <div
                className={
                  isCustomerExpand
                    ? "co_expand_container"
                    : "co_expand_container--modifier"
                }
              >
                {isCustomerExpand ? (
                  <CustomerListCard
                    // data={_.get(data, "data.docs", [])}
                    customerId={customerId}
                    queryFilter={queryFilter}
                    setQueryFilter={setQueryFilter}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div
                className={
                  isCustomerExpand
                    ? "co_sub_container--expand"
                    : "co_sub_container"
                }
              >
                <div className="co-header">
                  <div className="co-header_info">
                    <Icon
                      icon={isCustomerExpand ? "expand_left" : "expand_right"}
                      isPressable
                      onClick={() => expandColse()}
                    />

                    <CustomerNameEdit
                      name={_.get(customer, "data.display_name", "")}
                    />
                    {/* <Icon icon="download" isPressable /> */}

                    <div>
                      <Icon
                        icon="dot"
                        size={6}
                        className={"headline-dot-icon"}
                      />
                    </div>
                    {_.get(customer, "data.customer_portal_url", "") ? (
                      <>
                        {!isClientAccess ? (
                          <>
                            <Typography
                              type="a"
                              onClick={() =>
                                window.open(
                                  `${_.get(
                                    customer,
                                    "data.customer_portal_url",
                                    ""
                                  )}?is_internal_call=true`,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                            >
                              Portal Link
                            </Typography>
                            <CopyToClipboard
                              text={`${_.get(
                                customer,
                                "data.customer_portal_url",
                                ""
                              )}?is_internal_call=true`}
                            >
                              <Icon
                                icon="copy"
                                isPressable
                                onClick={(e) => {
                                  e.preventDefault();
                                  toast.success("Linked Copied Successfully");
                                }}
                              />
                            </CopyToClipboard>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <div
                      onClick={async () => {
                        await timelineDownload.mutateAsync({
                          customerId: customerId,
                          organization: currentOrganization,
                        });
                      }}
                    >
                      <Icon icon="download" isPressable />
                    </div>
                    <RBACWrapper
                      role={CUSTOMER_PORTAL_SETTINGS}
                      type="PERMISSION"
                    >
                      <div style={{ position: "relative" }}>
                        <Icon
                          icon="settings"
                          isPressable
                          onClick={() => {
                            setDrawer(PORTAL_SETTINGS_DRAWER);
                          }}
                        />

                        {_.get(
                          customer,
                          "data.is_customer_portal_customized",
                          false
                        ) ? (
                          <div
                            style={{
                              position: "absolute",
                              width: "5px",
                              height: "5px",
                              borderRadius: "100%",
                              backgroundColor: "#516beb",
                              top: "0%",
                              right: "0%",
                            }}
                          ></div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </RBACWrapper>
                    <Icon
                      icon="referesh"
                      size={16}
                      isPressable
                      onClick={() => {
                        onSuccessRefetch();
                      }}
                    />
                    {/* {_.get(customer, "data.vitil", "") > new Date() ? ( */}

                    {/* ) : (
                      <></>
                    )} */}

                    <div>
                      {_.get(customer, "data.gs") === "RVW" ||
                      _.get(customer, "data.groupingCustomerData", []).length >
                        0 ? (
                        <>
                          <Icon
                            icon="dot"
                            size={6}
                            className={"headline-dot-icon"}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* dummy grouping select is removed 03-05-24 */}
                    {customer &&
                    customer?.data &&
                    customer?.data?.gs === "COM" ? (
                      <>
                        {!isClientAccess && (
                          <GroupSelect
                            getGroupingDataList={getGroupingDataList}
                          />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {customer && customer?.data && customer?.data?.grc > 0 ? (
                      <ReviewButton />
                    ) : (
                      <></>
                    )}

                    {_.get(customer, "data.vitil", "") > new Date() &&
                    _.get(customer, "data.viusr", "") !==
                      _.get(userInfo, "_id", "") ? (
                      <StatefulTooltip
                        placement={PLACEMENT.bottomLeft}
                        showArrow
                        overrides={{
                          Arrow: {
                            style: ({ $theme }) => ({
                              outline: `#16164B solid`,
                              backgroundColor: "#16164B",
                            }),
                          },
                          Inner: {
                            style: ({ $theme }) => ({
                              outline: `#16164B solid`,
                              backgroundColor: "#16164B",
                            }),
                          },
                        }}
                        content={() => (
                          <div className="customer-header-locked_tooltip">
                            <Icon icon="lockedPoper" size={22} />
                            <Typography
                              type="p"
                              subType="regular"
                              style={{ color: "white" }}
                            >
                              Consumer Locked by:{" "}
                              {format.rd({
                                id: _.get(customer, "data.viusr", ""),
                                name: "users",
                              })}
                            </Typography>
                          </div>
                        )}
                        returnFocus
                        autoFocus
                      >
                        <Icon
                          icon="lockedIcon"
                          size={22}
                          isPressable
                          color="#FD372A"
                        />
                      </StatefulTooltip>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="co-header_action">
                    {/** TODO **/}
                    {_.get(customer, "data.is_hold", false) ? (
                      <RBACWrapper role={CUSTOMER_RESUME} type="PERMISSION">
                        <TextButton
                          size="mini"
                          onClick={() => {
                            handleModal({
                              formContent: ({ handleModal }) => {
                                return (
                                  <ResumeAction
                                    handleModal={handleModal}
                                    customerId={customerId}
                                    entity={"customer"}
                                  />
                                );
                              },
                              cancelCallback: () => {},
                              isChildren: true,
                            });
                          }}
                        >
                          Resume Action (
                          {_.get(customer, "data.hold_reason", "")
                            ? format.rd({
                                id: _.get(customer, "data.hold_reason", ""),
                                name: "hold_action_reason",
                              })
                            : ""}
                          )
                        </TextButton>
                      </RBACWrapper>
                    ) : (
                      <></>
                    )}
                    {_.get(customer, "data.active_next_action_type") &&
                    !_.get(customer, "data.is_hold", false) ? (
                      <>
                        <div className="co-header_action_info">
                          <Typography
                            type="p"
                            subType="regular"
                            className="text-secondary"
                          >
                            Next Action:
                          </Typography>
                          <Typography type="p" subType="regular">
                            {_.get(
                              customer,
                              "data.active_next_action_name",
                              ""
                            )}{" "}
                            <NextActionStatus
                              date={_.get(
                                customer,
                                "data.active_next_action_date",
                                0
                              )}
                            />
                          </Typography>
                        </div>
                        <div>
                          <RBACWrapper
                            role={CUSTOMER_WF_ACTION}
                            type="PERMISSION"
                          >
                            <TextButton
                              size="mini"
                              onClick={async () => {
                                let type = {
                                  sms: SMS_DRAWER,
                                  email: EMAIL_DRAWER,
                                  call: CALL_DRAWER,
                                  review_payment_plan: REVIEW_PROMISE,
                                  review_promise: REVIEW_PROMISE,
                                  review_dispute: DISPUTE_DRAWER,
                                  FIELD_VISIT: FIELD_VISIT_DRAWER,
                                  letter: LEGAL_LETTER_DRAWER,
                                  whatsapp: WHATSAPP_DRAWER,
                                };

                                const actionType = _.get(
                                  customer,
                                  "data.active_next_action_type",
                                  ""
                                );
                                const drawerType = type[actionType];

                                if (
                                  drawerType &&
                                  ![
                                    "review_dispute",
                                    "review_promise",
                                    "review_payment_plan",
                                    "review_notes",
                                  ].includes(actionType) &&
                                  _.get(customer, "data.workflow.name")
                                ) {
                                  const data =
                                    await getActionDetails.mutateAsync();

                                  const actions = _.get(
                                    data,
                                    "data.doc.customer_actions",
                                    []
                                  );
                                  const activeAction = actions.find(
                                    (e) => e.action_status === "ACTIVE"
                                  );

                                  let recipientTypes = [];

                                  if (activeAction) {
                                    recipientTypes = _.get(
                                      activeAction,
                                      "recipient_type",
                                      ["primary_contact"]
                                    );

                                    if (recipientTypes.length === 0) {
                                      recipientTypes = ["primary_contact"];
                                    }
                                  }

                                  setDrawer(drawerType, {
                                    isWorkflow: true,
                                    recipientTypes,
                                    content: _.get(
                                      data,
                                      "data.doc.templateContent.value",
                                      ""
                                    ),
                                    whatsappTemCon: _.get(
                                      data,
                                      "data.doc.templateContent",
                                      ""
                                    ),
                                    actions: _.get(
                                      data,
                                      "data.doc.customer_actions",
                                      []
                                    ),
                                    templateAttachments: _.get(
                                      data,
                                      "data.doc.template_attachments",
                                      []
                                    ),
                                    activeAction,
                                    name: _.get(
                                      data,
                                      "data.doc.workflow.name",
                                      ""
                                    ),
                                    ...(actionType === "email"
                                      ? {
                                          subject: _.get(
                                            data,
                                            "data.doc.templateContent.subject",
                                            ""
                                          ),
                                        }
                                      : {}),
                                  });
                                } else if (drawerType) {
                                  setDrawer(drawerType);
                                }
                                if (actionType === "review_notes") {
                                  setDrawer(NOTE_LIST_DRAWER);
                                }
                              }}
                              isLoading={getActionDetails.isLoading}
                              disabled={getActionDetails.isLoading}
                            >
                              Capture
                            </TextButton>
                          </RBACWrapper>
                        </div>
                        {![
                          "review_dispute",
                          "review_promise",
                          "review_payment_plan",
                          "review_notes",
                        ].includes(
                          _.get(customer, "data.active_next_action_type", "")
                        ) && _.get(customer, "data.workflow.name") ? (
                          <RBACWrapper
                            role={CUSTOMER_WF_SKIP}
                            type="PERMISSION"
                          >
                            <Icon
                              icon="skip_action"
                              className="mx-icon--path-fill-error"
                              isPressable
                              size={18}
                              onClick={async () => {
                                handleModal({
                                  formContent: ({ handleModal }) => {
                                    return (
                                      <SkipAction
                                        handleModal={handleModal}
                                        customerId={customerId}
                                        entity={"customer"}
                                      />
                                    );
                                  },
                                  cancelCallback: () => {},
                                  isChildren: true,
                                });
                              }}
                            />
                          </RBACWrapper>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {_.get(customer, "data.mini_miranda.agent_instruction", "")
                      .length > 0 ||
                    _.get(customer, "data.agentInstruction", []).length > 0 ||
                    _.get(customer, "data.mini_miranda_id") ? (
                      <Icon
                        icon="light_bulb"
                        size={22}
                        isPressable
                        onClick={() => {
                          setIsInternal(false);
                          setAgentInstructionModalState(true);
                          setClientInstrucionState(true);
                          // setIsEnable("BULB_ENABLE");
                          setIsEnable({
                            ...isEnable,
                            isType: "BULB_ENABLE",
                            isClientType: "BULB_ENABLE",
                            isCount: isEnable.isCount + 1,
                          });
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div
                  className={
                    isCustomerExpand ? "co_section--modifier" : "co-section"
                  }
                >
                  <div className="co-section_main">
                    {/* Overview Card */}
                    <div className="co-info-card_wrapper --co_more_info_modifier">
                      <div className="co-info-card_main">
                        <div className="co-info-card">
                          <CardRow
                            label={`Amount Owed (${_.get(
                              customer,
                              "data.p_cnt",
                              0
                            )})`}
                            value={format.currency({
                              amount: _.get(customer, "data.cs_p_amt", 0),
                            })}
                          />
                          <CardRow
                            label={`Fee (${_.get(customer, "data.fec", 0)})`}
                            value={format.currency({
                              amount: _.get(customer, "data.fea.value", 0),
                            })}
                          />
                          <CardRow
                            label={`Interest (${_.get(
                              customer,
                              "data.int_cnt",
                              0
                            )})`}
                            value={format.currency({
                              amount: _.get(customer, "data.cs_int_amt", 0),
                            })}
                          />
                          <CardRow
                            label={`Payments (${_.get(
                              customer,
                              "data.pmc",
                              0
                            )}) & Adjustments`}
                            iconType={"info_icon"}
                            value={format.currency({
                              amount:
                                Number(_.get(customer, "data.cs_adj_amt", 0)) -
                                Number(
                                  _.get(
                                    customer,
                                    "data.cs_total_payment_amount.value",
                                    0
                                  )
                                ),
                            })}
                            paymentAmt={format.currency({
                              amount: _.get(
                                customer,
                                "data.cs_total_payment_amount.value",
                                0
                              ),
                            })}
                            adjustmentAmount={format.currency({
                              amount: _.get(customer, "data.cs_adj_amt", 0),
                            })}
                          />
                          <CardRow
                            label={`Outstanding (${_.get(
                              customer,
                              "data.total_outstanding_invoice_count",
                              0
                            )})`}
                            value={format.currency({
                              amount: _.get(
                                customer,
                                "data.total_outstanding_invoice_amount.value",
                                0
                              ),
                            })}
                            typoSubType="bold"
                            type="error"
                          />
                        </div>
                        <div className="co-info-card">
                          {currentDefaultFormatDetails.cusovconf &&
                          currentDefaultFormatDetails.cusovconf.length > 0 ? (
                            currentDefaultFormatDetails?.cusovconf?.map(
                              (e, i) => {
                                return (
                                  <HeaderActionConfig
                                    {...e}
                                    customer={customer}
                                    lastActionDaysLeftMessage={
                                      lastActionDaysLeftMessage
                                    }
                                    key={i}
                                  />
                                );
                              }
                            )
                          ) : (
                            <>
                              <CardRow
                                label={`Last Action ${
                                  _.get(customer, "data.last_action_date")
                                    ? lastActionDaysLeftMessage
                                    : ""
                                }`}
                                value={_.get(
                                  customer,
                                  "data.last_action_name",
                                  ""
                                )}
                              />
                              <CardRow
                                label={"Last Consumer Action"}
                                value={
                                  <RDText
                                    rdName="customer_last_action"
                                    id={_.get(
                                      customer,
                                      "data.last_customer_action",
                                      ""
                                    )}
                                  />
                                }
                              />
                              <CardRow
                                label={"D.O.B"}
                                value={
                                  _.get(customer, "data.dob", "")
                                    ? moment
                                        .utc(_.get(customer, "data.dob", ""))
                                        ?.tz(
                                          currentDefaultFormatDetails?.time_zone
                                        )
                                        ?.format(
                                          currentDefaultFormatDetails?.date_format
                                        )
                                    : ""
                                }
                              />{" "}
                              {/* TODO */}
                              <CardRow
                                label={"CUS ID"}
                                value={_.get(customer, "data.customer_id")}
                              />
                              <CardRow
                                label="Action Stage"
                                value={
                                  <RDText
                                    rdName="customer_stage"
                                    id={_.get(
                                      customer,
                                      "data.customer_auto_status",
                                      ""
                                    )}
                                  />
                                }
                              />
                            </>
                          )}
                        </div>
                        <div className="co-info-card">
                          <CardRow
                            label={`Relation Manager ${
                              _.get(customer, "data.rm_ass_at", "")
                                ? realtionManagerDaysLeftMessage
                                : ""
                            }`}
                            value={
                              <RDText
                                rdName="users"
                                id={_.get(customer, "data.rel_manager", "")}
                              />
                            }
                            onEdit={() => {
                              handleModal({
                                title: "",
                                content: "",
                                successCallback: () => {
                                  // deleteTemplate.mutate(_id);
                                },
                                formContent: ({ handleModal }) => {
                                  return (
                                    <AssignRelationManager
                                      handleModal={handleModal}
                                      customerId={customerId}
                                      relManager={_.get(
                                        customer,
                                        "data.rel_manager",
                                        ""
                                      )}
                                    />
                                  );
                                },
                                cancelCallback: () => {},
                                isChildren: true,
                                image: workflowModal,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                            removable={
                              _.get(customer, "data.rel_manager", "")
                                ? true
                                : false
                            }
                            onRemove={() => {
                              handleModal({
                                title:
                                  "You are about to Unassign Relation Manager",
                                content:
                                  "This will Unassign Relation manager. Are you sure?",
                                successCallback: async () => {
                                  await unAssignRM.mutateAsync({
                                    customerId: customerId,
                                    organization: currentOrganization,
                                  });
                                },
                                formContent: () => {
                                  return <></>;
                                },
                                cancelCallback: () => {},
                                isChildren: false,
                                image: WarningImage,
                                buttonText: "Unassign",
                                closeButtonText: "Cancel",
                                isCloseAble: false,
                              });
                            }}
                            removePermission={UNASSIGN_RM}
                            editPermission={ASSIGN_RM}
                            editable
                          />

                          {/* TODO */}
                          <CardRow
                            label="Workflow"
                            value={_.get(customer, "data.workflow.name", "")}
                            editable
                            onEdit={() => dispatch(workflowAddModal(true))}
                            editPermission={ASSIGN_WORKFLOW}
                            removable={
                              _.get(customer, "data.workflow.name", "")
                                ? true
                                : false
                            }
                            onRemove={() => {
                              handleModal({
                                title: "You are about to Unassign a Workflow",
                                content:
                                  "This will Unassign your Workflow. Are you sure?",
                                successCallback: async () => {
                                  await mutateAsync({
                                    customerId: customerId,
                                    organization: currentOrganization,
                                    action: "UNASSIGN",
                                  });
                                },
                                formContent: () => {
                                  return <></>;
                                },
                                cancelCallback: () => {},
                                isChildren: false,
                                image: WarningImage,
                                buttonText: "Unassign",
                                closeButtonText: "Cancel",
                                isCloseAble: false,
                              });
                            }}
                            removePermission={ASSIGN_WORKFLOW}
                          />
                          <CardRow
                            label="Work Queue"
                            value={
                              <RDText
                                rdName="work_queue_list"
                                id={_.get(customer, "data.wq", "")}
                              />
                            }
                            onEdit={() => {
                              handleModal({
                                title: "",
                                content: "",
                                successCallback: () => {
                                  // deleteTemplate.mutate(_id);
                                },
                                formContent: ({ handleModal }) => {
                                  return (
                                    <WorkQueueForm
                                      handleModal={handleModal}
                                      customerId={customerId}
                                      unAssignType={
                                        !_.get(customer, "data.wq", "")
                                      }
                                      workQueue={_.get(customer, "data.wq", "")}
                                    />
                                  );
                                },
                                cancelCallback: () => {},
                                isChildren: true,
                                image: workflowModal,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                            editPermission={ASSIGN_WORK_QUEUE}
                            editable
                          />
                          {/* TODO */}
                          <CardRow
                            label="Status"
                            value={_.get(customer, "data.customer_status", "")}
                            editable={
                              consumerStatusConfig &&
                              consumerStatusConfig.isStatusModify
                                ? true
                                : false
                            }
                            onEdit={() => {
                              handleModal({
                                title: "",
                                content: "",
                                successCallback: () => {
                                  // deleteTemplate.mutate(_id);
                                },
                                formContent: ({ handleModal }) => {
                                  return (
                                    <CustomerStatus
                                      handleModal={handleModal}
                                      customerId={customerId}
                                      customerStatus={_.get(
                                        customer,
                                        "data.customer_status"
                                      )}
                                    />
                                  );
                                },
                                cancelCallback: () => {},
                                isChildren: true,
                                image: workflowModal,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                            editPermission={ASSIGN_STATUS}
                          />
                          <CardRow
                            label="Dispute"
                            value={
                              otherInfo.is_dispute
                                ? `${
                                    otherInfo.dispute_in_days !== 0
                                      ? `${
                                          otherInfo.is_dispute_overdue
                                            ? "Overdue"
                                            : "In"
                                        } ${otherInfo.dispute_in_days} Days`
                                      : "Due Today"
                                  }`
                                : "-"
                            }
                            type={
                              otherInfo.dispute_in_days !== 0 &&
                              otherInfo.is_dispute_overdue
                                ? "error"
                                : ""
                            }
                          />

                          <CardRow
                            label="Promise"
                            prefixValue={
                              otherInfo.is_promise
                                ? `${
                                    otherInfo.promise_in_days !== 0
                                      ? `${
                                          otherInfo.is_promise_overdue
                                            ? `by`
                                            : `in`
                                        } ${otherInfo.promise_in_days} Days`
                                      : "Due Today"
                                  }`
                                : ""
                            }
                            value={
                              otherInfo.is_promise
                                ? `${
                                    otherInfo.promise_in_days !== 0
                                      ? `${
                                          otherInfo.promises_amount
                                            ? currencyList[
                                                currentDefaultFormatDetails
                                                  ?.default_currency
                                              ]
                                            : ""
                                        }${
                                          otherInfo.promises_amount
                                            ? format.currency({
                                                amount:
                                                  otherInfo.promises_amount,
                                              })
                                            : ""
                                        }`
                                      : otherInfo.promises_amount
                                      ? `${
                                          currencyList[
                                            currentDefaultFormatDetails
                                              ?.default_currency
                                          ]
                                        } ${
                                          otherInfo.promises_amount
                                            ? format.currency({
                                                amount:
                                                  otherInfo.promises_amount,
                                              })
                                            : ""
                                        }`
                                      : ""
                                  }`
                                : ""
                            }
                            // type={otherInfo.is_promise_overdue ? "error" : ""}
                          />
                        </div>
                      </div>
                      {/* Overview Card Bottom */}
                      <StatefulPopover
                        content={() =>
                          _.get(customer, "data.mini_miranda_id") &&
                          (counterValues.maxWeeklyCount > 0 ||
                            counterValues.maxMonthlyCount > 0 ||
                            counterValues.callAttempt > 0 ||
                            counterValues.callRPC > 0) ? (
                            <div className="co-info-card_footer_popover">
                              {_.get(customer, "data.mini_miranda_id") &&
                                counterValues.maxWeeklyCount > 0 && (
                                  <Typography subType="regular">
                                    <span style={{ color: "#787878" }}>
                                      W#: Weekly Contact Count -{" "}
                                    </span>
                                    <span
                                      className={`text-${counterValues.weeklyType}`}
                                    >
                                      {_.get(counterValues, "weeklyCount", "")}
                                      {_.get(
                                        counterValues,
                                        "maxWeeklyCount",
                                        ""
                                      )
                                        ? "/"
                                        : ""}
                                      {_.get(
                                        counterValues,
                                        "maxWeeklyCount",
                                        ""
                                      )}
                                    </span>
                                  </Typography>
                                )}
                              {_.get(customer, "data.mini_miranda_id", "") &&
                                counterValues.maxMonthlyCount > 0 && (
                                  <Typography subType="regular">
                                    <span style={{ color: "#787878" }}>
                                      M#: Monthly Contact Count -{" "}
                                    </span>
                                    <span
                                      className={`text-${
                                        counterValues &&
                                        counterValues?.monthlyType
                                      }`}
                                    >
                                      {_.get(counterValues, "monthlyCount", "")}{" "}
                                      {_.get(
                                        counterValues,
                                        "maxMonthlyCount",
                                        ""
                                      )
                                        ? "/"
                                        : ""}
                                      {_.get(
                                        counterValues,
                                        "maxMonthlyCount",
                                        ""
                                      )}
                                    </span>
                                  </Typography>
                                )}
                              {_.get(customer, "data.mini_miranda_id") &&
                                counterValues.maxCallAttempt > 0 && (
                                  <Typography subType="regular">
                                    <span style={{ color: "#787878" }}>
                                      CA#: In Windows Call Attempts -{" "}
                                    </span>
                                    <span
                                      className={`text-${counterValues.callAttemptType}`}
                                    >
                                      {_.get(counterValues, "callAttempt", "")}
                                      {_.get(
                                        counterValues,
                                        "maxCallAttempt",
                                        ""
                                      )
                                        ? "/"
                                        : ""}
                                      {_.get(
                                        counterValues,
                                        "maxCallAttempt",
                                        ""
                                      )}
                                    </span>
                                  </Typography>
                                )}
                              {_.get(customer, "data.mini_miranda_id") &&
                                counterValues.maxCallRPC > 0 && (
                                  <Typography subType="regular">
                                    <span style={{ color: "#787878" }}>
                                      C RPC#: Call Right Party Contact -{" "}
                                    </span>
                                    <span
                                      className={`text-${counterValues.callRPCType}`}
                                    >
                                      {_.get(counterValues, "callRPC", "")}
                                      {_.get(counterValues, "maxCallRPC", "")
                                        ? "/"
                                        : ""}
                                      {_.get(counterValues, "maxCallRPC", "")}
                                    </span>
                                  </Typography>
                                )}
                            </div>
                          ) : (
                            <></>
                          )
                        }
                        triggerType={TRIGGER_TYPE.hover}
                        returnFocus
                        autoFocus
                      >
                        <div className="co-info-card_footer">
                          <FooterContent
                            label="Cur:"
                            value={_.get(customer, "data.default_currency")}
                          />
                          {_.get(customer, "data.mini_miranda_id", "") &&
                          _.get(customer, "data.max_weekly_follow_up", "") ? (
                            <FooterContent
                              label="W#:"
                              value={`${_.get(
                                counterValues,
                                "weeklyCount",
                                ""
                              )} ${
                                _.get(counterValues, "maxWeeklyCount", "")
                                  ? "/"
                                  : ""
                              } ${_.get(counterValues, "maxWeeklyCount", "")}`}
                              type={counterValues && counterValues?.weeklyType}
                            />
                          ) : (
                            <></>
                          )}

                          {_.get(customer, "data.mini_miranda_id") &&
                          _.get(customer, "data.max_monthly_follow_up") ? (
                            <FooterContent
                              label="M#:"
                              value={`${_.get(
                                counterValues,
                                "monthlyCount",
                                ""
                              )} ${
                                _.get(counterValues, "maxMonthlyCount", "")
                                  ? "/"
                                  : ""
                              } ${_.get(counterValues, "maxMonthlyCount", "")}`}
                              type={counterValues.monthlyType}
                            />
                          ) : (
                            <></>
                          )}

                          {_.get(
                            customer,
                            "data.mini_miranda.max_call_attempts"
                          ) ? (
                            <FooterContent
                              label="CA#:"
                              value={`${
                                counterValues?.callAttempt
                                  ? counterValues.callAttempt
                                  : 0
                              } / ${
                                counterValues?.maxCallAttempt
                                  ? counterValues?.maxCallAttempt
                                  : 0
                              }`}
                              type={counterValues.callAttemptType}
                            />
                          ) : (
                            <></>
                          )}

                          {_.get(customer, "data.mini_miranda.max_call_rpc") ? (
                            <FooterContent
                              label="C RPC#:"
                              value={`${
                                counterValues?.callRPC
                                  ? counterValues?.callRPC
                                  : 0
                              } / ${
                                counterValues?.maxCallRPC
                                  ? counterValues?.maxCallRPC
                                  : 0
                              }`}
                              type={counterValues.callRPCType}
                            />
                          ) : (
                            <></>
                          )}

                          {counterValues?.maxWeeklyCount ||
                          counterValues?.maxMonthlyCount ||
                          counterValues?.callAttempt ||
                          counterValues?.callRPC ? (
                            <>
                              <Icon
                                icon="dot"
                                size={6}
                                className={"headline-dot-icon"}
                              />
                            </>
                          ) : (
                            <></>
                          )}

                          <FooterContent
                            label="DSC:"
                            value={_.get(
                              customer,
                              "data.days_since_created",
                              ""
                            )}
                          />

                          {_.get(customer, "data.is_hold", "") ? (
                            <>
                              <Icon
                                icon="dot"
                                size={6}
                                className={"headline-dot-icon"}
                              />
                              <FooterContent
                                label="Pause Reason:"
                                value={
                                  <RDText
                                    rdName="hold_action_reason"
                                    id={_.get(customer, "data.hold_reason", "")}
                                  />
                                }
                                type="error"
                              />
                            </>
                          ) : (
                            <></>
                          )}

                          {counterValues && counterValues?.consentTillDate ? (
                            <FooterContent
                              label="Consent Till:"
                              value={
                                <DateComponent
                                  type={DATE_ORG}
                                  value={
                                    counterValues &&
                                    counterValues?.consentTillDate
                                  }
                                />
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </StatefulPopover>
                      {/* Overview Card Bottom */}
                      <Typography
                        className="co_more_info"
                        onClick={() => {
                          setDrawer(CUSTOM_FIELDS_DRAWER);
                        }}
                      >
                        More Info
                      </Typography>
                    </div>
                    {/* Overview Card */}

                    {/* Invoice List */}
                    <div className="co-invoice-section-wrapper">
                      <COInvoiceTable />
                      <div
                        className="co-quick-action-container"
                        style={{
                          maxHeight: disableTimeline ? "350px" : "500px",
                        }}
                      >
                        {[
                          {
                            icon: "call",
                            onClick: () => {
                              setDrawer(CALL_DRAWER);
                            },
                            permission: CUSTOMER_MANUAL_CALL,
                            error: [
                              counterValues.breached,
                              counterValues.callBreached,
                            ],
                            isCustomerAction:
                              consumerStatusConfig.customerAction,
                          },
                          {
                            icon: "email",
                            onClick: () => {
                              setDrawer(EMAIL_DRAWER);
                            },
                            permission: CUSTOMER_MANUAL_EMAIL,
                            error: [counterValues.breached],
                            isCustomerAction:
                              consumerStatusConfig.customerAction,
                          },
                          {
                            name: "SMS",
                            icon: "sms",
                            onClick: () => {
                              setDrawer(SMS_DRAWER);
                            },
                            permission: CUSTOMER_SMS,
                            error: [counterValues.breached],
                            configured: [
                              currentDefaultFormatDetails.is_sms_configured,
                              //is_whatsapp_configured
                            ],
                            isCustomerAction:
                              consumerStatusConfig.customerAction,
                          },
                          {
                            icon: "field_visit",
                            onClick: () => {
                              setDrawer(FIELD_VISIT_DRAWER);
                            },
                            permission: CUSTOMER_FIELD_VISIT,
                            isCustomerAction:
                              consumerStatusConfig.customerAction,
                          },
                          {
                            icon: "dispute",
                            onClick: () => {
                              setDrawer(DISPUTE_DRAWER);
                            },
                            permission: CUSTOMER_DISPUTE,
                            isCustomerAction:
                              consumerStatusConfig.customerAction,
                          },
                          {
                            icon: "eSign",
                            onClick: () => {
                              setDrawer(ESIGN_ACTION_DRAWER);
                            },
                            permission: ESIGNPERMISSIONS,
                            isCustomerAction:
                              consumerStatusConfig.customerAction,
                          },
                          {
                            icon: "request_info",
                            onClick: () => {
                              setDrawer(
                                REQUEST_INFORMATION,
                                _.get(customer, "data.business_unit", "")
                              );
                            },
                            permission: CUSTOMER_REQUEST_INFORMATION,
                            isCustomerAction:
                              consumerStatusConfig.customerAction,
                          },
                          ...(_.get(customer, "data.is_quick_action", "")
                            ? [
                                {
                                  icon: "hold_action",
                                  onClick: () => {
                                    handleModal({
                                      formContent: ({ handleModal }) => {
                                        return (
                                          <PauseAction
                                            handleModal={handleModal}
                                            customerId={customerId}
                                            entity={"customer"}
                                          />
                                        );
                                      },
                                      cancelCallback: () => {},
                                      isChildren: true,
                                    });
                                  },
                                  permission: CUSTOMER_PAUSE,
                                  isCustomerAction:
                                    consumerStatusConfig.customerAction,
                                },
                              ]
                            : [
                                {
                                  icon: "resume_action",
                                  onClick: () => {
                                    handleModal({
                                      formContent: ({ handleModal }) => {
                                        return (
                                          <ResumeAction
                                            handleModal={handleModal}
                                            customerId={customerId}
                                            entity={"customer"}
                                          />
                                        );
                                      },
                                      cancelCallback: () => {},
                                      isChildren: true,
                                    });
                                  },
                                  permission: CUSTOMER_RESUME,
                                  isCustomerAction:
                                    consumerStatusConfig?.customerAction,
                                },
                              ]),
                          {
                            icon: "generic_action",
                            onClick: () => {
                              setDrawer(GENERIC_ACTION_DRAWER);
                            },
                            permission: CUSTOMER_GENERIC_ACTION,
                            isCustomerAction:
                              consumerStatusConfig?.customerAction,
                          },
                          {
                            icon: "document",
                            onClick: () => {
                              setDrawer(DMS_DRAWER);
                            },
                            permission: VIEW_DOWNLOAD_DOCUMENTS,
                            isCustomerAction: true,
                          },
                          ...(!_.get(customer, "data.workflow.name")
                            ? [
                                {
                                  icon: "next_action",
                                  onClick: () => {
                                    setDrawer(NEXT_ACTION_DRAWER);
                                  },
                                  permission: CUSTOMER_NEXT_ACTION,
                                  isCustomerAction:
                                    consumerStatusConfig?.customerAction,
                                },
                              ]
                            : []),
                          {
                            icon: "review_promise",
                            onClick: () => {
                              setDrawer(REVIEW_PROMISE);
                            },
                            permission: CUSTOMER_PROMISE_REVIEW,
                            isCustomerAction:
                              consumerStatusConfig?.customerAction,
                          },
                          {
                            icon: "letter",
                            onClick: () => {
                              setDrawer(LEGAL_LETTER_DRAWER);
                            },
                            permission: CUSTOMER_LETTER,
                            isCustomerAction:
                              consumerStatusConfig?.customerAction,
                            // permission: CUSTOMER_LETTER,
                          },
                          {
                            icon: "legal_action",
                            onClick: () => {
                              setDrawer(LEGAL_ACTION_DRAWER);
                            },
                            permission: CONSUMER_LEGAL_ACTION,
                            isCustomerAction:
                              consumerStatusConfig?.customerAction,
                          },
                          {
                            icon: "letter_status",
                            onClick: () => {
                              setDrawer(LETTER_STATUS);
                            },
                            permission: CUSTOMER_LETTER_STATUS,
                            isCustomerAction:
                              consumerStatusConfig?.customerAction,
                          },
                          {
                            icon: "bankruptcy",
                            onClick: () => {
                              setDrawer(BANKRUPTCY_FORM);
                            },
                            permission: BANKRUCPTCY_STATUS,
                            isCustomerAction:
                              consumerStatusConfig?.customerAction,
                          },
                          {
                            icon: "manual_group",
                            onClick: () => {
                              setDrawer(MANUAL_GROUPING);
                            },
                            permission: CONSUMER_MANUAL_GROUPING,
                            isCustomerAction:
                              consumerStatusConfig?.customerAction,
                          },
                          {
                            name: "WhatsApp",
                            icon: "whatsapp",
                            onClick: () => {
                              setDrawer(WHATSAPP_DRAWER);
                            },
                            permission: CUSTOMER_WHATSAPP,
                            configured: [
                              currentDefaultFormatDetails?.is_whatsapp_configured,
                            ],
                            isCustomerAction:
                              consumerStatusConfig?.customerAction,
                          },
                          {
                            name: "Configure Credit Reports",
                            icon: "credit_report",
                            onClick: () => {
                              setDrawer(CREDIT_REPORT);
                            },
                            permission: ADD_CREDIT_REPORT,

                            isCustomerAction:
                              consumerStatusConfig?.customerAction,
                          },
                        ].map((props) => {
                          const { onClick = () => {} } = props;

                          const isCustomerAction =
                            props && props?.isCustomerAction;

                          const isError =
                            props.error &&
                            Array.isArray(props.error) &&
                            props.error.some((e) => e);

                          const configurable =
                            props.configured &&
                            Array.isArray(props.configured) &&
                            props.configured.some((e) => !e);

                          const isConfigured =
                            configurable && props.configured.some((e) => e);

                          return (
                            isCustomerAction && (
                              <div
                                style={{
                                  marginLeft:
                                    props.icon === "eSign" ? "2px" : "",
                                }}
                              >
                                <RBACWrapper
                                  role={props.permission}
                                  type="PERMISSION"
                                >
                                  <ConfigureToolTip
                                    name={props.name ? props.name : props.icon}
                                    enable={!(!configurable && !isConfigured)}
                                  >
                                    <Icon
                                      {...props}
                                      title={props.icon}
                                      size={props.icon === "eSign" ? 18 : 24}
                                      className={`co-quick-action-button ${
                                        isError
                                          ? "mx-icon--path-fill-error"
                                          : ""
                                      } ${
                                        !configurable && !isConfigured
                                          ? ""
                                          : "mx-icon--path-fill-disabled"
                                      }`}
                                      isPressable
                                      onClick={() => {
                                        if (!configurable && !isConfigured) {
                                          onClick();
                                        }
                                      }}
                                    />
                                  </ConfigureToolTip>
                                </RBACWrapper>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                    {/* Invoice List */}

                    {/* Timeline */}
                    <div>
                      {disableTimeline ? (
                        <COTimeline isOverView={false} />
                      ) : (
                        <></>
                      )}

                      <div
                        style={{
                          position: "absolute",
                          bottom: "0.6%",
                          left: "4.4%",
                        }}
                      >
                        {Array.isArray(lastTempDataActions) &&
                          lastTempDataActions.map((item, index) => {
                            if (index >= 3) {
                              return <></>;
                            }
                            return (
                              <>
                                <Tag
                                  kind={KIND.primary}
                                  onClick={() => {
                                    if (item && item.action) {
                                      setDrawer(item?.action, item);
                                    }
                                  }}
                                  onActionClick={() => {
                                    dispatch(minimizeDeleteAction(item?.id));
                                  }}
                                  overrides={{
                                    Root: {
                                      style: ({ $theme }) => ({
                                        borderBottomLeftRadius: "5px",
                                        borderBottomRightRadius: "5px",
                                        borderTopLeftRadius: "5px",
                                        borderTopRightRadius: "5px",
                                        border: "1px solid #CDCED9",
                                        backgroundColor: "#FAFAFA",
                                        color: "#5F6368",
                                        borderRadius: "5px",
                                        height: "35px",
                                        width: "180px",
                                      }),
                                    },
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <Icon icon={iconType[item.action]} />
                                    <Typography
                                      type="p"
                                      style={{
                                        maxWidth: "150px",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {" "}
                                      {item.name}
                                    </Typography>
                                  </div>
                                </Tag>
                              </>
                            );
                          })}
                      </div>
                    </div>
                    {/* Timeline */}
                  </div>
                  <div className="co-section_sidebar">
                    <COContacts
                      // contacts={_.get(customer, "data.contacts", [])}
                      setDrawer={setDrawer}
                    />

                    {paymentRoleExist && (
                      <COPaymentTable setDrawer={setDrawer} />
                    )}

                    {worklists?.length && (
                      <div className="float-next-button">
                        <StatefulTooltip
                          accessibilityType={"tooltip"}
                          content={
                            <div
                              style={{ minWidth: "auto", maxWidth: "200px" }}
                            >
                              Go to Next Record.
                              <br />
                              {process_count ? process_count : 0}/
                              {count ? count : 0} Worked
                            </div>
                          }
                        >
                          <div>
                            <IconButton
                              size="mini"
                              kind={"tertiary"}
                              isLoading={isCustomerCampaignLoading}
                              disabled={isCustomerCampaignLoading}
                              onClick={async () => {
                                await fetchCustomerCampaign({
                                  ...(worklists?.length
                                    ? { worklists: [...worklists] }
                                    : {}),
                                });
                              }}
                              $style={{
                                padding: "0px",
                                ":hover": {
                                  backgroundColor: "#ffffff10",
                                },
                              }}
                            >
                              <Icon icon="next_start" color="#fff" size={25} />
                            </IconButton>
                          </div>
                        </StatefulTooltip>
                        <StatefulTooltip
                          accessibilityType={"tooltip"}
                          content={
                            <div
                              style={{ minWidth: "auto", maxWidth: "200px" }}
                            >
                              Go to Previous Record. <br />
                              {process_count ? process_count : 0}/
                              {count ? count : 0} Worked
                            </div>
                          }
                        >
                          <div>
                            <IconButton
                              size="mini"
                              kind={"tertiary"}
                              isLoading={prevCustomer.isLoading}
                              disabled={prevCustomer.isLoading}
                              onClick={async () => {
                                await prevCustomer.mutateAsync({
                                  ...(actionResposce?.process_at
                                    ? { process_at: actionResposce.process_at }
                                    : {}),
                                  ...(worklists?.length
                                    ? { worklists: [...worklists] }
                                    : {}),
                                });
                              }}
                              $style={{
                                padding: "0px",
                                ":hover": {
                                  backgroundColor: "#ffffff10",
                                },
                              }}
                            >
                              <Icon
                                style={{
                                  transform: "rotate(182deg)",
                                }}
                                icon="next_start"
                                color="#fff"
                                size={25}
                              />
                            </IconButton>
                          </div>
                        </StatefulTooltip>
                      </div>
                    )}
                    <div className="doc_wraper">
                      {_.get(documentList, "data.data.docs", []).length > 0 &&
                        _.get(documentList, "data.data.docs", [])
                          .slice(0, 2)
                          .map((e) => {
                            return <DocQuickView {...e} open={setDrawer} />;
                          })}
                    </div>
                    {space &&
                      !space?.role_permissions?.includes(DISABLE_NOTES) && (
                        <>
                          {otherInfo.alertNote ? (
                            <COAlertNote {...otherInfo.alertNote} />
                          ) : (
                            <></>
                          )}
                        </>
                      )}

                    {/* <COQuickNote setDrawer={setDrawer} /> */}
                    {/* <NotesComp customer={customer} /> */}
                    <NotesContainer customer={customer} setDrawer={setDrawer} />
                    <CpWorkflowModal />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {_.get(customer, "data.vitil", "") > new Date() &&
        _.get(customer, "data.viusr", "") !== _.get(userInfo, "_id", "") && (
          <AgentLocked vistedUser={_.get(customer, "data.viusr", "-")} />
        )}

      {/* {!isInternal && agentInstructionModal ? ( */}
      <AgentInstruction
        isLoading={customer.isFetchedAfterMount}
        active={agentInstructionModal}
        setIsActive={setAgentInstructionModalState}
        // setClientInstrucion={setClientInstrucion}
        isClientInstruction={isClientInstruction}
        isEnable={isEnable}
        isAgentData={isAgentData}
        setIsAgentData={setIsAgentData}
        setIsEnable={setIsEnable}
      />
      {/* ) : (
        <></>
      )} */}
      {active && _.get(customer, "data.stkynt", []).length > 0 && (
        <div className="sticky_note_data_list">
          <div className="drawer_sticky">
            <Icon icon="sticky_note" color="#ffffff" />
            <Typography>Sticky Notes</Typography>
          </div>
          <div className="sticky_notes_list_container">
            {_.get(customer, "data.stkynt", []).map((sy, i) => {
              return (
                <Typography
                  className={
                    _.get(customer, "data.stkynt", []).length - 1 !== i
                      ? " --sticky_list_modifi"
                      : "sticky_list"
                  }
                >
                  {sy?.nt?.replace(/(<([^>]+)>)/gi, "")}{" "}
                  <span>
                    - {format.rd({ name: "users", id: sy.cby })}{" "}
                    {format.date({ value: sy.cat })}
                  </span>
                </Typography>
              );
            })}
          </div>
        </div>
      )}

      {subscriptionsDrawerIsOpen && (
        <SubscriptionDrawer getParams={customerId} isV2={true} />
      )}
      <Drawer />
    </div>
  );
}
