import React from "react";

const CrosssSvg = ({ size = 18, color = "#787878" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3469 11.9171C11.508 12.0782 11.7692 12.0782 11.9303 11.9171C12.0913 11.756 12.0913 11.4948 11.9303 11.3337L9.59694 9.00039L11.9303 6.66707C12.0913 6.50598 12.0913 6.2448 11.9303 6.08371C11.7692 5.92262 11.508 5.92262 11.3469 6.08371L9.01357 8.41703L6.68026 6.08371C6.51916 5.92262 6.25798 5.92262 6.09689 6.08371C5.9358 6.2448 5.9358 6.50598 6.09689 6.66707L8.43021 9.00039L6.09689 11.3337C5.9358 11.4948 5.9358 11.756 6.09689 11.9171C6.25798 12.0782 6.51916 12.0782 6.68026 11.9171L9.01357 9.58375L11.3469 11.9171Z"
        fill={color}
      />
    </svg>
  );
};

export default CrosssSvg;
