import React, { useContext, useEffect, useState } from "react";
import { Button } from "baseui/button";
import { Modal, SIZE } from "baseui/modal";
import workflowModal from "../../assets/img/workflow-modal.png";
import { Label1, Label2, Paragraph1, ParagraphLarge } from "baseui/typography";
import { TextButton } from "../TextButton";
import { IconButton, KIND } from "../IconButton";
import Edit from "../../assets/img/svg/EditSvg";
import { Controller, useForm } from "react-hook-form";
import { SelectBox } from "../SelectBox";
import { useIntl } from "react-intl";
import * as ModalButton from "../TextButton";
import {
  getWorkflowlist,
  assignWorkflow,
  getWorkflow,
  getOrganization,
  getCustomerContactDetail,
} from "../../services";
import getSelectValues from "../../utils/getSelectValues";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useSelector, useDispatch } from "react-redux";
import {
  workflowAddModal,
  refetchActions,
  customerConatctData,
} from "../../redux/actions";
import moment from "moment";
import "moment-timezone";
import {
  CO_TIMELINE_,
  CUSTOMER_OVERVIEW,
  CUSTOMER_OVERVIEW_,
} from "../../constants";

import DateRangeSelect from "../TableFilters/DateRangeFilter/DateRangeSelect";
import setSelectValues from "../../utils/setSelectValues";
import queryClient from "../../providers/queryClient";
import _ from "lodash";

const CpWorkflowModal = ({ kind = KIND.tertiary }) => {
  const { customerId } = useParams();
  const intl = useIntl();
  const [isOpen, setIsOpen] = React.useState(false);
  const [workflowList, setWorkflowList] = useState([]);
  const [workflowActionList, setWorkflowActionList] = useState([]);
  const [smsConfigBtn, setSmsConfigBtn] = useState(false);

  const { customerConatactDetails, customerDetails } = useSelector(
    (e) => e.customerOverviewReducer
  );

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  let maxyfiContextValue = useContext(MaxyfiContext);

  let {
    currentOrganization,
    referenceData,
    refetch,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;
  function close() {
    setIsOpen(false);
  }
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      start_date: new Date(),
    },
  });

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getParams = getSearchParams.get("customers");

  const assignWorkflowMutation = useMutation(
    (workflowData) =>
      assignWorkflow({
        ...workflowData,
        customerId: customerId,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        dispatch(workflowAddModal(false));
        dispatch(refetchActions());
        refetch({
          ...maxyfiContextValue,
          setLoader: false,
          currentOrganization: currentOrganization,
        });
        queryClient.invalidateQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
        queryClient.invalidateQueries(`${CO_TIMELINE_}${customerId}`);
      },
    }
  );

  const onSubmit = async (data) => {
    const orgRef = referenceData?.organizations?.find(
      ({ id }) => id === currentOrganization
    );

    const timeZone = orgRef?.time_zone;

    let newValues = {
      workflowId: getSelectValues(data.workflowId),
      start_workflow_from: getSelectValues(data.start_workflow_from),
      start_date: moment
        .tz(
          `${data.start_date.getFullYear()}-${
            data.start_date.getMonth() + 1
          }-${data.start_date.getDate()}`,
          "YYYY-MM-DD",
          timeZone
        )
        .utc()
        .valueOf(),
    };

    await assignWorkflowMutation.mutateAsync(newValues);
  };

  let workflowId = watch("workflowId");

  const fetchWorkFlowActions = async (id) => {
    let workflows = await getWorkflow({
      organization: currentOrganization,
      organization_id: [currentOrganization],
      workflowId: id,
    });

    setWorkflowActionList(
      workflows && workflows.data && workflows.data.doc
        ? workflows.data.doc.customer_flow.map((e) => ({
            id: e.days_after,
            label: `Day ${e.days_after} - ${e.action_name}`,
          }))
        : []
    );

    const customerConatactDetails = _.get(
      customerData,
      "data.doc.contacts",
      []
    );

    let isValid = customerConatactDetails?.some(
      (e) => e?.is_valid_email == true
    );

    let isValidPhone = customerConatactDetails?.some(
      (e) => e?.is_valid_phone == true
    );

    let isValidLandline = customerConatactDetails?.some(
      (e) => e?.is_valid_landline == true
    );

    if (
      workflows?.data?.doc?.action_types?.some(
        (e) => e.type === "sms" && e.count
      ) &&
      currentDefaultFormatDetails?.is_sms_configured === false
    ) {
      setError("workflowId", {
        message:
          "Configure SMS-Gateway Settings to select SMS-based workflows.",
      });
      setSmsConfigBtn(true);
    } else if (customerConatactDetails == []) {
      setError("workflowId", {
        message: "Consumer Contact doesn't have a phone number and Email ID.",
      });
      setSmsConfigBtn(true);
    } else if (
      workflows?.data?.doc?.action_types?.some(
        (e) => e.type === "sms" && e.count
      ) &&
      isValidPhone == false
    ) {
      setError("workflowId", {
        message: "Consumer Contact doesn't have a phone number.",
      });
      setSmsConfigBtn(true);
    } else if (
      workflows?.data?.doc?.action_types?.some(
        (e) => e.type === "call" && e.count
      ) &&
      isValidPhone == false &&
      isValidLandline == false
    ) {
      setError("workflowId", {
        message: "Consumer Contact doesn't have a phone number.",
      });
      setSmsConfigBtn(true);
    } else if (
      workflows?.data?.doc?.action_types?.some(
        (e) => e.type === "email" && e.count
      ) &&
      isValid == false
    ) {
      setError("workflowId", {
        message: "Consumer Contact doesn't have Email ID.",
      });
      setSmsConfigBtn(true);
    } else {
      clearErrors("workflowId");
      setSmsConfigBtn(false);
    }

    // setConfigSms(
    //   workflows && workflows.data && workflows.data.doc
    //     ? workflows.data.doc.action_types.map((e) => {
    //         return e.type;
    //       })
    //     : []
    // );
  };

  useEffect(() => {
    setWorkflowActionList([]);
    setValue("start_workflow_from", []);
    let id = getSelectValues(workflowId);
    // setValue("start_workflow_from", []);
    // if (!workflowId === false) {
    //   setSelectValues("start_workflow_from", workflowActionList[0]?.id);
    // }
    if (workflowId && id) {
      fetchWorkFlowActions(id);
    }

    // workflowId removed from useEffect dependency
  }, [workflowId, getParams]);

  useEffect(() => {
    setValue("start_workflow_from", [{ id: workflowActionList[0]?.id }]);

    // setValue(
    //   "workflowId",
    //   setSelectValues(
    //     customerDetails &&
    //       customerDetails.workflow &&
    //       customerDetails.workflow.id
    //   )
    // );
  }, [workflowActionList]);

  // let action = workflowList.find((e) => e._id == id);
  // let customerFlow =
  //   action && action.customer_flow
  //     ? action.customer_flow.map((e) => ({
  //         id: e._id,
  //         label: `Day ${e.days_after} - ${e.action_name}`,
  //       }))
  //     : [];
  // setWorkflowActionList(customerFlow);

  // TODO: Default Starting Action
  // let defaultStartingAction = customerFlow[0] ? [customerFlow[0]] : [];
  // setValue("starting_stage", defaultStartingAction);

  const fetchWorkflow = async (filters = {}) => {
    let workflows = await getWorkflowlist({
      organization_id: [currentOrganization],
      organization: currentOrganization,
      filters: {
        exclude: "ALL",
        page_size: 0,
        sort: "name",
        is_disabled: false,
        ...filters,
      },
    });

    setWorkflowList(
      workflows && workflows.data && workflows.data.docs
        ? workflows.data.docs
        : []
    );
  };

  useEffect(() => {
    fetchWorkflow();
  }, []);

  const isModalOpen = useSelector(
    (s) => s.customerOverviewReducer.addWorkflowIsModal
  );

  useEffect(() => {
    if (
      customerDetails &&
      customerDetails.workflow &&
      customerDetails.workflow.id
    ) {
      setValue(
        "workflowId",
        setSelectValues(
          customerDetails &&
            customerDetails.workflow &&
            customerDetails.workflow.id
        )
      );
    } else {
      setValue("workflowId", []);
      setWorkflowActionList([]);
    }
  }, [customerDetails]);
  return (
    <>
      <Modal
        onClose={close}
        isOpen={isModalOpen}
        size={SIZE.auto}
        closeable={false}
        overrides={{
          Dialog: {
            style: ({ $theme }) => ({
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
            }),
          },
        }}
      >
        <div style={{ width: "340px" }}>
          <div
            style={{
              backgroundColor: "#516BEB30",
              textAlign: "center",
              borderRadius: "10px 10px 50px 50px",
              height: "182px",
              paddingTop: "20px",
            }}
          >
            <img src={workflowModal} alt="" />
          </div>
          <form
            style={{
              textAlign: "center",
              marginTop: "15px",
              padding: "10px 20px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <ParagraphLarge $style={{ marginBottom: "20px" }}>
              Select the workflow to be used{" "}
            </ParagraphLarge>
            <Controller
              name="workflowId"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  // disabled={isDisabled}
                  requiredAstric={true}
                  onInputChange={(evt) => {
                    fetchWorkflow({ name: evt.target.value });
                  }}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "workflow",
                  })}
                  placeholder={intl.formatMessage({
                    id: "select_workflow",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={workflowList.map((e) => ({
                    id: e._id,
                    label: e.name,
                  }))}
                />
              )}
            />

            <ParagraphLarge $style={{ marginBottom: "20px" }}>
              Select the starting stage in the workflow to be used
            </ParagraphLarge>
            <Controller
              name="start_workflow_from"
              control={control}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  // disabled={isDisabled}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "start_workflow_from",
                  })}
                  placeholder={intl.formatMessage({
                    id: "start_workflow_from",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={workflowActionList}
                />
              )}
            />

            {/* <ParagraphLarge $style={{ marginBottom: "20px" }}>
              Select the starting stage in the workflow to be used
            </ParagraphLarge> */}
            <Controller
              name="start_date"
              control={control}
              render={({ field }) => (
                <DateRangeSelect
                  {...field}
                  name={field.name}
                  // disabled={isDisabled}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "start_date",
                  })}
                  placeholder={intl.formatMessage({
                    id: " ",
                  })}
                  value={field.value}
                  onChange={(e) => field.onChange(e.date)}
                  minDate={new Date()}
                  range
                />
              )}
            />

            <div
              style={{
                marginTop: "15px",
                textAlign: "center",
                marginBottom: "25px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "10px 20px",
              }}
            >
              <div style={{ height: "36px", width: "96px" }}>
                <TextButton
                  type="button"
                  fullWidth
                  size={ModalButton.SIZE.compact}
                  kind={KIND.tertiary}
                  // onClick={close}
                  onClick={() => {
                    reset();
                    dispatch(workflowAddModal(false));
                  }}
                >
                  Cancel
                </TextButton>
              </div>
              <div style={{ height: "36px", width: "96px" }}>
                <TextButton
                  type="submit"
                  fullWidth
                  size={ModalButton.SIZE.compact}
                  kind={KIND.primary}
                  isLoading={isSubmitting}
                  disabled={isSubmitting || smsConfigBtn}
                >
                  Apply
                </TextButton>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default CpWorkflowModal;
