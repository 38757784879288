import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { SIZE } from "baseui/textarea";
import { KIND, TextButton } from "../../../components/TextButton";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { getGridStructure, putCustomizeLable } from "../../../services";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { Icon, Typography } from "../../../components_v2";
import { Input } from "baseui/input";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { STYLE_TYPE } from "baseui/checkbox";
import { FormattedMessage, useIntl } from "react-intl";
import { SelectBox } from "../../../components/SelectBox";
import getSelectValues from "../../../utils/getSelectValues";
import setSelectValues from "../../../utils/setSelectValues";
import _ from "lodash";
import Loader from "../../../components/Loader";

function CustomizeLabel({
  customizeTableData,
  settingData,
  setIsRefetch,
  isFetching,
  isLoading,
}) {
  const {
    currentOrganization,
    referenceData,
    customInvoice,
    customCustomer,
    refetch,
  } = useContext(MaxyfiContext);

  const sortedCustomizeTableData = [...customizeTableData].sort(
    (a, b) => a.indx - b.indx
  );

  const mxContext = useContext(MaxyfiContext);
  const [preview, setPreview] = useState(false);
  const intl = useIntl();
  const CUSTOM_FIELD = "CF";
  const GRID = "GR";
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const [gridData, setGridData] = useState(null);
  const [rowsAdded, setRowsAdded] = useState(false);
  // Function to find the maximum indx value
  const getMaxIndex = (data) => {
    return data.length > 0 ? Math.max(...data.map((item) => item.indx)) : "";
  };
  const [isIndex, setIndex] = useState(getMaxIndex(customizeTableData));
  const [rowCount, setRowCount] = useState(0);
  const [isFieldError, setIsFieldError] = useState(false);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "cuslb",
  });

  useEffect(() => {
    const userListApi = async () => {
      let userListVal = await getGridStructure({
        organization: currentOrganization,
        menu: "customer_portal_custom_inv",
      });

      setGridData(_.get(userListVal, "data.doc.table", []));
    };

    userListApi();
  }, []);

  //Update the Customize the Label for the customer portal
  const customizeLabel = useMutation(
    (values) =>
      putCustomizeLable({
        values,
        organization: currentOrganization,
        DataType: "customize_label",
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        refetch({
          ...mxContext,
          setLoader: false,
          currentOrganization: currentOrganization,
        });
        setIsRefetch();
      },
    }
  );

  useEffect(() => {
    sortedCustomizeTableData.forEach((field, index) => {
      update(index, {
        ...field,
        indx: field?.indx,
        syt: field.redt
          ? _.get(field, "syt", "")
          : [
              {
                id: _.get(field, "syt", ""),
                label: `${
                  field.mod === "CUS"
                    ? "Consumer."
                    : field.mod === "INV"
                    ? "Invoice."
                    : ""
                }${intl.formatMessage({
                  id: _.get(field, "syt", "").replace("cus.", ""),
                })}`,
              },
            ],
        frmt: setSelectValues(_.get(field, "frmt", "")),
      });
    });
  }, [isFetching]);

  const handleAddField = () => {
    setRowCount((prevCount) => {
      const newCount = customizeTableData ? customizeTableData.length : "";
      append({
        indx: isIndex + 1,
        syt: [], // System Terms
        portt: ``, // Portal Terms
        ise: true, // Toggle State
        frmt: [],
      });

      return newCount;
    });
  };

  // "cus.customer_id" "client_reference" "invoice_number"

  const onSubmit = async (data) => {
    const processedData = _.get(data, "cuslb", [])
      .filter((item) => {
        const isSytUndefined = item.syt === undefined;
        const isSytEmptyArray =
          Array.isArray(item.syt) && item.syt.length === 0;
        const isIndxEmpty = !item.indx;

        // Filter out items where syt is undefined or an empty array
        return !(isSytUndefined || isSytEmptyArray);
      })
      .map((item) => {
        if (!item.indx) {
          item.indx = 99; // Set indx to 99 if it's not present
          if (Array.isArray(item.syt) && item.syt.length > 0 && !item?.portt) {
            item.portt = `${intl.formatMessage({
              id: getSelectValues(item.syt),
            })}`;
          }
        }

        return item;
      });

    const findCustomerId = processedData.find(
      (e) => e?.syt?.[0]?.id == "cus.customer_id"
    );
    const findClientRef = processedData.find(
      (e) => e?.syt?.[0]?.id == "client_reference"
    );
    const findInvNum = processedData.find((e) => e?.syt == "invoice_number");

    if (!findCustomerId?.ise && !findClientRef?.ise && !findInvNum?.ise) {
      return setIsFieldError(true);
    } else if (findCustomerId?.ise) {
      setIsFieldError(false);
    } else if (findClientRef?.ise) {
      setIsFieldError(false);
    } else if (findInvNum?.ise) {
      setIsFieldError(false);
    }

    const filteredData = processedData.map(({ _id, redt, rdis, ...item }) => {
      const newItem = { ...item };

      Object.keys(newItem).forEach((key) => {
        if (newItem[key] === undefined) {
          delete newItem[key];
        }
      });

      // Convert syt and frmt to strings if they are arrays
      const gridDataTest = gridData.map((item) => ({
        accessor: item.accessor,
        mod: item.mod,
      }));

      // Function to update newItem.mod based on syt value
      const updateMod = (sytValue) => {
        gridDataTest.forEach((item) => {
          if (
            sytValue === item.accessor ||
            (Array.isArray(sytValue) &&
              getSelectValues(sytValue).includes(item.accessor))
          ) {
            newItem.mod = item.mod; // Update newItem.mod with the corresponding mod
          }
        });
      };

      if (typeof newItem.syt === "string") {
        const customInvoiceIdsaaa = customInvoice.map((ci) => ci?.path);
        const customCustomerIdsss = customCustomer.map((ci) => ci?.path);

        if (customInvoiceIdsaaa.includes(newItem.syt)) {
          newItem.typ = CUSTOM_FIELD;
          newItem.mod = "INV";
        } else if (customCustomerIdsss.includes(newItem.syt)) {
          newItem.typ = CUSTOM_FIELD;
          newItem.mod = "CUS";
          newItem.cus.syt = `cus.${newItem.syt}`;
        } else {
          newItem.typ = GRID;
          updateMod(_.get(newItem, "syt", ""));
        }

        // Update newItem.mod based on the syt value
        updateMod(_.get(newItem, "syt", ""));
      }

      if (Array.isArray(newItem.syt)) {
        const customInvoics = customInvoice.map((ci) => ci?.path);
        const customCustomers = customCustomer.map((ci) => ci?.path);

        if (customInvoics.includes(getSelectValues(newItem?.syt))) {
          newItem.typ = CUSTOM_FIELD;
          newItem.mod = "INV";
        } else if (customCustomers.includes(newItem.syt)) {
          newItem.typ = CUSTOM_FIELD;
          newItem.mod = "CUS";
        } else {
          newItem.typ = GRID;
        }

        if (newItem.syt) {
        }

        updateMod(_.get(newItem, "syt", ""));
        // Update newItem.mod based on each value in the syt array

        newItem.syt = getSelectValues(newItem?.syt);
      }

      if (Array.isArray(newItem?.frmt)) {
        newItem.frmt = getSelectValues(newItem?.frmt);
      }

      return newItem;
    });

    // Make the API call with the filtered and transformed data
    await customizeLabel.mutateAsync({ cuslb: filteredData });
  };

  const tableStyle = {
    width: "50px",
    display: "flex",
    // Remove padding for precise width control
    textAlign: "center", // Center align text in the cell
  };

  //Option for the Choose Field Select Box
  const combinationGridCustom = useMemo(() => {
    let combineData = [];
    let gData = [];
    let cusInvData = [];
    let cusCusData = [];
    if (gridData) {
      gData = gridData
        ? gridData.map((item) => ({
            id: item?.accessor,
            _id: item?.col_id,
            label: `${intl.formatMessage({
              id: item?.accessor?.startsWith("cus.")
                ? item.mod === "INV"
                  ? `Invoice.${intl.formatMessage({
                      id: item?.accessor.replace("cus.", ""),
                    })}`
                  : item.mod === "CUS"
                  ? `Consumer.${intl.formatMessage({
                      id: item?.accessor.replace("cus.", ""),
                    })}`
                  : `${intl.formatMessage({
                      id: item?.accessor.replace("cus.", ""),
                    })}`
                : item.mod === "INV"
                ? `Invoice.${intl.formatMessage({
                    id: item?.accessor,
                  })}`
                : item.mod === "CUS"
                ? `Consumer.${intl.formatMessage({
                    id: item?.accessor,
                  })}`
                : item?.accessor,
            })}`,
          }))
        : [];
    }
    if (customInvoice.length > 0) {
      cusInvData = customInvoice.map((inv) => ({
        id: inv?.path,
        label: `Invoice.${inv.name}`,
      }));
    }
    if (customCustomer.length > 0) {
      cusCusData = customCustomer.map((inv) => ({
        id: inv?.path,
        label: `Consumer.${inv.name}`,
      }));
    }

    let existingData = customizeTableData
      ? customizeTableData.map((item) => item.syt)
      : [];

    let filterDattttt = [...gData, ...cusInvData, ...cusCusData].filter(
      (item) => !existingData.includes(item.id)
    );

    // let combineDatas = [...gData, ...cusInvData, ...cusCusData].filter(
    //   (item) => !existingData.includes(item.path)
    // );

    combineData = filterDattttt
      ? filterDattttt
      : [...gData, ...cusInvData, ...cusCusData];
    return combineData;
  }, [gridData]);

  if (isFetching || isLoading) {
    <Loader />;
  }
  return (
    !isFetching && (
      <>
        <div className="customize-table-container">
          <div></div>
          <div style={{ marginBottom: "10px" }}>
            <Typography type="h3" className="customize-table-header">
              Edit Columns & Manage Column Headers
            </Typography>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="customize-table-container-field-data">
              <div className="customize-table-data">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <Typography
                          type="p"
                          className="typo-tab-head"
                          style={{
                            gap: "2px",
                            alignItems: "center",
                          }}
                        >
                          No <span style={{ color: "red" }}>*</span>
                          <span>
                            <Icon
                              icon="terms_outline"
                              color="#ADADAD"
                              size={14}
                            />
                          </span>
                        </Typography>
                      </th>
                      <th>
                        <Typography type="p" className="typo-tab-head">
                          System Terms
                        </Typography>
                      </th>
                      <th colSpan="2">
                        <Typography type="p" className="typo-tab-head">
                          Portal Terms
                        </Typography>
                      </th>
                      <th>
                        <Typography type="p" className="typo-tab-head">
                          Format
                        </Typography>
                      </th>
                      <th>
                        <Typography
                          type="p"
                          className="typo-tab-head"
                        ></Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map((e, index) => (
                      <tr key={e.id}>
                        <td>
                          <Controller
                            name={`cuslb[${index}].indx`}
                            control={control}
                            rules={{
                              required: watch(`cuslb[${index}].ise`)
                                ? true
                                : false,
                            }}
                            render={({ field }) => (
                              <div style={tableStyle}>
                                <Input
                                  requiredAstric={true}
                                  type="number"
                                  overrides={SNnoInput(
                                    !!errors?.cuslb?.[index]?.indx &&
                                      watch(`cuslb[${index}].ise`)
                                  )}
                                  placeholder=""
                                  size={SIZE.mini}
                                  value={field.value}
                                  styleColor={
                                    errors?.cuslb?.[index]?.indx
                                      ? "rgb(253, 55, 42)"
                                      : ""
                                  }
                                  onChange={(e) => {
                                    const newIndxValue = parseInt(
                                      e.target.value,
                                      10
                                    );
                                    field.onChange(e.target.value);

                                    const dummyCurremntText = [
                                      ...customizeTableData,
                                      { indx: newIndxValue },
                                    ];

                                    setIndex(getMaxIndex(dummyCurremntText));
                                  }}
                                />
                              </div>
                            )}
                          />
                        </td>

                        <td>
                          {e.redt ? (
                            <Typography type="p" className="typo-tab-data">
                              <FormattedMessage id={e.syt}>
                                {e.syt}
                              </FormattedMessage>
                            </Typography>
                          ) : (
                            <Controller
                              style={{ marginBottom: "0px" }}
                              name={`cuslb[${index}].syt`}
                              control={control}
                              rules={{
                                required: watch(`cuslb[${index}].ise`)
                                  ? true
                                  : false,
                              }}
                              render={({ field }) => (
                                <div
                                  style={{
                                    width: "239px",
                                    position: "relative",
                                    top: "5px",
                                  }}
                                >
                                  <SelectBox
                                    {...field}
                                    clearable={true}
                                    size="mini"
                                    options={combinationGridCustom}
                                    value={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.value);
                                    }}
                                    error={
                                      errors?.cuslb?.[index]?.syt &&
                                      watch(`cuslb[${index}].ise`) &&
                                      "Required"
                                    }
                                    style={{ border: "1px solid red" }}
                                  />

                                  {/* {errors?.cuslb?.[index]?.syt &&
                                    watch(`cuslb[${index}].ise`) && (
                                      <span style={{ color: "red" }}>
                                        Required
                                      </span>
                                    )} */}
                                </div>
                              )}
                            />
                          )}
                        </td>
                        <td colSpan="2">
                          <Controller
                            name={`cuslb[${index}].portt`}
                            control={control}
                            rules={{
                              required: watch(`cuslb[${index}].ise`)
                                ? true
                                : false,
                            }}
                            render={({ field }) => (
                              <div className="customize-label-input-box">
                                <Input
                                  overrides={InputOverRideStyle}
                                  size={SIZE.mini}
                                  value={field.value}
                                  placeholder="Type Preferred Portal Term"
                                  onChange={(e) =>
                                    field.onChange(e.target.value)
                                  }
                                  error={
                                    errors?.cuslb?.[index]?.syt &&
                                    watch(`cuslb[${index}].ise`) &&
                                    "Required"
                                  }
                                />
                              </div>
                            )}
                          />
                          {errors?.cuslb?.[index]?.portt &&
                            watch(`cuslb[${index}].ise`) && (
                              <span style={{ color: "red" }}>Required</span>
                            )}
                        </td>
                        <td>
                          <Controller
                            name={`cuslb[${index}].frmt`}
                            control={control}
                            render={({ field }) => (
                              <div
                                style={{
                                  width: "239px",
                                  position: "relative",
                                  top: "5px",
                                }}
                              >
                                <SelectBox
                                  {...field}
                                  clearable={true}
                                  size="mini"
                                  label="Select Formatting"
                                  options={
                                    referenceData["cp_customized_label_format"]
                                  }
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                  }}
                                />
                              </div>
                            )}
                          />
                        </td>
                        <td>
                          <Controller
                            name={`cuslb[${index}].ise`}
                            control={control}
                            render={({ field }) => (
                              <div>
                                <CheckBoxBaseweb
                                  {...field}
                                  checked={field.value}
                                  checkmarkType={STYLE_TYPE.toggle_round}
                                  disabled={e.rdis === true}
                                />
                              </div>
                            )}
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6">
                        <TextButton
                          type="button"
                          kind={KIND.tertiary}
                          size={SIZE.mini}
                          startEnhancer={() => (
                            <Icon icon="plus" color="#516BEB" />
                          )}
                          onClick={() => {
                            // Handle the index based on the Max Math  indx of the reponse and targeted value pushed to the response to gt the Max Indx
                            setIndex(isIndex + 1);
                            setRowsAdded(true);
                            handleAddField();
                          }}
                        >
                          <span style={{ color: "#516beb" }}>
                            Add Another Field
                          </span>
                        </TextButton>
                        {/* <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          
                          <Typography
                            type="p"
                            className="text-primary"
                            
                          >
                           
                          </Typography>
                        </div> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {isFieldError ? (
                <div
                  style={{
                    backgroundColor: "#FD372A",
                    borderRadius: "5px",
                    padding: "5px 5px 5px 10px",
                    marginTop: "10px",
                  }}
                >
                  <Typography className="text-trinery">
                    Please enable at least one of these: Invoice Number, Client
                    Reference, or Consumer ID.
                  </Typography>
                </div>
              ) : (
                <></>
              )}

              {/* {preview && (
              <>
                {" "}
                <CustomizeLabelView
                customizeTableData={customizeTableData} />
              </>
            )} */}

              <div className="customize-table-last-section">
                <div>
                  {/* <TextButton
                  type="button"
                  kind={KIND.tertiary}
                  size={SIZE.mini}
                  style={{ border: "1px solid #CDCED9", padding: "10px" }}
                  onClick={() => {
                    setPreview(!preview);
                  }}
                >
                  {preview ? "Close" : "Preview"}
                </TextButton> */}
                </div>
                <div>
                  <TextButton
                    type="button"
                    kind={KIND.tertiary}
                    size={SIZE.mini}
                    style={{ color: "#516BEB", padding: "10px 35px" }}
                    onClick={() => {
                      // setIndex(getMaxIndex(sortedCustomizeTableData));
                      reset();
                      setIsRefetch();
                    }}
                  >
                    Reset
                  </TextButton>
                  <TextButton
                    type="submit"
                    size={SIZE.mini}
                    style={{ padding: "10px 35px" }}
                    isLoading={isSubmitting}
                  >
                    Save
                  </TextButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    )
  );
}

export default CustomizeLabel;

export const InputOverRideStyle = {
  Root: {
    style: () => ({
      // borderLeftColor: "#ceced9",
      // borderRightColor: "#cecde9",
      // borderTopColor: "#cecde9",
      // borderBottomColor: "#cecde9",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      borderTopWidth: "1px",
      borderBottomWidth: "1px",
      borderLeftWidth: "1px",
      borderRightWidth: "1px",
      background: "white",
      height: "30px",
    }),
  },
  InputContainer: {
    style: () => ({
      background: "white",
    }),
  },
  Input: {
    style: () => ({
      "::placeholder": {
        color: "#adadad",
      },
      background: "white",
    }),
  },
  EndEnhancer: {
    style: ({ $theme }) => ({
      padding: "0px",
      background: "white",
    }),
  },
};

export const SNnoInput = (hasError) => ({
  Root: {
    style: () => ({
      borderLeftColor: hasError ? "red" : "#ceced9",
      borderRightColor: hasError ? "red" : "#cecde9",
      borderTopColor: hasError ? "red" : "#cecde9",
      borderBottomColor: hasError ? "red" : "#cecde9",
      // borderBottomLeftRadius: "5px",
      // borderBottomRightRadius: "5px",
      // borderTopLeftRadius: "5px",
      // borderTopRightRadius: "5px",
      borderRadius: "5px",
      borderTopWidth: "1px",
      borderBottomWidth: "1px",
      borderLeftWidth: "1px",
      borderRightWidth: "1px",
      background: "white",
      height: "30px",
      width: "46px",
      textAlign: "center",
      padding: "0px !important",
    }),
  },
  Input: {
    style: ({ $theme }) => ({
      textAlign: "center",
      padding: "0px !important",
    }),
  },
  InputContainer: {
    style: () => ({
      background: "white",
      textAlign: "center",
      padding: "0px !important",
      border: hasError ? "1px solid red" : "1px solid #ceced9",
    }),
  },
  EndEnhancer: {
    style: ({ $theme }) => ({
      padding: "0px",
      background: "white",
    }),
  },
});
