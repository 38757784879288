import React from "react";
import { Drawer } from "baseui/drawer";
import WorkListDrawerType from "./WorkListDrawertype";

const WorkListType = ({ type, setIsDrawer, isDrawer }) => {
  switch (isDrawer.type) {
    case "EDIT":
      return (
        <WorkListDrawerType setIsDrawer={setIsDrawer} isDrawer={isDrawer} />
      );
    default:
      return null;
  }
};

const WorkListDrawer = ({ isDrawer, setIsDrawer }) => {
  return (
    <Drawer
      isOpen={isDrawer.isOpen}
      autoFocus
      size="570px"
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
          }),
        },
        DrawerBody: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
            padding: "15px",
            overflowY: "scroll",
          }),
        },
        DrawerContainer: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
          }),
        },
      }}
      closeable
      onClose={() => setIsDrawer({ type: "", isOpen: false, data: {} })}
    >
      <WorkListType isDrawer={isDrawer} setIsDrawer={setIsDrawer} />
    </Drawer>
  );
};

export default WorkListDrawer;
