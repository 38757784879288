import React from "react";
import { useSelector } from "react-redux";
import SmsSvgN from "../../../assets/img/svg/CusOverviewActionsIcon/SmsSvgN";
import SMS from "../../../assets/img/svg/SMS";
import SmsContactSvg from "../../../assets/img/svg/SmsContactSvg";
import ValidCallSvg from "../../../assets/img/svg/ValidCallSvg";
import ValidWhatsappSvg from "../../../assets/img/svg/ValidWhatsappSvg";
import VoiceBotIcon from "../../../assets/img/svg/VoiceBotIcon";
import { Icon } from "../../../components_v2";

const EsclationContact = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <ValidCallSvg
          color={row?.original?.is_valid_esclation_call ? "#12b112" : "#FD372A"}
          width={18}
          height={18}
        />
        <SMS
          color={row?.original?.is_valid_esclation_sms ? "#12b112" : "#FD372A"}
          width={18}
          height={16}
        />

        <ValidWhatsappSvg
          color={
            row?.original?.is_valid_esclation_whatsapp ? "#12b112" : "#FD372A"
          }
          width={18}
          height={18}
        />
        <Icon
          icon={"voice_bot_status"}
          color={row.original.veac ? "#12b112" : "#FD372A"}
          size={16}
        />
        <SmsContactSvg
          color={
            row?.original?.is_valid_esclation_email ? "#12b112" : "#FD372A"
          }
          width={18}
          height={18}
        />
        <Icon
          icon={"location_outline"}
          color={
            row.original.is_valid_esclation_address ? "#12b112" : "#FD372A"
          }
          size={16}
        />
      </div>
    </>
  );
};
export default EsclationContact;
