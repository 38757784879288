import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";

function User(props) {
  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;
  value = _.get(original, accessor, "");

  let { users, currentOrganization, referenceData } = useContext(MaxyfiContext);

  const user = users?.find(({ id }) => {
    return id == value;
  });

  return (
    <div
      style={{
        // display: "flex",
        // flexDirection: "row",
        // gap: "5px",
        // flex: "1",
        // justifyContent: alignment,
        // alignItems: "center",
        textAlign: alignment,
        width: "100%",
      }}
    >
      {user?.displayName || ""}
    </div>
  );
}

export default User;
