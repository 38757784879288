import React, { useContext, useEffect, useState } from "react";
import {
  QueryBuilder,
  defaultOperators,
  formatQuery,
} from "react-querybuilder";
import { Icon, Typography } from "../../../../components_v2";
import { Controller, useForm } from "react-hook-form";
import { SIZE, TextBox } from "../../../../components/TextBox";
import { FormattedMessage, useIntl } from "react-intl";
import CustomCombinator from "./CustomCombinator";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import { KIND, TextButton } from "../../../../components/TextButton";
import getSelectValues from "../../../../utils/getSelectValues";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { getRuleEngine, getRuleEngineSingle } from "../../../../services";
import { TextArea } from "../../../../components/TextArea";
import { EDIT, VIEW } from "../../../../constants";
import _ from "lodash";
import queryClient from "../../../../providers/queryClient";
import styles from "../../../../assets/scss/stateDrawer.module.scss";
import "react-querybuilder/dist/query-builder.scss";
import RuleQueryFormat from "../../../../utils/RuleQueryFormat";
import reverseRuleQueryFilter from "../../../../utils/RevereseRuleQueryFilter";
const initialQuery = {
  combinator: "and",
  rules: [
    {
      field: "customer_id",
      operator: "beginsWith",
      value: "",
      disabled: false,
      id: 1,
    },
  ],
};

const StateRuleDrawer = ({
  type,
  actionType,
  columnsState,
  addService,
  editService,
  isRuleId,
  gridStructureData,
  isEditState,
  setRulePreview,
  setIsOpen,
}) => {
  const [query, setQuery] = useState(initialQuery);
  const [lockedItems, setLockedItems] = useState({});
  const intl = useIntl();
  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    appAccessRest,
  } = useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      nam: "",
      desc: "",
      type: "ST_DIS",
      rul: query,
    },
  });

  const inputType = {
    STRING: "text",
    LOV: "select",
    DATE: "date",
    NUMBER: "number",
  };

  const GridStructure = columnsState?.reduce((prev, curr) => {
    if (curr.nested) {
      return [...prev, ...(curr?.nested ? curr?.nested : [])];
    }
    return [...prev, curr];
  }, []);

  let queryStrucher =
    GridStructure &&
    GridStructure?.reduce((prev, curr) => {
      //
      if (!curr.filter_by) {
        return prev;
      }

      let type =
        curr.type && inputType[curr.type] ? inputType[curr.type] : curr.type;

      let queryData = {
        name: curr.accessor,
        label: intl.formatMessage({ id: curr.literal_id }),

        ...(curr.type == "DATE"
          ? {
              operators: defaultOperators.filter(
                (op) =>
                  op.name === "between" ||
                  op.name === "notBetween" ||
                  op.name === ">" ||
                  op.name === "<"
              ),
            }
          : {}),

        ...(curr.type == "LOV" && curr.rd_name && referenceData[curr.rd_name]
          ? {
              values: referenceData[curr.rd_name].map((op) => {
                return {
                  name: op.id,
                  label: op.label,
                };
              }),
              valueEditorType: type,
            }
          : { inputType: type }),
      };

      prev.push(queryData);

      if (curr.type == "DATE") {
        let creatObject = {
          name: curr.accessor + "_dynamic",
          label: intl.formatMessage({ id: curr.literal_id + "_dynamic" }),
          inputType: "text",
          operators: defaultOperators.filter(
            (op) =>
              op.name === "between" ||
              op.name === "notBetween" ||
              op.name === ">" ||
              op.name === "<"
          ),
          defaultValue: "{{Today+0}}",
          isDynamic: true,
        };
        prev.push(creatObject);
      }

      return prev;
    }, []);

  const addRoleStateDisclosureService = useMutation(
    (data) =>
      addService({
        ...data,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(`RULE_ENGINE_${currentOrganization}`);
        setIsOpen(false);

        reset();
      },
    }
  );
  const editRoleStateDisclosureService = useMutation(
    (data) => editService(isRuleId, currentOrganization, data),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(`RULE_ENGINE_${currentOrganization}`);
        setRulePreview(VIEW);
        queryClient.refetchQueries(
          `SINGLE_RULE_${currentOrganization}_${isRuleId}`
        );
      },
    }
  );

  let newFilter = RuleQueryFormat({
    gridStructure: gridStructureData,
    timeZone: currentDefaultFormatDetails.time_zone,
    customerFilter: query,
  });

  const onSubmit = async (data) => {
    let values = {
      nam: data?.nam,
      desc: data?.desc,
      type: actionType,
      rul: newFilter,
    };
    let editValues = {
      desc: data.desc,
      rul: query,
    };

    try {
      if (isEditState && isRuleId) {
        await editRoleStateDisclosureService.mutateAsync(editValues);
      } else {
        await addRoleStateDisclosureService.mutateAsync(values);
      }
    } catch (error) {
      console.error("Submission failed", error);
    }
  };

  const {
    data: singleRuleData,
    isFetched: singleRuleFetching,
    isError: singleRuleError,
    refetch,
  } = useQuery(
    [`SINGLE_RULE_${currentOrganization}_${isRuleId}`],
    async () => {
      return await getRuleEngineSingle({
        organization: currentOrganization,
        id: isRuleId,
      });
    },
    {
      enabled: !!isRuleId, // Only run query if isRuleId is defined
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const singleValueRule = reverseRuleQueryFilter({
      gridStructure: gridStructureData,
      timeZone: currentDefaultFormatDetails.time_zone,
      customerFilter: _.get(singleRuleData, "data.doc.rul", ""),
    });
    if (type === VIEW && isRuleId) {
      const viewValues = {
        nam: _.get(singleRuleData, "data.doc.nam", ""),
        desc: _.get(singleRuleData, "data.doc.desc", ""),
        type: "ST_DIS",
        rul: singleValueRule,
      };

      reset(viewValues);
      setQuery(viewValues.rul);
    } else if (type === "COPY" && isRuleId) {
      const copyValues = {
        desc: _.get(singleRuleData, "data.doc.desc", ""),
        type: "ST_DIS",
        rul: singleValueRule,
      };
      reset(copyValues); // Reset the form with COPY context values
      setQuery(copyValues.rul);
    } else if (type === "EDIT" && isRuleId) {
      const viewValues = {
        nam: _.get(singleRuleData, "data.doc.nam", ""),
        desc: _.get(singleRuleData, "data.doc.desc", ""),
        type: "ST_DIS",
        rul: singleValueRule,
      };
      reset(viewValues);
      setQuery(viewValues.rul);
    } else {
      setQuery(initialQuery);
    }
  }, [isRuleId, _.get(singleRuleData, "data", null), reset, type]);

  const handleReset = () => {
    const singleValueRule = reverseRuleQueryFilter({
      gridStructure: gridStructureData,
      timeZone: currentDefaultFormatDetails.time_zone,
      customerFilter: _.get(singleRuleData, "data.doc.rul", ""),
    });
    if (type === "COPY" && isRuleId) {
      const copyValues = {
        desc: _.get(singleRuleData, "data.doc.desc", ""),
        type: "ST_DIS",
        rul: singleValueRule,
      };
      reset(copyValues); // Reset with COPY context values
      setQuery(copyValues.rul);
    } else if (type === EDIT && isRuleId) {
      const copyValues = {
        nam: _.get(singleRuleData, "data.doc.nam", ""),
        desc: _.get(singleRuleData, "data.doc.desc", ""),
        type: "ST_DIS",
        rul: singleValueRule,
      };
      reset(copyValues); // Reset with COPY context values
      setQuery(copyValues.rul);
    } else {
      setQuery(initialQuery);
      reset({
        nam: "",
        desc: "",
        type: "ST_DIS",
        rul: query,
      }); // Reset to default values if no specific context is matched
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {type === "ADD" ? (
        <Typography type="h3" className="regular">
          Add Rule
        </Typography>
      ) : (
        <></>
      )}
      {/* {type === "VIEW" ? (
        <Typography type="h3" className="regular">
          View Rule
        </Typography>
      ) : (
        <></>
      )} */}
      {type === "COPY" ? (
        <Typography type="h3" className="regular">
          Copy Rule
        </Typography>
      ) : (
        <></>
      )}
      {type === "EDIT" && <></>}
      <form>
        <div
          className="name_desc_rule_closure_drawer"
          style={{ display: "flex", alignItems: "center", gap: "15px" }}
        >
          <Controller
            name="nam"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <>
                <TextArea
                  {...field}
                  height={"auto"}
                  paddingTextArea={"5px"}
                  requiredAstric={true}
                  fullWidth
                  disabled={type === VIEW ? true : type === EDIT ? true : false}
                  name={field.name}
                  size={SIZE.compact}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "ent_rul_name",
                  })}
                  // placeholder={intl.formatMessage({
                  //   id: "rule_desc",
                  // })}
                  value={field.value}
                  // onChange={(e) => {
                  //   updateLocalState("first_name", e.target.value);
                  // }}
                />
              </>
            )}
          />
          <Controller
            name="desc"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <>
                <TextArea
                  {...field}
                  height={"auto"}
                  paddingTextArea={"5px"}
                  requiredAstric={true}
                  fullWidth
                  disabled={type === VIEW ? true : false}
                  name={field.name}
                  size={SIZE.compact}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "rule_desc",
                  })}
                  // placeholder={intl.formatMessage({
                  //   id: "rule_desc",
                  // })}
                  value={field.value}
                  // onChange={(e) => {
                  //   updateLocalState("first_name", e.target.value);
                  // }}
                />
              </>
            )}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Typography type="p" className="text-secondary" subType="regular">
            Set Your Preferred Rule engine
          </Typography>
          {/* <div className={styles}> */}
          <QueryBuilder
            controlElements={{
              addGroupAction: ({ handleOnClick, ...rest }) => (
                <TextButton
                  type="button"
                  kind={KIND.tertiary}
                  size={SIZE.mini}
                  disabled={type === VIEW ? true : false}
                  startEnhancer={() => (
                    <Icon
                      icon="plus"
                      color={type === VIEW ? "#333860" : "#333860"}
                    />
                  )}
                  onClick={handleOnClick}
                >
                  <span style={{ color: "#333860", fontWeight: "400" }}>
                    Add Group
                  </span>
                </TextButton>
              ),

              addRuleAction: ({ handleOnClick, ...rest }) => (
                <TextButton
                  type="button"
                  kind={KIND.tertiary}
                  size={SIZE.mini}
                  disabled={type === VIEW ? true : false}
                  startEnhancer={() => (
                    <Icon
                      icon="plus"
                      color={type === VIEW ? "#333860" : "#333860"}
                    />
                  )}
                  onClick={handleOnClick}
                >
                  <span style={{ color: "#333860", fontWeight: "400" }}>
                    Add Rule
                  </span>
                </TextButton>
              ),

              combinatorSelector: ({ handleOnChange, ...rest }) => (
                <CustomCombinator
                  {...rest}
                  handleOnChange={handleOnChange}
                  type={type}
                />
              ),
              removeRuleAction: ({ handleOnClick, ...rest }) => (
                <Icon
                  style={{
                    cursor: type === VIEW ? "not-allowed" : "pointer",
                  }}
                  icon="delete_outline"
                  isPressable
                  onClick={type === VIEW ? () => {} : handleOnClick}
                />
              ),
              removeGroupAction: ({ handleOnClick, ...rest }) => (
                <Icon
                  style={{
                    cursor: type === VIEW ? "not-allowed" : "pointer",
                  }}
                  icon="delete_outline"
                  isPressable
                  onClick={type === VIEW ? () => {} : handleOnClick}
                />
              ),
              lockRuleAction: ({ handleOnClick, disabled, ...rest }) => {
                return (
                  <CheckBoxBaseweb
                    checkmarkType={STYLE_TYPE.toggle_round}
                    checked={!disabled}
                    disabled={type === VIEW ? true : false}
                    onChange={handleOnClick}
                    labelPlacement={LABEL_PLACEMENT.right}
                  />
                );
              },
              lockGroupAction: ({ handleOnClick, disabled, ...rest }) => {
                return (
                  <CheckBoxBaseweb
                    checkmarkType={STYLE_TYPE.toggle_round}
                    checked={!disabled}
                    onChange={handleOnClick}
                    disabled={type === VIEW ? true : false}
                    labelPlacement={LABEL_PLACEMENT.right}
                  />
                );
              },
            }}
            controlClassnames={{
              // queryBuilder: "mx-rule-query-builder-group",
              queryBuilder: "queryBuilder",
              addRule: "mx-qb-add-rule",
              addGroupAction: "mx-qb-rule-group_role",
              operators: "mx-qb-operators",
              removeRule: <Icon icon="delete_outline" />,
              fields: "mx-qb-fields",
              rule: "mx-qb-rule",
              value: "mx-qb-value",
              body: "mx-rule-rule-group-body",
              header: "mx-rule-header-group",
              ruleGroup: "ruleGroup",
            }}
            fields={queryStrucher}
            query={query}
            onQueryChange={setQuery}
            showLockButtons
            // disabled={type === VIEW}
          />
          {/* </div> */}

          {type === VIEW ? (
            <></>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                gap: "10px",
                // borderTop: "0.5px solid #CDCED9",
                paddingTop: "10px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "100px" }}>
                <TextButton
                  type="button"
                  kind={KIND.tertiary}
                  size={SIZE.mini}
                  fullWidth
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Reset
                </TextButton>
              </div>
              <div>
                <TextButton
                  type="button"
                  kind={KIND.primary}
                  size={SIZE.mini}
                  fullWidth
                  disabled={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </TextButton>
              </div>
            </div>
          )}

          {/* <h4>Query</h4>
        <pre>
          <code>{formatQuery(query, "json")}</code>
        </pre> */}
        </div>
      </form>
    </div>
  );
};

export default StateRuleDrawer;
