import { Modal, SIZE } from "baseui/modal";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SelectBox } from "../../../../../../components/SelectBox";
import { TextArea } from "../../../../../../components/TextArea";
import { TextButton } from "../../../../../../components/TextButton";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { getCommunicationTemplateList } from "../../../../../../services";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";
import ReactQuill from "react-quill";
// import { uploadEmailAttachment } from "../../redux/createWorkFlow/actions";
import { useDispatch, useSelector } from "react-redux";
import { uploadEmailFile } from "../../../../../../redux/actions";

const TemplateAttachment = ({ isTemplateModalOpen, close }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const attachments = useSelector((e) => e.createWorkFlowReducer.attachments);

  const { currentOrganization, userInfo, referenceData } =
    useContext(MaxyfiContext);

  const [previewData, setPreview] = useState("");

  const { data } = useQuery(
    [
      `ATTACHMENT_TEMPLATE-${currentOrganization}`,
      {
        filters: { type: "letter" },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getCommunicationTemplateList({
        filters,
        organization: currentOrganization,

        clientCode: [currentOrganization],
      });
    }
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    shouldUnregister: true,
    defaultValues: {},
  });

  const onSubmit = (data) => {
    let templateData = {
      type: "TEMPLATE",
      name: previewData.name,
      ref: previewData._id,
    };
    const attachment = attachments.find(({ ref }) => ref === templateData.ref);

    if (!attachment) {
      //   dispatch(uploadEmailAttachment(templateData));
      dispatch(uploadEmailFile(templateData));
      close();
      setPreview("");
    } else {
      setError("res", { type: "custom", message: "this file already exists" });
      //
    }
  };

  return (
    <Modal
      onClose={() => {
        setPreview("");
        close();
      }}
      isOpen={isTemplateModalOpen}
      size={SIZE.auto}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            textAlign: "center",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
        Dialog: {
          style: ({ $theme }) => ({
            // padding: "50px 32px",
            // height: "500px",
            // width: "660px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            boxShadow: "0px 4px 4px 0px #33386050 ",
          }),
        },
      }}
    >
      {/* <AdhocModalTypes close={close} /> */}
      <div style={{ width: "500px", margin: "40px 40px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="res"
            defaultValue={[]}
            control={control}
            rules={{
              required: "Required",
            }}
            render={({ field }) => (
              <SelectBox
                // disabled={disableView}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "template_id",
                })}
                placeholder={intl.formatMessage({
                  id: "template_id",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);

                  if (!e.value.length) {
                    setPreview("");
                  } else {
                    const findAttachmentData = data.data.docs.find(
                      ({ _id }) => _id === e.value[0].id
                    );
                    setPreview(findAttachmentData);
                  }

                  //
                }}
                options={
                  data &&
                  data.data.docs.map((i) => {
                    return { label: i.name, id: i._id };
                  })
                }
              />
            )}
          />
          <ReactQuill
            readOnly={true}
            style={{
              minHeight: "30vh",
              maxHeight: "70vh",
            }}
            value={previewData && previewData.content?.en.value}
            modules={{
              toolbar: 0,
            }}
          />
          {/* <TextArea disabled={true} value={previewData} /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <div>
              <TextButton
                size={SIZE.compact}
                type="button"
                onClick={() => {
                  setPreview();
                  close();
                }}
                $style={{
                  // marginTop: "20px",
                  width: "126px",
                  height: "36px",
                  borderRadius: "5px",
                  background: "#fff",
                  color: "#333860",
                  ":hover": {
                    backgroundColor: "#fff",
                    color: "#333860",
                  },
                }}
              >
                Cancel
              </TextButton>
            </div>
            <div>
              <TextButton
                $style={{
                  // marginTop: "20px",
                  width: "126px",
                  height: "36px",
                  borderRadius: "5px",
                  background: "#516BEB",

                  boxShadow: "0px 4px 4px 0px #516beb25",
                  ":hover": {
                    boxShadow: "inset 0px 4px 4px 0px #00000025",
                    backgroundColor: "#0348C0",
                  },
                }}
                isLoading={isSubmitting}
                disabled={isSubmitting}
                size={SIZE.compact}
                type="submit"
                onClick={() => {
                  // addFile();
                }}
              >
                Submit
              </TextButton>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default TemplateAttachment;
