import React, { useContext, useState, useEffect } from "react";
import {
  HeadingXLarge,
  ParagraphLarge,
  LabelLarge,
  LabelSmall,
  LabelMedium,
} from "baseui/typography";
import Email from "../../../../../assets/img/svg/CustomerTemplate/Email";
import Info from "../../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Info";
import InfoTag from "../../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/InfoTag";
import InfoPortal from "../../../../../assets/img/svg/InfoPortal";
import { TextBoxTag } from "../../../../../components/TextBoxTag/TextBoxTag";
import { FormControl } from "baseui/form-control";
import {
  emailTempalteBuffer,
  executePaymentAction,
  getOrganization,
  letterPreviewPost,
} from "../../../../../services";
import AllActionPopOver from "../AllActionPopOver";
import { isOpenModal } from "../../../../../redux/actions";
import { removeEmailAttachedFile } from "../../../../../redux/actions";
import { useDispatch } from "react-redux";
import Files from "../../../../../assets/img/svg/Files";
import { useIntl } from "react-intl";
import { TextBox } from "../../../../../components/TextBox";
import { TextButton, SIZE, KIND } from "../../../../../components/TextButton";
import { useForm, Controller, useFieldArray, get } from "react-hook-form";
import { TextArea } from "../../../../../components/TextArea";
import Sms from "../../../../../assets/img/svg/CustomerOverViewIcon/Sms";
import { SelectBox } from "../../../../../components/SelectBox";
import LeftSmallArrow from "../../../../../assets/img/svg/LeftSmallArrow";
import { COMMUNICATION_TEMPLATE } from "../../../../../constants";
import { useQuery, useMutation } from "react-query";
import { Button } from "baseui/button";
import ClearFile from "../../../../../assets/img/svg/ClearFile";
import TemplateAttachment from "./EmailAttachments/TemplateAttachment";
import {
  getCommunicationTemplateList,
  executeCustomerAction,
  getCustomerContactDetail,
  getCommunicationTemplate,
  getCommunicationTemplateFinalContent,
  executeAdhocCustomerAction,
} from "../../../../../services";
import { MaxyfiContext } from "../../../../../providers/MaxyfiProvider";
import { useSelector } from "react-redux";
import getSelectValues from "../../../../../utils/getSelectValues";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { refetchActions } from "../../../../../redux/actions";
import ReactQuill from "react-quill";
import UploaderFile from "./EmailAttachments/UploaderFile";
import Attachment from "../../../../../assets/img/svg/Attachment";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { ParagraphMedium } from "baseui/typography";
import SystemAttachment from "./EmailAttachments/SystemAttachment";
import { addCOAdhocEmailAttachment } from "../../../../../redux/actions";
import { refetchInvoiceActions } from "../../../../../redux/invoiceOverview/action";
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import { useDropzone } from "react-dropzone";
import EmailAttachment from "../../../../../components/EmailAttachment/EmailAttachment";
import {
  addEmailAttachmentFromDesktop,
  customerDriveOpen,
  documentDriveQuickAction,
  refetchDocumentData,
  typeToInitial,
  updateEmailAttachmentFromDesktop,
} from "../../../../../redux/customerOverview/DMSystem/action";
import {
  addEmailInvoiceAttachmentFromDesktop,
  invoiceDriveOpen,
  invoiceTypeForm,
  refetchInvoiceDocumentData,
} from "../../../../../redux/invoiceOverview/DMSystemINV/action";
import setSelectValues from "../../../../../utils/setSelectValues";
import {
  addEmailAttachmentQuickActionFromDesktop,
  initialQAReducer,
  invDriveOpenQA,
} from "../../../../../redux/invoiceOverview/invoiceQA/action";

const EmailAction = ({
  getParams,
  id,
  entity,
  tags,
  removeAttchFile,
  updatingDeskUpload,
  setAttachment,
  action = "CUSTOMER",
  setIsSendEmailOpen,
  isSendEmailOpen,
}) => {
  const {
    referenceData,
    currentOrganization,
    userInfo,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  const [customerEmails, setCustomerEmailsData] = useState([]);

  const [emailOptions, setEmailOptions] = useState([]);

  // const [settingData, setSettingData] = useState(null);
  const intl = useIntl();
  const DataType = useSelector((s) => s.settingReducer.type);
  const dispatch = useDispatch();
  // const { id } = useParams();
  const [isTemplateModalOpen, setTemplateModalOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const attachments = useSelector(
    (s) => s.customerOverviewReducer.adhocAttachments
  );
  const slicedTwoValue = attachments.slice(0, 2);
  const attachmentLength = Object.keys(attachments).length;

  const slicedLength = Object.keys(slicedTwoValue).length;

  const entityName = useSelector(
    (s) => s.invoiceOverviewReducer.invoice_entity
  );

  let organizationDetail = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const { refetchActions } = useSelector((s) => s.customerOverviewReducer);

  const smsGateWayDatas = currentDefaultFormatDetails?.email?.value;
  const { emailAttachment } = useSelector((s) => s.customerDocumentReducer);
  const { emailAttachmentQuickActionInvoice } = useSelector(
    (s) => s.invoiceQAReducer
  );

  let emailCardMapingData =
    entity === "invoices" ? emailAttachmentQuickActionInvoice : emailAttachment;

  let scanFinalStatus =
    emailCardMapingData &&
    emailCardMapingData.some((e) => e.scanStatus !== "SUCCESS");

  let template = [];
  let emailAttached = [];

  const emailAttachments =
    emailCardMapingData &&
    emailCardMapingData.map((e) => {
      if (e.type == "TEMPLATE") {
        template.push(e.fileRef);
      } else {
        emailAttached.push(e.fileRef);
      }
    });

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      cc: [],
      bcc: [],
    },
  });

  let template_id = watch("template_id.0");

  let ccValue = watch("cc");

  function Modalclose() {
    setIsModalOpen(false);
    setTemplateModalOpen(false);
  }

  useEffect(() => {
    const getTemplateContent = async () => {
      let templateContent = await getCommunicationTemplateFinalContent({
        id: id,
        organization: currentOrganization,
        templateId: template_id.id,
        entity: entity,
        ...(action === "PAYMENT" ? { paymentId: isSendEmailOpen?.id } : {}),
      });

      if (
        templateContent.data &&
        templateContent.data.doc &&
        template_id &&
        template_id.id
      ) {
        let { value, subject } = templateContent.data.doc;

        setValue("content", value.replaceAll(String.fromCharCode(92), ""));
        setValue("subject", subject);

        let emailAttachedData = [];
        if (template_id && template_id.attachments) {
          emailAttachedData =
            template_id.attachments && Array.isArray(template_id.attachments)
              ? template_id.attachments.map((e) => {
                  return {
                    ...e,
                    fileRef: e.id,
                    isTemplate: e.type === "TEMPLATE" ? true : false,
                    scanStatus: "SUCCESS",
                  };
                })
              : [];
        }
        dispatch(setAttachment(emailAttachedData));
      }
    };

    if (template_id && template_id && template_id.id) {
      getTemplateContent();
    }
  }, [template_id?.id]);

  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `${COMMUNICATION_TEMPLATE}-ADHOC-${currentOrganization}`,
      {
        filters: { disable: false, type: "email", tags: `${tags}` },
      },
    ],
    async ({ queryKey }) => {
      let { page, page_size, sortBy, filters, type } = queryKey[1];
      return await getCommunicationTemplateList({
        organization: currentOrganization,
        clientCode: [currentOrganization],
        filters,
        type,
      });
    }
  );

  let validTem =
    action === "PAYMENT"
      ? data && data.data && data.data.docs
      : data &&
        data.data &&
        data.data.docs.filter((item) => {
          if (
            item &&
            item.tags &&
            item.tags.length == 1 &&
            item?.tags?.[0] == tags
          ) {
            return item;
          }
        });

  const executeActionMutation = useMutation(
    (formValues) =>
      executeAdhocCustomerAction({
        ...formValues,
        id: id,
        organization: currentOrganization,
        entity: entity,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        dispatch(isOpenModal(false));
        if (entityName === "INVOICE") {
          dispatch(refetchInvoiceActions());
          dispatch(refetchInvoiceDocumentData());
        } else {
          dispatch(refetchDocumentData());
          dispatch(refetchActions());
        }
      },
    }
  );

  const executePaymentMutation = useMutation(
    (formValues) =>
      executePaymentAction({
        ...formValues,
        id: id,
        organization: currentOrganization,
        entity: entity,
        paymentId: isSendEmailOpen?.id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        setIsSendEmailOpen({ id: "", isOpen: false });
      },
    }
  );

  const customerContacts = useQuery(
    [`customer-contacts-${getParams}-${id}-${refetchActions}`],

    async () => {
      return await getCustomerContactDetail({
        organization: currentOrganization,
        customerId: getParams,
        // invoiceId: entityName === "INVOICE" || id ? id : null,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      customerContacts &&
      customerContacts.data &&
      customerContacts.data.data &&
      customerContacts.data.data.docs &&
      Array.isArray(customerContacts.data.data.docs)
    ) {
      let hasPrimaryContact = false;
      let primaryContacts = customerContacts.data.data.docs.reduce(
        (prev, curr) => {
          let { phone, landline, email, address, ...rest } = curr;
          let nestedContacts = email
            .map(({ status, email_status, ...nRest }) => {
              if (
                entityName === "INVOICE" &&
                curr.primary_invoices &&
                Array.isArray(curr.primary_invoices) &&
                curr.primary_invoices.includes(id)
              ) {
                hasPrimaryContact = true;
              }

              return {
                id: nRest?._id,
                recipient_id: curr?._id,
                email_status,
                status,
                designation: curr.designation,
                primary_invoices: curr.primary_invoices,
              };
            })
            .filter(
              (e) =>
                (e.status === "VALID" || e.status === "LEAD") &&
                e.email_status === "VALID" &&
                ((!hasPrimaryContact && e.designation === "primary_contact") ||
                  (hasPrimaryContact && e.primary_invoices.includes(id)))
            );

          return [...prev, ...nestedContacts];
        },
        []
      );

      setValue("recipients", primaryContacts);
    }
  }, [customerContacts.isLoading]);

  const fileSize = 10000000;
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 1MB`,
      };
    }
  }

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: {
        "image/jpg": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      validator: uploadLogoValidation,

      onDrop: (acceptedFiles) => {
        let arrObj = acceptedFiles.map((fileDoc) => {
          let { path, type } = fileDoc;

          let splitType = type.split("/");

          return {
            name: path,
            type: splitType[1],
            mimeType: type,
            fileDoc,
          };
        });
        if (entity === "invoices") {
          dispatch(addEmailAttachmentQuickActionFromDesktop(arrObj));
        } else {
          dispatch(addEmailAttachmentFromDesktop(arrObj));
        }
        // dispatch(emailAttachedFile(acceptedFiles));
        // setAddedAttachment(
        //   acceptedFiles.map((file) =>
        //     Object.assign(file, {
        //       preview: URL.createObjectURL(file),
        //     })
        //   )
        // );
      },
      // validator: uploadLogoValidation,
    });

  let { recipients } = watch();

  const onSubmit = async (data) => {
    let recipients = [];
    if (data && data.recipients) {
      data &&
        data?.recipients?.map((e) => {
          //
          recipients?.push({
            id: e?.id,
            recipient_id: e?.recipient_id,
          });
        });
    }

    let values = {
      ...data,
      ...(action !== "PAYMENT" && { action_type: "email" }),

      template_id: getSelectValues(data.template_id),
      // recipients: getSelectValues(data.recipients, true),
      recipients: recipients,
      cc: data.cc,
      bcc: data.bcc,
      // attachments: attachments,
      attachments: emailAttached,
      template_attachments: template,
      is_include_invoice: data.is_include_invoice,
    };

    if (action === "PAYMENT") {
      await executePaymentMutation.mutateAsync(values);
    } else {
      await executeActionMutation.mutateAsync(values);
    }
  };

  let Block = ReactQuill.Quill.import("blots/block");

  Block.tagName = "DIV";
  ReactQuill.Quill.register(Block, true);

  let Embed = ReactQuill.Quill.import("blots/embed");
  class MxTable extends Embed {
    static create(paramValue) {
      let node = super.create();
      node.innerHTML = paramValue;
      return node;
    }

    static value(node) {
      return node.innerHTML;
    }
  }

  MxTable.blotName = "table";
  MxTable.tagName = "mx-table";
  ReactQuill.Quill.register(MxTable);

  return (
    <>
      {/* <TemplateAttachment
        isTemplateModalOpen={isTemplateModalOpen}
        close={Modalclose}
        // content={content}
      /> */}
      <SystemAttachment
        addAction={addCOAdhocEmailAttachment}
        removeAction={addCOAdhocEmailAttachment}
        isModalOpen={isModalOpen}
        close={Modalclose}
      />
      <form
        style={{
          gridColumn: "2/5",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          style={{
            height: "100%",
          }}
        >
          <div
            // onSubmit={handleSubmit(onSubmit)}
            style={{
              // display: "grid",
              // gridTemplateColumns: "repeat(10,50fr)",
              // gridTemplateRows: "repeat(12,10fr)",
              display: "flex",
              height: "100%",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div
              style={
                action !== "PAYMENT"
                  ? { padding: "15px 35px" }
                  : { padding: "0" }
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingTop: "5px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {action == "PAYMENT" ? (
                    <LabelLarge>Send payment receipt via email</LabelLarge>
                  ) : (
                    <>
                      <Email />
                      <HeadingXLarge>Email</HeadingXLarge>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              style={
                action !== "PAYMENT"
                  ? {
                      padding: "0px 35px",
                      // height: "1000%",
                      height: "39.5vh",
                      overflow: "scroll",
                    }
                  : { padding: "10px 10px 0px 0px", overflow: "scroll" }
              }
            >
              <Controller
                name="template_id"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "template_id",
                    })}
                    placeholder={intl.formatMessage({
                      id: "template_id",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                      if (entity === "invoices") {
                        dispatch(initialQAReducer());
                      } else {
                        dispatch(typeToInitial());
                      }
                    }}
                    options={
                      validTem &&
                      validTem.map((i) => {
                        return {
                          ...i,
                          label: `${i.name} (${
                            i.language
                              ? i.language.toLocaleUpperCase()
                              : "en".toLocaleUpperCase()
                          })`,
                          id: i._id,
                        };
                      })
                    }
                  />
                )}
              />
              <Controller
                name="recipients"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    requiredAstric={true}
                    noResultMessage={`${"Email ID is not available for the consumer contact."}${" Use the Edit / Add option at consumer contact to capture Email ID"}`}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "recipients",
                    })}
                    placeholder={intl.formatMessage({
                      id: "recipients",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    multi
                    options={
                      customerContacts &&
                      customerContacts.data &&
                      customerContacts.data.data &&
                      customerContacts.data.data.docs &&
                      Array.isArray(customerContacts.data.data.docs)
                        ? customerContacts.data.data.docs.reduce(
                            (prev, curr) => {
                              let { phone, landline, email, address, ...rest } =
                                curr;
                              let nestedContacts = email
                                .map(({ status, email_status, ...nRest }) => {
                                  let designation =
                                    referenceData &&
                                    referenceData["recipient_type"]
                                      ? referenceData["recipient_type"].find(
                                          (rf) => rf?.id === curr?.designation
                                        )
                                      : "";

                                  return {
                                    label: `${curr?.first_name} - ${designation?.label} - ${nRest?.value}`,
                                    id: nRest?._id,
                                    recipient_id: curr?._id,
                                    email_status,
                                    status,
                                  };
                                })
                                .filter(
                                  (e) =>
                                    (e.status === "VALID" ||
                                      e.status === "LEAD") &&
                                    e.email_status === "VALID"
                                );

                              return [...prev, ...nestedContacts];
                            },
                            []
                          )
                        : []
                    }
                  />
                )}
              />
              <Controller
                name="cc"
                control={control}
                render={({ field }) => (
                  <>
                    <TextBoxTag
                      // disabled={disableContent}
                      {...field}
                      name={field.name}
                      caption={`${`${`Business Email `}${smsGateWayDatas}${" will be kept in CC. "}${`You can review the Business Email in Organization Settings - Contact Details`}`}`}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "cc1",
                      })}
                      placeholder={intl.formatMessage({
                        id: "cc",
                      })}
                      value={field.value}
                      validation={true}
                      validationFunc={(value) => {
                        let rg = new RegExp(
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                        );
                        return rg.test(value);
                      }}
                      setError={() =>
                        setError(field.name, {
                          type: String,
                          message: "Invalid Email ID",
                        })
                      }
                      clearErrors={() => {
                        clearErrors(field.name, {
                          message: "",
                        });
                      }}
                    />
                  </>
                )}
              />
              {/* <Controller
                name="cc"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    multi
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "cc",
                    })}
                    placeholder={intl.formatMessage({
                      id: "cc",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={
                      customerContacts &&
                      customerContacts.data &&
                      customerContacts.data.data &&
                      customerContacts.data.data.docs.map((e) => {
                        return {
                          label: `${e.first_name || ""} - ${
                            e.designation || ""
                          }`,
                          id: e._id,
                        };
                      })
                    }
                  />
                )}
              /> */}
              {/* <Controller
                name="bcc"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    multi
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "bcc",
                    })}
                    placeholder={intl.formatMessage({
                      id: "bcc",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={
                      customerContacts &&
                      customerContacts.data &&
                      customerContacts.data.data &&
                      customerContacts.data.data.docs.map((e) => {
                        return {
                          label: `${e.first_name || ""} - ${
                            e.designation || ""
                          }`,
                          id: e._id,
                        };
                      })
                    }
                  />
                )}
              /> */}

              <Controller
                name="bcc"
                control={control}
                render={({ field }) => (
                  <>
                    <TextBoxTag
                      // disabled={disableContent}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "bcc1",
                      })}
                      placeholder={intl.formatMessage({
                        id: "bcc",
                      })}
                      value={field.value}
                      validation={true}
                      validationFunc={(value) => {
                        let rg = new RegExp(
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                        );
                        return rg.test(value);
                      }}
                      setError={() =>
                        setError(field.name, {
                          type: String,
                          message: "Email is Not Valid",
                        })
                      }
                      clearErrors={() => {
                        clearErrors(field.name, {
                          message: "",
                        });
                      }}
                    />
                  </>
                )}
              />
              <Controller
                name="subject"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "subject",
                    })}
                    placeholder={intl.formatMessage({
                      id: "subject",
                    })}
                    value={field.value}
                  />
                )}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div className="cp-quill cp-quill--action-view">
                    <Controller
                      name="content"
                      control={control}
                      render={({ field }) => {
                        return (
                          <ReactQuill
                            theme="snow"
                            value={field.value}
                            onChange={(content) => {
                              field.onChange(content);
                            }}
                            placeholder={"Write something awesome..."}
                            formats={[
                              "header",
                              "font",
                              "background",
                              "color",
                              "code",
                              "size",
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                              "list",
                              "bullet",
                              "indent",
                              "script",
                              "align",
                              "direction",
                              "link",
                              "image",
                              "code-block",
                              "table",
                            ]}
                            style={{ height: "fit-content" }}
                          />
                        );
                      }}
                    />
                  </div>
                  {emailCardMapingData?.map((attachment, index) => {
                    let { tempId } = attachment;
                    return (
                      <EmailAttachment
                        key={tempId}
                        {...attachment}
                        id={getParams}
                        currentOrganization={currentOrganization}
                        entity={entity}
                        removeAttchFile={removeAttchFile}
                        updatingDeskUpload={updatingDeskUpload}
                        recipientId={
                          recipients && recipients[0] && recipients[0].id
                        }
                        templateId={
                          template_id && template_id[0] && template_id[0].id
                        }
                        paymentId={isSendEmailOpen?.id}
                        // removeAttchFile={unSelectedDocumentsAddingInvoice}
                      />
                    );
                  })}
                </div>
              </div>
              <div
                className="cp-action-select"
                style={{ paddingRight: "0px", paddingTop: "20px" }}
              >
                <Controller
                  name="comment"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      size={SIZE.default}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "user_comments",
                      })}
                      placeholder={intl.formatMessage({
                        id: "user_comments",
                      })}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </div>

            <div
              style={
                action === "PAYMENT"
                  ? {
                      background: "#ffffff",
                      // width: "100%",
                      // height: "70px",
                      padding: "0px",
                    }
                  : {
                      background: "#F9F9F9",
                      // width: "100%",
                      height: "70px",
                      padding: "15px",
                      borderTop: "1px solid #CDCED9",
                      gridRow: "12/13",
                    }
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", gap: "35px" }}>
                  <StatefulPopover
                    content={({ close }) => (
                      <div className="cp_email_attachement">
                        {/* [{filename:"From Desktop",}] */}
                        <ParagraphMedium
                          $style={{}}
                          onClick={() => {
                            open();
                            close();
                          }}
                        >
                          From Desktop
                        </ParagraphMedium>
                        <ParagraphMedium
                          onClick={() => {
                            if (entity === "invoices") {
                              dispatch(invoiceDriveOpen(true));
                              close();
                            } else {
                              dispatch(customerDriveOpen(true));
                              close();
                            }
                          }}
                        >
                          From Consumer Drive
                        </ParagraphMedium>
                      </div>
                    )}
                    returnFocus
                    autoFocus
                  >
                    <Button
                      type="button"
                      kind={KIND.secondary}
                      size={SIZE.mini}
                      overrides={{
                        BaseButton: {
                          style: ({ $theme }) => ({
                            border: "1px solid #CDCED9",
                            borderRadius: "5px",
                          }),
                        },
                      }}
                    >
                      <LabelSmall>Add Attachments</LabelSmall>
                    </Button>
                  </StatefulPopover>
                  {entity !== "customer" &&
                  organizationDetail?.accounting_book !== "CSV" ? (
                    <div>
                      <Controller
                        name="is_include_invoice"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            // disabled={disableView}
                            {...field}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                            }}
                            checkmarkType={STYLE_TYPE.toggle_round}
                            labelPlacement={LABEL_PLACEMENT.left}
                            overrides={{
                              Root: {
                                style: () => ({
                                  display: "flex",
                                  // justifyContent: "space-between",
                                  alignItems: "center",
                                }),
                              },
                              Label: {
                                style: ({ $theme }) => ({
                                  fontSize: "14px",
                                }),
                              },
                              Toggle: {
                                style: ({ $theme }) => ({
                                  backgroundColor: "#ffffff",
                                  width: "12px",
                                  height: "12px",
                                }),
                              },
                              ToggleTrack: {
                                style: ({ $theme, $checked }) => ({
                                  backgroundColor: $checked
                                    ? "#516BEB"
                                    : "#adadad",
                                  width: "30px",
                                  paddingLeft: "3px",
                                  height: "17px",
                                  borderRadius: "20px",
                                }),
                              },
                            }}
                          >
                            Attach Invoice
                          </Checkbox>
                        )}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {/* <Button
                    type="button"
                    kind={KIND.tertiary}
                    size={SIZE.compact}
                    style={{ width: "126px", height: "36px" }}
                    // onClick={() => {
                    //   // defaultCancelValue();
                    // }}
                    onClick={() => {
                      // close();
                      setIsModalOpen(true);
                    }}
                    overrides={{
                      BaseButton: {
                        style: ({ $theme }) => ({
                          border: "1px solid #CDCED9",
                          borderRadius: "5px",
                        }),
                      },
                    }}
                  >
                    <span className="wfm-attachment_btn">
                      <Attachment />{" "}
                    </span>
                    &nbsp;Files
                  </Button> */}

                  <div>
                    {attachmentLength <= 2 ? (
                      <>
                        <div className="wfm-attachment-container">
                          {attachments.map((e, i) => (
                            <div
                              className="wfm-attached_files"
                              style={{ marginTop: "0px" }}
                            >
                              <Files />
                              <ParagraphMedium>
                                <div>{e.name}</div>
                              </ParagraphMedium>
                              <div
                                onClick={() =>
                                  dispatch(removeEmailAttachedFile(i))
                                }
                              >
                                <ClearFile />
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="wfm-attachment-container">
                          {slicedTwoValue.map((e, i) => (
                            <div
                              className="wfm-attached_files"
                              style={{ marginTop: "0px" }}
                            >
                              <Files />
                              <ParagraphMedium>
                                <div>{e.name}</div>
                              </ParagraphMedium>
                              <div
                                onClick={() =>
                                  // dispatch(removeTemplateAttachment(e))
                                  dispatch(removeEmailAttachedFile(i))
                                }
                              >
                                <ClearFile />
                              </div>
                            </div>
                          ))}
                          <div
                            style={{
                              width: "25px",
                              height: "20px",
                              border: "1px solid #ADADAD",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                            }}
                          >
                            +{attachmentLength - slicedLength}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", gap: "5px" }}>
                    <div style={{ width: "125px", height: "36px" }}>
                      <TextButton
                        size={SIZE.compact}
                        fullWidth
                        kind={KIND.tertiary}
                        type="button"
                        onClick={() => {
                          if (action !== "PAYMENT") {
                            dispatch(isOpenModal(false));
                            if (entity === "invoices") {
                              dispatch(initialQAReducer());
                            } else {
                              dispatch(typeToInitial());
                            }
                          } else {
                            setIsSendEmailOpen({ id: "", isOpen: false });
                            dispatch(typeToInitial());
                          }
                        }}
                      >
                        Cancel
                      </TextButton>
                    </div>{" "}
                    <div style={{ width: "125px", height: "36px" }}>
                      <TextButton
                        size={SIZE.compact}
                        fullWidth
                        type="button"
                        isLoading={executeActionMutation.isLoading}
                        disabled={
                          executeActionMutation.isLoading || scanFinalStatus
                        }
                        onClick={async () => {
                          const result = await trigger();
                          if (result) {
                            const values = getValues();
                            onSubmit(values);
                          }
                        }}
                      >
                        Send
                      </TextButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

const compare = (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

export default React.memo(EmailAction, compare);
