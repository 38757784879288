import React from "react";

const Connection = () => {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.50047 2.59599C10.967 2.5961 13.3393 3.56668 15.1269 5.30712C15.2615 5.44149 15.4766 5.43979 15.6092 5.30332L16.896 3.9734C16.9631 3.90418 17.0006 3.81042 17 3.71287C16.9994 3.61531 16.9609 3.52201 16.893 3.4536C12.2011 -1.1512 4.79908 -1.1512 0.107163 3.4536C0.0391973 3.52196 0.000634479 3.61523 7.75932e-06 3.71279C-0.00061896 3.81034 0.0367421 3.90413 0.103824 3.9734L1.39096 5.30332C1.52346 5.44 1.73878 5.4417 1.87331 5.30712C3.66116 3.56656 6.03367 2.59598 8.50047 2.59599ZM8.50045 6.92266C9.85566 6.92257 11.1625 7.43843 12.1671 8.36999C12.3029 8.5022 12.517 8.49933 12.6494 8.36353L13.9347 7.03361C14.0024 6.96386 14.0399 6.86922 14.039 6.77089C14.038 6.67255 13.9986 6.57872 13.9295 6.51039C10.8704 3.59629 6.13306 3.59629 3.07399 6.51039C3.00489 6.57872 2.96546 6.6726 2.96455 6.77096C2.96365 6.86933 3.00133 6.96396 3.06916 7.03361L4.35407 8.36353C4.48652 8.49933 4.70056 8.5022 4.83643 8.36999C5.84032 7.43905 7.14613 6.92323 8.50045 6.92266ZM11.0751 9.83398C11.0771 9.93259 11.0392 10.0277 10.9705 10.0968L8.74719 12.3945C8.68201 12.462 8.59316 12.5 8.50045 12.5C8.40773 12.5 8.31888 12.462 8.2537 12.3945L6.03006 10.0968C5.96137 10.0276 5.92358 9.93251 5.92562 9.8339C5.92765 9.73529 5.96933 9.64191 6.04082 9.57581C7.46068 8.34595 9.54021 8.34595 10.9601 9.57581C11.0315 9.64196 11.0731 9.73537 11.0751 9.83398Z"
        fill="black"
      />
    </svg>
  );
};
export default Connection;
