import React from "react";

const Chat = ({ color = "ffffff", size = "24" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 18C6.71667 18 6.47917 17.9042 6.2875 17.7125C6.09583 17.5208 6 17.2833 6 17V15H19V6H21C21.2833 6 21.5208 6.09583 21.7125 6.2875C21.9042 6.47917 22 6.71667 22 7V19.575C22 20.025 21.7958 20.3375 21.3875 20.5125C20.9792 20.6875 20.6167 20.6167 20.3 20.3L18 18H7ZM6 13L3.7 15.3C3.38333 15.6167 3.02083 15.6875 2.6125 15.5125C2.20417 15.3375 2 15.025 2 14.575V3C2 2.71667 2.09583 2.47917 2.2875 2.2875C2.47917 2.09583 2.71667 2 3 2H16C16.2833 2 16.5208 2.09583 16.7125 2.2875C16.9042 2.47917 17 2.71667 17 3V12C17 12.2833 16.9042 12.5208 16.7125 12.7125C16.5208 12.9042 16.2833 13 16 13H6Z"
        fill={color}
      />
    </svg>
  );
};

export default Chat;
