import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useContext,
  createRef,
  useCallback,
} from "react";
import { useCallbackRef } from "use-callback-ref";
import { TextButton, KIND } from "../../components/TextButton";
// import { ModalButton } from "baseui/modal";
import { LabelLarge, ParagraphLarge, ParagraphMedium } from "baseui/typography";
// import * as ModalButton from "../TextButton";
import workflowModalPic from "../../assets/img/workqueue.png";
import relManagerPic from "../../assets/img/rel-manager.png";
import unSuccessCallPic from "../../assets/img/workqueue.png";
import workQueuePic from "../../assets/img/workqueue.png";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import warningImage from "../../assets/img/warning.png";
import { setInvoiceFilter } from "../../redux/invoiceSummary/action";
import {
  setCusOverviewPageIndex,
  setCustomerSummaryFilter,
  setTableHeaderOrder,
  setTableWidth,
} from "../../redux/customerSummary/action";
import { useSelector } from "react-redux";
import CardMerge from "../../components/Card";
import PaginationWraper from "../../components/Pagination/Pagination";
import { ModalContext } from "../../providers/ModalProvider";
import { useDispatch } from "react-redux";
import {
  getCustomerSummaryGridStructure,
  updateTableCustomerSummaryDraftState,
  setCustomerSummaryTableView,
  setGlobalFilter,
  setCustomerSummarySort,
  setCustomerSummaryPageSize,
  setCustomerSummaryPageIndex,
  setCustomerSummaryGridAndView,
  setNeedsActionFilter,
  setCustomerStageFilter,
  setCustomerPinnedView,
} from "../../redux/customerSummary/action";
import CustomerAction from "../CustomerSummaryNew";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import setTableGridStructure from "../../utils/setTableGridStructure";
import { useDebouncedCallback } from "use-debounce";
import { TextBox, SIZE } from "../../components/TextBox";
import { useForm, Controller } from "react-hook-form";
import { Search } from "baseui/icon";
import AllFilters from "../../components/AllFilters/AllFilters";
import ColumnToogle from "../../components/ColumnToggle/ColumnToggle";
import { useIntl } from "react-intl";
import TableViewPicker from "../../components/TableViewPicker";
import Plus from "../../assets/img/svg/Upload/Plus";
import PlusOne from "../../assets/img/svg/Plus";
import CustomerSummaryPlus from "../../assets/img/svg/CustomerSummaryPlus";
import { SelectBox, inputOverrides } from "../../components/SelectBox";
import AssignWorkflowForm from "../../components/FormModal/AssignWorkflowForm";
import AssignRelationManagerForm from "../../components/FormModal/AssignRelationManagerForm";
import Loader from "../../components/Loader";
import CheckIn from "../../components/FormModal/CheckInForm";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  BULK_ASSIGN_WORKFLOW,
  BULK_ASSIGN_RM,
  BULK_CHECKIN,
  BULK_RM_WORKFLOW,
  BULK_PAUSE,
  BULK_STATUS,
  BULK_CALL_UNSUCESSFUL,
  RESCHEDULR_NEXT_ACTION,
  BULK_SKIP_TRACE,
  ADD_WORKLIST,
  CONSUMER_PORTAL_SETTING_BULK,
} from "../../providers/RBACProvider/permissionList";
import { PLACEMENT } from "baseui/popover";
import { Input } from "baseui/input";
import PauseActionForm from "../../components/FormModal/PauseActionForm";
import StatusForm from "../../components/FormModal/StatusForm";
import CallUnsuccessBulkAssign from "../../components/FormModal/CallUnsuccessBulkAssign";
import BulkSkipTrace from "../../components/FormModal/BulkSkipTrace";
import NextActionBulkUpdate from "../../components/FormModal/NextActionBulkUpdate";
import WorkQueueForm from "../../components/FormModal/WorkQueueForm";
import voiceMailImg from "../../assets/img/VoicemailImg.png";
import VoiceMailForm from "../../components/FormModal/VoiceMailForm";
import { Icon, Typography } from "../../components_v2";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";
import useFormat from "../../hooks/useFormat";
import formatRecentTime from "../../utils_v2/FormatTimeInDays";
import AssignWorkflowStrategyForm from "../../components/FormModal/AssignWorkflowStrategyForm";
import { Drawer } from "baseui/drawer";
import CustomerPortal from "../Setting/TypeContainer/CustomerPortal";
import WorkListForm from "../../components/FormModal/WorkListForm";
import CustomerPortalSettings from "../../containers_v2/CustomerOverview/CustomerPortalSettings";
import { getOrganization } from "../../services";
import { assignBulkCustomerPortalSetting } from "../../services/creditPortal";

let removeValues = ["consolidated_action_remarks"];

const TableBody = ({
  page,
  prepareRow,
  renderRowSubComponent,
  getTableBodyProps,
  visibleColumns,
}) => {
  const format = useFormat();
  return (
    <div className="customer__tabel__body" {...getTableBodyProps()}>
      {page.map((row, index) => {
        prepareRow(row);
        const rowProps = row.getRowProps();
        // let removeConsolidateRemr = row.cells.filter(
        //   (item) => !removeValues.includes(item.column.id)
        // );
        return (
          <React.Fragment key={rowProps.key}>
            <div
              key={index}
              className={`table-main__body-row  --row_overflow${
                row.original.is_active ? "" : "row__disable"
              } ${row.isExpanded ? "expanded" : ""}`}
              {...row.getRowProps()}
            >
              {/* <Link to={`/customer/${index}`}> */}
              {row.cells.map((cell, celIndex) => {
                return (
                  <div
                    className={
                      celIndex === 0
                        ? "table-main1_first__body-cell "
                        : "table-main1__body-cell"
                    }
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </div>
                );
              })}
              {/* </Link> */}
            </div>
            {row.isExpanded &&
              renderRowSubComponent({
                row,
                rowProps,
                visibleColumns,
              })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const TableBodyMemo = React.memo(TableBody, (prevProps, nextProps) => {
  return nextProps.isColumnResizing;
});

const CustomerSummary = (props) => {
  let {
    currentOrganization,
    userPersonalization,
    referenceData,
    space,
    customCustomer,
  } = useContext(MaxyfiContext);
  const { handleModal } = useContext(ModalContext);
  const expandedRowId = useRef(null);
  const setView = (props) => dispatch(setCustomerSummaryTableView(props));
  let ref = useRef();
  let intl = useIntl();

  const dispatch = useDispatch();
  let reduxData = useSelector((state) => state.customerSummary.tableState);
  const currentFilteringData = useSelector(
    (s) => s.customerSummary.tableCurrentState.formFilters
  );
  useEffect(() => {
    if (ref.current) {
      // setWidth(ref.current?.offsetWidth);
      dispatch(setTableWidth(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);

  let {
    viewName,
    gridAndViewLoaded,
    tableCurrentState,
    tableDraftView,
    tableOrder,
    gridStructure,
    overViewPageIndex,
  } = useSelector((state) => state.customerSummary);

  // let globalFilter = { display_name: tableCurrentState.globalFilters };

  const [filterSearch, setFilterSearch] = useState(
    props?.queryFilter.display_name
  );

  const debounceFilter = useDebouncedCallback((value) => {
    gotoPage(0);
    dispatch(setCustomerSummaryPageIndex(0));
    props.setQueryFilter({ display_name: value });
    // dispatch(setGlobalFilter(value));
  }, 1000);

  useEffect(() => {
    const menu = "customer_summary";
    if (!viewName && !gridAndViewLoaded) {
      setTableGridStructure({
        menu,
        currentOrganization,
        setGridStructureAction: getCustomerSummaryGridStructure,
        setViewAction: setCustomerSummaryTableView,
        setGridAndViewAction: setCustomerSummaryGridAndView,
        userPersonalization,
        dispatch,
        isSetGridAndView: true,
        customFields:
          customCustomer && Array.isArray(customCustomer) ? customCustomer : [],
      });
      // dispatch(setTableWidth(width));
    }
  }, []);

  // useEffect(() => {
  //   setWidth(ref.current.offsetWidth);
  // }, []);

  const columns = useMemo(() => {
    return reduxData.columns;
  }, [reduxData.columns]);

  const tabledata = useMemo(
    () =>
      props.isFetched &&
      !props.isError &&
      props.data &&
      props.data.data &&
      props.data.data.docs
        ? props.data.data.docs
        : [],
    [props.isFetched, props.data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    visibleColumns,
    state: { pageIndex, pageSize, sortBy, filters, columnResizing, expanded },
    prepareRow,
    selectedFlatRows,
    setHiddenColumns,
    setAllFilters,
    setColumnOrder,
    allColumns,
    gotoPage,
    setPageSize,
    setSortBy,
    toggleRowExpanded,
    toggleRowSelected,
    toggleAllRowsSelected,
    initialState,
    isAllRowsSelected,
  } = useTable(
    {
      columns,
      initialState: {
        sortBy: tableCurrentState.sort ? tableCurrentState.sort : [],
        // pageIndex: tableCurrentState.pageIndex,
        pageIndex: tableCurrentState.pageIndex,

        pageSize: tableCurrentState.pageSize,
        // hiddenColumns: tableDraftView.table
        //   .filter((e) => e.is_hidden)
        //   .map((e) => e.col_id),
      },
      data: tabledata,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount:
        props.isFetched &&
        !props.getCustomerCount.isFetching &&
        !props.getCustomerCount.isError &&
        props?.getCustomerCount?.data?.data?.totalPages
          ? props?.getCustomerCount?.data?.data?.totalPages
          : 0,

      // props.isFetched &&
      // !props.isError &&
      // props.data &&
      // props.data.data &&
      // props.data.data.docs
      //   ? props.data.data.totalPages
      //   : 0,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  // FILTERS STARTS

  // Customer Filter
  const filterReduxData = useSelector(
    (s) => s.customerSummary.tableCurrentState.formFilters
  );

  // Customer Stage Filter
  const customerStageFilters = useSelector(
    (s) => s.customerSummary.tableCurrentState.customer_stage
  );
  // Needs Action Filter

  const NeedsActionData = useSelector(
    (s) => s.customerSummary.tableCurrentState.needs_action
  );

  // FILTERS ENDS

  // TO FIND LENGTH STARTS
  const CustomFilter = useSelector(
    (s) => s.customerSummary.tableCurrentState.formFilters
  );
  const NeedsActionFilter = useSelector(
    (s) => s.customerSummary.tableCurrentState.needs_action
  );

  const CustomerStageFilter = useSelector(
    (s) => s.customerSummary.tableCurrentState.customer_stage
  );

  // TO FIND LENGTH ENDS

  // GRID STRUCTURE FOR CUSTOMER

  const gridStructureValues = useSelector(
    (s) => s.customerSummary.gridStructure.table
  );

  const pinnedView = useSelector((s) => s.customerSummary.pinnedView);

  // GRID STRUCTURE FOR CUSTOMER

  const currentColOrder = React.useRef();
  const isColumnResizing = !!columnResizing.isResizingColumn;

  useEffect(() => {
    if (reduxData.sort) {
      let isDesc = reduxData.sort.startsWith("-");
      setSortBy([{ id: reduxData.sort.replace("-", ""), desc: isDesc }]);
    }
  }, [reduxData.sort]);

  // useEffect(() => {
  //   if (reduxData.filters) {
  //     setAllFilters(reduxData.filters);
  //   }
  // }, [reduxData.filters]);

  useEffect(() => {
    let rowIds = Object.keys(expanded);

    if (rowIds.length > 1) {
      toggleRowExpanded(
        rowIds.filter((e) => e == expandedRowId.current),
        false
      );
    }

    expandedRowId.current = rowIds[0];
  }, [expanded]);

  useEffect(() => {
    setHiddenColumns(reduxData.hiddenColumns);
  }, [reduxData.hiddenColumns]);

  /** Update Table Structure Changes to Redux as Draft Table State **/

  useEffect(() => {
    if (!isColumnResizing) {
      dispatch(updateTableCustomerSummaryDraftState(allColumns));
    }
  }, [isColumnResizing, sortBy, filters]);

  useEffect(() => {
    dispatch(updateTableCustomerSummaryDraftState(allColumns));
  }, [isColumnResizing, sortBy, filters, columns, allColumns, visibleColumns]);

  useEffect(() => {
    setColumnOrder(tableOrder);
  }, [tableOrder]);

  function SubRows({ row, rowProps, visibleColumns, data, loading }) {
    if (loading) {
      return (
        <div className="table-main__row-expanded">
          <div colSpan={visibleColumns.length - 1}>Loading...</div>
        </div>
      );
    }

    // error handling here :)
    return (
      <>
        <div
          className="table-main__row-expanded"
          {...rowProps}
          key={`${rowProps.key}-expanded`}
        >
          <CardMerge />
        </div>
        <div className="table-main__row-expanded-bottom">
          <p>#Hospitality</p>
          <p>#foodSupplier</p>
          <p>#CriticalCustomer</p>
        </div>
      </>
    );
  }

  function SubRowAsync({ row, rowProps, visibleColumns }) {
    const [loading, setLoading] = React.useState(true);
    const [data] = React.useState([]);

    React.useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    return (
      <SubRows
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        data={data}
        loading={loading}
      />
    );
  }

  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );

  useEffect(() => {
    let currTableSort =
      sortBy && sortBy[0]
        ? sortBy[0].desc
          ? "-" + sortBy[0].id
          : sortBy[0].id
        : null;

    let currRdxSort =
      tableCurrentState.sort && tableCurrentState.sort[0]
        ? tableCurrentState.sort[0].desc
          ? "-" + tableCurrentState.sort[0].id
          : tableCurrentState.sort[0].id
        : null;

    if (currTableSort != currRdxSort) {
      gotoPage(0);
      dispatch(setCustomerSummarySort(sortBy));
    }
  }, [sortBy]);

  let selectedCustomer = [...(selectedFlatRows ? selectedFlatRows : [])].map(
    (i) => i.original._id
  );

  useEffect(() => {
    dispatch(setCusOverviewPageIndex(tableCurrentState.pageIndex + 1));
  }, [tableCurrentState.pageIndex]);

  const [isOpenCustomPortal, setOpenCustomPortal] = useState(false);

  const containerRef = useRef(null);
  const wrappers = [
    {
      id: "workflow",
      label: "Workflow",
      icon: "mx-icon-add",
      role: BULK_ASSIGN_WORKFLOW,
      component: AssignWorkflowForm,
      img: workflowModalPic,
      width: "100px",
    },
    {
      id: "relationManager",
      label: "Relation Manager",
      icon: "mx-icon-add",
      role: BULK_ASSIGN_RM || BULK_RM_WORKFLOW,
      component: AssignRelationManagerForm,
      img: relManagerPic,
      width: "150px",
    },
    {
      id: "worklist",
      label: "Worklist",
      icon: "mx-icon-add",
      role: ADD_WORKLIST,
      component: WorkListForm,
      img: relManagerPic,
      width: "100px",
    },
    {
      id: "checkin",
      label: "CheckIn",
      icon: "mx-icon-add",
      role: BULK_CHECKIN,
      component: CheckIn,
      img: relManagerPic,
      width: "100px",
    },
    {
      id: "status",
      label: "Status",
      icon: "mx-icon-add",
      role: BULK_STATUS,
      component: StatusForm,
      img: relManagerPic,
      width: "80px",
    },
    {
      id: "pause_action",
      label: "Pause Action",
      icon: "mx-icon-add",
      role: BULK_PAUSE,
      component: PauseActionForm,
      img: relManagerPic,
      width: "100px",
    },
    {
      id: "call_unsucessfull",
      label: "Call Unsuccessful",
      icon: "mx-icon-add",
      role: BULK_CALL_UNSUCESSFUL,
      component: CallUnsuccessBulkAssign,
      img: unSuccessCallPic,
      width: "150px",
    },
    {
      id: "reshedule",
      label: "Reschedule",
      icon: "mx-icon-add",
      role: RESCHEDULR_NEXT_ACTION,
      component: NextActionBulkUpdate,
      img: unSuccessCallPic,
      width: "110px",
    },
    {
      id: "skip_trace",
      label: "Skip Tracing",
      icon: "mx-icon-add",
      role: BULK_SKIP_TRACE,
      component: BulkSkipTrace,
      img: unSuccessCallPic,
      width: "150px",
    },
    {
      id: "work_queue",
      label: "Work Queue",
      icon: "mx-icon-add",

      component: WorkQueueForm,
      img: workflowModalPic,
      width: "120px",
    },
    {
      id: "voice_mail",
      label: "Voice Mail",
      icon: "mx-icon-add",

      component: VoiceMailForm,
      img: voiceMailImg,
      width: "110px",
    },
    {
      id: "workflow_strategy",
      label: "Workflow Strategy",
      icon: "mx-icon-add",
      role: BULK_ASSIGN_WORKFLOW,
      component: AssignWorkflowStrategyForm,
      img: workflowModalPic,
      width: "150px",
    },
    {
      id: "portal_setting",
      label: "Portal Settings",
      icon: "mx-icon-add",
      role: CONSUMER_PORTAL_SETTING_BULK,
      drawer: true,
      width: "150px",
    },
  ];

  console.log(space?.role_permissions, "UIOUIOUIOUOi");
  const [showAll, setShowAll] = useState(false);
  const maxVisibleItems = 9;
  const [showDropDown, setShowDropDown] = useState(false);
  const visibleWrappers = showAll
    ? wrappers.filter(
        (item) => !item.role || space?.role_permissions?.includes(item.role)
      )
    : wrappers
        .filter(
          (item) => !item.role || space?.role_permissions?.includes(item.role)
        )
        .slice(0, maxVisibleItems);

  const remainingItems = wrappers
    .filter(
      (item) => !item.role || space?.role_permissions?.includes(item.role)
    )
    .slice(maxVisibleItems); // Ensure remaining items do not include visible ones

  const remainingCount = remainingItems.length;

  const handleShowMore = () => {
    setShowDropDown(!showDropDown);
  };

  useEffect(() => {
    if (!selectedFlatRows.length) {
      setShowDropDown(false);
    }
  }, [selectedFlatRows]);
  return (
    <>
      {isOpenCustomPortal && (
        <Drawer
          isOpen={isOpenCustomPortal}
          size={"950px"}
          autoFocus
          onClose={() => setOpenCustomPortal(false)}
          overrides={{
            Root: { style: { margin: 0 } },
            DrawerBody: { style: { padding: "0px 5px" } },
            DrawerContainer: { style: { margin: 0 } },
            Close: { style: { display: "none" } },
          }}
        >
          <CustomerPortalSettings
            setOpenCustomPortal={setOpenCustomPortal}
            bulkAssignCustomer={true}
            selectedCustomer={selectedCustomer}
            handleModal={handleModal}
            getService={getOrganization}
            submitService={assignBulkCustomerPortalSetting}
            toggleAllRowsSelected={toggleAllRowsSelected}
            isAllRowsSelected={isAllRowsSelected}
            queryFilter={props.queryFilter}
          />
        </Drawer>
      )}
      {!selectedFlatRows.length > 0 ? (
        <>
          <div
            className="table-main__head-row-horizontal"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  padding: "7px",
                  width: "260px",
                  // marginTop: "1%",
                }}
              >
                <Input
                  clearable
                  autoComplete="off"
                  autoFill="off"
                  size={SIZE.mini}
                  placeholder={intl.formatMessage({
                    id: "search_customer",
                  })}
                  // value={viewName}
                  endEnhancer={<Search size="18px" />}
                  overrides={{ ...inputOverrides }}
                  value={filterSearch}
                  onChange={(val) => {
                    let value = val.target.value;
                    debounceFilter(value);
                    setFilterSearch(value);

                    // dispatch(setGlobalFilter(value));
                    // dispatch(setMainCustomerNameFilter(value));
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  padding: "8px",
                  borderRadius: "5px",

                  // height: "50px",
                  backgroundColor: "#F0F5FF",
                  alignItems: "center",
                  minWidth: "100px",
                }}
              >
                <Icon icon="terms_outline" color={"#516BEB"} size={18} />

                <Typography type="p" className="text-primary" subType="regular">
                  {_.get(props, "getCustomerCount.data.data.totalDocs", "0")}{" "}
                  {_.get(props, "getCustomerCount.data.data.totalDocs", 0) > 1
                    ? "Records"
                    : "Record"}
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
                gap: "10px",
              }}
            >
              <div style={{}}>
                <TableViewPicker
                  allColumns={[]}
                  tableDraftView={tableDraftView}
                  viewName={viewName}
                  isCustomersPage={true}
                  menu={"customer_summary"}
                  setView={setView}
                  currentFilteringData={currentFilteringData}
                  gridStructure={gridStructure}
                  colOrder={(data) => dispatch(setTableHeaderOrder(data))}
                  tableCurrentState={tableCurrentState}
                  pinnedView={pinnedView}
                  overAllFilters={_.get(props, "overAllFilters", "")}
                  setPinnedView={setCustomerPinnedView}
                />
              </div>

              <div style={{ width: "40px" }}>
                <AllFilters
                  setQueryFilter={props.setQueryFilter}
                  setPageIndex={gotoPage}
                  filterReduxData={filterReduxData}
                  stageFilter={customerStageFilters}
                  NeedsAction={NeedsActionData}
                  name={"Custom"}
                  title={"custom"}
                  gridStructureValues={gridStructureValues}
                  mainFilter={(data) =>
                    dispatch(setCustomerSummaryFilter(data))
                  }
                  needsActionFilter={setNeedsActionFilter}
                  customerStageFilter={setCustomerStageFilter}
                  pageIndex={setCustomerSummaryPageIndex}
                  mainFilterDataLength={CustomFilter}
                  needsActionFilterDataLength={NeedsActionFilter}
                  customerStageFilterDataLength={CustomerStageFilter}
                  id={"customer"}
                  marginTopHandler={"68px"}
                />
              </div>
              <ColumnToogle
                allColumns={allColumns}
                setColumnOrder={setColumnOrder}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {selectedFlatRows.length > 0 && (
            <>
              <div
                ref={containerRef}
                style={{
                  backgroundColor: "#FFFFFF",
                  height: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "15px",
                  alignItems: "center",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  padding: "8px",
                  position: "relative",
                }}
              >
                {visibleWrappers.map(
                  ({
                    id,
                    label,
                    icon,
                    role,
                    img,
                    drawer,
                    width,
                    component: Component,
                  }) => (
                    <>
                      {role === undefined && drawer === undefined ? (
                        <div
                          style={{
                            minWidth: width,
                            height: "30px",
                            background: "#DDE3FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                            cursor: "pointer",
                            padding: "0 10px",
                          }}
                          onClick={() => {
                            handleModal({
                              title: "",
                              content: "",
                              isChildren: true,
                              successCallback: () => {},
                              formContent: ({ handleModal }) => {
                                return (
                                  <>
                                    <Component
                                      selectedCustomer={selectedCustomer}
                                      handleModal={handleModal}
                                      toggleAllRowsSelected={
                                        toggleAllRowsSelected
                                      }
                                      customerRefetch={props.refetch}
                                      isAllRowsSelected={isAllRowsSelected}
                                      queryFilter={props.queryFilter}
                                    />
                                  </>
                                );
                              },
                              cancelCallback: () => {},
                              image: img,
                              buttonText: "",
                              closeButtonText: "",
                              isCloseAble: false,
                            });
                          }}
                        >
                          <i className={icon} />
                          <span
                            style={{
                              fontSize: "13px",
                              color: "#333860",
                              fontWeight: "450",
                              marginLeft: "6px",
                            }}
                          >
                            {label}
                          </span>
                        </div>
                      ) : null}

                      {drawer ? (
                        <RBACWrapper key={id} role={role} type="PERMISSION">
                          <div
                            style={{
                              minWidth: width,
                              height: "30px",
                              background: "#DDE3FF",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpenCustomPortal(!isOpenCustomPortal);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "6px",
                                alignItems: "center",
                                margin: "0px 10px",
                              }}
                            >
                              <i className="mx-icon-add customer-add" />
                              <div
                                style={{
                                  fontSize: "13px",
                                  color: "#333860",
                                  fontWeight: "450",
                                  minWidth: "85px",
                                }}
                              >
                                Portal Settings
                              </div>
                            </div>
                          </div>
                        </RBACWrapper>
                      ) : null}

                      {drawer === undefined && role ? (
                        <RBACWrapper key={id} role={role} type="PERMISSION">
                          <div
                            style={{
                              height: "30px",
                              minWidth: width,
                              background: "#DDE3FF",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                              cursor: "pointer",
                              padding: "0 10px",
                            }}
                            onClick={() => {
                              handleModal({
                                title: "",
                                content: "",
                                isChildren: true,
                                successCallback: () => {},
                                formContent: ({ handleModal }) => {
                                  return (
                                    <>
                                      <Component
                                        selectedCustomer={selectedCustomer}
                                        handleModal={handleModal}
                                        toggleAllRowsSelected={
                                          toggleAllRowsSelected
                                        }
                                        customerRefetch={props.refetch}
                                        isAllRowsSelected={isAllRowsSelected}
                                        queryFilter={props.queryFilter}
                                      />
                                    </>
                                  );
                                },
                                cancelCallback: () => {},
                                image: img,
                                buttonText: "",
                                closeButtonText: "",
                                isCloseAble: false,
                              });
                            }}
                          >
                            <i className={icon} />
                            <span
                              style={{
                                fontSize: "13px",
                                color: "#333860",
                                fontWeight: "450",
                                marginLeft: "6px",
                              }}
                            >
                              {label}
                            </span>
                          </div>
                        </RBACWrapper>
                      ) : null}
                    </>
                  )
                )}

                {!showAll && remainingCount > 0 && (
                  <div
                    style={{
                      maxWidth: "50px",
                      height: "30px",
                      background: "#DDE3FF",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "5px",
                      cursor: "pointer",
                      padding: "0 10px",
                      position: "relative",
                    }}
                    onClick={handleShowMore}
                  >
                    <span
                      style={{
                        fontSize: "13px",
                        color: "#333860",
                        fontWeight: "450",
                      }}
                    >
                      +{remainingCount}
                    </span>

                    {showDropDown && wrappers.length > maxVisibleItems && (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%", // Position below the parent
                          left: "50%", // Center horizontally
                          transform: "translateX(-50%)", // Center horizontally
                          width: "200px",
                          maxHeight: "200px",
                          backgroundColor: "white",
                          zIndex: 9999,
                          borderRadius: "5px",
                          padding: "10px",
                          overflowY: "auto",
                          overflowX: "hidden",
                          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                          marginTop: "5px", // Add some space between button and dropdown
                        }}
                      >
                        {console.log(remainingItems, "remainingItems")}
                        {remainingItems.map(
                          ({
                            id,
                            label,
                            icon,
                            role,
                            drawer,
                            width,
                            component: Component,
                            img,
                          }) => (
                            <>
                              {drawer ? (
                                <>
                                  {" "}
                                  <div
                                    key={id}
                                    style={{
                                      minWidth: width,
                                      height: "30px",
                                      background: "#DDE3FF",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                      padding: "0 10px",
                                      marginBottom: "8px",
                                    }}
                                    onClick={() => {
                                      setOpenCustomPortal(!isOpenCustomPortal);
                                    }}
                                  >
                                    <i className={icon} />
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        color: "#333860",
                                        fontWeight: "450",
                                        marginLeft: "6px",
                                      }}
                                    >
                                      {label}
                                    </span>
                                  </div>{" "}
                                </>
                              ) : role === undefined && drawer === undefined ? (
                                <div
                                  key={id}
                                  style={{
                                    minWidth: width,
                                    height: "30px",
                                    background: "#DDE3FF",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    padding: "0 10px",
                                    marginBottom: "8px",
                                  }}
                                  onClick={() => {
                                    drawer
                                      ? setOpenCustomPortal(!isOpenCustomPortal)
                                      : handleModal({
                                          title: "",
                                          content: "",
                                          isChildren: true,
                                          successCallback: () => {},
                                          formContent: ({ handleModal }) => (
                                            <Component
                                              selectedCustomer={
                                                selectedCustomer
                                              }
                                              handleModal={handleModal}
                                              toggleAllRowsSelected={
                                                toggleAllRowsSelected
                                              }
                                              customerRefetch={props.refetch}
                                              isAllRowsSelected={
                                                isAllRowsSelected
                                              }
                                              queryFilter={props.queryFilter}
                                            />
                                          ),
                                          cancelCallback: () => {},
                                          image: img,
                                          buttonText: "",
                                          closeButtonText: "",
                                          isCloseAble: false,
                                        });
                                  }}
                                >
                                  <i className={icon} />
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "#333860",
                                      fontWeight: "450",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    {label}
                                  </span>
                                </div>
                              ) : (
                                <>
                                  <RBACWrapper
                                    key={id}
                                    role={role}
                                    type="PERMISSION"
                                  >
                                    <div
                                      key={id}
                                      style={{
                                        minWidth: width,
                                        height: "30px",
                                        background: "#DDE3FF",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        padding: "0 10px",
                                        marginBottom: "8px",
                                      }}
                                      onClick={() => {
                                        drawer
                                          ? setOpenCustomPortal(
                                              !isOpenCustomPortal
                                            )
                                          : handleModal({
                                              title: "",
                                              content: "",
                                              isChildren: true,
                                              successCallback: () => {},
                                              formContent: ({
                                                handleModal,
                                              }) => (
                                                <Component
                                                  selectedCustomer={
                                                    selectedCustomer
                                                  }
                                                  handleModal={handleModal}
                                                  toggleAllRowsSelected={
                                                    toggleAllRowsSelected
                                                  }
                                                  customerRefetch={
                                                    props.refetch
                                                  }
                                                  isAllRowsSelected={
                                                    isAllRowsSelected
                                                  }
                                                  queryFilter={
                                                    props.queryFilter
                                                  }
                                                />
                                              ),
                                              cancelCallback: () => {},
                                              image: img,
                                              buttonText: "",
                                              closeButtonText: "",
                                              isCloseAble: false,
                                            });
                                      }}
                                    >
                                      <i className={icon} />
                                      <span
                                        style={{
                                          fontSize: "13px",
                                          color: "#333860",
                                          fontWeight: "450",
                                          marginLeft: "6px",
                                        }}
                                      >
                                        {label}
                                      </span>
                                    </div>
                                  </RBACWrapper>
                                </>
                              )}
                            </>
                          )
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}

      <div
        className="table-main"
        {...getTableProps()}
        style={{ overflowY: "hidden" }}
      >
        <div style={{ display: "flex", height: "100%" }}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div className="table-main-container" ref={ref}>
              <div className="table-main__head" style={{ position: "unset" }}>
                {headerGroups.map((headerGroup) => {
                  // let removeConsolidateRemr = headerGroup.headers.filter(
                  //   (item) => !removeValues.includes(item.id)
                  // );
                  return (
                    <>
                      <div className="table-main__head-container">
                        <div
                          className="table-main__head-row"
                          style={{ backgroundColor: "#F5F6FA" }}
                        >
                          {headerGroup.headers.map((column, index) => (
                            <div
                              className={
                                index === 0
                                  ? "table-main1_first__head-cell-wrapper"
                                  : "table-main1__head-cell-wrapper"
                              }
                              {...column.getHeaderProps()}
                            >
                              <div className="table-main__head-cell">
                                {column.render("Header")}
                                <>
                                  {column.canResize && (
                                    <>
                                      <a
                                        className="table-main__head-cell-resizer"
                                        {...column.getResizerProps()}
                                      ></a>
                                    </>
                                  )}
                                </>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              {props.isLoading || !gridAndViewLoaded ? (
                <Loader />
              ) : props?.status === "error" ? (
                <p
                  style={{
                    paddingTop: "10px",
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  Unable to Load Data, Kindly Try Again
                </p>
              ) : tabledata && tabledata.length === 0 ? (
                <p
                  style={{
                    paddingTop: "10px",
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  No Records Found
                </p>
              ) : (
                <TableBodyMemo
                  isColumnResizing={isColumnResizing}
                  page={page}
                  prepareRow={prepareRow}
                  renderRowSubComponent={renderRowSubComponent}
                  getTableBodyProps={getTableBodyProps}
                  visibleColumns={visibleColumns}
                />
              )}

              <CustomerAction className="nested-ribbon" />
            </div>
          </div>
        </div>
      </div>
      <PaginationWraper
        pageIndex={tableCurrentState.pageIndex}
        pageSize={tableCurrentState.pageSize}
        localKey={"customer_page_size"}
        pageCount={pageCount}
        gotoPage={(val) => {
          gotoPage(val);
          dispatch(setCustomerSummaryPageIndex(val));
        }}
        setPageSize={(val) => {
          setPageSize(val);
          dispatch(setCustomerSummaryPageSize(val));
        }}
      />
    </>
  );
};

export default CustomerSummary;
