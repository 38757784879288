import { StatefulTooltip } from "baseui/tooltip";
import { Paragraph2, ParagraphSmall } from "baseui/typography";
import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";

const ReferenceValue = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  value = _.get(row.original, accessor, "");

  let isMulti = Array.isArray(value);
  //
  let data;
  let maxyfiContextValue = useContext(MaxyfiContext);

  const { referenceData } = maxyfiContextValue;

  //

  let rdName = column && column?.rdName;

  if (props.rdName) {
    rdName = props.rdName;
  }

  let tootltipData = [];

  if (isMulti) {
    data = value.map((e) => {
      const label =
        referenceData &&
        referenceData[rdName] &&
        referenceData[rdName]?.find(({ id }) => id === e);
      return label && label.label ? label.label : "";
    });
    tootltipData = [...data];
  } else {
    const label =
      referenceData &&
      referenceData[rdName] &&
      referenceData[rdName]?.find(({ id }) => id === value);

    data = label?.label;
  }

  return isMulti ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "10px",
      }}
    >
      {/* {visible ? ( */}
      <Paragraph2
        // ref={ref}
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textAlign: alignment,
          // margin: "5px",
        }}
      >
        {data && data[0]}
      </Paragraph2>
      {data && data.length > 1 && (
        <div style={{}}>
          <StatefulTooltip
            accessibilityType={"tooltip"}
            content={`${tootltipData.join(", ").toString()}${"."}`}
          >
            <div
              style={{
                border: "1px solid #333860",
                width: "25px",
                height: "15px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "#333860",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
              >
                +{data && data.length - 1}
              </div>
            </div>
          </StatefulTooltip>
        </div>
      )}
    </div>
  ) : (
    <StatefulTooltip
      accessibilityType={"tooltip"}
      content={
        <div style={{ minWidth: "auto", maxWidth: "200px" }}>{data}</div>
      }
    >
      <ParagraphSmall
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textAlign: alignment,
          maxWidth: "100px",
        }}
      >
        {data ? data : "-"}
      </ParagraphSmall>
    </StatefulTooltip>
  );
};

export default ReferenceValue;
