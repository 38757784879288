import React, { useState, useEffect, useContext } from "react";
import { StatefulPopover } from "baseui/popover";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { IconButton, KIND, SIZE } from "./../../../components/IconButton";
import { refetchInvoiceActions } from "../../../redux/invoiceOverview/action";
import getSelectValues from "../../../utils/getSelectValues";
import { TextButton } from "./../../../components/TextButton";
import warningImage from "../../../assets/img/warning.png";
import { skipActionModal } from "../../../redux/actions";
import { useDropzone } from "react-dropzone";
import { Button } from "baseui/button";
import LeftArrow from "./../../../assets/img/svg/CustomerOverViewIcon/LeftArrow";
import RightArrow from "./../../../assets/img/svg/CustomerOverViewIcon/RightArrow";
import SkipEmail from "./../../../assets/img/svg/CustomerOverViewIcon/SkipEmail";
import ActiveMsg from "./../../../assets/img/svg/CustomerOverViewIcon/ActiveMsg";
import Danger from "./../../../assets/img/svg/CustomerOverViewIcon/Danger";
import Clock from "./../../../assets/img/svg/CustomerOverViewIcon/Clock";
import ProgressCircle from "./../../../assets/img/svg/CustomerOverViewIcon/ProgressCircle";
import CaptureHold from "./../../../assets/img/svg/NestedRibbons/CaptureHold";
import ReleaseOnHold from "./../../../assets/img/svg/NestedRibbons/ReleaseOnHold";
import Next from "./../../../assets/img/svg/CustomerOverViewIcon/Next";
import EditSvg from "./../../../assets/img/svg/EditSvg";
import { postInvoiceAction } from "../../../services/invoiceOverview";
import Loader from "../../../components/Loader";
import { releaseActionModal } from "../../../redux/actions";
// import { SMS, LETTER, EMAIL, SMSACTIVE } from "./../../../constants";
import SkipMsg from "./../../../assets/img/svg/CustomerOverViewIcon/SkipMsg";
import InActiveLetter from "./../../../assets/img/svg/CustomerOverViewIcon/InActiveLetter";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { ParagraphMedium, LabelLarge, LabelSmall } from "baseui/typography";
import { getInvoiceAction } from "../../../services/invoiceOverview";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { ModalContext } from "../../../providers/ModalProvider";
import SmsForm from "../../CustomerOverView/COTabContainers/Actions/SmsForm";
import { getCustomerActions } from "./../../../services";
import ReactQuill from "react-quill";
import AssignWorkflow from "../../CustomerOverView/COTabContainers/Actions/AssignWorkflow";
import CallForm from "../../CustomerOverView/COTabContainers/Actions/CallForm";
import EmailForm from "../../CustomerOverView/COTabContainers/Actions/EmailForm";
import "react-quill/dist/quill.snow.css";
import { useSelector, useDispatch } from "react-redux";
import { updateCommunicationTemplate } from "./../../../services";
import { useNavigate } from "react-router-dom";
import SmsIcon from "../../../assets/img/svg/CustomerOverViewIcon/Sms";
import ActiveEmail from "../../../assets/img/svg/CustomerOverViewIcon/ActiveEmail";
import Dispute from "../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Dispute";
import MettingRequest from "../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/MettingRequest";
import Call from "../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Call";
import CallBackLater from "../../../assets/img/svg/CallBackLater";
import CallUnsuccessfull from "../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/CallUnsuccessfull";
import PromiseToPay from "../../../assets/img/svg/PromiseToPay";
import ReviewPromise from "../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/ReviewPromise";
import NextActionForward from "../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/NextActionForward";
import CallSkipped from "../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/CallSkipped";
import CallBackLaterSkipped from "../../../assets/img/svg/CallBackLaterSkipped";
import WorkflowHold from "../../../assets/img/svg/CustomerOverViewIcon/WorkflowHold";
import Executed from "../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Executed";
import SkippedWorkflow from "../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/SkippedWorkflow";
import SmsAction from "../../CustomerOverView/COTabContainers/Actions/AdhocActionPopup/SmsAction";
import NextAction from "../../CustomerOverView/COTabContainers/Actions/AdhocActionPopup/NextAction";
import EmailAction from "../../CustomerOverView/COTabContainers/Actions/AdhocActionPopup/EmailAction";
import ManualCall from "../../CustomerOverView/COTabContainers/Actions/AdhocActionPopup/ManualCall";
import DisputeAction from "../../CustomerOverView/COTabContainers/Actions/AdhocActionPopup/Dispute";
import LetterAction from "../../CustomerOverView/COTabContainers/Actions/AdhocActionPopup/LetterAction";
import PaymentsAction from "../../CustomerOverView/COTabContainers/Actions/AdhocActionPopup/Payments";
import ReviewPromiseAction from "../../CustomerOverView/COTabContainers/Actions/AdhocActionPopup/ReviewPromiseAction";
import {
  ACTIVE,
  SMS,
  LETTER,
  EMAIL,
  SMSACTIVE,
  CALLUNSUCCESSFUL,
  REVIEWPROMISE,
  CALLBACKLATER,
  MEETINGREQUEST,
  PROMISETOPAY,
  DISPUTE,
  SKIPPED,
  IN_QUEUE,
  EXECUTED,
  CALL,
  UNSUCCESSFUL,
  NEXTACTIONTRIGGER,
  ON_HOLD,
  WHATSAPP,
} from "./../../../constants";

import { toast } from "react-toastify";
import {
  invoiceEntity,
  invoiceWorkflowActionDatas,
} from "../../../redux/invoiceOverview/action";
import {
  invoiceDriveOpen,
  addEmailInvoiceAttachmentFromDesktop,
  refetchInvoiceDocumentData,
  unSelectedInvoiceDocumentData,
  updateEmailInvoiceAttachmentFromDesktop,
  documentDriveInvEmailAttachment,
} from "../../../redux/invoiceOverview/DMSystemINV/action";
import WhatsAppAction from "../../CustomerOverView/COTabContainers/Actions/AdhocActionPopup/WhatsAppAction";
import WhatsApp from "../../../assets/img/svg/Template/WhatsApp";
import WhastAppForm from "../../CustomerOverView/COTabContainers/Actions/WhastAppForm";
// import SmsForm from "../SmsForm";
// import EmailForm from "../EmailForm";
// import LetterForm from "../LetterForm";
// import CallForm from "../CallForm";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { StatefulTooltip, PLACEMENT, TRIGGER_TYPE } from "baseui/tooltip";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import {
  INVOICE_RESUME,
  INVOICE_WF_SKIP,
} from "../../../providers/RBACProvider/permissionList";
import {
  documentDriveQuickActionInv,
  removeAttachmentQuickAction,
  updateQuickActionEmailInvoiceAttachmentFromDesktop,
} from "../../../redux/invoiceOverview/invoiceQA/action";
import LetterWorkflowAction from "../../CustomerOverView/COTabContainers/Actions/AdhocActionPopup/LetterWorkflowAction";

const sampleWorkflow = [
  {
    id: "AC1",
    action_name: "sms",
    type: "sms",
    isSkipped: true,
    plannedOn: "03/05/2022",
    isActive: false,
    isExecuted: false,
    action_type: "IN_QUEUE",
  },
  {
    id: "AC2",
    action_name: "sms",
    type: "smsactive",
    isSkipped: false,
    plannedOn: "03/15/2022",
    isActive: true,
    isExecuted: false,
    action_type: "ON_HOLD",
  },
  {
    id: "AC3",
    action_name: "letter",
    type: "letter",
    isSkipped: true,
    skippedOn: "03/20/2022",
    plannedOn: "03/25/2022",
    isActive: false,
    isExecuted: false,
    action_type: "IN_QUEUE",
  },
  {
    id: "AC4",
    action_name: "letter",
    type: "letter",
    isSkipped: false,
    plannedOn: "03/30/2022",
    isActive: false,
    isExecuted: true,
    action_type: "ON_HOLD",
  },
  {
    id: "AC5",
    action_name: "email",
    type: "email",
    isSkipped: false,
    plannedOn: "04/15/2022",
    isActive: false,
    isExecuted: true,
    action_type: "IN_QUEUE",
  },
  {
    id: "AC6",
    action_name: "email",
    type: "email",
    isSkipped: false,
    plannedOn: "04/15/2022",
    isActive: false,
    isExecuted: true,
    action_type: "ON_HOLD",
  },
];

const InvoiceWorkflowAction = (props) => {
  let {
    id,
    action_name,
    action_type,
    isSkipped,
    skippedOn,
    planned_on,
    action_status,
    workFlow,
    executed_on,
    is_adhoc_action,
    actionInMessage,
    isAlert,
    is_automatic_action,
    time,
  } = props;

  const { referenceData, currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  let timePlanned = time
    ? referenceData["full_day_time_format"].find(({ id }) => id == time)
    : null;

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );
  const timeZone = orgRef?.time_zone;

  // const SwitchSvg = ({ type }) => {
  //   switch (type) {
  //     case SMS:
  //       return <SkipMsg />;
  //     case LETTER:
  //       return <InActiveMsg />;
  //     case EMAIL:
  //       return <SkipEmail />;

  //     default:
  //       return null;
  //   }
  // };

  let plannedOn = moment
    .utc(planned_on)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);
  let executedOn = moment
    .utc(executed_on)
    .format(currentDefaultFormatDetails.date_format);
  const workFlowData = workFlow.action_type;
  let ActionType;
  switch (`${action_type}_${action_status}`) {
    case `${SMS}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <SmsIcon />
        </div>
      );
      break;
    case `${EMAIL}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <ActiveEmail />
        </div>
      );
      break;

    case `${LETTER}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <InActiveLetter />
        </div>
      );
      break;
    case `${DISPUTE}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <Dispute />
        </div>
      );
      break;
    case `${UNSUCCESSFUL}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <unSuccessful />
        </div>
      );
      break;
    case `${MEETINGREQUEST}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <MettingRequest />
          {/* <div style={{ color: "#ffffff" }}>Hello</div> */}
        </div>
      );
      break;
    case `${CALL}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <Call />
        </div>
      );
      break;
    case `${CALLBACKLATER}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <CallBackLater />
        </div>
      );
      break;
    case `${PROMISETOPAY}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <PromiseToPay />
        </div>
      );
      break;
    case `${REVIEWPROMISE}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <ReviewPromise />
        </div>
      );
      break;
    case `${NEXTACTIONTRIGGER}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <NextActionForward />
        </div>
      );
      break;
    case `${CALLUNSUCCESSFUL}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <CallUnsuccessfull />
        </div>
      );
      break;
    case `${WHATSAPP}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <WhatsApp />
        </div>
      );
      break;

    case `${SMS}_${IN_QUEUE}`:
      ActionType = () => <SmsIcon />;
      break;
    case `${EMAIL}_${IN_QUEUE}`:
      ActionType = () => <ActiveEmail />;
      break;

    case `${LETTER}_${IN_QUEUE}`:
      ActionType = () => <InActiveLetter />;
      break;
    case `${CALL}_${IN_QUEUE}`:
      ActionType = () => <Call />;
      break;
    case `${DISPUTE}_${IN_QUEUE}`:
      ActionType = () => <Dispute />;
      break;
    case `${CALL}_${IN_QUEUE}`:
      ActionType = () => <Call />;
      break;
    case `${UNSUCCESSFUL}_${IN_QUEUE}`:
      ActionType = () => <unSuccessful />;
      break;
    case `${MEETINGREQUEST}_${IN_QUEUE}`:
      ActionType = () => <MettingRequest />;
      break;
    case `${CALLBACKLATER}_${IN_QUEUE}`:
      ActionType = () => <CallBackLater />;
      break;
    case `${PROMISETOPAY}_${IN_QUEUE}`:
      ActionType = () => <PromiseToPay />;
      break;
    case `${REVIEWPROMISE}_${IN_QUEUE}`:
      ActionType = () => <ReviewPromise />;
      break;
    case `${NEXTACTIONTRIGGER}_${IN_QUEUE}`:
      ActionType = () => <NextActionForward />;
      break;
    case `${CALLUNSUCCESSFUL}_${IN_QUEUE}`:
      ActionType = () => <CallUnsuccessfull />;
      break;
    case `${WHATSAPP}_${IN_QUEUE}`:
      ActionType = () => <WhatsApp />;
      break;
    case `${EMAIL}_${SKIPPED}`:
      ActionType = () => <SkipEmail />;
      break;
    case `${SMS}_${SKIPPED}`:
      ActionType = () => <SkipMsg />;
      break;
    case `${LETTER}_${SKIPPED}`:
      ActionType = () => <SkipEmail />;
      break;
    case `${CALL}_${SKIPPED}`:
      ActionType = () => <CallSkipped />;
      break;
    case `${PROMISETOPAY}_${SKIPPED}`:
      ActionType = () => <PromiseToPay />;
      break;
    case `${CALLBACKLATER}_${SKIPPED}`:
      ActionType = () => <CallBackLaterSkipped />;
      break;
    case `${WHATSAPP}_${SKIPPED}`:
      ActionType = () => (
        <div className="action-executed">
          <WhatsApp />
        </div>
      );
      break;
    case `${EMAIL}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <ActiveEmail />
        </div>
      );
      break;
    case `${SMS}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <SmsIcon />
        </div>
      );
      break;
    case `${LETTER}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <InActiveLetter />
        </div>
      );
      break;

    case `${DISPUTE}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <Dispute />
        </div>
      );
      break;

    case `${EMAIL}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <ActiveEmail />
        </div>
      );

      break;
    case `${UNSUCCESSFUL}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <unSuccessful />
        </div>
      );
      break;
    case `${MEETINGREQUEST}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <MettingRequest />
        </div>
      );
      break;
    case `${CALL}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <Call />
        </div>
      );
      break;
    case `${CALLBACKLATER}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <CallBackLater />
        </div>
      );
      break;
    case `${PROMISETOPAY}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <PromiseToPay />
        </div>
      );
      break;
    case `${REVIEWPROMISE}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <ReviewPromise />
        </div>
      );
      break;
    case `${NEXTACTIONTRIGGER}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <NextActionForward />
        </div>
      );
      break;
    case `${CALLUNSUCCESSFUL}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <CallUnsuccessfull />
        </div>
      );
      break;
    case `${WHATSAPP}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <WhatsApp />
        </div>
      );
      break;

    default:
      ActionType = () => <></>;
  }

  let ActionStatus;
  switch (action_status) {
    case ACTIVE:
      ActionStatus = (props) => (
        <div className="circle">
          <div className="">{props.children}</div>
          {is_automatic_action && <span className="work_flow_auto">AUTO</span>}
        </div>
      );
      break;

    case IN_QUEUE:
      ActionStatus = (props) => (
        <div
          style={{
            position: "relative",

            width: "50px",
            height: "50px",
            borderRadius: "50%",
            border: "2px solid #E5EAFF",
            background: "#E5EAFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div> {props.children}</div>
          {is_automatic_action && (
            <span className="work_flow_auto --auto_in_queue">AUTO</span>
          )}
        </div>
      );
      break;
    case SKIPPED:
      ActionStatus = (props) => (
        <div
          style={{
            position: "relative",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            border: "2px solid #EEEEEE",
            background: "#EEEEEE",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>{props.children}</div>
          {is_automatic_action && (
            <span className="work_flow_auto --auto_executed">AUTO</span>
          )}
        </div>
      );
      break;
    case EXECUTED:
      ActionStatus = (props) => (
        <div
          style={{
            position: "relative",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            border: "2px solid #EEEEEE",
            background: "#EEEEEE",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>{props.children}</div>
          {is_automatic_action && (
            <span className="work_flow_auto --auto_executed">AUTO</span>
          )}
        </div>
      );
      break;

    default:
      ActionStatus = () => <></>;
  }

  let ActionLine;
  switch (action_status) {
    case ACTIVE:
      ActionLine = (props) => (
        <div
          style={{
            width: "100%",
            height: "0px",
            border: "3px solid #516BEB",
            background: "#516BEB",
            borderRadius: "5px",
          }}
        >
          <div> {props.children} </div>
        </div>
      );
      break;

    case IN_QUEUE:
      ActionLine = (props) => (
        <div
          style={{
            width: "100%",
            height: "0px",
            border: "3px solid #E3E3E3",
            background: "#e3e3e3",
            borderRadius: "5px",
          }}
        >
          {props.children}
        </div>
      );
      break;
    case SKIPPED:
      ActionLine = (props) => (
        <div
          style={{
            width: "100%",
            height: "0px",
            border: "3px solid #E3E3E3",
            background: "#e3e3e3",
            borderRadius: "5px",
          }}
        >
          {props.children}
        </div>
      );
      break;
    case EXECUTED:
      ActionLine = (props) => (
        <div
          style={{
            width: "100%",
            height: "0px",
            border: "3px solid #E3E3E3",
            background: "#e3e3e3",
            borderRadius: "5px",
          }}
        >
          {props.children}
        </div>
      );
      break;

    default:
      ActionLine = () => <></>;
  }

  let ActionSvg;
  switch (action_status) {
    case ACTIVE:
      ActionSvg = () => (
        <div
          style={{
            position: "absolute",
            top: "0%",
            right: "50%",
            transform: "translate(60%,-70%)",
            maxHeight: "10px",
          }}
        >
          {workFlowData === "ON_HOLD" ? (
            <>
              <WorkflowHold />
            </>
          ) : (
            <>
              <Clock />
            </>
          )}
        </div>
      );
      break;

    case IN_QUEUE:
      ActionSvg = () => (
        <div
          style={{
            position: "absolute",
            top: "38%",
            right: "45%",
            transform: "translate(50%,-50%)",
            maxHeight: "10px",
          }}
        >
          {" "}
          <ProgressCircle />{" "}
        </div>
      );
      break;
    case EXECUTED:
      ActionSvg = () => (
        <div
          style={{
            position: "absolute",
            bottom: "-2px",
            right: "52%",
            transform: "translate(50%,-50%)",
            maxHeight: "10px",
          }}
        >
          <Executed />
        </div>
      );
      break;
    case SKIPPED:
      ActionSvg = () => (
        <div
          style={{
            position: "absolute",
            bottom: "-2px",
            right: "52%",
            transform: "translate(50%,-50%)",
            maxHeight: "10px",
          }}
        >
          <SkippedWorkflow />
        </div>
      );
      break;

    default:
      ActionSvg = () => <></>;
  }

  return (
    <>
      {/* <div
        key={id}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(5,1fr)",
          gridTemplateRows: "1fr 1fr 1fr 1fr",
          width: "250px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            gridColumn: "1/4",
            gridRow: "1/5",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            color: "#ADADAD",
          }}
        >
          <div>{isSkipped ? "Skipped On" : "Planned On"}</div>
          <div>{isSkipped ? skippedOn : plannedOn}</div>
        </div>

        <div
          style={{
            position: "relative",
            gridColumn: "1/4",
            gridRow: "3/4",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "5px",
              background: isActive ? "#516BEB" : "#e3e3e3",
              borderRadius: "5px",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: "0%",
              right: "50%",
              transform: "translate(50%,-50%)",
              maxHeight: "10px",
            }}
          >
            {isSkipped ? <Danger /> : ""}
            {isActive ? <Clock /> : ""}
            {isExecuted ? <ProgressCircle /> : ""}
          </div>
        </div>

        {index !== 3 ? (
          <>
            <div
              style={{
                gridColumn: "4/6",
                gridRow: "2/4",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isActive ? (
                <div className="circle">
                  {type === SMSACTIVE ? <ActiveMsg /> : ""}
                </div>
              ) : (
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    border: "2px solid #EEEEEE",
                    background: "#EEEEEE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {type === SMS ? <SkipMsg /> : ""}
                  {type === EMAIL ? <SkipEmail /> : ""}
                  {type === LETTER ? <InActiveLetter /> : ""}
                </div>
              )}
            </div>
            <div
              style={{
                gridColumn: "4/6",
                gridRow: "4/5",
                paddingTop: "9px",
                color: "#333860",
                fontWeight: "bold",
              }}
            >
              {actionName}
            </div>
          </>
        ) : (
          <></>
        )}
      </div> */}

      <div
        key={id}
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          {action_status === ACTIVE ? (
            <div>
              <span>{actionInMessage}</span>
            </div>
          ) : action_status === IN_QUEUE ? (
            <div>
              <span>{actionInMessage}</span>
            </div>
          ) : (
            <div style={{ color: "#ffffff" }}>
              <span>1</span>
            </div>
          )}

          <div
            style={{
              display: "grid",
              position: "relative",
              gridColumn: "1/4",
              gridRow: "3/4",
              margin: "10px 0px",
            }}
          >
            <ActionLine />
            <div>
              <ActionSvg />
            </div>
          </div>

          {/* {action_status === ACTIVE ? <div>{plannedOn}</div> : <></>} */}

          {action_status === ACTIVE ? (
            <div>{time ? timePlanned && timePlanned.label : plannedOn}</div>
          ) : action_status === IN_QUEUE ? (
            <div>
              <span>{timePlanned && timePlanned.label}</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <>
            <div
              style={{
                gridColumn: "4/6",
                gridRow: "2/4",
                display: "flex",
                justifyContent: "center",
                userSelect: "none",
              }}
            >
              <ActionStatus>
                <ActionType />
              </ActionStatus>
            </div>
            <div
              style={{
                gridColumn: "4/6",
                gridRow: "4/5",
                paddingTop: "9px",
                color: "#333860",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {action_name}
            </div>
            <div style={{ paddingTop: "5px" }}>
              {action_status === "EXECUTED" && executed_on ? (
                <>{executedOn}</>
              ) : action_status === "SKIPPED" ? (
                <>{executedOn}</>
              ) : (
                <div style={{ color: "#ffffff" }}>
                  <span>1</span>
                </div>
              )}

              {/* {is_adhoc_action === true ? (
                <LabelXSmall style={{ color: "#ADADAD" }}>
                  Customer Action
                </LabelXSmall>
              ) : (
                <></>
              )} */}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const WorkflowAction = (props) => {
  const { referenceData } = useContext(MaxyfiContext);
  const { emailAttachmentInvoice } = useSelector(
    (s) => s.invoiceDocumentReducer
  );
  const [smsOptions, setSmsOptions] = useState([]);
  const [emailOptions, setEmailOptions] = useState([]);
  const [callOptions, setCallOptions] = useState([]);
  const [whatsAppOptions, setWhatsAppOptions] = useState([]);

  let dispatch = useDispatch();

  let {
    customerActions = [],
    control,
    errors,
    actioWatch,
    callBackActin,
    hideWorkflowArea,
    data,
    setValue,
    callOutcome,
    setCallOutcome,
    setError,
    clearErrors,
    watch,
    getParams,
  } = props;

  let {
    customer_actions,
    contacts,
    recipients,
    templateContent,
    template_attachments,
    workflow,
    cost,
  } = data;

  const refetchInvoiceAction = useSelector(
    (s) => s.invoiceOverviewReducer.refetchActions
  );

  useEffect(() => {
    if (template_attachments) {
      let emailAttachedData = template_attachments.map((e) => {
        return {
          ...e,
          fileRef: e.id,
          isTemplate: e.type === "TEMPLATE" ? true : false,
          scanStatus: "SUCCESS",
        };
      });
      dispatch(documentDriveInvEmailAttachment(emailAttachedData));
    }
  }, [
    refetchInvoiceAction,
    data.template_attachments,
    templateContent?.subject,
  ]);

  let currentAction = customer_actions.find((e) => e.action_status === ACTIVE);
  let type =
    currentAction && currentAction.action_type ? currentAction.action_type : "";
  let isAdhocAction = currentAction ? currentAction.is_adhoc_action : false;
  const { total_outstanding_invoice_amount } = useSelector(
    (state) => state.customerOverviewReducer.customerDetails
  );
  let recipientsData = data && data?.recipients;

  let contactsOptionsEmail = contacts
    .map((e) => {
      let designation = referenceData["recipient_type"].find(
        (rf) => rf.id === e.designation
      );

      if (designation && e.email && e.email[0] && e.email[0].value) {
        let label = designation.label || "";

        return {
          // id: e._id,
          // label: `${e.first_name || ""} - ${label}`,
          id: e._id,
          // label: `${e.first_name || ""} - ${label}`,
          label: `${e.first_name || ""} - ${label} - ${
            e?.email[0]?.value || ""
          }`,
        };
      }

      return null;
    })
    .filter((con) => con);

  let contactsOptionsSms = contacts
    .map((e) => {
      let designation = referenceData["recipient_type"].find(
        (rf) => rf.id === e.designation
      );

      if (
        designation &&
        e.phone &&
        e.phone[0] &&
        // e.phone[0].country_code &&
        e.phone[0].value
      ) {
        let label = designation.label || "";

        return {
          // id: e._id,
          // label: `${e.first_name || ""} - ${label}`,
          id: e._id,
          label: `${e.first_name || ""} - ${label} - ${
            e.phone[0]?.country_code ? e.phone[0]?.country_code : ""
          } ${e.phone[0]?.value} ${
            // e.phone[0].value
            e.phone[0]?.country_code
              ? ""
              : "(Country code unavailable, Update the Country code in Contacts to continue)"
          }`,
          disabled: e.phone[0].country_code ? false : true,
        };
      }

      return null;
    })
    .filter((con) => con);

  useEffect(() => {
    let allPhoneNumber = [];
    if (contacts && Array.isArray(contacts)) {
      for (let i of contacts.filter(
        (e) =>
          e?.designation === "primary_contact" ||
          e?.designation === "escalation_contact"
      )) {
        for (let j of i?.phone?.filter(
          (e) => e?.status === "VALID" || e?.status === "LEAD"
        )) {
          allPhoneNumber.push({
            phone_id: j?._id,
            value: j?.value,
            recipient_id: i?._id,
            country_code: j?.country_code,
            name: i?.first_name,
            type: i?.designation,
          });
        }
      }
    }
    // if(allPhoneNumber && Array.isArray(allPhoneNumber)){
    //   allPhoneNumber.map
    // }
    setSmsOptions(allPhoneNumber);
  }, [contacts]);

  useEffect(() => {
    let allEmail = [];
    if (contacts && Array.isArray(contacts)) {
      for (let i of contacts?.filter(
        (e) =>
          e?.designation === "primary_contact" ||
          e?.designation === "escalation_contact"
      )) {
        for (let j of i?.email?.filter(
          (e) => e?.status === "VALID" || e?.status === "LEAD"
        )) {
          allEmail.push({
            email_id: j?._id,
            value: j?.value,
            recipient_id: i?._id,
            name: i?.first_name,
            type: i?.designation,
          });
        }
      }
    }

    setEmailOptions(allEmail);
  }, [contacts]);

  useEffect(() => {
    let allPhoneNumber = [];
    let allLandLink = [];
    if (contacts && Array.isArray(contacts)) {
      for (let i of contacts?.filter(
        (e) =>
          e?.designation === "primary_contact" ||
          e?.designation === "escalation_contact"
      )) {
        for (let j of i?.phone?.filter(
          (e) => e?.status === "VALID" || e?.status === "LEAD"
        )) {
          allPhoneNumber.push({
            phone_id: j?._id,
            value: j?.value,
            recipient_id: i?._id,
            country_code: j?.country_code,
            name: i?.first_name,
            type: i?.designation,
            basicType: "Phone",
          });
        }

        if (i && i?.landline) {
          for (let j of i?.landline?.filter(
            (e) => e?.status === "VALID" || e?.status === "LEAD"
          )) {
            allLandLink.push({
              phone_id: j?._id,
              value: j?.value,
              recipient_id: i?._id,
              country_code: j?.country_code,
              name: i?.first_name,
              type: i?.designation,
              basicType: "LandLine",
            });
          }
        }
      }
    }

    // if(allPhoneNumber && Array.isArray(allPhoneNumber)){
    //   allPhoneNumber.map
    // }
    // setContactOptions(allPhoneNumber);
    let combinedPhone = [...allPhoneNumber, ...allLandLink];

    setCallOptions(combinedPhone);
  }, [contacts]);

  useEffect(() => {
    let allWhatsAppNumber = [];
    if (contacts && Array.isArray(contacts)) {
      for (let i of contacts?.filter(
        (e) =>
          e?.designation === "primary_contact" ||
          e?.designation === "escalation_contact"
      )) {
        for (let j of i.phone.filter(
          (e) => e?.status === "VALID" || e?.status === "LEAD"
        )) {
          allWhatsAppNumber.push({
            whatsApp_id: j?._id,
            value: j?.value,
            recipient_id: i?._id,
            country_code: j?.country_code,
            name: i?.first_name,
            type: i?.designation,
          });
        }
      }
    }
    // if(allPhoneNumber && Array.isArray(allPhoneNumber)){
    //   allPhoneNumber.map
    // }
    setWhatsAppOptions(allWhatsAppNumber);
  }, [contacts]);

  let costDatas = cost && cost;

  useEffect(() => {
    //   setValue(
    //     "recipients",
    //     contactsOptions.filter(
    //       (e) => recipients && recipients.some((rc) => rc._id === e.id)
    //     ) || []
    //   );

    setValue("content", (templateContent && templateContent.value) || "");

    if (type === EMAIL) {
      setValue("subject", templateContent && templateContent.subject);
    }

    if (type === WHATSAPP) {
      setValue("recipients", []);
    }

    if (type === CALL) {
      //     setValue("promise_type", [{ id: "full_amount" }]);
      //     // setValue(
      //     //   "promise_amount",
      //     //   total_outstanding_invoice_amount &&
      //     //     total_outstanding_invoice_amount.value?.toFixed(2)
      //     // );

      if (
        total_outstanding_invoice_amount &&
        total_outstanding_invoice_amount.value
      ) {
        setValue(
          "promise_amount",
          Number(total_outstanding_invoice_amount.value).toFixed(2)
        );
      }
    }
  }, []);
  // const workflowDatas = useSelector((s) => s.invoiceOverviewReducer);

  // let filteringActionStatus =
  //   workflowDatas && workflowDatas?.filter((i) => i.action_status === "ACTIVE");

  //

  let workFlowDatas = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceWorkflow
  );

  let filteringActionStatus =
    data && data.customer_actions?.filter((i) => i.action_status === "ACTIVE");
  // //
  let currentActioDate =
    filteringActionStatus &&
    filteringActionStatus[0] &&
    filteringActionStatus[0].planned_on;
  //
  switch (type) {
    case SMS:
      return (
        <SmsForm
          Controller={Controller}
          control={control}
          errors={errors}
          hideWorkflowArea={hideWorkflowArea}
          contacts={contacts || []}
          cost={costDatas || {}}
          isAdhocAction={isAdhocAction}
          recipientsData={recipientsData}
          setValue={setValue}
          currentActioDate={currentActioDate}
          contactsData={recipients}
        />
      );

    case EMAIL:
      return (
        <EmailForm
          Controller={Controller}
          control={control}
          errors={errors}
          hideWorkflowArea={hideWorkflowArea}
          contacts={contacts || []}
          contactsData={recipients}
          isAdhocAction={isAdhocAction}
          setError={setError}
          clearErrors={clearErrors}
          recipientsData={recipientsData}
          setValue={setValue}
          currentActioDate={currentActioDate}
          emailAttached={emailAttachmentInvoice}
          getParams={getParams}
          removeAttchFile={unSelectedInvoiceDocumentData}
          updatingDeskUpload={updateEmailInvoiceAttachmentFromDesktop}
          setAttachment={documentDriveInvEmailAttachment}
          watch={watch}
          data={data}
        />
      );

    case CALL:
      return (
        <CallForm
          currentAction={currentAction}
          actioWatch={actioWatch}
          Controller={Controller}
          watch={watch}
          control={control}
          errors={errors}
          callBackActin={callBackActin}
          hideWorkflowArea={hideWorkflowArea}
          contacts={contacts || []}
          templateContent={(templateContent && templateContent.value) || ""}
          callOutcome={callOutcome}
          setCallOutcome={setCallOutcome}
          setValue={setValue}
          isAdhocAction={isAdhocAction}
          contactsData={recipients}
        />
      );

    case LETTER:
      return (
        // <LetterForm
        //   Controller={Controller}
        //   control={control}
        //   errors={errors}
        //   hideWorkflowArea={hideWorkflowArea}
        //   contacts={contactsOptions || []}
        //   isAdhocAction={isAdhocAction}
        //   cost={costDatas || {}}
        // />
        <LetterWorkflowAction
          Controller={Controller}
          control={control}
          errors={errors}
          hideWorkflowArea={hideWorkflowArea}
          contacts={contacts || []}
          isAdhocAction={isAdhocAction}
          setError={setError}
          clearErrors={clearErrors}
          contactsData={recipients}
          recipientsData={recipientsData}
          setValue={setValue}
          currentActioDate={currentActioDate}
          emailAttached={emailAttachmentInvoice}
          getParams={getParams}
          removeAttchFile={unSelectedInvoiceDocumentData}
          updatingDeskUpload={updateEmailInvoiceAttachmentFromDesktop}
          watch={watch}
          workFlowData={data}
        />
      );

    case WHATSAPP:
      return (
        <WhastAppForm
          currentAction={currentAction}
          actioWatch={actioWatch}
          Controller={Controller}
          watch={watch}
          control={control}
          errors={errors}
          callBackActin={callBackActin}
          hideWorkflowArea={hideWorkflowArea}
          contacts={whatsAppOptions || []}
          templateContent={templateContent || ""}
          callOutcome={callOutcome}
          setCallOutcome={setCallOutcome}
          setValue={setValue}
          isAdhocAction={isAdhocAction}
          data={data}
          // setCallRefDetails={setCallRefDetails}
          // settingData={settingData}
          recipientsData={recipientsData}
          getParams={getParams}
          currentActioDate={currentActioDate}
          contactsData={recipients}
        />
      );

    default:
      return () => <></>;
  }
};

const InvoiceActions = ({ getInvoiceData, getParams, invData }) => {
  const [active, setActive] = useState([]);
  const [all, setAll] = useState([]);
  const [callOutcome, setCallOutcome] = useState("");
  const [dayDifference, setDayDifference] = useState(false);

  // const { emailAttachmentInvoice } = useSelector(
  //   (s) => s.invoiceDocumentReducer
  // );

  const scanFinalStatus = false;
  // emailAttachmentInvoice &&
  // emailAttachmentInvoice.some((e) => e.scanStatus !== "SUCCESS");

  // const [getSearchParams, setGetSearchParams] = useSearchParams();
  // const getParams = getSearchParams.get("inv");

  let invoiceId = getParams;
  let customerId = getInvoiceData && getInvoiceData.customer_id;
  const visibleActionCount = 4;
  const dispatch = useDispatch();

  const invoiceCustomerId = useSelector(
    (s) => s.invoiceOverviewReducer.inv_cus_id
  );

  const workflowDatas = useSelector(
    (s) => s.invoiceOverviewReducer.onLoadCustomerActions
  );

  // let filteringActionStatus = workflowDatas.filter(
  //   (i) => i.action_status === "ACTIVE"
  // );
  //
  const { handleModal } = useContext(ModalContext);
  // let currentActioDate =
  //   filteringActionStatus &&
  //   filteringActionStatus[0] &&
  //   filteringActionStatus[0].planned_on;

  // let today = moment().endOf("d").utc().valueOf();

  const refetchInvoiceAction = useSelector(
    (s) => s.invoiceOverviewReducer.refetchActions
  );

  const {
    referenceData,
    currentOrganization,
    restrictions,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  let organizationDetail = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const { isLoading, data, refetch, isFetching, isError } = useQuery(
    [`invoice-actions-${invoiceId}`],
    async () => {
      return await getInvoiceAction({
        organization: currentOrganization,
        invoiceId: invoiceId,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetchInvoiceAction]);

  useEffect(() => {
    if (
      data &&
      data.data &&
      data.data.doc &&
      data.data.doc.templateContent &&
      data.data.doc.templateContent.attachments
    ) {
      // dispatch(addCOEmailAttachment(data.data.doc.templateContent.attachments));
    }

    if (data && data.data && data.data.doc && data.data.doc.customer_actions) {
      // New
      const activeActionIndex = data?.data.doc.customer_actions.findIndex(
        (i) => i.action_status === "ACTIVE"
      );

      let customerActions = data.data.doc.customer_actions || [];
      // let startIndex = Math.sign(activeActionIndex - 2) ? 0 : activeActionIndex;
      // let endIndex =
      //   customerActions.length - 1 >= activeActionIndex + 4
      //     ? customerActions.length - 1
      //     : activeActionIndex + 4;

      //TODO: Error finding right start index

      let startIndex = customerActions[activeActionIndex - 2]
        ? activeActionIndex
        : 0;
      let endIndex = customerActions[startIndex + 2]
        ? startIndex + 4
        : customerActions.length;
      if (endIndex === customerActions.length) {
        startIndex = customerActions.length - 4;
      }

      let inQueueAccumulationDays = 0;
      customerActions = customerActions.map((act, i) => {
        let { planned_on, action_status, action_lead_time } = act;
        let actionData = {
          actionInMessage: "",
          isAlert: false,
        };

        if (planned_on && action_status === "ACTIVE") {
          // let today = new Date().toISOString().split("T")[0];
          // today = moment(today);
          // let plannedOnLocal = utcTimstampToLocalDate(planned_on);
          // plannedOnLocal = moment(plannedOnLocal);

          // let dayDiff = Math.floor(
          //   moment.duration(plannedOnLocal.diff(today)).asDays()
          // );

          let dayDiff = moment
            .utc(planned_on)
            ?.tz(timeZone)
            ?.diff(moment?.tz(timeZone), "days", true);

          dayDiff = Math.ceil(dayDiff);

          if (dayDiff == 0) {
            actionData.actionInMessage = "Due Today";
            actionData.isAlert = true;
          } else if (dayDiff > 0) {
            actionData.actionInMessage = `In ${dayDiff} Day${
              dayDiff > 1 ? "s" : ""
            }`;
          } else {
            setDayDifference(true);
            actionData.actionInMessage = "Over Due";
            actionData.isAlert = true;
          }

          if (activeActionIndex === i) {
            // inQueueAccumulationDays += dayDiff;
            inQueueAccumulationDays += dayDiff > 0 ? dayDiff : 0;
          }
        } else if (action_status === "IN_QUEUE") {
          let inDays = inQueueAccumulationDays + action_lead_time;
          inQueueAccumulationDays += action_lead_time;
          actionData.actionInMessage = `In ${inDays} Day${
            inDays > 1 ? "s" : ""
          }`;
        }

        return {
          ...act,
          ...actionData,
        };
      });

      setAll(customerActions);
      setActive(customerActions.slice(startIndex, endIndex));
      // dispatch(
      //   invoiceWorkflowActionDatas(
      //     data && data.data && data.data.doc && data.data.doc.customer_actions
      //   )
      // );
    }
  }, [data]);
  let workFlowDatas = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceWorkflow
  );

  const next = () => {
    const newActiveElement =
      all[all.findIndex((e) => e._id === active[3]._id) + 1];
    let newActive = [...active.splice(1, visibleActionCount), newActiveElement];

    setActive(newActive);
  };

  const previous = () => {
    const newActiveElement =
      all[all.findIndex((e) => e._id === active[0]._id) - 1];
    let newActive = [
      newActiveElement,
      ...active.splice(0, visibleActionCount - 1),
    ];
    setActive(newActive);
  };
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      cc: [],
      bcc: [],
      promise_type: [],
    },
    reValidateMode: "onChange",
    // resolver: yupResolver(schema),
  });
  const { emailAttachmentInvoice } = useSelector(
    (s) => s.invoiceDocumentReducer
  );
  let template = [];
  let emailAttached = [];
  const emailcustomerAttachments =
    emailAttachmentInvoice &&
    emailAttachmentInvoice.map((e) => {
      if (e.type == "TEMPLATE") {
        template.push(e.fileRef);
      } else {
        emailAttached.push(e.fileRef);
      }
    });

  const onSubmit = async (data) => {
    const orgRef = referenceData.organizations.find(
      ({ id }) => id === currentOrganization
    );

    const timeZone = orgRef.time_zone;

    let { followup_date, template_id, invoice_follow_ups, ...restValue } = data;

    let newValue = {
      ...restValue,
      recipients: data?.recipients.map((e) => {
        return { id: e?.id, recipient_id: e?.recipient_id };
      }),
      // attachments: attachments,
      // attachments: emailAttachments,
      ...(emailAttached.length > 0 ? { attachments: emailAttached } : {}),
      ...(template.length > 0 ? { template_attachments: template } : {}),
    };

    if (data.cc) {
      newValue = { ...newValue, cc: data.cc };
    }

    if (data.bcc) {
      newValue = { ...newValue, bcc: data.bcc };
    }

    if (data.promise_date) {
      newValue = {
        ...newValue,
        promise_date: moment
          .tz(
            `${data.promise_date.getFullYear()}-${
              data.promise_date.getMonth() + 1
            }-${data.promise_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        promise_type: getSelectValues(data.promise_type),
        call_outcome: callOutcome,
        // promise_amount: data.promise_amount,
        promise_amount: {
          value: data.promise_amount.value,
          currency: getSelectValues(data.promise_amount.currency),
        },
      };
    }

    if (data.callback_date) {
      newValue = {
        ...newValue,
        callback_date: moment
          .tz(
            `${data.callback_date.getFullYear()}-${
              data.callback_date.getMonth() + 1
            }-${data.callback_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        callback_time: getSelectValues(data.callback_time),
        call_outcome: callOutcome,
      };
    }
    if (callOutcome === "PROMISE_TO_PAY") {
      setValue("promise_type", [{ id: "full_amount" }]);
      // if (
      //   total_outstanding_invoice_amount &&
      //   total_outstanding_invoice_amount.value
      // ) {
      //   setValue(
      //     "promise_amount.value",
      //     Number(total_outstanding_invoice_amount.value).toFixed(2)
      //   );
      // }
    }

    // if (data.meeting_date) {
    //   newValue = {
    //     ...newValue,
    //     meeting_date: moment(data.meeting_date).utc().valueOf(),
    //     meeting_time: moment(data.meeting_time).utc().valueOf(),
    //     call_outcome: callOutcome,
    //   };
    // }

    if (data.dispute_review_date) {
      newValue = {
        ...newValue,
        dispute_review_date: moment
          .tz(
            `${data.dispute_review_date.getFullYear()}-${
              data.dispute_review_date.getMonth() + 1
            }-${data.dispute_review_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        dispute_type: getSelectValues(data.dispute_type),
        call_outcome: callOutcome,
      };
    }

    if (data.action_follow_up_type || data.action_follow_up_template_id) {
      newValue = {
        ...newValue,
        action_follow_up_type: getSelectValues(data.action_follow_up_type),
        action_follow_up_template_id: getSelectValues(
          data.action_follow_up_template_id
        ),
      };
    }

    if (callOutcome === "UNSUCCESSFUL") {
      newValue = {
        ...newValue,
        call_unsuccessful_reason: getSelectValues(
          data.call_unsuccessful_reason
        ),
        call_outcome: callOutcome,
      };
    }

    if (data.invoice_follow_ups) {
      newValue = {
        ...newValue,
        action_type: "letter",
        ...(data.letter_generated === "system_generator"
          ? { is_system_generated: true }
          : { is_system_generated: false }),

        ...(data.letter_generated === "system_generator"
          ? {
              ...(data.template_id
                ? { template_id: getSelectValues(data.template_id) }
                : {}),
            }
          : {}),

        ...(data.letter_generated === "system_generator"
          ? {
              letter_name: template_id && template_id[0] && template_id[0].name,
              ...(template_id
                ? {
                    template_id:
                      template_id && template_id[0] && template_id[0].id,
                  }
                : {}),
            }
          : {
              letter_name:
                data.invoice_follow_ups &&
                data.invoice_follow_ups[0] &&
                data.invoice_follow_ups[0].label,
            }),
        // recipient: [
        //   {
        //     id: data?.recipients?.[0]?.id,
        //     recipient_id: data?.recipients?.[0]?.recipient_id,
        //   },
        // ],
        recipients: data?.recipients.map((e) => {
          return { id: e?.id, recipient_id: e?.recipient_id };
        }),
        attachments: emailAttached,
        template_attachments: template,
        sub_category: getSelectValues(data.sub_category),
        date_of_communication: moment
          .tz(data.date_of_communication, timeZone)
          .startOf("D")
          .utc()
          .valueOf(),
        delivery_status: getSelectValues(data.delivery_status),
        delivery_method: getSelectValues(data.delivery_method),
        tracking_url: data.tracking_url,
        comments: data.comments,
      };
    }

    if (followup_date) {
      newValue = {
        ...newValue,
        followup_date: moment
          .tz(
            `${followup_date.getFullYear()}-${
              followup_date.getMonth() + 1
            }-${followup_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
      };
    }

    if (
      data.confirmation_message_type ||
      data.confirmation_message_template_id
    ) {
      newValue = {
        ...newValue,
        confirmation_message_type: getSelectValues(
          data.confirmation_message_type
        ),
        confirmation_message_template_id: getSelectValues(
          data.confirmation_message_template_id
        ),
      };
    }

    // if (dayDifference === true) {
    //   handleModal({
    //     title: "This is A Future Action",
    //     content: "Do you still want to continue",
    //     successCallback: async () => {
    //       await postInvoiceAction({
    //         organization: currentOrganization,
    //         customerId: invoiceId,
    //         ...newValue,
    //       });
    //       refetch();
    //       reset();
    //       setCallOutcome("");
    //       dispatch(refetchInvoiceDocumentData());
    //       // dispatch(refetchActions());

    //       dispatch(refetchInvoiceActions());
    //     },
    //     formContent: () => {
    //       return <></>;
    //     },
    //     cancelCallback: () => {},
    //     isChildren: false,
    //     image: warningImage,
    //     buttonText: "Confirm",
    //     closeButtonText: "Cancel",
    //     isCloseAble: false,
    //   });
    // } else {}
    await postInvoiceAction({
      organization: currentOrganization,
      invoiceId: invoiceId,
      ...newValue,
    });
    refetch();
    reset();
    dispatch(refetchInvoiceActions());
    dispatch(refetchInvoiceDocumentData());

    // setCallOutcome("");
    // dispatch(refetchActions());
    // }
    // dispatch(refetchActions());
    dispatch(refetchInvoiceActions());
  };
  let HoldDatas = data && data.data.doc && data.data.doc.on_hold;

  let reason = referenceData["hold_action_reason"];

  let filteringValue = reason.filter(
    (i) => i.id === HoldDatas?.content?.hold_reason
  );
  let holdDateFormate = HoldDatas?.content?.hold_action_till
    ? moment
        .utc(HoldDatas?.content?.hold_action_till)
        .format(currentDefaultFormatDetails.date_format)
    : "-";

  let workflowData = data?.data.doc.workflow;

  const TypesData = useSelector((state) => state.customerOverviewReducer.type);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;
  let currentAction = data?.data.doc.customer_actions?.find(
    (e) => e.action_status === ACTIVE
  );

  let type =
    currentAction && currentAction.action_type ? currentAction.action_type : "";
  const actioWatch = watch([
    "is_send_confirmation_message",
    "is_send_action_follow_up",
    "is_send_klq",
    "is_send_meet_1rq",
    "promise_type",
  ]);

  const callBackActin = watch(["is_send_call", "is_send_call_to"]);
  const intl = useIntl();
  const { viewOnly } = useSelector((state) => state.communicationTemplate);
  const quillToolbar = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
    ["bold", "italic", "underline", "strike"], // toggled buttons
    [{ align: [] }], // text align
    ["blockquote", "code-block"], // blocks
    [{ list: "ordered" }, { list: "bullet" }], // lists
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ color: [] }, { background: [] }], // dropdown with defaults
    ["clean"], // remove formatting
  ];
  const [hideWorkflow, setHideWorkflow] = useState(true);

  function hideWorkflowArea() {
    setHideWorkflow(!hideWorkflow);
  }

  const isModalOpens = useSelector(
    (s) => s.customerOverviewReducer.modalIsOpen
  );

  // let SwitchingAction;

  // const SwitchAction = () => {

  const SwitchingAction = ({ type, getParams }) => {
    const { invoiceDetails } = useSelector((s) => s.invoiceOverviewReducer);

    if (type === "sms") {
      return (
        <SmsAction
          getParams={invoiceDetails && invoiceDetails?.customer_id}
          id={invoiceId}
          entity={"invoices"}
          tags={"INVOICE"}
        />
      );
    }

    if (type === "next") {
      return <NextAction />;
    }

    if (type === "call") {
      return <ManualCall />;
    }

    if (type === "email") {
      return (
        <EmailAction
          getParams={invoiceDetails && invoiceDetails?.customer_id}
          id={invoiceId}
          entity={"invoices"}
          tags={"INVOICE"}
          removeAttchFile={removeAttachmentQuickAction}
          updatingDeskUpload={
            updateQuickActionEmailInvoiceAttachmentFromDesktop
          }
          setAttachment={documentDriveQuickActionInv}
        />
      );
    }

    if (type === "payments") {
      return <PaymentsAction />;
    }

    if (type === "review") {
      return <ReviewPromiseAction />;
    }

    if (type === "dispute") {
      return (
        <DisputeAction
          getParams={getParams}
          invoiceId={invoiceId}
          enity={"invoices"}
        />
      );
    }
    if (type === "whatsapp") {
      return (
        <WhatsAppAction
          getParams={getParams}
          id={invoiceId}
          entity={"invoices"}
          tags={"INVOICE"}
        />
      );
    }
    return null;
  };

  // switch (TypesData) {
  //   case "sms":
  //     {
  //       SwitchingAction = () => (
  //         <SmsAction
  //           getParams={invoiceCustomerId}
  //           id={invoiceId}
  //           entity={"invoices"}
  //           tags={"INVOICE"}
  //         />
  //       );
  //     }
  //     break;
  //   case "whatsapp":
  //     {
  //       SwitchingAction = () => (
  //         <WhatsAppAction
  //           getParams={invoiceCustomerId}
  //           id={invoiceId}
  //           entity={"invoices"}
  //           tags={"INVOICE"}
  //         />
  //       );
  //     }
  //     break;
  //   case "next":
  //     {
  //       SwitchingAction = () => <NextAction />;
  //     }
  //     break;
  //   case "call":
  //     {
  //       SwitchingAction = () => <ManualCall />;
  //     }
  //     break;

  //   case "email":
  //     {
  //       SwitchingAction = (props) => {
  //         return (
  //           <EmailAction
  //             getParams={invoiceCustomerId}
  //             id={invoiceId}
  //             entity={"invoices"}
  //             tags={"INVOICE"}
  //             removeAttchFile={unSelectedInvoiceDocumentData}
  //           />
  //         );
  //       };
  //     }
  //     break;

  //   case "dispute":
  //     {
  //       SwitchingAction = () => (
  //         <DisputeAction
  //           getParams={invoiceCustomerId}
  //           invoiceId={invoiceId}
  //           enity={"invoices"}
  //         />
  //       );
  //     }
  //     break;

  //   case "letter":
  //     {
  //       SwitchingAction = () => <LetterAction />;
  //     }
  //     break;
  //   // case "hold":
  //   //   {
  //   //     SwitchingAction = () => <></>;
  //   //   }
  //   //   break;
  //   case "payments":
  //     {
  //       SwitchingAction = () => <PaymentsAction />;
  //     }
  //     break;

  //   case "review":
  //     {
  //       SwitchingAction = () => <ReviewPromiseAction />;
  //     }
  //     break;

  //   default:
  //     SwitchingAction = () => "";
  // }

  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  let quickActionEnabel = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceDetails.is_quick_action
  );

  // let quickActionEnabel = customerDetails?.is_quick_action;

  let workflowAsigned =
    customerDetails?.workflow && customerDetails?.workflow.id
      ? customerDetails?.workflow.id
      : null;

  useEffect(() => {
    if (type === "email") {
      setValue(
        "is_include_invoice",
        currentAction && currentAction?.is_include_invoice
      );
    }
  }, [type]);

  const mostUsedData = [
    {
      id: "1",
      actionName: "SMS",
      type: "sms",
      content: "SMS Reminders with/without an actionable link",
      is_active: true,
      is_disable: !quickActionEnabel,
    },
    {
      id: "2",
      actionName: "Email",
      type: "email",
      content: "Email follow-up with Customer",
      is_active: true,
      is_disable: !quickActionEnabel,
    },
    {
      id: "3",
      actionName: "Dispute",
      type: "dispute",
      content: "Review and Update Dispute Status.",
      is_active: true,
    },
    {
      id: "4",
      actionName: "WhatsApp",
      type: "whatsapp",
      is_active: true,
      is_disable: !quickActionEnabel,
    },
  ];

  const fileSize = 10000000;
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 1MB`,
      };
    }
  }

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: {
        "image/jpg": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      validator: uploadLogoValidation,

      onDrop: (acceptedFiles) => {
        let arrObj = acceptedFiles.map((fileDoc) => {
          let { path, type } = fileDoc;

          let splitType = type.split("/");

          return {
            name: path,
            type: splitType[1],
            mimeType: type,
            fileDoc,
          };
        });
        dispatch(addEmailInvoiceAttachmentFromDesktop(arrObj));

        // dispatch(addEmailAttachmentFromDesktop(arrObj));
        // dispatch(emailAttachedFile(acceptedFiles));
        // setAddedAttachment(
        //   acceptedFiles.map((file) =>
        //     Object.assign(file, {
        //       preview: URL.createObjectURL(file),
        //     })
        //   )
        // );
      },
      // validator: uploadLogoValidation,
    });

  if (all.length == 0 || !data || !data.data || isError) {
    return (
      <AssignWorkflow
        mostUsedData={mostUsedData}
        getParams={invoiceCustomerId}
        id={invoiceId}
        entity={"invoices"}
        tags={"INVOICE"}
        invoiceId={invoiceId}
      />
    );
  }

  if (isModalOpens === true) {
    return <SwitchingAction type={TypesData} getParams={getParams} />;
  }

  if (isLoading || isSubmitting || isFetching) {
    return <Loader />;
  }
  return (
    <>
      {/* {all.length == 0 || !data || !data.data || isError ? (
        <AssignWorkflow
          mostUsedData={mostUsedData}
          getParams={invoiceCustomerId}
          id={invoiceId}
          entity={"invoices"}
          tags={"INVOICE"}
          invoiceId={invoiceId}
        />
      ) : isModalOpens === true ? (
        <SwitchingAction type={TypesData} getParams={invoiceCustomerId} />
      ) : ( */}
      <form
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* workflow start */}

        <div
          style={{
            display: "flex",
            padding: "0px 20px",
            flexDirection: "column",
          }}
        >
          {/* Actions Tab - Header Ends */}

          <>
            {/* Actions Tab - Timeline Starts */}
            {/* Actions Tab - Header Starts */}
            {/* TODO: MOVE ABOVE COMPONENT BELOW FORM */}

            {/* TODO: MOVE ABOVE COMPONENT BEFORE FORM */}
            {hideWorkflow ? (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100% ",
                    overflow: "hidden",
                    padding: "0px",
                    flexDirection: "row",
                  }}
                >
                  {(active[0] && active[0]._id) === (all[0] && all[0]._id) ? (
                    <></>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => {
                          previous();
                        }}
                      >
                        <LeftArrow />
                      </div>
                    </>
                  )}

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      padding: "20px 0px 0px 0px",
                      margin: "0px 20px",
                    }}
                  >
                    {active.map((act, i) => {
                      return (
                        <InvoiceWorkflowAction
                          {...act}
                          index={i}
                          workFlow={workflowData}
                        />
                      );
                    })}
                  </div>
                  {(active[active.length - 1] &&
                    active[active.length - 1]._id) ===
                  (all[all.length - 1] && all[all.length - 1]._id) ? (
                    <></>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => {
                          next();
                        }}
                      >
                        <RightArrow />
                      </div>
                    </>
                  )}
                </div>

                {/* Actions Tab - Timeline Ends */}

                {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "13px",
                      gap: "30px",
                    }}
                  > */}
                {/* {data &&
                    data.data &&
                    data.data.doc &&
                    data.data.doc.workflow.status === "ON_HOLD" ? (
                      <>
                        <IconButton
                          size={SIZE.compact}
                          style={{ borderRadius: "20px" }}
                          type="button"
                          onClick={() => dispatch(releaseActionModal(true))}
                        >
                          <ReleaseOnHold />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <div>
                          <IconButton
                            onClick={() => dispatch(holdActionModal(true))}
                            size={SIZE.compact}
                            style={{ borderRadius: "20px" }}
                            type="button"
                          >
                            <CaptureHold />
                          </IconButton>
                        </div>
                      </>
                    )} */}

                {/* <div>
                      <IconButton
                        style={{
                          borderRadius: "20px",
                          width: "36px",
                          height: "36px",
                        }}
                        onClick={() => dispatch(skipActionModal(true))}
                        type="button"
                      >
                        <Next />
                      </IconButton>
                    </div> */}
                {/* </div> */}
              </>
            ) : (
              ""
            )}
          </>
        </div>

        {/* workflow end */}

        {/* Displays the form (inputs) below the workflow screen to 
      capture the action outcome / make changes to action */}
        {data &&
        data.data &&
        data.data.doc &&
        data.data.doc.workflow.status === "ON_HOLD" ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "50px",
              }}
            >
              {/* Picture Section */}
              <div>{/* <img src={Hold} alt="" /> */}</div>
              {/* Content Section */}
              <div>
                <LabelLarge>
                  <span
                    style={{
                      color: "#333860",
                      textAlign: "center",
                      paddingLeft: "55px",
                    }}
                  >
                    Invoice Actions is
                  </span>{" "}
                  <span style={{ color: "#516BEB", paddingLeft: "" }}>
                    Paused
                  </span>
                </LabelLarge>
                <div style={{ padding: "15px 0px" }}>
                  <div style={{ display: "flex" }}>
                    <ParagraphMedium style={{ color: "#ADADAD" }}>
                      Pause Till Date:
                    </ParagraphMedium>
                    <ParagraphMedium
                      style={{ paddingLeft: "15px", color: "#333860" }}
                    >
                      {/* 27-02-2022 */}
                      {holdDateFormate}
                    </ParagraphMedium>
                  </div>
                  <div
                    style={{
                      display: "flex",

                      paddingTop: "10px",
                    }}
                  >
                    <ParagraphMedium style={{ color: "#ADADAD" }}>
                      Reason:
                    </ParagraphMedium>
                    <ParagraphMedium
                      style={{ paddingLeft: "50px", color: "#333860" }}
                    >
                      {filteringValue[0]?.label}
                      {/* {HoldDatas?.content?.hold_reason} */}
                    </ParagraphMedium>
                  </div>{" "}
                  <div
                    style={{
                      display: "flex",

                      paddingTop: "10px",
                    }}
                  >
                    <ParagraphMedium style={{ color: "#ADADAD" }}>
                      Remarks:
                    </ParagraphMedium>
                    <ParagraphMedium
                      style={{
                        paddingLeft: "44px",
                        color: "#333860",
                        width: "400px",
                      }}
                    >
                      {HoldDatas?.comment}
                    </ParagraphMedium>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <StatefulTooltip
                      content={
                        invData && invData?.action_hold_by === "CUSTOMER" ? (
                          <div>
                            Consumer Level Hold - Resume Option available at
                            Consumer Level
                          </div>
                        ) : (
                          ""
                        )
                      }
                      showArrow
                      returnFocus
                      autoFocus
                      placement={PLACEMENT.left}
                      triggerType={TRIGGER_TYPE.click}
                    >
                      <RBACWrapper role={INVOICE_RESUME} type="PERMISSION">
                        <div style={{ width: "150px" }}>
                          <TextButton
                            size={SIZE.compact}
                            kind={KIND.secondary}
                            style={{ width: "100%" }}
                            type="button"
                            // disabled={
                            //   invData && invData?.action_hold_by === "CUSTOMER"
                            //     ? true
                            //     : false
                            // }
                            startEnhancer={() => <ReleaseOnHold size="18px" />}
                            onClick={() => {
                              invData && invData?.action_hold_by === "CUSTOMER"
                                ? dispatch()
                                : dispatch(releaseActionModal(true));
                            }}
                          >
                            Release Hold
                          </TextButton>
                        </div>
                      </RBACWrapper>
                    </StatefulTooltip>
                    <ParagraphMedium style={{ paddingLeft: "10px" }}>
                      to Continue take Action
                    </ParagraphMedium>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </>
        ) : (
          <>
            <div className="cp-action-switch-container-review ">
              <WorkflowAction
                customerActions={all}
                actioWatch={actioWatch}
                watch={watch}
                control={control}
                errors={errors}
                intl={intl}
                viewOnly={viewOnly}
                dispatch={dispatch}
                quillToolbar={quillToolbar}
                callBackActin={callBackActin}
                hideWorkflowArea={hideWorkflowArea}
                setValue={setValue}
                data={data && data.data && data.data.doc ? data.data.doc : {}}
                callOutcome={callOutcome}
                setCallOutcome={setCallOutcome}
                setError={setError}
                clearErrors={clearErrors}
                getParams={invoiceCustomerId}
              />
            </div>

            <div className="cp-action-switch-footer">
              {
                (type = "follow_up_call" ? (
                  <>
                    {type === "email" || type === "letter" ? (
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "flex-end",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* <div
                            style={{
                              padding: "19px",
                              paddingLeft: "30px",
                              display: "flex",
                              gap: "10px",
                            }}
                          > */}
                        {/* <Button
                              type="button"
                              kind={KIND.tertiary}
                              size={SIZE.compact}
                              style={{ width: "126px", height: "36px" }}
                              onClick={() => {
                                // defaultCancelValue();
                                setIsModalOpen(true);
                              }}
                              overrides={{
                                BaseButton: {
                                  style: ({ $theme }) => ({
                                    border: "1px solid #CDCED9",
                                    borderRadius: "5px",
                                  }),
                                },
                              }}
                            >
                              <span className="wfm-attachment_btn">
                                <Attachment />{" "}
                              </span>
                              &nbsp;Files
                            </Button> */}
                        {/* <div>
                              {attachmentLength <= 2 ? (
                                <>
                                  <div className="wfm-attachment-container">
                                    {attachments.map((e, i) => (
                                      <div
                                        className="wfm-attached_files"
                                        style={{ marginTop: "0px" }}
                                      >
                                        <Files />
                                        <ParagraphMedium>
                                          <div>{e.name}</div>
                                        </ParagraphMedium>
                                        <div
                                          onClick={() =>
                                            dispatch(removeCOEmailAttachment(e))
                                          }
                                        >
                                          <ClearFile />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="wfm-attachment-container">
                                    {slicedTwoValue.map((e, i) => (
                                      <div
                                        className="wfm-attached_files"
                                        style={{ marginTop: "0px" }}
                                      >
                                        <Files />
                                        <ParagraphMedium>
                                          <div>{e.name}</div>
                                        </ParagraphMedium>
                                        <div
                                          onClick={() =>
                                            // dispatch(removeTemplateAttachment(e))
                                            dispatch(removeCOEmailAttachment(e))
                                          }
                                        >
                                          <ClearFile />
                                        </div>
                                      </div>
                                    ))}
                                    <div
                                      style={{
                                        width: "25px",
                                        height: "20px",
                                        border: "1px solid #ADADAD",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      +{attachmentLength - slicedLength}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div> */}
                        {/* </div> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "35px",
                          }}
                        >
                          <StatefulPopover
                            content={({ close }) => (
                              <div className="cp_email_attachement">
                                {/* [{filename:"From Desktop",}] */}
                                <ParagraphMedium
                                  $style={{}}
                                  onClick={() => {
                                    open();
                                    close();
                                  }}
                                >
                                  From Desktop
                                </ParagraphMedium>
                                <ParagraphMedium
                                  onClick={() => {
                                    dispatch(invoiceDriveOpen(true));
                                    close();
                                  }}
                                >
                                  From Consumer Drive
                                </ParagraphMedium>
                              </div>
                            )}
                            returnFocus
                            autoFocus
                          >
                            <Button
                              type="button"
                              kind={KIND.secondary}
                              size={SIZE.mini}
                              overrides={{
                                BaseButton: {
                                  style: ({ $theme }) => ({
                                    border: "1px solid #CDCED9",
                                    borderRadius: "5px",
                                  }),
                                },
                              }}
                            >
                              <LabelSmall>Add Attachments</LabelSmall>
                            </Button>
                          </StatefulPopover>
                          {organizationDetail?.accounting_book !== "CSV" ? (
                            <>
                              <div>
                                <Controller
                                  name="is_include_invoice"
                                  control={control}
                                  render={({ field }) => (
                                    <CheckBoxBaseweb
                                      checkmarkType={STYLE_TYPE.toggle_round}
                                      {...field}
                                      checked={field.value}
                                      labelPlacement={LABEL_PLACEMENT.left}
                                    >
                                      <div>Attach Invoice</div>
                                    </CheckBoxBaseweb>
                                  )}
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <RBACWrapper role={INVOICE_WF_SKIP} type="PERMISSION">
                            <div className="cp-action-btn">
                              <TextButton
                                type="button"
                                size={SIZE.compact}
                                fullWidth
                                kind={KIND.secondary}
                                disabled={scanFinalStatus}
                                startEnhancer={() => <Next size="18px" />}
                                onClick={() => dispatch(skipActionModal(true))}
                              >
                                Skip Action
                              </TextButton>
                            </div>
                          </RBACWrapper>

                          <div className="cp-action-btn">
                            <TextButton
                              type="button"
                              size={SIZE.compact}
                              fullWidth
                              isLoading={isSubmitting}
                              disabled={isSubmitting || scanFinalStatus}
                              onClick={async () => {
                                const result = await trigger();
                                if (result) {
                                  const values = getValues();
                                  onSubmit(values);
                                }
                              }}
                            >
                              Send
                            </TextButton>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <RBACWrapper role={INVOICE_WF_SKIP} type="PERMISSION">
                            <div className="cp-action-btn">
                              <TextButton
                                type="button"
                                size={SIZE.compact}
                                fullWidth
                                kind={KIND.secondary}
                                startEnhancer={() => <Next size="18px" />}
                                onClick={() => dispatch(skipActionModal(true))}
                              >
                                Skip Action
                              </TextButton>
                            </div>
                          </RBACWrapper>
                          <div className="cp-action-btn">
                            <TextButton
                              size={SIZE.compact}
                              fullWidth
                              // disabled={
                              //   type === "sms"
                              //     ? isSubmitting || smsGateWayDatas === false
                              //     : isSubmitting ||
                              //       promiseAmountChangingValue[0]?.value >
                              //         total_outstanding_invoice_amount?.value
                              // }
                              disabled={isSubmitting}
                            >
                              Save
                            </TextButton>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="cp-action-btn">
                      <TextButton
                        type="submit"
                        size={SIZE.mini}
                        fullWidth
                        disabled={isSubmitting}
                      >
                        Send
                      </TextButton>
                    </div>
                  </>
                ))
              }
            </div>
          </>
        )}
      </form>
      {/* )} */}
    </>
  );
};

export default InvoiceActions;
