import React, { useContext, useState, useEffect } from "react";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";
import { TextButton, SIZE, KIND } from "../TextButton";
import AssignDataSvg from "../../assets/img/svg/AssignDataSvg";
import AssignDataDownArrow from "../../assets/img/svg/AssignDataDownArrow";
import UploadDataModal from "../UploadDataModal/UploadDataModal";
import { customerData } from "../../services/customerSummary";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  getWorkflowlist,
  uploadCustomerAdhocData,
  uploadCustomerVoiceData,
} from "../../services";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import csvDownload from "json-to-csv-export";
import _ from "lodash";
import { useNumericFormat } from "react-number-format";
import { useQuery } from "react-query";
import { WORKFLOW_MAINTENANCE } from "../../constants";
import { useSelector } from "react-redux";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import { ModalContext } from "../../providers/ModalProvider";
import CreditReportModel from "../FormModal/creditReportModel";
import workflowModalPic from "../../assets/img/workflow-modal.png";
import {
  GENERATE_CREDIT_REPORT,
  UPLOAD_CREDIT_REPORT_RETURN_FILE,
} from "../../providers/RBACProvider/permissionList";
import { Icon } from "../../components_v2";
import { toast } from "react-toastify";
// const PERMISSIONITEMS = [
//   { label: "Download Customer Data", id: "dct" },
//   { label: "Upload Data", id: "ud" },
// ];

const AssignData = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreditOpen, setIsCreditOpen] = useState(false);
  const [isCreditModel, setIsCreditModel] = useState(false);
  // const [clickedItem, setClickedItem] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCreditLoading, setIsCreditLoading] = useState(false);
  const { handleModal } = useContext(ModalContext);
  const intl = useIntl();
  let {
    currentOrganization,
    referenceData,
    users,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    space,
  } = useContext(MaxyfiContext);

  const ITEMS = [
    {
      label: intl.formatMessage({
        id: "download_customer_data",
      }),
      id: "dct",
    },
    {
      label: intl.formatMessage({
        id: "download_workflow_list_data",
      }),
      id: "dwl",
    },
    {
      label: intl.formatMessage({
        id: "download_relation_manager_list",
      }),
      id: "drm",
    },
    {
      label: intl.formatMessage({
        id: "upload_data",
      }),
      id: "ud",
    },
    {
      label: intl.formatMessage({
        id: "upload_voice_mail_status",
      }),
      id: "uvms",
    },
  ];
  const generateCreditReport =
    space && space?.role_permissions?.includes(GENERATE_CREDIT_REPORT);
  const uploadCreditReport =
    space &&
    space?.role_permissions?.includes(UPLOAD_CREDIT_REPORT_RETURN_FILE);
  const CREDITITEMS = [
    generateCreditReport && {
      label: intl.formatMessage({ id: "generate_credit_report" }),
      id: "gcr",
    },
    uploadCreditReport && {
      label: intl.formatMessage({ id: "upload_return_file" }),
      id: "urf",
    },
  ].filter(Boolean);

  // const resumePermission =
  //   space && space?.role_permissions?.includes("bulk_upload_rm_workflow");
  //
  // Customer Data Starts

  const numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });
  let filteredColumns = props.allColumns;

  let neededData = [
    "display_name",
    // "rel_manager",
    "workflow.name",
    "due_inv_amount",
    "overdue_inv_amount",
    "total_outstanding_invoice_amount",
    "cus_max_delay",
    "customer_status",
    "customer_comment",
  ];

  let finalFilteredData = filteredColumns.filter((i) =>
    neededData.includes(i.id)
  );

  let includedData = [
    ...finalFilteredData,
    {
      id: "cus id",
      path: "customer_id",
      cell_type: "NUMBER",
      col_id: "col_cs_10",
      disableFilters: false,
      disableSortBy: false,
      id: "cus_id",
      literal_id: "customer_id",
    },
    {
      path: "workflow.start_date",
      id: "wk startDate",
      cell_type: "DATEORGTZ",
      col_id: "col_cs_11",
      disableFilters: false,
      disableSortBy: false,
      id: "wf_date",
      literal_id: `${"workflow_start_date"}`,
      tag: `(${currentDefaultFormatDetails.date_format})`,
    },
    {
      path: "total_inv_count",
      id: "total inv count",
      cell_type: "NUMBER",
      col_id: "col_cs_12",
      disableFilters: false,
      disableSortBy: false,
      id: "total_inv_count",
      literal_id: "total_inv_count_",
    },
    {
      path: "rel_manager",
      id: "total inv amount",
      cell_type: "USER",
      col_id: "col_cs_13",
      disableFilters: false,
      disableSortBy: false,
      id: "rel_manager",
      literal_id: "rel_manager_email",
    },
    {
      path: "business_unit",
      id: "business_unit",
      cell_type: "REFERENCE_VALUE",
      col_id: "col_cs_14",
      disableFilters: false,
      disableSortBy: false,
      rdName: "business_unit_list",
      // id: "rel_manager",
      literal_id: "business_unit_name",
    },
    {
      path: "business_location",
      id: "business_location",
      cell_type: "REFERENCE_VALUE",
      col_id: "col_cs_15",
      disableFilters: false,
      disableSortBy: false,
      rdName: "business_location_list",
      // id: "rel_manager",
      literal_id: "business_unit_location",
    },
    {
      path: "is_quick_action",
      id: "is_quick_action",
      cell_type: "HOLDACTIONREASON",
      col_id: "col_cs_15",
      disableFilters: false,
      disableSortBy: false,
      // id: "rel_manager",
      literal_id: "is_quick_action",
    },
    {
      path: "hold_quick_action_reason",
      id: "hold_quick_action_reason",
      cell_type: "REFERENCE_VALUE",
      col_id: "col_cs_15",
      disableFilters: false,
      disableSortBy: false,
      rdName: "hold_action_reason",
      // id: "rel_manager",
      literal_id: "hold_quick_action_reason",
    },
    {
      path: "wq",
      id: "wq",
      cell_type: "ALPHANUMERIC",
      col_id: "col_cs_15",
      disableFilters: false,
      disableSortBy: false,
      // id: "rel_manager",
      literal_id: "wq",
    },
    {
      path: "hold_quick_action_till",
      id: "hold_quick_action_till",
      cell_type: "DATEORGTZ",
      col_id: "col_cs_15",
      disableFilters: false,
      disableSortBy: false,
      // id: "rel_manager",
      literal_id: "hold_quick_action_till",
    },
  ];

  let reOrder = [];
  let redoreder = includedData.map((e, i) => {
    let accessorData = [
      4, 13, 14, 16, 17, 6, 8, 9, 3, 7, 15, 16, 5, 1, 2, 10, 11, 12,
    ];
    return reOrder.push({ ...e, reOrder: accessorData[i] });
  });

  let sortingTableData = reOrder.sort((a, b) => a.reOrder - b.reOrder);

  // let tableOrderChange = includedData.filter(
  //   (i) => !accessorData.includes((k) => k.accessor === i.accessor)
  // );

  // let changingOrder = accessorData;

  // Customer Data Ends

  // Relation Manager Starts

  let relationManager = users;
  let filteringActiveRm = relationManager?.filter((i) => i.active);

  let sortingRm = filteringActiveRm?.sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );

  let userRole = referenceData?.user_role;

  let changingKeys = sortingRm?.map(({ displayName, email, roleId }) => {
    let changedroleId = roleId ? userRole?.find((i) => i?.id === roleId) : null;
    let roleIdChange = changedroleId?.label;
    return {
      "Relation Manager Name": displayName,
      "Relation Manager Email": email,
      Role: roleIdChange,
    };
  });

  const { gridStructure } = useSelector((state) => state.customerSummary);

  let newFilter = dynamicQueryFilter({
    gridStructure: gridStructure,
    // customerQueryFilter: customerQueryFilter,
    timeZone: currentDefaultFormatDetails.time_zone,
    customerFilter: props.customerFilter,
    // fieldName: "display_name",
  });

  return (
    <>
      {generateCreditReport || uploadCreditReport ? (
        <StatefulPopover
          focusLock
          placement={PLACEMENT.bottomRight}
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                borderRadius: "5px",
              }),
            },
          }}
          content={({ close }) => (
            <StatefulMenu
              // items={resumePermission ? PERMISSIONITEMS : ITEMS}
              items={CREDITITEMS}
              onItemSelect={(params) => {
                if (_.get(params, "item.id", "") === "gcr") {
                  setIsCreditModel(true);

                  handleModal({
                    title: "",
                    content: "",
                    isChildren: true,

                    successCallback: () => {
                      // deleteTemplate.mutate(_id);
                    },
                    formContent: ({ handleModal }) => {
                      return (
                        <>
                          <CreditReportModel
                            handleModal={handleModal}
                            customerRefetch={props.refetch}
                            queryFilter={props.queryFilter}
                          />
                        </>
                      );
                    },
                    cancelCallback: () => {},
                    image: workflowModalPic,
                    buttonText: "",
                    closeButtonText: "",
                    isCloseAble: false,
                  });
                } else if (_.get(params, "item.id", "") === "urf") {
                  setIsCreditOpen(true);
                }
              }}
            />
          )}
        >
          <div>
            <TextButton
              style={{ width: "35px", height: "30px" }}
              size={SIZE.mini}
              kind={KIND.secondary}
              overrides={{
                LoadingSpinner: {
                  style: ({ $theme }) => ({
                    height: "18px",
                    width: "18px",
                  }),
                },
              }}
            >
              <Icon icon="credit_report" />
            </TextButton>
          </div>
        </StatefulPopover>
      ) : (
        <></>
      )}

      <StatefulPopover
        focusLock
        placement={PLACEMENT.bottomRight}
        overrides={{
          Body: {
            style: ({ $theme }) => ({
              borderRadius: "5px",
            }),
          },
        }}
        content={({ close }) => (
          <StatefulMenu
            // items={resumePermission ? PERMISSIONITEMS : ITEMS}
            items={ITEMS}
            onItemSelect={async (params) => {
              close();
              if (params.item.id === "ud") {
                setIsModalOpen(true);
              } else if (params.item.id === "dct") {
                if (
                  _.get(props, "getCustomerCount.data.data.totalDocs", 0) <=
                  10000
                ) {
                  setLoading(true);

                  let data = await props
                    .service({
                      organization: currentOrganization,
                      pageIndex: 1,
                      sortBy: props.querySortBy,
                      pageSize: 0,
                      filters: props.queryFilter,
                      customerFilter: newFilter,
                    })
                    .then((res) => {
                      // setData(res?.data?.docs);
                      setLoading(false);
                      return res.data.docs;
                    })
                    .catch((error) => {
                      return [];
                    });

                  let mockData = data?.map((row) => {
                    let rsObj = {};

                    sortingTableData?.forEach((k) => {
                      let key = k.literal_id;
                      key = intl.formatMessage({
                        id: key,
                      });

                      if (k.tag) {
                        key = key + " " + k.tag;
                      } else if (
                        props.defaultCurrency &&
                        k.cell_type === "CURRENCY"
                      ) {
                        key = `${key} ${""} (in ${props.defaultCurrency})`;
                      }

                      let value = _.get(row, k.path, "");
                      switch (k.cell_type) {
                        // Next Action Date
                        case "DATEORGTZ": {
                          const orgRef = currentOrganization
                            ? referenceData?.organizations?.find(
                                ({ id }) => id === currentOrganization
                              )
                            : null;
                          const timeZone = orgRef?.time_zone;
                          let date = moment
                            .utc(value)
                            ?.tz(timeZone)
                            ?.format(currentDefaultFormatDetails?.date_format);
                          if (value) {
                            value = date;
                          }
                          break;
                        }

                        case "DATE": {
                          let dateOn = moment
                            .utc(value)
                            .format(currentDefaultFormatDetails?.date_format);
                          if (value) {
                            value = dateOn;
                          }

                          break;
                        }

                        case "USER": {
                          const user = users?.find(({ id }) => {
                            return id === value;
                          });

                          if (value) {
                            value = user?.email;
                          }

                          break;
                        }

                        case "CURRENCY": {
                          if (value) {
                            value = `${value?.value} ${
                              props.defaultCurrency ? "" : value?.currency
                            }`;
                          }
                          break;
                        }
                        case "REFERENCE_VALUE": {
                          if (value) {
                            let rdData = value
                              ? referenceData &&
                                referenceData[k && k.rdName]?.find(
                                  ({ id }) => id == value
                                )
                              : null;
                            if (k && k.rdName === "business_unit_list") {
                              value = rdData && rdData.client_name;
                            } else if (!rdData) {
                              value = value;
                            } else {
                              value = rdData && rdData.label;
                            }
                          } else {
                            value = "";
                          }
                          break;
                        }
                        case "HOLDACTIONREASON": {
                          if (value) {
                            value = "No";
                          } else {
                            value = "Yes";
                          }
                          break;
                        }
                      }
                      rsObj[key] = value;
                    });

                    return rsObj;
                  });
                  csvDownload(
                    mockData,
                    `${`${props.fileName} ${moment().format(
                      currentDefaultFormatDetails?.date_format
                    )}${" "}${moment().format("hh:mm:ss a")}.csv`}`
                  );
                } else {
                  toast.error(
                    "Max download limit is 10K, Kindly retry with < 10K records"
                  );
                }
              } else if (params.item.id === "dwl") {
                let data;

                try {
                  data = await getWorkflowlist({
                    organization: currentOrganization,
                    page: 1,
                    page_size: 0,
                    filters: {
                      is_deleted: false,
                      status: true,
                      is_disabled: false,
                    },
                  });
                } catch (err) {}

                let workflowData =
                  data && data.data && data.data.docs ? data.data.docs : [];

                let sortingData =
                  workflowData &&
                  workflowData.sort((a, b) => a.name.localeCompare(b.name));

                let changingWorkflowKeys = [
                  ...(sortingData ? sortingData : []),
                ]?.map(({ name, description }) => ({
                  "Workflow Name": name,
                  "Workflow Description": description,
                }));

                csvDownload(
                  changingWorkflowKeys,
                  `${`${"Workflow"} ${moment().format(
                    currentDefaultFormatDetails?.date_format
                  )}${" "}${moment().format("hh:mm:ss a")}.csv`}`
                );
              } else if (params.item.id === "drm") {
                csvDownload(
                  changingKeys,
                  `${`${"Relation"} ${moment().format(
                    currentDefaultFormatDetails?.date_format
                  )}${" "}${moment().format("hh:mm:ss a")}.csv`}`
                );
              } else if (params.item.id === "uvms") {
                // setClickedItem("voice");
                setIsModalOpen(true);
              }
            }}
            overrides={{
              List: {
                style: {
                  height: "160px",

                  width: "230px",
                },
              },
            }}
          />
        )}
      >
        <div style={{ width: "140px", display: "flex", gap: "5px" }}>
          <TextButton
            startEnhancer={
              <i className="mx-icon-upload upload-color-change" />
              // <AssignDataSvg />
            }
            size={SIZE.mini}
            endEnhancer={
              <i className="mx-icon-arrow_drop_down_FILL0_wght400_GRAD0_opsz48-2 mx-icon-arrow_drop_down" />
              // <AssignDataDownArrow />
            }
            fullWidth
            style={{ fontWeight: "500", fontSize: "12px" }}
          >
            <FormattedMessage id="assign_data">assign_data</FormattedMessage>
          </TextButton>
        </div>
      </StatefulPopover>

      <UploadDataModal
        isModalOpen={isModalOpen}
        isCreditOpen={isCreditOpen}
        setIsCreditOpen={setIsCreditOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default AssignData;
