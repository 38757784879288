import React, { useMemo } from "react";
import { Typography } from "../../../components_v2";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import {
  getDocumentData,
  getSubCategory,
} from "../../../services/documentSystem";
import {
  COMMUNICATION_TEMPLATE,
  CO_TIMELINE_,
  CUSTOMER_OVERVIEW_,
} from "../../../constants";
import {
  getCommunicationTemplateList,
  letterName,
  letterPreviewPost,
} from "../../../services";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { useState } from "react";
import { SelectBox } from "../../../components/SelectBox";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextBox } from "../../../components/TextBox";
import DocFileType from "../../../assets/img/svg/DocFileType";
import { KIND, SIZE } from "baseui/button";
import EmailAttachment from "../../../components/EmailAttachment/EmailAttachment";
import { useEffect } from "react";
import { ADD_LIST_VALUES } from "../../../providers/RBACProvider/permissionList";
import { Spinner } from "baseui/icon";
import _ from "lodash";
import { TextButton } from "../../../components/TextButton";
import moment from "moment";
import getSelectValues from "../../../utils/getSelectValues";
import ActionReminders from "../../../components_v2/ActionReminders";
import getActionReminder from "../../../utils_v2/getActionReminders";
import { useDispatch, useSelector } from "react-redux";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { useDropzone } from "react-dropzone";
import SelectDoc from "../Actions/SelectDoc";
import {
  addLetterEmailAttachment,
  onSuccessLetterData,
} from "../../../redux/customerOverview/letter/action";
import { setDrawerState } from "../../../redux/actions";
import queryClient from "../../../providers/queryClient";
import setSelectValues from "../../../utils/setSelectValues";

const LegalLetterForm = (props) => {
  let {
    recipient_id,
    customerId,
    entity,
    formValues,
    tags,
    master_tag,
    removeAttchFile,
    updatingDeskUpload,
    service,
    letterAttachmentsData,
    doucumentAttachment,
  } = props;
  const intl = useIntl();
  let dispatch = useDispatch();
  const {
    referenceData,
    currentOrganization,
    space,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  const [isSearch, setIsSearch] = useState("");
  const [isDriveData, setIsDriveData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  const [letterNameSelect, setLetterName] = useState([]);

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      letter_generated: "system_generator",
      comments: "",
    },
  });

  let { letter_generated, template_id, invoice_follow_ups, sub_category } =
    watch();

  const postLetterPreview = useMutation(
    (data) => {
      return letterPreviewPost({
        customerId: customerId,
        organization: currentOrganization,
        ...data,
      });
    },
    {
      onSuccess: (data, variables, context) => {},
    }
  );

  const subCategory = useQuery(
    [`sub-category-${customerId}`],
    async () => {
      return await getSubCategory({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: true,
      // refetchOnWindowFocus: true,
    }
  );

  const communicationTemplateData = useQuery(
    [
      `${COMMUNICATION_TEMPLATE}-LETTER-${currentOrganization}`,
      {
        filters: {
          disable: false,
          type: "letter",
          sub_type: "letter_content",
          tags: tags,
          ...(master_tag ? { master_tag: master_tag } : {}),
        },
      },
    ],
    async ({ queryKey }) => {
      let { page, page_size, sortBy, filters, type } = queryKey[1];
      return await getCommunicationTemplateList({
        organization: currentOrganization,
        clientCode: [currentOrganization],
        filters,
        type,
      });
    }
  );

  const { isLoading, data, refetch, isFetching } = useQuery(
    [`document-management-system-${customerId}`],
    async ({ queryKey }) => {
      if (customerId) {
        return await getDocumentData({
          organization: currentOrganization,
          customerId: customerId,
        });
      }
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (_.get(data, "data.docs", [])) {
      setIsDriveData(_.get(data, "data.docs", []));
    }
  }, [data]);

  useEffect(() => {
    if (
      isSearch &&
      isSearch.length > 0 &&
      data &&
      data.data &&
      data.data.docs
    ) {
      const searchResult = _.get(data, "data.docs", []).filter((item) =>
        item.file_name.toLowerCase().includes(isSearch.toLowerCase())
      );
      setIsDriveData(searchResult);
    } else {
      setIsDriveData(_.get(data, "data.docs", []));
    }
  }, [isSearch]);

  const fileSize = 10000000;
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 1MB`,
      };
    }
  }

  const { open } = useDropzone({
    accept: {
      "image/jpg": [".jpg", ".png"],
      "text/*": [".pdf", ".csv"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    validator: uploadLogoValidation,

    onDrop: (acceptedFiles) => {
      let arrObj = acceptedFiles.map((fileDoc) => {
        let { path, type } = fileDoc;

        let splitType = type.split("/");

        return {
          name: path,
          type: splitType[1],
          mimeType: type,
          fileDoc,
        };
      });
      dispatch(addLetterEmailAttachment(arrObj));
    },
  });

  const onFileSelectChange = (file) => {
    setSelectedData((prevSelectedData) => {
      const isSelected = prevSelectedData.find(
        (fl) => fl.fileRef === file.fileRef
      );
      if (isSelected) {
        return prevSelectedData.filter((fl) => fl.fileRef !== file.fileRef);
      } else {
        return [...prevSelectedData, file];
      }
    });
  };

  useEffect(async () => {
    if (customerId && letter_generated === "manual_generator") {
      await letterName({
        organization: currentOrganization,
        customerId: customerId,
      }).then((res) => {
        let item = res && res.data && res.data.docs;

        if (item) {
          setLetterName(item);
        }
      });
    }
  }, [customerId, letter_generated]);

  const executeActionMutation = useMutation(
    (formValues) =>
      service({
        ...formValues,
        id: customerId,
        organization: currentOrganization,
        entity: entity,
      }),
    {
      onSuccess: (data, variables, context) => {
        dispatch(onSuccessLetterData());
        queryClient.invalidateQueries({
          queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
        });
        queryClient.invalidateQueries({
          queryKey: [`${CO_TIMELINE_}${customerId}`],
        });
        closeDrawer();
        reset();
      },
    }
  );

  // useEffect(() => {
  //   return () => {
  //     dispatch(onSuccessLetterData());
  //   };
  // }, []);

  const drawerData = useSelector((state) => state.customerOverviewDrawer);
  useEffect(() => {
    const { data } = drawerData;
    if (
      data &&
      data.isWorkflow &&
      _.get(communicationTemplateData, "data.data.docs", [])
    ) {
      let findTemData = _.get(
        communicationTemplateData,
        "data.data.docs",
        []
      ).find(({ _id }) => _id === data?.activeAction?.template_id);
      setValue("letter_generated", "system_generator");
      setValue(
        "delivery_method",
        setSelectValues(data?.activeAction?.delivery_method)
      );
      setValue("template_id", [{ ...findTemData, id: findTemData?._id }]);
    }
  }, [
    drawerData?.data,
    communicationTemplateData.isFetching,
    communicationTemplateData.isLoading,
  ]);

  const onSubmit = async (data) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      ...formValues,
      action_type: "letter",
      ...(data.letter_generated === "system_generator"
        ? { is_system_generated: true }
        : { is_system_generated: false }),

      ...(data.letter_generated === "system_generator"
        ? { template_id: getSelectValues(data.template_id) }
        : {}),

      ...(data.letter_generated === "system_generator"
        ? {
            letter_name:
              data.template_id &&
              data.template_id[0] &&
              data.template_id[0].name,
          }
        : {
            letter_name:
              data.invoice_follow_ups &&
              data.invoice_follow_ups[0] &&
              data.invoice_follow_ups[0].label,
          }),
      sub_category: getSelectValues(data.sub_category),
      date_of_communication: moment
        .tz(data.date_of_communication, currentDefaultFormatDetails.time_zone)
        .startOf("D")
        .utc()
        .valueOf(),

      sub_category: getSelectValues(data.sub_category),
      delivery_status: getSelectValues(data.delivery_status),
      delivery_method: getSelectValues(data.delivery_method),
      tracking_url: data.tracking_url,
      attachments:
        (letterAttachmentsData &&
          letterAttachmentsData.map((e) => {
            return e.fileRef;
          })) ||
        [],
      ...getActionReminder(data, {
        is_hold: false,
        is_consent_enabled: false,
        is_calendar: false,
        is_followup: false,
        is_confirmation: false,
      }),
      mention_users: uniqueMentionedUsers,
    };
    await executeActionMutation.mutateAsync(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography>
        How do you like to Generate Letters?{" "}
        <span style={{ color: "red" }}>*</span>
      </Typography>

      <div style={{ margin: "10px 0px" }}>
        <Controller
          defaultValues={"system_generator"}
          name="letter_generated"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field} value={field.value} align={ALIGN.horizontal}>
              <Radio value="system_generator">System Generator Letters</Radio>
              <Radio value="manual_generator">Manual Generator Letters</Radio>
            </RadioGroup>
          )}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {letter_generated === "system_generator" ? (
          <div style={{ width: "200px" }}>
            <Controller
              defaultValues={[]}
              name="template_id"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  size={SIZE.mini}
                  {...field}
                  name={field.name}
                  requiredAstric={true}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "template_id",
                  })}
                  placeholder={intl.formatMessage({
                    id: "template_id",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={_.get(
                    communicationTemplateData,
                    "data.data.docs",
                    []
                  )
                    .filter((e) => e.version >= 1)
                    .map((i) => {
                      return {
                        label: `${i.name} (${
                          i.language
                            ? i.language.toLocaleUpperCase()
                            : "en".toLocaleUpperCase()
                        })`,
                        id: i._id,
                        name: i.name,
                      };
                    })}
                />
              )}
            />
          </div>
        ) : (
          <div style={{ width: "200px" }}>
            <Controller
              defaultValues={[]}
              name="invoice_follow_ups"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  size={SIZE.mini}
                  {...field}
                  name={field.name}
                  requiredAstric={true}
                  creatable={creatablePermission ? true : false}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "letter_name",
                  })}
                  placeholder={intl.formatMessage({
                    id: "letter_name",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={letterNameSelect}
                />
              )}
            />
          </div>
        )}

        <div style={{ width: "200px" }}>
          <Controller
            defaultValues={[]}
            name="sub_category"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "sub_category",
                })}
                placeholder={intl.formatMessage({
                  id: "sub_category",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                creatable={creatablePermission ? true : false}
                options={
                  subCategory.data &&
                  subCategory.data.data &&
                  subCategory.data.data.docs
                }
              />
            )}
          />
        </div>

        <div style={{ width: "200px" }}>
          <Controller
            name="date_of_communication"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                error={errors[field.name] && errors[field.name].message}
                requiredAstric={true}
                label={intl.formatMessage({
                  id: "date_of_communication",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                size="mini"
              />
            )}
          />
        </div>
        <div style={{ width: "200px" }}>
          <Controller
            defaultValues={[]}
            name="delivery_method"
            control={control}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                // creatable={creatablePermission ? true : false}
                backspaceRemoves={false}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "delivery_method",
                })}
                placeholder={intl.formatMessage({
                  id: "delivery_method",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["letter_delivery_method_list"]}
              />
            )}
          />
        </div>
        <div style={{ width: "200px" }}>
          <Controller
            defaultValue={""}
            name="tracking_url"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <TextBox
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "tracking_url",
                })}
                placeholder={intl.formatMessage({
                  id: "tracking_url",
                })}
                value={field.value}
                size="mini"
              />
            )}
          />
        </div>
        <div style={{ width: "200px" }}>
          <Controller
            defaultValues={[]}
            name="delivery_status"
            control={control}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "delivery_status",
                })}
                placeholder={intl.formatMessage({
                  id: "delivery_status",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["letter_delivery_status"]}
              />
            )}
          />
        </div>
      </div>

      {recipient_id &&
      recipient_id?.length > 0 &&
      template_id &&
      template_id.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              width: "350px",
              justifyContent: "space-between",
              border: "1px solid #c7c7c7",
              borderRadius: "5px",
              padding: "8px 5px 5px 5px",
              marginTop: "10px",
              cursor: "pointer",
              alignItems: "center",
              marginBottom: "10px",
            }}
            onClick={async () => {
              await postLetterPreview.mutateAsync({
                recipient: recipient_id,
                template_id: template_id && template_id[0] && template_id[0].id,
              });
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div className="file_wraper_icon">
                <DocFileType />
                <p
                  className={`file_text_wraper --file_name_position ${"--doc_font_size"}`}
                  style={{ paddingTop: "5px", fontSize: "6px" }}
                >
                  {"pdf".toUpperCase()}
                </p>
              </div>
              <Typography
                $style={{
                  color: "#516beb",
                  lineHeight: "15px",
                  color: "#516beb",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  minWidth: "50px",
                  maxWidth: "205px",
                  textOverflow: "ellipsis",
                }}
              >
                {template_id && template_id[0] && template_id[0].name}
              </Typography>
            </div>
            <div>
              {postLetterPreview && postLetterPreview.isLoading ? (
                <>
                  <Spinner $size={SIZE.small} />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div style={{ marginBottom: "10px" }}>
        {letterAttachmentsData?.map((attachment, index) => {
          let { tempId } = attachment;
          return (
            <EmailAttachment
              key={tempId}
              {...attachment}
              id={customerId}
              currentOrganization={currentOrganization}
              entity={entity}
              removeAttchFile={removeAttchFile}
              updatingDeskUpload={updatingDeskUpload}
              subCategory={
                sub_category && sub_category[0] && sub_category[0].id
              }
            />
          );
        })}
      </div>

      <StatefulPopover
        placement={PLACEMENT.top}
        content={(fDProps) => (
          <div className="cp_email_attachement">
            <Typography
              $style={{}}
              onClick={() => {
                open();
                fDProps.close();
              }}
            >
              From Desktop
            </Typography>
            <StatefulPopover
              overrides={{
                Body: {
                  style: ({ $theme }) => ({
                    borderRadius: "5px",
                    overflow: "hidden",
                    width: "351px",
                    background: "#ffffff",
                  }),
                },
                Inner: {
                  style: ({ $theme }) => ({
                    backgroundColor: "#ffffff",
                  }),
                },
              }}
              placement={PLACEMENT.right}
              content={({ close }) => (
                <div style={{ padding: "10px" }}>
                  <TextBox
                    size="mini"
                    label={intl.formatMessage({
                      id: "search",
                    })}
                    placeholder={intl.formatMessage({
                      id: "search",
                    })}
                    value={isSearch}
                    onChange={(e) => {
                      setIsSearch(e.target.value);
                    }}
                  />
                  <div style={{ height: "200px", overflow: "scroll" }}>
                    {isDriveData.map((file, i) => {
                      let isSelected = selectedData.find((fl) => {
                        return fl.fileRef === file._id;
                      });
                      return (
                        <SelectDoc
                          res={file}
                          // deleteMethod={deleteDocumentData}
                          resourceId={customerId}
                          isSelected={isSelected ? true : false}
                          onFileSelectChange={onFileSelectChange}
                          entity={entity}
                          // need to change
                          // type={type}
                        />
                      );
                    })}
                  </div>
                  <div className="co-drawer-actions">
                    <TextButton
                      kind="tertiary"
                      size="mini"
                      type="button"
                      onClick={() => {
                        close();
                        setSelectedData([]);
                      }}
                    >
                      Cancel
                    </TextButton>
                    <TextButton
                      size="mini"
                      type="button"
                      onClick={() => {
                        dispatch(doucumentAttachment(selectedData));
                        close();
                        fDProps.close();
                        setSelectedData([]);
                      }}
                    >
                      Add
                    </TextButton>
                  </div>
                </div>
              )}
              returnFocus
              autoFocus
            >
              <Typography>Consumer Drive</Typography>
            </StatefulPopover>
          </div>
        )}
        returnFocus
        autoFocus
      >
        <div style={{ width: "200px", marginBottom: "10px" }}>
          <TextButton type="button" size="mini" kind={KIND.secondary}>
            Add Attachments
          </TextButton>
        </div>
      </StatefulPopover>

      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        is_hold={false}
        is_confirmation={false}
        is_calendar={false}
        is_followup={false}
        is_consent_enabled={false}
        is_inbound_call={true}
        is_status={false}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <div>
          <TextButton
            size={"mini"}
            type="button"
            kind={KIND.tertiary}
            onClick={() => closeDrawer()}
            disabled={executeActionMutation.isLoading}
          >
            Cancel
          </TextButton>
          <TextButton
            size={"mini"}
            disabled={executeActionMutation.isLoading}
            isLoading={executeActionMutation.isLoading}
          >
            Save
          </TextButton>
        </div>
      </div>
    </form>
  );
};

export default LegalLetterForm;
