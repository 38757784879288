import React, { useState, useEffect, useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import { IconButton, KIND, SIZE } from "../../../components/IconButton";
import { Label2, ParagraphSmall, LabelLarge } from "baseui/typography";
import Descending from "../../../assets/img/svg/Descending";
import AddPayment from "./AddPayment";
import PaymentEdit from "../../../assets/img/svg/PaymentEdit";
import { TextButton } from "../../../components/TextButton";
import { ModalContext } from "../../../providers/ModalProvider";
import {
  tabMotion,
  getActionData,
  isOpenModal,
  refetchActions,
} from "../../../redux/actions";
import WarningImage from "../../../assets/img/warning.png";

import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { useParams } from "react-router-dom";
import { COLUMNS } from "./colums";
// import Edit from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Edit.png";
import Delete from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import View from "../../../assets/img/svg/View";
import {
  editPaymentModal,
  emptyEditData,
  initialEditPaymentData,
  paymentAction,
  viewPaymentDetails,
  viewPaymentModal,
} from "../../../redux/customerOverview/payment/action";
import { getPaymentDatas } from "../../../services";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { deletePaymentDetailsCustomerOverview } from "../../../services/customerOverview";
import { getGridStructure } from "../../../services";
import columnMapper from "../../../utils/mapper";
import { useDispatch } from "react-redux";
import EditSvg from "../../../assets/img/svg/EditSvg";
import Edit from "../../../assets/img/svg/Template/Edit.js";
import EditeSvgN from "../../../assets/img/svg/EditeSvgN";
import PaymentView from "../../../assets/img/svg/PaymentView";
import { addPaymentModal } from "../../../redux/customerOverview/payment/action";

import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import TripleDot from "../../../assets/img/svg/TripleDot";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import {
  ADD_PAYMENTS,
  DELETE_PAYMENT,
  EDIT_PAYMENTS,
} from "../../../providers/RBACProvider/permissionList";
import SendEmailDrawer from "./SendEmailDrawer";
import warningImage from "../../../assets/img/warning.png";
import _ from "lodash";
// svg

let paymentInit = {
  id: "",
  isOpen: false,
};

const Payment = ({ getParams }) => {
  // const { id } = useParams();
  let {
    referenceData,
    currentOrganization,
    userInfo,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  const [columnData, setColumnData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [width, setWidth] = useState(0);
  const [isSendEmailOpen, setIsSendEmailOpen] = useState(paymentInit);
  const [isRefeatch, setIsRefeatch] = useState(0);

  let ref = React.useRef();

  const refetchingPayment = useSelector(
    (s) => s.customerPaymentReducer.refetchPaymentActions
  );

  const { handleModal } = useContext(ModalContext);

  // Disabling Payment Button

  let findingUser = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );
  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
      // dispatch(setTableWidth(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);
  // Table
  const dispatch = useDispatch();

  let reduxData = useSelector((state) => state.customerSummary.tableState);

  useEffect(() => {
    const getPayment = async () => {
      let data = await getPaymentDatas({
        paymentId: getParams,
        organization: currentOrganization,
      });
      setTableData(data.data.docs);
    };

    getPayment();
  }, [getParams, refetchingPayment, isRefeatch]);

  useEffect(() => {
    const userListApi = async () => {
      let userListVal = await getGridStructure({
        organization: currentOrganization,
        menu: "customer_payment",
      });
      setColumnData(userListVal.data.doc.table);
    };
    userListApi();
  }, []);

  const columns = useMemo(() => {
    let { columns } = columnMapper({
      columns: columnData,
      isExpanded: false,
      isExpandedHeader: false,
      isSelection: false,
      width: width,
      isEnableSort: true,
    });
    return columns;
  }, [columnData]);
  // const columns = useMemo(() => columnMapper());
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    state: { pageIndex, pageSize, sortBy, filters },
    prepareRow,
    setHiddenColumns,
    setColumnOrder,
    allColumns,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  const deletePaymentData = useMutation(
    (paymentData) =>
      deletePaymentDetailsCustomerOverview({
        ...paymentData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        setIsRefeatch(1 + isRefeatch);
      },
    }
  );

  return (
    <div>
      <>
        {/* {findingUser?.accounting_book === "maxyfi" ? ( */}
        <>
          {currentDefaultFormatDetails.is_add_payment ? (
            <>
              <div
                className="cp-payment__filter"
                style={{
                  gridColumn: "1/6",
                  gridRow: "1/2",
                  paddingTop: "20px",
                }}
              >
                <div
                  className="cp-payment-content"
                  style={{ paddingRight: "0px" }}
                >
                  <div className="cp-add-payment__btn">
                    <RBACWrapper role={ADD_PAYMENTS} type={"PERMISSION"}>
                      <TextButton
                        kind={KIND.primary}
                        size={SIZE.compact}
                        onClick={() => {
                          dispatch(
                            tabMotion(2),
                            dispatch(getActionData("payments")),
                            dispatch(isOpenModal(true)),
                            dispatch(emptyEditData()),
                            dispatch(paymentAction())
                          );

                          // ,dispatch(addPaymentModal)
                        }}
                        fullWidth
                      >
                        Add Payment
                      </TextButton>
                    </RBACWrapper>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
        {/* ) : (
          <></>
        )} */}
      </>

      <div className="customer-profile-tab__table" ref={ref}>
        <div className="cp-tab__table-wrapper" {...getTableProps()}>
          <div className="cp-tab__table-header">
            {headerGroups.map((headerGroup) => (
              <>
                <div
                  className="cp-tab__table-header-wrapper"
                  style={{
                    backgroundColor: "#F9F9F9",
                    border: "1px solid #CDCED9",
                    display: "flex",
                    flex: "1 0 auto",
                    minWidth: "0px",
                    // borderStartEndRadius: "5px",
                  }}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  <div className="cp-tab__table-header-content">
                    {headerGroup.headers.map((column, index) => (
                      <div
                        className="cp-tab__table-header-cell-content"
                        style={{
                          boxSizing: "border-box",
                          flex: "150 0 auto",
                          minWidth: "0px",
                          width: "150px",
                          position: "relative",
                        }}
                      >
                        <div
                          className="cp-tab__table-header-cell"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ))}
          </div>
          {data && data.length === 0 ? (
            <p
              style={{
                paddingTop: "10px",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              No Records Found
            </p>
          ) : (
            <>
              <div className="payment__table" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();
                  return (
                    <React.Fragment key={rowProps.key}>
                      <div
                        className="cp-tab__table-body-content"
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <div
                              className="cp-tab__table-body-cell"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </div>
                          );
                        })}

                        <>
                          <div className="action-button">
                            <div style={{ marginRight: "", width: "40px" }}>
                              <StatefulPopover
                                content={({ close }) => {
                                  return (
                                    <div>
                                      {row.original.source === "maxyfi" ? (
                                        <>
                                          <RBACWrapper
                                            role={EDIT_PAYMENTS}
                                            type={"PERMISSION"}
                                          >
                                            <ParagraphSmall
                                              $style={{
                                                cursor: "pointer",
                                                padding: "5px 30px",
                                                textAlign: "center",
                                                borderBottom:
                                                  "0.5px solid #CDCED9",
                                              }}
                                              onClick={() => {
                                                dispatch(
                                                  viewPaymentDetails(false)
                                                );
                                                dispatch(tabMotion(2));
                                                dispatch(
                                                  getActionData("payments")
                                                );
                                                dispatch(
                                                  initialEditPaymentData(
                                                    row.original
                                                  )
                                                );
                                                dispatch(isOpenModal(true));
                                              }}
                                            >
                                              Edit
                                            </ParagraphSmall>
                                          </RBACWrapper>

                                          <ParagraphSmall
                                            $style={{
                                              cursor: "pointer",
                                              padding: "5px 30px",
                                              textAlign: "center",
                                              borderBottom:
                                                "0.5px solid #CDCED9",
                                            }}
                                            onClick={() => {
                                              dispatch(
                                                tabMotion(2),
                                                dispatch(
                                                  getActionData("payments")
                                                ),
                                                dispatch(isOpenModal(true)),
                                                dispatch(
                                                  initialEditPaymentData(
                                                    row.original
                                                  )
                                                ),
                                                dispatch(
                                                  viewPaymentDetails(true)
                                                )
                                              );
                                              //
                                            }}
                                          >
                                            Preview
                                          </ParagraphSmall>
                                          {row.original.status !==
                                            "success" && (
                                            <RBACWrapper
                                              role={DELETE_PAYMENT}
                                              type={"PERMISSION"}
                                            >
                                              <ParagraphSmall
                                                $style={{
                                                  cursor: "pointer",
                                                  padding: "5px 30px",
                                                  textAlign: "center",
                                                  borderBottom:
                                                    "0.5px solid #CDCED9",
                                                }}
                                                onClick={() => {
                                                  handleModal({
                                                    title:
                                                      "You are about to delete a Payment",
                                                    content:
                                                      "This will delete your Payment. Are you sure?",
                                                    successCallback: () => {
                                                      deletePaymentData.mutate({
                                                        customerId: getParams,
                                                        contactId: _.get(
                                                          row,
                                                          "original._id",
                                                          ""
                                                        ),
                                                      });
                                                    },
                                                    formContent: ({
                                                      handleModal,
                                                    }) => {
                                                      return <></>;
                                                    },
                                                    cancelCallback: () => {},
                                                    isChildren: false,
                                                    image: warningImage,
                                                    buttonText: "Delete",
                                                    closeButtonText: "Cancel",
                                                    isCloseAble: true,
                                                  });
                                                }}
                                              >
                                                Delete
                                              </ParagraphSmall>
                                            </RBACWrapper>
                                          )}
                                        </>
                                      ) : row.original.source === "UPLOAD" ? (
                                        <>
                                          {row.original.status !==
                                            "success" && (
                                            <RBACWrapper
                                              role={DELETE_PAYMENT}
                                              type={"PERMISSION"}
                                            >
                                              <ParagraphSmall
                                                $style={{
                                                  cursor: "pointer",
                                                  padding: "5px 30px",
                                                  textAlign: "center",
                                                  borderBottom:
                                                    "0.5px solid #CDCED9",
                                                }}
                                                onClick={() => {
                                                  handleModal({
                                                    title:
                                                      "You are about to delete a Payment",
                                                    content:
                                                      "This will delete your Payment. Are you sure?",
                                                    successCallback: () => {
                                                      deletePaymentData.mutate({
                                                        customerId: getParams,
                                                        contactId: _.get(
                                                          row,
                                                          "original._id",
                                                          ""
                                                        ),
                                                      });
                                                    },
                                                    formContent: ({
                                                      handleModal,
                                                    }) => {
                                                      return <></>;
                                                    },
                                                    cancelCallback: () => {},
                                                    isChildren: false,
                                                    image: warningImage,
                                                    buttonText: "Delete",
                                                    closeButtonText: "Cancel",
                                                    isCloseAble: true,
                                                  });
                                                }}
                                              >
                                                Delete
                                              </ParagraphSmall>
                                            </RBACWrapper>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      <ParagraphSmall
                                        $style={{
                                          cursor: "pointer",
                                          padding: "5px 30px",
                                          textAlign: "center",
                                          borderBottom: "0.5px solid #CDCED9",
                                        }}
                                        onClick={() => {
                                          setIsSendEmailOpen({
                                            id: row?.original?._id,
                                            isOpen: true,
                                          });
                                          close();
                                        }}
                                      >
                                        Send Email
                                      </ParagraphSmall>

                                      {/* delete payment is removed 18-10-23 */}
                                    </div>
                                  );
                                }}
                                accessibilityType={"tooltip"}
                                triggerType={TRIGGER_TYPE.hover}
                                overrides={{
                                  Inner: {
                                    style: () => ({
                                      backgroundColor: "#FFFFFF",
                                      borderRadius: "5px",
                                      background: "#ffffff",
                                      border: "0.5px solid #CDCED9",
                                    }),
                                  },
                                  Body: {
                                    style: () => ({
                                      backgroundColor: "#FFFFFF",
                                      borderRadius: "5px",
                                      top: "-10px",
                                    }),
                                  },
                                  Arrow: {
                                    style: ({ $theme }) => ({}),
                                  },
                                }}
                              >
                                <div className="payment-popup_btn">
                                  <TripleDot />
                                </div>
                              </StatefulPopover>
                            </div>
                          </div>
                        </>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          )}
          <SendEmailDrawer
            isSendEmailOpen={isSendEmailOpen}
            setIsSendEmailOpen={setIsSendEmailOpen}
            getParams={getParams}
          />
        </div>
      </div>
    </div>
  );
};

export default Payment;
