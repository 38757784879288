import DownArrow from "../../assets/img/svg/DownArrow";
import DocFileType from "../../assets/img/svg/DocFileType";
import Edit from "../../assets/img/svg/Template/Edit";
import { LabelMedium, LabelSmall, ParagraphMedium } from "baseui/typography";
import moment from "moment";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import React, { useContext, useState } from "react";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";
import { IconButton } from "../IconButton";
import DocumentCard from "../DocumentCard/DocumentCard";
import { useParams, useSearchParams } from "react-router-dom";
import { Typography } from "../../components_v2";
import useFormat from "../../hooks/useFormat";
import { currencyList } from "../../utils_v2/CurrencyToSymbol";
import QuillFrame from "../QuillIframe/QuillIframe";

const statusColor = {
  CLOSED: "#0FB158",
  SUBMITTED: "#7D7C7C",
  IN_PROGRESS: "#FBBD04",
  OPEN: "#FD372A",
};

const RequestInformationHistory = ({
  status,
  created_by,
  assigned_to,
  assign_type,
  attachments = [],
  ...props
}) => {
  let {
    referenceData,
    users,
    currentOrganization,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const params = useParams();
  let customerId = "";
  const [isViewMore, setIsViewMore] = useState(false);
  if (params.customerId) {
    customerId = params.customerId;
  } else {
    customerId = getSearchParams.get("customers");
  }
  function isHTMLString(str) {
    // Regular expression to check if the string contains HTML tags
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;

    return htmlRegex.test(str);
  }

  const raisedBy = users.find((e) => e && e.id === created_by);
  const assignedTo =
    assign_type === "BUSINESS_UNIT" && referenceData["business_unit_list"]
      ? referenceData["business_unit_list"].find((e) => e.id === assigned_to)
      : users
          .map((e) => ({ id: e.id, label: e.displayName }))
          .find((e) => e && e.id === assigned_to);

  return (
    <div style={{ padding: "10px 10px", borderTop: "1px solid #cec3ce" }}>
      <div style={{ paddingBottom: "10px" }}>
        {attachments.map((e) => {
          const file = e.name ? e.name.split(".") : [];

          return (
            <DocumentCard
              res={{
                file_name: e.name,
                mime_type: file && file[1] ? file[1] : "",
                _id: e.ref,
                customerId: customerId,
                tab: "timeline",
              }}
              resourceId={customerId}
            />
          );
        })}
      </div>

      {isHTMLString(_.get(props, "comment", "")) ? (
        <div>
          {" "}
          {isViewMore ? (
            <QuillFrame
              content={_.get(props, "comment", "")}
              QuillHeight={300}
              scrollCustomize={true}
            />
          ) : (
            <div
              style={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 4,
                lineHeight: "1.5em",
                maxHeight: "5em", // 4 lines * lineHeight
              }}
              dangerouslySetInnerHTML={{
                __html: _.get(props, "comment", ""),
              }}
            />
          )}
          <span>
            <button
              onClick={() => {
                setIsViewMore(!isViewMore);
              }}
              style={{
                marginTop: "10px",
                background: "none",
                border: "none",
                color: "blue",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              {isViewMore ? "View Less" : "View More"}
            </button>
          </span>
        </div>
      ) : (
        <ParagraphMedium>
          {_.get(props, "comment", "").replace(/(<([^>]+)>)/gi, "")}
        </ParagraphMedium>
      )}

      {props.invoice_numbers && props.invoice_numbers.length > 0 ? (
        <div className="request_head_line">
          <ParagraphMedium $style={{ color: "#ADADAD" }}>
            Invoice # :
          </ParagraphMedium>
          <ParagraphMedium>
            {props.invoice_numbers && props.invoice_numbers[0]
              ? props.invoice_numbers[0]
              : ""}{" "}
          </ParagraphMedium>
          {props.invoice_numbers.length > 1 ? (
            <StatefulTooltip
              content={() => (
                <ParagraphMedium $style={{ color: "#333860" }}>
                  {props.invoice_numbers && Array.isArray(props.invoice_numbers)
                    ? props.invoice_numbers.toString()
                    : ""}
                </ParagraphMedium>
              )}
              returnFocus
              autoFocus
              overrides={{
                Inner: {
                  style: ({ $theme }) => ({
                    backgroundColor: "#fff",
                  }),
                },
              }}
            >
              <div className="count_inv">
                +{props.invoice_numbers.length - 1}
              </div>
            </StatefulTooltip>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="request_header">
        <div className="request_head_line">
          <ParagraphMedium $style={{ color: "#ADADAD" }}>
            Raised By:
          </ParagraphMedium>
          <ParagraphMedium>
            {raisedBy && raisedBy.displayName ? raisedBy.displayName : ""} on{" "}
            {moment
              .utc(props && props.created_at)
              .tz(currentDefaultFormatDetails.time_zone)
              .format(currentDefaultFormatDetails.date_format)}
          </ParagraphMedium>
        </div>
        <div className="request_head_line">
          <ParagraphMedium $style={{ color: "#ADADAD" }}>
            Status :
          </ParagraphMedium>
          <div
            className="request_status"
            style={{
              background: statusColor[status] ? statusColor[status] : "#FBBD04",
            }}
          ></div>
          <ParagraphMedium>
            {_.capitalize(_.replace(status, "_", " "))}
          </ParagraphMedium>
        </div>
      </div>
      <div className="request_header">
        <div className="request_head_line">
          <ParagraphMedium $style={{ color: "#ADADAD" }}>
            Assigned To :
          </ParagraphMedium>
          <ParagraphMedium>
            {assignedTo && assignedTo.label ? assignedTo.label : ""}
          </ParagraphMedium>
        </div>
        {props.review_date ? (
          <div className="request_head_line">
            <ParagraphMedium $style={{ color: "#ADADAD" }}>
              Review Date :
            </ParagraphMedium>

            <ParagraphMedium>
              {moment
                .utc(props.review_date)
                .tz(currentDefaultFormatDetails.time_zone)
                .format(currentDefaultFormatDetails.date_format)}
            </ParagraphMedium>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

function RequestInformationCard({
  setResource,
  status,
  setIsEdit,
  isEdits,
  assign_type,
  assigned_to,
  created_by,
  isList = true,
  children,
  ...props
}) {
  let {
    referenceData,
    users,
    currentOrganization,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  const format = useFormat();
  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const [isViewMore, setIsViewMore] = useState(false);
  function isHTMLString(str) {
    // Regular expression to check if the string contains HTML tags
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;

    return htmlRegex.test(str);
  }
  const raisedBy = users.find((e) => e && e.id === created_by);
  const assignedTo =
    assign_type === "BUSINESS_UNIT" && referenceData["business_unit_list"]
      ? referenceData["business_unit_list"].find((e) => e.id === assigned_to)
      : users
          .map((e) => ({ id: e.id, label: e.displayName }))
          .find((e) => e && e.id === assigned_to);

  return (
    <>
      <div className="request_information_card">
        <div className="request_information">
          <div className="request_header">
            <div className="request_head_line">
              <LabelMedium>
                {_.capitalize(_.get(props, "category", ""))}
                {" / "}
                {_.startCase(
                  _.toLower(
                    _.get(props, "sub_category", "").replaceAll("_", " ")
                  )
                )}
              </LabelMedium>
              <div className="request_dot_clarifi"></div>
              <ParagraphMedium $style={{ color: "#787878" }}>
                {_.get(props, "request_ref", "")}
              </ParagraphMedium>
            </div>
            {isList ? (
              <IconButton
                kind="tertiary"
                size="mini"
                onClick={() => {
                  setResource(props._id, props.request_ref, true);
                }}
              >
                <Edit width={15} height={15} fill="#ADADAD" />
              </IconButton>
            ) : (
              <></>
            )}
          </div>
          {isHTMLString(_.get(props, "details", "")) ? (
            <div>
              {" "}
              {isViewMore ? (
                <QuillFrame
                  content={_.get(props, "details", "")}
                  QuillHeight={300}
                  scrollCustomize={true}
                />
              ) : (
                <div
                  style={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 4,
                    lineHeight: "1.5em",
                    maxHeight: "5em", // 4 lines * lineHeight
                  }}
                  dangerouslySetInnerHTML={{
                    __html: _.get(props, "details", ""),
                  }}
                />
              )}
              <span>
                <button
                  onClick={() => {
                    setIsViewMore(!isViewMore);
                  }}
                  style={{
                    marginTop: "10px",
                    background: "none",
                    border: "none",
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {isViewMore ? "View Less" : "View More"}
                </button>
              </span>
            </div>
          ) : (
            <ParagraphMedium>{_.get(props, "details", "")}</ParagraphMedium>
          )}

          {props.invoice_numbers && props.invoice_numbers.length > 0 ? (
            <div className="request_head_line">
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                Invoice # :
              </ParagraphMedium>
              <ParagraphMedium>
                {props.invoice_numbers && props.invoice_numbers[0]
                  ? props.invoice_numbers[0]
                  : ""}{" "}
              </ParagraphMedium>
              {props.invoice_numbers.length > 1 ? (
                <StatefulTooltip
                  content={() => (
                    <ParagraphMedium $style={{ color: "#333860" }}>
                      {props.invoice_numbers &&
                      Array.isArray(props.invoice_numbers)
                        ? props.invoice_numbers.toString()
                        : ""}
                    </ParagraphMedium>
                  )}
                  returnFocus
                  autoFocus
                  overrides={{
                    Inner: {
                      style: ({ $theme }) => ({
                        backgroundColor: "#fff",
                      }),
                    },
                  }}
                >
                  <div className="count_inv">
                    +{props.invoice_numbers.length - 1}
                  </div>
                </StatefulTooltip>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          <div className="request_header">
            <div className="request_head_line">
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                Raised By:
              </ParagraphMedium>
              <ParagraphMedium>
                {raisedBy && raisedBy.displayName ? raisedBy.displayName : ""}{" "}
                on{" "}
                {moment
                  .utc(props && props.created_at)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .format(currentDefaultFormatDetails.date_format)}
              </ParagraphMedium>
            </div>
            <div className="request_head_line">
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                Status :
              </ParagraphMedium>
              <div
                className="request_status"
                style={{
                  background: statusColor[status]
                    ? statusColor[status]
                    : "#FBBD04",
                }}
              ></div>
              <ParagraphMedium>
                {_.capitalize(_.replace(status, "_", " "))}
              </ParagraphMedium>
            </div>
          </div>
          <div className="request_header">
            <div className="request_head_line">
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                Dispute Amount:
              </ParagraphMedium>
              <ParagraphMedium>
                {_.get(props, "amt", "")
                  ? `${
                      currencyList[currentDefaultFormatDetails.default_currency]
                    }${format.currency({ amount: _.get(props, "amt", null) })}`
                  : "-"}
              </ParagraphMedium>
            </div>
          </div>
          <div className="request_header">
            <div className="request_head_line">
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                Assigned To :
              </ParagraphMedium>
              <ParagraphMedium>
                {assignedTo && assignedTo.label ? assignedTo.label : ""}
              </ParagraphMedium>
            </div>
            {props.review_date ? (
              <div className="request_head_line">
                <ParagraphMedium $style={{ color: "#ADADAD" }}>
                  Review Date :
                </ParagraphMedium>

                <ParagraphMedium>
                  {moment
                    .utc(props.review_date)
                    .tz(currentDefaultFormatDetails.time_zone)
                    .format(currentDefaultFormatDetails.date_format)}
                </ParagraphMedium>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {children ? (
          <div style={{ padding: "0px 10px" }}>{children}</div>
        ) : (
          <></>
        )}
        {props.audits && Array.isArray(props.audits) ? (
          <>
            {props.audits
              .reverse()
              .slice(0, isList ? 1 : props.audits.length)
              .map((e) => {
                return (
                  <RequestInformationHistory
                    {...e.data}
                    created_by={e.audit_by}
                  />
                );
              })}
            {isList && props.audits.length > 1 ? (
              <div
                style={{
                  background: "#FAFAFA",
                  borderTop: "1px solid #CDCED9",
                  // borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2px 0px",
                  color: "#516beb",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setResource(props._id, props.request_ref);
                }}
              >
                View More (+{props.audits.length - 1})
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default RequestInformationCard;
