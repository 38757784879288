import React, { useContext, useEffect, useState } from "react";
import {
  Label1,
  Label2,
  Paragraph1,
  ParagraphLarge,
  ParagraphSmall,
} from "baseui/typography";
import { TextButton, SIZE } from "../TextButton";
import { IconButton, KIND } from "../IconButton";
import { Controller, useForm } from "react-hook-form";
import { SelectBox } from "../SelectBox";
import { useIntl } from "react-intl";
import * as ModalButton from "../TextButton";
import {
  getWorkflowlist,
  assignWorkflow,
  getWorkflow,
  getOrganization,
  getCustomerContactDetail,
  assignMulCusWfRel,
} from "../../services";
import getSelectValues from "../../utils/getSelectValues";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment-timezone";
import DateRangeSelect from "../TableFilters/DateRangeFilter/DateRangeSelect";
import { faSlash } from "@fortawesome/free-solid-svg-icons";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";

const AssignWorkflowForm = ({
  selectedCustomer,
  handleModal,
  toggleAllRowsSelected,
  customerRefetch,
  isAllRowsSelected,
  queryFilter,
}) => {
  const { id } = useParams();
  const intl = useIntl();
  const [workflowList, setWorkflowList] = useState([]);
  const [workflowActionList, setWorkflowActionList] = useState([]);
  const [smsConfigBtn, setSmsConfigBtn] = useState(false);

  let maxyfiContextValue = useContext(MaxyfiContext);

  const { tableCurrentState, gridStructure } = useSelector(
    (state) => state.customerSummary
  );

  let {
    currentOrganization,
    referenceData,
    refetch,
    appAccessRest,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;

  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      start_date: new Date(),
      is_all_customer: false,
    },
  });

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];

  const getParams = getSearchParams.get("customers");

  const assignWorkflowMutation = useMutation(
    (workflowData) =>
      assignMulCusWfRel({
        ...workflowData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }

          toast.error(errorData);
          toggleAllRowsSelected(false);
          customerRefetch();
          handleModal();
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);
        reset();
        handleModal();
        // dispatch(workflowAddModal(false));
        // dispatch(refetchActions());
        customerRefetch();
        refetch({
          ...maxyfiContextValue,
          setLoader: false,
          currentOrganization: currentOrganization,
        });
      },
    }
  );

  const { filters, globalFilters } = useSelector(
    (s) => s.customerSummary.tableCurrentState
  );

  const onSubmit = async (data) => {
    const orgRef = referenceData?.organizations?.find(
      ({ id }) => id === currentOrganization
    );

    let newFilter = dynamicQueryFilter({
      gridStructure: gridStructure,
      // customerQueryFilter: queryFilter && queryFilter.display_name,
      timeZone: currentDefaultFormatDetails.time_zone,
      customerFilter: filters,
    });

    const timeZone = orgRef?.time_zone;

    let newValues = {
      workflowId: getSelectValues(data.workflowId),
      start_workflow_from: getSelectValues(data.start_workflow_from),
      start_date: moment
        .tz(
          `${data.start_date.getFullYear()}-${
            data.start_date.getMonth() + 1
          }-${data.start_date.getDate()}`,
          "YYYY-MM-DD",
          timeZone
        )
        .utc()
        .valueOf(),
      ...(data.is_all_customer
        ? {
            query: newFilter ? newFilter : {},
            filters: {
              ...globalFilters,
              ...appAccessRest,
              needs_actions: [...NeedsActionFilter],
              stage: [...FilteredCustomerStage],
              search_value: queryFilter && queryFilter.display_name,
            },
          }
        : { customerId: selectedCustomer }),
      is_all_customer: data.is_all_customer,
    };

    await assignWorkflowMutation.mutateAsync(newValues);
  };

  let workflowId = watch("workflowId");

  const fetchWorkFlowActions = async (id) => {
    let workflows = await getWorkflow({
      organization: currentOrganization,
      organization_id: [currentOrganization],
      workflowId: id,
    });

    setWorkflowActionList(
      workflows && workflows.data && workflows.data.doc
        ? workflows.data.doc.customer_flow.map((e) => ({
            id: e.days_after,
            label: `Day ${e.days_after} - ${e.action_name}`,
          }))
        : []
    );

    if (
      workflows?.data?.doc?.action_types?.some(
        (e) => e.type === "sms" && e.count
      ) &&
      currentDefaultFormatDetails.is_sms_configured === false
    ) {
      setError("workflowId", {
        message:
          "Configure SMS-Gateway Settings to select SMS-based workflows.",
      });
      setSmsConfigBtn(true);
    }
    //  else if (
    //   workflows?.data?.doc?.action_types?.some(
    //     (e) => e.type === "call" && e.count
    //   ) &&
    //   data?.data?.docs?.some((e) => e.phone.some((e) => e.value == ""))
    // ) {
    //   setError("workflowId", {
    //     message: "Customer Contact doesn't have a phone number.",
    //   });
    //   setSmsConfigBtn(true);
    // } else if (
    //   data?.data?.docs?.some((e) => e.email.some((e) => e.value == ""))
    // ) {
    //   setError("workflowId", {
    //     message: "Customer Contact doesn't have Email ID.",
    //   });
    //   setSmsConfigBtn(true);
    // }
    else {
      clearErrors("workflowId");
      setSmsConfigBtn(false);
    }

    // setConfigSms(
    //   workflows && workflows.data && workflows.data.doc
    //     ? workflows.data.doc.action_types.map((e) => {
    //         return e.type;
    //       })
    //     : []
    // );
  };

  useEffect(() => {
    setWorkflowActionList([]);
    setValue("start_workflow_from", []);
    let id = getSelectValues(workflowId);
    // setValue("start_workflow_from", []);
    // if (!workflowId === false) {
    //   setSelectValues("start_workflow_from", workflowActionList[0]?.id);
    // }
    if (workflowId && id) {
      fetchWorkFlowActions(id);
    }
  }, [workflowId]);

  useEffect(() => {
    setValue("start_workflow_from", [{ id: workflowActionList[0]?.id }]);
  }, [workflowActionList]);

  // let action = workflowList.find((e) => e._id == id);
  // let customerFlow =
  //   action && action.customer_flow
  //     ? action.customer_flow.map((e) => ({
  //         id: e._id,
  //         label: `Day ${e.days_after} - ${e.action_name}`,
  //       }))
  //     : [];
  // setWorkflowActionList(customerFlow);

  // TODO: Default Starting Action
  // let defaultStartingAction = customerFlow[0] ? [customerFlow[0]] : [];
  // setValue("starting_stage", defaultStartingAction);

  const fetchWorkflow = async (filters = {}) => {
    let workflows = await getWorkflowlist({
      organization_id: [currentOrganization],
      organization: currentOrganization,
      filters: {
        exclude: "ALL",
        page_size: 0,
        sort: "name",
        is_disabled: false,
        ...filters,
      },
    });

    setWorkflowList(
      workflows && workflows.data && workflows.data.docs
        ? workflows.data.docs
        : []
    );
  };

  useEffect(() => {
    fetchWorkflow();
  }, []);

  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ParagraphLarge style={{ display: "flex", paddingBottom: "5px" }}>
          Select the workflow to be used
        </ParagraphLarge>
        <Controller
          name="workflowId"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              size={SIZE.compact}
              // disabled={isDisabled}
              requiredAstric={true}
              onInputChange={(evt) => {
                fetchWorkflow({ name: evt.target.value });
              }}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "workflow",
              })}
              placeholder={intl.formatMessage({
                id: "select_workflow",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={workflowList.map((e) => ({
                id: e._id,
                label: e.name,
              }))}
            />
          )}
        />

        <ParagraphLarge $style={{ paddingBottom: "10px", display: "flex" }}>
          Select starting stage in the workflow
        </ParagraphLarge>
        <Controller
          name="start_workflow_from"
          control={control}
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              size={SIZE.compact}
              // disabled={isDisabled}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "start_workflow_from",
              })}
              placeholder={intl.formatMessage({
                id: "start_workflow_from",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={workflowActionList}
            />
          )}
        />

        {/* <ParagraphLarge $style={{ marginBottom: "20px" }}>
              Select the starting stage in the workflow to be used
            </ParagraphLarge> */}
        <ParagraphLarge $style={{ paddingBottom: "10px", display: "flex" }}>
          Start Date
        </ParagraphLarge>
        <Controller
          name="start_date"
          control={control}
          render={({ field }) => (
            <DateRangeSelect
              size={SIZE.compact}
              {...field}
              name={field.name}
              // disabled={isDisabled}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "start_date",
              })}
              placeholder={intl.formatMessage({
                id: " ",
              })}
              value={field.value}
              onChange={(e) => field.onChange(e.date)}
              minDate={new Date()}
            />
          )}
        />

        {isAllRowsSelected && (
          <Controller
            name="is_all_customer"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall style={{ color: "#333860" }}>
                  Update all the matching consumer records
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        )}

        <div
          style={{
            // marginTop: "15px",
            textAlign: "center",
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
            // paddingBottom: "20px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={ModalButton.SIZE.compact}
              kind={KIND.tertiary}
              // onClick={close}
              onClick={() => {
                // dispatch(workflowAddModal(false));
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={ModalButton.SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
              disabled={isSubmitting || smsConfigBtn}
            >
              Apply
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default AssignWorkflowForm;
