import {
  Label1,
  LabelMedium,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { TextBox } from "../../../components/TextBox";
import { SIZE } from "baseui/textarea";
import { KIND, TextButton } from "../../../components/TextButton";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  customerPortalLogo,
  deleteCustomerLogo,
  postOrganizationData,
  uploadLogoOrganization,
} from "../../../services";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { IconButton } from "../../../components/IconButton";
import Delete from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import { ModalContext } from "../../../providers/ModalProvider";
import warningImage from "../../../assets/img/warning.png";
import CustomerPreview from "../../../components/CustomerPreview/CustomerPreview";
import UploadFile from "../../../assets/img/svg/UploadFile";
import UploadDrop from "../../../assets/img/svg/UploadDrop";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import {
  UPDATE_ORGANIZATION_SETTINGS,
  VIEW_ORGANIZATION_SETTINGS,
} from "../../../providers/RBACProvider/permissionList";
import { SelectBox } from "../../../components/SelectBox";
import { Typography } from "../../../components_v2";
import { TextArea } from "../../../components/TextArea";
import ReactQuill from "react-quill";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import insertTemplateString from "../../../utils/insertTemplateString";
import _ from "lodash";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
function CustomizeUI({ settingData, setIsRefetch, isTemplateData }) {
  const { currentOrganization, referenceData, users, userInfo } =
    useContext(MaxyfiContext);
  const { handleModal } = useContext(ModalContext);

  const editorRef = useRef(null);
  const conPhRef = useRef(null);
  const conEmRef = useRef(null);
  const workHrRef = useRef(null);
  const emSubRef = useRef(null);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      primary_color: "#516beb",
      header_color: "#08083A",
      prfv: [{ id: "SAV" }],
    },
  });

  useEffect(() => {
    if (settingData?.customer_portal_ui) {
      setValue("primary_color", settingData?.customer_portal_ui?.primary_color);
      setValue("header_color", settingData?.customer_portal_ui?.header_color);
      setValue("comi", settingData?.customer_portal_ui?.comi);
      setValue("welt", settingData?.customer_portal_ui?.welt);
      setValue(
        "prfv",
        [{ id: settingData?.customer_portal_ui?.prfv }] || [{ id: "SAV" }]
      );
      setValue("cl_conf", _.get(settingData, "customer_portal_ui.cl_conf", ""));
      setValue("em_conf", _.get(settingData, "customer_portal_ui.em_conf", ""));
      setValue("wh_conf", _.get(settingData, "customer_portal_ui.wh_conf", ""));
      setValue("em_sub", _.get(settingData, "customer_portal_ui.em_sub", ""));
      setValue("aut_enb", _.get(settingData, "customer_portal_ui.aut_enb", ""));
    }
  }, [settingData]);

  const color = watch(settingData);
  const preferredView = watch("prfv");
  const welcomeText = watch("welt");
  const complianceInfo = watch("comi");

  const sendUploadFile = useMutation(
    (formData) =>
      customerPortalLogo({
        formData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
          // setIsSubmitting(false);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        setIsRefetch();
      },
      // retry: 2,
    }
  );

  const deleteLogo = useMutation(
    (formData) =>
      deleteCustomerLogo({
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
          // setIsSubmitting(false);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        // setRefetch(isrefetch + 1);

        setIsRefetch();
      },
      // retry: 2,
    }
  );

  const filteredCustomerAllTemplate = useMemo(() => {
    if (_.get(isTemplateData, "data.docs", []).length > 0) {
      return _.get(isTemplateData, "data.docs", []).filter(
        (fi) => fi.module === "CUSTOMER" || "BUSINESS_UNIT"
      );
    } else {
      return [];
    }
  }, []);

  const customizeColor = useMutation(
    (values) =>
      postOrganizationData({
        values,
        organization: currentOrganization,
        DataType: "customer_portal_ui",
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
          // setIsSubmitting(false);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        // setRefetch(isrefetch + 1);

        setIsRefetch();
      },
      // retry: 2,
    }
  );

  const fileSize = 10000000;
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 1MB`,
      };
    }
  }

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      maxFiles: 1,
      accept: {
        "image/*": [".jpg", ".png"],
      },
      validator: uploadLogoValidation,
      //   onDrop: (acceptedFiles) => {
      //     setFiles(
      //       acceptedFiles.map((file) =>
      //         Object.assign(file, {
      //           preview: URL.createObjectURL(file),
      //         })
      //       )
      //     );
      //   },
    });

  async function uploadFile() {
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    if (acceptedFiles.length) {
      await sendUploadFile.mutateAsync(formData);
    }
  }

  useEffect(() => {
    if (acceptedFiles) {
      uploadFile();
    }
  }, [acceptedFiles]);

  // let Block = ReactQuill.Quill.import("blots/block");
  // Block.tagName = "DIV";
  // ReactQuill.Quill.register(Block, true);

  const quillModule = {
    toolbar: 0,
    clipboard: {
      matchVisual: false,
    },
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      blotName: "TemplateMarker",
      mentionDenotationChars: ["@"],
      dataAttributes: [
        "id",
        "value",
        "denotationChar",
        "link",
        "target",
        "disabled",
        "programmaticInsert",
      ],
      spaceAfterInsert: false,

      onSelect: (insert, setInsert) => {
        try {
          setInsert(insert);
        } catch (err) {}
        insertTemplateString(editorRef, insert);
      },

      source: (searchTerm, renderList, mentionChar) => {
        //TODO: DONT RENDER LIST OF VALUES WHEN CURSOR INSIDE TEMPLATE VARIABLE
        // const atValuesOne = useSelector(
        //   (s) => s.communicationTemplate.atData
        // );

        const renderListSize = 6;
        let valuesAt = filteredCustomerAllTemplate;

        let atValues = valuesAt
          ? valuesAt.map((i) => {
              return { value: i.label, id: i.id };
            })
          : [];

        let values;
        if (mentionChar === "@") {
          values = atValues;
        }

        if (searchTerm.length === 0) {
          renderList(values.slice(0, renderListSize), searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < values.length; i++)
            if (
              values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) >=
              0
            )
              matches.push(values[i]);

          renderList(matches.slice(0, renderListSize), searchTerm);
        }
      },
    },
  };

  const modules = useMemo(
    () => ({
      welcome_text: {
        ...quillModule,
        mention: {
          ...quillModule.mention,
          onSelect: (insert, setInsert) => {
            try {
              setInsert(insert);
            } catch (err) {}
            insertTemplateString(editorRef, insert);
          },
        },
      },
      contact_phone: {
        ...quillModule,
        mention: {
          ...quillModule.mention,
          onSelect: (insert, setInsert) => {
            try {
              setInsert(insert);
            } catch (err) {}
            insertTemplateString(conPhRef, insert);
          },
        },
      },

      contact_email: {
        ...quillModule,
        mention: {
          ...quillModule.mention,
          onSelect: (insert, setInsert) => {
            try {
              setInsert(insert);
            } catch (err) {}
            insertTemplateString(conEmRef, insert);
          },
        },
      },

      working_hours: {
        ...quillModule,
        mention: {
          ...quillModule.mention,
          onSelect: (insert, setInsert) => {
            try {
              setInsert(insert);
            } catch (err) {}
            insertTemplateString(workHrRef, insert);
          },
        },
      },

      email_subject: {
        ...quillModule,
        mention: {
          ...quillModule.mention,
          onSelect: (insert, setInsert) => {
            try {
              setInsert(insert);
            } catch (err) {}
            insertTemplateString(emSubRef, insert);
          },
        },
      },
    }),
    []
  );

  const onSubmit = async (data) => {
    let values = {
      primary_color:
        data && data?.primary_color ? data?.primary_color : "#516beb",
      header_color: data && data?.header_color ? data?.header_color : "#08083A",
      prfv: data && data?.prfv && data.prfv[0] && data?.prfv[0]?.id,
      welt: data.welt,
      comi: data.comi,
      em_conf: _.get(data, "em_conf", ""),
      wh_conf: _.get(data, "wh_conf", ""),
      cl_conf: _.get(data, "cl_conf", ""),
      aut_enb: data?.aut_enb || false,
      em_sub: data?.em_sub,
    };
    await customizeColor.mutateAsync({ ...values });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          // height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={
            {
              // height: "calc(100% - 90px)",
            }
          }
        >
          <div className="customize-mobile">
            <div
              style={{
                width: "40%",
                padding: "25px",
                height: "calc(100% - 258px)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <Typography
                  type="p"
                  style={{
                    color: "#787878",
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    paddingBottom: "5px",
                  }}
                >
                  Choose Your Organization logo
                </Typography>

                {/* <ParagraphMedium>Choose Your Organization logo</ParagraphMedium> */}
                {settingData &&
                settingData.customer_portal_ui &&
                settingData.customer_portal_ui.logo &&
                settingData.customer_portal_ui.logo?.length > 0 ? (
                  <>
                    <IconButton
                      {...getRootProps()}
                      type="button"
                      kind={KIND.tertiary}
                    >
                      <i className="mx-icon-upload" />
                    </IconButton>
                    <div>
                      <IconButton
                        type="button"
                        kind={KIND.tertiary}
                        onClick={() => {
                          handleModal({
                            title: "You are about to delete the logo",
                            content:
                              "This will remove the uploaded logo. Are you sure?",
                            successCallback: () => {
                              deleteLogo.mutate();
                            },
                            formContent: () => {
                              return <></>;
                            },
                            cancelCallback: () => {},
                            image: warningImage,
                            buttonText: "Delete",
                            closeButtonText: "Cancel",
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              {settingData &&
              settingData?.customer_portal_ui &&
              settingData?.customer_portal_ui?.logo ? (
                <div
                  style={{
                    aspectRatio: "3/2",
                    objectFit: "contain",
                    width: "40%",
                  }}
                >
                  <img
                    src={settingData?.customer_portal_ui?.logo}
                    style={{ width: "100%" }}
                  />
                </div>
              ) : (
                <section className="container">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div>
                      <UploadFile />
                      {/* <UploadDrop /> */}
                    </div>
                    <ParagraphSmall>
                      <span style={{ fontSize: "13px", fontWeight: "700" }}>
                        Click to upload{" "}
                      </span>
                      a organization logo or drag and drop
                    </ParagraphSmall>
                  </div>
                </section>
              )}
              <Typography
                type="p"
                style={{
                  color: "#787878",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "16px",
                  paddingBottom: "5px",
                }}
              >
                Choose a Header Color
              </Typography>

              {/* <ParagraphMedium>Choose a Header Color</ParagraphMedium> */}
              <Controller
                name="header_color"
                control={control}
                rules={{
                  max: {
                    value: 100,
                    message: "Enter percentage between 0 to 100",
                  },
                }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    type="color"
                    name={field.name}
                    size={SIZE.mini}
                    // label={intl.formatMessage({
                    //   id: "header_color",
                    // })}
                    // placeholder={intl.formatMessage({
                    //   id: "header_color",
                    // })}
                    value={field.value}
                  />
                )}
              />
              <Typography
                type="p"
                style={{
                  color: "#787878",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "16px",
                  paddingBottom: "5px",
                }}
              >
                Choose a Primary Color
              </Typography>

              {/* <ParagraphMedium>Choose a Primary Color</ParagraphMedium> */}

              <Controller
                name="primary_color"
                control={control}
                rules={{
                  max: {
                    value: 100,
                    message: "Enter percentage between 0 to 100",
                  },
                }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    type="color"
                    name={field.name}
                    size={SIZE.mini}
                    // label={intl.formatMessage({
                    //   id: "header_color",
                    // })}
                    // placeholder={intl.formatMessage({
                    //   id: "header_color",
                    // })}
                    value={field.value}
                    color={true}
                  />
                )}
              />
              <div>
                <Typography
                  type="p"
                  style={{
                    color: "#787878",
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    paddingBottom: "5px",
                  }}
                >
                  Preferred View <span style={{ color: "red" }}>*</span>
                </Typography>

                <Controller
                  name="prfv"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      clearable={false}
                      backspaceRemoves={false}
                      name={field.name}
                      size={SIZE.mini}
                      value={field.value}
                      error={errors[field.name] && errors[field.name].message}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={
                        referenceData &&
                        referenceData["cus_portal_preferred_view"]
                          ? referenceData["cus_portal_preferred_view"]
                          : []
                      }
                    />
                  )}
                />
              </div>
              <div style={{ marginBottom: "10px" }}>
                <Typography
                  type="p"
                  style={{
                    color: "#787878",
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    paddingBottom: "5px",
                  }}
                >
                  Welcome Text
                </Typography>
                <Controller
                  name="welt"
                  control={control}
                  render={({ field }) => (
                    <ReactQuill
                      {...field}
                      name={field.name}
                      ref={editorRef}
                      placeholder="This is a Welcome Text {{first_name}} and {{second_name}}"
                      id={"portal-welcome-text-editor"}
                      theme="snow"
                      value={field.value}
                      modules={{ ...modules.welcome_text, toolbar: 0 }}
                      style={{ height: "80px" }}
                    />
                  )}
                />
              </div>
              <div>
                <Typography
                  type="p"
                  style={{
                    color: "#787878",
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    paddingBottom: "5px",
                  }}
                >
                  Compliance Information
                </Typography>
                <Controller
                  name="comi"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      name={field.name}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  )}
                />
              </div>
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Controller
                  name="aut_enb"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <CheckBoxBaseweb {...field} checked={field.value}>
                      <Typography
                        type="p"
                        style={{
                          marginTop: "4px",
                          color: "#787878",
                          fontSize: "13px",
                          fontWeight: "400",
                          lineHeight: "16px",
                          paddingBottom: "2px",
                        }}
                      >
                        Portal Authentication
                      </Typography>
                    </CheckBoxBaseweb>
                  )}
                />
              </div>
              <div>
                <Typography
                  type="p"
                  style={{
                    color: "#787878",
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    paddingBottom: "5px",
                  }}
                >
                  Contact Phone
                </Typography>

                <Controller
                  name="cl_conf"
                  control={control}
                  render={({ field }) => (
                    <ReactQuill
                      {...field}
                      name={field.name}
                      ref={conPhRef}
                      id={"contact-phone-quill"}
                      theme="snow"
                      value={field.value}
                      modules={{
                        ...modules.contact_phone,
                        toolbar: 0,
                      }}
                      style={{ height: "50px" }}
                    />
                  )}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <Typography
                  type="p"
                  style={{
                    color: "#787878",
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    paddingBottom: "5px",
                  }}
                >
                  Contact Email
                </Typography>
                <Controller
                  name="em_conf"
                  control={control}
                  render={({ field }) => (
                    <ReactQuill
                      {...field}
                      name={field.name}
                      ref={conEmRef}
                      id={"contact-email-quill"}
                      theme="snow"
                      value={field.value}
                      modules={{
                        ...modules.contact_email,
                        toolbar: 0,
                      }}
                      style={{ height: "50px" }}
                    />
                  )}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <Typography
                  type="p"
                  style={{
                    color: "#787878",
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    paddingBottom: "5px",
                  }}
                >
                  Email Subject
                </Typography>
                <Controller
                  name="em_sub"
                  control={control}
                  render={({ field }) => (
                    <ReactQuill
                      {...field}
                      name={field.name}
                      ref={emSubRef}
                      id={"contact-email-quill"}
                      theme="snow"
                      value={field.value}
                      modules={{
                        ...modules.email_subject,
                        toolbar: 0,
                      }}
                      style={{ height: "50px" }}
                    />
                  )}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <Typography
                  type="p"
                  style={{
                    color: "#787878",
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    paddingBottom: "5px",
                  }}
                >
                  Working hours
                </Typography>

                <Controller
                  name="wh_conf"
                  control={control}
                  render={({ field }) => (
                    <ReactQuill
                      {...field}
                      name={field.name}
                      ref={workHrRef}
                      id={"working-hours-quill"}
                      theme="snow"
                      value={field.value}
                      modules={{
                        ...modules.working_hours,
                        toolbar: 0,
                      }}
                      style={{ height: "50px" }}
                    />
                  )}
                />
              </div>
              <div style={{ display: "flex", gap: "5px", marginTop: "10px" }}>
                <div
                  style={{
                    textAlign: "start",
                    // margin: "5px 20px 20px",
                    width: "130px",
                  }}
                >
                  <TextButton
                    fullWidth
                    size={SIZE.compact}
                    kind={KIND.tertiary}
                    type="button"
                    onClick={() => {
                      if (settingData?.customer_portal_ui) {
                        setValue(
                          "primary_color",
                          settingData?.customer_portal_ui?.primary_color
                        );
                        setValue(
                          "header_color",
                          settingData?.customer_portal_ui?.header_color
                        );
                        setValue("comi", settingData?.customer_portal_ui?.comi);
                        setValue("welt", settingData?.customer_portal_ui?.welt);
                        setValue(
                          "prfv",
                          [{ id: settingData?.customer_portal_ui?.prfv }] || [
                            { id: "SAV" },
                          ]
                        );
                      }
                    }}
                  >
                    Cancel
                  </TextButton>
                </div>
                <RBACWrapper
                  role={UPDATE_ORGANIZATION_SETTINGS}
                  type="PERMISSION"
                >
                  <div
                    style={{
                      textAlign: "start",
                      // margin: "5px 20px 20px",
                      width: "130px",
                    }}
                  >
                    <TextButton
                      fullWidth
                      size={SIZE.compact}
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Publish
                    </TextButton>
                  </div>
                </RBACWrapper>
              </div>
            </div>
            <div
              style={{
                flex: "1",
                height: "850px",
              }}
            >
              <CustomerPreview
                color={color}
                preferredView={preferredView}
                welcomeText={welcomeText}
                complianceInfo={complianceInfo}
                logo={settingData?.customer_portal_ui?.logo}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default CustomizeUI;
