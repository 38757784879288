import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import queryClient from "../../../../providers/queryClient";
import { CO_INVOICES_, EDIT } from "../../../../constants";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import {
  ADD_LIST_VALUES,
  ADD_SUB_MAIN_STATUS_MAINTENANCE,
  EDIT_SUB_MAIN_STATUS_MAINTENANCE,
} from "../../../../providers/RBACProvider/permissionList";
import { useMutation, useQuery } from "react-query";
import {
  getInvoiceStatusAuditOnChange,
  udpateEditInvoiceStatusAudit,
  udpateInvoiceStatusAudit,
} from "../../../../services";
import getSelectValues from "../../../../utils/getSelectValues";
import { SIZE, TextArea } from "../../../../components/TextArea";
import { SelectBox } from "../../../../components/SelectBox";
import { Icon, Typography } from "../../../../components_v2";
import { TextButton } from "../../../../components/TextButton";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import setSelectValues from "../../../../utils/setSelectValues";
import { useDispatch, useSelector } from "react-redux";
import { resetInvoiceStatus } from "../../../../redux/InvoiceStatusMaintenance/action";

const SubStatusForm = (props) => {
  const dispach = useDispatch();
  const { invoiceId, type, isTimeLineId, customerId, setIsFormOpen } = props;

  let { invoice_audit_edit_data } = useSelector(
    (e) => e.invoiceStatusMaintenanceReducer
  );

  const intl = useIntl();
  let colors = {
    CUSTOMER: "#FF9A76",
    INVOICE: "#646FD4",
    ORGANIZATION: "#679B9B",
  };
  let { referenceData, currentOrganization, space } = useContext(MaxyfiContext);

  const transformArrayToObjects = (arr) => {
    return arr.map((item) => ({ id: item, label: item }));
  };
  const { refetchActions } = useSelector((s) => s.customerOverviewReducer);
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      i_sts: [],
      i_s_sts: [],
      i_ctr: [],
      i_sdt: "",
      ofb: false,
      updateAll: false,
    },
  });

  useEffect(() => {
    if (invoice_audit_edit_data?.name) {
      setValue(
        "i_sts",
        setSelectValues(_.get(invoice_audit_edit_data, "name", ""))
      );
      setValue(
        "i_s_sts",
        setSelectValues(_.get(invoice_audit_edit_data, "content.s_sts", ""))
      );
      setValue(
        "i_ctr",
        setSelectValues(_.get(invoice_audit_edit_data, "content.ctr", ""))
      );
      setValue("i_sdt", _.get(invoice_audit_edit_data, "comment", ""));
      setValue("ofb", _.get(invoice_audit_edit_data, "content.ofb", false));
      setValue(
        "updateAll",
        _.get(invoice_audit_edit_data, "content.u_all", false)
      );
    }
    return () => {
      dispach(resetInvoiceStatus());
    };
  }, [invoice_audit_edit_data]);

  const i_sts = watch("i_sts");
  const i_s_sts = watch("i_s_sts");
  const i_ctr = watch("i_ctr");
  const fetchInvoiceStatusAudit = async () => {
    if (!i_sts?.length) return null; // Prevent API call if i_sts is empty

    const response = await getInvoiceStatusAuditOnChange({
      invoiceId,
      organization: currentOrganization,
      i_sts: getSelectValues(i_sts, false),
      ...(i_s_sts?.length ? { i_s_sts: getSelectValues(i_s_sts, false) } : {}),
      ...(i_ctr?.length ? { i_ctr: getSelectValues(i_ctr, false) } : {}),
      customerId: customerId,
    });

    return response.data;
  };

  const {
    data: invoiceAuditOption,
    isLoading: invoiceAuditOptionLoading,
    error: invoiceAuditStatusError,
  } = useQuery({
    queryKey: [i_sts, i_s_sts, i_ctr],
    queryFn: fetchInvoiceStatusAudit,
    enabled: !!i_sts?.length, // API is only triggered when i_sts has a value
  });

  const addServiceAuditInvoiceStaMutation = useMutation(
    (formData) =>
      udpateInvoiceStatusAudit(invoiceId, currentOrganization, formData),
    {
      onError: (error, variables, context) => {
        console.groupEnd(error, "Error Messsage");
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(
          `SINGLE_INVOICE_AUDIT_STATUS_${currentOrganization}_${invoiceId}`
        );
        queryClient.refetchQueries(`${CO_INVOICES_}${customerId}`);
        setIsFormOpen(false);
        reset();
      },
    }
  );

  const editServiceAuditInvoiceStaMutation = useMutation(
    (formData) =>
      udpateEditInvoiceStatusAudit(
        _.get(invoice_audit_edit_data, "_id", ""),
        invoiceId,
        currentOrganization,
        formData
      ),
    {
      onError: (error, variables, context) => {},
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(
          `SINGLE_INVOICE_AUDIT_STATUS_${currentOrganization}_${invoiceId}`
        );

        queryClient.refetchQueries(
          `CUSTOMER_INVOICE_OVERVIEW-${customerId}-${refetchActions}`
        );
        setIsFormOpen(false);
        reset();
      },
    }
  );

  const onSubmit = async (data) => {
    let values = {
      ...data,
      i_ctr: getSelectValues(i_ctr, false),
      i_s_sts: getSelectValues(i_s_sts, false),
      i_sts: getSelectValues(i_sts),
    };
    let editValues = {
      i_ofb: _.get(data, "ofb", false),
      i_u_all: _.get(data, "updateAll", false),
      i_ctr: getSelectValues(i_ctr, false),
      i_s_sts: getSelectValues(i_s_sts, false),
      i_sts: getSelectValues(i_sts),
      i_sdt: _.get(data, "i_sdt", ""),
    };
    if (type === EDIT) {
      await editServiceAuditInvoiceStaMutation.mutateAsync(editValues);
    } else {
      await addServiceAuditInvoiceStaMutation.mutateAsync(values);
    }
  };

  useEffect(() => {
    if (
      _.get(invoiceAuditOption, "doc.description", "") &&
      _.get(invoiceAuditOption, "doc.is_description_available", false) &&
      props?.state?.isPreFill
    ) {
      setValue("i_sdt", _.get(invoiceAuditOption, "doc.description", ""));
    }
  }, [
    watch("i_sts"),
    watch("i_s_sts"),
    watch("i_ctr"),
    invoiceAuditOption &&
      invoiceAuditOption?.doc &&
      invoiceAuditOption?.doc?.is_description_available,
  ]);

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);
  // Define state and refs

  // useEffect(() => {
  //   if (
  //     _.get(invoice_audit_edit_data, "name", "") !== getSelectValues(i_sts) &&
  //     getSelectValues(i_sts) !== undefined
  //   ) {
  //     setValue("i_s_sts", []);
  //     setValue("i_ctr", []);
  //   }
  // }, [invoice_audit_edit_data?._id]); // Ensure correct dependencies

  // useEffect(() => {
  //   if (
  //     _.get(invoice_audit_edit_data, "content.s_sts", "") !==
  //       getSelectValues(i_s_sts) &&
  //     getSelectValues(i_s_sts) !== undefined
  //   ) {
  //     setValue("i_ctr", []);
  //   }
  // }, [invoice_audit_edit_data?._id]); // Fixed duplicate dependency

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="sub-status-form-Header" style={{ marginTop: "10px" }}>
        <Typography type="h4" className="regular">
          {type === "ADD" || type === "LIST" ? "Add Status" : "Edit Status"}
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          marginTop: "10px",
          // width: "400px",
        }}
      >
        <div style={{ width: "200px" }}>
          <Controller
            name="i_sts"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                requiredAstric={true}
                {...field}
                clearable={false}
                // creatable={creatablePermission ? true : false}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "status",
                })}
                placeholder={intl.formatMessage({
                  id: "status",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                  props.setState({ ...props.state, isPreFill: true });
                  setValue("i_s_sts", []);
                  setValue("i_ctr", []);
                  setValue("i_sdt", "");
                }}
                options={referenceData["inv_status_list"] || []}
              />
            )}
          />
        </div>
        <div style={{ width: "200px" }}>
          <Controller
            name="i_s_sts"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                clearable={false}
                creatable={creatablePermission ? true : false}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "sub_status",
                })}
                disabled={
                  watch("i_sts").length === 0
                    ? true
                    : _.get(invoiceAuditOption, "doc.sub_status", []).length > 0
                    ? false
                    : true
                }
                placeholder={intl.formatMessage({
                  id: "sub_status",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                  props.setState({ ...props.state, isPreFill: true });
                  setValue("i_ctr", []);
                  setValue("i_sdt", "");
                }}
                options={
                  _.get(invoiceAuditOption, "doc.sub_status", [])
                    ? transformArrayToObjects(
                        _.get(invoiceAuditOption, "doc.sub_status", [])
                      )
                    : referenceData["inv_sub_status_list"]
                }
              />
            )}
          />
        </div>
        <div style={{ width: "200px" }}>
          <Controller
            name="i_ctr"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                clearable={false}
                {...field}
                creatable={creatablePermission ? true : false}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "category",
                })}
                placeholder={intl.formatMessage({
                  id: "category",
                })}
                disabled={
                  watch("i_s_sts").length === 0
                    ? true
                    : _.get(invoiceAuditOption, "doc.category", []).length > 0
                    ? false
                    : true
                }
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                  props.setState({ ...props.state, isPreFill: true });
                }}
                options={
                  _.get(invoiceAuditOption, "doc.category", [])
                    ? transformArrayToObjects(
                        _.get(invoiceAuditOption, "doc.category", [])
                      )
                    : referenceData["inv_status_category_list"]
                }
              />
            )}
          />
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <Controller
          name="i_sdt"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <>
              <TextArea
                {...field}
                name={field.name}
                // label={intl.formatMessage({
                //   id: "description",
                // })}
                error={_.get(errors, "i_sdt.message", "")}
                placeholder={intl.formatMessage({
                  id: "description",
                })}
                size={SIZE.mini}
                disabled={
                  watch("i_sts").length === 0
                    ? true
                    : _.get(
                        invoiceAuditOption,
                        "doc.is_description_edit",
                        false
                      )
                    ? false
                    : true
                }
                value={field.value}
              />
            </>
          )}
        />
      </div>

      <Controller
        name="ofb"
        defaultValue={false}
        control={control}
        render={({ field }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <CheckBoxBaseweb
              {...field}
              checked={field.value}
              // disabled={pageType == VIEW ? true : false}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <Typography type="p" className="regular" subType="regular">
                Out of Business
              </Typography>
            </CheckBoxBaseweb>
          </div>
        )}
      />
      <Controller
        name="updateAll"
        defaultValue={false}
        control={control}
        render={({ field }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <CheckBoxBaseweb
              {...field}
              checked={field.value}
              // disabled={pageType == VIEW ? true : false}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <Typography type="p" className="regular" subType="regular">
                Update Status Across all Records
              </Typography>
            </CheckBoxBaseweb>
          </div>
        )}
      />

      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          disabled={
            addServiceAuditInvoiceStaMutation.isLoading ||
            editServiceAuditInvoiceStaMutation?.isLoading
          }
          onClick={() => {
            props?.setState({ ...props?.state, type: "LIST" });
            setIsFormOpen(false);
            // onCancel();
          }}
        >
          Cancel
        </TextButton>

        <TextButton
          type="submit"
          size="mini"
          disabled={
            (addServiceAuditInvoiceStaMutation.isLoading ||
              editServiceAuditInvoiceStaMutation) &&
            (!(
              space &&
              space?.role_permissions?.includes(ADD_SUB_MAIN_STATUS_MAINTENANCE)
            ) ||
              !(
                space &&
                space?.role_permissions?.includes(
                  EDIT_SUB_MAIN_STATUS_MAINTENANCE
                )
              ))
          }
          isLoading={
            addServiceAuditInvoiceStaMutation.isLoading ||
            editServiceAuditInvoiceStaMutation.isLoading
          }
        >
          Save
        </TextButton>
      </div>
    </form>
  );
};

export default SubStatusForm;
