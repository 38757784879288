import {
  GET_CS_GRID_STRUCTURE,
  UPDATE_CS_TABLE_DRAFT_STATE,
  SET_CS_VIEW,
  NESTED_CS_ACTION_TYPE,
  RESET_INITIAL_STATE,
  SET_CS_GRID_AND_VIEW,
  SET_CS_SORT,
  SET_CS_PAGE_INDEX,
  SET_CS_PAGE_SIZE,
  SET_CS_FILTER,
  SET_GLOBAL_FILTER,
  NEEDS_ACTION_FILTERS,
  CUSTOMER_STAGE_FILTERS,
  DASHBOARD_NEED_ACTION_FILTER,
  DASHBOARD_CUSTOMER_ACTION_STAGE,
  TABLE_COLUMN_ORDER,
  RESET_CUSTOMER_NAVLINK,
  COLUMN_WIDTH,
  SET_CS_PINNED_VIEW,
  DASHBOARD_CUSTOM_ACTION_FILTER,
  ORG_DTLS,
  OVERVIEW_PAGE_INDEX,
} from "./action";
import columnMapper from "../../utils/mapper";
import tableStateDraftMapper from "../../utils/tableStateDraftMapper";

const initialQueryBuilder = {
  combinator: "and",
  rules: [],
};

const initialState = {
  gridStructure: [],
  width: 0,
  pinnedView: [],

  tableState: { hiddenColumns: [], columns: [], sort: null, filters: [] },
  tableCurrentState: {
    sort: null,
    pageIndex: 0,
    pageSize: 10,
    filters: {},
    globalFilters: "",
    formFilters: initialQueryBuilder,
    needs_action: {},
    customer_stage: {},
  },
  // columns: col_id, is_hidden, sort, order, width, filter
  tableDraftView: {
    table: [],
    filters: {},
    formFilters: initialQueryBuilder,
  },
  viewName: null,
  columnsData: [],
  currentTableData: { columns: [], hiddenColumns: [] },
  actionType: "",
  gridAndViewLoaded: false,
  tableOrder: [],
  path: "",
  orgDefaultData: {},
  overAllfilters: {},
  overViewPageIndex: 1,
};

/*** TABLE SETTINGS ***/
const isExpanded = false;
const isExpandedHeader = false;
const isSelection = true;
const isChecked = true;
const isCurrency = true;

export const customerSummaryReducer = (
  state = initialState,

  { type, payload }
) => {
  switch (type) {
    case COLUMN_WIDTH: {
      return { ...state, width: payload };
    }
    case RESET_INITIAL_STATE: {
      return { ...state, ...initialState };
    }

    case TABLE_COLUMN_ORDER: {
      return {
        ...state,
        tableOrder: payload,
      };
    }

    case NESTED_CS_ACTION_TYPE: {
      return {
        ...state,
        actionType: payload,
      };
    }

    case GET_CS_GRID_STRUCTURE: {
      let gridStructure = { ...payload }; // {menu, table[], widget[]}

      let { hiddenColumns = [], columns = [] } = columnMapper({
        columns: gridStructure.table,
        isExpanded,
        isExpandedHeader,
        isSelection,
        isRowDeletedVar: "is_active",
        isCurrency,
        width: state.width - 200,
        isEnableSort: state?.orgDefaultData?.en_sort,
      });

      return {
        ...state,
        gridStructure,
        tableState: { hiddenColumns, columns },
      };
    }

    case SET_CS_VIEW: {
      let newState = { ...state };
      let viewData = { ...payload };

      newState.tableState = columnMapper({
        columns: state.gridStructure.table,
        viewSelect: viewData.table,
        isExpanded,
        isExpandedHeader,
        isSelection,
        isRowDeletedVar: "is_active",
        isCurrency,
        width: state.width - 200,
        isEnableSort: state?.orgDefaultData?.en_sort,
      });

      newState.viewName = viewData.name;

      newState.tableDraftView = initialState.tableDraftView;

      newState.gridAndViewLoaded = true;

      if (newState.tableState.sort) {
        let isDesc = newState.tableState.sort.startsWith("-");

        newState.tableCurrentState.sort = [
          { id: newState.tableState.sort.replace("-", ""), desc: isDesc },
        ];
      }

      if (newState.tableState.filters) {
        const currentFilter = newState.tableState.filters;

        newState.tableCurrentState.formFilters = viewData.filter;
        newState.tableCurrentState.filters = viewData.filter;
        newState.tableCurrentState.needs_action = viewData.needs_action;
        newState.tableCurrentState.customer_stage = viewData.customer_stage;
      }
      return {
        ...newState,
      };
    }

    case SET_CS_GRID_AND_VIEW: {
      let newState = { ...state };
      let { grid_structure, default_view, pinned_view = [] } = payload;

      //

      newState.gridStructure = grid_structure;
      newState.pinnedView = pinned_view;

      newState.tableState = columnMapper({
        columns: grid_structure?.table,
        viewSelect: default_view?.table,
        isExpanded,
        isExpandedHeader,
        isSelection,
        isChecked,
        isRowDeletedVar: "is_active",
        isCurrency,
        width: newState.width - 200,
        isEnableSort: state?.orgDefaultData?.en_sort,
      });

      newState.viewName = default_view?.name;

      newState.tableDraftView = initialState.tableDraftView;

      newState.gridAndViewLoaded = true;

      if (newState.tableState.sort) {
        let isDesc = newState.tableState.sort.startsWith("-");
        newState.tableCurrentState = {
          ...newState.tableCurrentState,
          sort: [
            { id: newState.tableState.sort.replace("-", ""), desc: isDesc },
          ],
          pageIndex: 0,
          pageSize: 10,
        };
      }

      if (!state.path) {
        newState.tableCurrentState.formFilters = default_view?.filter;
        newState.tableCurrentState.filters = default_view?.filter;
        newState.tableCurrentState.needs_action = default_view?.needs_action;
        newState.tableCurrentState.customer_stage =
          default_view?.customer_stage;
      }

      return {
        ...newState,
      };
    }

    case UPDATE_CS_TABLE_DRAFT_STATE: {
      let draftColumnData = tableStateDraftMapper({
        tableColumns: payload,
        isSelection,
        isExpanded,
        isExpandedHeader,
        isChecked,
      });

      let newState = { ...state };

      const onlyData = newState.tableState.filters;

      newState.tableDraftView = { table: draftColumnData };

      if (
        state.tableCurrentState.sort &&
        state.tableCurrentState.sort[0] &&
        payload.sort[0] &&
        payload.sort[0].id !== state.tableCurrentState.sort[0].id &&
        payload.sort[0].desc !== state.tableCurrentState.sort[0].desc &&
        !state.path
      ) {
        newState.tableCurrentState = {
          ...newState.tableCurrentState,
          needs_action: newState.tableCurrentState.needs_action,
          customer_stage: newState.tableCurrentState.customer_stage,
          formFilters: newState.tableCurrentState.formFilters,
          filters: newState.tableCurrentState.filters,
          sort: payload.sort,
        };
      }

      return {
        ...newState,
      };
    }

    case SET_CS_SORT: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          sort: payload,
        },
      };
    }

    case SET_CS_PAGE_INDEX: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          pageIndex: payload,
        },
      };
    }

    case SET_CS_PAGE_SIZE: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          pageSize: payload,
        },
      };
    }

    case SET_CS_FILTER: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: payload.filters,
          formFilters: payload.formFilters,
        },
      };
    }

    case SET_GLOBAL_FILTER: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          globalFilters: payload,
        },
      };
    }

    case NEEDS_ACTION_FILTERS: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          needs_action: payload,
        },
      };
    }

    case CUSTOMER_STAGE_FILTERS: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          customer_stage: payload,
        },
      };
    }

    case DASHBOARD_CUSTOM_ACTION_FILTER: {
      return {
        ...state,
        path: payload.path,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: payload.filters,
          formFilters: payload.formFilters,
        },
      };
    }

    case DASHBOARD_NEED_ACTION_FILTER: {
      let needActionType = payload;
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          formFilters: initialState.tableCurrentState.formFilters,
          filters: initialState.tableCurrentState.filters,
          pageIndex: 0,
          needs_action: {
            ...initialState.tableCurrentState.needs_action,
            [payload.key]: true,
          },
          customer_stage: initialState.tableCurrentState.customer_stage,
        },
        path: payload.path,
      };
    }

    case DASHBOARD_CUSTOMER_ACTION_STAGE: {
      let customerStage = payload;

      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          formFilters: initialState.tableCurrentState.formFilters,
          filters: initialState.tableCurrentState.filters,
          pageIndex: 0,
          needs_action: initialState.tableCurrentState.needs_action,
          customer_stage: {
            ...initialState.tableCurrentState.customer_stage,
            [payload.key]: true,
          },
        },
        path: payload.path,
      };
    }
    case RESET_CUSTOMER_NAVLINK: {
      // want to be optimize the view
      return {
        ...state,
        // viewName: null,
        tableCurrentState: {
          ...state.tableCurrentState,
          pageIndex: 0,
          pageSize: 10,
          filters: {},
          globalFilters: "",
          formFilters: initialQueryBuilder,
          needs_action: {},
          customer_stage: {},
        },
      };
    }

    case SET_CS_PINNED_VIEW: {
      return {
        ...state,
        pinnedView: payload,
      };
    }

    case ORG_DTLS: {
      return {
        ...state,
        orgDefaultData: { ...payload },
      };
    }

    case OVERVIEW_PAGE_INDEX: {
      return {
        ...state,
        overViewPageIndex: payload,
      };
    }

    default:
      return state;
  }
};
