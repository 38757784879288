import React, { useEffect, useState } from "react";
import DocFileType from "../../assets/img/svg/DocFileType";
import { ProgressBar, SIZE } from "baseui/progress-bar";
import { KIND, TextButton } from "../TextButton";
import { size, template } from "lodash";
import { HeadingXSmall, LabelSmall } from "baseui/typography";
import {
  createDocument,
  scanningAttachment,
  createSpaceLevelDocument,
  scanSpaceLevelAttachments,
} from "../../services/documentSystem";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  emailAttachmentStatus,
  emailFinalAttachmentData,
  emailAttachmentId,
  updateEmailAttachmentFromDesktop,
  unSelectedDocumentData,
  refetchDocumentData,
} from "../../redux/customerOverview/DMSystem/action";
import { useDispatch } from "react-redux";
import {
  refetchInvoiceDocumentData,
  unSelectedInvoiceDocumentData,
  updateEmailInvoiceAttachmentFromDesktop,
} from "../../redux/invoiceOverview/DMSystemINV/action";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useContext } from "react";
import { letterPreviewPost } from "../../services";
import { Icon } from "../../components_v2";
import { REVERSE_PAYMENT } from "../../constants";
const DocumentType = ({ type }) => {
  switch (type) {
    case "pdf":
      return (
        <div className="file_wraper_icon">
          <DocFileType size={18} />
          <p
            className={`file_text_wraper --file_name_position ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingTop: "5px", top: "-3", left: "2px" }}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );
    case "vnd.ms-excel":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType />
          <p
            className={`file_text_wraper--field ${
              type.length >= 4 && "--doc_font_size_field"
            }`}
          >
            Excel
          </p>
        </div>
      );
    case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType />
          <p
            className={`file_text_wraper--field ${
              type.length >= 4 && "--doc_font_size_field"
            }`}
          >
            Excel
          </p>
        </div>
      );
    case "jpg":
    case "jpeg":
    case "png":
    case "bmp":
      return (
        <div className="file_wraper_icon --doc_purple">
          <DocFileType size={18} />
          <p
            className={`file_text_wraper --file_name_position ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingTop: "5px", top: "-3px", left: "2px" }}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "csv":
    case "xls":
    case "xlsx":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType size={18} />
          <p
            className={`file_text_wraper --file_name_position ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingTop: "5px", top: "-3px", left: "2px" }}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "doc":
    case "docx":
      return (
        <div className="file_wraper_icon --doc_blue">
          <DocFileType size={18} />
          <p
            className={`file_text_wraper --file_name_position ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingTop: "5px", top: "-3px", left: "2px" }}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "txt":
    case "plain":
      return (
        <div className="file_wraper_icon --doc_grey">
          <DocFileType size={18} />
          <p
            className={`file_text_wraper --file_name_position ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingLeft: "2px", paddingTop: "5px" }}
          >
            {type === "plain" ? "TXT" : type.toUpperCase()}

            {/* {type.toUpperCase()} */}
          </p>
        </div>
      );

    case "TEMPLATE":
    case "DOCUMENT":
      return (
        <div className="file_wraper_icon">
          <DocFileType size={18} />
          <p
            className={`file_text_wraper --file_name_position`}
            style={{ paddingTop: "5px", top: "-3", left: "2px" }}
          >
            PDF
          </p>
        </div>
      );

    default:
      return (
        <div className="file_wraper_icon">
          <DocFileType size={18} />
          <p
            className={`file_text_wraper --file_name_position ${
              type?.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingTop: "5px" }}
          >
            {type ? type.toUpperCase() : "PDF"}
          </p>
        </div>
      );
  }
};

const ScanStatus = ({ status }) => {
  switch (status) {
    case "FILE_SCAN_YTS":
      return (
        <>
          <ProgressBar
            overrides={{
              Bar: {
                style: ({ $theme }) => ({
                  marginBottom: "0px",
                }),
              },
            }}
            getProgressLabel={(value) => `Virus Scan`}
            showLabel
            value={10}
            size={SIZE.medium}
            infinite
          />
        </>
      );

    case "FAILURE":
      return <div>Failed</div>;

    default:
      return null;
  }
};

const AttachmentFile = (props) => {
  let {
    isNewDoc,
    fileRef,
    id,
    currentOrganization,
    name = "",
    type,
    tempId,
    fileDoc,
    scanStatus,
    updatingDeskUpload,
    removeAttchFile,
    subCategory,
    setIsRemove = () => {},
    isSpace = false,
    recipientId,
    isTemplate,
    file_type,
    paymentId,
    action,
    isClose = true,
  } = props;

  let dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [fileScanCount, setFileScanCount] = React.useState(0);
  const [unSelectedData, setUnSelectedData] = useState([]);
  const { userInfo, referenceData, space } = useContext(MaxyfiContext);

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    setValue(percent);
  };

  const postLetterPreview = useMutation(
    (data) => {
      return letterPreviewPost({
        customerId: id,
        organization: currentOrganization,
        ...data,
      });
    },
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {},
    }
  );

  const createEmailDocumentMutation = useMutation(
    (data) =>
      isSpace
        ? createSpaceLevelDocument({
            spaceId: space._id,
            data,
            onUploadProgress,
          })
        : createDocument({
            data,
            organization: currentOrganization,
            customerId: id,
            onUploadProgress,
          }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        let fileRef = data && data.data && data.data.doc && data.data.doc._id;
        let updateFile = { tempId, fileRef };
        dispatch(updatingDeskUpload(updateFile));
      },
    }
  );

  useEffect(() => {
    //Initiate Upload only when isNewDoc is true & file reference doesn't exist
    if (isNewDoc && !fileRef) {
      let formData = new FormData();
      formData.append("file", fileDoc);
      formData.append("category", isSpace ? "SPACE" : "CUSTOMER");
      formData.append(
        "sub_category",
        subCategory ? subCategory : "EMAIL_ATTACHMENTS"
      );
      createEmailDocumentMutation.mutate(formData);
    }
  }, []);

  const { refetch } = useQuery(
    [`ATTACHMENT-SCAN-STATUS-${fileRef}`],
    async () => {
      if (fileRef && scanStatus !== "SUCCESS" && !isTemplate) {
        return (await isSpace)
          ? scanSpaceLevelAttachments({
              spaceId: space._id,
              documentId: fileRef,
            })
          : scanningAttachment({
              organization: currentOrganization,
              customerId: id,
              documentId: fileRef,
            });
      }
    },
    {
      onSuccess: (data) => {
        let scanStatus =
          data && data.data && data.data.doc && data.data.doc.scan_status;

        if (scanStatus === "SUCCESS") {
          let updateFile = { tempId, scanStatus };
          dispatch(updatingDeskUpload(updateFile));
          setFileScanCount(5);
        }
      },
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    //Initiate Virus Scan when scanStatus is YTS & isNewDoc & fileRef
    setIsRemove({ fileRef: fileRef });

    if (
      isNewDoc &&
      fileRef &&
      scanStatus === "FILE_SCAN_YTS" &&
      fileScanCount <= 3 &&
      !isTemplate
    ) {
      refetch();
      setTimeout(() => {
        setFileScanCount(fileScanCount + 1);
      }, 3000);
    }
  }, [fileRef, fileScanCount]);

  return (
    <>
      {/* {emailAttached.map((i) => ( */}

      <div
        style={{
          display: "flex",
          width: "175px",
          justifyContent: "space-between",
          border: "1px solid #c7c7c7",
          borderRadius: "5px",
          padding: "3px 5px 2px 5px",
          background: "#ffffff",
          pointerEvents: action === "VIEW" ? "none" : "",
          opacity: action === "VIEW" ? 0.4 : "",
          cursor: action === "VIEW" ? "not-allowed" : "",
          // height: "36px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
            flex: 1,
          }}
          onClick={async () => {
            if (isTemplate) {
              await postLetterPreview.mutateAsync({
                recipient: recipientId,
                template_id: fileRef,
                paymentId: paymentId,
              });
            }
          }}
        >
          <DocumentType type={type === "DOCUMENT" ? file_type : type} />
          <LabelSmall
            title={name}
            //  $style={{ color: "#516beb" }}
            $style={{
              color: "#516beb",
              lineHeight: "15px",
              color: "#516beb",
              whiteSpace: "nowrap",
              overflow: "hidden",
              minWidth: "50px",
              maxWidth: "100px",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </LabelSmall>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* {fileRef && scanStatus === "FILE_SCAN_YTS" ? (
            <div style={{ width: "100px" }}>
              <ScanStatus status={scanStatus} />
            </div>
          ) : (
            <></>
          )} */}

          {/* {!fileRef ? (
            <div style={{ width: "100px" }}>
              <ProgressBar value={value} size={SIZE.medium} />
            </div>
          ) : (
            <></>
          )} */}

          {isClose && action !== REVERSE_PAYMENT && (
            <Icon
              isPressable
              icon="remove"
              size={18}
              onClick={() => {
                let arrObj = {
                  fileRef: fileRef,
                };
                dispatch(removeAttchFile([arrObj]));
              }}
            />
          )}
        </div>
      </div>

      {/* ))} */}
    </>
  );
};

export default AttachmentFile;
