import React, { useContext } from "react";
import { NumericFormat } from "react-number-format";
import {
  H2,
  HeadingSmall,
  HeadingXSmall,
  LabelMedium,
  LabelXSmall,
  LabelLarge,
  ParagraphSmall,
  ParagraphMedium,
  ParagraphLarge,
  HeadingXLarge,
  Label1,
} from "baseui/typography";
import Battery from "../../assets/img/whatsapp/low-battery";
import Signel from "../../assets/img/whatsapp/Signel";
import Connection from "../../assets/img/whatsapp/Connection";
import digit from "../../assets/img/digit.svg";
import { Avatar } from "baseui/avatar";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import moment from "moment";

import { useState } from "react";
import { Icon, Typography } from "../../components_v2";
import { KIND, SIZE, TextButton } from "../TextButton";
import _ from "lodash";
function CustomerPreview(props) {
  const replacements = {
    "{{first_name}}": "John",
    "{{second_name}}": "Antony",
    "{{consumer_name}}": "Jack",
    "{{client_name}}": "T8888DPEX Chambers",
    "{{client_location}}": "USA",
    "{{cus_outstanding_amount}}": "16600",
    "{{last_name}}": "antony",
  };

  const { preferredView, welcomeText, complianceInfo } = props;
  const { header_color, primary_color } = props.color;
  let maxyfiContextValue = useContext(MaxyfiContext);
  let {
    currentOrganization,
    referenceData,
    refetch,
    currentDefaultFormatDetails,
    currencyGroupStyle,
    currencySeparator,
    currencyDecimalSeparator,
    userInfo,
  } = maxyfiContextValue;

  let Duedate = moment
    .utc(new Date())
    .format(currentDefaultFormatDetails.date_format);

  const [click, setClick] = useState(false);
  // function setColor() {
  if (primary_color) {
    document.documentElement.style.setProperty("--logo-color", primary_color);
  }
  // }
  //
  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const replacedString = welcomeText?.replace(
    new RegExp(Object.keys(replacements).join("|"), "g"),
    (matched) => replacements[matched]
  );

  const handler = () => {
    return setClick(true);
  };

  const PayNow = () => {
    return (
      <div style={{ margin: "4px" }}>
        <div className="customer_preview-summary">
          {/* <div>
            <LabelLarge>Summary</LabelLarge>
          </div> */}
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "3px",
            }}
          >
            <LabelLarge
              style={{ color: "#cdced9", fontSize: "14px", fontWeight: "400" }}
            >
              2 invoices
            </LabelLarge>
            <div style={{ display: "flex", gap: "3px" }}>
              <LabelXSmall $style={{ fontWeight: "400", color: "#08083A" }}>
                USD
              </LabelXSmall>
              {/* <LabelLarge $style={{ color: "#08083A" }}>1000.00</LabelLarge> */}
              <NumericFormat
                // type="text"
                displayType="text"
                value={20000.0}
                thousandsGroupStyle={currencyGroupStyle}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={currencySeparator}
                decimalSeparator={currencyDecimalSeparator}
                renderText={(value) => (
                  <LabelLarge
                  // style={{
                  //   mixBlendMode: "difference",
                  //   color: "#ffffff",
                  // }}
                  >
                    {value}
                  </LabelLarge>
                )}
              />
            </div>
          </div>
          <hr style={{ margin: "6px" }} />

          <div
            style={{
              background: `${primary_color}`,
              padding: "8px",
              textAlign: "center",
              cursor: "pointer",
              marginTop: "18px",
              borderRadius: "5px",
            }}
          >
            <LabelLarge
              style={{
                mixBlendMode: "difference",
                color: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              {" "}
              Pay Now
              {/* 1000.00 */}
            </LabelLarge>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {preferredView && preferredView[0] && preferredView[0].id === "SAV" ? (
        <>
          <div class="iphone-x">
            <div className="top-phone">
              <i className="i">Speaker</i>
              <b>Camera</b>
              <s>{showTime}</s>
              <div
                style={{
                  right: "20px",

                  top: "8px",
                  position: "absolute",
                  display: "flex",
                  alignItems: "baseline",
                  alignSelf: "center",
                  gap: "5px",
                  transform: "translateX(8px)",
                }}
              >
                <Signel />
                <Connection />
                <Battery />
              </div>
            </div>
            <div>
              <div
                className="customer_preview-header "
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: `${header_color}`,
                }}
              >
                <Typography type="p" style={{ color: "white" }}>
                  {_.get(currentDefaultFormatDetails, "label", "")}
                </Typography>
                <Typography type="p" style={{ color: "white" }}>
                  {_.get(currentDefaultFormatDetails, "org_url", "")}
                </Typography>
              </div>
            </div>
            <div className="customixe-ui-summary-container">
              <div className="customixe-ui-summary-upload-icon">
                {" "}
                {props.logo && (
                  <>
                    <Avatar
                      overrides={{
                        Root: {
                          style: ({ $theme }) => ({
                            backgroundColor: "#F8F8FB",
                            borderRadius: "5px",
                          }),
                        },
                        Initials: {
                          style: () => ({
                            color: header_color,
                            fontSize: "14px",
                            fontWeight: "700",
                          }),
                        },
                      }}
                      src={props.logo}
                      name={`${orgRef.label}`}
                      size="scale1000"
                    />
                  </>
                )}
              </div>
              <div className="customize-ui-welcome-text">
                <p
                  className="welcome-text-inner-style"
                  dangerouslySetInnerHTML={{
                    __html: replacedString,
                  }}
                />
                {/* <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "16.8px",
                  }}
                >
                 
                  {replacedString?.replace(
                    /<br\s*\/?>|<div[^>]*>|<\/div>/gi,
                    " "
                  )}
                </Typography> */}
              </div>
              <div className="customize-ui-welcome-text-amt">
                <NumericFormat
                  displayType="text"
                  value={10000.0}
                  thousandsGroupStyle={currencyGroupStyle}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={currencySeparator}
                  decimalSeparator={currencyDecimalSeparator}
                  renderText={(value) => (
                    <Typography
                      type="h2"
                      style={{
                        fontSize: "25px",
                        fontWeight: "700",
                        lineHeight: "30px",
                      }}
                    >
                      ${value}
                    </Typography>
                  )}
                />
              </div>
              <div className="summery-action-view-btn">
                <div>
                  <TextButton
                    type="button"
                    size={SIZE.compact}
                    fullWidth
                    style={{ background: `${primary_color}` }}
                  >
                    PAY NOW
                  </TextButton>
                </div>

                <div>
                  <TextButton
                    type="button"
                    size={SIZE.compact}
                    kind={KIND.tertiary}
                    fullWidth
                    style={{
                      border: "1px solid #CDCED9",
                      color: `${primary_color}`,
                    }}
                  >
                    PAY IN INSTALLMENTS
                  </TextButton>
                </div>

                <div>
                  <TextButton
                    type="button"
                    size={SIZE.compact}
                    kind={KIND.tertiary}
                    fullWidth
                    style={{ color: `${primary_color}` }}
                  >
                    VIEW DETAILS
                  </TextButton>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 50px",
                }}
              >
                <div style={{ width: "50%", marginTop: "24px" }}>
                  <hr />
                </div>
                <div>
                  <Typography style={{ color: "#EEEEEE" }}>or</Typography>
                </div>

                <div style={{ width: "50%", marginTop: "24px" }}>
                  <hr />
                </div>
              </div>
              <div className="summery-action-view-icons">
                <div className="icon-border">
                  <Icon icon="email_outline" color={`${primary_color}`} />{" "}
                </div>
                <div className="icon-border">
                  <Icon icon="call_outline" color={`${primary_color}`} />
                  {""}
                </div>
                <div className="icon-border">
                  <Icon icon="email_outline" color={`${primary_color}`} />
                </div>
              </div>
              <div className="summery-action-view-labels">
                <div>
                  <Typography
                    type="p"
                    style={{
                      fontWeight: "500",
                      fontSize: "13px",
                      lineHeight: "17px",
                    }}
                  >
                    Email us
                  </Typography>
                </div>
                <div>
                  <Typography
                    type="p"
                    style={{
                      fontWeight: "500",
                      fontSize: "13px",
                      lineHeight: "17px",
                    }}
                  >
                    Call-Back
                  </Typography>
                </div>
                <div>
                  <Typography
                    type="p"
                    style={{
                      fontWeight: "500",
                      fontSize: "13px",
                      lineHeight: "17px",
                    }}
                  >
                    Your Rights
                  </Typography>
                </div>
              </div>
              <div className="customize-ui-compliance-text">
                <Typography
                  style={{
                    fontSize: "11px",
                    fontWeight: "400",
                    lineHeight: "15px",
                    color: "#787878",
                  }}
                >
                  {complianceInfo}
                </Typography>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div class="iphone-x">
            <div className="top-phone">
              <i className="i">Speaker</i>
              <b>Camera</b>
              <s>{showTime}</s>
              <div
                style={{
                  right: "20px",

                  top: "8px",
                  position: "absolute",
                  display: "flex",
                  alignItems: "baseline",
                  alignSelf: "center",
                  gap: "5px",
                  transform: "translateX(8px)",
                }}
              >
                <Signel />
                <Connection />
                <Battery />
              </div>
            </div>
            <div>
              <div
                className="customer_preview-header "
                style={{ backgroundColor: `${header_color}` }}
              >
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",

                    justifyContent: "center",
                  }}
                >
                  <ParagraphMedium
                    $style={{
                      fontSize: "1rem",
                      fontWeight: "400",
                      lineheight: "1.75rem",
                      mixBlendMode: "difference",
                      color: "#ffffff",
                    }}
                  >
                    Payables for&nbsp;
                  </ParagraphMedium>
                  <ParagraphMedium
                    $style={{
                      fontSize: "1rem",
                      fontWeight: "bolder",
                      lineheight: "1.75rem",
                      mixBlendMode: "difference",
                      color: "#ffffff",
                    }}
                  >
                    Xyz
                  </ParagraphMedium>
                </div>
              </div>
              <div></div>
              <div className="customer_preview-digit">
                <div
                  style={{ display: "flex", gap: "9px", alignItems: "center" }}
                >
                  {/* {props?.logo.length > 0 ? (
            <div
            // style={{
            //   objectFit: "contain",
            //   // height: "55px",
            // }}
            >
              <img src={props.logo} style={{ width: "100%" }} />
            </div>
          ) : (
            <>
              <Avatar
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#F8F8FB",
                      borderRadius: "5px",
                    }),
                  },
                  Initials: {
                    style: () => ({
                      color: header_color,
                      fontSize: "14px",
                      fontWeight: "700",
                    }),
                  },
                }}
                // name="AB"
                src={props.logo}
                name={`${orgRef.label}`}
                size="scale1000"
              />
            </>
          )} */}
                  <Avatar
                    overrides={{
                      Root: {
                        style: ({ $theme }) => ({
                          backgroundColor: "#F8F8FB",
                          borderRadius: "5px",
                        }),
                      },
                      Avatar: {
                        style: () => ({
                          borderTopLeftRadius: "0px",
                          borderTopRightRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: "0px",
                          objectFit: "contain",
                        }),
                      },
                      Initials: {
                        style: () => ({
                          color: header_color,
                          fontSize: "14px",
                          fontWeight: "700",
                        }),
                      },
                    }}
                    // name="AB"
                    src={props?.logo}
                    name={`${orgRef.label ? orgRef.label : ""}`}
                    size="scale1200"
                  />
                  <div>
                    <LabelLarge className="org_name" title={orgRef?.label}>
                      {orgRef?.label}
                    </LabelLarge>
                    <div>Total O/S: 12xxxxxx</div>
                  </div>
                </div>

                <div style={{ display: "flex", gap: "9px" }}>
                  {/* <div className="customer_preview-icons">
            <i className="mx-icon-Vector-2" />
          </div> */}
                  <div className="customer_preview-icons">
                    <i className="mx-icon-Vector-3" />
                  </div>
                  <div className="customer_preview-icons">
                    <i className="mx-icon-Vector-4" />
                  </div>
                </div>
              </div>
              {/* <hr /> */}
              <div className="customer-preview-invoice">
                <div
                  style={{
                    // borderBottom: "3px solid ",
                    borderBottom: `3px solid  ${primary_color}`,
                    zIndex: "90",
                    // padding: "9px 9px 0px ",
                  }}
                >
                  <ParagraphLarge
                    $style={{
                      color: `${primary_color}`,

                      fontWeight: "500",
                      padding: "0px 12px ",
                    }}
                  >
                    Outstanding
                  </ParagraphLarge>
                </div>
                <div style={{ borderBottom: "3px solid transparent" }}>
                  <ParagraphLarge
                    $style={{
                      fontWeight: "400",
                      padding: "0px 12px",
                      color: "#08083A",
                    }}
                  >
                    Paid Invoice
                  </ParagraphLarge>
                </div>
              </div>
              <div style={{ margin: "14px 18px 0px" }}>
                <div className="customer_preview-card">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <LabelLarge $style={{ color: "#08083A" }}>
                      INV-30356
                    </LabelLarge>
                    <i
                      className="mx-icon-checkbox-select --logo-color"
                      style={{ cursor: `pointer` }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ margin: "0px 0px 9px" }}>
                      <div
                        style={{
                          display: "flex",
                          lineHeigth: "12px",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <LabelXSmall
                          $style={{
                            color: "#787878",
                            fontWeight: "400",
                            lineHeight: "29px",
                          }}
                        >
                          Due Date{" "}
                        </LabelXSmall>
                        <span className="date" style={{ color: "#08083A" }}>
                          {Duedate}
                        </span>
                      </div>
                      <LabelXSmall
                        $style={{
                          color: "red",
                          fontWeight: "400",
                        }}
                      >
                        Overdue 16 days ago
                      </LabelXSmall>
                    </div>
                    <div style={{ display: "flex", gap: "3px" }}>
                      <LabelXSmall
                        $style={{ fontWeight: "400", color: "#08083A" }}
                      >
                        USD
                      </LabelXSmall>
                      <LabelLarge $style={{ color: "#08083A" }}>
                        <NumericFormat
                          // type="text"
                          displayType="text"
                          value={10000.0}
                          thousandsGroupStyle={currencyGroupStyle}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={currencySeparator}
                          decimalSeparator={currencyDecimalSeparator}
                          renderText={(value) => (
                            <LabelLarge>{value}</LabelLarge>
                          )}
                        />
                        {/* 500.00 */}
                      </LabelLarge>
                    </div>
                  </div>
                  <hr
                    style={{ margin: "0px", border: "0.5px solid #EEEEEE" }}
                  />

                  <div className="customer_preview-card_bottom">
                    <LabelMedium
                      $style={{ color: `${primary_color}`, fontWeight: "400" }}
                    >
                      View More
                    </LabelMedium>
                    <i className="mx-icon-expand_more" />
                  </div>
                </div>
              </div>
              {/* sec card */}
              <div style={{ margin: "14px 18px 0px" }}>
                <div className="customer_preview-card">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <LabelLarge $style={{ color: "#08083A" }}>
                      INV-30356
                    </LabelLarge>
                    <i className="mx-icon-check-box-unselect" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ margin: "0px 0px 9px" }}>
                      <div
                        style={{
                          display: "flex",
                          lineHeigth: "12px",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <LabelXSmall
                          $style={{
                            color: "#787878",
                            fontWeight: "400",
                            lineHeight: "29px",
                          }}
                        >
                          Due Date{" "}
                        </LabelXSmall>
                        <span className="date" style={{ color: "#08083A" }}>
                          {Duedate}
                        </span>
                      </div>
                      <LabelXSmall
                        $style={{
                          color: "red",
                          fontWeight: "400",
                        }}
                      >
                        Overdue 16 days ago
                      </LabelXSmall>
                    </div>
                    <div style={{ display: "flex", gap: "3px" }}>
                      <LabelXSmall
                        $style={{ fontWeight: "400", color: "#08083A" }}
                      >
                        INR
                      </LabelXSmall>
                      {/* <LabelLarge $style={{ color: "#08083A" }}>500.00</LabelLarge> */}
                      <NumericFormat
                        // type="text"
                        displayType="text"
                        value={10000.0}
                        thousandsGroupStyle={currencyGroupStyle}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={currencySeparator}
                        decimalSeparator={currencyDecimalSeparator}
                        renderText={(value) => <LabelLarge>{value}</LabelLarge>}
                      />
                    </div>
                  </div>
                  <hr
                    style={{ margin: "0px", border: "0.5px solid #EEEEEE" }}
                  />
                  <div className="customer_preview-card_hidden-part">
                    <div className="customer_preview-card_invoice">
                      {" "}
                      <LabelMedium
                        $style={{
                          color: `${primary_color}`,
                          fontWeight: "500",
                        }}
                      >
                        Download Invoice{" "}
                      </LabelMedium>
                    </div>
                    <div className="customer_preview-card_payment">
                      {" "}
                      <LabelMedium
                        $style={{
                          color: `${primary_color}`,
                          fontWeight: "500",
                        }}
                      >
                        Pay Now
                      </LabelMedium>
                    </div>
                  </div>
                  <div className="customer_preview-card_bottom">
                    <LabelMedium
                      $style={{ color: `${primary_color}`, fontWeight: "400" }}
                    >
                      View Less
                    </LabelMedium>

                    <i className="mx-icon-view-less1 logo-color " />
                  </div>
                </div>
              </div>
              {/* third card */}
              <div style={{ margin: "14px 18px 0px" }}>
                <div className="customer_preview-card">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <LabelLarge $style={{ color: "#08083A" }}>
                      INV-30356
                    </LabelLarge>
                    <i
                      className="mx-icon-checkbox-select"
                      style={{ color: `${primary_color}`, cursor: `pointer` }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ margin: "0px 0px 9px" }}>
                      <div
                        style={{
                          display: "flex",
                          lineHeigth: "12px",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <LabelXSmall
                          $style={{
                            color: "#787878",
                            fontWeight: "400",
                            lineHeight: "29px",
                          }}
                        >
                          Due Date{" "}
                        </LabelXSmall>
                        <span className="date" style={{ color: "#08083A" }}>
                          {Duedate}
                        </span>
                      </div>
                      <LabelXSmall
                        $style={{
                          color: `${primary_color}`,
                          fontWeight: "400",
                        }}
                      >
                        Due 20 days ago
                      </LabelXSmall>
                    </div>
                    <div style={{ display: "flex", gap: "3px" }}>
                      <LabelXSmall
                        $style={{ fontWeight: "400", color: "#08083A" }}
                      >
                        USD
                      </LabelXSmall>
                      {/* <LabelLarge $style={{ color: "#08083A" }}>500.00</LabelLarge> */}
                      <NumericFormat
                        // type="text"
                        displayType="text"
                        value={10000.0}
                        thousandsGroupStyle={currencyGroupStyle}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={currencySeparator}
                        decimalSeparator={currencyDecimalSeparator}
                        renderText={(value) => <LabelLarge>{value}</LabelLarge>}
                      />
                    </div>
                  </div>
                  <hr
                    style={{ margin: "0px", border: "0.5px solid #EEEEEE" }}
                  />

                  <div className="customer_preview-card_bottom">
                    <LabelMedium
                      $style={{ color: "#FFBB00", fontWeight: "400" }}
                    >
                      View More
                    </LabelMedium>
                    <i className="mx-icon-expand_more " />
                  </div>
                </div>
              </div>
            </div>
            <PayNow />
          </div>
        </>
      )}
    </>
  );
}

export default CustomerPreview;
const date = new Date();
const showTime = date.getHours() + ":" + date.getMinutes();

// document.documentElement.style.setProperty(
//   "--primary",
//   payload.organization.customer_portal_ui.primary_color
// );
