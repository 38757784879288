export const CUSTOMER_OVERVIEW = "customer_overview";
export const COMMUNICATION_TEMPLATE = "communication_template";
export const WORKFLOW_MAINTENANCE = "workflow_maintenance";
export const INVOICE_SUMMARY = "invoice_summary";
export const INVOICE_OVERVIEW = "invoice_overview";
export const CUSTOMER_INVOICE_OVERVIEW = "customer_invoice_overview1";
export const CREDIT_NOTES = "credit_notes";
export const USERS = "users";
export const ORGANIZATION = "organization";
export const PROMISE_TO_PAY = "promise_to_pay";
export const CALLBACK = "CALLBACK";
export const PROMISE_TO_PAY_INFO = "PROMISE_TO_PAY";
export const PAYMENT = "PAYMENT";
export const EMAIL_REPLIES = "EMAIL_REPLIES";
export const MENTION = "MENTION";
export const COMMISSION_SLAB = "COMMISSION_SLAB";
export const BUSINESS_UNIT_MAINTENANCE = "BUSINESS_UNIT_MAINTENANCE_";
export const KPI_MAINTENANCE = "KPI_MAINTENANCE_";
export const BUSINESS_UNIT_FORM_DATA = "BUSINESS_UNIT_FORM_DATA";
export const CONTACT_STATUS_MAINTENANCE = "CONTACT_STATUS_MAINTENANCE";
export const MINI_MIRANDA_ = "MINI_MIRANDA_";
export const REPORT_DOWNLOAD_ = "REPORT_DOWNLOAD_";
export const BANKRUCPTCY_MAINTANANCE = "BANKRUCPTCY_MAINTANANCE";
export const STRATEGY_MAINTENTENCE_LIST = "STRATEGY_MAINTENTENCE_LIST";
export const CHECK_MAINTENANCE = "CHECK_MAINTENANCE";
export const REPORT_MAINTENTENCE_LIST = "REPORT_MAINTENTENCE_LIST";
/* Query KEY */
export const CUSTOMER_OVERVIEW_ = "CUSTOMER_OVERVIEW_";
export const CO_INVOICES_ = "CO_INVOICES_";
export const CO_TIMELINE_ = "CO_TIMELINE_";
export const CO_PAYMENT_ = "CO_PAYMENT_";
export const LOCATION_TRACKER_ = "LOCATION_TRACKER_";
export const CUSTOMER_LIST_ = "CUSTOMER_LIST_";
export const LEGAL_ACTION_INFO_ = "LEGAL_ACTION_INFO_";
export const LEGAL_DEBT_INVOICES_ = "LEGAL_DEBT_INVOICES_";
export const PAYMENT_INVOICE_LIST_ = "PAYMENT_INVOICE_LIST_";
export const LETTER_STATUS_ = "LETTER_STATUS_";
export const GETTING_FIELD_VISIT_ = "GETTING_FIELD_VISIT_";
export const GET_PERFORMANCE_DASHBOARD_ = "GET_PERFORMANCE_DASHBOARD_";
export const GET_STATS_DASHBOARD_ = "GET_STATS_DASHBOARD_";
export const GET_USER_POSITION_DASHBOARD_ = "GET_USER_POSITION_DASHBOARD_";
export const GRID_STRUCTURE_LEGAL_STATUS = "GRID_STRUCTURE_LEGAL_STATUS";
export const DOC_QUICK_VIEW_LIST = "DOC_QUICK_VIEW_LIST";
export const ADJ_TIMELINE_ = "ADJ_TIMELINE_";
export const REFERENCE_DATA_ = "REFERENCE_DATA_";
export const SPACE_DATA_ = "SPACE_DATA_";
export const GET_GROUPING_LIST_DATA = "GET_GROUPING_LIST_DATA";

export const REPORT_DELIVERY_SETTINGS_ = "REPORT_DELIVERY_SETTINGS_";
export const CUSTOMER_OVERVIEW_COUNT = "customer_overview_count";
export const CHATBOT_CHAT_DATA = "CHATBOT_CHAT_DATA";
export const CHATBOT_CHAT_BODY = "CHATBOT_CHAT_BODY";
