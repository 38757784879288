import React, { useState, useEffect, useContext, useMemo } from "react";
// import * as TextBoxSize from "../../components/TextBox";
import * as TextBoxSize from "../../../components/TextBox";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { FileUploader } from "baseui/file-uploader";
import { useForm, Controller, useFieldArray, get } from "react-hook-form";
import { SelectBox } from "../../../components/SelectBox";
import { TextBox } from "../../../components/TextBox";
import { TextButton, SIZE, KIND } from "../../../components/TextButton";
import getSelectValues from "../../../utils/getSelectValues";
import { useMutation, useQuery } from "react-query";
import { postOrganization } from "../../../services/setting";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { TimePickerAction } from "../../../components/TimePicker/TimePicker";
import CustomerPortalSettingLabel from "../../../components/CustomerPortalSettingLabel/CustomerPortalSettingLabel";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import { getOrganization } from "../../../services/setting";
import { MultiSelect } from "../../../components/MultiSelect/MultiSelect";
import moment, { localeData } from "moment";
import {
  HeadingXLarge,
  HeadingXSmall,
  LabelLarge,
  LabelMedium,
  ParagraphLarge,
  ParagraphMedium,
} from "baseui/typography";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import setSelectValues from "../../../utils/setSelectValues";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import {
  CP_AUTHENTICATION,
  UPDATE_ORGANIZATION_SETTINGS,
  VIEW_ORGANIZATION_SETTINGS,
} from "../../../providers/RBACProvider/permissionList";
import { number } from "yup";
import { errorMonitor } from "form-data";
import { utcTimstampToLocalDate } from "../../../utils/utcTimstampToLocalDate";
import { Tabs, Tab } from "baseui/tabs-motion";
import { useDropzone } from "react-dropzone";
import CustomizeUI from "./CustomizeUI";
import Plus from "../../../assets/img/svg/Upload/Plus";
import Delete from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import CustomizeLabel from "./CutomizeLabel";
import _, { cond } from "lodash";
import { getTemplateVariables } from "../../../services";
import Loader from "../../../components/Loader";
import { Icon, Typography } from "../../../components_v2";
import { IconButton } from "../../../components/IconButton";
import DiscountSlabModal from "../DiscountSlab/DiscountSlabModal";
import {
  getCommissionSlab,
  getDiscountSlabFn,
} from "../../../services/organization";
import { COMMISSION_SLAB, NEW, VIEW } from "../../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const CustomerPortal = () => {
  const { currentOrganization, referenceData, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const [settingData, setSettingData] = useState(null);
  const [isrefetch, setIsRefetch] = useState(0);
  const [isModalState, setisModalState] = useState({
    isOpen: false,
    data: {},
    type: NEW,
  });

  const intl = useIntl();
  const DataType = useSelector((s) => s.settingReducer.type);

  const [activeKey, setActiveKey] = React.useState("0");

  const schema = Yup.object().shape({
    amount_percentage: Yup.number().max(
      100,
      "Enter percentage between 0 to 100"
    ),
    pre_defined_payment_plan: Yup.array(
      Yup.object({
        payment_of: Yup.string()
          .required("Required")
          .max(999, "Enter percentage between 0 to 999"),
        frequency: Yup.array()
          .max(1, "Required")
          .required("Required")
          .nullable()
          .typeError("Required"),
        leadtime: Yup.string()
          .required("Required")
          .matches(
            /^([0-9]+|{{eom}})$/,
            "Lead should be either a number or {{eom}}"
          ),
        discount: Yup.string()
          .optional()
          .test(
            "prc",
            "{{max_settlement_rate}} tag is Required",
            (prc, res) => {
              if (
                prc.length == 0 ||
                Number(prc) ||
                (!Number(prc) && prc.includes("{{max_settlement_rate}}"))
              ) {
                return true;
              }
            }
          ),
      })
    ),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      pre_defined_payment_plan: [
        { payment_of: "", leadtime: "", frequency: "", discount: "" },
      ],
    },
  });

  const { refetch, ...rest } = useQuery(
    [`PORTAL_SETTING_DATA_CUS_${currentOrganization}`],
    async ({ queryKey }) => {
      return await getOrganization({
        organization: currentOrganization,
      });
    },
    {
      onSuccess: (data) => setSettingData(data && data.data && data.data.doc),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const CommissionSlab = useQuery(
    [`DISCOUNT_SLAB-${currentOrganization}`],
    async ({ queryKey }) => {
      // let { filters } = queryKey[1];
      return await getDiscountSlabFn({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  // useEffect(async () => {
  //   setSettingData(
  //     (await getOrganization({ organization: currentOrganization })).data.doc
  //   );
  //   //TODO: PRAVEEN FAILURE ERROR HANDLING
  // }, [isrefetch]);

  let paymentAmountRestriction = getSelectValues(watch("restrict_date"));
  const { payment_plan, pre_defined_payment_plan } = watch();
  useEffect(() => {
    if (paymentAmountRestriction !== "no_validation") {
      setValue("amount_percentage", 100);
    } else {
      setValue("amount_percentage", "0");
    }
  }, []);
  // useEffect(() => {
  //   if (paymentAmountRestriction !== "no_validation") {
  //     setValue("request_back.amount_percentage", 100);
  //   } else {
  //     setValue("request_back.amount_percentage", "0");
  //   }
  // }, [paymentAmountRestriction]);

  useEffect(() => {
    if (settingData) {
      setValue(
        "is_authentication",
        settingData.customer_portal.is_authentication
      );
      setValue("is_callback", settingData.customer_portal.is_callback);
      setValue("is_configured", settingData.customer_portal.is_configured);
      setValue("is_credit_note", settingData.customer_portal.is_credit_note);
      setValue("is_invoice", settingData.customer_portal.is_invoice);
      setValue("is_message", settingData.customer_portal.is_message);
      setValue("is_payment", settingData.customer_portal.is_payment);
      setValue("is_request_back", settingData.customer_portal.is_request_back);
      setValue(
        "is_allow_invoice_download",
        settingData.customer_portal.invoice.is_allow_invoice_download
      );
      setValue(
        "is_display_paid_invoices",
        settingData.customer_portal.invoice.is_display_paid_invoices
      );
      setValue(
        "is_allow_payment_receipt_download",
        settingData.customer_portal.invoice.is_allow_payment_receipt_download
      );
      setValue(
        "is_display_invoice_description",
        settingData.customer_portal.invoice.is_display_invoice_description
      );
      setValue(
        "is_display_adjusted_credit_note",
        settingData.customer_portal.credit_note.is_display_adjusted_credit_note
      );
      setValue(
        "callback_days",
        setSelectValues(
          settingData.customer_portal.callback.callback_days,
          true
        )
      );
      setValue(
        "callback_duration",
        setSelectValues(settingData.customer_portal.callback.callback_duration)
      );
      setValue(
        "is_predefined_payment_plan",
        settingData.customer_portal.is_pre_defined_payment_plan
      );

      setValue("pre_defined_payment_plan");
      settingData &&
        settingData?.customer_portal &&
        settingData?.customer_portal?.pre_defined_payment_plan &&
        settingData?.customer_portal?.pre_defined_payment_plan.map((e, i) => {
          update(i, {
            payment_of: e?.no_of_payments,
            leadtime: _.get(e, "max_lead_time", ""),
            frequency: setSelectValues(e?.payment_plan_frequency),
            discount: e?.discount || "",
          });
        });

      // let startLocalDate = utcTimstampToLocalDate(
      //   settingData.customer_portal.callback.start_time
      // );
      // let endLocalDate = utcTimstampToLocalDate(settingData.customer_portal.callback.end_time);

      // startLocalDate = moment(startLocalDate)
      //   .set({
      //     year: moment().local().year(),
      //     month: moment().local().month(),
      //     date: moment().local().date(),
      //   })
      //   .toDate();

      // endLocalDate = moment(endLocalDate)
      //   .set({
      //     year: moment().local().year(),
      //     month: moment().local().month(),
      //     date: moment().local().date(),
      //   })
      //   .toDate();

      setValue(
        "start_time",
        setSelectValues(settingData.customer_portal.callback.start_time)
      );
      setValue(
        "end_time",
        setSelectValues(settingData?.customer_portal.callback.end_time)
      );

      setValue(
        "is_pay_all_dues",
        settingData.customer_portal.payment.is_pay_all_dues
      );
      setValue(
        "is_adhoc_payment",
        settingData.customer_portal.payment.is_adhoc_payment
      );
      setValue(
        "is_pay_overdues",
        settingData.customer_portal.payment.is_pay_overdues
      );
      setValue(
        "is_allow_files_transfered",
        settingData.customer_portal.message.is_allow_files_transfered
      );
      setValue(
        "is_display_historical_chat",
        settingData.customer_portal.message.is_display_historical_chat
      );
      setValue(
        "amount_percentage",
        settingData.customer_portal.request_back.amount_percentage
      );
      setValue(
        "amount_restriction",
        setSelectValues(
          settingData?.customer_portal?.request_back?.amount_restriction
        )
      );
      setValue(
        "restrict_date",
        setSelectValues(
          settingData?.customer_portal?.request_back?.restrict_date
        )
      );
      setValue(
        "payment_plan.instalmentextension",
        setSelectValues(
          settingData?.customer_portal?.request_payment_plan
            ?.maximum_instalment_extention
        )
      );
      setValue(
        "payment_plan.frequency",
        setSelectValues(
          settingData?.customer_portal?.request_payment_plan
            ?.payment_plan_freequency,
          true
        )
      );
      setValue(
        "payment_plan.residual",
        setSelectValues(
          settingData?.customer_portal?.request_payment_plan
            ?.payment_plan_residual
        )
      );
      let slabData = _.get(CommissionSlab, "data.data.docs", []);
      let legCommissionSlab = _.get(
        settingData,
        "customer_portal.request_payment_plan.disc_slb",
        ""
      );
      if (legCommissionSlab && slabData) {
        let findCommissionSlab = slabData.find(
          ({ _id }) => _id == legCommissionSlab
        );
        let findata = [
          {
            id: findCommissionSlab?._id,
            label: findCommissionSlab?.slab_name,
            ...findCommissionSlab,
          },
        ];

        setValue("payment_plan.disc_slb", findata);
      }
      // setValue(
      //   "payment_plan.disc_slb",
      //   setSelectValues(
      //     settingData?.customer_portal?.request_payment_plan
      //       ?.disc_slb
      //   )
      // );
      setValue(
        "is_request_payment_plan",
        settingData?.customer_portal?.is_request_payment_plan
      );
    }
  }, [settingData]);

  const postOrganizationSetting = useMutation(
    (data) => postOrganization({ ...data, organization: currentOrganization }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;

          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);

          // toast.error(data.message);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        // reset();
      },
    }
  );
  const [ispre, setIsPre] = useState(1);

  const onSubmit = async (data) => {
    let pre_defined_payment_list =
      data && data.pre_defined_payment_plan
        ? data.pre_defined_payment_plan
            .map((value) => {
              return {
                ...(value?.discount?.length > 0
                  ? { discount: value?.discount }
                  : {}),
                payment_plan_frequency: getSelectValues(value?.frequency),
                max_lead_time: _.get(value, "leadtime", ""),
                no_of_payments: Number(value?.payment_of),
              };
            })
            .filter((obj) => obj.no_of_payments)
        : [];

    let values = {
      ...data.customer_portal,
      is_authentication: true,
      is_callback: data.is_callback,
      is_credit_note: data.is_credit_note,
      is_invoice: data.is_invoice,
      is_message: data.is_message,
      // is_payment: data.is_payment,
      is_payment: false,
      is_request_back: data.is_request_back,
      is_request_payment_plan: data.is_request_payment_plan,
      request_back: {
        amount_percentage: data.amount_percentage,
        amount_restriction: getSelectValues(data.amount_restriction),
        restrict_date: getSelectValues(data.restrict_date),
      },
      invoice: {
        is_allow_invoice_download: data.is_allow_invoice_download,
        is_display_paid_invoices: data.is_display_paid_invoices,
        is_allow_payment_receipt_download:
          data.is_allow_payment_receipt_download,
        is_display_invoice_description: data.is_display_invoice_description,
      },
      credit_note: {
        is_display_adjusted_credit_note: data.is_display_adjusted_credit_note,
      },
      payment: {
        // is_adhoc_payment: data.is_adhoc_payment,
        // is_pay_all_dues: data.is_pay_all_dues,
        // is_pay_overdues: data.is_pay_overdues,
        is_adhoc_payment: false,
        is_pay_all_dues: false,
        is_pay_overdues: false,
      },
      message: {
        is_allow_files_transfered: data.is_allow_files_transfered,
        is_display_historical_chat: data.is_display_historical_chat,
      },
      callback: {
        callback_days: getSelectValues(data.callback_days, true),
        callback_duration: getSelectValues(data.callback_duration),
        start_time: getSelectValues(data.start_time),
        end_time: getSelectValues(data.end_time),
        // start_time: moment(data.start_time).utc().valueOf(),
        // end_time: moment(data.end_time).utc().valueOf(),
      },
      request_payment_plan: {
        maximum_instalment_extention: getSelectValues(
          data.payment_plan.instalmentextension
        ),
        payment_plan_freequency: getSelectValues(
          data.payment_plan.frequency,
          true
        ),
        payment_plan_residual: getSelectValues(data.payment_plan.residual),
        disc_slb: getSelectValues(data.payment_plan.disc_slb),
      },
      is_pre_defined_payment_plan: data.is_predefined_payment_plan,
      pre_defined_payment_plan: pre_defined_payment_list,
    };
    // delete values?.request_payment_plan;
    delete values.payment_plan;
    await postOrganizationSetting.mutateAsync({ values, DataType });
  };

  const { fields, append, update, remove, swap, move, insert } = useFieldArray({
    control,
    name: "pre_defined_payment_plan",
  });

  const {
    data: isTemplateData,
    isLoading,
    isFetching,
  } = useQuery(
    `GET_TEMPLATE_VARIABLES_${currentOrganization}`,
    async () => {
      return await getTemplateVariables({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <div className="settings__right-tab">
        <div
          style={{
            height: "100%",
          }}
        >
          <div
            // onSubmit={handleSubmit(onSubmit)}
            // style={{
            //   display: "grid",
            //   // gridTemplateColumns: "repeat(10,50fr)",
            //   gridTemplateRows: "repeat(12,10fr)",
            //   height: "100%",
            // }}
            style={{ height: "100%" }}
          >
            <Tabs
              activeKey={activeKey}
              onChange={({ activeKey }) => {
                setActiveKey(activeKey);
              }}
              overrides={{
                Root: {
                  style: ({ $isActive }) => ({
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }),
                },
                TabBorder: {
                  style: ({ $isActive }) => ({
                    height: "1px",
                  }),
                },
                TabList: {
                  // style: ({ $theme }) => ({ height: "55px" }),
                },
                TabHighlight: {
                  style: ({}) => ({
                    borderRadius: "3px",
                    backgroundColor: "#516BEB",
                  }),
                },
              }}
              activateOnFocus
            >
              <Tab
                title="Portal"
                overrides={{
                  Root: {
                    style: () => ({}),
                  },
                  TabPanel: {
                    style: () => ({
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      //,
                      // height: "100%",
                      // marginBottom: "20px",

                      overflow: "hidden",
                      height: "1vh",
                    }),
                  },
                }}
              >
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ height: "100%" }}
                >
                  <div
                    style={{
                      padding: "25px 30px",
                      overflow: "scroll",
                      height: "calc(100% - 65px)",
                    }}
                  >
                    <div
                      style={{
                        height: "calc(100% - 258px)",
                      }}
                    >
                      {/* Authentication */}
                      {/* <RBACWrapper role={CP_AUTHENTICATION}>
                        <CustomerPortalSettingLabel
                          label={"is_authentication"}
                          isActive={watch("is_authentication")}
                          setActive={(data) =>
                            setValue("is_authentication", data)
                          }
                          children={""}
                          caption={
                            "Secure Portal Access via OTP Authentication"
                          }
                        />
                      </RBACWrapper> */}

                      {/* Invoices */}
                      <div style={{ marginBottom: "20px" }}>
                        <CustomerPortalSettingLabel
                          checkBox={false}
                          label={"is_invoice"}
                          isActive={watch("is_invoice")}
                          setActive={(data) => setValue("is_invoice", data)}
                          caption={"Display Invoice information in the Portal"}
                          children={
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  // height: "80px",
                                  // backgroundColor: "#FFFFFF",
                                  // paddingLeft: "25px",
                                  // paddingTop: "25px",
                                  // paddingBottom: "25px",
                                  // alignItems: "center",
                                  // borderLeft: "0.5px solid #CDCED9",
                                  // borderRight: "0.5px solid #CDCED9",
                                  // borderBottom: "0.5px solid #CDCED9",
                                  // borderBottomLeftRadius: "5px ",
                                  // borderBottomRightRadius: "5px",
                                  // // gap: "150px",
                                  // overflow: "hidden",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <div>
                                    <Controller
                                      name="is_allow_invoice_download"
                                      control={control}
                                      render={({ field }) => (
                                        <CheckBoxBaseweb
                                          {...field}
                                          checked={field.value}
                                          checkmarkType={STYLE_TYPE.default}
                                          // disabled={true}
                                        >
                                          <HeadingXSmall>
                                            <FormattedMessage id="allow_invoice_download">
                                              allow_invoice_download
                                            </FormattedMessage>
                                          </HeadingXSmall>
                                        </CheckBoxBaseweb>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    paddingLeft: "75px",
                                  }}
                                >
                                  <div>
                                    <Controller
                                      name="is_display_paid_invoices"
                                      control={control}
                                      render={({ field }) => (
                                        <CheckBoxBaseweb
                                          {...field}
                                          checked={field.value}
                                          checkmarkType={STYLE_TYPE.default}
                                        >
                                          <HeadingXSmall>
                                            <FormattedMessage id="display_paid_invoices">
                                              display_paid_invoices
                                            </FormattedMessage>
                                          </HeadingXSmall>
                                        </CheckBoxBaseweb>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  // paddingLeft: "75px",
                                  gap: "22px",
                                }}
                              >
                                <div>
                                  <Controller
                                    name="is_allow_payment_receipt_download"
                                    control={control}
                                    render={({ field }) => (
                                      <CheckBoxBaseweb
                                        {...field}
                                        checked={field.value}
                                        checkmarkType={STYLE_TYPE.default}
                                        // checked={true}
                                      >
                                        <HeadingXSmall>
                                          <FormattedMessage id="allow_payment_receipt_download">
                                            Allow_payment_receipt_download
                                          </FormattedMessage>
                                        </HeadingXSmall>
                                      </CheckBoxBaseweb>
                                    )}
                                  />
                                </div>
                                <div>
                                  {/* <Controller
                                    name="is_display_invoice_description"
                                    control={control}
                                    render={({ field }) => (
                                      <CheckBoxBaseweb
                                        {...field}
                                        checked={field.value}
                                        checkmarkType={STYLE_TYPE.default}
                                        // checked={true}
                                      >
                                        <HeadingXSmall>
                                          <FormattedMessage id="is_display_invoice_description">
                                            is_display_invoice_description
                                          </FormattedMessage>
                                        </HeadingXSmall>
                                      </CheckBoxBaseweb>
                                    )}
                                  /> */}
                                </div>
                              </div>
                            </>
                          }
                        />
                      </div>
                      {/* Credit Note */}
                      {/* <div style={{ marginBottom: "20px" }}>
                        <CustomerPortalSettingLabel
                          label={"is_credit_note"}
                          isActive={watch("is_credit_note")}
                          setActive={(data) => setValue("is_credit_note", data)}
                          caption={
                            "Display Credit Note information in the Portal"
                          }
                          children={
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  // height: "80px",
                                  // backgroundColor: "#FFFFFF",
                                  // paddingLeft: "25px",
                                  // paddingTop: "25px",
                                  // paddingBottom: "25px",
                                  // alignItems: "center",
                                  // borderLeft: "0.5px solid #CDCED9",
                                  // borderRight: "0.5px solid #CDCED9",
                                  // borderBottom: "0.5px solid #CDCED9",
                                  // borderBottomLeftRadius: "5px ",
                                  // borderBottomRightRadius: "5px",
                                }}
                              >
                                <Controller
                                  name="is_display_adjusted_credit_note"
                                  control={control}
                                  render={({ field }) => (
                                    <CheckBoxBaseweb
                                      {...field}
                                      checked={field.value}
                                      checkmarkType={STYLE_TYPE.default}
                                    >
                                      <HeadingXSmall>
                                        <FormattedMessage id="adjusted_credit_note">
                                          adjusted_credit_note
                                        </FormattedMessage>
                                      </HeadingXSmall>
                                    </CheckBoxBaseweb>
                                  )}
                                />
                              </div>
                            </>
                          }
                        />
                      </div> */}
                      {/* Payment */}
                      <div style={{ marginBottom: "" }}>
                        <CustomerPortalSettingLabel
                          // checkBox={true}
                          label={"is_payment"}
                          // isActive={watch("is_payment")}
                          isActive={
                            currentDefaultFormatDetails?.is_payment_configured
                              ? true
                              : false
                          }
                          setActive={(data) => setValue("is_payment", data)}
                          disable={
                            !currentDefaultFormatDetails?.is_payment_configured
                          }
                          caption="Get Paid Online or Payment Confirmation"
                          endCaption={
                            !currentDefaultFormatDetails?.is_payment_configured
                              ? "Configure Payment Methods"
                              : ""
                          }
                          // children={
                          //   <>
                          //     <div
                          //       style={{
                          //         display: "flex",
                          //         // height: "80px",
                          //         // backgroundColor: "#FFFFFF",
                          //         // paddingLeft: "25px",
                          //         // paddingTop: "25px",
                          //         // paddingBottom: "25px",
                          //         // alignItems: "center",
                          //         // borderLeft: "0.5px solid #CDCED9",
                          //         // borderRight: "0.5px solid #CDCED9",
                          //         // borderBottom: "0.5px solid #CDCED9",
                          //         // borderBottomLeftRadius: "5px ",
                          //         // borderBottomRightRadius: "5px",
                          //         // // gap: "150px",
                          //         // overflow: "hidden",
                          //       }}
                          //     >
                          //       <div style={{ display: "flex" }}>
                          //         <Controller
                          //           name="is_pay_all_dues"
                          //           control={control}
                          //           render={({ field }) => (
                          //             <CheckBoxBaseweb
                          //               {...field}
                          //               checked={field.value}
                          //               checkmarkType={STYLE_TYPE.default}
                          //             >
                          //               <HeadingXSmall>
                          //                 <FormattedMessage id="pay_all_dues">
                          //                   pay_all_dues
                          //                 </FormattedMessage>
                          //               </HeadingXSmall>
                          //             </CheckBoxBaseweb>
                          //           )}
                          //         />
                          //       </div>
                          //       <div
                          //         style={{
                          //           display: "flex",
                          //           paddingLeft: "75px",
                          //         }}
                          //       >
                          //         <Controller
                          //           name="is_pay_overdues"
                          //           control={control}
                          //           render={({ field }) => (
                          //             <CheckBoxBaseweb
                          //               {...field}
                          //               checked={field.value}
                          //               checkmarkType={STYLE_TYPE.default}
                          //             >
                          //               <HeadingXSmall>
                          //                 <FormattedMessage id="pay_overdues">
                          //                   pay_overdues
                          //                 </FormattedMessage>
                          //               </HeadingXSmall>
                          //             </CheckBoxBaseweb>
                          //           )}
                          //         />
                          //       </div>
                          //       <div
                          //         style={{
                          //           display: "flex",
                          //           paddingLeft: "75px",
                          //         }}
                          //       >
                          //         <Controller
                          //           name="is_adhoc_payment"
                          //           control={control}
                          //           render={({ field }) => (
                          //             <CheckBoxBaseweb
                          //               {...field}
                          //               checked={field.value}
                          //               checkmarkType={STYLE_TYPE.default}
                          //             >
                          //               <HeadingXSmall>
                          //                 <FormattedMessage id="adhoc_payment">
                          //                   adhoc_payment
                          //                 </FormattedMessage>
                          //               </HeadingXSmall>
                          //             </CheckBoxBaseweb>
                          //           )}
                          //         />
                          //       </div>
                          //     </div>
                          //   </>
                          // }
                        />
                      </div>

                      {/* Call Back */}
                      <div style={{ marginBottom: "20px" }}>
                        <CustomerPortalSettingLabel
                          label={"is_callback"}
                          isActive={watch("is_callback")}
                          checkBox={true}
                          setActive={(data) => setValue("is_callback", data)}
                          caption={"Consumers can request Call Back"}
                          children={
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  // height: "80px",
                                  // backgroundColor: "#FFFFFF",
                                  // paddingLeft: "25px",
                                  // paddingTop: "40px",
                                  // paddingBottom: "25px",
                                  // paddingRight: "25px",
                                  // alignItems: "center",
                                  // borderLeft: "0.5px solid #CDCED9",
                                  // borderRight: "0.5px solid #CDCED9",
                                  // borderBottom: "0.5px solid #CDCED9",
                                  // borderBottomLeftRadius: "5px ",
                                  // borderBottomRightRadius: "5px",
                                  // justifyContent: "space-between",
                                  // // gap: "155px",
                                  // overflow: "hidden",
                                }}
                              >
                                <div style={{ width: "260px" }}>
                                  <Controller
                                    name="callback_days"
                                    control={control}
                                    render={({ field }) => (
                                      <MultiSelect
                                        {...field}
                                        multi
                                        name={field.name}
                                        // label={intl.formatMessage({
                                        //   id: "callback_1",
                                        // })}
                                        placeholder={intl.formatMessage({
                                          id: "callback",
                                        })}
                                        value={field.value}
                                        options={referenceData["week_days"]}
                                        onChange={(e) => {
                                          field.onChange(e);
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                                <div style={{ width: "260px" }}>
                                  <Controller
                                    name="callback_duration"
                                    control={control}
                                    render={({ field }) => (
                                      <SelectBox
                                        clearable={false}
                                        size={SIZE.default}
                                        style={{ marginTop: "0px" }}
                                        {...field}
                                        name={field.name}
                                        label={intl.formatMessage({
                                          id: "duration",
                                        })}
                                        placeholder={intl.formatMessage({
                                          id: "duration",
                                        })}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        // options={[
                                        //   {
                                        //     label: "1 Day",
                                        //     id: "1_day",
                                        //   },
                                        //   { label: "2 Days", id: "2_days" },
                                        //   { label: "1 Week", id: "1_week" },
                                        //   { label: "2 Weeks", id: "2_weeks" },
                                        //   {
                                        //     label: "No Validation",
                                        //     id: "no_validaion",
                                        //   },
                                        // ]}
                                        options={
                                          referenceData["call_back_duration"]
                                        }
                                      />
                                    )}
                                  />
                                </div>
                                <div style={{ display: "flex", gap: "10px" }}>
                                  <div style={{ width: "110px" }}>
                                    {/* <Controller
                                name="start_time"
                                control={control}
                                render={({ field }) => (
                                  <TimePickerAction
                                    {...field}
                                    clearable={false}
                                    name={field.name}
                                    error={
                                      errors[field.name] &&
                                      errors[field.name].message
                                    }
                                    label={intl.formatMessage({
                                      id: "start",
                                    })}
                                    placeholder={intl.formatMessage({
                                      id: "start",
                                    })}
                                    value={field.value}
                                    onChange={(e) => {
                                      field.onChange(e);
                                    }}
                                  />
                                )}
                              /> */}
                                    <Controller
                                      name="start_time"
                                      control={control}
                                      render={({ field }) => (
                                        <SelectBox
                                          clearable={false}
                                          style={{ marginTop: "0px" }}
                                          {...field}
                                          size={SIZE.default}
                                          name={field.name}
                                          label={intl.formatMessage({
                                            id: "start",
                                          })}
                                          placeholder={intl.formatMessage({
                                            id: "start",
                                          })}
                                          value={field.value}
                                          onChange={(e) => {
                                            field.onChange(e.value);
                                          }}
                                          options={referenceData["org_time"]}
                                        />
                                      )}
                                    />
                                  </div>
                                  <div style={{ width: "110px" }}>
                                    <Controller
                                      name="end_time"
                                      control={control}
                                      render={({ field }) => (
                                        <SelectBox
                                          clearable={false}
                                          style={{ marginTop: "0px" }}
                                          {...field}
                                          size={SIZE.default}
                                          name={field.name}
                                          label={intl.formatMessage({
                                            id: "end",
                                          })}
                                          placeholder={intl.formatMessage({
                                            id: "end",
                                          })}
                                          value={field.value}
                                          onChange={(e) => {
                                            field.onChange(e.value);
                                          }}
                                          options={referenceData["org_time"]}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          }
                        />
                      </div>
                      {/* Request Back */}
                      <div style={{ paddingBottom: "5px" }}>
                        <CustomerPortalSettingLabel
                          label={"is_request_back"}
                          isActive={watch("is_request_back")}
                          checkBox={true}
                          setActive={(data) =>
                            setValue("is_request_back", data)
                          }
                          caption={
                            "Consumers can request additional Payment Time"
                          }
                          children={
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  // height: "80px",
                                  // backgroundColor: "#FFFFFF",
                                  // paddingLeft: "25px",
                                  paddingTop: "5px",
                                  // paddingBottom: "25px",
                                  // paddingRight: "25px",
                                  // alignItems: "center",
                                  // borderLeft: "0.5px solid #CDCED9",
                                  // borderRight: "0.5px solid #CDCED9",
                                  // borderBottom: "0.5px solid #CDCED9",
                                  // borderBottomLeftRadius: "5px ",
                                  // borderBottomRightRadius: "5px",
                                  // // gap: "90px",
                                  // justifyContent: "space-between",
                                  // overflow: "hidden",
                                }}
                              >
                                <div style={{ width: "260px" }}>
                                  <Controller
                                    name="restrict_date"
                                    control={control}
                                    render={({ field }) => (
                                      <SelectBox
                                        clearable={false}
                                        size={SIZE.default}
                                        style={{ marginTop: "0px" }}
                                        {...field}
                                        name={field.name}
                                        label={intl.formatMessage({
                                          id: "restrict_date",
                                        })}
                                        placeholder={intl.formatMessage({
                                          id: "restrict_date",
                                        })}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        options={
                                          referenceData[
                                            "payment_extension_duration"
                                          ]
                                        }

                                        // options={
                                        //   referenceData["payment_amount_restriction"]
                                        // }
                                      />
                                    )}
                                  />
                                </div>

                                <div style={{ width: "260px" }}>
                                  <Controller
                                    name="amount_restriction"
                                    control={control}
                                    render={({ field }) => (
                                      <SelectBox
                                        clearable={false}
                                        style={{ marginTop: "0px" }}
                                        {...field}
                                        size={SIZE.default}
                                        name={field.name}
                                        label={intl.formatMessage({
                                          id: "amnt_restriction",
                                        })}
                                        placeholder={intl.formatMessage({
                                          id: "amnt_restriction",
                                        })}
                                        options={
                                          referenceData[
                                            "payment_amount_restriction"
                                          ]
                                        }
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                      />
                                    )}
                                  />
                                </div>

                                <div style={{ width: "260px" }}>
                                  <Controller
                                    name="amount_percentage"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <TextBox
                                          size={SIZE.default}
                                          {...field}
                                          type="number"
                                          disabled={
                                            paymentAmountRestriction ===
                                            "no_validation"
                                          }
                                          maxLength={3}
                                          // clearOnEscape
                                          fullWidth
                                          name={field.name}
                                          error={
                                            errors[field.name] &&
                                            errors[field.name].message
                                          }
                                          label={intl.formatMessage({
                                            id: "percentage",
                                          })}
                                          placeholder={intl.formatMessage({
                                            id: "percentage",
                                          })}
                                          value={field.value}
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </>
                          }
                        />
                      </div>
                      {/* PaymentPlan */}
                      <div style={{ marginBottom: "" }}>
                        <CustomerPortalSettingLabel
                          label={"is_payment_plan"}
                          isActive={watch("is_request_payment_plan")}
                          checkBox={true}
                          setActive={(data) =>
                            setValue("is_request_payment_plan", data)
                          }
                          caption={"Consumers can request Payment Plan"}
                          children={
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  gap: "10px",
                                  flexDirection: "row",
                                }}
                              >
                                <div style={{ width: "230px" }}>
                                  <Controller
                                    name="payment_plan.instalmentextension"
                                    control={control}
                                    render={({ field }) => (
                                      <SelectBox
                                        style={{ marginTop: "0px" }}
                                        {...field}
                                        name={field.name}
                                        clearable={false}
                                        label={intl.formatMessage({
                                          id: "instalment_extension",
                                        })}
                                        placeholder={intl.formatMessage({
                                          id: "instalment_extension",
                                        })}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                          // onChange();
                                        }}
                                        options={
                                          referenceData[
                                            "payment_plan_maximum_instalment"
                                          ]
                                        }
                                        size={SIZE.default}
                                      />
                                    )}
                                  />
                                </div>
                                <div style={{ width: "200px" }}>
                                  <Controller
                                    name="payment_plan.frequency"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <MultiSelect
                                          {...field}
                                          multi
                                          name={field.name}
                                          // label={intl.formatMessage({
                                          //   id: "callback_1",
                                          // })}
                                          placeholder={intl.formatMessage({
                                            id: "payment_frequency",
                                          })}
                                          value={field.value}
                                          options={
                                            referenceData[
                                              "payment_plan_frequency"
                                            ]
                                          }
                                          onChange={(e) => {
                                            field.onChange(e);
                                          }}
                                          disabled={
                                            payment_plan?.instalmentextension[0]
                                              ?.label == "No Validation"
                                          }
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                                <div style={{ width: "200px" }}>
                                  <Controller
                                    name="payment_plan.residual"
                                    control={control}
                                    render={({ field }) => (
                                      <SelectBox
                                        clearable={false}
                                        style={{ marginTop: "0px" }}
                                        {...field}
                                        name={field.name}
                                        label={intl.formatMessage({
                                          id: "payment_residual",
                                        })}
                                        placeholder={intl.formatMessage({
                                          id: "payment_residual",
                                        })}
                                        size={SIZE.default}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                          //
                                          // (e.value && e.value[0].id === "full_amount") ||
                                          // (e.value && e.value[0].id === "due_amount") ? (
                                          //   <>
                                          //     {setValue(
                                          //       "request_back.amount_percentage",
                                          //       100
                                          //     )}
                                          //   </>
                                          // ) : e.value &&
                                          //   e.value[0].id === "no_validation" ? (
                                          //   <>
                                          //     {setValue(
                                          //       "request_back.amount_percentage",
                                          //       0
                                          //     )}
                                          //   </>
                                          // ) : (
                                          //   <></>
                                          // );
                                          // onChange();
                                        }}
                                        disabled={
                                          payment_plan?.instalmentextension[0]
                                            ?.label == "No Validation"
                                        }
                                        options={
                                          referenceData["payment_plan_residual"]
                                        }
                                      />
                                    )}
                                  />
                                </div>
                                <div
                                  style={{
                                    width: "300px",
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "260px",
                                    }}
                                  >
                                    <Controller
                                      name="payment_plan.disc_slb"
                                      control={control}
                                      render={({ field }) => (
                                        <SelectBox
                                          clearable={false}
                                          style={{ marginTop: "0px" }}
                                          {...field}
                                          name={field.name}
                                          label={intl.formatMessage({
                                            id: "discount_slab",
                                          })}
                                          placeholder={intl.formatMessage({
                                            id: "discount_slab",
                                          })}
                                          size={SIZE.default}
                                          value={field.value}
                                          onChange={(e) => {
                                            field.onChange(e.value);
                                          }}
                                          disabled={
                                            payment_plan?.instalmentextension[0]
                                              ?.label == "No Validation"
                                          }
                                          options={
                                            CommissionSlab &&
                                            CommissionSlab.data &&
                                            CommissionSlab.data.data &&
                                            CommissionSlab.data.data.docs &&
                                            Array.isArray(
                                              CommissionSlab.data.data.docs
                                            ) &&
                                            CommissionSlab.data.data.docs
                                              .map((e) => {
                                                let max =
                                                  e?.slab_range_list?.[0]
                                                    ?.percentage;
                                                let min =
                                                  e?.slab_range_list?.[0]
                                                    ?.percentage;
                                                for (
                                                  let i = 1;
                                                  i <
                                                  e?.slab_range_list?.length;
                                                  i++
                                                ) {
                                                  if (
                                                    e?.slab_range_list?.[i]
                                                      .percentage > max
                                                  ) {
                                                    max =
                                                      e?.slab_range_list?.[i]
                                                        .percentage;
                                                  }
                                                  if (
                                                    e?.slab_range_list?.[i]
                                                      .percentage < min
                                                  ) {
                                                    min =
                                                      e?.slab_range_list?.[i]
                                                        .percentage;
                                                  }
                                                }
                                                return {
                                                  id: e._id,
                                                  label: `${e.slab_name} (${min}% - ${max}%)`,
                                                  ...e,
                                                };
                                              })
                                              .sort((a, b) =>
                                                a?.label?.localeCompare(b.label)
                                              )
                                          }
                                        />
                                      )}
                                    />
                                    <div
                                      className="add_discont_slan"
                                      onClick={() => {
                                        setisModalState({
                                          isOpen: true,
                                          data: {},
                                        });
                                      }}
                                    >
                                      <Icon icon="plus" color="#516beb" />
                                      <Typography>
                                        {" "}
                                        Add Discount Slab
                                      </Typography>
                                    </div>
                                  </div>
                                  {payment_plan &&
                                    payment_plan.disc_slb &&
                                    payment_plan.disc_slb[0] && (
                                      <div style={{ marginTop: "5px" }}>
                                        <IconButton
                                          type="button"
                                          kind={KIND.primary}
                                          onClick={() => {
                                            setisModalState({
                                              type: VIEW,
                                              isOpen: true,
                                              data:
                                                payment_plan &&
                                                payment_plan.disc_slb,
                                            });
                                          }}
                                        >
                                          <Icon icon="view_icon" />
                                        </IconButton>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </>
                          }
                        />
                      </div>

                      {/* Predefined Payment Plans */}

                      <div style={{ marginBottom: "" }}>
                        <CustomerPortalSettingLabel
                          label={"is_per_payment_plan"}
                          isActive={watch("is_predefined_payment_plan")}
                          checkBox={true}
                          setActive={(data) =>
                            setValue("is_predefined_payment_plan", data)
                          }
                          caption={
                            "Create customized payment plan with incentives"
                          }
                          children={
                            <>
                              <div>
                                {fields &&
                                  fields.map((e, i) => {
                                    return (
                                      <div
                                        key={e.id}
                                        style={{
                                          display: "flex",
                                          paddingTop: "5px",
                                          paddingBottom: "5px",
                                          gap: "10px",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div style={{ width: "260px" }}>
                                          <Controller
                                            name={`pre_defined_payment_plan.${i}.payment_of`}
                                            control={control}
                                            shouldUnregister={true}
                                            // rules={{
                                            //   max: {
                                            //     value: 999,
                                            //     message:
                                            //       "Enter percentage between 0 to 999", // JS only: <p>error message</p> TS only support string
                                            //   },
                                            //   required: "Required",
                                            // }}
                                            render={({ field }) => (
                                              <TextBox
                                                type="number"
                                                {...field}
                                                //   disabled={isDisable}
                                                fullWidth
                                                name={field.name}
                                                size={SIZE.default}
                                                error={
                                                  errors &&
                                                  errors[
                                                    "pre_defined_payment_plan"
                                                  ] &&
                                                  errors[
                                                    "pre_defined_payment_plan"
                                                  ][i] &&
                                                  errors[
                                                    "pre_defined_payment_plan"
                                                  ][i]["payment_of"] &&
                                                  errors[
                                                    "pre_defined_payment_plan"
                                                  ][i]["payment_of"].message
                                                }
                                                label={intl.formatMessage({
                                                  id: "of_payment",
                                                })}
                                                placeholder={intl.formatMessage(
                                                  {
                                                    id: "of_payment",
                                                  }
                                                )}
                                                // value={field.value}
                                                onKeyDown={(e) => {
                                                  if (
                                                    e.key == "ArrowUp" ||
                                                    e.key == "ArrowDown"
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                              />
                                            )}
                                          />
                                        </div>
                                        <div style={{ width: "260px" }}>
                                          <Controller
                                            name={`pre_defined_payment_plan.${i}.frequency`}
                                            control={control}
                                            shouldUnregister={true}
                                            render={({ field }) => (
                                              <>
                                                <SelectBox
                                                  clearable={false}
                                                  style={{ marginTop: "0px" }}
                                                  {...field}
                                                  name={field.name}
                                                  label={intl.formatMessage({
                                                    id: "frequency",
                                                  })}
                                                  placeholder={intl.formatMessage(
                                                    {
                                                      id: "frequency",
                                                    }
                                                  )}
                                                  error={
                                                    errors &&
                                                    errors[
                                                      "pre_defined_payment_plan"
                                                    ] &&
                                                    errors[
                                                      "pre_defined_payment_plan"
                                                    ][i] &&
                                                    errors[
                                                      "pre_defined_payment_plan"
                                                    ][i]["frequency"] &&
                                                    errors[
                                                      "pre_defined_payment_plan"
                                                    ][i]["frequency"].message
                                                  }
                                                  size={SIZE.default}
                                                  value={field.value}
                                                  onChange={(e) => {
                                                    field.onChange(e.value);
                                                  }}
                                                  disabled={
                                                    payment_plan
                                                      ?.instalmentextension[0]
                                                      ?.label == "No Validation"
                                                  }
                                                  options={
                                                    referenceData[
                                                      "pre_defined_payment_plan_frequency"
                                                    ]
                                                  }
                                                />
                                              </>
                                            )}
                                          />
                                        </div>
                                        <div style={{ width: "260px" }}>
                                          <Controller
                                            name={`pre_defined_payment_plan.${i}.leadtime`}
                                            control={control}
                                            shouldUnregister={true}
                                            render={({ field }) => (
                                              <TextBox
                                                {...field}
                                                //   disabled={isDisable}
                                                fullWidth
                                                name={field.name}
                                                size={SIZE.default}
                                                error={
                                                  errors &&
                                                  errors[
                                                    "pre_defined_payment_plan"
                                                  ] &&
                                                  errors[
                                                    "pre_defined_payment_plan"
                                                  ][i] &&
                                                  errors[
                                                    "pre_defined_payment_plan"
                                                  ][i]["leadtime"] &&
                                                  errors[
                                                    "pre_defined_payment_plan"
                                                  ][i]["leadtime"].message
                                                }
                                                label={intl.formatMessage({
                                                  id: "max_lead_time",
                                                })}
                                                placeholder={intl.formatMessage(
                                                  {
                                                    id: "max_lead_time",
                                                  }
                                                )}
                                                value={field.value}
                                                onKeyDown={(e) => {
                                                  if (
                                                    e.key == "ArrowUp" ||
                                                    e.key == "ArrowDown"
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                              />
                                            )}
                                          />
                                        </div>
                                        <div style={{ width: "260px" }}>
                                          <Controller
                                            name={`pre_defined_payment_plan.${i}.discount`}
                                            control={control}
                                            shouldUnregister={true}
                                            render={({ field }) => (
                                              <TextBox
                                                {...field}
                                                fullWidth
                                                name={field.name}
                                                size={SIZE.default}
                                                error={
                                                  errors &&
                                                  errors.pre_defined_payment_plan &&
                                                  errors
                                                    .pre_defined_payment_plan[
                                                    i
                                                  ] &&
                                                  errors
                                                    .pre_defined_payment_plan[i]
                                                    .discount &&
                                                  errors
                                                    .pre_defined_payment_plan[i]
                                                    .discount.message
                                                }
                                                label={intl.formatMessage({
                                                  id: "discount",
                                                })}
                                                placeholder={intl.formatMessage(
                                                  {
                                                    id: "discount",
                                                  }
                                                )}
                                                value={field.value}
                                                onKeyDown={(e) => {
                                                  if (
                                                    e.key == "ArrowUp" ||
                                                    e.key == "ArrowDown"
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                              />
                                            )}
                                          />
                                        </div>
                                        {i > 0 ? (
                                          <div
                                            style={{ paddingBottom: "15px" }}
                                          >
                                            <TextButton
                                              type="button"
                                              kind={KIND.tertiary}
                                              size={TextBoxSize.SIZE.mini}
                                              onClick={() => {
                                                remove(i);
                                              }}
                                            >
                                              <Delete color="#FD372A" />
                                            </TextButton>
                                          </div>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                              </div>
                              <div style={{ widows: "100%" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <TextButton
                                    type="button"
                                    kind={KIND.tertiary}
                                    size={TextBoxSize.SIZE.mini}
                                    onClick={() =>
                                      append({
                                        payment_of: "",
                                        leadtime: "",
                                        frequency: "",
                                        discount: "",
                                      })
                                    }
                                  >
                                    <Plus fill={"#516BEB"} />
                                    <span className="new_payment_plan">
                                      Add New Schedule
                                    </span>
                                  </TextButton>
                                </div>
                              </div>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      // background: "#ffffff",
                      background: "#F9F9F9",

                      // width: "100%",
                      height: "70px",
                      padding: "15px",
                      // borderTop: "1px solid #CDCED9",
                      gridRow: "12/13",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: "125px", height: "36px" }}>
                        <RBACWrapper
                          role={UPDATE_ORGANIZATION_SETTINGS}
                          type="PERMISSION"
                        >
                          <TextButton
                            size={SIZE.compact}
                            fullWidth
                            type="submit"
                            isLoading={isSubmitting}
                            disabled={isSubmitting}
                          >
                            Save
                          </TextButton>
                        </RBACWrapper>
                      </div>
                    </div>
                  </div>
                </form>
              </Tab>
              <Tab
                title="Customize UI"
                overrides={{
                  Root: {
                    style: () => ({
                      paddingTop: "0px",
                    }),
                  },

                  TabPanel: {
                    style: () => ({
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      // height: "100%",
                      // marginBottom: "-100px",
                      overflow: "scroll",
                      marginBottom: "0px",
                      height: "1vh",
                    }),
                  },
                }}
              >
                <CustomizeUI
                  isTemplateData={isTemplateData}
                  settingData={settingData}
                  setIsRefetch={refetch}
                  isrefetch={isrefetch}
                />
              </Tab>
              <Tab
                title="Customize Label"
                overrides={{
                  Root: {
                    style: () => ({
                      paddingTop: "0px",
                    }),
                  },

                  TabPanel: {
                    style: () => ({
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      // height: "100%",
                      // marginBottom: "-100px",
                      overflow: "scroll",
                      marginBottom: "0px",
                      height: "1vh",
                    }),
                  },
                }}
              >
                {!rest.isFetching ? (
                  <CustomizeLabel
                    customizeTableData={_.get(
                      settingData,
                      "customer_portal_ui.cuslb",
                      []
                    )}
                    settingData={settingData}
                    setIsRefetch={refetch}
                    isLoading={rest.isLoading}
                    isFetching={rest.isFetching}
                  />
                ) : (
                  <Loader />
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <DiscountSlabModal
        isModalState={isModalState}
        setisModalState={setisModalState}
        refetch={refetch}
      />
    </>
  );
};

export default CustomerPortal;
