import React, { useState, useEffect, useContext, useMemo } from "react";
import Layout from "../../layouts";
import PageTitle from "../../components/PageTitle";
import PerformanceDashboardTable from "../../containers/PerformanceDashboard/PerformanceDashboardTable";
import { Icon, Typography } from "../../components_v2";
import { KIND, TextButton } from "../../components/TextButton";
import queryClient from "../../providers/queryClient";
import _ from "lodash";
import { useIntl } from "react-intl";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import moment from "moment";
import csvDownload from "json-to-csv-export";
import { getPerformanceList } from "../../services/revenueBilling";
import useFormat from "../../hooks/useFormat";
import { currencyList } from "../../utils_v2/CurrencyToSymbol";
const PerformanceDashboard = () => {
  const [addItemModal, setAddItemModal] = useState(false);
  const [refetchingList, setRefetchingList] = useState(1);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [querySortBy, setQuerySortBy] = useState("");
  const intl = useIntl();
  const format = useFormat();
  const [filterSearch, setFilterSearch] = useState("");
  const {
    currentOrganization,
    currentDefaultFormatDetails,

    users,
  } = useContext(MaxyfiContext);
  const [performanceColumn, setPerformanceColumn] = useState([]);
  const [hiddenPerformanceData, setPerformanceHiddenData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const datas = queryClient.getQueryData("GRID_STRUCTURE-performace_dashBoard");
  const filteredColumns = useMemo(() => {
    const allColumns = performanceColumn.filter(
      (i) => i.id !== "expander" && i.id !== "selection"
    );

    const hiddenColumns = hiddenPerformanceData
      .filter((i) => !i.is_hidden)
      .sort((a, b) => a.order - b.order)
      .map((i) => i.accessor);

    return hiddenColumns
      .map((e) => allColumns.find((col) => col.path === e))
      .filter(Boolean);
  }, [performanceColumn, hiddenPerformanceData]);

  const finalDatas = useMemo(() => {
    return filteredColumns.reduce((prev, curr) => {
      const gridColumn = _.get(datas, "data.doc.table", []).find(
        (i) => i.accessor === curr.id
      );

      if (gridColumn?.nested && Array.isArray(gridColumn.nested)) {
        return [...prev, ...gridColumn.nested];
      }
      return [...prev, curr];
    }, []);
  }, [filteredColumns, datas]);

  return (
    <Layout>
      <>
        <div className="content-container">
          <div className="content-header">
            <div
              className="content-header__title"
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <PageTitle id="performance_dashboard" />
              <TextButton
                style={{
                  display: "flex",

                  gap: "4px",
                  alignItems: "center",
                }}
                onClick={async () => {
                  setLoading(true);
                  // close();

                  let performanceData = await getPerformanceList({
                    organization: currentOrganization,
                    queryPageIndex,
                    querySortBy,
                    queryPageSize,
                    filters: {
                      ...(filterSearch && filterSearch.length > 0
                        ? { display_name: filterSearch }
                        : {}),
                      ...(startDate
                        ? {
                            month: [
                              moment
                                .tz(
                                  startDate,
                                  currentDefaultFormatDetails.time_zone
                                )
                                .startOf("month")
                                .utc()
                                .valueOf(),
                              moment
                                .tz(
                                  startDate,
                                  currentDefaultFormatDetails.time_zone
                                )
                                .endOf("month")
                                .utc()
                                .valueOf(),
                            ],
                          }
                        : {}),
                    },
                  })
                    .then((res) => {
                      setData(res?.data?.docs);
                      setLoading(false);
                      return _.get(res, "data.data", []);
                    })
                    .catch((error) => {
                      return [];
                    });

                  let mockData = [];
                  for (let row of performanceData) {
                    let rsObj = {};

                    for (let k of finalDatas) {
                      let key = k.literal_id;
                      key = intl.formatMessage({
                        id: key,
                      });
                      if (
                        currentDefaultFormatDetails &&
                        currentDefaultFormatDetails.default_currency &&
                        (k.cell_type === "CURRENCY" ||
                          k.cell_type === "CURRENCY_NEW" ||
                          k.cell_type === "CURRENCYFEEAMOUNT")
                      ) {
                        key = `${key} ${""} (in ${
                          currentDefaultFormatDetails &&
                          currentDefaultFormatDetails.default_currency
                        })`;
                      }

                      let value = _.get(row, k.accessor || k.path, "");

                      if (key === "Payment Collected &  Expected") {
                        rsObj[
                          intl.formatMessage({
                            id: "payment_collection",
                          })
                        ] = `${
                          currencyList[
                            currentDefaultFormatDetails.default_currency
                          ]
                        } ${format.currency({
                          amount: _.get(row, "cla", ""),
                        })}`;
                        rsObj[
                          intl.formatMessage({
                            id: "payment_expected",
                          })
                        ] = `${
                          currencyList[
                            currentDefaultFormatDetails.default_currency
                          ]
                        } ${format.currency({
                          amount: _.get(row, "fppa", ""),
                        })}`;
                      } else {
                        switch (k.cell_type) {
                          case "USER": {
                            const user = users.find(({ id }) => {
                              return id === value;
                            });

                            if (value) {
                              value = user?.displayName;
                            }
                            // value && value ? (value = user?.displayName) : <></>;

                            break;
                          }
                          case "USER_ROLE": {
                            if (value) {
                              value = format.rd({
                                name: "user_role",
                                id: value,
                              });
                            }
                            break;
                          }
                          // case "PAYMENT_COLLECTED": {
                          //   if (value) {
                          //     value = `${
                          //       currencyList[
                          //         currentDefaultFormatDetails.default_currency
                          //       ]
                          //     } ${format.currency({ amount: value })}`;
                          //   }
                          //   break;
                          // }
                          case "COMMISSION_EARNED": {
                            if (value) {
                              value = `${
                                currentDefaultFormatDetails &&
                                currentDefaultFormatDetails.default_currency
                                  ? currencyList[
                                      currentDefaultFormatDetails
                                        .default_currency
                                    ]
                                  : ""
                              } ${format.currency({ amount: value })}`;
                            }
                            break;
                          }
                          case "COLLECTION_TARGET": {
                            if (value) {
                              value = `${
                                currentDefaultFormatDetails &&
                                currentDefaultFormatDetails.default_currency
                                  ? currencyList[
                                      currentDefaultFormatDetails
                                        .default_currency
                                    ]
                                  : ""
                              } ${format.currency({ amount: value })}`;
                            }
                            break;
                          }
                        }
                        rsObj[key] = value;
                      }
                    }

                    mockData.push(rsObj);
                  }

                  csvDownload(
                    mockData.sort((a, b) => a?.Name?.localeCompare(b?.Name)),
                    `${`Performance Dashboard List ${moment().format(
                      currentDefaultFormatDetails?.date_format
                    )}${" "}${moment().format("hh:mm:ss a")}.csv`}`
                  );
                }}
                size="mini"
                kind={KIND.tertiary}
              >
                <Icon icon="download" size={20} color="#516BEB" />
                <Typography type="p" className="text-primary" subType="medium">
                  Download
                </Typography>
              </TextButton>
            </div>
          </div>
          <div className="content-body">
            <PerformanceDashboardTable
              setQueryPageIndex={setQueryPageIndex}
              queryPageIndex={queryPageIndex}
              queryPageSize={queryPageSize}
              setStartDate={setStartDate}
              setPerformanceColumn={setPerformanceColumn}
              setQueryPageSize={setQueryPageSize}
              setQuerySortBy={setQuerySortBy}
              startDate={startDate}
              querySortBy={querySortBy}
              setPerformanceHiddenData={setPerformanceHiddenData}
              setFilterSearch={setFilterSearch}
              filterSearch={filterSearch}
              addItemModal={addItemModal}
              setAddItemModal={setAddItemModal}
              // setEditingInvoiceItem={setEditingInvoiceItem}
              refetchingList={refetchingList}
              setRefetchingList={setRefetchingList}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};

export default PerformanceDashboard;
