import React, { useContext, useMemo, useState } from "react";
import { Typography } from "../../../components_v2";
import { Controller, useForm } from "react-hook-form";
import { SIZE, TextArea } from "../../../components/TextArea";
import { findPhoneNumbersInText } from "libphonenumber-js";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import queryClient from "../../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../../constants";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { SelectBox } from "../../../components/SelectBox";
import { TextButton } from "../../../components/TextButton";
import { TextBox } from "../../../components/TextBox";
import getSelectValues from "../../../utils/getSelectValues";
import {
  useCustomerContact,
  useUpdateCustomerContact,
} from "../../../services/customerOverviewContacts";
import { setDrawerState } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { constactEmailRegex } from "../../../utils/regexConfig";
import { ADD_LIST_VALUES } from "../../../providers/RBACProvider/permissionList";

const BulkPhoneImport = () => {
  const { customerId } = useParams();
  const intl = useIntl();
  const {
    currentOrganization,
    referenceData,
    currentDefaultFormatDetails,
    refetch,
    space,
  } = useContext(MaxyfiContext);
  const [isTextBox, setIsTextBox] = useState("");
  const [isCount, setIsCount] = useState(0);
  const [isType, setIsType] = useState("PHONE");
  let dispatch = useDispatch();

  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  const { mutateAsync, isLoading } = useCustomerContact();

  const updateContactData = useUpdateCustomerContact();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      status: [{ id: "LEAD" }],
      type: [{ id: "PERSONAL_MOBILE" }],
    },
  });

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  const contacts = useMemo(() => {
    const contacts = _.get(customerData, "data.doc.contacts", []).reduce(
      (prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = [
          ...(isType === "PHONE"
            ? [...phone, ...(landline ? landline : [])]
            : [...email]),
        ].map(({ status, call_status, ...nRest }) => {
          let designation =
            referenceData && referenceData["recipient_type"]
              ? referenceData["recipient_type"].find(
                  (rf) => rf?.id === curr?.designation
                )
              : "";

          return {
            label: `${curr?.first_name} - ${designation?.label}`,
            id: curr?._id,
            recipient_id: nRest?._id,
            call_status,
            status,
            designation: curr?.designation,
            value: nRest?.value,
            first_name: curr?.first_name,
            last_name: curr?.last_name,
          };
        });

        return [...prev, ...nestedContacts];
      },
      []
    );

    if (isType === "PHONE") {
      setValue("type", [
        {
          id: "PERSONAL_MOBILE",
        },
      ]);
    } else {
      setValue("type", [
        {
          id: "PERSONAL_EMAIL",
        },
      ]);
    }

    setValue("designation", [
      {
        id: "primary_contact",
      },
    ]);

    return contacts;
  }, [customerData?.data, customerId, isCount, isType]);

  const contactOptions = useMemo(() => {
    const contactOpt = _.get(customerData, "data.doc.contacts", []).map((e) => {
      let designation =
        referenceData && referenceData["recipient_type"]
          ? referenceData["recipient_type"].find(
              (rf) => rf?.id === e?.designation
            )
          : "";
      return {
        label: `${e?.first_name} - ${designation?.label}`,
        id: e?._id,
      };
    });
    setValue("contact", [
      {
        label: _.get(contactOpt, "[0].label", ""),
        id: _.get(contactOpt, "[0].id", ""),
      },
    ]);
    return contactOpt;
  }, [customerData?.data]);

  const { fieldData, leadNumber, phoneNumber } = useMemo(() => {
    let fieldData;
    if (isTextBox?.length) {
      fieldData =
        isType === "PHONE"
          ? isTextBox.split(/\r?\n|\r|\n|;|,|:+/g)
          : isTextBox.split(/\r?\n|\r|\n|;|,|:|\s+/g);
    }

    if (fieldData?.length && isType === "PHONE") {
      let removeSpecialCharacters = fieldData.map((e) =>
        e.replace(/[^0-9+]|\s/g, "")
      );
      let splitPhoneCode = removeSpecialCharacters.flatMap((e) =>
        findPhoneNumbersInText(e, currentDefaultFormatDetails?.country)
      );
      fieldData = [...splitPhoneCode];
    } else {
      let validEmail = fieldData?.map((e) => {
        if (e.match(constactEmailRegex)) {
          return e;
        }
      });
      fieldData = validEmail?.filter((ele) => {
        return ele !== undefined;
      });
    }

    let leadNumber = [];
    let phoneNumber = [];
    if (fieldData && fieldData.length && isType === "PHONE") {
      let importedNumber = fieldData.map((e) => {
        return {
          value: e?.number?.nationalNumber,
          code: `+${e?.number?.countryCallingCode}`,
        };
      });
      let contactNumber = contacts?.map((e) => e?.value);
      importedNumber.map((e) => {
        if (!contactNumber.includes(e.value)) {
          leadNumber.push(e.value);
          phoneNumber.push(e);
        }
      });
    } else {
      let contactNumber = contacts?.map((e) => e?.value);
      fieldData?.map((e) => {
        if (!contactNumber.includes(e)) {
          leadNumber.push(e);
        }
      });
    }

    if (leadNumber && leadNumber.length) {
      setValue("phone_list", [...new Set(leadNumber)].join("\n").toString());
    }

    return { fieldData, leadNumber, phoneNumber };
  }, [isCount]);

  // const hasValue = (obj) => {
  //   return obj && typeof obj === "object"
  //     ? Object.values(obj).some((value) => value !== null && value !== "")
  //     : false;
  // };

  // const cleanedObj = Object.fromEntries(
  //   Object.entries(obj).filter(([key, value]) => value !== null && value !== "")
  // );

  const onSubmit = async (data) => {
    let findFirstNameMatch = _.get(customerData, "data.doc.contacts", []).find(
      (e) => e.first_name === data?.first_name?.trim()
    );

    let findLastNameMatch = _.get(customerData, "data.doc.contacts", []).find(
      (e) => e.last_name === data?.last_name?.trim()
    );

    if (
      findFirstNameMatch?.first_name &&
      findLastNameMatch?.last_name &&
      data &&
      data.contact &&
      data.contact[0] &&
      data.contact[0].id
    ) {
      // let findContact = _.get(customerData, "data.doc.contacts", []).find(
      //   (e) => e._id === data.contact[0].id
      // );
      let splitContact = data?.phone_list?.split(/\r?\n|\r|\n|;|,|:|\s+/g);
      let {
        phone,
        address,
        email,
        landline,
        primary_invoices,
        is_valid_whatsapp,
        created_file_id,
        esclation_invoices,
        is_default,
        is_deleted,
        is_valid_address,
        is_valid_call,
        is_valid_email,
        is_valid_landline,
        is_valid_phone,
        is_valid_sms,
        updated_file_id,
        _id,
        cid,
        custom_field,
        vac,
        ...rest
      } = findFirstNameMatch;

      let createContactData;
      if (isType === "PHONE") {
        createContactData = phoneNumber.map((ph) => {
          return {
            type: getSelectValues(data.type),
            status: getSelectValues(data.status),
            value: ph?.value,
            ...(isType === "PHONE" ? { country_code: ph?.code } : {}),
          };
        });
      } else {
        createContactData = splitContact.map((ph) => {
          return {
            type: getSelectValues(data.type),
            status: getSelectValues(data.status),
            value: ph,
            ...(isType === "PHONE" ? { country_code: "+1" } : {}),
          };
        });
      }

      let phoneDtl = phone?.map((ld) => {
        let { _id, ...rest } = ld;
        return {
          ...rest,
        };
      });

      let emailDtl = email?.map((ld) => {
        let { _id, ...rest } = ld;
        return {
          ...rest,
        };
      });

      let values = {
        ...rest,
        ...(isType === "PHONE"
          ? { phone: [...phoneDtl, ...createContactData] }
          : { phone: [...phoneDtl] }),
        ...(isType === "EMAIL"
          ? { email: [...emailDtl, ...createContactData] }
          : { email: [...emailDtl] }),
        landline: landline.map((ld) => {
          let { _id, ...rest } = ld;
          return {
            ...rest,
          };
        }),

        address: address.map((ld) => {
          let { _id, ...rest } = ld;
          return {
            ...rest,
          };
        }),

        ...(_.isNull(custom_field) ||
        _.isUndefined(custom_field) ||
        _.isEmpty(custom_field)
          ? {}
          : {
              custom_field: Object.fromEntries(
                Object.entries(custom_field).filter(
                  ([key, value]) => value !== null && value !== ""
                )
              ),
            }),
        ...(vac ? {} : {}),
        // ...(hasValue(custom_field)
        //   ? { custom_field: { ...custom_field } }
        //   : {}),
      };

      await updateContactData.mutateAsync(
        {
          values,
          customerId: customerId,
          organization: currentOrganization,
          contactId: _.get(findFirstNameMatch, "_id", null),
        },
        {
          onError: (error) => {
            if (error.response) {
              let { data } = error.response;
              toast.error(data.message);
            }
          },
          onSuccess: (data) => {
            queryClient.invalidateQueries({
              queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
            });
            reset();
            closeDrawer();
          },
        }
      );
    } else if (
      data &&
      data.contact &&
      data.contact[0] &&
      data.contact[0].id &&
      !data?.first_name &&
      !data?.last_name
    ) {
      let findContact = _.get(customerData, "data.doc.contacts", []).find(
        (e) => e._id === data.contact[0].id
      );

      let splitContact = data?.phone_list?.split(/\r?\n|\r|\n|;|,|:|\s+/g);
      let {
        phone,
        address,
        email,
        landline,
        primary_invoices,
        is_valid_whatsapp,
        created_file_id,
        esclation_invoices,
        is_default,
        is_deleted,
        is_valid_address,
        is_valid_call,
        is_valid_email,
        is_valid_landline,
        is_valid_phone,
        is_valid_sms,
        updated_file_id,
        _id,
        cid,
        custom_field,
        vac,
        ...rest
      } = findContact;

      let createContactData;
      if (isType === "PHONE") {
        createContactData = phoneNumber?.map((ph) => {
          return {
            type: getSelectValues(data.type),
            status: getSelectValues(data.status),
            value: ph.value,
            ...(isType === "PHONE" ? { country_code: ph.code } : {}),
          };
        });
      } else {
        createContactData = splitContact?.map((ph) => {
          return {
            type: getSelectValues(data.type),
            status: getSelectValues(data.status),
            value: ph,
            ...(isType === "PHONE" ? { country_code: "+1" } : {}),
          };
        });
      }

      let phoneDtl = phone?.map((ld) => {
        let { _id, ...rest } = ld;
        return {
          ...rest,
        };
      });

      let emailDtl = email?.map((ld) => {
        let { _id, ...rest } = ld;
        return {
          ...rest,
        };
      });

      let values = {
        ...rest,
        ...(isType === "PHONE"
          ? { phone: [...phoneDtl, ...createContactData] }
          : { phone: [...phoneDtl] }),
        ...(isType === "EMAIL"
          ? { email: [...emailDtl, ...createContactData] }
          : { email: [...emailDtl] }),
        landline: landline.map((ld) => {
          let { _id, ...rest } = ld;
          return {
            ...rest,
          };
        }),

        ...(_.isNull(custom_field) ||
        _.isUndefined(custom_field) ||
        _.isEmpty(custom_field)
          ? {}
          : {
              custom_field: Object.fromEntries(
                Object.entries(custom_field).filter(
                  ([key, value]) => value !== null && value !== ""
                )
              ),
            }),

        address: address.map((ld) => {
          let { _id, ...rest } = ld;
          return {
            ...rest,
          };
        }),
      };

      await updateContactData.mutateAsync(
        {
          values,
          customerId: customerId,
          organization: currentOrganization,
          contactId: _.get(data, "contact[0].id", null),
        },
        {
          onError: (error) => {
            if (error.response) {
              let { data } = error.response;
              toast.error(data.message);
            }
          },
          onSuccess: (data) => {
            queryClient.invalidateQueries({
              queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
            });
            reset();
            closeDrawer();
          },
        }
      );
    } else {
      let splitContact = data?.phone_list?.split(/\r?\n|\r|\n|;|,|:|\s+/g);
      let createContactData;
      if (isType === "PHONE") {
        createContactData = phoneNumber.map((ph) => {
          return {
            type: getSelectValues(data.type),
            status: getSelectValues(data.status),
            value: ph.value,
            ...(isType === "PHONE" ? { country_code: ph.code } : {}),
          };
        });
      } else {
        createContactData = splitContact.map((ph) => {
          return {
            type: getSelectValues(data.type),
            status: getSelectValues(data.status),
            value: ph,
            ...(isType === "PHONE" ? { country_code: "+1" } : {}),
          };
        });
      }

      let values = {
        first_name: data.first_name,
        last_name: data.last_name,
        designation: getSelectValues(data.designation),
        relation: getSelectValues(data.relation),
        ...(isType === "PHONE"
          ? { phone: [...createContactData] }
          : { email: [...createContactData] }),
      };

      await mutateAsync(
        {
          values,
          customerId: customerId,
          organization: currentOrganization,
        },
        {
          onError: (error) => {
            if (error.response) {
              let { data } = error.response;
              toast.error(data.message);
            }
          },
          onSuccess: (data) => {
            queryClient.invalidateQueries({
              queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
            });
            reset();
            closeDrawer();
          },
        }
      );
    }
  };

  return (
    <div style={{ width: "500px" }}>
      <Typography type="h3">Bulk Import</Typography>
      {/* Phone */}

      <RadioGroup
        value={isType}
        onChange={(e) => setIsType(e.currentTarget.value)}
        align={ALIGN.horizontal}
      >
        <Radio value="PHONE">Phone</Radio>
        <Radio value="EMAIL">Email</Radio>
      </RadioGroup>

      <Typography type={"h4"} style={{ marginTop: "5px" }}>
        {isType === "PHONE" ? (
          <FormattedMessage id="paste_phone_number_below">
            paste_phone_number_below
          </FormattedMessage>
        ) : (
          <FormattedMessage id="paste_email_below">
            paste_email_below
          </FormattedMessage>
        )}
      </Typography>

      <div style={{ marginTop: "15px" }}>
        <TextArea
          name="phone_number"
          value={isTextBox}
          autoFocus
          // label={intl.formatMessage({
          //   id: "paste_phone_number",
          // })}
          // placeholder={intl.formatMessage({
          //   id: "paste_phone_number",
          // })}
          onChange={(e) => setIsTextBox(e.target.value)}
        />
      </div>

      <div style={{ textAlign: "end", marginBottom: "15px" }}>
        <TextButton
          onClick={() => {
            setIsCount(isCount + 1);
          }}
          size="mini"
          type="button"
        >
          Process
        </TextButton>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography type={"h4"} style={{ marginBottom: "15px" }}>
          {isType === "PHONE" ? (
            <FormattedMessage id="import_new_numbers">
              import_new_numbers
            </FormattedMessage>
          ) : (
            <FormattedMessage id="import_new_emails">
              import_new_emails
            </FormattedMessage>
          )}
        </Typography>
        <div style={{ display: "flex", gap: "15px" }}>
          <div style={{ flex: "1" }}>
            <Controller
              name="phone_list"
              control={control}
              render={({ field }) => (
                <>
                  <TextArea
                    {...field}
                    fullWidth
                    // requiredAstric={true}
                    // readOnly
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    // placeholder={intl.formatMessage({
                    //   id: "new_numbers",
                    // })}
                    value={field.value}
                    onChange={(e) => field.onChange("")}
                    height={"220px"}
                  />
                </>
              )}
            />
          </div>
          <div style={{ flex: "1" }}>
            <Controller
              control={control}
              name="type"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  clearable={false}
                  size={SIZE.mini}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  label={intl.formatMessage({
                    id: "type_review",
                  })}
                  placeholder={intl.formatMessage({
                    id: "type_review",
                  })}
                  options={
                    isType === "PHONE"
                      ? referenceData["contact_phone_type"].sort((a, b) =>
                          a.label.localeCompare(b.label)
                        )
                      : referenceData["contact_email_type"].sort((a, b) =>
                          a.label.localeCompare(b.label)
                        )
                  }
                />
              )}
            />
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  clearable={false}
                  size={SIZE.mini}
                  value={field.value}
                  label={intl.formatMessage({
                    id: "status",
                  })}
                  placeholder={intl.formatMessage({
                    id: "status",
                  })}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={referenceData["contact_status"]}
                />
              )}
            />
            <Controller
              control={control}
              name="contact"
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  // clearable={false}
                  size={SIZE.mini}
                  value={field.value}
                  label={intl.formatMessage({
                    id: "select_contact",
                  })}
                  placeholder={intl.formatMessage({
                    id: "select_contact",
                  })}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={[...contactOptions]}
                />
              )}
            />

            <div className="border-line-contact-container">
              <div className="border-line-contact"></div>
              <div>
                {" "}
                <Typography
                  type="p"
                  subType="small"
                  style={{ color: "#ADADAD" }}
                >
                  or
                </Typography>
              </div>
              <div className="border-line-contact"></div>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Controller
                control={control}
                name="first_name"
                render={({ field }) => (
                  <TextBox
                    {...field}
                    // disabled={contact?.length}
                    fullWidth
                    name={field.name}
                    size={"mini"}
                    label={intl.formatMessage({
                      id: "first_name",
                    })}
                    placeholder={intl.formatMessage({
                      id: "first_name",
                    })}
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name="last_name"
                render={({ field }) => (
                  <TextBox
                    {...field}
                    // disabled={contact?.length}
                    fullWidth
                    name={field.name}
                    size={"mini"}
                    label={intl.formatMessage({
                      id: "last_name",
                    })}
                    placeholder={intl.formatMessage({
                      id: "last_name",
                    })}
                    value={field.value}
                  />
                )}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Controller
                control={control}
                name="designation"
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // clearable={false}
                    // disabled={contact?.length}
                    size={SIZE.mini}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    label={intl.formatMessage({
                      id: `role`,
                    })}
                    placeholder={intl.formatMessage({
                      id: "role",
                    })}
                    options={referenceData && referenceData["recipient_type"]}
                  />
                )}
              />
              <Controller
                control={control}
                name="relation"
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // clearable={false}
                    size={SIZE.mini}
                    value={field.value}
                    // disabled={contact?.length}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    label={intl.formatMessage({
                      id: `relation`,
                    })}
                    placeholder={intl.formatMessage({
                      id: "relation",
                    })}
                    creatable={creatablePermission ? true : false}
                    options={
                      referenceData &&
                      referenceData["customer_contact_relation_list"]
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div style={{ textAlign: "end", marginBottom: "15px" }}>
          <TextButton
            isLoading={updateContactData.isLoading || isLoading}
            disabled={updateContactData.isLoading || isLoading}
            size="mini"
          >
            Add
          </TextButton>
        </div>
      </form>
    </div>
  );
};

export default BulkPhoneImport;
