import { useContext } from "react";
import { MaxyfiContext } from "../providers/MaxyfiProvider";
import moment from "moment";
import { useNumericFormat } from "react-number-format";

const useFormat = () => {
  const {
    currentDefaultFormatDetails,
    referenceData,
    currencyGroupStyle,
    currencySeparator,
    currencyDecimalSeparator,
  } = useContext(MaxyfiContext);

  const numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });
  const customDateFormat = "ddd, MMM D, YYYY [at] hh:mmA";
  return {
    dateTime: ({
      isOrg = true,
      includeTime = false,
      month = false,
      value = 0,
      customFormat,
      customCase,
    }) => {
      let dateInst = isOrg
        ? moment.tz(value, currentDefaultFormatDetails.time_zone)
        : moment(value);

      if (customFormat) {
        dateInst = dateInst.format(customFormat);
      } else if (customCase === "customDateFormat") {
        dateInst = dateInst.format(customDateFormat);
      } else {
        if (month) {
          dateInst = dateInst.format("MMMM D, YYYY");
        } else {
          dateInst = dateInst.format(currentDefaultFormatDetails.date_format);
        }
      }

      return `${dateInst}`;
    },

    date: ({ isOrg = true, includeTime = false, value = 0, customFormat }) => {
      let dateInst = isOrg
        ? moment.tz(value, currentDefaultFormatDetails.time_zone)
        : moment(value);

      let formatDate;
      if (customFormat) {
        formatDate = dateInst.format(customFormat);
      } else {
        formatDate = dateInst.format(currentDefaultFormatDetails.date_format);
      }
      // const timeInst = moment
      //   .tz(value, currentDefaultFormatDetails.time_zone)
      //   .format("hh:mm A");
      // return `${dateInst} ${includeTime ? timeInst : ""}`;

      return `${formatDate}${
        includeTime ? ` ${dateInst.format("hh:mm A")}` : ""
      }`;
    },
    rd: ({ id = "", name }) => {
      const rd = name && referenceData[name] ? referenceData[name] : null;
      const reference =
        rd && Array.isArray(rd) ? rd.find((e) => e.id === id) : null;

      return reference && reference.label ? reference.label : "";
    },
    currency: ({ amount = 0 }) => {
      const amountStr =
        typeof amount === "string" || typeof amount === "number"
          ? String(amount)
          : "0";
      return numberFormat.format(amountStr);
    },
  };
};

export default useFormat;
