import React, { useContext, useEffect, useMemo, useState } from "react";
import advocate from "./../../../../assets/img/advocate.png";
import { Icon, RDText, Typography } from "../../../../components_v2";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { Input } from "baseui/input";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { updateCounsel, customerInvoiceOverview } from "../../../../services";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import moment from "moment";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { KIND, TextButton } from "../../../../components/TextButton";
import CounselForm from "./CounselForm";
import _ from "lodash";
import AddedCounselCard from "./AddedCounselCard";
import queryClient from "../../../../providers/queryClient";
import { LEGAL_ACTION_INFO_ } from "../../../../constants";
import * as TextBoxSize from "./../../../../components/TextBox";
import { toast } from "react-toastify";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import { ADD_REMOVED_COUNSEL_LEGAL } from "../../../../providers/RBACProvider/permissionList";

const ADD = "ADD";
const EDIT = "EDIT";

const AllowAccessPopOver = ({ title, setallowacess, allowAccess = [] }) => {
  const { currentOrganization, currentDefaultFormatDetails, users } =
    useContext(MaxyfiContext);

  const orgUsers = useMemo(() => {
    return users?.filter((i) => i.organization?.includes(currentOrganization));
  }, []);

  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    setSelectedUsers(allowAccess);
  }, [allowAccess]);

  const handleCheckboxChange = (e, item) => {
    if (e.target.checked) {
      setSelectedUsers([...selectedUsers, item]);
    } else {
      setSelectedUsers(selectedUsers.filter((e) => e !== item));
    }
  };

  const handleAllowSubmit = () => {
    setallowacess(selectedUsers);
  };

  return (
    <StatefulPopover
      placement={PLACEMENT.bottom}
      overrides={{
        Body: {
          style: ({ $theme }) => ({
            border: "1px solid #CDCED9",
            borderRadius: "5px",
            backgroundColor: "#FFFFFF",
          }),
        },
        Inner: {
          style: ({ $theme }) => ({
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
          }),
        },
      }}
      onClose={() => {
        setSelectedUsers(allowAccess);
      }}
      content={({ close }) => (
        <div className="list-councel-allow-accses-container">
          <div className="concel_selection_list_select">
            {orgUsers.map((item, index) => {
              return (
                <div style={{ display: "flex" }}>
                  <CheckBoxBaseweb
                    checked={selectedUsers.some((allow) => allow === item.id)}
                    onChange={(e) => {
                      handleCheckboxChange(e, item.id);
                    }}
                  ></CheckBoxBaseweb>

                  <Typography type="p" className="regular">
                    {item.displayName}
                  </Typography>
                </div>
              );
            })}
            <div style={{ border: "1px solid #EEEEEE" }}></div>
          </div>

          <div className="debt-legal-invoices_buttons">
            <TextButton
              size="mini"
              kind={KIND.tertiary}
              onClick={() => {
                close();
                setSelectedUsers(allowAccess);
              }}
            >
              Cancel
            </TextButton>
            <TextButton
              size="mini"
              kind={KIND.primary}
              onClick={() => {
                handleAllowSubmit();
                close();
              }}
            >
              Allow
            </TextButton>
          </div>
        </div>
      )}
      returnFocus
      autoFocus
    >
      <div className="list-council-card_select">
        <Typography
          type="p"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {allowAccess.length > 0
            ? allowAccess
                .map((e) => {
                  const user = orgUsers.find((usr) => usr.id === e);
                  return user ? user.displayName : "";
                })
                .toString()
            : "Allow Access To"}
        </Typography>
        <Icon icon="chevron_down" size={14} color="#516BEB" />
      </div>
    </StatefulPopover>
  );
};

const SelectCounselCard = ({
  isOpposingCounsel = false,
  addCounsel,
  setCounsel,
}) => {
  const [searchList, setSearchList] = useState("");
  const { referenceData } = useContext(MaxyfiContext);

  const legalAttorneyList = useMemo(() => {
    const search = new RegExp(searchList, "i");
    return referenceData["legal_attorney_list"]
      .sort((a, b) => a?.id?.localeCompare(b?.id))
      .filter(({ label }) =>
        searchList && searchList.length > 0 ? search.test(label) : true
      );
  }, [referenceData, searchList]);

  return (
    <div className="list-council-card-container">
      <img src={advocate} alt="advocate" width={24} height={24} />
      <div className="list-council-card-subContainer">
        <Typography type="h3" subType="medium">
          {isOpposingCounsel ? "Opposing" : "Our"} Counsel
        </Typography>
        <Typography type="p" className="text-secondary" subType="regular">
          Select Counsel from below or Click “Add” to add new
        </Typography>
        <div className="list-council-card-select-container">
          <StatefulPopover
            placement={PLACEMENT.bottom}
            overrides={{
              Body: {
                style: ({ $theme }) => ({
                  border: "1px solid #CDCED9",
                  borderRadius: "5px",
                  backgroundColor: "#FFFFFF",
                }),
              },
              Inner: {
                style: ({ $theme }) => ({
                  backgroundColor: "#FFFFFF",
                  borderRadius: "5px",
                }),
              },
            }}
            content={() => (
              <div className="debt-legal-invoice-container">
                <div>
                  <Input
                    startEnhancer={
                      <Icon icon="search_outline" size={16} color="#ADADAD" />
                    }
                    onChange={(e) => {
                      let values = e.target.value;
                      setSearchList(values);
                    }}
                    overrides={{
                      Root: {
                        style: ({ $isFocused }) => ({
                          borderRadius: "5px",
                          borderWidth: "1px",
                          borderColor: $isFocused ? "#516BEB" : "#CDCED9",
                          backgroundColor: "#ffffff",
                          paddingRight: "0px",
                          padding: "0px",
                          paddingLeft: "0px",
                        }),
                      },
                      InputContainer: {
                        style: ({ $isFocused }) => ({
                          backgroundColor: "#ffffff",
                          paddingLeft: "5px",
                          "::placeholder": {
                            color: $isFocused ? "#cbd5e0" : "transparent",
                            transition: "color 0.4s ease",
                          },
                        }),
                      },
                      StartEnhancer: {
                        style: ({ $theme }) => ({
                          paddingLeft: "5px",
                          paddingRight: "0px",
                          backgroundColor: "#FFFFFF",
                          display: "flex",
                        }),
                      },
                    }}
                    size="mini"
                    placeholder="Search by Attorney Name"
                  />
                </div>

                <div className="debt-legal-invoice_check">
                  {legalAttorneyList.map((item) => {
                    return (
                      <div
                        className="councel_selection_list"
                        key={item?.id}
                        onClick={() => setCounsel(item?.id)}
                      >
                        <Typography type="p" className="regular">
                          {item.label}
                        </Typography>
                      </div>
                    );
                  })}
                  <div className="debt-legal-invoice-line"></div>
                </div>
              </div>
            )}
            returnFocus
            autoFocus
          >
            <div>
              <RBACWrapper role={ADD_REMOVED_COUNSEL_LEGAL} type="PERMISSION">
                <div className="list-council-card_select">
                  <Typography type="p" subType="small">
                    Select {isOpposingCounsel ? "Opposing" : "Our"} Counsel
                  </Typography>
                  <Icon icon="chevron_down" size={14} color="#516BEB" />
                </div>
              </RBACWrapper>
            </div>
          </StatefulPopover>

          <RBACWrapper role={ADD_REMOVED_COUNSEL_LEGAL} type="PERMISSION">
            <Typography type="p" className="text-secondary" subType="small">
              or
            </Typography>{" "}
            <TextButton
              kind="tertiary"
              size="mini"
              startEnhancer={<Icon icon="plus" size={16} />}
              onClick={addCounsel}
            >
              Add {isOpposingCounsel ? "Opposing" : "Our"} Counsel
            </TextButton>
          </RBACWrapper>
        </div>
      </div>
    </div>
  );
};

const initialState = {
  counselId: null,
  opposingCounselId: null,
  allowAccess: [],
};

const initialFormState = {
  active: false,
  type: ADD,
  isOpposingCounsel: false,
  defaultValues: {},
};

const ListingCouncil = () => {
  const { customerId } = useParams();
  const { currentOrganization, referenceData, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const [state, setState] = useState(initialState);
  const [formState, setFormState] = useState(initialFormState);

  const fetchInfo = () => {
    const legalInfo = queryClient.getQueryData(
      `${LEGAL_ACTION_INFO_}${customerId}`
    );

    const counselId = _.get(legalInfo, "data.doc.atn.oatn");
    const opposingCounselId = _.get(legalInfo, "data.doc.atn.opatn");
    const allowAccess = _.get(legalInfo, "data.doc.atn.ala", []);

    setState({ ...initialState, counselId, opposingCounselId, allowAccess });
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleAddCounselClick = (isOpposingCounsel = false) => {
    setFormState({ ...initialFormState, active: true, isOpposingCounsel });
  };

  const handleResetForm = () => {
    setFormState(initialFormState);
  };

  const handleEditCounselClick = (counselId, isOpposingCounsel = false) => {
    const counsel = _.get(currentDefaultFormatDetails, "atrl", []).find(
      (e) => e._id === counselId
    );

    if (counsel) {
      setFormState({
        ...initialFormState,
        isOpposingCounsel,
        active: true,
        type: EDIT,
        defaultValues: {
          id: counselId,
          ...counsel,
        },
      });
    }
  };

  const updateCounselMutation = useMutation(
    (data) =>
      updateCounsel({
        ...data,
        customerId: customerId,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`${LEGAL_ACTION_INFO_}${customerId}`);
      },
    }
  );

  return (
    <div className="list-councel-container">
      {formState.active ? (
        <CounselForm {...formState} handleResetForm={handleResetForm} />
      ) : (
        <>
          {state.counselId ? (
            <AddedCounselCard
              counselId={state.counselId}
              removeCounsel={() => {
                setState({ ...state, counselId: null });
              }}
              editCounsel={handleEditCounselClick}
            />
          ) : (
            <SelectCounselCard
              addCounsel={() => handleAddCounselClick()}
              setCounsel={(counselId) => {
                setState({ ...state, counselId: counselId });
              }}
            />
          )}
          <AllowAccessPopOver
            allowAccess={state.allowAccess}
            setallowacess={(allowAccess) => setState({ ...state, allowAccess })}
          />
          {state.opposingCounselId ? (
            <AddedCounselCard
              counselId={state.opposingCounselId}
              removeCounsel={() => {
                setState({ ...state, opposingCounselId: null });
              }}
              editCounsel={handleEditCounselClick}
            />
          ) : (
            <SelectCounselCard
              isOpposingCounsel={true}
              addCounsel={() => handleAddCounselClick(true)}
              setCounsel={(opposingCounselId) =>
                setState({ ...state, opposingCounselId: opposingCounselId })
              }
            />
          )}
          <div className="row gap-10" style={{ justifyContent: "flex-end" }}>
            <TextButton
              kind={KIND.tertiary}
              size={TextBoxSize.SIZE.mini}
              type="button"
              onClick={() => {
                fetchInfo();
              }}
            >
              Cancel
            </TextButton>
            <TextButton
              type="submit"
              kind={KIND.primary}
              size={TextBoxSize.SIZE.mini}
              onClick={async () => {
                await updateCounselMutation.mutateAsync({
                  ...(state.counselId ? { our_counsel: state.counselId } : {}),
                  ...(state.opposingCounselId
                    ? { opp_counsel: state.opposingCounselId }
                    : {}),
                  allow_access: state.allowAccess,
                });
              }}
              disabled={updateCounselMutation.isLoading}
              isLoading={updateCounselMutation.isLoading}
            >
              Save
            </TextButton>
          </div>
        </>
      )}
    </div>
  );
};

export default ListingCouncil;
