import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { Input, SIZE } from "baseui/input";
import { Search } from "baseui/icon";
import { IconButton } from "../../../components/IconButton";
import { Label2 } from "baseui/typography";
import { TextButton, KIND } from "../../../components/TextButton";
import PlusOne from "../../../assets/img/svg/Plus";
import Descending from "../../../assets/img/svg/Descending";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import columnMapper from "../../../utils/mapper";
import { invoicePaymentForm } from "../../../redux/invoiceOverview/action";
import {
  getActionData,
  isOpenModal,
  tabMotion,
} from "../../../redux/customerOverview/action";

// Table
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { COLUMNS } from "../../CustomerOverView/COTabContainers/colums";
import PaginationWraper from "../../../components/Pagination/Pagination";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getGridStructure, getPaymentDatas } from "../../../services";
// svg
import Plus from "../../../assets/img/svg/Upload/Plus";
import DragDrop from "../../../assets/img/svg/DragDrop";
import SortDown from "../../../assets/img/svg/SortDown";
import SortUp from "../../../assets/img/svg/SortUp";
import TagTick from "../../../assets/img/svg/CustomerOverViewIcon/TagTick";
import { LabelLarge, ParagraphLarge } from "baseui/typography";

import {
  createPaymentDetailsInvoiceOverview,
  getInvoicePayment,
  postPaymentDetailsInvoiceOverview,
} from "../../../services/invoiceOverview";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import Loader from "../../../components/Loader";
import { createPaymentDetailsCustomerOverview } from "../../../services";
const InvoicePayments = ({ getParams }) => {
  const { id } = useParams();
  let ref = useRef();
  const [width, setWidth] = useState(0);

  let { referenceData, currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  const [columnData, setColumnData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  let findingUser = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );
  const initialData = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceDetails
  );

  const isLoading = useSelector(
    (state) => state.invoiceOverviewReducer.isLoading
  );

  let particularCustomer = initialData && initialData.customer_id;

  // Table
  const dispatch = useDispatch();

  let reduxData = useSelector((state) => state.customerSummary.tableState);

  useEffect(() => {
    const userListApi = async () => {
      let userListVal = await getGridStructure({
        organization: currentOrganization,
        menu: "customer_payment",
      });

      setColumnData(userListVal.data.doc.table);
    };
    userListApi();
  }, []);

  useEffect(() => {
    const getPaymentData = async () => {
      let data = await getInvoicePayment({
        // "allocated_to.invoice_number": "INVT621-45435458",
        invoicePaymentId: getParams,
        organization: currentOrganization,
      });

      setTableData(data.data.docs);
    };
    particularCustomer && getPaymentData();
  }, [particularCustomer]);

  // useEffect(() => {
  //   const userListApi = async () => {
  //     let userListVal = await getGridStructure({
  //       organization: currentOrganization,
  //       menu: "customer_payment",
  //     });
  //     setColumnData(userListVal.data.doc.table);
  //   };
  //   userListApi();
  // }, []);

  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
    }
  }, [ref]);

  const columns = useMemo(() => {
    let { columns } = columnMapper({
      columns: columnData,
      isExpanded: false,
      isExpandedHeader: false,
      isSelection: false,
      width: width,
      isEnableSort: true,
    });
    return columns;
  }, [columnData]);
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    state: { pageIndex, pageSize, sortBy, filters },
    prepareRow,
    setHiddenColumns,
    setColumnOrder,
    allColumns,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      // data: tabledata,
      data,

      // data,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );
  if (isLoading) {
    return (
      <div className="customer-profile-card">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(5,1fr)",
          gridTemplateRows: "repeat(12,1fr)",
          height: "100%",
        }}
      >
        {currentDefaultFormatDetails.is_add_payment && (
          <div
            className="cp-payment__filter"
            style={{ gridColumn: "1/6", gridRow: "1/2" }}
          >
            <div className="cp-payment-content" style={{ paddingRight: "0px" }}>
              <div className="cp-add-payment__btn">
                <TextButton
                  kind={KIND.primary}
                  size={SIZE.compact}
                  onClick={() => {
                    navigate(`/customers/${particularCustomer}`, {
                      replace: true,
                    });
                    dispatch(getActionData("payments"));
                    dispatch(isOpenModal(true));
                    dispatch(tabMotion(2));
                  }}
                  fullWidth
                >
                  Add Payment
                </TextButton>
              </div>
            </div>
          </div>
        )}

        <div
          className="customer-profile-tab__table"
          style={{ gridColumn: "1/6", gridRow: "2/13", overflowY: "unset" }}
        >
          <div className="cp-tab__table-wrapper" {...getTableProps()} ref={ref}>
            <div className="cp-tab__table-header">
              {headerGroups.map((headerGroup) => (
                <>
                  <div
                    className="cp-tab__table-header-wrapper"
                    style={{
                      backgroundColor: "#F9F9F9",
                      border: "1px solid #CDCED9",
                      display: "flex",
                      flex: "1 0 auto",
                      minWidth: "0px",
                      // borderStartEndRadius: "5px",
                    }}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    <div className="cp-tab__table-header-content">
                      {headerGroup.headers.map((column, index) => (
                        <div
                          className="cp-tab__table-header-cell-content"
                          style={{
                            boxSizing: "border-box",
                            flex: "150 0 auto",
                            minWidth: "0px",
                            width: "150px",
                            position: "relative",
                          }}
                        >
                          <div
                            className="cp-tab__table-header-cell"
                            {...column.getHeaderProps()}
                          >
                            {column.render("Header")}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ))}
            </div>
            {data && data.length === 0 ? (
              <p
                style={{
                  paddingTop: "10px",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                No Records Found
              </p>
            ) : (
              <>
                <div className="cp-tab__table-body" {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    const rowProps = row.getRowProps();
                    return (
                      <React.Fragment key={rowProps.key}>
                        <div
                          className="cp-tab__table-body-content"
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <div
                                className="cp-tab__table-body-cell"
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoicePayments;
