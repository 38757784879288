import React from "react";

const TagFile = () => {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 8.4H6.75V9.6H2.25V8.4ZM2.25 6H6.75V7.2H2.25V6ZM5.625 0H1.125C0.50625 0 0 0.54 0 1.2V10.8C0 11.46 0.500625 12 1.11937 12H7.875C8.49375 12 9 11.46 9 10.8V3.6L5.625 0ZM7.875 10.8H1.125V1.2H5.0625V4.2H7.875V10.8Z"
        fill="#333860"
      />
    </svg>
  );
};

export default TagFile;
