import getSelectValues from "../utils/getSelectValues";

export default function getActionReminder(formData, options = {}) {
  const {
    is_hold = true,
    is_consent_enabled = true,
    is_calendar = true,
    is_followup = true,
    is_confirmation = true,
    is_incrementor = true,
    is_comment = true,
    is_status = true,
  } = options;

  const {
    is_hold_workflow = false,
    is_send_confirmation_message = false,
    is_send_action_follow_up = false,
    is_enable_calendar_alert = false,
    confirmation_message_type,
    confirmation_message_template_id,
    action_follow_up_type,
    action_follow_up_template_id,
    calendar_alert_time,
    is_increment_follow_up = false,
    is_consent = false,
    comment,
    status,
  } = formData;

  let values = {
    ...(is_incrementor ? { is_increment_follow_up } : {}),
    ...(is_comment ? { comment } : {}),
    ...(is_status ? { status: getSelectValues(status) } : {}),
  };

  if (is_hold) {
    values = {
      ...values,
      is_hold_workflow,
    };
  }

  if (is_calendar) {
    values = {
      ...values,
      is_enable_calendar_alert,
      ...(is_enable_calendar_alert
        ? {
            calendar_alert_time: getSelectValues(calendar_alert_time),
          }
        : {}),
    };
  }

  if (is_consent_enabled) {
    values = {
      ...values,
      is_consent,
    };
  }

  if (is_confirmation) {
    values = {
      ...values,
      is_send_confirmation_message,
      ...(is_send_confirmation_message
        ? {
            confirmation_message_type: getSelectValues(
              confirmation_message_type
            ),
            confirmation_message_template_id: getSelectValues(
              confirmation_message_template_id
            ),
          }
        : {}),
    };
  }

  if (is_followup) {
    values = {
      ...values,
      is_send_action_follow_up,
      ...(is_send_action_follow_up
        ? {
            action_follow_up_type: getSelectValues(action_follow_up_type),
            action_follow_up_template_id: getSelectValues(
              action_follow_up_template_id
            ),
          }
        : {}),
    };
  }

  return values;
}
