export const MANAGE_INVOICE_STATUS_DRAWER = "MANAGE_INVOICE_STATUS_DRAWER";
export const MANAGE_EDIT_INVIOCE_STATUS = "MANAGE_EDIT_INVIOCE_STATUS";
export const MANAGE_COPY_INVIOCE_STATUS = "MANAGE_COPY_INVIOCE_STATUS";
export const RESET_INVOICE_STATUS = "RESET_INVOICE_STATUS";
export const INVOICE_OVERVIEW_SUBSTATUS_DRAWER =
  "INVOICE_OVERVIEW_SUBSTATUS_DRAWER";

export const SET_INVOICE_STATUS_AUDITSDATE = "SET_INVOICE_STATUS_AUDITSDATE";
export const setDrawerInvoiceStatus = ({
  isEdit,
  isCopy,
  drawerState,
  invoice_status_data,
}) => {
  return {
    type: MANAGE_INVOICE_STATUS_DRAWER,
    payload: {
      isEdit,
      isCopy,
      drawerState,
      invoice_status_data,
    },
  };
};

export const setEditInvoiceStatus = ({
  isEdit,
  isCopy,
  drawerState,
  invoice_status_data,
}) => {
  return {
    type: MANAGE_EDIT_INVIOCE_STATUS,
    payload: {
      isEdit,
      isCopy,
      drawerState,
      invoice_status_data,
    },
  };
};

export const setCopyInvoiceStatus = ({
  isCopy,
  isEdit,
  drawerState,
  invoice_status_data,
}) => {
  return {
    type: MANAGE_EDIT_INVIOCE_STATUS,
    payload: {
      isCopy,
      isEdit,
      drawerState,
      invoice_status_data,
    },
  };
};

export const setInvoiceOverviewStatusDrawer = ({ invoice_status_drawer }) => {
  return {
    type: INVOICE_OVERVIEW_SUBSTATUS_DRAWER,
    payload: { invoice_status_drawer },
  };
};

export const setEditAuditDataInvoice = ({ invoice_audit_edit_data }) => {
  return {
    type: SET_INVOICE_STATUS_AUDITSDATE, // Use the correct action type
    payload: {
      invoice_audit_edit_data,
    },
  };
};

export const resetInvoiceStatus = () => ({
  type: RESET_INVOICE_STATUS,
});
