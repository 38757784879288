import {
  HeadingXLarge,
  ParagraphLarge,
  LabelLarge,
  ParagraphMedium,
  LabelMedium,
  LabelSmall,
} from "baseui/typography";
import React, { useState, useContext, useEffect, useMemo } from "react";
import DMSystem from "../../../DMSystem";
import Email from "../../../../../assets/img/svg/CustomerTemplate/Email";
import Info from "../../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Info";
import InfoTag from "../../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/InfoTag";
import InfoPortal from "../../../../../assets/img/svg/InfoPortal";
import AllActionPopOver from "../AllActionPopOver";
import { isOpenModal } from "../../../../../redux/actions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { TextBox } from "../../../../../components/TextBox";
import { TextButton, SIZE, KIND } from "../../../../../components/TextButton";
import { TextArea } from "../../../../../components/TextArea";
import Sms from "../../../../../assets/img/svg/CustomerOverViewIcon/Sms";
import { SelectBox } from "../../../../../components/SelectBox";
import LeftSmallArrow from "../../../../../assets/img/svg/LeftSmallArrow";
import { COMMUNICATION_TEMPLATE } from "../../../../../constants";
import { useMutation, useQuery } from "react-query";
// import DocFileType from "../../assets/img/svg/DocFileType";
import DocFileType from "../../../../../assets/img/svg/DocFileType";
import {
  getCommunicationTemplateList,
  letterName,
} from "../../../../../services";
import { MaxyfiContext } from "../../../../../providers/MaxyfiProvider";
import { useSelector } from "react-redux";
import getSelectValues from "../../../../../utils/getSelectValues";
import Letter from "../../../../../assets/img/svg/CustomerTemplate/Letter";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import DateRangeSelect from "../../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import EmailAttachment from "../../../../../components/EmailAttachment/EmailAttachment";
import { StatefulPopover } from "baseui/popover";
import { Button } from "baseui/button";
import { useDropzone } from "react-dropzone";
import {
  addEmailInvoiceAttachmentFromDesktop,
  invoiceDriveOpen,
} from "../../../../../redux/invoiceOverview/DMSystemINV/action";
import {
  addEmailAttachmentFromDesktop,
  customerDriveOpen,
} from "../../../../../redux/customerOverview/DMSystem/action";
import { getSubCategory } from "../../../../../services/documentSystem";
import {
  getCustomerContactDetail,
  letterPreviewPost,
  executeAdhocCustomerAction,
} from "../../../../../services";
import moment from "moment";
import {
  addLetterEmailAttachment,
  letterDocumentDrawerState,
  letterDocumentDrawerStateClose,
  letterDocumentDrive,
  onSuccessLetterData,
  updateLetterAttachmentFromDesktop,
} from "../../../../../redux/customerOverview/letter/action";
import { toast } from "react-toastify";
import _, { rest } from "lodash";
import { Spinner } from "baseui/spinner";
import PlusLetter from "../../../../../assets/img/svg/PlusLetter";
import { ADD_LIST_VALUES } from "../../../../../providers/RBACProvider/permissionList";
import { useSearchParams } from "react-router-dom";

const LetterWorkflowAction = ({
  letterAttachmentsData,
  Controller,
  control,
  errors,
  hideWorkflowArea,
  setError,
  clearErrors,
  setValue,
  contacts,
  contactsData,
  recipientsData,
  isAdhocAction = false,
  currentActioDate,
  emailAttached,
  getParams,
  entity,
  removeAttchFile,
  updatingDeskUpload,
  emailOptions,
  watch,
  workFlowData,
}) => {
  const { referenceData, currentOrganization, space, userInfo } =
    useContext(MaxyfiContext);

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getInvParams = getSearchParams.get("inv");

  const { letterDrawerStatus, drawerType } = useSelector(
    (s) => s.letterReducer
  );

  const { customerConatactDetails } = useSelector(
    (s) => s.customerOverviewReducer
  );

  const orgRef = referenceData.organizations.find(
    ({ id }) => id === currentOrganization
  );

  const letterAttachments =
    letterAttachmentsData &&
    letterAttachmentsData.map((e) => {
      return e.fileRef;
    });

  const timeZone = orgRef.time_zone;
  const [settingData, setSettingData] = useState(null);
  const intl = useIntl();
  const DataType = useSelector((s) => s.settingReducer.type);
  const dispatch = useDispatch();

  const [letterNameSelect, setLetterName] = useState([]);

  const { letterAttachment } = useSelector((s) => s.letterReducer);

  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   reset,
  //   watch,
  //   setValue,
  //   formState: { errors, isSubmitting },
  // } = useForm({
  //   defaultValues: {
  //     letter_generated: "system_generator",
  //     comments: "",
  //   },
  // });

  let {
    letter_generated,
    recipients,
    template_id,
    invoice_follow_ups,
    sub_category,
  } = watch();

  const subCategory = useQuery(
    [`sub-category-${getParams}`],
    async () => {
      return await getSubCategory({
        organization: currentOrganization,
        // customerId: resourceId,
      });
    },
    {
      refetchOnMount: true,
      // refetchOnWindowFocus: true,
    }
  );

  const communicationTemplateData = useQuery(
    [
      `${COMMUNICATION_TEMPLATE}-LETTER-${currentOrganization}`,
      {
        filters: {
          disable: false,
          type: "letter",
          sub_type: "letter_content",
          tags: ["INVOICE"],
        },
      },
    ],
    async ({ queryKey }) => {
      let { page, page_size, sortBy, filters, type } = queryKey[1];
      return await getCommunicationTemplateList({
        organization: currentOrganization,
        clientCode: [currentOrganization],
        filters,
        type,
      });
    }
  );

  const fileSize = 10000000;
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 1MB`,
      };
    }
  }

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: {
        "image/jpg": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      validator: uploadLogoValidation,

      onDrop: (acceptedFiles) => {
        let arrObj = acceptedFiles.map((fileDoc) => {
          let { path, type } = fileDoc;

          let splitType = type.split("/");

          return {
            name: path,
            type: splitType[1],
            mimeType: type,
            fileDoc,
          };
        });

        dispatch(addLetterEmailAttachment(arrObj));
      },
    });

  // const executeActionMutation = useMutation(
  //   (formValues) =>
  //     executeAdhocCustomerAction({
  //       ...formValues,
  //       id: getParams,
  //       organization: currentOrganization,
  //       entity: entity,
  //     }),
  //   {
  //     onError: (error, variables, context) => {
  //       if (error.response) {
  //         let { data } = error.response;
  //         let errorData = "";

  //         if (typeof data.error === "object") {
  //           errorData = Object.keys(data.error).map(
  //             (e) => `${e}: ${data.error[e]}`
  //           );
  //           errorData = errorData.toString();
  //         }

  //         toast.error(errorData);
  //       }

  //       //Need to handle if no error response is send from backend
  //     },
  //     onSuccess: (data, variables, context) => {
  //       dispatch(onSuccessLetterData());
  //       setValue("comments", "");
  //       reset();
  //     },
  //   }
  // );

  const postLetterPreview = useMutation(
    (data) => {
      return letterPreviewPost({
        customerId: getParams,
        organization: currentOrganization,
        ...data,
      });
    },
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        // dispatch(onSuccessLetterData());
        // setValue("comments", "");
        // if (
        //   entityName === "INVOICE"
        //     ? dispatch(refetchInvoiceActions())
        //     : dispatch(refetchActions())
        // );
        // if (
        //   entity === "invoices"
        //     ? dispatch(refetchInvoiceDocumentData())
        //     : dispatch(refetchDocumentData())
        // )
        // dispatch(addLetterEmailAttachment([]));
        // reset();
        // dispatch(isOpenModal(false));
      },
    }
  );

  useEffect(async () => {
    if (getParams && letter_generated === "manual_generator") {
      await letterName({
        organization: currentOrganization,
        customerId: getParams,
      }).then((res) => {
        let item = res && res.data && res.data.docs;

        if (item) {
          setLetterName(item);
        }
      });
    }
  }, [getParams, letter_generated]);

  useEffect(() => {
    if (letter_generated) {
      setValue("template_id", []);
      setValue("invoice_follow_ups", []);
      setValue("sub_category", []);
      setValue("date_of_communication", "");
      setValue("delivery_method", []);
      setValue("tracking_url", "");
      setValue("delivery_status", []);
      setValue("comments", "");
    }
  }, [letter_generated]);

  const onSubmit = async (data) => {
    let values = {
      action_type: "letter",
      ...(data.letter_generated === "system_generator"
        ? { is_system_generated: true }
        : { is_system_generated: false }),

      ...(data.letter_generated === "system_generator"
        ? { template_id: getSelectValues(data.template_id) }
        : {}),

      ...(data.letter_generated === "system_generator"
        ? {
            letter_name:
              data.template_id &&
              data.template_id[0] &&
              data.template_id[0].name,
          }
        : {
            letter_name:
              data.invoice_follow_ups &&
              data.invoice_follow_ups[0] &&
              data.invoice_follow_ups[0].label,
          }),
      recipient: [
        {
          id: data?.recipients?.[0]?.id,
          recipient_id: data?.recipients?.[0]?.recipient_id,
        },
      ],
      sub_category: getSelectValues(data.sub_category),
      date_of_communication: moment
        .tz(data.date_of_communication, timeZone)
        .startOf("D")
        .utc()
        .valueOf(),

      sub_category: getSelectValues(data.sub_category),
      delivery_status: getSelectValues(data.delivery_status),
      delivery_method: getSelectValues(data.delivery_method),
      tracking_url: data.tracking_url,
      attachments: letterAttachments,
      comments: data.comments,
    };
    // await executeActionMutation.mutateAsync(values);
  };

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  const tempaletList = useMemo(() => {
    let temp = _.get(communicationTemplateData, "data.data.docs", [])
      .filter((e) => e.type === "letter")
      .map((i) => {
        if (i?.version >= 1) {
          return {
            label: `${i.name} (${
              i.language
                ? i.language.toLocaleUpperCase()
                : "en".toLocaleUpperCase()
            })`,
            id: i?._id,
            name: i?.name,
          };
        } else {
          return [];
        }
      });
    return temp;
  }, [communicationTemplateData.isFetching]);

  const contactsOptions = useMemo(() => {
    const contacts = customerConatactDetails.reduce((prev, curr) => {
      let { phone, landline, email, address, ...rest } = curr;
      let nestedContacts = [...address]
        .map(({ status, address_status, ...nRest }) => {
          let designation =
            referenceData && referenceData["recipient_type"]
              ? referenceData["recipient_type"].find(
                  (rf) => rf?.id === curr?.designation
                )
              : "";

          return {
            label: `${curr?.first_name ? curr?.first_name : ""} - ${
              nRest.line_1
            }, ${nRest.line_2 ? nRest.line_2 : ""}, ${nRest.country}, ${
              nRest.city
            }-${nRest.zip_code} ${designation?.label}`,
            id: nRest?._id,
            recipient_id: curr?._id,
            address_status,
            status,
            designation: curr?.designation,
          };
        })
        .filter(
          (e) =>
            (e.status === "VALID" || e.status === "LEAD") &&
            e.address_status === "VALID"
        );

      return [...prev, ...nestedContacts];
    }, []);

    const actions = _.get(workFlowData, "customer_actions", []);
    const activeAction = actions.find((e) => e.action_status === "ACTIVE");

    let recipientTypes = [];

    if (activeAction) {
      recipientTypes = _.get(activeAction, "recipient_type", [
        "primary_contact",
      ]);

      if (recipientTypes.length === 0) {
        recipientTypes = ["primary_contact"];
      }
    }

    if (recipientTypes && Array.isArray(recipientTypes)) {
      const selectedContacts = contacts.filter((e) =>
        recipientTypes.includes(e.designation)
      );
      setValue("recipients", selectedContacts.slice(0, 1));
    }
    return contacts;
  }, [workFlowData, customerConatactDetails]);

  useEffect(() => {
    if (workFlowData) {
      const actions = _.get(workFlowData, "customer_actions", []);
      const activeAction = actions.find((e) => e.action_status === "ACTIVE");

      let findTemData = _.get(
        communicationTemplateData,
        "data.data.docs",
        []
      ).find(({ _id }) => _id === activeAction.template_id);
      setValue("letter_generated", "system_generator");
      setValue("delivery_method", [{ id: activeAction?.delivery_method }]);
      setValue("template_id", [{ ...findTemData, id: findTemData?._id }]);
    }
  }, [
    workFlowData,
    communicationTemplateData.isLoading,
    communicationTemplateData.isFetching,
  ]);

  return (
    <>
      <DMSystem
        resourceName="customerId"
        resourceId={getParams}
        drawerOpen={letterDrawerStatus}
        documentDrawertype={drawerType}
        documentDrive={letterDocumentDrive}
        closeDocmentDrawer={letterDocumentDrawerStateClose}
      />
      <div
        style={{
          gridColumn: "2/5",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
        // onSubmit={handleSubmit(onSubmit)}
      >
        <div
          style={{
            height: "100%",
          }}
        >
          <div
            style={{
              display: "grid",
              // gridTemplateColumns: "repeat(10,50fr)",
              gridTemplateRows: "repeat(12,10fr)",
              height: "100%",
            }}
          >
            <div
              style={{
                padding: "0px 35px",
                height: "1000%",
                overflow: "scroll",
              }}
            >
              <div style={{ paddingTop: "5px" }}>
                <Controller
                  defaultValues={[]}
                  name="recipients"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      size={SIZE.default}
                      {...field}
                      name={field.name}
                      requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "recipients",
                      })}
                      placeholder={intl.formatMessage({
                        id: "recipients",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={contactsOptions}
                    />
                  )}
                />
              </div>

              <LabelMedium>
                How do you like to Generate Letters?{" "}
                <span style={{ color: "red" }}>*</span>
              </LabelMedium>

              <div style={{ margin: "10px 0px" }}>
                <Controller
                  defaultValues={"system_generator"}
                  name="letter_generated"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      value={field.value}
                      align={ALIGN.horizontal}
                    >
                      <Radio value="system_generator">
                        System Generator Letters
                      </Radio>
                      <Radio value="manual_generator">
                        Manual Generator Letters
                      </Radio>
                    </RadioGroup>
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}
              >
                {letter_generated === "system_generator" ? (
                  <>
                    <Controller
                      defaultValues={[]}
                      name="template_id"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          size={SIZE.default}
                          {...field}
                          name={field.name}
                          requiredAstric={true}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "template_id",
                          })}
                          placeholder={intl.formatMessage({
                            id: "template_id",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={tempaletList}
                        />
                      )}
                    />
                  </>
                ) : (
                  <>
                    <Controller
                      defaultValues={[]}
                      name="invoice_follow_ups"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          size={SIZE.default}
                          {...field}
                          name={field.name}
                          requiredAstric={true}
                          creatable={creatablePermission ? true : false}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "letter_name",
                          })}
                          placeholder={intl.formatMessage({
                            id: "letter_name",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={letterNameSelect}
                        />
                      )}
                    />
                  </>
                )}

                <Controller
                  defaultValues={[]}
                  name="sub_category"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      size={SIZE.default}
                      {...field}
                      name={field.name}
                      requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "sub_category",
                      })}
                      placeholder={intl.formatMessage({
                        id: "sub_category",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      creatable={creatablePermission ? true : false}
                      options={
                        subCategory.data &&
                        subCategory.data.data &&
                        subCategory.data.data.docs
                      }
                    />
                  )}
                />
              </div>

              <div
                style={{ display: "flex", flexDirection: "row", gap: "20px" }}
              >
                <Controller
                  name="date_of_communication"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <DateRangeSelect
                      {...field}
                      error={errors[field.name] && errors[field.name].message}
                      requiredAstric={true}
                      label={intl.formatMessage({
                        id: "date_of_communication",
                      })}
                      // placeholder={intl.formatMessage({
                      //   id: `${currentDefaultFormatDetails.date_format}`,
                      // })}
                      value={field.value}
                      onChange={(e) => field.onChange(e.date)}
                      // minDate={new Date()}
                    />
                  )}
                />
                <Controller
                  defaultValues={[]}
                  name="delivery_method"
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      size={SIZE.default}
                      {...field}
                      name={field.name}
                      // creatable={creatablePermission ? true : false}
                      // requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "delivery_method",
                      })}
                      placeholder={intl.formatMessage({
                        id: "delivery_method",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={referenceData["letter_delivery_method_list"]}
                    />
                  )}
                />

                <Controller
                  defaultValue={""}
                  name="tracking_url"
                  control={control}
                  // rules={{ required: "Required" }}
                  render={({ field }) => (
                    <TextBox
                      {...field}
                      // hidden={true}
                      // type="number"
                      name={field.name}
                      // requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "tracking_url",
                      })}
                      placeholder={intl.formatMessage({
                        id: "tracking_url",
                      })}
                      // onChange={(e) => {
                      //   field.onChange(e.value);
                      // }}
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  defaultValues={[]}
                  name="delivery_status"
                  control={control}
                  // rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      size={SIZE.default}
                      {...field}
                      name={field.name}
                      // requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "delivery_status",
                      })}
                      placeholder={intl.formatMessage({
                        id: "delivery_status",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={referenceData["letter_delivery_status"]}
                    />
                  )}
                />
              </div>
              {recipients &&
              recipients?.length > 0 &&
              template_id &&
              template_id.length > 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "350px",
                      justifyContent: "space-between",
                      border: "1px solid #c7c7c7",
                      borderRadius: "5px",
                      padding: "8px 5px 5px 5px",
                      marginTop: "10px",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={async () => {
                      // let templateId =
                      //   template_id && template_id[0] && template_id[0].name;

                      await postLetterPreview.mutateAsync({
                        recipient:
                          recipients && recipients[0] && recipients[0].id,
                        template_id:
                          template_id && template_id[0] && template_id[0].id,
                        invoiceId: getInvParams,
                      });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div className="file_wraper_icon">
                        <DocFileType />
                        <p
                          className={`file_text_wraper --file_name_position ${"--doc_font_size"}`}
                          style={{ paddingTop: "5px", fontSize: "6px" }}
                        >
                          {"pdf".toUpperCase()}
                        </p>
                      </div>
                      <LabelSmall
                        $style={{
                          color: "#516beb",
                          lineHeight: "15px",
                          color: "#516beb",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          minWidth: "50px",
                          maxWidth: "205px",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {template_id && template_id[0] && template_id[0].name}
                      </LabelSmall>
                    </div>
                    <div>
                      {postLetterPreview && postLetterPreview.isLoading ? (
                        <>
                          <Spinner $size={SIZE.small} />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {emailAttached?.map((attachment, index) => {
                let { tempId } = attachment;
                return (
                  <EmailAttachment
                    key={tempId}
                    {...attachment}
                    id={getParams}
                    currentOrganization={currentOrganization}
                    entity={entity}
                    removeAttchFile={removeAttchFile}
                    updatingDeskUpload={updatingDeskUpload}
                    subCategory={
                      sub_category && sub_category[0] && sub_category[0].id
                    }
                  />
                );
              })}

              <div style={{ marginTop: "15px" }}>
                <Controller
                  control={control}
                  // defaultValues=""
                  name="comments"
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      name={field.name}
                      // requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "comments",
                      })}
                      placeholder={intl.formatMessage({
                        id: "comments",
                      })}
                      size={SIZE.default}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </div>

            {/* <div style={{ padding: "15px 35px" }}></div>
            <div
              style={{
                background: "#F9F9F9",
                // width: "100%",
                height: "70px",
                padding: "15px",
                borderTop: "1px solid #CDCED9",
                gridRow: "12/13",
              }}
            > */}
            {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              > */}
            {/* attachment => ADD in workflow  */}
            {/* <StatefulPopover
                  content={({ close }) => (
                    <div className="cp_email_attachement">
                      <ParagraphMedium
                        $style={{}}
                        onClick={() => {
                          open();
                          close();
                        }}
                      >
                        From Desktop
                      </ParagraphMedium>
                      <ParagraphMedium
                        onClick={() => {
                          // if (entity === "invoices") {
                          //   dispatch(invoiceDriveOpen(true));
                          //   close();
                          // } else {
                          //   dispatch(customerDriveOpen(true));
                          //   close();
                          // }
                          dispatch(letterDocumentDrawerState(true));
                        }}
                      >
                        From Customer Drive
                      </ParagraphMedium>
                    </div>
                  )}
                  returnFocus
                  autoFocus
                >
                  <Button
                    type="button"
                    kind={KIND.secondary}
                    size={SIZE.mini}
                    disabled={sub_category && sub_category.length === 0}
                    overrides={{
                      BaseButton: {
                        style: ({ $theme }) => ({
                          border: "1px solid #CDCED9",
                          borderRadius: "5px",
                        }),
                      },
                    }}
                  >
                    <div style={{ paddingRight: "4px", paddingTop: "2px" }}>
                      <PlusLetter />
                    </div>
                    <LabelSmall>Add Attachments</LabelSmall>
                  </Button>
                </StatefulPopover> */}
            {/* <div style={{ display: "flex", gap: "10px" }}>
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={SIZE.compact}
                      fullWidth
                      type="button"
                      // isLoading={isSubmitting}
                      // disabled={isSubmitting}
                      kind={KIND.tertiary}
                      onClick={() => {
                        dispatch(isOpenModal(false));
                      }}
                    >
                      Cancel
                    </TextButton>
                  </div>
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={SIZE.compact}
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Save
                    </TextButton>
                  </div>
                </div> */}
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default LetterWorkflowAction;
