import { alphabetize } from "../../utils/alphabetize";
import {
  PAYMENT_ADD_ADHOC_UPLOAD_DATA,
  PAYMENT_UPDATE_MAP_FIELDS,
  PAYMENT_UPDATE_FORMAT_DATA,
  PAYMENT_UPDATE_DEFAULT_DATA,
  PAYMENT_UPDATE_SYSTEM_FIELDS,
  PAYMENT_UPDATE_TEMPLATE_DATA_LIST,
  PAYMENT_INITIALSTATE_STATE,
  PAYMENT_ADHOC_REFETCH,
  PAYMENT_SET_AU_FILTER,
  PAYMENT_ADHOC_FILTER,
  PAYMENT_VALIDATION_ADHOC_FILTER,
  PAYMENT_MAP_BASED_ON,
  PAYMENT_BUSINESS_UNIT_DATA,
  PAYMENT_UPDATE_BASED_ON,
  PAYMENT_FORMULA_FIELDS,
  DELETE_PAYMENT_FORMULA_FIELDS,
  UPDATE_PAYMENT_FORMULA_FIELDS,
} from "./action";
import { Parser } from "hot-formula-parser";
import { tokenize } from "excel-formula-tokenizer";

import moment from "moment";
import { formulas } from "../../utils/formulaObj";
import _ from "lodash";

const initialState = {
  mappingFieldList: [],
  defaultSystemFields: [],
  defaultFields: [],
  requiredFields: [],
  systemFields: [],
  requiredDefaultFields: [],
  requiredFormatFields: [],
  adhocRefetchActions: 0,
  validDefaultFields: [],
  tableCurrentState: {
    filters: {},
    formFilters: [
      {
        id: [],
      },
    ],
  },
  adhocFilter: false,
  defaultRequiredFields: [],
  fieldMessage: [],
  mapBasedOn: [],
  businessUnitRd: [],
  updateBasedOn: [],
  formulaVariables: [],
};

const adhocPaymentUpload = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case PAYMENT_ADHOC_FILTER: {
      return {
        ...state,
        adhocFilter: payload,
      };
    }
    case PAYMENT_ADD_ADHOC_UPLOAD_DATA:
      /* getting the mapping system fields value */
      if (payload.systemFields) {
        const requiredFields = [];
        payload.systemFields.map(({ required, system_column, id }) => {
          if (required == true) {
            requiredFields.push({ system_column, id, isMapped: false });
          }
        });
        payload.requiredFields = [...requiredFields];

        payload.defaultSystemFields = payload.systemFields;
      }
      /* set value of unmapped default fieds  */
      if (payload.defaultFields) {
        payload.requiredDefaultFields = [...payload.defaultFields];
      }

      if (payload.mappingFieldList) {
        payload.mappingFieldList = payload.mappingFieldList.map((e, i) => {
          return {
            index: alphabetize[i],
            upload_field: e.upload_field,
            first_row_data: e.first_row_data,
            format: e.format,
            system_field: e.system_field,
          };
        });
      }

      return {
        ...state,
        ...payload,
      };

    // case UPDATE_MAP_FIELDS:

    //   state.systemColumn[payload.index].mapped_field = payload.mapField;
    //   state.systemColumn[payload.index].data =
    //     state.firstRowData[payload.mapField];
    //   const systemColumn = [...state.systemColumn];
    //   return {
    //     ...state,
    //     systemColumn: [...systemColumn],
    //   };

    case PAYMENT_UPDATE_FORMAT_DATA:
      /* formate value  */
      if (!state.mappingFieldList[payload.index].format) {
        const formatSelectIndex = state.requiredFormatFields.findIndex(
          ({ id, isSelected }) =>
            id == state.mappingFieldList[payload.index].system_field &&
            isSelected == false
        );
        state.requiredFormatFields[formatSelectIndex].isSelected = true;
      }

      state.mappingFieldList[payload.index].format = payload.formatData;

      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
      };

    case PAYMENT_UPDATE_SYSTEM_FIELDS: {
      const prevSystemField =
        state.mappingFieldList[payload.index].system_field;
      if (prevSystemField === payload.systemField) {
        return {
          ...state,
        };
      }

      let newRequiredFields = [...state.requiredFields];

      // if (payload.type === "clear") {
      //   const mapBasedData = state?.mapBasedOn?.map((e) =>
      //     e?.id?.toLowerCase()
      //   );
      //  state.systemFields.reduce(
      //     (prev, { required, system_column, id, isSelected }) => {
      //       if (mapBasedData?.includes(id) && id !== "business_unit") {
      //         state.requiredFields.filter((fl) => fl.id !== id);
      //         let findIndexPR = state.requiredFields.findIndex(
      //           (e) => e.id === id
      //         );

      //         state.requiredFields.splice(findIndexPR, 1);

      //         prev = state.requiredFields.push({
      //           system_column,
      //           id,
      //           isMapped: false,
      //         });
      //       }
      //       return prev;
      //     },
      //     []
      //   );

      // }

      // if (payload.type == "clear") {
      let checkDueReqFields = state.requiredFields.find(
        ({ id }) => id == "due_amount"
      );
      let checkInvDueReqFields = state.requiredFields.find(
        ({ id }) => id == "invoice_total_amount"
      );

      let customerName = state.requiredFields.find(
        ({ id }) => id == "customer_name"
      );
      let contactFirstName = state.requiredFields.find(
        ({ id }) => id == "first_name"
      );

      if (state.mappingFieldList[payload.index].system_field == "customer_id") {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "customer_id"
        );
        state.requiredFields.splice(findIndexOfInvAmt, 1);
        state.requiredFields.push({
          system_column: "CUSTOMER ID",
          id: "customer_id",
          isMapped: false,
        });
      }

      if (
        state.mappingFieldList[payload.index].system_field == "customer_uuid"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "customer_uuid"
        );
        state.requiredFields.splice(findIndexOfInvAmt, 1);
        state.requiredFields.push({
          system_column: "CUSTOMER UUID",
          id: "customer_uuid",
          isMapped: false,
        });
      }

      if (
        state.mappingFieldList[payload.index].system_field == "client_reference"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "client_reference"
        );
        state.requiredFields.splice(findIndexOfInvAmt, 1);
        state.requiredFields.push({
          system_column: "CLIENT REFERENCE",
          id: "client_reference",
          isMapped: false,
        });
      }

      if (
        state.mappingFieldList[payload.index].system_field == "invoice_number"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "invoice_number"
        );
        state.requiredFields.splice(findIndexOfInvAmt, 1);
        state.requiredFields.push({
          system_column: "INVOICE NUMBER",
          id: "invoice_number",
          isMapped: false,
        });
      }

      if (
        state.mappingFieldList[payload.index].system_field ==
        "payment_reference"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "payment_reference"
        );
        state.requiredFields.splice(findIndexOfInvAmt, 1);
        state.requiredFields.push({
          system_column: "Payment Reference",
          id: "payment_reference",
          isMapped: false,
        });
      }

      if (
        state.mappingFieldList[payload.index].system_field == "payment_date"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "payment_date"
        );
        state.requiredFields.splice(findIndexOfInvAmt, 1);
        state.requiredFields.push({
          system_column: "Payment Date",
          id: "payment_date",
          isMapped: false,
        });
      }

      if (
        !customerName &&
        contactFirstName &&
        state.mappingFieldList[payload.index].system_field == "first_name"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "first_name"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let findMapDueAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "customer_name"
        );

        if (findMapDueAmt) {
          state.requiredFields.push({
            system_column: "Customer Name",
            id: "customer_name",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Customer Name",
            id: "customer_name",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "First Name",
            id: "first_name",
            isMapped: false,
          });
        }
      }

      if (
        !contactFirstName &&
        customerName &&
        state.mappingFieldList[payload.index].system_field == "customer_name"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "customer_name"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let findMapDueAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "first_name"
        );

        if (findMapDueAmt) {
          state.requiredFields.push({
            system_column: "First Name",
            id: "first_name",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Customer Name",
            id: "customer_name",
            isMapped: false,
          });
        }
      }

      if (
        !checkDueReqFields &&
        checkInvDueReqFields &&
        state.mappingFieldList[payload.index].system_field ==
          "invoice_total_amount"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "invoice_total_amount"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let findMapDueAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "due_amount"
        );

        if (findMapDueAmt) {
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: false,
          });
        }
      }

      if (
        checkDueReqFields &&
        !checkInvDueReqFields &&
        state.mappingFieldList[payload.index].system_field == "due_amount"
      ) {
        let findDueAmt = state.requiredFields.findIndex(
          ({ id }) => id === "due_amount"
        );

        state.requiredFields.splice(findDueAmt, 1);

        let findMapInvAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "invoice_total_amount"
        );

        if (findMapInvAmt) {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: false,
          });
        }
      }
      // }

      if (prevSystemField) {
        const preSystemFieldIndex = state.systemFields.findIndex(
          (sys) => prevSystemField == sys.id
        );
        const preSystemFieldConfig = state.systemFields[preSystemFieldIndex];
        if (preSystemFieldConfig && preSystemFieldConfig.required) {
          // state.requiredFields.push(preSystemFieldConfig);

          let requiredFieldIndex = state.requiredFields.findIndex(
            ({ id }) => id == prevSystemField
          );

          if (requiredFieldIndex != -1) {
            state.requiredFields[requiredFieldIndex].isMapped = false;
          }
        }

        if (preSystemFieldConfig && preSystemFieldConfig.format) {
          let requiredFieldIndex = state.requiredFormatFields.findIndex(
            ({ id }) => id == prevSystemField
          );

          if (requiredFieldIndex != -1) {
            state.requiredFormatFields.splice(requiredFieldIndex, 1);
          }
        }

        state.systemFields[preSystemFieldIndex].isSelected = false;
      }

      state.mappingFieldList[payload.index].system_field = payload.systemField;
      state.mappingFieldList[payload.index].format = null;

      const requiredFieldIndex = state.requiredFields.findIndex((req) => {
        return req.id == payload.systemField;
      });
      if (requiredFieldIndex != -1) {
        state.requiredFields[requiredFieldIndex].isMapped = true;
      }

      const selectSystemField =
        state &&
        state.systemFields &&
        state.systemFields.findIndex(
          (select) => select.id == payload.systemField
        );

      state.systemFields[selectSystemField].isSelected = true;

      if (state.mappingFieldList[payload.index].system_field === "due_amount") {
        newRequiredFields = state.requiredFields.filter(
          ({ id, isMapped }) => id != "invoice_total_amount" || isMapped
        );
      }

      if (
        state.mappingFieldList[payload.index].system_field === "customer_name"
      ) {
        newRequiredFields = state.requiredFields.filter(
          ({ id, isMapped }) => id != "first_name" || isMapped
        );
      }

      if (state.mappingFieldList[payload.index].system_field === "first_name") {
        newRequiredFields = state.requiredFields.filter(
          ({ id, isMapped }) => id != "customer_name" || isMapped
        );
      }

      if (
        state.mappingFieldList[payload.index].system_field ===
        "invoice_total_amount"
      ) {
        newRequiredFields = state.requiredFields.filter(
          ({ id, isMapped }) => id != "due_amount" || isMapped
        );
      }
      // }

      if (state.systemFields[selectSystemField].format?.reference) {
        const { system_column, id } = state.systemFields[selectSystemField];
        const findRequiredFormatFields = state.requiredFormatFields.find(
          (e) => e.id === id
        );
        {
          state.requiredFormatFields.push({
            system_column,
            id,
            isSelected: false,
          });
        }
      }

      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
        requiredFields: [...newRequiredFields],
        requiredFormatFields: [...state.requiredFormatFields],
      };
    }

    case PAYMENT_UPDATE_DEFAULT_DATA: {
      // if (!state.validDefaultFields[payload.index].default.value) {
      //   const requiredDefaultFieldsIndex =
      //     state.requiredDefaultFields.findIndex(
      //       ({ id }) => id === state.validDefaultFields[payload.index].id
      //     );
      //   state.requiredDefaultFields.splice(requiredDefaultFieldsIndex, 1);
      // }

      let newValidDefaultFields = [...state.validDefaultFields];
      let newReqFields = [...state.fieldMessage];

      newValidDefaultFields[payload.index].default.value = payload.defaultData;

      if (
        state.validDefaultFields[payload.index].id == "invoice_date" &&
        !payload.defaultData.startsWith("{{") &&
        !payload.defaultData.endsWith("}}")
      ) {
        let isDateVaied = moment(
          payload.defaultData,
          state.organizationDtl.date_format,
          true
        ).isValid();

        if (!isDateVaied) {
          newReqFields.push({
            id: "invoice_date",
            message: `Invoice Date is not as per the format ${state.organizationDtl.date_format}`,
          });
        } else {
          newReqFields = newReqFields.filter((e) => e.id != "invoice_date");
        }
      }

      if (
        state.validDefaultFields[payload.index].id == "invoice_due_date" &&
        !payload.defaultData.startsWith("{{") &&
        !payload.defaultData.endsWith("}}")
      ) {
        let isDateVaied = moment(
          payload.defaultData,
          state.organizationDtl.date_format,
          true
        ).isValid();

        if (!isDateVaied) {
          newReqFields.push({
            id: "invoice_due_date",
            message: `Due Date entered is not as per the format ${state.organizationDtl.date_format}`,
          });
        } else {
          newReqFields = newReqFields.filter((e) => e.id != "invoice_due_date");
        }
      }

      let findInvDateFields = newValidDefaultFields.find(
        (e) => e.id == "invoice_date"
      );

      let findDueDateFields = newValidDefaultFields.find(
        (e) => e.id == "invoice_due_date"
      );

      if (
        findInvDateFields &&
        findInvDateFields.id &&
        !findInvDateFields.default.value.startsWith("{{") &&
        !findInvDateFields.default.value.endsWith("}}") &&
        findDueDateFields &&
        findDueDateFields.id &&
        !findDueDateFields.default.value.startsWith("{{") &&
        !findDueDateFields.default.value.endsWith("}}") &&
        newReqFields.filter((nr) =>
          ["invoice_date", "invoice_due_date"].includes(nr.id)
        ).length === 0
      ) {
        let invDate = moment(
          findInvDateFields.default.value,
          state.organizationDtl.date_format
        );
        let dueDate = moment(
          findDueDateFields.default.value,
          state.organizationDtl.date_format
        );

        if (invDate.diff(dueDate, "days") > 0) {
          newReqFields.push({
            id: "invoice_date_gr",
            message: `Invoice Date has to be lesser than or equal to Due Date`,
          });
        } else {
          newReqFields = newReqFields.filter(
            (e) => e.id != "invoice_due_date_gr"
          );
        }

        if (dueDate.diff(invDate, "days") < 0) {
          newReqFields.push({
            id: "invoice_due_date_gr",
            message: `Due Date has to be greater than or equal to Invoice Date`,
          });
        } else {
          newReqFields = newReqFields.filter((e) => e.id != "invoice_date_gr");
        }
      } else {
        newReqFields = newReqFields.filter(
          (e) => e.id != "invoice_due_date_gr" || e.id != "invoice_date_gr"
        );
      }

      return {
        ...state,
        validDefaultFields: [...state.validDefaultFields],
        fieldMessage: [...newReqFields],
      };

      break;
    }

    case PAYMENT_UPDATE_TEMPLATE_DATA_LIST:
      let formulaVar = [];
      let newRequiredFields = state.requiredFields;
      let newFormatFields = [];
      state.mappingFieldList = state.mappingFieldList.map((e) => ({
        ...e,
        format: null,
        system_field: null,
      }));

      let filterFormulaData = payload.mappingFields.filter(
        ({ is_formula }) => is_formula
      );

      if (filterFormulaData && filterFormulaData.length > 0) {
        let filterPreFormaul = state.mappingFieldList.filter(
          ({ is_formula }) => !is_formula
        );
        let mergeFormuaData = [...filterPreFormaul, ...filterFormulaData];
        state.mappingFieldList = [...mergeFormuaData].map((e, i) => {
          return {
            ...e,
            index: alphabetize[i],
          };
        });
      } else {
        let filterPreFormaul = state.mappingFieldList.filter(
          ({ is_formula }) => !is_formula
        );
        state.mappingFieldList = [...filterPreFormaul].map((e, i) => {
          return {
            ...e,
            index: alphabetize[i],
          };
        });
      }

      let validatingData = state.mappingFieldList.reduce((acc, cur) => {
        acc[cur.index] = cur.first_row_data;
        return acc;
      }, {});

      payload.mappingFields.map((e) => {
        if (e.is_formula) {
          const tokens = tokenize(e.upload_field);
          let parser = new Parser();
          let isVariable = [];
          for (const { value, type, subtype } of tokens) {
            if (type == "operand" && subtype == "range") {
              isVariable.push(value);
              parser.setVariable(value, validatingData[value]);
            }
          }

          for (const formulaName of Object.keys(formulas)) {
            parser.setFunction(formulaName, formulas[formulaName]);
          }

          let { result, error } = parser.parse(e.upload_field);

          let findIndexOfFormula = state.mappingFieldList.findIndex(
            ({ _id }) => _id == e._id
          );

          state.mappingFieldList[findIndexOfFormula] = {
            ...state.mappingFieldList[findIndexOfFormula],
            first_row_data: result,
            variableUsed: [...isVariable],
          };
          validatingData[e.index] = result;
        }

        const initialMappingFieldIndex = state.mappingFieldList.findIndex(
          ({ index, upload_field, is_formula }) => {
            if (is_formula && e.is_formula && !e.index) {
              return upload_field == e.upload_field;
            } else if (e.is_formula && is_formula) {
              return index == e.index;
            } else {
              return upload_field == e.upload_field;
            }
          }
        );
        const systemFieldIndex = state.systemFields.findIndex(
          ({ id }) => e.system_field === id
        );
        let isUploadField = state.mappingFieldList.find(
          ({ index, upload_field, is_formula }) => {
            if (is_formula && e.is_formula && !e.index) {
              return upload_field == e.upload_field;
            } else if (is_formula && e.is_formula) {
              return index == e.index;
            } else {
              return upload_field == e.upload_field;
            }
          }
        );
        if (systemFieldIndex !== -1 && isUploadField) {
          state.systemFields[systemFieldIndex].isSelected = true;
        }
        const selectTick = state.systemFields.findIndex(
          ({ id }) => id === e.system_field
        );

        // if (e.system_field == "invoice_total_amount") {
        //   newRequiredFields = state.requiredFields.filter(
        //     ({ id, isMapped }) => id != "due_amount"
        //   );
        // }

        if (
          initialMappingFieldIndex != -1 &&
          state.systemFields[selectTick]?.required
        ) {
          const { system_column, id } = state.systemFields[selectTick];
          const requiredFieldIndex = newRequiredFields.findIndex(
            (e) => e.id == id
          );

          if (requiredFieldIndex != -1) {
            newRequiredFields[requiredFieldIndex].isMapped = true;
          }

          // newRequiredFields.push({ system_column, id, isMapped: true });
        }

        if (
          initialMappingFieldIndex != -1 &&
          state.systemFields[selectTick]?.format &&
          isUploadField
        ) {
          const { system_column, id } = state.systemFields[selectTick];
          newFormatFields.push({ system_column, id, isSelected: true });
        }

        if (state.systemFields[selectTick] && isUploadField) {
          state.systemFields[selectTick].isSelected = true;
          state.mappingFieldList[initialMappingFieldIndex] = {
            ...state.mappingFieldList[initialMappingFieldIndex],
            format: e.format,
            system_field: e.system_field,
          };
        }

        if (e.system_field == "first_name") {
          newRequiredFields = newRequiredFields.filter(
            ({ id, isMapped }) => id != "customer_name" || isMapped
          );
        } else if (e.system_field == "customer_name") {
          newRequiredFields = newRequiredFields.filter(
            ({ id, isMapped }) => id != "first_name" || isMapped
          );
        }

        if (e.system_field == "invoice_total_amount") {
          newRequiredFields = newRequiredFields.filter(
            ({ id, isMapped }) => id != "due_amount" || isMapped
          );
        } else if (e.system_field == "due_amount") {
          newRequiredFields = newRequiredFields.filter(
            ({ id, isMapped }) => id != "invoice_total_amount" || isMapped
          );
        }
      });

      let paymentMapBasedOn = payload.notesMappingBasedOn.map((e) => {
        return { id: e };
      });

      const mapBasedData = payload?.notesMappingBasedOn.map((e) =>
        e?.toLowerCase()
      );

      newRequiredFields = state.systemFields.reduce(
        (prev, { required, system_column, id, isSelected }) => {
          if (required && id !== "business_unit") {
            return [
              ...prev,
              {
                system_column,
                id,
                isMapped: isSelected ? isSelected : false,
              },
            ];
          }

          if (mapBasedData?.includes(id) && id !== "business_unit") {
            if (id === "customer_name") {
              return [
                ...prev,
                {
                  system_column,
                  id,
                  isMapped: isSelected ? isSelected : false,
                },
              ];
            }

            return [
              ...prev,
              {
                system_column,
                id,
                isMapped: isSelected ? isSelected : false,
              },
            ];
          }
          return prev;
        },
        []
      );

      let paymentUpdateBasedOn = payload.updateBasedOn.map((e) => {
        return { id: e };
      });

      let mappingState = [...state.mappingFieldList];

      if (filterFormulaData && filterFormulaData.length > 0) {
        formulaVar = mappingState.reduce(
          (acc, curr) => acc.concat(curr.variableUsed),
          []
        );
      } else {
        formulaVar = [...initialState.formulaVariables];
      }

      let defaultData = payload.defaultFields.map((e) => {
        const initialDefaultFieldIndex = state.defaultFields.findIndex(
          ({ id }) => id == e.id
        );
        if (
          state &&
          state.defaultFields &&
          state.defaultFields[initialDefaultFieldIndex] &&
          state.defaultFields[initialDefaultFieldIndex].default
        ) {
          state.defaultFields[initialDefaultFieldIndex].default.value = e.value;
        }
      });

      // const updateBasedData = payload?.updateBasedOn.map((e) => e);

      // newRequiredFields = state.systemFields.reduce(
      //   (prev, { required, system_column, id, isSelected }) => {
      //     if (required && id !== "business_unit") {
      //       return [
      //         ...prev,
      //         {
      //           system_column,
      //           id,
      //           isMapped: isSelected ? isSelected : false,
      //         },
      //       ];
      //     }

      //     if (
      //       (updateBasedData?.includes(id) && id === "PAYMENT_REFERENCE") ||
      //       (updateBasedData?.includes(id) && id === "PAYMENT_DATE")
      //     ) {
      //       prev = state.requiredFields.push({
      //         system_column,
      //         id,
      //         isMapped: isSelected ? isSelected : false,
      //       });
      //     }

      //     return prev;
      //   },
      //   []
      // );

      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
        systemFields: [...state.systemFields],
        mapBasedOn: [...paymentMapBasedOn],
        updateBasedOn: [...paymentUpdateBasedOn],
        requiredFields: [...newRequiredFields],
        requiredFormatFields: [...newFormatFields],
        formulaVariables: [...formulaVar],
      };

    case PAYMENT_ADHOC_REFETCH: {
      const { adhocRefetchActions } = state;
      return {
        ...state,
        adhocRefetchActions: adhocRefetchActions + 1,
      };
    }

    case PAYMENT_SET_AU_FILTER: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: payload.filters,
          formFilters: payload.formFilters,
        },
      };
    }

    case PAYMENT_VALIDATION_ADHOC_FILTER: {
      let defaultFields = [];

      state.defaultFields.map((e) => {
        let findMappedFields = state.mappingFieldList.find(
          ({ system_field }) => system_field == e.id
        );

        if (!findMappedFields || !findMappedFields.system_field) {
          defaultFields.push(e);
        }
      });

      let newDefaultFields = defaultFields.reduce((prev, curr) => {
        if (curr.id === "business_unit") {
          let businessUnit = state.systemFields.find(
            ({ id }) => id === "business_unit"
          );

          let mapBasedOn = state.mapBasedOn.find(
            ({ id }) => id === "business_unit"
          );

          let findBusinessUnit =
            state && state.businessUnitRd.find(({ label }) => label == "ALL");

          if (businessUnit && !businessUnit.isMapped && mapBasedOn) {
            return [
              ...prev,
              {
                ...curr,
                default: {
                  ...curr.default,
                  value: curr.default.value
                    ? curr.default.value
                    : findBusinessUnit?.id,
                },
                is_create: true,
              },
            ];
          }

          return prev;
        }

        if (curr.id === "payment_commission") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "payment_commission"
          );

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default },
                is_create: true,
              },
            ];
          }

          return prev;
        }

        if (curr.id === "adjustment_type") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "adjustment_type"
          );

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default },
                is_create: false,
              },
            ];
          }

          return prev;
        }

        if (curr.id === "customer_name") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "customer_name"
          );

          let mapBasedOn = state.mapBasedOn.find(
            ({ id }) => id === "customer_name"
          );

          if (sysField && !sysField.isSelected && !mapBasedOn) {
            return [
              ...prev,
              {
                ...curr,
                default: {
                  ...curr.default,
                  value: curr.default.value
                    ? curr.default.value
                    : "{{First_name+Last_name}}",
                },
              },
            ];
          }

          return prev;
        }

        if (curr.id === "payment_date") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "payment_date"
          );

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: {
                  ...curr.default,
                  value: curr.default.value ? curr.default.value : "{{Today}}",
                },
              },
            ];
          }

          return prev;
        }

        if (curr.id === "payment_status") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "payment_status"
          );

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: {
                  ...curr.default,
                  value: curr.default.value ? curr.default.value : "success",
                },
              },
            ];
          }

          return prev;
        }

        if (curr.id === "trans_code") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "trans_code"
          );

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: {
                  ...curr.default,
                  value: curr?.default.value ? curr?.default.value : "",
                },
              },
            ];
          }

          return prev;
        }

        if (curr.id === "payment_paid_to") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "payment_paid_to"
          );
          let dueDate = defaultFields.find(
            ({ id }) => id === "payment_paid_to"
          );

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: {
                  ...curr.default,
                  value: curr.default.value
                    ? curr.default.value
                    : "TRUST_ACCOUNT",
                },
              },
            ];
          }

          return prev;
        }

        if (curr.id === "payment_currency") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "payment_currency"
          );

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: {
                  ...curr.default,
                  value: curr.default.value
                    ? curr.default.value
                    : state.orgDefaultData.default_currency,
                },
              },
            ];
          }

          return prev;
        }

        return [...prev, curr];
      }, []);

      return {
        ...state,
        validDefaultFields: [...newDefaultFields],
      };
    }

    case PAYMENT_MAP_BASED_ON: {
      const mapBasedData = payload?.map((e) => e?.id?.toLowerCase());
      let requeredField = state.requiredFields.map((e) => e.id);
      const requiredFields = state.systemFields.reduce(
        (prev, { required, system_column, id, isSelected }) => {
          if (required && id !== "business_unit") {
            return [
              ...prev,
              {
                system_column,
                id,
                isMapped: isSelected ? isSelected : false,
              },
            ];
          }

          if (mapBasedData?.includes(id) && id !== "business_unit") {
            if (id === "customer_name") {
              return [
                ...prev,
                {
                  system_column,
                  id,
                  isMapped: isSelected ? isSelected : false,
                },
              ];
            }

            return [
              ...prev,
              {
                system_column,
                id,
                isMapped: isSelected ? isSelected : false,
              },
            ];
          }
          return prev;
        },
        []
      );

      return {
        ...state,
        mapBasedOn: [...payload],
        requiredFields: [...requiredFields],
      };
    }

    case PAYMENT_UPDATE_BASED_ON: {
      const mapBasedData = payload?.map((e) => e?.id?.toLowerCase());

      let requeredField = state.requiredFields.map((e) => e.id);

      const requiredFields = state.systemFields.reduce(
        (prev, { required, system_column, id, isSelected }) => {
          if (required && id !== "business_unit") {
            return [
              ...prev,
              {
                system_column,
                id,
                isMapped: isSelected ? isSelected : false,
              },
            ];
          }

          if (
            (mapBasedData?.includes(id) &&
              id === "payment_reference" &&
              !requeredField.includes(id)) ||
            (mapBasedData?.includes(id) &&
              id === "payment_date" &&
              !requeredField.includes(id))
          ) {
            prev = state.requiredFields.push({
              system_column,
              id,
              isMapped: isSelected ? isSelected : false,
            });
          }

          return prev;
        },
        []
      );

      if (
        !mapBasedData?.includes("payment_reference") &&
        requeredField.includes("payment_reference")
      ) {
        let findIndexPR = state.requiredFields.findIndex(
          (e) => e.id === "payment_reference"
        );
        state.requiredFields.splice(findIndexPR, 1);
      }

      if (
        !mapBasedData?.includes("payment_date") &&
        requeredField.includes("payment_date")
      ) {
        let findIndexPR = state.requiredFields.findIndex(
          (e) => e.id === "payment_reference"
        );
        state.requiredFields.splice(findIndexPR, 1);
      }

      return {
        ...state,
        updateBasedOn: [...payload],
        // requiredFields: [...filterField],
      };
    }

    case PAYMENT_INITIALSTATE_STATE: {
      return {
        ...state,
        ...initialState,
        requiredFormatFields: [],
        mapBasedOn: [],
        updateBasedOn: [],
      };
    }

    case PAYMENT_BUSINESS_UNIT_DATA: {
      return {
        ...state,
        businessUnitRd: [...payload.rdBussinessUnit],
        paymentStatus: [...payload.paymentStatus],
        paymentMethod: [...payload.paymentMethod],
        orgDefaultData: { ...payload.orgDefaultData },
      };
    }

    case PAYMENT_FORMULA_FIELDS: {
      let formulaFieldStrucher;
      if (payload?.formulaFields) {
        formulaFieldStrucher = {
          ...payload.formulaFields,
          index: alphabetize[state.mappingFieldList.length],
        };
      }
      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList, formulaFieldStrucher],
        formulaVariables: [
          ...state.formulaVariables,
          ..._.get(payload, "formulaFields.variableUsed", []),
        ],
      };
    }

    case DELETE_PAYMENT_FORMULA_FIELDS: {
      let formulMapdata = state.mappingFieldList;
      let deleteFormulaFields = formulMapdata.splice(payload, 1);
      let reMapFieldData = formulMapdata.map((e, i) => {
        return {
          ...e,
          index: alphabetize[i],
        };
      });

      let vaValues = new Set();
      state.mappingFieldList.forEach((obj) =>
        obj?.variableUsed?.forEach((value) => vaValues.add(value))
      );

      let filteredY = state.formulaVariables.filter((value) =>
        vaValues.has(value)
      );

      return {
        ...state,
        mappingFieldList: [...reMapFieldData],
        formulaVariables: [...filteredY],
      };
    }

    case UPDATE_PAYMENT_FORMULA_FIELDS: {
      state.mappingFieldList[payload.formulaFields.rowIndex] = {
        ...state.mappingFieldList[payload.formulaFields.rowIndex],
        upload_field: payload.formulaFields.upload_field,
        first_row_data: payload.formulaFields.first_row_data,
        isValid: payload.formulaFields.isValid,
        variableUsed: [...payload.formulaFields.variableUsed],
      };

      let vaValues = new Set();
      state.mappingFieldList.forEach((obj) => {
        obj?.variableUsed?.forEach((value) => vaValues.add(value));
      });

      let filteredY = state.formulaVariables.filter((value) =>
        vaValues.has(value)
      );

      state.mappingFieldList.forEach((obj) => {
        obj?.variableUsed?.forEach((value) => {
          if (!filteredY.includes(value)) {
            filteredY.push(value);
          }
        });
      });
      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
        formulaVariables: [...filteredY],
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default adhocPaymentUpload;
