import { Modal } from "baseui/modal";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Plus from "../../assets/img/svg/Upload/Plus";
import { KIND, SIZE, TextButton } from "../../components/TextButton";
import { Controller, useForm } from "react-hook-form";
import { SelectBox } from "../../components/SelectBox";
import { useMutation } from "react-query";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { toast } from "react-toastify";
import getSelectValues from "../../utils/getSelectValues";
import { useIntl } from "react-intl";
import { ParagraphLarge } from "baseui/typography";
import { addUser, getUsers } from "./indexedDBUtil";
import { DatePicker } from "baseui/datepicker";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { Typography } from "../../components_v2";
import useFormat from "../../hooks/useFormat";
import { EDIT, EMAIL, NEW, REPORT_DELIVERY_SETTINGS_ } from "../../constants";
import { TextBox } from "../../components/TextBox";
import { TextBoxTag } from "../../components/TextBoxTag/TextBoxTag";
import {
  addReportDlv,
  editAddReportFn,
  postAddReport,
  updateReportDlv,
} from "../../services/reportMaintanace";
import moment from "moment";
import setSelectValues from "../../utils/setSelectValues";
import queryClient from "../../providers/queryClient";
import _ from "lodash";
import { ADD_REPORT_DELIVERY_SETTINGS } from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";

const AddReportModal = ({ isOpen, setIsOpen }) => {
  const intl = useIntl();
  const format = useFormat();

  const { referenceData, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: [],
    },
  });

  const useAddReport = useMutation(
    (data) =>
      addReportDlv({
        organization: currentOrganization,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(
          `${REPORT_DELIVERY_SETTINGS_}${currentOrganization}`
        );
        setIsOpen({ isOpen: false, data: {}, type: NEW });
      },
    }
  );

  const useEditReport = useMutation(
    (data) =>
      updateReportDlv({
        organization: currentOrganization,
        reportId: isOpen?.data?._id,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        queryClient.invalidateQueries(
          `${REPORT_DELIVERY_SETTINGS_}${currentOrganization}`
        );
        setIsOpen({ isOpen: false, data: {}, type: NEW });
      },
    }
  );

  const { cli } = watch();

  const { findClient, findGroup } = useMemo(() => {
    let findClient;
    let findGroup;

    if (_.get(cli, "[0].id", "")) {
      findClient = referenceData["business_unit_list"].find(
        (e) => e.id == _.get(cli, "[0].id", "")
      );
      findGroup = referenceData["client_group_list"].find(
        (e) => e.id == _.get(cli, "[0].id", "")
      );
    }

    return { findClient, findGroup };
  }, [_.get(cli, "[0].id", "")]);

  function close() {
    reset();
    setIsOpen({ isOpen: !isOpen?.isOpen, data: {}, type: NEW });
  }

  useEffect(() => {
    if (isOpen && isOpen.type === EDIT && isOpen?.data) {
      setValue("rep", setSelectValues(isOpen?.data?.rep));

      let findClientDtl = [];
      [
        ...referenceData["business_unit_list"],
        ...referenceData["client_group_list"],
      ].map((e) => {
        if (isOpen?.data?.cli?.includes(e.id)) {
          findClientDtl.push(e);
        }
      });

      setValue("cli", findClientDtl);

      // if (findGroup) {
      //   setValue("cli", setSelectValues(isOpen?.data?.cli, true));
      // } else {
      //   setValue("cli", setSelectValues(isOpen?.data?.cli));
      // }

      setValue("frq", setSelectValues(isOpen?.data?.frq));
      setValue("st_dt", new Date(isOpen?.data?.st_dt));
      setValue("time", setSelectValues(isOpen?.data?.time));
      setValue("typ", setSelectValues(isOpen?.data?.typ));
      setValue("pth", isOpen?.data?.pth);
      setValue("pswd", isOpen?.data?.pswd);
      setValue("usr_id", isOpen?.data?.usr_id);
      setValue("port", isOpen?.data?.port);
      setValue("serv_adr", isOpen?.data?.serv_adr);
      setValue("email", isOpen?.data?.email);
    } else {
      reset();
    }
  }, [isOpen?.isOpen, isOpen?.type === EDIT]);

  let { typ } = watch();

  const onSubmit = async (data) => {
    let { email, ...rest } = data;
    let values = {
      ...rest,
      rep: getSelectValues(data.rep),
      cli: getSelectValues(data.cli, true),
      frq: getSelectValues(data.frq),
      st_dt: moment
        .tz(
          `${data?.st_dt?.getFullYear()}-${
            data?.st_dt?.getMonth() + 1
          }-${data?.st_dt?.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails.time_zone
        )
        .utc()
        .valueOf(),
      time: getSelectValues(data.time),
      typ: getSelectValues(data.typ), // type - td report communication type
      ...(getSelectValues(data.typ) === "EML" ? { email: email } : {}),
      ...(getSelectValues(data.typ) === "FTP"
        ? {
            serv_adr: data.serv_adr,
            port: data.port,
            usr_id: data.usr_id,
            pswd: data.pswd,
            path: data.path,
          }
        : {}),
    };

    if (isOpen.type === EDIT) {
      await useEditReport.mutateAsync(values);
    } else {
      await useAddReport.mutateAsync(values);
    }
  };

  return (
    <>
      <RBACWrapper role={ADD_REPORT_DELIVERY_SETTINGS} type="PERMISSION">
        <TextButton
          size="mini"
          startEnhancer={() => <Plus size={18} />}
          onClick={() => {
            setIsOpen({ isOpen: true, data: {}, type: NEW });
          }}
        >
          Add Report
        </TextButton>
      </RBACWrapper>

      <Modal
        onClose={close}
        isOpen={isOpen.isOpen}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              textAlign: "center",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
          Dialog: {
            style: ({ $theme }) => ({
              padding: "22px 22px",
              width: "auto",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              boxShadow: "0px 4px 4px 0px #33386050 ",
            }),
          },
        }}
      >
        <form
          style={{
            textAlign: "center",
            // marginTop: "15px",
            // padding: "10px 20px",
            width: "642px",
          }}
          // onSubmit={handleSubmit(onSubmit)}
        >
          <Typography type="h3" style={{ marginBottom: "20px" }}>
            {isOpen.type === EDIT ? "Edit Report" : "Add Report"}
          </Typography>
          <div
            style={{
              display: "flex",
              gap: "5px 20px",
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: "200px" }}>
              <Controller
                name="rep"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // creatable={creatablePermission ? true : false}
                    size="mini"
                    clearable={false}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "select_report",
                    })}
                    placeholder={intl.formatMessage({
                      id: "select_report",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["custom_reports"]
                      .sort((a, b) => a?.id?.localeCompare(b?.id))
                      .map((e) => {
                        return {
                          ...e,
                          disabled: !e?.idw,
                        };
                      })}
                  />
                )}
              />
            </div>
            <div style={{ width: "200px" }}>
              <Controller
                name="cli"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // creatable={creatablePermission ? true : false}
                    size="mini"
                    clearable={false}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "select_client",
                    })}
                    placeholder={intl.formatMessage({
                      id: "select_client",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    multi={findGroup?.id ? false : true}
                    options={
                      findGroup?.id
                        ? [...referenceData["client_group_list"]]
                        : findClient?.id
                        ? [...referenceData["business_unit_list"]]
                        : [
                            ...referenceData["business_unit_list"],
                            ...referenceData["client_group_list"],
                          ].sort((a, b) => a?.id?.localeCompare(b?.id))
                    }
                  />
                )}
              />
            </div>
            <div style={{ width: "200px" }}>
              <Controller
                name="frq"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // creatable={creatablePermission ? true : false}
                    size="mini"
                    clearable={false}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "frequency",
                    })}
                    placeholder={intl.formatMessage({
                      id: "frequency",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["report_delivery_frequency"]}
                  />
                )}
              />
            </div>
            <div style={{ width: "200px" }}>
              <Controller
                name="time"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // creatable={creatablePermission ? true : false}
                    size="mini"
                    clearable={false}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "start_time",
                    })}
                    placeholder={intl.formatMessage({
                      id: "start_time",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["full_day_time_format"]}
                  />
                )}
              />
            </div>
            <div style={{ width: "200px" }}>
              <Controller
                name="st_dt"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <DateRangeSelect
                    size={SIZE.mini}
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "start_date",
                    })}
                    placeholder={intl.formatMessage({
                      id: " ",
                    })}
                    requiredAstric={true}
                    disabled={isOpen.type === EDIT ? true : false}
                    value={field.value}
                    onChange={(e) => field.onChange(e.date)}
                    minDate={new Date()}
                  />
                )}
              />
            </div>
            <div style={{ width: "200px" }}>
              <Controller
                name="typ"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // creatable={creatablePermission ? true : false}
                    size="mini"
                    clearable={false}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "type_review",
                    })}
                    placeholder={intl.formatMessage({
                      id: "type_review",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["report_communication_type"]}
                  />
                )}
              />
            </div>
          </div>

          <div style={{ textAlign: "left" }}>
            {typ && typ.length ? (
              <Typography type="p" style={{ marginBottom: "12px" }}>
                Enter{" "}
                {format.rd({
                  id: typ && typ[0] && typ[0].id,
                  name: "report_communication_type",
                })}{" "}
                Details
              </Typography>
            ) : (
              <></>
            )}

            {typ && typ[0] && typ[0].id === "EML" && (
              <Controller
                name="email"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <>
                    <TextBoxTag
                      // disabled={disableContent}
                      {...field}
                      name={field.name}
                      // caption={`${`${`Business Email `}${smsGateWayDatas}${" will be kept in CC. "}${`You can review the Business Email in Organization Settings - Contact Details`}`}`}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "email",
                      })}
                      placeholder={intl.formatMessage({
                        id: "bcc",
                      })}
                      size="mini"
                      value={field.value}
                      validation={true}
                      validationFunc={(value) => {
                        let rg = new RegExp(
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                        );
                        return rg.test(value);
                      }}
                      setError={() =>
                        setError(field.name, {
                          type: String,
                          message: "Invalid Email ID",
                        })
                      }
                      clearErrors={() => {
                        clearErrors(field.name, {
                          message: "",
                        });
                      }}
                    />
                  </>
                )}
              />
            )}

            {typ && typ[0] && typ[0].id === "FTP" && (
              <div
                style={{
                  display: "flex",
                  gap: "5px 20px",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ width: "200px" }}>
                  <Controller
                    name="serv_adr"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          requiredAstric={true}
                          fullWidth
                          name={field.name}
                          size={"mini"}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "server",
                          })}
                          placeholder={intl.formatMessage({
                            id: "server",
                          })}
                          value={field.value}
                        />
                      </>
                    )}
                  />
                </div>
                <div style={{ width: "200px" }}>
                  <Controller
                    name="port"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          requiredAstric={true}
                          fullWidth
                          name={field.name}
                          size={"mini"}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "port",
                          })}
                          placeholder={intl.formatMessage({
                            id: "port",
                          })}
                          value={field.value}
                        />
                      </>
                    )}
                  />
                </div>
                <div style={{ width: "200px" }}>
                  <Controller
                    name="usr_id"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          requiredAstric={true}
                          fullWidth
                          name={field.name}
                          size={"mini"}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "user_id",
                          })}
                          placeholder={intl.formatMessage({
                            id: "user_id",
                          })}
                          value={field.value}
                        />
                      </>
                    )}
                  />
                </div>
                <div style={{ width: "200px" }}>
                  <Controller
                    name="pswd"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          requiredAstric={true}
                          fullWidth
                          name={field.name}
                          size={"mini"}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "password",
                          })}
                          placeholder={intl.formatMessage({
                            id: "password",
                          })}
                          value={field.value}
                        />
                      </>
                    )}
                  />
                </div>
                <div style={{ width: "200px" }}>
                  <Controller
                    name="pth"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          requiredAstric={true}
                          fullWidth
                          name={field.name}
                          size={"mini"}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "upload_file_path",
                          })}
                          placeholder={intl.formatMessage({
                            id: "upload_file_path",
                          })}
                          value={field.value}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              marginTop: "15px",
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              padding: "10px 20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ height: "", width: "96px" }}>
              <TextButton
                type="button"
                fullWidth
                size={SIZE.mini}
                kind={KIND.tertiary}
                // onClick={close}
                onClick={() => {
                  reset();
                  close();
                }}
              >
                Cancel
              </TextButton>
            </div>
            <div style={{ height: "", width: "96px" }}>
              <TextButton
                type="button"
                fullWidth
                size={SIZE.mini}
                kind={KIND.primary}
                isLoading={useEditReport.isLoading || useAddReport.isLoading}
                disabled={useEditReport.isLoading || useAddReport.isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </TextButton>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddReportModal;
