import React, { useContext } from "react";
import { TextButton, SIZE, KIND } from "../TextButton";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { LabelLarge, ParagraphLarge, ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";
import { SelectBox } from "../SelectBox";
import { MaxyfiProvider } from "../../providers/MaxyfiProvider";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { assignMulCusRel } from "../../services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { useSelector } from "react-redux";
import moment from "moment";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";

const AssignRelationManagerForm = ({
  selectedCustomer,
  handleModal,
  toggleAllRowsSelected,
  refetch,
  isAllRowsSelected,
  queryFilter,
}) => {
  let {
    currentOrganization,
    users,
    referenceData,
    restrictions,
    space,
    appAccessRest,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  const { filters, globalFilters } = useSelector(
    (s) => s.customerSummary.tableCurrentState
  );
  const { tableCurrentState, gridStructure } = useSelector(
    (state) => state.customerSummary
  );

  let rm = users.filter((i) => i.active);

  let findingUser = rm?.filter((i) =>
    i.organization?.includes(currentOrganization)
  );

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];

  const assignRelationManager = useMutation(
    (relMang) =>
      assignMulCusRel({
        ...relMang,

        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
          toggleAllRowsSelected(false);
        }
        refetch();
        handleModal();

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);
        reset();
        handleModal();
        refetch();
      },
    }
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_all_customer: false,
    },
  });
  const onSubmit = async (data) => {
    let newFilter = dynamicQueryFilter({
      gridStructure: gridStructure,
      // customerQueryFilter: queryFilter && queryFilter.display_name,
      timeZone: currentDefaultFormatDetails.time_zone,
      customerFilter: filters,
    });

    let values = {
      rel_manager: getSelectValues(data.choose_relation_manager, true),
      ...(data.is_all_customer
        ? {
            query: newFilter ? newFilter : {},
            filters: {
              ...globalFilters,
              ...appAccessRest,
              needs_actions: [...NeedsActionFilter],
              stage: [...FilteredCustomerStage],
              search_value: queryFilter && queryFilter.display_name,
            },
          }
        : { customerId: selectedCustomer }),
      is_all_customer: data.is_all_customer,
    };

    await assignRelationManager.mutateAsync(values);
    // toggleAllRowsSelected(false);
  };

  const intl = useIntl();

  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ParagraphLarge style={{ display: "flex", paddingBottom: "5px" }}>
          Select Relation Manager
        </ParagraphLarge>
        <Controller
          defaultValues={[]}
          name="choose_relation_manager"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              size={SIZE.compact}
              {...field}
              name={field.name}
              requiredAstric={true}
              multi
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "choose_relation_manager",
              })}
              placeholder={intl.formatMessage({
                id: "choose_relation_manager",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={findingUser.map((i) => {
                return { label: i.displayName, id: i.id };
              })}
            />
          )}
        />
        {isAllRowsSelected && (
          <Controller
            name="is_all_customer"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall style={{ color: "#333860" }}>
                  Update all the matching consumer records
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        )}
        <div
          style={{
            // marginTop: "15px",
            textAlign: "center",
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
            // paddingBottom: "20px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.compact}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
              //   disabled={isSubmitting || smsConfigBtn}
            >
              Apply
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default AssignRelationManagerForm;
