import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Icon, Typography } from "../../components_v2";
import { TextBox } from "../../components/TextBox";
import { useIntl } from "react-intl";
import { SelectBox } from "../../components/SelectBox";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { ProgressBar } from "baseui/progress-bar";
import { useInfiniteQuery, useMutation } from "react-query";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  deleteSingleUser,
  deleteWorklistFn,
  patchWorklistFn,
  worklistMain,
} from "../../services";
import useFormat from "../../hooks/useFormat";
import _ from "lodash";
import WorkListDrawer from "./WorkListDrawer";
import { setDrawerState } from "../../redux/actions";
import { ModalContext } from "../../providers/ModalProvider";
import { toast } from "react-toastify";
import WarningImage from "../../assets/img/warning.png";
import queryClient from "../../providers/queryClient";
import { IconButton, KIND } from "../../components/IconButton";
import {
  DELETE_WORKLIST,
  EDIT_WORKLIST,
  REMOVE_USER_WORKLIST,
} from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { StatefulTooltip } from "baseui/tooltip";
import { useDebouncedCallback } from "use-debounce";
import { Skeleton } from "baseui/skeleton";

let initState = {
  nm: "", // name
  nm_dbon: "",
  filter_type: "TODAY",
  sort: [],
  page: 1,
  page_size: 1,
};

let quickSelectOptions = [
  {
    label: "All",
    id: "ALL",
  },
  {
    label: "Today",
    id: "TODAY",
  },
  {
    label: "Overdue",
    id: "OVERDUE",
  },
  {
    label: "Future",
    id: "FUTURE",
  },
  {
    label: "Completed",
    id: "COMPLETED",
  },
];

const LoaderSkeleton = ({ count }) => {
  return (
    <>
      {Array.from({ length: count }, (_, i) => {
        return (
          <div className={"wl_card_container"}>
            <Skeleton
              rows={0}
              height="25px"
              width="200px"
              animation
              overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    borderRadius: "5px",
                  }),
                },
              }}
            />
            <Skeleton
              rows={0}
              height="115px"
              width="100%"
              animation
              overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    borderRadius: "5px",
                    marginTop: "10px",
                  }),
                },
              }}
            />

            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Skeleton
                rows={0}
                height="25px"
                width="100%"
                animation
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      borderRadius: "5px",
                      marginTop: "10px",
                    }),
                  },
                }}
              />
              <Skeleton
                rows={0}
                height="25px"
                width="50px"
                animation
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      borderRadius: "5px",
                      marginTop: "10px",
                    }),
                  },
                }}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

const WorklistCardWraper = ({
  children,
  item,
  setIsDrawer,
  isDrawer,
  ...rest
}) => {
  const format = useFormat();
  const { currentOrganization } = useContext(MaxyfiContext);
  const { handleModal } = useContext(ModalContext);
  const deleteWorklist = useMutation(
    (checkInData) =>
      deleteWorklistFn({
        ...checkInData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          `WORKLIST_WORK_COUNT-${currentOrganization}`,
        ]);
        queryClient.invalidateQueries([`WORKLIST_MAIN-${currentOrganization}`]);
      },
    }
  );

  const activeWorklist = useMutation(
    (checkInData) =>
      patchWorklistFn({
        ...checkInData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          `WORKLIST_WORK_COUNT-${currentOrganization}`,
        ]);
        queryClient.invalidateQueries([`WORKLIST_MAIN-${currentOrganization}`]);
      },
    }
  );
  return (
    <div>
      <div
        className={
          item?.is_user_active
            ? "wl_card_container wl_active_card"
            : "wl_card_container"
        }
      >
        <div className="wl_icon_wraper">
          <div className="wl_add_ic">
            {item?.is_user_allocated && (
              <IconButton
                size="mini"
                kind={KIND.tertiary}
                isLoading={activeWorklist.isLoading}
                disabled={activeWorklist.isLoading}
                onClick={() => {
                  activeWorklist.mutateAsync({
                    worklistId: item._id,
                  });
                }}
                $style={{
                  padding: "0px",
                  borderRadius: "5px",
                }}
              >
                <Icon
                  icon={item?.is_user_active ? "remove_dash" : "plus"}
                  size={18}
                />
              </IconButton>
            )}
          </div>
          <>
            <RBACWrapper role={EDIT_WORKLIST} type={"PERMISSION"}>
              <div className="wl_edit_ic">
                <IconButton
                  size="mini"
                  kind={KIND.tertiary}
                  onClick={() =>
                    setIsDrawer({
                      type: "EDIT",
                      isOpen: true,
                      data: item,
                    })
                  }
                  $style={{
                    padding: "0px",
                    borderRadius: "5px",
                  }}
                >
                  <Icon icon="edit" size={18} />
                </IconButton>
              </div>
            </RBACWrapper>
            <RBACWrapper role={DELETE_WORKLIST} type={"PERMISSION"}>
              <div className="wl_delete_ic">
                <IconButton
                  size="mini"
                  kind={KIND.tertiary}
                  isLoading={deleteWorklist.isLoading}
                  disabled={deleteWorklist.isLoading}
                  onClick={() => {
                    handleModal({
                      title: "You are about to delete Worklist",
                      content: "",
                      successCallback: () => {
                        deleteWorklist.mutateAsync({
                          worklistId: item._id,
                        });
                      },
                      image: WarningImage,
                      buttonText: "Confirm",
                      closeButtonText: "Cancel",
                    });
                  }}
                  $style={{
                    padding: "0px",
                    borderRadius: "5px",
                  }}
                >
                  <Icon icon="delete" size={18} isPressable />
                </IconButton>
              </div>
            </RBACWrapper>
          </>
        </div>
        {/* <div
          className="wl_edit_ic"
         
        >
          <Icon icon="edit" size={18} isPressable />
        </div> */}
        <div className="card_header_wraper">
          <Typography type="h3" className="wl_name">
            {_.get(item, "nm", "-")}
          </Typography>
          <Typography subType={"small"} className="text-secondary wl-date-al">
            {item.dt
              ? format.date({ value: Number(item.dt), isOrg: true })
              : ""}
          </Typography>
        </div>
        <div style={{ height: "100px", overflow: "hidden" }}>{children}</div>
        <div style={{ height: "15px" }}>
          {item.alc_usr.length > 5 ? (
            <Typography
              type="a"
              onClick={() =>
                setIsDrawer({
                  type: "EDIT",
                  isOpen: true,
                  data: item,
                })
              }
            >
              +{item.alc_usr.length - 5} more
            </Typography>
          ) : (
            <></>
          )}
        </div>
        <Typography subType={"small"} className="text-secondary worked_count">
          <span>{Number(item.prc_cnt) > 0 ? Number(item.prc_cnt) : 0}</span> /{" "}
          {Number(item?.cnt) > 0 ? Number(item?.cnt) : 0} Worked
        </Typography>
        <ProgressBar
          value={Number(item.prc_cnt) > 0 ? Number(item.prc_cnt) : ""}
          maxValue={Number(item?.cnt) > 0 ? Number(item?.cnt) : 0}
          overrides={{
            BarContainer: {
              style: ({ $theme }) => ({
                marginLeft: "0px",
                marginRight: "0px",
                marginBottom: "0px",
                marginTop: "0px",
              }),
            },
          }}
        />
        <div className="wl-card-footer-wraper">
          <Typography subType={"small"} className="text-secondary">
            {item.uby
              ? format.rd({ name: "users", id: item?.uby })
              : format.rd({ name: "users", id: item?.cby })}{" "}
            /{" "}
            {item.uat
              ? format.date({
                  isOrg: true,
                  includeTime: true,
                  value: item?.uat,
                })
              : format.date({
                  isOrg: true,
                  includeTime: true,
                  value: item?.cat,
                })}
          </Typography>
          <StatefulTooltip
            accessibilityType={"tooltip"}
            content={() =>
              item.cmt ? (
                <div
                  style={{
                    minWidth: "auto",
                    maxWidth: "200px",
                    whiteSpace: "break-spaces",
                  }}
                >
                  {item.cmt ? item.cmt : "-"}
                </div>
              ) : (
                <></>
              )
            }
          >
            {item.cmt ? (
              <div>
                <Icon icon="request_info" size={18} color={"#787878"} />
              </div>
            ) : (
              <></>
            )}
          </StatefulTooltip>
        </div>
      </div>
    </div>
  );
};

const WorklistContainer = () => {
  const intl = useIntl();
  const format = useFormat();
  const { handleModal } = useContext(ModalContext);
  const { currentOrganization, referenceData } = useContext(MaxyfiContext);

  const [isWorklistState, setIsWorklistState] = useState({ ...initState });

  const debounceFilter = useDebouncedCallback((filter) => {
    setIsWorklistState({ ...isWorklistState, nm_dbon: filter });
  }, 2000);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
  } = useInfiniteQuery(
    [
      `WORKLIST_MAIN-${currentOrganization}`,
      {
        filters: {
          filter_type: isWorklistState.filter_type,
          sort: isWorklistState?.sort?.[0]?.id,
          ...(isWorklistState.nm_dbon.length
            ? { nm: isWorklistState.nm_dbon }
            : {}),
        },
      },
    ],
    async ({ queryKey, pageParam = 1 }) => {
      let { filters } = queryKey[1];
      return await worklistMain({
        page: pageParam,
        page_size: 20,
        organization: currentOrganization,
        filters: filters,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = lastPage.data.totalPages;
        return allPages.length < maxPages ? allPages.length + 1 : undefined;
      },
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
  const [isDrawer, setIsDrawer] = useState({
    type: "",
    isOpen: false,
    data: {},
  });

  const allData = useMemo(() => {
    return data?.pages?.flatMap((page) => page?.data?.docs) || []; // Remove duplicates by ID
  }, [data]);

  // Virtualizer setup

  const parentRef = useRef(null);

  // const rowVirtualizer = useVirtualizer({
  //   count: allData.length,
  //   getScrollElement: () => parentRef.current,
  //   estimateSize: () => 100, // Approximate row height
  //   overscan: 5, // Load extra items before and after viewport
  //   key: allData.length, // Add a key to force re-render when data changes
  // });

  const handleScroll = useCallback(
    _.debounce(() => {
      const container = parentRef.current;
      if (!container) return;

      const { scrollHeight, scrollTop, clientHeight } = container;

      if (hasNextPage && !isFetchingNextPage) {
        if (scrollHeight - scrollTop <= clientHeight + 50) {
          // Increased buffer
          fetchNextPage();
        }
      }
    }, 200), // Reduced debounce to make scrolling more responsive
    [hasNextPage, isFetchingNextPage]
  );

  const deleteUserWorklist = useMutation(
    (checkInData) =>
      deleteSingleUser({
        ...checkInData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          `WORKLIST_WORK_COUNT-${currentOrganization}`,
        ]);
        queryClient.invalidateQueries([`WORKLIST_MAIN-${currentOrganization}`]);
      },
    }
  );

  return (
    <>
      <WorkListDrawer isDrawer={isDrawer} setIsDrawer={setIsDrawer} />
      <div className="wl_filter_container_parent">
        <div className="wl_filter_container">
          {quickSelectOptions.map((e) => (
            <div
              className={
                isWorklistState.filter_type == e.id
                  ? "filter_active"
                  : "report_filter"
              }
              onClick={() => {
                setIsWorklistState({ ...isWorklistState, filter_type: e.id });
              }}
            >
              <Typography>{e.label}</Typography>
            </div>
          ))}
        </div>
        <div className="wl_sort_search_container">
          <div style={{ width: "200px" }}>
            <SelectBox
              name="sort"
              size={"mini"}
              clearable={false}
              label={intl.formatMessage({
                id: "sort_by_worklist",
              })}
              placeholder={intl.formatMessage({
                id: "sort_by_worklist",
              })}
              value={isWorklistState.sort}
              onChange={(e) => {
                setIsWorklistState({ ...isWorklistState, sort: e.value });
              }}
              options={(referenceData && referenceData["worklist_sort"]) || []}
            />
          </div>
          <div style={{ width: "200px" }}>
            <TextBox
              name="nm"
              fullWidth
              clearable
              size={"mini"}
              value={isWorklistState.nm}
              onChange={(e) => {
                debounceFilter(e.target.value);
                setIsWorklistState({ ...isWorklistState, nm: e.target.value });
              }}
              label={intl.formatMessage({
                id: "search_by_name",
              })}
              placeholder={intl.formatMessage({
                id: "search_by_name",
              })}
            />
          </div>
        </div>
      </div>
      <div className="wl_body_wraper" ref={parentRef} onScroll={handleScroll}>
        {allData && !allData.length && !isLoading & !isFetching ? (
          <div
            style={{
              display: "flex",
              flex: "1",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography type="h2">
              No worklists available. Please check again later.
            </Typography>
          </div>
        ) : (
          <></>
        )}

        {allData.map((virtualRow) => {
          const item = virtualRow;
          return (
            <WorklistCardWraper
              key={item._id}
              item={item}
              isDrawer={isDrawer}
              setIsDrawer={setIsDrawer}
              {...virtualRow}
            >
              {_.get(item, "alc_usr", [])
                .sort((a, b) =>
                  a.prc_cnt === b.prc_cnt ? 1 : a.prc_cnt ? -1 : 1
                )
                .map((e) => {
                  return (
                    <div className="wl_user_list_wraper">
                      <Typography>
                        {e?.usr
                          ? format.rd({ name: "users", id: e?.usr })
                          : "-"}
                      </Typography>
                      <Typography
                        subType={"small"}
                        style={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "17px",
                          color: _.get(e, "lst_act_dt", "") ? "" : "#fd372a",
                        }}
                      >
                        {_.get(e, "lst_act_dt", "")
                          ? format.date({
                              value: e?.lst_act_dt,
                              includeTime: true,
                              isOrg: true,
                            })
                          : "Yet to Start"}
                      </Typography>
                      <Typography subType={"small"}>
                        {e?.prc_cnt ? e?.prc_cnt : "-"}
                      </Typography>
                      <div style={{ width: "18px" }}>
                        <RBACWrapper
                          role={REMOVE_USER_WORKLIST}
                          type={"PERMISSION"}
                        >
                          <div className="wl_user_delete">
                            <Icon
                              icon="remove"
                              size={18}
                              isPressable
                              onClick={() => {
                                handleModal({
                                  title: "",
                                  content:
                                    "You are about to remove user from worklist, Click confirm to continue",
                                  successCallback: () => {
                                    deleteUserWorklist.mutateAsync({
                                      worklistId: item._id,
                                      type: "REMOVE",
                                      user: e?.usr,
                                    });
                                  },
                                  image: WarningImage,
                                  buttonText: "Confirm",
                                  closeButtonText: "Cancel",
                                });
                              }}
                            />
                          </div>
                        </RBACWrapper>
                      </div>
                    </div>
                  );
                })}
            </WorklistCardWraper>
          );
        })}
        {isFetching || isLoading ? <LoaderSkeleton count={4} /> : <></>}
      </div>
    </>
  );
};

export default WorklistContainer;
