import React, { useState } from "react";
import { Typography } from "../components_v2";

const ExpandableText = ({ description, width, textAlign, noOfLine }) => {
  const [isViewMore, setIsViewMore] = useState(false);

  return (
    <div style={{ width: width, textAlign: textAlign }}>
      <Typography
        type="p"
        className="regular"
        subType="regular"
        style={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitLineClamp: isViewMore ? "unset" : noOfLine, // Show only 2 lines when collapsed
          whiteSpace: isViewMore ? "normal" : "pre-wrap",
        }}
      >
        {description}
      </Typography>

      {/* Show "View More" only if content is truncated */}
      {description?.length > 100 && ( // Adjust the threshold based on your text length
        <a className="mx-a" onClick={() => setIsViewMore(!isViewMore)}>
          {" "}
          {isViewMore ? "View Less" : "View More"}
        </a>
        // <button
        //   onClick={() => setIsViewMore(!isViewMore)}
        //   style={{
        //     marginTop: "10px",
        //     background: "none",
        //     border: "none",
        //     color: "blue",
        //     cursor: "pointer",
        //     opacity: "0.6",
        //     textDecoration: "underline",
        //   }}
        // >
        //
        // </button>
      )}
    </div>
  );
};

export default ExpandableText;
