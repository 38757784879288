import { Controller, useForm, useWatch } from "react-hook-form";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import React, { useContext, useMemo, useRef, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { SelectBox } from "../../components/SelectBox";
import { TextButton, SIZE, KIND } from "../../components/TextButton";
import { useIntl } from "react-intl";
import { TextArea, SIZE as TASize } from "../../components/TextArea";
import { ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";
import {
  addRequestInformation,
  updateRequestInformation,
  customerInvoiceOverview,
  getHolidayBlackoutHolidayDate,
  getCommunicationTemplateFinalContent,
} from "../../services";
import { getSubCategory } from "../../services/documentSystem";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import {
  requestInformationAttachment,
  requestInformationRemoveAttachment,
  requestInformationUpdateAttachment,
  requestInformationReset,
} from "../../redux/actions";
import { Plus } from "baseui/icon";
import EmailAttachment from "../../components/EmailAttachment/EmailAttachment";
import {
  ADD_LIST_VALUES,
  ASSIGN_RI_TO_CLIENT,
  CLIENT_PORTAL_ACCESS,
} from "../../providers/RBACProvider/permissionList";
import "quill-mention";
import _ from "lodash";
import "quill-mention/dist/quill.mention.css";
import queryClient from "../../providers/queryClient";
import { CO_TIMELINE_, CUSTOMER_OVERVIEW_ } from "../../constants";
import useFormat from "../../hooks/useFormat";
import { Icon, Typography } from "../../components_v2";
import RichTextEditorEmail from "../../containers_v2/CustomerOverview/RichTextEditorEmail";

function RequestClarificationForm({
  isEdit = false,
  defaultValues = {},
  resourceId,
  sub_category = "",
  customerId,
  ...props
}) {
  const dispatch = useDispatch();
  const attachments = useSelector((s) => s.requestInformation.attachments);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  let {
    currentOrganization,
    users,
    referenceData,
    currentDefaultFormatDetails,
    userInfo,
    space,
    myTeam,
  } = useContext(MaxyfiContext);
  const editorRef = useRef(null);
  const editorRef1 = useRef(null);
  const isClientAccess =
    space && space?.role_permissions?.includes(CLIENT_PORTAL_ACCESS);
  const riqPermission =
    space &&
    space.role_permissions &&
    space.role_permissions.includes(ASSIGN_RI_TO_CLIENT);

  const orgUsers = useMemo(() => {
    let orgBusiness = referenceData["users"].filter((e) => {
      return (
        e?.organization?.includes(currentOrganization) &&
        e.active &&
        !(
          e.is_client_login == true &&
          !e.business_unit
            ?.map((bt) => bt.business_unit_id)
            ?.includes(props.business_unit)
        )
      );
    });
    return orgBusiness || [];
  }, [props.business_unit]);

  const filterRiqUser = orgUsers?.filter(
    (e) => e && e.is_client_login !== true
  );

  // const filterRiqUser = useMemo(() => {
  //   const filterRiqUser = orgUsers?.filter(
  //     (e) => e && e.is_client_login !== true
  //   );

  //   //
  //   // const filterBusinessUnitUser = filterRiqUser.filter((e) => {
  //   //   if (e && e.business_unit && Array.isArray(e.business_unit)) {
  //   //     return e.business_unit
  //   //       .map((bt) => bt.business_unit_id)
  //   //       .includes(props.business_unit);
  //   //   }
  //   // });
  //   return filterBusinessUnitUser;
  // }, [props.business_unit]);

  useEffect(() => {
    return () => {
      dispatch(requestInformationReset());
    };
  }, []);
  const businessUnit = referenceData["business_unit_list"]
    ? referenceData["business_unit_list"].find(
        (e) =>
          e.id ===
          (isEdit
            ? props?.business_unit
            : _.get(customerData, "data.doc.business_unit"))
      )
    : null;

  const businessUnits = referenceData["business_unit_list"].find(
    (item) => item.id === props?.business_unit
  );

  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...(isEdit
        ? { ...defaultValues }
        : {
            category: "CLARIFICATION",
          }),
    },
  });

  const intl = useIntl();

  let category = watch("category");
  let Subcategory = watch("sub_category");
  let { review_date, details } = watch();

  const [agentInstru, setInstruc] = useState("");
  const [isSubject, setSubject] = useState("");

  const { data, isFetched, isError } = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: {
          date: review_date
            ? [
                moment(review_date)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf(),
              ]
            : null,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: customerId,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: review_date ? true : false,
    }
  );

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: {
        "image/jpg": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      validator: (file) => {
        if (file && file.size > 10000000) {
          return {
            code: "File is too-large",
            message: "File Size is Largen than 1MB",
          };
        }
      },
      onDrop: (acceptedFiles) => {
        let arrObj = acceptedFiles.map((fileDoc) => {
          let { path, type } = fileDoc;

          let splitType = type.split("/");

          return {
            name: path,
            type: splitType[1],
            mimeType: type,
            fileDoc,
          };
        });

        dispatch(requestInformationAttachment(arrObj));
      },
    });

  const subCategory = useQuery(
    [`sub-category-${customerId}`],
    async () => {
      return await getSubCategory({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const invoices = useQuery(
    [`invoices-${customerId}`],
    async () => {
      return await customerInvoiceOverview({
        organization: currentOrganization,
        customer_id: customerId,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const BlockEmbed = ReactQuill.Quill.import("blots/embed");
  class TemplateMarker extends BlockEmbed {
    static create(value) {
      let node = super.create(value);

      node.setAttribute("class", "quill-bolt");
      node.setAttribute("data-title", value.value);
      node.setAttribute("data-user-id", value.id);

      node.innerText = `@${value.value}`;

      return node;
    }

    static value(node) {
      return {
        id: node.getAttribute("data-user-id"),
        value: node.getAttribute("data-title"),
      };
    }
  }

  // TemplateMarker.blotName = "TemplateMarker";
  TemplateMarker.tagName = "span";

  ReactQuill.Quill.register({ "formats/TemplateMarker": TemplateMarker });

  const modules = useMemo(
    () => ({
      toolbar: 0,
      clipboard: {
        matchVisual: false,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        // blotName: "TemplateMarker",
        mentionDenotationChars: ["@"],
        dataAttributes: [
          "id",
          "value",
          "denotationChar",
          "link",
          "target",
          "disabled",
          "programmaticInsert",
        ],

        source: (searchTerm, renderList, mentionChar) => {
          const renderListSize = 5;

          let finalUsers = users
            ?.filter(
              (i) => i.organization?.includes(currentOrganization) && i.active
            )
            .filter((e) => e.id !== userInfo._id);

          let atValues = finalUsers
            ? finalUsers.map((i) => {
                return { value: i.displayName, id: i.id };
              })
            : [];

          let values;
          if (mentionChar === "@") {
            values = atValues;
          }

          if (searchTerm.length === 0) {
            renderList(values.slice(0, renderListSize), searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                values[i].value
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) >= 0
              )
                matches.push(values[i]);

            renderList(matches.slice(0, renderListSize), searchTerm);
          }
        },
      },
    }),
    []
  );

  const addRequestInformationMutation = useMutation(
    (contactData) =>
      addRequestInformation({
        ...contactData,
        organization: currentOrganization,
        customerId: customerId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        queryClient.invalidateQueries(
          `customer-${customerId}-request-information`
        );
        queryClient.invalidateQueries([`${CO_TIMELINE_}${customerId}`]);
        props.reset();
      },
    }
  );

  const updateRequestInformationMutation = useMutation(
    (contactData) =>
      updateRequestInformation({
        ...contactData,
        organization: currentOrganization,
        customerId: customerId,
        resourceId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        // reset();
        setValue("comment", "");
        dispatch(requestInformationReset());
        queryClient.invalidateQueries(
          `customer-${customerId}-request-information`
        );
        queryClient.invalidateQueries(
          `customer-${customerId}-request-${resourceId}`
        );
        queryClient.invalidateQueries([`${CO_TIMELINE_}${customerId}`]);
      },
    }
  );
  const addValuePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);
  const onSubmit = async (data) => {
    let {
      category,
      sub_category,
      invoices = [],
      assigned_to = [],
      review_date,
      status = [],
      comment = "",
    } = data;

    const parser = new DOMParser();
    const doc = parser.parseFromString(data.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values;

    if (category === "CLARIFICATION") {
      values = {
        ...(!isEdit
          ? {
              category,
              ...(isSubject
                ? {
                    subject: isSubject,
                  }
                : {}),
              details: agentInstru ? agentInstru : details,
              sub_category: getSelectValues(sub_category, true),
              assigned_to: getSelectValues(assigned_to),
              ...(review_date
                ? {
                    review_date: review_date,
                  }
                : {}),
            }
          : {
              status: getSelectValues(status),
              mention_users: uniqueMentionedUsers,
              attachments:
                attachments && Array.isArray(attachments)
                  ? attachments.filter((e) => e.fileRef).map((e) => e.fileRef)
                  : [],
              comment,
            }),

        ...(isEdit && !isClientAccess ? { details: details } : {}),

        invoices: getSelectValues(invoices, true),
        assigned_to: getSelectValues(assigned_to),
        ...(assigned_to && assigned_to[0] && assigned_to[0].isClient
          ? { assign_type: "BUSINESS_UNIT" }
          : { assign_type: "USER" }),
        ...(review_date
          ? {
              review_date: moment
                .tz(
                  `${new Date(review_date).getFullYear()}-${
                    new Date(review_date).getMonth() + 1
                  }-${new Date(review_date).getDate()}`,
                  "YYYY-MM-DD",
                  currentDefaultFormatDetails?.time_zone
                )
                .utc()
                .valueOf(),
            }
          : {}),
      };
    } else {
      values = {
        ...(!isEdit
          ? {
              category,

              details: details,
              sub_category: getSelectValues(sub_category, true),
            }
          : {
              status: getSelectValues(status),
              mention_users: uniqueMentionedUsers,
              attachments:
                attachments && Array.isArray(attachments)
                  ? attachments.filter((e) => e.fileRef).map((e) => e.fileRef)
                  : [],
              comment,
            }),

        ...(isEdit && !isClientAccess ? { details: details } : {}),

        invoices: getSelectValues(invoices, true),
        assigned_to: getSelectValues(assigned_to),
        ...(assigned_to && assigned_to[0] && assigned_to[0].isClient
          ? { assign_type: "BUSINESS_UNIT" }
          : { assign_type: "USER" }),
        ...(review_date
          ? {
              review_date: moment
                .tz(
                  `${new Date(review_date).getFullYear()}-${
                    new Date(review_date).getMonth() + 1
                  }-${new Date(review_date).getDate()}`,
                  "YYYY-MM-DD",
                  currentDefaultFormatDetails?.time_zone
                )
                .utc()
                .valueOf(),
            }
          : {}),
      };
    }

    if (!isEdit) {
      await addRequestInformationMutation.mutateAsync(values);
    } else {
      await updateRequestInformationMutation.mutateAsync(values);
    }
  };
  const format = useFormat();
  const templateIdInsert = useMemo(() => {
    if (
      (getSelectValues(Subcategory) || sub_category) &&
      _.get(businessUnit, "rir", []).length > 0
    ) {
      return _.get(businessUnit, "rir", []).find(
        (item) => item.cat === (getSelectValues(Subcategory) || sub_category)
      );
    }
  }, [Subcategory || sub_category]);

  const rirCats = _.get(businessUnit, "rir", []).map((item) => item.cat);

  useEffect(() => {
    if (
      getSelectValues(Subcategory) === _.get(templateIdInsert, "cat", "") &&
      _.get(templateIdInsert, "ass", "") &&
      category === "CLARIFICATION" &&
      resourceId === undefined &&
      templateIdInsert?.enb
    ) {
      const referenceUserData = referenceData["users"].find(
        (id) => id.id === _.get(templateIdInsert, "ass", "")
      );

      const referenceBusiness = referenceData["business_unit_list"].find(
        (id) => id.id === _.get(templateIdInsert, "ass", "")
      );

      if (referenceUserData) {
        setValue("assigned_to", [
          {
            id: _.get(templateIdInsert, "ass", ""),
            label: referenceUserData?.label,
            isClient: false,
          },
        ]);
      } else if (referenceBusiness) {
        setValue("assigned_to", [
          {
            id: _.get(templateIdInsert, "ass", ""),
            label: referenceBusiness?.client_name,
            isClient: true,
          },
        ]);
      }
    } else {
      setValue("assigned_to", []);
    }

    //need to add these place
    if (
      getSelectValues(Subcategory) === _.get(templateIdInsert, "cat", "") &&
      _.get(templateIdInsert, "rd", null) &&
      resourceId === undefined &&
      category === "CLARIFICATION" &&
      templateIdInsert?.enb
    ) {
      const daysToAdd = Number(_.get(templateIdInsert, "rd", 0)); // Convert to a number if needed
      const newReviewDate = moment()
        .tz(currentDefaultFormatDetails.time_zone)
        .startOf("day")
        .add(daysToAdd, "days")
        .valueOf(); // Get timestamp

      setValue("review_date", newReviewDate);
    } else {
      setValue("review_date", undefined);
    }
  }, [
    _.get(Subcategory, "[0].id", ""),
    Subcategory?.length,
    templateIdInsert?.cat,
    category,
  ]);

  useEffect(() => {
    if (defaultValues?.assigned_to) {
      setValue("assigned_to", defaultValues?.assigned_to);
    }
  }, [defaultValues, isEdit]);

  useEffect(() => {
    const getTemplateContent = async () => {
      let templateContent = await getCommunicationTemplateFinalContent({
        organization: currentOrganization,
        templateId: _.get(templateIdInsert, "tem", ""),
        entity: "CUSTOMER",
        id: customerId,
      });

      if (
        templateContent.data &&
        templateContent.data.doc &&
        _.get(templateIdInsert, "tem", "") &&
        (getSelectValues(Subcategory) || sub_category) ===
          _.get(templateIdInsert, "cat", "") &&
        templateIdInsert?.enb &&
        !isEdit &&
        resourceId === undefined
      ) {
        let { subject, value } = templateContent.data.doc;

        setValue("content", value?.replaceAll(String.fromCharCode(92), ""));
        setInstruc(value?.toString());
        setSubject(subject);
      } else {
        setInstruc("");
      }
    };

    if (_.get(templateIdInsert, "tem", "")) {
      getTemplateContent();
    }
  }, [
    _.get(Subcategory, "[0].id", ""),
    _.get(templateIdInsert, "tem", ""),
    category,
    sub_category,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!isEdit ? (
        <div className="clarification-category">
          <ParagraphSmall>Category: </ParagraphSmall>
          <div
            className={`clarification-category-button ${
              category === "CLARIFICATION"
                ? "clarification-category-button--selected"
                : ""
            }`}
            onClick={() => {
              setValue("category", "CLARIFICATION");
            }}
          >
            Clarification
          </div>
          <div
            className={`clarification-category-button ${
              category === "DOCUMENT"
                ? "clarification-category-button--selected"
                : ""
            }`}
            onClick={() => {
              setValue("category", "DOCUMENT");
            }}
          >
            Document
          </div>
        </div>
      ) : (
        <></>
      )}
      {isEdit ? (
        <>
          <div style={{ paddingBottom: "5px" }}>
            <TextButton
              kind="tertiary"
              size="mini"
              type="button"
              onClick={() => {
                open();
              }}
            >
              <Plus size={18} />
              Add Document
            </TextButton>
            {attachments && Array.isArray(attachments) ? (
              attachments.map((e) => (
                <EmailAttachment
                  {...e}
                  currentOrganization={currentOrganization}
                  id={customerId}
                  subCategory={sub_category}
                  removeAttchFile={requestInformationRemoveAttachment}
                  updatingDeskUpload={requestInformationUpdateAttachment}
                />
              ))
            ) : (
              <></>
            )}
          </div>
          <div className="rq-quill" style={{ marginBottom: "10px" }}>
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <ReactQuill
                  {...field}
                  name={field.name}
                  id={"editor"}
                  ref={editorRef}
                  theme="snow"
                  placeholder="comments"
                  value={field.value}
                  modules={modules}
                  // modules={{}}
                  style={{ height: "50px" }}
                />
              )}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="clarification_form">
        {!isEdit ? (
          <Controller
            name="sub_category"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                requiredAstric={true}
                clearable={false}
                name={field.name}
                size={SIZE.mini}
                label={intl.formatMessage({
                  id: `sub_category`,
                })}
                placeholder={intl.formatMessage({
                  id: "sub_category",
                })}
                error={errors[field.name] && errors[field.name].message}
                value={field.value}
                onChange={(e) => {
                  setValue("content", "");
                  setInstruc(" ");
                  setSubject("");
                  field.onChange(e.value);
                }}
                multi={category === "DOCUMENT"}
                options={
                  category === "CLARIFICATION"
                    ? referenceData &&
                      referenceData["request_information_sub_category_list"] &&
                      Array.isArray(
                        referenceData["request_information_sub_category_list"]
                      )
                      ? referenceData["request_information_sub_category_list"]
                      : []
                    : subCategory &&
                      subCategory.data &&
                      subCategory.data.data &&
                      subCategory.data.data.docs &&
                      Array.isArray(subCategory.data.data.docs)
                    ? subCategory.data.data.docs
                    : []
                }
                creatable={addValuePermission ? true : false}
              />
            )}
          />
        ) : (
          <></>
        )}
        <Controller
          name="invoices"
          control={control}
          render={({ field }) => (
            <SelectBox
              name={field.name}
              label={intl.formatMessage({
                id: "invoice",
              })}
              placeholder={intl.formatMessage({
                id: "invoice",
              })}
              error={errors[field.name] && errors[field.name].message}
              size={SIZE.mini}
              value={field.value}
              multi
              onChange={(e) => {
                field.onChange(e.value);
              }}
              getValueLabel={({ option }) => {
                const { label } = option;
                const value = label ? label.split("/") : [];
                return <>{value && value[1] ? value[1] : value[0]}</>;
              }}
              options={
                invoices &&
                invoices.data &&
                invoices.data.data &&
                invoices.data.data.docs &&
                Array.isArray(invoices.data.data.docs)
                  ? invoices.data.data.docs.map((e) => ({
                      id: e._id,
                      label: `${format.rd({
                        id: _.get(e, "business_unit", ""),
                        name: "business_unit_list",
                      })}${_.get(e, "business_unit", "") ? " / " : ""}${_.get(
                        e,
                        "invoice_number",
                        ""
                      )}${_.get(e, "invoice_number", "") ? " / " : ""}
                    ${_.get(e, "client_reference", "")}${
                        _.get(e, "client_reference", "") ? " / " : ""
                      }
                    ${moment
                      .utc(e.invoice_date)
                      .tz(currentDefaultFormatDetails.time_zone)
                      .format(currentDefaultFormatDetails.date_format)}${
                        _.get(e.not_paid_amount, "currency", "") ? " / " : ""
                      }${_.get(e.not_paid_amount, "currency", "")}${
                        _.get(e.not_paid_amount, "value", "") ? " " : ""
                      }${_.get(e.not_paid_amount, "value", "")}`,
                    }))
                  : []
              }
            />
          )}
        />
      </div>
      {!isEdit || (isEdit && !isClientAccess) ? (
        <>
          {category === "CLARIFICATION" ||
          defaultValues?.category === "CLARIFICATION" ? (
            <div
              className="cp-quill cp-quill--action-view"
              style={{ marginBottom: "10px" }}
            >
              <Controller
                name="details"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => {
                  return (
                    <>
                      <RichTextEditorEmail
                        {...field}
                        name={field.name}
                        editorRef={editorRef1}
                        textToInsert={agentInstru}
                        setTextToInsert={setInstruc}
                        isHtml={true}
                        // isDisabled={
                        //   space && !space?.role_permissions?.includes(EMAIL_EDIT)
                        // }
                      />
                      <p style={{ color: "red", padding: "5px" }}>
                        {_.get(errors, "details.message", "")}
                      </p>
                    </>
                  );
                }}
              />
            </div>
          ) : (
            <>
              <Controller
                name="details"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    fullWidth
                    requiredAstric={true}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "details",
                    })}
                    placeholder={intl.formatMessage({
                      id: "details",
                    })}
                    value={field.value}
                    size={TASize.mini}
                  />
                )}
              />
            </>
          )}
        </>
      ) : (
        <></>
      )}

      <div className="request_label" style={{ marginTop: "22px" }}>
        <Controller
          name="assigned_to"
          control={control}
          render={({ field }) => (
            <SelectBox
              {...field}
              clearable={false}
              name={field.name}
              size={SIZE.mini}
              disabled={
                (getSelectValues(Subcategory) || sub_category) ===
                  _.get(templateIdInsert, "cat", "") &&
                (category === "CLARIFICATION" ||
                  defaultValues.category === "CLARIFICATION") &&
                resourceId === undefined
                  ? true
                  : false
              }
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: `assign_to`,
              })}
              placeholder={intl.formatMessage({
                id: "assign_to",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={
                orgUsers && Array.isArray(orgUsers) && riqPermission
                  ? [
                      ...orgUsers,
                      ...(businessUnit && businessUnit.id
                        ? [
                            {
                              id: businessUnit.id,
                              label: businessUnit.label,
                              isClient: true,
                            },
                          ]
                        : []),
                    ]
                  : filterRiqUser && !riqPermission
                  ? [...filterRiqUser]
                  : []
              }
            />
          )}
        />
        <Controller
          name="review_date"
          control={control}
          render={({ field }) => (
            <DateRangeSelect
              clearable={false}
              size={SIZE.mini}
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "review_date",
              })}
              placeholder={intl.formatMessage({
                id: " ",
              })}
              value={field.value}
              onChange={(e) => field.onChange(e.date)}
              minDate={new Date()}
            />
          )}
        />
        {isEdit ? (
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <SelectBox
                {...field}
                clearable={false}
                name={field.name}
                size={SIZE.mini}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: `status`,
                })}
                placeholder={intl.formatMessage({
                  id: "status",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={
                  referenceData &&
                  referenceData["request_information_status"] &&
                  Array.isArray(referenceData["request_information_status"])
                    ? referenceData["request_information_status"].filter(
                        (e) => {
                          if (
                            isClientAccess &&
                            ["OPEN", "CLOSED"].includes(e.id)
                          ) {
                            return false;
                          }

                          return true;
                        }
                      )
                    : []
                }
              />
            )}
          />
        ) : (
          <></>
        )}
      </div>
      {_.get(data, "data.doc.is_holiday") && (
        <>
          <div className="alert-holiday-section">
            <Icon icon="alert_circle" color="#516BEB" />
            <Typography
              type="p"
              style={{
                fontWeight: "400",
              }}
            >
              The Date Selected{" "}
              {_.get(data, "data.doc.holidays", []).map((val) => {
                return (
                  <>
                    {moment(val).format(
                      currentDefaultFormatDetails.date_format
                    )}
                  </>
                );
              })}{" "}
              is a <b>“Holiday”</b>
            </Typography>
          </div>
        </>
      )}
      <div className="request_flex">
        <TextButton
          size="mini"
          kind={KIND.tertiary}
          onClick={() => props.reset()}
          type="button"
        >
          Cancel
        </TextButton>
        <TextButton size="mini" kind={KIND.primary} isLoading={isSubmitting}>
          Save
        </TextButton>
      </div>
    </form>
  );
}

export default RequestClarificationForm;
